import styled from 'styled-components';
import { DESKTOP, TABLET } from 'base/styles/media-queries';
import { PRIMARY, CARD_BORDER } from 'base/styles/skin';
import { Link as ReactRouterLink } from 'react-router-dom';

const backgroundImage = require('assets/icons/alert.svg');

export const TemplateContent = styled.div`
${ DESKTOP } {
  width: 320px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 4px;
  //box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.1);
  border: solid 1px ${ CARD_BORDER };
  background-color: white;
}
`;
TemplateContent.displayName = 'CardContent';

export const TemplateButtonsWrapper = styled.div`
  width: 100%;
  && button {
    width: 100%;
  }
`;
TemplateContent.displayName = 'CardContent';

export const FieldWrapperTemplateName = styled.div`
${ DESKTOP } {
  font-size: 14px;
  color: #3a3a3a;
  padding-bottom: 10px;
}
`;
TemplateContent.displayName = 'FieldWrapperTemplateName';

export const ShowDetailsLink = styled(ReactRouterLink)`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
  display: flex;
  color: ${ PRIMARY };
  &:link,
  &:visited {
    color: ${ PRIMARY };
    font-weight: 500;
    text-decoration: underline;
  }
`;
ShowDetailsLink.displayName = 'ShowDetailsLink';
export const DateWrapper = styled.div`

    & .react-datepicker {
      right: 50px;
      top: 60px;
      .react-datepicker__day-name{
        width:2.8rem;
      }
      .react-datepicker__day{
        width:50px;
      }
      ${ DESKTOP } {
      right: 0px;
      top: 0px;
    }
    ${ TABLET } {
      right: 0px;
      top: 0px;
    }
  }

`;
DateWrapper.displayName = 'DateWrapper';

TemplateContent.displayName = 'CardContent';

export const WarningMessageWrapper = styled.p`
    color: red;
    padding:10px 0px 20px 0px;
    font-size: 14px;
    :before {
      content: '';
      background-image: url(${ backgroundImage });
      display: inline-block;
      width: 30px;
      height: 30px;
      position: relative;
      top: 10px;
`;
WarningMessageWrapper.displayName = 'WarningMessageWrapper';
