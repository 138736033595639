import { Dispatch } from 'redux';
import { isMockingApi } from 'base';
import { LOGOUT, USERS } from 'app/routes';
import { deleteUser, updateUser, createUser } from '../../../actions';
import { User, UserStatus, CompanyPermissions } from '../../../types';
import { MODALS } from '../types';

type SetOpenModal = (openModal: MODALS) => void;

interface CommonDependencies {
  dispatch: Dispatch;
  setOpenModal: SetOpenModal;
}

export const dismiss = (setOpenModal: SetOpenModal) => {
  setOpenModal(undefined);
};

interface RemoveUser extends CommonDependencies {
  history: any;
  id: string;
}

export const remove = async ({ dispatch, history, id, setOpenModal }: RemoveUser) => {
  dismiss(setOpenModal);
  const response = await dispatch(deleteUser(id));
  const { type, payload } = response;

  if (type === 'DELETE_USER_SUCCESS') {
    if (payload && payload.selfdelete) {
      history.push(LOGOUT.path);
    } else {
      history.push(USERS.path);
    }
  } else {
    console.error('Error deleting user');
    history.push(LOGOUT.path);
  }

};

interface SaveUser extends CommonDependencies {
  companyPermissions: CompanyPermissions;
  history: any;
  isAdmin: boolean;
  setEmailError: (isErrored: boolean) => void;
  user: User;
}

export const save = async ({ companyPermissions,
  dispatch,
  history,
  isAdmin,
  user,
  setEmailError,
  setOpenModal }: SaveUser) => {
  const shouldUpdate = !!user?.id;
  dismiss(setOpenModal);
  const actionToPerform = shouldUpdate ? updateUser : createUser;

  let { status } = user;

  // New user
  if (!shouldUpdate) {
    status = UserStatus.PASSWORD_PENDING;

    // User has requested access and anybody is updating that user
    // User gets access granted
  } else if (user.status === UserStatus.ACCESS_REQUESTED && shouldUpdate) {
    status = UserStatus.PASSWORD_PENDING;
  }

  const { type } = await dispatch(actionToPerform({ ...user, status }, isAdmin, companyPermissions));
  if (isMockingApi() || /SUCCESS/.test(type)) {
    history.push(USERS.path);
  } else if (/ERROR/.test(type)) {
    setEmailError(true);
  }
};
