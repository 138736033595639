/* eslint-disable max-len */
import { FormikValues } from 'formik';
import React, { useState, useEffect } from 'react';
import { applyDateLimits } from 'app/utils/validations';
import { createIndexedFields, flattenObject } from 'app/utils/fields';
import Modal from 'components/Modal';
import SmartForm from 'components/SmartForm';
import { FieldName } from 'app/containers/PickupRequest/containers/PickupRequestDetails/types';
import { DateRuleDropdown } from 'app/constants/pickupRequest';
import { FieldWrapperNoteText } from 'app/containers/PickupRequest/containers/PickupRequestDetails/components/SuccessModal/styles';
import { useTranslationsContext } from 'app/translations';
import { useButtons } from './data/buttons';
import { useFields } from './data/fields';
import { useValidationSchema } from './data/validation';
import { TextDescription, FormWrapper, FieldWrapper, DateWrapper, Body, Footer, ButtonsWrapper } from './styles';
import { ModalData, TEMPLATE_TYPE } from '../types';


interface Props {
  templateName: string;
  modalConfig: ModalData;
  templateDateRule?: number;
  isLoading?: boolean;
}

const ExportModal: React.FC<Props> = ({ modalConfig, templateName, templateDateRule, isLoading }) => {
  const [values, setValues] = useState<FormikValues>();
  const translations = useTranslationsContext();
  const fields = applyDateLimits(useFields(), values);
  const buttons = useButtons(modalConfig.onDismiss, modalConfig.modalType);
  //  const formInitialValues = { format: fields.find(f => f.id === ExportFieldName.checkboxSave) };

  const [formErrors, getFormErrors] = React.useState<FormikValues>({});
  const [formErrorKeys, setFormErrorKeys] = React.useState<FormikValues>({});

  const [formTouched, getFormTouched] = React.useState<FormikValues>({});
  const [formTouchedKeys, setFormTouchedKeys] = React.useState<FormikValues>({});
  const wishdate = `${ translations.pickupRequestDetails_preferredDateLabel } ${ ': ' }`; 
  useEffect(() => {
    setFormErrorKeys(flattenObject(formErrors));
  }, [formErrors]);

  useEffect(() => {
    setFormTouchedKeys(flattenObject(formErrors));
  }, [formTouched]);

  const onFormSubmit = React.useCallback(() => {
    const templateValues = { templateActionDetails: values?.templateActionDetails };
    modalConfig.onConfirm(templateValues);
  }, [values]);

  const getDateString = (currentDate: Date) => {
    return <span>{ `${ String(currentDate.getDate()).padStart(2, '0') }.${ String(currentDate.getMonth() + 1).padStart(2, '0') }.${ currentDate.getFullYear().toString().substr(2, 2) }` }</span>;
  };
  const getDateFromRule = (dateRule: number) => {
    const currentDate = new Date();
    switch (dateRule) {
      case DateRuleDropdown.date_rule_haute:
        return getDateString(currentDate);

      case DateRuleDropdown.date_rule_haute_1:
        currentDate.setDate(currentDate.getDate() + 1);
        return getDateString(currentDate);

      case DateRuleDropdown.date_rule_haute_2:
        currentDate.setDate(currentDate.getDate() + 2);
        return getDateString(currentDate);

      case DateRuleDropdown.date_rule_haute_3:
        currentDate.setDate(currentDate.getDate() + 3);
        return getDateString(currentDate);

      case DateRuleDropdown.date_rule_haute_4:
        currentDate.setDate(currentDate.getDate() + 4);
        return getDateString(currentDate);

      case DateRuleDropdown.date_rule_haute_5:
        currentDate.setDate(currentDate.getDate() + 5);
        return getDateString(currentDate);

      default:
        return '';
    }
  };

  return (
    <Modal onClose={ modalConfig.onDismiss } header={ modalConfig.title } size="auto">
      <>
        <TextDescription>{ modalConfig.description }</TextDescription>

        { modalConfig.modalType !== TEMPLATE_TYPE.saveAsTemplate ? <TextDescription>{ translations.template_modalBox_template_Name }: { templateName }</TextDescription> : null }
        <SmartForm
          form={ 'exportData' }
          buttons={ buttons }
          fields={ fields }
          isFetching= { isLoading }
          validationSchema={ useValidationSchema(modalConfig.modalType) }
          handleSubmit={ onFormSubmit }
          handleErrors={ getFormErrors }
          handleTouched={ getFormTouched }
          validateOnChange={ true }
        // initialValues={ formInitialValues }
        >
          { ({ controlledFields, controlledButtons, Field, Buttons, values: vals }) => {
            const indexedFields = createIndexedFields(controlledFields);
            const field = indexedFields;

            setValues(vals);
            return (
              <>
                <Body>
                  { modalConfig.modalType === TEMPLATE_TYPE.saveAsTemplate ? (
                    <FormWrapper>
                      <FieldWrapper>
                        <Field options={ {
                          ...field[FieldName.templateName],
                          error: formTouchedKeys[FieldName.templateName] && formErrorKeys[FieldName.templateName]
                        } } />
                      </FieldWrapper>
                    </FormWrapper>
                  ) : null }
                  { modalConfig.modalType === TEMPLATE_TYPE.processOrderTemplate && templateDateRule === DateRuleDropdown.date_rule_datum_wahlen ? (
                    <DateWrapper>
                      <Field options={ { ...field[FieldName.templatePlaceOrderDate] } } />
                    </DateWrapper>
                  )
                    : <FieldWrapperNoteText>
                      { modalConfig.modalType === TEMPLATE_TYPE.processOrderTemplate 
                      && templateDateRule !== DateRuleDropdown.date_rule_datum_wahlen 
                        ? <span> { wishdate } { getDateFromRule(templateDateRule) } </span> : null }
                      
                    </FieldWrapperNoteText> }
                </Body>
                <Footer>
                  <ButtonsWrapper>
                    <Buttons options={ controlledButtons } />
                  </ButtonsWrapper>
                </Footer>
              </>
            );
          } }
        </SmartForm>
      </>
    </Modal>
  );
};

export default ExportModal;
