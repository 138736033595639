import { http, envConf } from 'base';
import { handleListError } from 'app/utils/connection-errors';
import { createPaginationFromServer } from 'app/models';
import { Pagination } from 'app/types';
import * as Model from '../models/fromServer';
import { createUserToServer } from '../models/toServer';
import { User, CompanyPermissions } from '../types';

interface UserResponse {
  collection: User[];
  pagination: Pagination;
}

export const getUserList = async (offset: number, limit: number, filterValue: string): Promise<UserResponse> => {
  try {
    const { data } = await http.get(envConf.API.USERS_GET, { params: { limit, offset, filterValue } });
    const { user_details, ...pagination } = data;

    return {
      collection: Model.createUserListFromServer(user_details),
      pagination: createPaginationFromServer(pagination)
    };
  } catch (error) {
    return handleListError<User>(error);
  }
};

export const getUser = async (id: string): Promise<User> => {
  try {
    const { data } = await http.get(envConf.API.USER_GET.replace(':id', id));
    return Model.createUserFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const createUser = async (user: User, isAdmin: boolean, permissions: CompanyPermissions): Promise<User> => {
  try {
    const userToServer = createUserToServer(user, isAdmin, permissions);
    const { data } = await http.post(envConf.API.USERS_POST, userToServer);
    return Model.createUserFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const updateUser = async (user: User, isAdmin: boolean, permissions: CompanyPermissions): Promise<User> => {
  try {
    const userToServer = createUserToServer(user, isAdmin, permissions);
    const { data } = await http.patch(envConf.API.USERS_PUT.replace(':id', user.id), userToServer);
    return Model.createUserFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const deleteUser = async (id: string): Promise<User> => {
  try {
    const { data } = await http.delete(envConf.API.USERS_DELETE.replace(':id', id));
    return data;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
