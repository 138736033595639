import React from 'react';

import { ButtonProps } from 'components/Touchable/types';
import { useHistory } from 'react-router-dom';
import { useTranslationsContext } from 'app/translations';
import Page from 'components/Page';
import Footer from 'components/Footer';
import * as ROUTES from 'app/routes';
import Buttons from '../../../../components/Buttons';
import Header from '../../../Core/components/SimpleLayout/components/Header';
import { ButtonWrapper, Body, Content, Text, ExternalLink } from './styles';

interface Props {
  isModal?: boolean;
}

export const PrivacyAgreementView: React.FC<Props> = ({ isModal }) => {
  const translations = useTranslationsContext();
  const history = useHistory();
  const web = 'https://www.avag.ch/avag-kundenportal';

  const button: ButtonProps[] = [
    {
      id: 'closePrivacyInfo',
      label: translations.onboarding_closePrivacyAgreement,
      type: 'button',
      onClick: () => history.push(ROUTES.ONBOARDING.path)
    }
  ];

  if (isModal) {
    return (
      <Content isModal={ isModal }>
        <Text isBold={ true }>{ translations.onboarding_privacy_info_title1 }.</Text>
        <Text>{ translations.onboarding_privacy_info1 }</Text>
        <Text isBold={ true }>{ translations.onboarding_privacy_info_title2 }</Text>
        <Text>{ translations.onboarding_privacy_info2 }</Text>
        <Text isBold={ true }>{ translations.onboarding_privacy_info_title4 }</Text>
        <Text> { translations.onboarding_privacy_info4 }</Text>
        <Text> { translations.onboarding_privacy_info5 }
          <ExternalLink href={ web }>
            { translations.onboarding_privacy_info_title6 }
          </ExternalLink>{ translations.onboarding_privacy_info6 }
        </Text>
        { /* <Text>{ translations.onboarding_privacy_info4 }
          <ExternalLink href={ web }>
            { translations.onboarding_privacy_info_title6 }
          </ExternalLink>{ translations.onboarding_privacy_info5 }
        </Text> */ }
        <Text isBold={ true }>{ translations.onboarding_privacy_info_title3 }</Text>
        <Text>{ translations.onboarding_privacy_info3 }</Text>
      </Content>
    );
  }
  return (
    <>
      <Page size="sm">
        <Body>
          <ButtonWrapper>
            <Buttons options={ button }></Buttons>
          </ButtonWrapper>
          <Header />
          <Content> 
            <Text isBold={ true }>{ translations.onboarding_privacy_info_title1 }</Text>
            <Text>{ translations.onboarding_privacy_info1 }</Text>
            <Text isBold={ true }>{ translations.onboarding_privacy_info_title2 }</Text>
            <Text>{ translations.onboarding_privacy_info2 }</Text>
            <Text isBold={ true }>{ translations.onboarding_privacy_info_title3 }</Text>
            <Text>{ translations.onboarding_privacy_info3 }</Text>
          </Content>
        </Body>
      </Page>
      <Footer margin={ false } />
    </>
  );
};

export default PrivacyAgreementView;
