import { ROLE } from 'app/constants/roles';
import { Pagination } from 'app/types';

export * from './fromServer';

export enum UserStatus {
  ACCESS_REQUESTED = 1,
  PASSWORD_PENDING,
  USER,
  VERIFICATION_PENDING,
  Inactive
}

export interface Entity {
  id: number;
  name: React.ReactElement;
}

export interface ConstraintGroup {
  canModify: boolean;
  isActiveUser: boolean;
  isAdmin: boolean;
  isNewUser: boolean;
  isSameUser: boolean;
  isSuperUser: boolean;
}

export interface UserRequestParams {
  page: number;
  pageSize: number;
  filterValue: string;
}

export interface UsersState {
  collection: User[];
  pagination: Pagination;
}

export interface Permissions {
  requestPickup: boolean;
  accessBills: boolean;
}

export interface CompanyPermissions extends Permissions {
  portal: boolean;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  date?: number;
  email: string;
  phoneNumber?: string;
  companyId: string;
  companyName: string;
  permissions: Permissions;
  companyPermissions: CompanyPermissions;
  role: ROLE;
  status: number;
}

export interface UserRow {
  locked: boolean;
  id: string;
  company: string;
  date: number;
  fullName: string;
  email: string;
  role: string;
  status: number;
  navigate: string;
}
