import { RootState } from 'base/types';
import { CREATE_URL } from 'app/constants/routes';
import { pickupRequestDetailsInitialState } from 'containers/PickupRequest/models';
import { Container } from 'containers/PickupRequest/types';
import { pickupRequestTemplateDetailsInitialState } from '../models';

export const getTemplatesList = ({ templateRequest: { collection, pagination } }: RootState) => ({
  collection,
  pagination
});

export const getTemplateDetails = (id?: string) => ({ templateRequest }: RootState) => (id && id !== CREATE_URL
  ? templateRequest.collection.find(template => template.templateId === id)
  : pickupRequestTemplateDetailsInitialState);

export const getPickupRequest = (id?: string) => ({ pickupRequest }: RootState) =>
  (id && id !== CREATE_URL
    ? pickupRequest.collection.find(pickup => pickup.id === id)
    : pickupRequestDetailsInitialState);

export const getPickupStatus = (id?: string) => (rootState: RootState) => getPickupRequest(id)(rootState)?.status;

export const getRecentContainerList = ({ pickupRequest }: RootState) => pickupRequest.dropdownGroup.recentsList;

export const getMaterialList = ({ pickupRequest }: RootState) => pickupRequest.dropdownGroup.materialList;

export const getContainerList = (type?: string) => ({ pickupRequest }: RootState): Container[] => {
  const { containerList } = pickupRequest.dropdownGroup;
  if (!type) {
    return containerList;
  }

  return containerList.filter(c => c.type === type);
};

export const getContainer = (id: string) => ({ pickupRequest }: RootState) =>
  pickupRequest.dropdownGroup.containerList.find(c => c.id === id);

export const getTypesList = ({ pickupRequest }: RootState) => pickupRequest.dropdownGroup.typesList;