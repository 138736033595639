import { http, envConf } from 'base';
import { handleListError } from 'app/utils/connection-errors';
import * as Model from '../models';
import { BookingRef } from '../types';

export const getBookingRefs = async (companyId: string): Promise<BookingRef[]> => {
  try {
    const { data } = await http.get(envConf.API.BOOKING_REF_GET.replace(':client_id', companyId));
    return Model.createBookingRefListFromServer(data);
  } catch (error) {
    const { collection } = handleListError(error);
    return collection as BookingRef[];
  }
};
