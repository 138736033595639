import styled from 'styled-components';
import { HEADER_SIZE, SIDEBAR_SIZE, SIDEBAR_SIZE_DOCKED } from 'base/styles/sizes';
import { SIDEBAR_BACKGROUND,
  SIDEBAR_COLOR,
  SIDEBAR_BORDER_COLOR,
  BACKDROP_BACKGROUND,
  PRIMARY,
  NAV_LINK_HOVER } from 'base/styles/skin';
import { TABLET, DESKTOP } from 'base/styles/media-queries';
import getLayerPosition from 'base/styles/z-index';
import { NavLink } from 'react-router-dom';


interface WrapperProps {
  isOpen: boolean;
}

export const Wrapper = styled.nav`
  background-color: ${ SIDEBAR_BACKGROUND };
  color: ${ SIDEBAR_COLOR };
  height: calc(100vh - ${ HEADER_SIZE }px);
  left: ${ ({ isOpen }: WrapperProps) => (isOpen ? '0' : '-250vw') };
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: ${ HEADER_SIZE }px;
  transition: left 0.2s;
  width: 100%;
  z-index: ${ getLayerPosition('SIDEBAR') };

  ${ TABLET } {
    left: ${ ({ isOpen }: WrapperProps) => (isOpen ? '0' : `-${ SIDEBAR_SIZE }px`) };
    max-width: ${ SIDEBAR_SIZE }px;
  }

  ${ DESKTOP } {
    left: 0;
    max-width: ${ SIDEBAR_SIZE_DOCKED }px;
    overflow-y: hidden;
    transition: max-width 0.2s;

    &:hover {
      max-width: ${ SIDEBAR_SIZE }px;
      overflow-y: auto;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export const Backdrop = styled.div`
  background-color: ${ BACKDROP_BACKGROUND };
  display: ${ ({ isOpen }: WrapperProps) => (isOpen ? 'inline-block' : 'none') };
  height: 100vh;
  left: 0;
  opacity: ${ ({ isOpen }: WrapperProps) => (isOpen ? '1' : '0') };
  position: fixed;
  top: 0;
  transition: opacity 0.2s;
  width: 100vw;
  z-index: ${ getLayerPosition('SIDEBAR') };

  ${ DESKTOP } {
    display: none;
  }
`;
Backdrop.displayName = 'Backdrop';

export const Header = styled.div`
  align-items: center;
  display: flex;
  font-size: 22px;
  font-weight: 500;
  padding: 16px;

  ${ DESKTOP } {
    display: none;
  }
`;
Header.displayName = 'Header';

export const List = styled.ul``;
List.displayName = 'List';

export const Item = styled(NavLink)`
  align-items: center;
  border-bottom: 1px solid ${ SIDEBAR_BORDER_COLOR };
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 300;
  min-height: 52px;
  padding: 19px;
  transition: min-height 0.2s;

  &.active {
    background-color: ${ PRIMARY };
  }

  ${ DESKTOP } {
    min-height: ${ SIDEBAR_SIZE_DOCKED }px;

    // Hide logout item
    &:last-child {
      display: none;
    }

    &:hover {
      //background-image: linear-gradient(to bottom, ${ SIDEBAR_BACKGROUND }, ${ PRIMARY });
        background-color: ${ NAV_LINK_HOVER };
    }

    // Hide labels
    & > span:last-child {
      opacity: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: opacity 0.2s;
      white-space: nowrap;

      nav:hover & {
        opacity: 1;
      }
    }
  }
`;
Item.displayName = 'Item';
