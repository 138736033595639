import React from 'react';
import Buttons from 'components/Buttons';
import { TemplateButtonsWrapper } from '../styles';

interface Props {
  label: string;
}
const TemplateHomeButton: React.FC<Props> = ({ label }) => {

  return (
    <TemplateButtonsWrapper>
      <Buttons
        align="center"
        options={ [
          {
            id: 'show-template',
            label,
            buttonType: 'primary',
      
            type: 'button'
          }
        ] }
      />
    </TemplateButtonsWrapper>
  );
};

export default TemplateHomeButton;
