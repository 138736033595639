import styled from 'styled-components';
import DefaultTable from 'components/Table';
import { ActionsBar, TableFooter } from 'components/Table/styles';

export const TotalsTable = styled(DefaultTable)`
  & > ${ ActionsBar } {
    display: none;
  }
  & ${ TableFooter } {
    display: none;
  }
    
    & td:nth-last-child(1){
        font-weight: bold;
        text-align: left;
        padding-left: 5px;
        width: 25%;
    }
    & td:nth-last-child(2){
        text-align: right;
        padding-right: 0px;
    }
` as typeof DefaultTable;

export const TotalsTableWrapper = styled.div`
  margin-bottom: 20px;
`;
TotalsTableWrapper.displayName = 'TotalsTableWrapper';
