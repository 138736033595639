import React from 'react';
import styled from 'styled-components';
import Card from 'components/Card';
import { WrapperTitle } from 'components/SectionTitle/styles';
import { SectionWrapper } from 'containers/PickupRequest/containers/PickupRequestDetails/styles';
import Skeleton, { AvagDot, Bar } from './pieces';

const StyledBar = styled(Bar)`
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  width: 200px;
`;

const StyledCard = styled(Card)`
  min-height: 300px;
`;

const Section: React.FC = () => (
  <SectionWrapper>
    <WrapperTitle>
      <AvagDot />
      <StyledBar />
    </WrapperTitle>
    <StyledCard indentation={ true } />
  </SectionWrapper>
);

const PickupDetailsSkeleton: React.FC = () => (
  <Skeleton>
    <Section />
    <Section />
    <Section />
  </Skeleton>
);

export default PickupDetailsSkeleton;
