import React from 'react';
import { useTranslationsContext } from 'app/translations';
import SectionTitle from 'components/SectionTitle';
import { IndexedFields } from 'app/types';
import { PickupRequest } from 'containers/PickupRequest/types';
import { createIndexedFields } from 'app/utils/fields';
import { FieldArray } from 'formik';
import { FieldName, DriverOrderFieldName } from '../../types';
import { CardContent } from './styles';
import DriverOrderRow from './components/DriverOrderRow';

interface Props {
 fields: IndexedFields<FieldName>;
 values: PickupRequest;
 isLoading?: boolean;
}
const DriverOrders: React.FC<Props> = ({ fields, values }) => {
  // const [fieldId, setFieldId] = React.useState('');
  const translations = useTranslationsContext();
  const { driverOrders } = fields;

  return (
    <>
      { (values?.driverOrders?.length > 0 
        && (values.status !== undefined && values.status !== 3)) ? <>
          <SectionTitle>{ translations.driver_order_title }</SectionTitle>
          <CardContent indentation={ false }>
            <FieldArray name={ driverOrders?.id }>
              { () => (
                <>
                  { values.driverOrders.map((driverOrder, index) => {
                    const driverOrderFields = createIndexedFields<DriverOrderFieldName>(driverOrders.fields,
                      driverOrders.id, index);

                    return (
                      <DriverOrderRow
                        key={ index }
                        fields={ driverOrderFields }
                        values={ driverOrder }
                      />
                    );
                  }) }
                </>
              ) }
            </FieldArray>
          </CardContent> 
        </>
        : null }
    </>
  );
};

export default DriverOrders;