import React, { PropsWithChildren } from 'react';
import { Wrapper } from './styles';

/**
 * Manual tooltip
 * Set manually tooltip position
 */
interface ManualProps {
  left: number;
  top: number;
  right?: number;
}

const ManualTooltip: React.FC<PropsWithChildren<ManualProps>> = ({ children, left, top, right }) => (
  <Wrapper left={ left } top={ top } right={ right }>
    { children }
  </Wrapper>
);

/**
 * Automatic tooltip
 * Detect mouse position and apply tooltip
 */
interface AutoProps {
  wrapper: React.RefObject<HTMLDivElement>;
}

const AutoTooltip: React.FC<PropsWithChildren<AutoProps>> = ({ children, wrapper }) => {
  const [[left, top], setPosition] = React.useState([0, 0]);
  const [render, setRender] = React.useState(false);

  const handleMouseEnter = () => {
    setRender(true);
  };

  const handleMouseLeave = () => {
    setRender(false);
  };

  const handleMouseMove = (event: MouseEvent) => {
    const { offsetX = 0, offsetY = 0 } = event || {};

    if (offsetX !== left || offsetY !== top) {
      setPosition([offsetX, offsetY]);
    }
  };

  React.useEffect(() => {
    wrapper.current.addEventListener('mouseenter', handleMouseEnter, true);
    wrapper.current.addEventListener('mousemove', handleMouseMove, true);
    wrapper.current.addEventListener('mouseleave', handleMouseLeave, true);
    return () => {
      if (wrapper.current) {
        wrapper.current.removeEventListener('mouseenter', handleMouseEnter, true);
        wrapper.current.removeEventListener('mousemove', handleMouseMove, true);
        wrapper.current.removeEventListener('mouseleave', handleMouseLeave, true);
      }
    };
  }, []);

  if (!render) {
    return null;
  }

  return (
    <Wrapper left={ left } top={ top }>
      { children }
    </Wrapper>
  );
};

/**
 * Tooltip.
 * Transparent implementation of tooltip
 */
const Tooltip: React.FC<PropsWithChildren<ManualProps | AutoProps>> = ({ children, ...props }) => {
  // eslint-disable-next-line no-restricted-syntax
  if ('wrapper' in props) {
    return <AutoTooltip wrapper={ props.wrapper }>{ children }</AutoTooltip>;
  }

  return (
    <ManualTooltip left={ props.left } top={ props.top } right={ props.right }>
      { children }
    </ManualTooltip>
  );
};

export default Tooltip;
