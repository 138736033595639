import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslationsContext } from 'app/translations';
import Buttons from 'components/Buttons';
import { TEMPLATE_LIST } from 'app/routes';
import { Wrapper } from './styles';

const FooterTable: React.FC = () => {
  const translations = useTranslationsContext();
  const history = useHistory();

  return (
    <Wrapper>
      <>
        <Buttons
          options={ [
            {
              id: 'view-all-templates',
              label: translations.template_view_all,
              onClick: () => {
                history.push(TEMPLATE_LIST.path);
              },
              type: 'button'
            }
          ] }
        />
      </>
    </Wrapper>
  );
};

export default FooterTable;
