import produce from 'immer';
import { createReducer, isMockingApi } from 'base';
import { ActionHandler } from 'base/types';
import { ActionTypes as LogoutActions } from 'containers/Login/actionTypes';
import { ActionTypes, DeliverySlipActionType } from '../actionTypes';
import { DELIVERY_SLIP_LIST } from '../mocks';
import { deliverySlipInitialState } from '../models';
import { DeliverySlip, DeliverySlipsState } from '../types';

const getDeliverySlipListSuccess = (state: DeliverySlipsState, action: DeliverySlipActionType) =>
  produce(state, draft => {
    const { collection, pagination } = action.payload as DeliverySlipsState;
    draft.collection = collection || state.collection;
    draft.pagination = pagination || state.pagination;
  });

const getDeliverySlipListError = (state: DeliverySlipsState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.collection = DELIVERY_SLIP_LIST.slice(0, draft.pagination.pageSize);
      draft.pagination.totalCount = DELIVERY_SLIP_LIST.length;
    }
  });

const getDeliverySlipSuccess = (state: DeliverySlipsState, action: DeliverySlipActionType) =>
  produce(state, draft => {
    const deliverySlip = action.payload as DeliverySlip;
    let hasBeenModified = false;
    draft.collection = state.collection.map(p => {
      if (p.id === deliverySlip.id) {
        hasBeenModified = true;
        return {
          ...p,
          ...deliverySlip
        };
      }

      return p;
    });

    if (!hasBeenModified) {
      draft.collection.push(deliverySlip);
    }
  });

const resetDeliverySlips = (state: DeliverySlipsState) =>
  produce(state, draft => {
    draft.collection = [];
  });

const getInitialState = (state: DeliverySlipsState) =>
  produce(state, draft => {
    Object.keys(draft).forEach(key => {
      // @ts-ignore
      draft[key] = deliverySlipInitialState[key];
    });
  });

const actionHandlers: ActionHandler<DeliverySlipsState> = {
  [ActionTypes.GET_DELIVERY_SLIP_LIST_SUCCESS]: getDeliverySlipListSuccess,
  [ActionTypes.GET_DELIVERY_SLIP_LIST_ERROR]: getDeliverySlipListError,
  [ActionTypes.GET_DELIVERY_SLIP_SUCCESS]: getDeliverySlipSuccess,
  [ActionTypes.GET_DELIVERY_SLIP_ERROR]: getDeliverySlipListError,
  [ActionTypes.RESET_DELIVERY_SLIPS]: resetDeliverySlips,
  [LogoutActions.LOGOUT_REQUEST]: getInitialState
};

export default createReducer(deliverySlipInitialState, actionHandlers);
