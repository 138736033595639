import styled from 'styled-components';
import { TABLET, DESKTOP } from 'base/styles/media-queries';
import { PAGE_MAX_WIDTH_SM, PAGE_MAX_WIDTH, PAGE_MAX_WIDTH_LG } from 'base/styles/sizes';
import { Size } from 'app/types';

export const Wrapper = styled.div`
  padding: 0 10px;

  ${ TABLET } {
    padding: 0 20px;
  }

  ${ DESKTOP } {
    padding: 0 40px;
  }
`;
Wrapper.displayName = 'Wrapper';

interface Props {
  size: Size;
}

export const Content = styled.div`
  margin: 0 auto;
  max-width: ${ ({ size }: Props) => {
    switch (size) {
      case 'sm':
        return `${ PAGE_MAX_WIDTH_SM }px`;
      case 'md':
        return `${ PAGE_MAX_WIDTH }px`;
      case 'lg':
        return `${ PAGE_MAX_WIDTH_LG }px`;
      default:
        return 'none';
    }
  } };
`;
Content.displayName = 'Content';
