import { LoginState, LoginRequest } from '../types';

export * from './fromServer';
export * from './toServer';

export const loginInitialState: LoginState = {
  user: undefined
};

export const loginFormInitialValues: LoginRequest = {
  user_email: '',
  password: ''
};
