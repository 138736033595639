import styled from 'styled-components';
import getLayerPosition from 'base/styles/z-index';
import { TOOLTIP_BACKGROUND, TOOLTIP_SHADOW } from 'base/styles/skin';

interface Props {
  left?: number;
  top?: number;
  right?: number;
}

export const Wrapper = styled.div`
  background: ${ TOOLTIP_BACKGROUND };
  border: 1px solid white;
  border-radius: 5px;
  box-shadow: ${ TOOLTIP_SHADOW };
  left: ${ ({ left }: Props) => (left ? `${ left }px` : 'initial') };
  padding: 8px 12px;
  position: fixed;
  right: ${ ({ right }: Props) => (right ? `${ right }px` : 'initial') };
  top: ${ ({ top }: Props) => (top ? `${ top }px` : 'initial') };
  transform: translate(0, -50%);
  z-index: ${ getLayerPosition('NOTIFICATION') };
`;
Wrapper.displayName = 'Wrapper';
