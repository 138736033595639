import styled from 'styled-components';
import { Styles } from 'react-select/src/styles';
import { PRIMARY,
  FIELD_BORDER_COLOR,
  DISABLED_BACKGROUND,
  FIELD_BACKGROUND,
  DISABLED_COLOR,
  DROPDOWN_COLOR_OPTIONS,
  DROPDOWN_BACKGROUND_ACTIVE,
  BUTTON_PRIMARY_BORDER,
  BUTTON_PRIMARY_COLOR,
  BUTTON_PRIMARY_BACKGROUND,
  FIELD_BORDER_COLOR_DISABLED,
  ERROR_BORDER_COLOR } from 'base/styles/skin';

interface State {
  isActive: boolean;
  isDisabled: boolean;
  isSelected: boolean;
}

const getBackgroundColor = (provided: React.CSSProperties, { isActive, isSelected }: State): string => {
  if (isActive || isSelected) return DROPDOWN_BACKGROUND_ACTIVE;
  return provided.backgroundColor;
};

const getColor = (provided: React.CSSProperties, { isActive, isDisabled, isSelected }: State): string => {
  if (isDisabled) return DISABLED_COLOR;
  if (isActive || isSelected) return DROPDOWN_COLOR_OPTIONS;
  return provided.color;
};

const getDropdownStyles = (): Partial<Styles> => ({
  container: (provided, { isDisabled }) => ({
    ...provided,
    borderRadius: isDisabled ? '0' : '4px',
    borderBottom: isDisabled && `solid 1px ${ FIELD_BORDER_COLOR_DISABLED }`,
    border: !isDisabled && `solid 1px ${ FIELD_BORDER_COLOR }`
  }),
  control: provided => ({
    ...provided,
    alignItems: 'baseline',
    backgroundColor: FIELD_BACKGROUND,
    border: 'none',
    boxShadow: 'none',
    color: 'pointer',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    height: '30px',
    lineHeight: '1.43em',
    minHeight: '30px',
    // minWidth: `${ 8 * selectedOption?.length + 50 }px`,
    position: 'relative',
    textOverflow: 'ellipsis',
    transition: 'background-color 0.2s, border: 0.2s, color 0.2s',

    '& > div': {
      height: '100%'
    },

    '@media only screen and (max-width: 720px)': {
      minWidth: '0',
      minHeight: 'max-content'
    },

    '&:hover': {}
  }),
  indicatorsContainer: provided => ({
    ...provided,
    alignItems: 'baseline'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: (provided, { isDisabled }) => ({
    ...provided,
    display: isDisabled && 'none',
    borderRadius: '4px',
    backgroundColor: PRIMARY,
    border: `1px solid ${ PRIMARY }`,
    padding: '0'
  }),
  menu: provided => ({
    ...provided,
    margin: '0',
    boxShadow: '0 2px 10px 5px rgba(0, 0, 0, 0.1)',
    backgroundColor: FIELD_BACKGROUND
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: getBackgroundColor(provided, state),
    color: getColor(provided, state),
    transition: 'background-color 0.2s',

    '&:active, &:focus, &:hover': {
      backgroundColor: state.isDisabled ? DISABLED_BACKGROUND : DROPDOWN_BACKGROUND_ACTIVE,
      color: state.isDisabled ? DISABLED_COLOR : DROPDOWN_COLOR_OPTIONS
    }
  }),
  singleValue: () => ({
    color: 'inherit !important',
    maxWidth: 'calc(100% - 6px - 20px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
});

export default getDropdownStyles;

export const getDropdownErrorStyles = (): Partial<Styles> => ({
  container: provided => ({
    ...provided,
    borderRadius: '4px',
    border: `1px solid ${ ERROR_BORDER_COLOR }`
  })
});

export const DropdownButton = styled.button`
  background-color: ${ BUTTON_PRIMARY_BACKGROUND };
  border: none;
  color: ${ BUTTON_PRIMARY_COLOR };
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
  min-height:30px;
  min-width:30px;

  &:active {
    padding-top-color: ${ BUTTON_PRIMARY_BORDER };
    border-bottom-color: transparent;
  }

  &:focus {
    outline: none;
  }
  img{
      width:100%;
      height: 100%;
  }
`;
DropdownButton.displayName = 'DropdownButton';
