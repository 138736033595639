import * as Yup from 'yup';
import { useTranslationsContext } from 'app/translations';
import { TEMPLATE_TYPE } from '../../types';

const TEXT_MAX_LENGTH = 100;
const TEXT_MIN_LENGTH = 1;

export const useValidationSchema = (modalType: string): Yup.ObjectSchema => {

  const translations = useTranslationsContext();
  switch (modalType) {
    case TEMPLATE_TYPE.saveAsTemplate:
      return Yup.object().shape({
        templateActionDetails: Yup.object().shape({
          templateName: Yup.string()
            .required(translations.validations_requiredField)
            .min(TEXT_MIN_LENGTH, translations.validations_minLength.replace(':min:', TEXT_MIN_LENGTH.toString()))
            .max(TEXT_MAX_LENGTH, translations.validations_maxLength.replace(':max:', TEXT_MAX_LENGTH.toString()))
        })
      });
    default:
      return Yup.object().shape({});
  }
};
