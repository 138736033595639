import styled, { css } from 'styled-components';
import {HEADER_SIZE, LOGO_SIZE, LOGO_SIZE_DESKTOP, New_LOGO_SIZE_DESKTOP} from 'base/styles/sizes';
import { HEADER_BORDER_COLOR, HEADER_BACKGROUND, TEXT_COLOR, HEADER_USERNAME_HOVER_COLOR } from 'base/styles/skin';
import { DESKTOP } from 'base/styles/media-queries';
import getLayerPosition from 'base/styles/z-index';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.header``;
Wrapper.displayName = 'Wrapper';

const TextStyles = css`
  font-family: inherit;
  font-size: 14px;
  line-height: 1.43;
  text-align: right;
  color: ${ TEXT_COLOR };
`;

const HeaderStyles = css`
  height: ${ HEADER_SIZE }px;
  width: 100%;
  z-index: ${ getLayerPosition('HEADER') };
`;

export const HeaderBar = styled.div`
  ${ HeaderStyles }
  align-items: center;
  background-color: ${ HEADER_BACKGROUND };
  border-bottom: 1px solid ${ HEADER_BORDER_COLOR };
  display: flex;
  padding: 12px 8px;
  position: fixed;
  top: 0;
  transition: padding 0.2s;

  ${ DESKTOP } {
    padding: 8px 20px;
  }
`;
HeaderBar.displayName = 'HeaderBar';

export const HeaderSpacer = styled.div`
  ${ HeaderStyles }
  height: ${ HEADER_SIZE }px;
`;
HeaderSpacer.displayName = 'HeaderSpacer';

export const Side = styled.div`
  display: flex;
`;
Side.displayName = 'Side';

export const Logo = styled.img`
  height: ${ LOGO_SIZE }px;
  transition: height 0.2s;

  ${ DESKTOP } {
    height: ${ New_LOGO_SIZE_DESKTOP }px;
  }
`;

export const HeaderButton = styled(NavLink)`
  align-content: center;
  cursor: pointer;
  display: none;
  height: ${ LOGO_SIZE_DESKTOP }px;
  justify-content: center;
  padding: 3px;
  width: ${ LOGO_SIZE_DESKTOP }px;

  ${ DESKTOP } {
    display: flex;
  }
`;

export const WelcomeMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  height: ${ LOGO_SIZE_DESKTOP }px;
`;
WelcomeMessage.displayName = 'WelcomeMessage';

export const Text = styled.div`
  ${ TextStyles }
`;
Text.displayName = 'Text';

export const Name = styled(NavLink)`
  ${ TextStyles }
  font-weight: bold;
  &:hover{
      text-decoration: none;
      color: ${ HEADER_USERNAME_HOVER_COLOR };
  }
`;
Name.displayName = 'Name';
