import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslationsContext } from 'app/translations';
import Buttons from 'components/Buttons';
import { NOTIFICATIONS } from 'app/routes';
import { Wrapper, Text, TextDesktop, TextRow } from './styles';

interface Props {
  currentLength: number;
  totalCount: number;
}

const FooterTable: React.FC<Props> = ({ totalCount, currentLength }) => {
  const translations = useTranslationsContext();
  const history = useHistory();
  const text = `${ translations.home_footer_show } ${ currentLength } ${ translations.home_footer_of } ${ totalCount }`;

  return (
    <Wrapper>
      { !!totalCount && (
        <>
          <TextRow>
            <Text>{ text }</Text>
            <TextDesktop>{ translations.notifications }</TextDesktop>
          </TextRow>

          <Buttons
            options={ [
              {
                id: 'show-all-notifications',
                label: translations.button_show_all,
                onClick: () => {
                  history.push(NOTIFICATIONS.path);
                },
                type: 'button'
              }
            ] }
          />
        </>
      ) }
    </Wrapper>
  );
};

export default FooterTable;
