import styled from 'styled-components';
import { DISABLED_BACKGROUND, SWITCH_BACKGROUND, SWITCH_BACKGROUND_ACTIVE, DISABLED_BORDER } from 'base/styles/skin';

import { RADIO_SIZE, Wrapper, Label, Description } from '../styles';

const outerBorder = Math.trunc(RADIO_SIZE / 10);
const innerBorder = Math.trunc(RADIO_SIZE / 10);

export const StyledWrapper = styled(Wrapper)`
  padding: 0;
`;

export const StyledLabel = styled(Label)`
  padding: 0;
`;

export const StyledDescription = styled(Description)`
  padding: 10px 0;
  white-space: initial;
`;

export const CustomRadio = styled.div`
  background-color: ${ SWITCH_BACKGROUND };
  border: ${ outerBorder }px solid ${ DISABLED_BORDER };
  border-radius: 50%;
  box-sizing: border-box;
  cursor: inherit;
  display: inline-block;
  height: ${ RADIO_SIZE }px;
  justify-content: center;
  margin-right: 10px;
  min-height: ${ RADIO_SIZE }px;
  min-width: ${ RADIO_SIZE }px;
  padding: ${ innerBorder }px;
  position: relative;
  transition: background-color 0.2s;
  width: ${ RADIO_SIZE }px;

  input:disabled ~ div & {
    background-color: ${ DISABLED_BACKGROUND };
    cursor: not-allowed;
  }

  &:after {
    background-color: ${ SWITCH_BACKGROUND_ACTIVE };
    border-radius: 50%;
    box-sizing: border-box;
    content: ' ';
    display: none;
    height: 100%;
    transition: background-color 0.2s, left 0.2s;
    width: 100%;

    input:checked ~ div & {
      display: block;
    }

    input:disabled ~ div & {
      background-color: ${ DISABLED_BORDER };
    }
  }

  & + span {
    font-size: 14px;
    line-height: 1.2em;
  }
`;
CustomRadio.displayName = 'CustomRadio';
