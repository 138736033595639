const DEFAULT_DEBOUNCE_TIME = 500;
let timeout: ReturnType<typeof setTimeout>;

const debounce = (callback: () => void, time = DEFAULT_DEBOUNCE_TIME) => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    callback();
  }, time);
};

export default debounce;
