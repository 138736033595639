import React from 'react';
import { isMockingApi } from 'base';
import { Wrapper } from './styles';

const MocksAdvisor: React.FC = () => {
  if (!isMockingApi()) {
    return null;
  }

  return <Wrapper>Mocks mode</Wrapper>;
};

export default MocksAdvisor;
