import produce from 'immer';
import { createReducer, isMockingApi } from 'base';
import { ActionHandler } from 'base/types';
import { ActionTypes as LogoutActions } from 'containers/Login/actionTypes';
import { ActionTypes, CompaniesActionType } from '../actionTypes';
import {COMPANY_SIMPLE_LIST, COMPANY_LIST, COMPANY_SWITCH_LIST} from '../mocks';
import { companiesInitialState } from '../models';
import * as Model from '../models/fromServer';
import {CompaniesState, CompanySimple, Company, CompaniesCompleteState, CompanyList} from '../types';

const getCompaniesSimpleSuccess = (state: CompaniesState, action: CompaniesActionType) =>
  produce(state, draft => {
    const collection = action.payload as CompanySimple[];
    draft.simple.collection = collection || state.simple.collection;
  });

const getCompaniesSimpleError = (state: CompaniesState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.simple.collection = COMPANY_SIMPLE_LIST;
    }
  });
const getCompanyListSuccess = (state: CompaniesState, action: CompaniesActionType) =>
    produce(state, draft => {
        const collection = action.payload as CompanyList[];
        draft.switchlist.collection = collection || state.switchlist.collection;
    });

const getCompanyListError = (state: CompaniesState) =>
    produce(state, draft => {
        if (isMockingApi()) {
            draft.switchlist.collection = COMPANY_SWITCH_LIST;
        }
    });

const getCompaniesSuccess = (state: CompaniesState, action: CompaniesActionType) =>
  produce(state, draft => {
    const { collection, pagination } = action.payload as CompaniesCompleteState;
    draft.complete.collection = collection || state.complete.collection;
    draft.complete.pagination = pagination || state.complete.pagination;
  });

const getCompaniesError = (state: CompaniesState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.complete.collection = COMPANY_LIST.slice(0, draft.complete.pagination?.pageSize);
      draft.complete.pagination.totalCount = COMPANY_LIST.length;
    }
  });

const getCompanySuccess = (state: CompaniesState, action: CompaniesActionType) =>
  produce(state, draft => {
    const company = action.payload as Company;
    let hasBeenModified = false;
    draft.complete.collection = state.complete.collection.map(c => {
      if (c.id === company?.id) {
        hasBeenModified = true;
        return {
          ...c,
          ...company
        };
      }

      return c;
    });

    if (!hasBeenModified) {
      draft.complete.collection.push(company);
    }
  });

const getCompanyError = (state: CompaniesState) =>
  produce(state, draft => {
    if (isMockingApi() && !state.complete.collection.length) {
      draft.complete.collection = [COMPANY_LIST[0]];
      draft.complete.pagination.totalCount = 1;
    }
  });

const updatePermissionsSuccess = (state: CompaniesState, action: CompaniesActionType) =>
  produce(state, draft => {
    const companies = action.payload as Company;
    draft.complete.collection = draft.complete.collection.map(company => {
      if (company.id === companies?.id) {
        companies.name = company.name;
        return {
          ...company,
          ...companies
        };
      }

      return company;
    });
  });

const updatePermissionsError = (state: CompaniesState, action: CompaniesActionType) =>
  produce(state, draft => {
    const payload = action?.payload as Error;
    if (isMockingApi() && /{/.test(payload?.message)) {
      const response = JSON.parse(payload.message);
      const newCompanyPermissions = Model.createCompanyFromServer(response);
      draft.complete.collection = draft.complete.collection.map(company => {
        if (company.id === newCompanyPermissions?.id) {
          newCompanyPermissions.name = company.name;
          return { ...company, ...newCompanyPermissions };
        }
        return company;
      });
    }
  });

const resetCompanies = (state: CompaniesState) =>
  produce(state, draft => {
    draft.complete.collection = [];
  });

const getInitialState = (state: CompaniesState) =>
  produce(state, draft => {
    Object.keys(draft).forEach(key => {
      // @ts-ignore
      draft[key] = companiesInitialState[key];
    });
  });

const actionHandlers: ActionHandler<CompaniesState> = {
  [ActionTypes.GET_COMPANIES_SIMPLE_SUCCESS]: getCompaniesSimpleSuccess,
  [ActionTypes.GET_COMPANIES_SIMPLE_ERROR]: getCompaniesSimpleError,
  [ActionTypes.GET_COMPANIES_SUCCESS]: getCompaniesSuccess,
  [ActionTypes.GET_COMPANIES_ERROR]: getCompaniesError,
  [ActionTypes.GET_COMPANY_SUCCESS]: getCompanySuccess,
  [ActionTypes.GET_COMPANY_ERROR]: getCompanyError,
  [ActionTypes.PATCH_COMPANY_PERMISSIONS_SUCCESS]: updatePermissionsSuccess,
  [ActionTypes.PATCH_COMPANY_PERMISSIONS_ERROR]: updatePermissionsError,
    [ActionTypes.GET_COMPANYLIST_SUCCESS]: getCompanyListSuccess,
    [ActionTypes.GET_COMPANYLIST_ERROR]: getCompanyListError,
  [ActionTypes.RESET_COMPANIES]: resetCompanies,
  [LogoutActions.LOGOUT_REQUEST]: getInitialState
};

export default createReducer(companiesInitialState, actionHandlers);
