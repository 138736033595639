import React from 'react';
import { useTranslationsContext } from 'app/translations';

import { Container, Text, Logo, Icon } from './styles';

const logo = require('assets/images/avag.svg');
const maintenanceIcon = require('assets/icons/maintenance.svg');

const MaintenancePage = () => {
  const { maintenance_text } = useTranslationsContext();
 
  return (
    <Container>
      <Logo src={ logo } />
      <Text>
        { maintenance_text }
      </Text>
      <Icon src={ maintenanceIcon } />
    </Container>
  );
};
export default MaintenancePage;
