import React from 'react';
import { useTranslationsContext } from 'app/translations';
import Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import HelpButton from 'components/HelpButton';
import { HelpTopic } from 'containers/Help/types';
import { Page, CardContent, TextParagraph, HelpButtonWrapper, AsideListItem } from './styles';

const AsideSection: React.FC = () => {
  const { pickupRequestDetails_noteSectionTitle,
    template_aside_section_title,
    pickupRequestDetails_noteSectionInfo4,
    pickupRequestDetails_noteSectionInfo5,
    template_aside_section_header_1,
    template_aside_section_header_2,
    template_aside_section_header_3, 
    template_aside_section_header_4, 
    template_aside_section_Terminregler_list_1,
    template_aside_section_Terminregler_list_2,
    template_aside_section_Terminregler_list_3,
    template_aside_section_Terminregler_list_4,
    template_aside_section_Terminregler_detail, } = useTranslationsContext();

  return (
    <Page>
      <SectionTitle>
        { pickupRequestDetails_noteSectionTitle }
        <HelpButtonWrapper>
          <HelpButton topic={ HelpTopic.PICKUPS } />
        </HelpButtonWrapper>
      </SectionTitle>

      <Card indentation={ true }>
        <CardContent>
          <TextParagraph>
            { template_aside_section_title }</TextParagraph>
          <TextParagraph><strong>{ template_aside_section_header_1 }: </strong>
            { pickupRequestDetails_noteSectionInfo4 }.</TextParagraph>
          <TextParagraph><strong>{ template_aside_section_header_2 }: </strong>
            { template_aside_section_Terminregler_detail }
            <TextParagraph>
              <ul>
                <AsideListItem>{ template_aside_section_Terminregler_list_3 }</AsideListItem>
                <AsideListItem>{ template_aside_section_Terminregler_list_1 }</AsideListItem>
                <AsideListItem>{ template_aside_section_Terminregler_list_2 }</AsideListItem>
              </ul>
            </TextParagraph>
          </TextParagraph>
          <TextParagraph><strong>{ template_aside_section_header_3 }: </strong>
            { pickupRequestDetails_noteSectionInfo5 }.</TextParagraph>
          <TextParagraph><strong>{ template_aside_section_header_4 }: </strong>
            { template_aside_section_Terminregler_list_4 }.</TextParagraph>
        </CardContent>
      </Card>
    </Page>
  );
};

export default AsideSection;
