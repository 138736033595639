import { Action } from 'app/types';

export enum ActionTypes {
  GET_BILL_LIST_REQUEST = 'GET_BILL_LIST_REQUEST',
  GET_BILL_LIST_SUCCESS = 'GET_BILL_LIST_SUCCESS',
  GET_BILL_LIST_ERROR = 'GET_BILL_LIST_ERROR',

  GET_BILL_REQUEST = 'GET_BILL_REQUEST',
  GET_BILL_SUCCESS = 'GET_BILL_SUCCESS',
  GET_BILL_ERROR = 'GET_BILL_ERROR',

  GET_BILLS_EXPORT_DATA_REQUEST = 'GET_BILLS_EXPORT_DATA_REQUEST',
  GET_BILLS_EXPORT_DATA_SUCCESS = 'GET_BILLS_EXPORT_DATA_SUCCESS',
  GET_BILLS_EXPORT_DATA_ERROR = 'GET_BILLS_EXPORT_DATA_ERROR',

  PRINT_BILL_REQUEST = 'PRINT_BILL_REQUEST',
  PRINT_BILL_SUCCESS = 'PRINT_BILL_SUCCESS',
  PRINT_BILL_ERROR = 'PRINT_BILL_ERROR',


  RESET_BILLS = 'RESET_BILLS'
}

type GetBillListRequest = Action<ActionTypes.GET_BILL_LIST_REQUEST>;
type GetBillListSuccess = Action<ActionTypes.GET_BILL_LIST_SUCCESS>;
type GetBillListError = Action<ActionTypes.GET_BILL_LIST_ERROR>;

type GetBillRequest = Action<ActionTypes.GET_BILL_REQUEST>;
type GetBillSuccess = Action<ActionTypes.GET_BILL_SUCCESS>;
type GetBillError = Action<ActionTypes.GET_BILL_ERROR>;

type GetBillsExportDataRequest = Action<ActionTypes.GET_BILLS_EXPORT_DATA_REQUEST>;
type GetBillsExportDataSuccess = Action<ActionTypes.GET_BILLS_EXPORT_DATA_SUCCESS>;
type GetBillsExportDataError = Action<ActionTypes.GET_BILLS_EXPORT_DATA_ERROR>;

type PrintBillRequest = Action<ActionTypes.PRINT_BILL_REQUEST>;
type PrintBillSuccess = Action<ActionTypes.PRINT_BILL_SUCCESS>;
type PrintBillError = Action<ActionTypes.PRINT_BILL_ERROR>;

type ResetBills = Action<ActionTypes.RESET_BILLS>;

type GetBillListActionType = GetBillListRequest | GetBillListSuccess | GetBillListError;
type GetBillActionType = GetBillRequest | GetBillSuccess | GetBillError;
type GetBillsExportType = GetBillsExportDataRequest | GetBillsExportDataSuccess | GetBillsExportDataError;
type PrintBillActionType = PrintBillRequest | PrintBillSuccess | PrintBillError;

export type BillActionType = 
  | GetBillListActionType 
  | GetBillActionType 
  | GetBillsExportType 
  | PrintBillActionType 
  | ResetBills;
