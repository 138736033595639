import { getChartBackgrounds } from 'base/styles/skin';
import { FILL_EMPTY_DAYS } from 'app/constants/localStorage';
import { DimensionChartOptions } from 'app/constants/statistic';
import * as localStorage from 'app/utils/localStorage';
import { DataFilterValues, Statistic, StatisticData, ChartData, ChartMetadata } from 'containers/Statistics/types';
import { getFilteredMetricUnits, filterListByMaterial, filterNoWeightItems } from './filterables';
import { getDataGroupedByMaterial,
  getMaxAndMinDimensions,
  getMinimumBarWidth,
  getDataGroupedByDate } from './selectables';
import { getColorIn } from '../colors';
import { formatDate } from '../formatters';
import { howManyDays, getSomeDaysAgo } from '../date';

const fillEmptyDays = (data: StatisticData[], filters: DataFilterValues): StatisticData[] => {
  const shouldFill = localStorage.getItem(FILL_EMPTY_DAYS);
  if (shouldFill === 'false' || !data.length) {
    return data;
  }

  const days = howManyDays(new Date(filters.startDate), new Date(filters.endDate));

  return new Array(days).fill(null).map((_, index) => {
    const date = getSomeDaysAgo(new Date(filters.endDate), days - index - 1);
    const dailyRecord = data.find(record => formatDate(new Date(record.date)) === formatDate(date));

    return {
      // Pollyfill with first item
      ...data[0],

      // Selected date
      date: date.getTime(),
      isPolyfill: !dailyRecord,

      // Set values for today
      expected: dailyRecord?.expected || [0, 0],
      values: dailyRecord?.values || [0, 0]
    };
  });
};

interface TotalFlags {
  dimensions: string[];
  isAreaChart: boolean;
  isOneDimension: boolean;
  minCost: number;
  maxCost: number;
  maxWeight: number;
}

const transformStatisticDataToChartData = (
  { date, id, isPolyfill, material, expected, values }: StatisticData,
  index: number,
  { dimensions, isAreaChart, isOneDimension, minCost, maxCost, maxWeight }: TotalFlags,
  colors: string[],
  // eslint-disable-next-line max-params
): ChartData => {
  const dataModel = {
    cost: expected[0] + values[0],
    weight: expected[1]
  };

  let height = 0;
  let width;
  const isWeight = isOneDimension && dimensions.includes('KG');

  // Two dimensions
  if (!isOneDimension) {
    // Setup width based on weight (absolute value)
    width = getMinimumBarWidth(dataModel.weight);
  }

  if (isAreaChart) {
    // Areachart needs absolute heights
    height = isWeight ? dataModel.weight : dataModel.cost;
  } else if (isWeight) {
    // Set height based on weight (relative positive value)
    height = (dataModel.weight * 100) / maxWeight;
  } else if (dataModel.cost >= 0) {
    // Set height based on cost (relative positive value)
    height = (dataModel.cost * 100) / maxCost;
  } else {
    // Set height based on cost (relative negative value)
    height = (dataModel.cost * 100) / Math.abs(minCost);
  }

  return {
    id,
    title: material.name,
    date,
    groupId: material.id,
    isPolyfill,
    ...dataModel,
    color: getColorIn(colors, index),
    height,
    width
  };
};

interface ReturnValue {
  data: ChartData[];
  meta: ChartMetadata;
}

export const createStatisticsData = (
  statisticData: Statistic,
  mode: DimensionChartOptions,
  filters: DataFilterValues
): ReturnValue => {
  const { data = [], metricUnits = [] } = statisticData || {};

  // Setup an array with unit names
  const dimensionUnits = getFilteredMetricUnits(metricUnits, filters);
  const isAreaChart = mode === DimensionChartOptions.fraction;
  const aggregatedData = isAreaChart
    ? getDataGroupedByDate(filterListByMaterial(data, filters.material))
    : getDataGroupedByMaterial(data);
  const nonZeroWeightData = filterNoWeightItems(aggregatedData);
  const filledEmptyDatesData = isAreaChart ? fillEmptyDays(nonZeroWeightData, filters) : nonZeroWeightData;

  // Get some useful flags
  const { minCost, maxCost, maxWeight } = getMaxAndMinDimensions(filledEmptyDatesData, dimensionUnits);
  
  const colors = getChartBackgrounds();
  const isOneDimension = dimensionUnits.length === 1;
  const chartData = filledEmptyDatesData.map((record, index) =>
    transformStatisticDataToChartData(record, index, {
      dimensions: dimensionUnits,
      isAreaChart,
      isOneDimension,
      minCost,
      maxCost,
      maxWeight
    }, colors));

  return {
    data: chartData,
    meta: { minCost, maxCost, maxWeight }
  };
};
