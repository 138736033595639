import * as React from 'react';

import Content from '../../../Touchable';
import { HiddenInput } from '../styles';
import { StyledWrapper as Wrapper,
  StyledLabel as Label,
  StyledDescription as Description,
  CustomSwitch } from './styles';

import { FieldProps } from '../../types';

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  options: FieldProps;
}

const Switch: React.FC<Props> = ({ onChange, onClick, onFocus, options }) => (
  <Wrapper>
    <Label htmlFor={ options.id }>
      <HiddenInput
        id={ options.id }
        name={ options.id }
        defaultChecked={ options.isChecked }
        disabled={ options.isDisabled }
        onChange={ onChange }
        onClick={ onClick }
        onFocus={ onFocus }
        required={ options.isRequired }
        type="checkbox"
      />

      <Description>
        <CustomSwitch checked={ options.isChecked } />
        <Content options={ options } />
      </Description>
    </Label>
  </Wrapper>
);

export default Switch;
