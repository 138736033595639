import produce from 'immer';
import { createReducer, isMockingApi } from 'base';
import { ActionHandler } from 'base/types';
import { ActionTypes as LogoutActions } from 'containers/Login/actionTypes';
import { Material } from 'containers/PickupRequest/types';
import { ActionTypes, StatisticsActionType } from '../actionTypes';
import { StatisticsState, Statistic } from '../types';
import { statisticsInitialState } from '../models';
import { STATISTIC_LIST, MATERIAL_OPTIONS } from '../mocks';

const initialStatistic: Statistic = { data: [], metricUnits: [] };

const getStatisticsSuccess = (state: StatisticsState, action: StatisticsActionType) =>
  produce(state, draft => {
    const statistics = action.payload as Statistic;
    draft.collection = [statistics];
    if (state.collection[1]) draft.collection.push(state.collection[1]);
  });

const getStatisticsError = (state: StatisticsState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.collection = [STATISTIC_LIST[0]];
      if (state.collection[1]) draft.collection.push(state.collection[1]);
    }
  });

const getStatisticsComparisionSuccess = (state: StatisticsState, action: StatisticsActionType) =>
  produce(state, draft => {
    const comparison = action.payload as Statistic;
    draft.collection = [state.collection[0], comparison];
  });

const getStatisticsComparisionError = (state: StatisticsState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.collection = [state.collection[0], STATISTIC_LIST[1]];
    }
  });

const enableComparision = (state: StatisticsState) =>
  produce(state, draft => {
    draft.collection.push(draft.collection[0]);
  });

const disableComparision = (state: StatisticsState) =>
  produce(state, draft => {
    draft.collection = [draft.collection[0]];
  });

const resetStatistics = (state: StatisticsState) =>
  produce(state, draft => {
    const comparision = state.collection[1];
    if (comparision) {
      draft.collection = [initialStatistic, comparision];
    } else {
      draft.collection = [initialStatistic];
    }
  });

const resetStatisticsComparision = (state: StatisticsState) =>
  produce(state, draft => {
    const comparision: Statistic | undefined = state.collection[1];
    if (comparision) {
      draft.collection = [state.collection[0], initialStatistic];
    }
  });

const getInitialState = (state: StatisticsState) =>
  produce(state, draft => {
    Object.keys(draft).forEach(key => {
      // @ts-ignore
      draft[key] = statisticsInitialState[key];
    });
  });

const getStatisticsMaterialsSuccess = (state: StatisticsState, action: StatisticsActionType) =>
  produce(state, draft => {
    const materials = action.payload as Material[];
    draft.materials = materials;
  });

const getStatisticsMaterialsError = (state: StatisticsState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.materials = MATERIAL_OPTIONS;
    }
  });

const actionHandlers: ActionHandler<StatisticsState> = {
  [ActionTypes.GET_STATISTICS_SUCCESS]: getStatisticsSuccess,
  [ActionTypes.GET_STATISTICS_ERROR]: getStatisticsError,
  [ActionTypes.GET_STATISTICS_COMPARISION_SUCCESS]: getStatisticsComparisionSuccess,
  [ActionTypes.GET_STATISTICS_COMPARISION_ERROR]: getStatisticsComparisionError,
  [ActionTypes.ENABLE_COMPARISION]: enableComparision,
  [ActionTypes.DISABLE_COMPARISION]: disableComparision,
  [ActionTypes.RESET_STATISTICS]: resetStatistics,
  [ActionTypes.RESET_STATISTICS_COMPARISION]: resetStatisticsComparision,
  [ActionTypes.GET_STATISTICS_MATERIALS_SUCCESS]: getStatisticsMaterialsSuccess,
  [ActionTypes.GET_STATISTICS_MATERIALS_ERROR]: getStatisticsMaterialsError,
  [LogoutActions.LOGOUT_REQUEST]: getInitialState
};

export default createReducer(statisticsInitialState, actionHandlers);
