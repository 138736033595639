export enum TimePeriod {
  timePeriod_flexible = 1,
  timePeriod_morning,
  timePeriod_afternoon,
}

export enum DriverOrderStatus {
  driver_order_status_new = 1,
  driver_order_status_progress,
  driver_order_status_proceed,
}

export const DESTINATION_OPTIONS = [
  'EZ/KVA Thun',
  'EZ Jaberg',
  'EZ Wimmis',
  'Biomassen Zentrum',
  'EZ Brienz',
  'EZ Interlaken',
  'EZ Frutigen',
  'EZ Zweisimmen',
  'EZ Saanen',
  'EZ Langnau',
  'Halle 683',
  'Kaiser August',
  // 'Oberland Energie AG',  // comment it as client it remove this option
  'Perlen Papier AG',
  'SOGES Wimmis',
  'Sonstige Standorte',
  'Utzenstorf',
];

export enum PickupRequestStatus {
  NEW =1,
  IN_PROCESS,
  DRAFT,
  PROCESSED,
  CONFIRMED,
  NO_NAV = 6,
}

export enum PickupAttachedFile {
  COMMENTARY = 1,
  DRIVER_INFO,
}
export enum EveryWeekPeriod {
  every_monday = 1,
  every_tuesday,
  every_wednesday,
  every_thursday,
  every_friday,

}

export enum DateRuleDropdown {
  date_rule_datum_wahlen = 1,
  date_rule_haute,
  date_rule_haute_1,
  date_rule_haute_2,
  date_rule_haute_3,
  date_rule_haute_4,
  date_rule_haute_5,

}
