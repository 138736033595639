import styled from 'styled-components';
import { STATUS_BACKGROUND, STATUS_TEXT } from 'base/styles/skin';

interface StyledProps {
  isBold?: boolean;
  inlineText?: boolean;
}

export const Container = styled.div`
  padding: 12px 10px;
  //box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.1);
  background-color: ${ STATUS_BACKGROUND };
  border-radius: 5px;
  display: flex;
  align-items: center;
`;
Container.displayName = 'Container';

export const StatusIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;
StatusIcon.displayName = 'StatusIcon';

export const TextWrapper = styled.div`
  display: ${ ({ inlineText }: StyledProps) => (inlineText ? 'inline' : 'flex') };
  flex-direction: column;
  padding-left: 11px;
  color: ${ STATUS_TEXT };
  font-size: 14px;
`;
TextWrapper.displayName = 'TextWrapper';

export const Text = styled.span`
  font-weight: ${ ({ isBold }: StyledProps) => (isBold ? 'bold' : 'normal') };
`;
Text.displayName = 'Text';
