import styled from 'styled-components';
import { DESKTOP } from 'base/styles/media-queries';
import { FIELD_BORDER_COLOR,
  FIELD_COLOR,
  FIELD_LABEL,
  FIELD_BACKGROUND,
  FIELD_PLACEHOLDER_COLOR,
  ERROR_BORDER_COLOR,
  ERROR_COLOR,
  FIELD_BORDER_COLOR_DISABLED } from 'base/styles/skin';

export const CONTROL_SIZE = 24;
export const RADIO_SIZE = 14;

interface Props {
  isError?: boolean;
  isDisabled?: boolean;
}

export const Wrapper = styled.div`
  color: ${ FIELD_COLOR };
  display: inline-block;
  font-size: 14px;
  margin: 5px 0;
  padding: 5px 0;
  width: 100%;

  & > .react-datepicker-wrapper {
    display: flex;
    flex: 1;
  }

  .react-datepicker__portal {
    align-items: flex-start;
    align-items: initial;
    padding-top: 20px;

    .react-datepicker__header > div:nth-child(2) > div {
      min-width: 110px;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.label`
  color: ${ FIELD_LABEL };
  cursor: default;
  display: block;
  font-size: 12px;
  padding-left: 10px;
`;
Label.displayName = 'Label';

export const Input = styled.input`
  appearance: none;
  background-color: ${ FIELD_BACKGROUND };
  border-radius: 5px;
  border: solid 1px ${ ({ isError }: Props) => (isError ? ERROR_BORDER_COLOR : FIELD_BORDER_COLOR) };
  color: ${ ({ isError }: Props) => (isError ? ERROR_COLOR : 'inherit') };
  cursor: inherit;
  display: block;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.43;
  min-height: 30px;
  padding: 5px 10px;
  width: 100%;

  user-select: initial;
  -webkit-touch-callout: initial;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;

  &:disabled {
    border-radius: 0px;
    border: none;
    border-bottom: solid 1px ${ FIELD_BORDER_COLOR_DISABLED };
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${ FIELD_PLACEHOLDER_COLOR };
    font-size: 12px;

    ${ DESKTOP } {
      font-size: 14px;
    }
  }
`;
Input.displayName = 'Input';

export const HiddenInput = styled.input`
  display: none;
`;
HiddenInput.displayName = 'HiddenInput';

export const Description = styled.div`
  align-items: center;
  cursor: inherit;
  display: flex;
  line-height: ${ CONTROL_SIZE }px;
  white-space: nowrap;
`;
Description.displayName = 'Description';

export const ErrorMessage = styled.span`
  border: none;
  display: flex;
  align-items: center;
  padding: 5px 0px;  // changed padding 10px to 0px 
  color: red;
`;
ErrorMessage.displayName = 'ErrorMessage';

export const Icon = styled.img`
  width: 0px;  // change width, height and min-width:0 from 30px to hide 
  height: 0px;  // alert icon for now 
  cursor: ${ ({ isDisabled }: Props) => (isDisabled ? 'default' : 'pointer') };
  object-fit: contain;
  min-width: 0px;
`;
Icon.displayName = 'Icon';
