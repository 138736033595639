import React, { useRef, useCallback } from 'react';
import { FieldProps } from '../SmartForm/types';
import { SearchWrapper, SearchButton, IconSearch, InputSearch } from './styles';

interface Options extends FieldProps {
  minLength?: number;
  maxLength?: number;
}

interface Props {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>, value?: string | void) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  options: Partial<Options>;
  value?: string;
}

const searchIcon = require('assets/icons/lens.svg');

const Searcher: React.FC<Props> = ({ onChange, onBlur, onClick, onFocus, options, value = '' }) => {
  const inputEl = useRef<HTMLInputElement>();

  const handleClick = useCallback(
    event => {
      onClick(event, inputEl.current.value);
    },
    [inputEl.current?.value]
  );

  return (
    <SearchWrapper isDisabled={ options.isDisabled } isError={ !!options.error }>
      <InputSearch
        ref={ inputEl }
        id={ options.id }
        name={ options.id }
        value={ value }
        disabled={ options.isDisabled }
        maxLength={ options.maxLength }
        minLength={ options.minLength }
        onChange={ onChange }
        onBlur={ onBlur }
        onFocus={ onFocus }
        size={ options.placeholder?.length }
        placeholder={ options.placeholder }
        required={ options.isRequired }
      />
      { !options.isDisabled && (
        <SearchButton type="button" onClick={ handleClick }>
          <IconSearch src={ searchIcon } />
        </SearchButton>
      ) }
    </SearchWrapper>
  );
};

export default Searcher;
