import { useTranslationsContext } from 'app/translations';
import { ContainerAddon, AddonType } from '../types';

interface PickupOption {
  pickup?: number;
}

interface DeliverOption {
  deliver?: number;
}

export const renderAddonValue = (
  translations: ReturnType<typeof useTranslationsContext>,
  addon: ContainerAddon,
  containerAddons: ContainerAddon[] = [],
  options?: PickupOption | DeliverOption
  // eslint-disable-next-line max-params
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addonOptions = containerAddons.find(c => c.addonId === addon.addonId)?.options || [];

  const { pickupRequestDetails_toPickup,
    pickupRequestDetails_toDeliver,
    pickupRequestDetails_perContainer } = translations;

  switch (addon.type) {
    case AddonType.bool:
      
      if (addon.defaultValue) {
        // eslint-disable-next-line no-restricted-syntax
        if (options && 'pickup' in options) {
          return `${ options.pickup } ${ pickupRequestDetails_toPickup }`;
        }

        // eslint-disable-next-line no-restricted-syntax
        if (options && 'deliver' in options) {
          return `${ options.deliver } ${ pickupRequestDetails_toDeliver }`;
        }

        return `1 ${ addon.name } ${ pickupRequestDetails_perContainer }`;
      }

      return undefined;

    case AddonType.number:
      
      if (addon.defaultValue) {
        // eslint-disable-next-line no-restricted-syntax
        if (options && 'pickup' in options) {
          return `${ options.pickup * parseInt(addon.defaultValue.toString(), 10) } ${ pickupRequestDetails_toPickup }`;
        }

        // eslint-disable-next-line no-restricted-syntax
        if (options && 'deliver' in options) {
          // eslint-disable-next-line max-len
          return `${ options.deliver * parseInt(addon.defaultValue.toString(), 10) } ${ pickupRequestDetails_toDeliver }`;
        }

        return `${ addon.defaultValue } ${ addon.name } ${ pickupRequestDetails_perContainer }`;
      }

      return undefined;

    case AddonType.dropdown:
      
      // eslint-disable-next-line no-restricted-syntax
      if (options && 'pickup' in options) {
        
        return `${ options.pickup } ${ pickupRequestDetails_toPickup }`;
      }

      // eslint-disable-next-line no-restricted-syntax
      if (options && 'deliver' in options) {
       
        return `${ options.deliver } ${ pickupRequestDetails_toDeliver }`;
      }

      return `1 ${ addon.name } ${ pickupRequestDetails_perContainer }`;
    
    default:
      return '';
  }
};
