import styled from 'styled-components';

import { TABLET, DESKTOP } from 'base/styles/media-queries';
import { LOGIN_LOGO_SIZE, LOGIN_LOGO_SIZE_TABLET, LOGIN_LOGO_SIZE_DESKTOP } from 'base/styles/sizes';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;

  ${ TABLET } {
    margin: 0 50px;
  }

  ${ DESKTOP } {
    margin: 0 200px;
  }
`;
Container.displayName = 'Container';

export const Logo = styled.img`
  height: ${ LOGIN_LOGO_SIZE }px;

  ${ TABLET } {
    height: ${ LOGIN_LOGO_SIZE_TABLET }px;
  }

  ${ DESKTOP } {
    height: ${ LOGIN_LOGO_SIZE_DESKTOP }px;
  }
  width: auto;
`;
Logo.displayName = 'Logo';

export const Text = styled.div`
  font-size: 20px;
  margin: 30px 0;
  text-align: left;
  white-space: pre-wrap;

  ${ TABLET } {
    font-size: 22px; 
  }
`;
Text.displayName = 'Text';

export const Icon = styled.img`
  width: 64px;
  height: 64px;
  object-fit: contain;
`;
Icon.displayName = 'Icon';
