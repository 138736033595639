import styled from 'styled-components';
import {
  FIELD_BORDER_COLOR,
  FIELD_BACKGROUND,
  FIELD_PLACEHOLDER_COLOR,
  BUTTON_PRIMARY_BACKGROUND,
  BUTTON_PRIMARY_COLOR,
  FIELD_BORDER_COLOR_DISABLED, BUTTON_COLOR_HOVER
} from 'base/styles/skin';
import { DESKTOP } from 'base/styles/media-queries';

interface StyledProps {
  isDisabled?: boolean;
}

export const InputFile = styled.label`
  appearance: none;
  background-color: ${ FIELD_BACKGROUND };
  color: inherit;
  border-radius: ${ ({ isDisabled }: StyledProps) => (isDisabled ? '0' : '4px') };
  border: ${ ({ isDisabled }: StyledProps) => (isDisabled ? 'none' : `solid 1px ${ FIELD_BORDER_COLOR }`) };
  border-bottom: ${ ({ isDisabled }: StyledProps) => isDisabled && `solid 1px ${ FIELD_BORDER_COLOR_DISABLED }` };
  cursor: ${ ({ isDisabled }: StyledProps) => (isDisabled ? 'default' : 'pointer') };
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  //font-family: Roboto;
  font-family: 'TT Norms';
  font-size: 14px;
  line-height: 1.43;
  min-height: 30px;
  max-height: 33px;
  padding-left: 10px;
  width: 100%;
  margin-bottom: 20px;

  user-select: initial;
  -webkit-touch-callout: initial;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
    
`;
InputFile.displayName = 'InputFile';

export const Placeholder = styled.span`
  color: ${ FIELD_PLACEHOLDER_COLOR };
  font-size: 12px;
  ${ DESKTOP } {
    font-size: 14px;
  }
`;
Placeholder.displayName = 'Placeholder';

export const Input = styled.input`
  display: none;
`;
Input.displayName = 'Input';

export const InputButton = styled.span`
  background-color: ${ BUTTON_PRIMARY_BACKGROUND };
  border: none;
  color: ${ BUTTON_PRIMARY_COLOR };
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 4px;
  cursor: pointer;
  max-height: 30px;

  &:focus {
    outline: none;
  }
    &:hover{
        background-color: ${ BUTTON_COLOR_HOVER };
    }
`;
InputButton.displayName = 'InputButton';

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;
Icon.displayName = 'Icon';

export const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;

  > :last-child {
    margin-right: 0;
  }
`;
Files.displayName = 'Files';
