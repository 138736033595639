import React from 'react';
import styled from 'styled-components';
import DefaultButtons from 'components/Buttons';
import { SKELETON_BACKGROUND } from 'base/styles/skin';

const StyledButtons = styled.div`
  button {
    &,
    &:hover:enabled {
      background: ${ SKELETON_BACKGROUND };
      border-color: ${ SKELETON_BACKGROUND };
      color: ${ SKELETON_BACKGROUND };
      cursor: default;
      min-width: 100px;
    }
  }
`;

interface Props {
  align?: 'left' | 'center' | 'right';
  count?: number;
}

const Buttons: React.FC<Props> = ({ align = 'right', count = 1 }) => {
  const buttons = [];

  for (let x = 1; x <= count; x += 1) {
    buttons.push({
      id: `skeleton-btn-${ x }`,
      label: '...',
      type: 'button' as 'button'
    });
  }

  return (
    <StyledButtons>
      <DefaultButtons align={ align } options={ buttons } />
    </StyledButtons>
  );
};

export default Buttons;
