import React from 'react';
import { RendererProps } from 'components/Table/types';
import { Container, Icon } from '../../styles';

const veva = require('assets/icons/veva-doc.svg');

const VevaDoc = <R extends unknown>({ cell }: RendererProps<R>): React.ReactElement | null =>
  cell && (
    <Container>
      <Icon src={ veva } />
    </Container>
  );

export default VevaDoc;
