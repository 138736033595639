import React from 'react';
import Checkbox from 'components/SmartForm/components/checkbox';
import { FieldProps } from 'components/SmartForm/types';
import { CheckboxWrapper } from './styles';

interface Props {
  options: FieldProps;
  value: boolean;
}

const CheckboxSettings: React.FC<Props> = ({ options, value }) => {
  const emptyFn = () => {};

  return (
    <CheckboxWrapper>
      <Checkbox
        options={ { ...options, isChecked: value } }
        value={ value }
        onChange={ () => {
          options.onChange();
        } }
        onClick={ emptyFn }
        onBlur={ emptyFn }
        onFocus={ emptyFn }
        setFieldValue={ emptyFn }
      />
    </CheckboxWrapper>
  );
};

export default CheckboxSettings;
