import styled from 'styled-components';

export const Wrapper = styled.div``;
Wrapper.displayName = 'Wrapper';

export const Title = styled.p`
  font-weight: bold;
  padding-bottom: 12px;
`;
Title.displayName = 'Title';

export const Message = styled.p`
  padding-bottom: 12px;
`;
Message.displayName = 'Message';
