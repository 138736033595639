import React from 'react';
import { formatDate } from 'app/utils/formatters';
import { RendererProps } from '../../types';

export const DateTime = <R extends unknown>({ cell }: RendererProps<R>): React.ReactElement | null => {
  if (typeof cell === 'boolean') {
    return null;
  }
  const parsed = typeof cell === 'string' || typeof cell === 'number' ? new Date(cell) : cell;

  return <p>{ formatDate(parsed, true) }</p>;
};

const DateRenderer = <R extends unknown>({ cell }: RendererProps<R>): React.ReactElement | null => {
  if (typeof cell === 'boolean') {
    return null;
  }
  const parsed = typeof cell === 'string' || typeof cell === 'number' ? new Date(cell) : cell;

  return <p>{ formatDate(parsed) }</p>;
};

export default DateRenderer;
