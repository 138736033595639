import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as ROUTES from 'app/routes';
import { RouteWithID, RouteWithToken } from 'app/types';

import Navigation from './components/Navigation';

const allRoutes = Object.values(ROUTES);

const Breadcrumbs: React.FC = () => {
  const match = useRouteMatch<RouteWithID | RouteWithToken>();
  const matchingRoutes = allRoutes
    .filter(route => match.isExact && match.path.includes(route.path))
    .map(({ path: currentPath, ...rest }) => ({
      path: Object.keys(match.params).length
        ? Object.keys(match.params).reduce((path, param) => {
          return path.replace(`:${ param }`, match.params[param]);
        }, currentPath)
        : currentPath,
      ...rest
    }));

  if (matchingRoutes?.length) {
    matchingRoutes[0].path = ROUTES.HOME.path;
  }

  return <Navigation links={ matchingRoutes } />;
};

export default Breadcrumbs;
