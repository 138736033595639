import { FormikValues } from 'formik';
import React, { useState } from 'react';
import { useTranslationsContext } from 'app/translations';
import { applyDateLimits } from 'app/utils/validations';
import Modal from 'components/Modal';
import SmartForm from 'components/SmartForm';
import { FieldProps } from 'components/SmartForm/types';
import { useButtons } from './data/buttons';
import { useFields, ExportFieldName } from './data/fields';
import { useValidationSchema } from './data/validation';
import { TextDescription, FormWrapper, FieldWrapper, Body, Footer } from './styles';

interface Props {
  onConfirm: (values: FieldProps[]) => void;
  onDismiss: () => void;
}

const ExportModal: React.FC<Props> = ({ onConfirm, onDismiss }) => {
  const [values, setValues] = useState<FormikValues>();
  const translations = useTranslationsContext();
  const fields = applyDateLimits(useFields(), values);
  const buttons = useButtons(onDismiss);
  const formInitialValues = { format: fields.find(f => f.id === ExportFieldName.format)?.options[0].value };

  return (
    <Modal onClose={ onDismiss } header={ translations.deliverySlip_exportModalTitle } size="auto">
      <>
        <TextDescription>{ translations.deliverySlip_exportModalDescription }</TextDescription>
        <SmartForm
          form={ 'exportData' }
          buttons={ buttons }
          fields={ fields }
          validationSchema={ useValidationSchema() }
          handleSubmit={ v => onConfirm(v) }
          initialValues={ formInitialValues }
        >
          { ({ controlledFields, controlledButtons, Field, Buttons, values: vals }) => {
            setValues(vals);

            return (
              <>
                <Body>
                  <FormWrapper>
                    { controlledFields.map((field, index) => (
                      <FieldWrapper key={ index }>
                        <Field options={ field } />
                      </FieldWrapper>
                    )) }
                  </FormWrapper>
                </Body>
                <Footer>
                  <Buttons options={ controlledButtons } />
                </Footer>
              </>
            );
          } }
        </SmartForm>
      </>
    </Modal>
  );
};

export default ExportModal;
