import { RootState } from 'base/types';
import { REDUX_NODE } from './constants';
import { State, ModifiedPromise } from './types';

const isPending = (req: ModifiedPromise<any>) => req.isPending();

export const isAnyRequest = (rootState: RootState) => (requestNames?: string[]) => {
  // @ts-ignore
  const state: State = rootState[REDUX_NODE];
  const checkedArray = requestNames || Object.keys(state);
  return checkedArray.some(requestName => state?.[requestName]?.some(isPending));
};
