/* eslint-disable no-restricted-imports */
import React from 'react';
import Button from './do-not-export/Button';
import DateRenderer, { DateTime } from './do-not-export/Date';
import ArrowButton from './do-not-export/ArrowButton';
import NumberRenderer from './do-not-export/Number';
import CurrencyRenderer from './do-not-export/Currency';
import { RendererProps } from '../types';

const Default = <R extends unknown>({ cell }: RendererProps<R>): React.ReactElement | null => {
  if (typeof cell !== 'boolean' && typeof cell !== 'number' && typeof cell !== 'string' && cell?.getTime) {
    return <DateRenderer cell={ cell } />;
  }

  if (typeof cell === 'number') {
    return <NumberRenderer cell={ cell } />;
  }

  return <p>{ cell }</p>;
};

export { Button, DateRenderer as Date, DateTime, Default, ArrowButton, CurrencyRenderer as Currency };
