import styled, { css } from 'styled-components';
import { TABLET, MAX_MOBILE_XXL } from 'base/styles/media-queries';
import Card from 'components/Card';

const hideCard = css`
  background-color: transparent;
  border: none;
  box-shadow: none;
`;

export const CardContent = styled(Card)`
  ${ MAX_MOBILE_XXL } {
    ${ hideCard }
  }

  ${ TABLET } {
    & > div:first-child > div {
      ${ hideCard }
    }
  }
`;
CardContent.displayName = 'CardContent';

export const ButtonsWrapper = styled.div`
  justify-content: center;
  display: flex;
  padding: 8px 12px;

  & > div > button {
    margin: 4px;
    max-width: none;
  }

  ${ TABLET } {
    justify-content: flex-end;
  }
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';
