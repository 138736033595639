import React from 'react';
import { Create, Filter } from '../actions';
import * as renderers from '../renderers';
import { matches } from '../search-engine';
import { ActionWrapper, TableHeading, SortArrow, TableData, TableRow } from '../styles';
import { Column, SortingOptions } from '../types';

const sortArrow = require('assets/icons/sort-arrow.svg');

interface RenderActionProps {
  action: string | React.ReactNode;
  index: number;
  handleFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const renderAction: React.FC<RenderActionProps> = ({ action, index, handleFilter }) => {
  if (typeof action === 'string') {
    switch (action) {
      case 'create':
        return (
          <ActionWrapper key={ action }>
            <Create />
          </ActionWrapper>
        );

      case 'filter':
        return (
          <ActionWrapper key={ action }>
            <Filter onChange={ handleFilter } />
          </ActionWrapper>
        );

      default:
        return null;
    }
  }

  return <ActionWrapper key={ index }>{ action }</ActionWrapper>;
};

export const renderHeadings = <R extends unknown>(
  columns: Column<R>[],
  showTitle: boolean,
  sortingOptions?: SortingOptions<R>
): React.ReactElement | null => (
    <>
      { columns.map(({ key, mobile = false, label }) => (
        <TableHeading
          active={ sortingOptions?.sortedBy === key }
          key={ `th-${ key }` }
          mobile={ mobile }
          showTitle={ showTitle }
          onClick={
            label && sortingOptions
              ? () => {
                if (sortingOptions.sortedBy === key) {
                  sortingOptions.setReverse(!sortingOptions.reverse);
                } else {
                  sortingOptions.setSortedBy(key);
                  sortingOptions.setReverse(false);
                }
              }
              : undefined
          }
        >
          { label }
          { label && sortingOptions && ' ' }
          { label && sortingOptions && (
            <SortArrow
              reverse={ sortingOptions.sortedBy === key && sortingOptions.reverse }
              alt={ `Sort by ${ label }` }
              src={ sortArrow }
            />
          ) }
        </TableHeading>
      )) }
    </>
  );

interface CellProps<R> {
  columns: Column<R>[];
  index: number;
  filterValue: string;
  row: R;
}

const renderCells = <R extends unknown>({ columns,
  index,
  filterValue,
  row }: CellProps<R>): React.ReactElement | null => (
    <>
      { columns.map(({ key, mobile = false, renderer = renderers.Default, isLeftAlign }) => {
        const Renderer = renderer;

        // @ts-ignore
        const cell = row[key] as Cell;
        
        return (
          <TableData 
            key={ `row-${ index }-${ key }` } 
            isLeftAlign={ isLeftAlign } 
            mobile={ mobile } 
            active={ matches(cell, filterValue) }
          >
            <Renderer data={ row } cell={ cell } />
          </TableData>
        );
      }) }
    </>
  );

interface RenderRowsProps<R> {
  columns: Column<R>[];
  emptyMessage: string;
  filterValue: string;
  onClick?: (row: R) => void;
  rows: R[];
}

export const renderRows = <R extends unknown>({ columns,
  emptyMessage,
  filterValue,
  onClick,
  rows }: RenderRowsProps<R>): React.ReactElement | null => {
  if (!rows.length) {
    return (
      <TableRow>
        <TableData colSpan={ columns.length } mobile={ true }>
          { emptyMessage }
        </TableData>
      </TableRow>
    );
  }

  return (
    <>
      { rows.map((row, index) => (
        <TableRow
          key={ `row-${ index }` }
          onClick={
            onClick
              ? () => {
                onClick(row);
              }
              : undefined
          }
        >
          { renderCells<R>({ columns, index, filterValue, row }) }
        </TableRow>
      )) }
    </>
  );
};
