import { LangKeys } from 'app/translations';
import { renderers } from 'components/Table';
import { Column } from 'components/Table/types';
import { BillRow } from 'containers/Bills/types';
import TableTag from '../components/Tag';
import DocTypeRenderer from '../components/DocTypeRenderer';

export const createColumns = (translations: LangKeys): Column<BillRow>[] => [
  {
    key: 'id',
    label: translations.billsList_id,
    mobile: true
  },
  {
    key: 'date',
    label: translations.billsList_date,
    renderer: renderers.Date
  },
  {
    key: 'documentType',
    label: translations.billsList_docType,
    renderer: DocTypeRenderer
  },
  {
    key: 'totalAmount',
    label: translations.billsList_amount,
    mobile: true
  },
  {
    key: 'status',
    label: translations.billsList_status,
    renderer: TableTag,
    mobile: true
  },
  {
    key: 'navigate',
    mobile: true
  }
];
