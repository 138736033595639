import styled, { css } from 'styled-components';
import { Link } from 'containers/Login/styles';
import { PRIMARY, TEXT_COLOR } from 'base/styles/skin';
import { DESKTOP } from 'base/styles/media-queries';

const TextStyles = css`
  font-size: 14px;
  font-weight: normal;
  color: ${ TEXT_COLOR };
`;

export const Wrapper = styled.div`
  display: inline-block;
  ${ TextStyles }

  ${ DESKTOP } {
    width: 80%;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.div`
  ${ TextStyles }
`;
Text.displayName = 'Text';

export const OnboardSuccessInfo = styled.div`
  margin-bottom: 30px;
`;
Wrapper.displayName = 'OnboardSuccessInfo';

export const TextLink = styled(Link)`
  &,
  &:link,
  &:visited {
    ${ TextStyles }
    color: ${ PRIMARY };
    text-decoration: underline;
  }
`;
TextLink.displayName = 'TextLink';

export const BackHeaderButton = styled.div`

`;
BackHeaderButton.displayName = 'BackHeaderButton';
