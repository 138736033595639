import React from 'react';
import { DimensionChartOptions } from 'app/constants/statistic';
import { useTranslationsContext } from 'app/translations';
import Buttons from 'components/Buttons';
import SectionTitle from 'components/SectionTitle';
import StatusMessage from 'components/StatusMessage';
import Dropdown from 'components/SmartForm/components/dropdown';
import StatisticsWidget from 'containers/Statistics/components/StatisticsWidget';
import { createDimensionChart } from 'app/containers/Statistics/components/StatisticsWidget/components/Header/data';
import { Container, Wrapper, TopWrapper, ChartWrapper } from './styles';

interface Props {
  isComparing: boolean;
  dimension: DimensionChartOptions;
  onChangeDimension: (newDimension: DimensionChartOptions) => void;
  onDisableComparison: () => void;
  onEnableComparison: () => void;
}

const View: React.FC<Props> = ({ isComparing,
  dimension,
  onChangeDimension,
  onDisableComparison,
  onEnableComparison }) => {
  const translations = useTranslationsContext();

  const dimensionChartOptions = createDimensionChart(translations, 'dimensionChartOptions');

  return (
    <Wrapper>
      <SectionTitle>{ translations.routeName_statistics }</SectionTitle>
      <StatusMessage 
        title={ `${ translations.billDetails_disclaimerTitle }:` }
        description={ translations.billDetails_disclaimerText }
        showIcon={ false }
        inlineText={ true }
      />
      <Container>
        { isComparing && (
          <TopWrapper>
            <Dropdown
              options={ dimensionChartOptions }
              onClick={ () => {} }
              onBlur={ () => {} }
              onFocus={ () => {} }
              setFieldValue={ (_, value) => onChangeDimension(value) }
              onChange={ () => {} }
              value={ dimension }
            />
            <Buttons
              options={ [
                {
                  id: 'disable-comparison-button',
                  label: translations.statistics_exitCompare,
                  onClick: onDisableComparison,
                  type: 'button'
                }
              ] }
            />
          </TopWrapper>
        ) }

        <ChartWrapper isComparing={ isComparing }>
          <StatisticsWidget
            dimension={ dimension }
            onCompare={ onEnableComparison }
            onChangeDimension={ onChangeDimension }
            isComparing={ isComparing }
          />

          { isComparing && (
            <StatisticsWidget
              isSecondary={ true }
              dimension={ dimension }
              onChangeDimension={ onChangeDimension }
              isComparing={ isComparing }
            />
          ) }
        </ChartWrapper>
      </Container>
    </Wrapper>
  );
};

export default View;
