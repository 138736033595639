import { MIN_BAR_WIDTH, WEIGHT_CONVERSION_RATIO } from 'app/constants/statistic';
import { DrawableBarData } from 'components/BarChart/types';
import { StatisticData } from 'containers/Statistics/types';
import { safeNumber } from '../safeTypes';

const getMinimumWidth = (width = 1) => {
  const computedWidth = width / WEIGHT_CONVERSION_RATIO;
  return computedWidth > MIN_BAR_WIDTH ? computedWidth : MIN_BAR_WIDTH;
};

export const getDataDrawable = (data: StatisticData[] = [], weightChart?: boolean): DrawableBarData => {
  const { maxPositive, maxNegative, totalWidth } = data.reduce(
    (acc, curr) => {
      const [height, width] = curr.values;
      if (height >= 0 && height > acc.maxPositive) {
        acc.maxPositive = height;
      } else if (height < 0 && height < acc.maxNegative) {
        acc.maxNegative = height;
      }
      acc.totalWidth += getMinimumWidth(width) + 2; // Margin between bars
      return acc;
    },
    { maxPositive: 0, maxNegative: 0, totalWidth: 0 }
  );

  const widthWithMargins = totalWidth + 5; // Global margin
  const positiveHeight = weightChart ? 100 : 50;
  const negativeHeight = weightChart ? 0 : -50;

  return data.reduce(
    (aggregated, current) => {
      const [height, width] = current.values;

      const bar = {
        ...current,
        height: 0,
        width: getMinimumWidth(width)
      };

      if (height >= 0) {
        aggregated.negativeData.push(bar);
        aggregated.positiveData.push({ ...bar, height: (height * 100) / maxPositive });
      } else if (height < 0) {
        aggregated.negativeData.push({ ...bar, height: -(height * 100) / maxNegative });
        aggregated.positiveData.push(bar);
      }

      return aggregated;
    },
    { positiveData: [], negativeData: [], positiveHeight, negativeHeight, totalWidth: widthWithMargins }
  );
};

export const filterListByDimension = <T>(list: T[] = [], dimensions: boolean[], isAreaChart?: boolean): T[] =>
  list
    .filter((_, i) => dimensions[i])
    .reduce((valueAcc, curr) => {
      if (isAreaChart) valueAcc.push(0);
      valueAcc.push(curr);
      return valueAcc;
    }, []);

export const safeHeight = (height: number) => safeNumber(height, 1);
