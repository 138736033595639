import { Action } from 'app/types';

export enum ActionTypes {
  GET_TEMPLATES_LIST_REQUEST = 'GET_TEMPLATES_LIST_REQUEST',
  GET_TEMPLATES_LIST_SUCCESS = 'GET_TEMPLATES_LIST_SUCCESS',
  GET_TEMPLATES_LIST_ERROR = 'GET_TEMPLATES_LIST_ERROR',

  GET_TEMPLATE_DETAILS_REQUEST = 'GET_TEMPLATE_DETAILS_REQUEST',
  GET_TEMPLATE_DETAILS_SUCCESS = 'GET_TEMPLATE_DETAILS_SUCCESS',
  GET_TEMPLATE_DETAILS_ERROR = 'GET_TEMPLATE_DETAILS_ERROR',

  CREATE_TEMPLATE_REQUEST = 'CREATE_TEMPLATE_REQUEST',
  CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS',
  CREATE_TEMPLATE_ERROR = 'CREATE_TEMPLATE_ERROR',

  UPDATE_TEMPLATE_DETAILS_REQUEST = 'UPDATE_TEMPLATE_DETAILS_REQUEST',
  UPDATE_TEMPLATE_DETAILS_SUCCESS = 'UPDATE_TEMPLATE_DETAILS_SUCCESS',
  UPDATE_TEMPLATE_DETAILS_ERROR = 'UPDATE_TEMPLATE_DETAILS_ERROR',

  DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST',
  DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS',
  DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR',

  PROCESS_TEMPLATE_REQUEST = 'PROCESS_TEMPLATE_REQUEST',
  PROCESS_TEMPLATE_SUCCESS = 'PROCESS_TEMPLATE_SUCCESS',
  PROCESS_TEMPLATE_ERROR = 'PROCESS_TEMPLATE_ERROR',

  PLACEORDER_FROM_HOME_TEMPLATE_REQUEST = 'PLACEORDER_FROM_HOME_TEMPLATE_REQUEST',
  PLACEORDER_FROM_HOME_TEMPLATE_SUCCESS = 'PLACEORDER_FROM_HOME_TEMPLATE_SUCCESS',
  PLACEORDER_FROM_HOME_TEMPLATE_ERROR = 'PROCESS_TEMPLATE_ERROR',

  RESET_TEMPLATES = 'RESET_TEMPLATES'
}

type GetTemplatesListRequest = Action<ActionTypes.GET_TEMPLATES_LIST_REQUEST>;
type GetTemplatesListSuccess = Action<ActionTypes.GET_TEMPLATES_LIST_SUCCESS>;
type GetTemplatesListError = Action<ActionTypes.GET_TEMPLATES_LIST_ERROR>;

type GetTemplateDetailsRequest = Action<ActionTypes.GET_TEMPLATE_DETAILS_REQUEST>;
type GetTemplateDetailsSuccess = Action<ActionTypes.GET_TEMPLATE_DETAILS_SUCCESS>;
type GetTemplateDetailsError = Action<ActionTypes.GET_TEMPLATE_DETAILS_ERROR>;

type CreateTemplateRequest = Action<ActionTypes.CREATE_TEMPLATE_REQUEST>;
type CreateTemplateSuccess = Action<ActionTypes.CREATE_TEMPLATE_SUCCESS>;
type CreateTemplateError = Action<ActionTypes.CREATE_TEMPLATE_ERROR>;

type UpdateTemplateDetailsRequest = Action<ActionTypes.UPDATE_TEMPLATE_DETAILS_REQUEST>;
type UpdateTemplateDetailsSuccess = Action<ActionTypes.UPDATE_TEMPLATE_DETAILS_SUCCESS>;
type UpdateTemplateDetailsError = Action<ActionTypes.UPDATE_TEMPLATE_DETAILS_ERROR>;

type DeleteTemplateRequest = Action<ActionTypes.DELETE_TEMPLATE_REQUEST>;
type DeleteTemplateSuccess = Action<ActionTypes.DELETE_TEMPLATE_SUCCESS>;
type DeleteTemplateError = Action<ActionTypes.DELETE_TEMPLATE_ERROR>;

type ProcessTemplateRequest = Action<ActionTypes.PROCESS_TEMPLATE_REQUEST>;
type ProcessTemplateSuccess = Action<ActionTypes.PROCESS_TEMPLATE_SUCCESS>;
type ProcessTemplateError = Action<ActionTypes.PROCESS_TEMPLATE_ERROR>;

type PickupFromHomeTemplateRequest = Action<ActionTypes.PLACEORDER_FROM_HOME_TEMPLATE_REQUEST>;
type PickupFromHomeTemplateSuccess = Action<ActionTypes.PLACEORDER_FROM_HOME_TEMPLATE_SUCCESS>;
type PickupFromHomeTemplateError = Action<ActionTypes.PLACEORDER_FROM_HOME_TEMPLATE_ERROR>;

type ResetTemplates = Action<ActionTypes.RESET_TEMPLATES>;

type GetTemplatesListActionType = GetTemplatesListRequest | GetTemplatesListSuccess | GetTemplatesListError;
type GetTemplateDetailsActionType = GetTemplateDetailsRequest | GetTemplateDetailsSuccess | GetTemplateDetailsError;
type CreateTemplateActionType = CreateTemplateRequest | CreateTemplateSuccess | CreateTemplateError;
type UpdateTemplateDetailsActionType = UpdateTemplateDetailsRequest | UpdateTemplateDetailsSuccess
| UpdateTemplateDetailsError;
type DeleteTemplateActionType = DeleteTemplateRequest | DeleteTemplateSuccess | DeleteTemplateError;
type ProcessTemplateActionType = ProcessTemplateRequest | ProcessTemplateSuccess | ProcessTemplateError;
type PickupFromHomeTemplateActionType = PickupFromHomeTemplateRequest
  | PickupFromHomeTemplateSuccess | PickupFromHomeTemplateError;
export type TemplatesActionType = 
GetTemplatesListActionType 
|ResetTemplates 
|GetTemplateDetailsActionType
|CreateTemplateActionType
|UpdateTemplateDetailsActionType
|DeleteTemplateActionType
|ProcessTemplateActionType
|PickupFromHomeTemplateActionType;
