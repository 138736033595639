import React from 'react';
import { useTranslationsContext } from 'app/translations';
import AvagDot from 'components/AvagDot';
import Portal from 'components/Portal';
import Content from 'components/Touchable';
import { SidebarItem } from '../../types';
import { Wrapper, Backdrop, Header, List, Item } from './styles';

interface Props {
  handleClick: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  items?: SidebarItem[];
}

const Sidebar: React.FC<Props> = ({ handleClick, isOpen, items = [] }) => {
  const { sidebar_menu } = useTranslationsContext();

  return (
    <Portal target="sidebar">
      <Backdrop
        isOpen={ isOpen }
        onClick={ () => {
          handleClick(false);
        } }
      />
      <Wrapper
        isOpen={ isOpen }
        onClick={ () => {
          handleClick(false);
        } }
      >
        <Header>
          <AvagDot /> { sidebar_menu }
        </Header>
        <List>
          { items.map(item => (
            <Item key={ item.id } to={ item.to }>
              <Content options={ item } />
            </Item>
          )) }
        </List>
      </Wrapper>
    </Portal>
  );
};

export default Sidebar;
