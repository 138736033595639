import { ROLE } from 'app/constants/roles';
import { LoggedUser } from '../types';

export const AVAG_ADMIN: LoggedUser = {
  user_id: '1',
  role: ROLE.AVAG_ADMIN,
  first_name: 'Avag Admin',
  last_name: 'User',
  user_email: 'adm1n.avag@gmail.com',
  client_id: '1',
  client_name: 'Test',
  companyPermissions: {
    portal: true,
    accessBills: false,
    requestPickup: false
  },
  permissions: {
    accessBills: false,
    requestPickup: false
  }
};

export const CLIENT_SUPER_USER: LoggedUser = {
  user_id: '2',
  role: ROLE.CLIENT_SUPER_USER,
  first_name: 'Super user',
  last_name: 'User',
  user_email: 'super.user.avag@gmail.com',
  client_id: '1',
  client_name: 'Test',
  companyPermissions: {
    portal: true,
    accessBills: false,
    requestPickup: false
  },
  permissions: {
    accessBills: true,
    requestPickup: true
  }
};

export const CLIENT_OPERATOR: LoggedUser = {
  user_id: '3',
  role: ROLE.CLIENT_OPERATOR,
  first_name: 'Operator',
  last_name: 'User',
  user_email: 'operator.avag@gmail.com',
  client_id: '1',
  client_name: 'Test',
  companyPermissions: {
    portal: true,
    accessBills: false,
    requestPickup: false
  },
  permissions: {
    accessBills: true,
    requestPickup: true
  }
};
