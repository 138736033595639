import { FormikValues } from 'formik';
import React, { useState } from 'react';
import { applyDateLimits } from 'app/utils/validations';
import { useTranslationsContext } from 'app/translations';
import Buttons from 'components/Buttons';
import Modal from 'components/Modal';
import { Footer } from 'components/Modal/styles';
import SmartForm from 'components/SmartForm';
import { SUBMIT } from 'components/SmartForm/models';
import { FieldProps } from 'components/SmartForm/types';
import { ButtonProps } from 'components/Touchable/types';
import { useDatesValidationSchema } from '../../data/validations';
import { Container, Description, FieldsWrapper, FieldWrapperNoteText } from './styles';
import { ExportFieldName } from '../../types';

interface Props {
  onClose: () => void;
  onConfirm: (values: FieldProps[]) => void;
  formName: string;
  formFields: FieldProps[];
  formButtons: ButtonProps[];
  title: string;
  description: string;
}

const FormExportModal: React.FC<Props> = ({ onClose,
  onConfirm,
  formName,
  formFields,
  formButtons,
  title,
  description }) => {
  const [values, setValues] = useState<FormikValues>();
  const translations = useTranslationsContext();

  const handleConfirm = React.useCallback(
    (fieldsData: FieldProps[]) => {
      onConfirm(fieldsData);
      onClose();
    },
    [onConfirm]
  );

  const buttons = formButtons.map<ButtonProps>(button => {
    if (button.type === SUBMIT) return { ...button, onClick: onConfirm };
    if (button.id === 'cancel') return { ...button, onClick: onClose };
    return button;
  });

  const formatField = formFields.find(f => f.id === ExportFieldName.format);
  const formInitialValues = formatField
    ? {
      format: formFields.find(f => f.id === ExportFieldName.format)?.options[0].value
    }
    : undefined;

  const validationSchema = useDatesValidationSchema();

  return (
    <Modal onClose={ onClose } header={ title }>
      <SmartForm
        key="smartform"
        buttons={ buttons }
        fields={ applyDateLimits(formFields, values) }
        form={ formName }
        handleSubmit={ vals => handleConfirm(vals) }
        initialValues={ formInitialValues }
        submitRequireDirty={ false }
        validateRequireDirty={ false }
        validateOnBlur={ false }
        validateOnMount={ false }
        validationSchema={ validationSchema }
      >
        { ({ controlledFields, Field, controlledButtons, values: vals }) => {
          setValues(vals);

          return (
            <>
              <Container>
                <Description> { description }</Description>
                <FieldsWrapper>
                  { controlledFields.map(f => (
                    <Field key={ f.id } options={ f } />
                  )) }
                </FieldsWrapper>
                <FieldWrapperNoteText>
                  <strong>{ translations.billDetails_disclaimerTitle }: </strong>
                  { translations.billDetails_modalBoxNoteText }
                </FieldWrapperNoteText>
              </Container>
              <Footer>
                <Buttons options={ controlledButtons } />
              </Footer>
            </>
          );
        } }
      </SmartForm>
    </Modal>
  );
};

export default FormExportModal;
