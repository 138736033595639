import { howManyDays } from 'app/utils/date';
import { formatDate } from 'app/utils/formatters';
import { safeDate } from 'app/utils/safeTypes';
import { LangKeys } from 'app/translations';
import { Notification, MaintenanceNotification } from '../types';

const buildMaintenanceMessage = ({ changes, end, message, start }: MaintenanceNotification): string => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const difference = howManyDays(startDate, endDate) - 1;

  let text = message;
  if (!message) {
    text = `There will be a maintenance on ${ formatDate(safeDate(startDate)) }`;

    if (difference > 0) {
      text += ` and it will last ${ difference }`;
  
      if (difference > 1) {
        text += ' days';
      } else {
        text += ' day';
      }
    }
  }

  if (changes.length) {
    text += '\n\nSome changes you will notice are:';

    changes.forEach(change => {
      text += `\n${ change }`;
    });
  }

  return text;
};

export const createNotificationFromMaintenance = (
  notification: MaintenanceNotification,
  translations: LangKeys
): Notification | undefined => {
  const startDate = new Date(notification.start).getTime();
  const now = new Date().getTime();

  if (startDate - now <= 0) {
    return undefined;
  }

  return {
    actions: [
      {
        action: 'dismiss',
        title: translations.unsupportedBrowser_buttonConfirm,
      }
    ],
    body: buildMaintenanceMessage(notification),
    tag: 'maintenance-notification',
    timestamp: new Date().getTime(),
    title: translations.scheduledMaintenance,
  };
};

export const createNotificationFromSupport = (
  translations: LangKeys
): Notification => ({
  actions: [
    {
      action: 'more-info',
      title: translations.unsupportedBrowser_buttonInfo,
    },
    {
      action: 'dismiss',
      title: translations.unsupportedBrowser_buttonConfirm,
    }
  ],
  body: `${ translations.unsupportedBrowser_modal }.`,
  tag: 'browser-notification',
  timestamp: new Date().getTime(),
  // title: 'translations.unsupportedBrowser_title', // commentted title
});