import React, { useEffect, useCallback } from 'react';
import { KEY_CODES } from 'app/constants/keyboard';
import { Size } from 'app/types';
// eslint-disable-next-line no-restricted-imports
import ConfirmationModal from './do-not-export/ConfirmationModal';
import Portal from '../Portal';
import AvagDot from '../AvagDot';
import { Wrapper, Header, Content, Background, Foreground, Footer } from './styles';

interface Props {
  children?: React.ReactNode;
  header?: string | React.ReactNode;
  flex?: boolean;
  footer?: React.ReactNode;
  size?: Size;
  onClose: () => void;
}

const Modal: React.FC<Props> = ({ children, header, flex = false, footer, size = 'auto', onClose }) => {
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.keyCode === KEY_CODES.ESC) {
      onClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, []);

  const modalHeader = (
    <Header>
      { typeof header === 'string' ? (
        <>
          {/* <AvagDot /> */}
          { header }
        </>
      ) : (
        header
      ) }
    </Header>
  );

  const modalFooter = <Footer>{ footer }</Footer>;

  return (
    <Portal>
      <Wrapper>
        <Background onClick={ onClose } />
        <Foreground size={ size }>
          { !!header && modalHeader }
          <Content flex={ flex }>{ children }</Content>
          { !!footer && modalFooter }
        </Foreground>
      </Wrapper>
    </Portal>
  );
};

export default Modal;
export { ConfirmationModal };
