import styled from 'styled-components';
import { TABLET, DESKTOP } from 'base/styles/media-queries';
import { MAX_WITH_FORM_SIZE_TABLET, MAX_WIDTH_FORM_SIZE_DESKTOP, FOOTER_SIZE, FOOTER_SPACE } from 'base/styles/sizes';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const Body = styled.div`
  min-height: calc(100vh - ${ FOOTER_SIZE + FOOTER_SPACE }px);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  ${ TABLET } {
    max-width: ${ MAX_WITH_FORM_SIZE_TABLET }px;
  }

  ${ DESKTOP } {
    max-width: ${ MAX_WIDTH_FORM_SIZE_DESKTOP }px;
  }
`;
Body.displayName = 'Body';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
Content.displayName = 'Content';
