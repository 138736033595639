import React from 'react';
import * as ROUTES from 'app/routes';
import { useTranslationsContext } from 'app/translations';
import Footer from 'components/Footer';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { AppWrapper, Content } from './styles';
import { SidebarItem } from './types';

const billsIcon = require('assets/icons/bills.svg');
const helpIcon = require('assets/icons/help.svg');
const homeIcon = require('assets/icons/home.svg');
const logoutIcon = require('assets/icons/logout.svg');
const deliverySlipsIcon = require('assets/icons/delivery-slips.svg');
const pickupsIcon = require('assets/icons/pick-ups.svg');
const statisticsIcon = require('assets/icons/statistics.svg');
const usersIcon = require('assets/icons/users.svg');
const settingsIcon = require('assets/icons/settings.svg');

const widthIcon = 30;
const heightIcon = 30;

export interface Props {
  permissions: {
    pickupsAvailable: boolean;
    deliverySlipsAvailable: boolean;
    billsAvailable: boolean;
    statisticsAvailable: boolean;
    usersAvailable: boolean;
    settingsAvailable: boolean;
    helpAvailable: boolean;
  };
}

const MainLayout: React.FC<React.PropsWithChildren<Props>> = ({ children, permissions }) => {
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const translations = useTranslationsContext();

  const logout: SidebarItem = {
    icon: <img src={ logoutIcon } alt={ `${ translations.routeName_logout } icon` } />,
    id: 'logout',
    label: translations.routeName_logout,
    to: ROUTES.LOGOUT.path
  };

  const items: SidebarItem[] = [
    {
      icon: <img src={ homeIcon } alt={ `${ translations.routeName_home } icon` }
        width={ widthIcon } height={ heightIcon }/>,
      id: 'home',
      label: translations.routeName_home,
      to: ROUTES.HOME.path
    }
  ];

  if (permissions.pickupsAvailable) {
    items.push({
      // eslint-disable-next-line max-len
      icon: <img src={ pickupsIcon } alt={ `${ translations.routeName_pickUpRequests } icon` } width={ widthIcon } height={ heightIcon }/>,
      id: 'pickups',
      label: translations.routeName_pickUpRequests,
      to: ROUTES.PICKUP_REQUESTS_LIST.path
    });
  }

  if (permissions.deliverySlipsAvailable) {
    items.push({
      // eslint-disable-next-line max-len
      icon: <img src={ deliverySlipsIcon } alt={ `${ translations.routeName_deliverySlips } icon` } width={ widthIcon } height={ heightIcon } />,
      id: 'delivery-slips',
      label: translations.routeName_deliverySlips,
      to: ROUTES.DELIVERY_SLIP_LIST.path
    });
  }
  if (permissions.billsAvailable) {
    items.push({
      icon: <img src={ billsIcon } alt={ `${ translations.routeName_bills } icon` }
        width={ widthIcon } height={ heightIcon } />,
      id: 'bills',
      label: translations.routeName_bills,
      to: ROUTES.BILL_LIST.path
    });
  }

  if (permissions.statisticsAvailable) {
    items.push({
      // eslint-disable-next-line max-len
      icon: <img src={ statisticsIcon } alt={ `${ translations.routeName_statistics } icon` } width={ widthIcon } height={ heightIcon } />,
      id: 'statistics',
      label: translations.routeName_statistics,
      to: ROUTES.STATISTICS.path
    });
  }

  if (permissions.usersAvailable) {
    items.push({
      icon: <img src={ usersIcon } alt={ `${ translations.routeName_users } icon` }
        width={ widthIcon } height={ heightIcon } />,
      id: 'users',
      label: translations.routeName_users,
      to: ROUTES.USERS.path
    });
  }

  if (permissions.settingsAvailable) {
    items.push({
      // eslint-disable-next-line max-len
      icon: <img src={ settingsIcon } alt={ `${ translations.routeName_settings } icon` } width={ widthIcon } height={ heightIcon } />,
      id: 'settings',
      label: translations.routeName_settings,
      to: ROUTES.SETTINGS.path
    });
  }

  if (permissions.helpAvailable) {
    items.push({
      icon: <img src={ helpIcon } alt={ `${ translations.routeName_help } icon` } 
        width={ widthIcon } height={ heightIcon }/>,
      id: 'help',
      label: translations.routeName_help,
      to: ROUTES.HELP.path
    });
  }

  items.push(logout);

  return (
    <>
      <Header handleClick={ setOpenSidebar } isOpen={ openSidebar } logout={ logout } />
      <AppWrapper>
        <Sidebar handleClick={ setOpenSidebar } isOpen={ openSidebar } items={ items } />
        <Content>{ children }</Content>
      </AppWrapper>
      <Footer />
    </>
  );
};

export default MainLayout;
