import React from 'react';
import { Row, Cell, BarWrapper, BarContent } from './styles';

interface Props {
  columns: number;
  isLoading: boolean;
}

const Loading: React.FC<Props> = ({ columns, isLoading }) => (
  <Row>
    <Cell colSpan={ columns }>
      <BarWrapper isLoading={ isLoading }>
        <BarContent />
      </BarWrapper>
    </Cell>
  </Row>
);

export default Loading;
