/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslationsContext } from 'app/translations';
import { IndexedFields } from 'app/types';
import { sortBy } from 'app/utils/sorting';
import { createOptionsFromEntity } from 'app/utils/fields';
import Field from 'components/SmartForm/components';
import { ContainerPermission, Material, Container, MaterialDetails } from 'containers/PickupRequest/types';
import { getMaterialList } from 'containers/PickupRequest/actions';
import { Option } from 'components/SmartForm/types';
import { getLoginData } from 'containers/Login/selectors';
import { isAvagAdmin } from 'containers/Core/selectors';
import { getContainer } from 'containers/PickupRequest/selectors';
import { renderAddonValue } from 'containers/PickupRequest/tools';
import { MaterialFieldName } from '../../../../types';
import IconButton from '../IconButton';
import { FieldsWrapper,
  RemoveRowWrapper,
  MaterialRowContainer,
  FlexBox,
  AdminSection,
  UserSection,
  FlexField,
  VevaSection,
  ContainerField,
  AmountsWrapper,
  AddonsList,
  Addon,
  AddonListHeader,
  AddonName,
  AddonPickup } from './styles';

interface Props {
  errorKeys?: any;
  fields: IndexedFields<MaterialFieldName>;
  onOpenContainerModal: (containerId: string) => void;
  onRemove?: () => void;
  permissions?: ContainerPermission;
  selectedCompanyId: string;
  touchedKeys?: any;
  values: MaterialDetails;
}
const removeMaterialRow = require('assets/icons/trash-bin.svg');
// eslint-disable-next-line max-lines-per-function
const MaterialRow: React.FC<Props> = ({ fields,
  errorKeys,
  onOpenContainerModal,
  onRemove,
  permissions,
  selectedCompanyId,
  touchedKeys,
  values }) => {
  const translations = useTranslationsContext();
  const { client_id } = useSelector(getLoginData);
  const container = useSelector(getContainer(values.containerId));
  const isAdmin = useSelector(isAvagAdmin);
  const dispatch = useDispatch();
  const [materials, setMaterials] = useState<Option[]>([]);
  const [loadingMaterials, setLoadingMaterials] = useState(false);
  const selected = materials.find(m => m.id === values.materialId);
  const isVevaMaterial = /veva/.test(selected?.label.toLowerCase());

  const defaultedPermissions: Container['permissions'] = {
    collected: permissions?.collected !== undefined ? permissions.collected : container?.permissions.collected,
    delivered: permissions?.delivered !== undefined ? permissions.delivered : container?.permissions.delivered
  };
  const requestMaterials = async (containerId: string, companyId: string) => {
    setLoadingMaterials(true);
    const { payload } = await dispatch(getMaterialList(containerId, companyId));
    const materialsCreated = createOptionsFromEntity((payload || []) as Material[]);
    setMaterials(materialsCreated.sort(sortBy('label', 'asc')));
    setLoadingMaterials(false);
  };

  useEffect(() => {
    if (values.containerId && ((isAdmin && selectedCompanyId) || (!isAdmin && client_id))) {
      requestMaterials(values.containerId, isAdmin ? selectedCompanyId : client_id);
    }
  }, [values.containerId, selectedCompanyId]);

  useEffect(() => {
    if (isVevaMaterial) values.isVeva = isVevaMaterial;
  }, [isVevaMaterial]);
  const addonsWithValues = values.addons.filter(a => a.defaultValue);
  return (
    <MaterialRowContainer indentation={ false }>
      <FieldsWrapper>
        <UserSection>
          <FlexBox>
            <ContainerField>
              <Field
                options={ {
                  ...fields.containerName,
                  error: touchedKeys[fields.containerId.id] && errorKeys[fields.containerId.id],
                  onClick: () => {
                    onOpenContainerModal(fields.containerId.id);
                  }
                } }
              />
            </ContainerField>
            <FlexField>
              <Field
                options={ {
                  ...fields.materialId,
                  error: touchedKeys[fields.materialId.id] && errorKeys[fields.materialId.id],
                  isDisabled: loadingMaterials || fields.materialId.isDisabled,
                  options: materials,
                  model: (materials && materials.find(m => m.label === 'leerer Behälter')?.id) || '1',
                  placeholder: loadingMaterials ? translations.validations_loading : fields.materialId.placeholder
                } }
              />
            </FlexField>
          </FlexBox>
          <AmountsWrapper>
            <FlexField>
              <Field
                options={ {
                  ...fields.pickupContainers,
                  error: touchedKeys[fields.pickupContainers.id] && errorKeys[fields.pickupContainers.id],
                  isDisabled: !defaultedPermissions?.collected || fields.pickupContainers.isDisabled,
                  min: 0
                } }
              />
            </FlexField>
            <FlexField>
              <Field
                options={ {
                  ...fields.deliverContainers,
                  error: touchedKeys[fields.deliverContainers.id] && errorKeys[fields.deliverContainers.id],
                  isDisabled: !defaultedPermissions?.delivered || fields.deliverContainers.isDisabled,
                  min: 0
                } }
              />
            </FlexField>
          </AmountsWrapper>

          { isVevaMaterial && (
            <VevaSection>
              <Field options={ fields.handleDocumentation } />
            </VevaSection>
          ) }

          { !!addonsWithValues.length && (
            <AddonsList>
              <AddonListHeader>{ translations.pickupRequestDetails_containerSetup }</AddonListHeader>

              <ul>
                { addonsWithValues.map(addon => (
                  <Addon key={ `addon-${ addon.addonId }` }>
                    <AddonName>{ renderAddonValue(translations, addon, container?.addons, undefined) }</AddonName>
                    <AddonPickup>
                      ({ renderAddonValue(translations, addon, [], { pickup: values?.pickupContainers }) })
                    </AddonPickup>
                    <AddonPickup>
                      ({ renderAddonValue(translations, addon, [], { deliver: values?.deliverContainers }) })
                    </AddonPickup>
                  </Addon>
                )) }
              </ul>
            </AddonsList>
          ) }
        </UserSection>

        { fields.destination && (
          <AdminSection>
            <FlexField>
              <Field
                options={ {
                  ...fields.destination,
                  error: touchedKeys[fields.destination.id] && errorKeys[fields.destination.id]
                } }
              />
            </FlexField>
            <FlexField>
              <Field
                options={ {
                  ...fields.date,
                  error: touchedKeys[fields.date.id] && errorKeys[fields.date.id]
                } }
              />
            </FlexField>
            <FlexField>
              <Field
                options={ {
                  ...fields.timeSlot,
                  error: touchedKeys[fields.timeSlot.id] && errorKeys[fields.timeSlot.id]
                } }
              />
            </FlexField>
          </AdminSection>
        ) }
      </FieldsWrapper>

      { onRemove && (
        <RemoveRowWrapper>
          <IconButton icon={ removeMaterialRow } onClickHandler={ onRemove } />
        </RemoveRowWrapper>
      ) }
    </MaterialRowContainer>
  );
};

export default MaterialRow;
