import React from 'react';
import { useTranslationsContext } from 'app/translations';
import { createIndexedFields } from 'app/utils/fields';
import Card from 'components/Card';
import { DeliverySlipDetailsSkeleton } from 'components/Skeletons';
import SectionTitle from 'components/SectionTitle';
import SmartForm from 'components/SmartForm';
import Table from 'components/Table';
import { createRowFromMaterialInfo } from 'containers/DeliverySlips/models';
import { DeliverySlip, MaterialInfoRow } from 'containers/DeliverySlips/types';
import { useTableData } from '../../data';
import { createFields } from '../../data/fields';
import { FieldName } from '../../types';
import { CustomCardContent, FormWrapper, WrapperLeft, WrapperRight } from './styles';

interface Props {
  deliverySlip: DeliverySlip;
  isLoading?: boolean;
}

const TableForm: React.FC<Props> = ({ deliverySlip, isLoading }) => {
  const translations = useTranslationsContext();
  const fields = createFields(translations, deliverySlip);

  const { columns } = useTableData();

  const materialRows = React.useMemo(() => {
    return deliverySlip.materials.map(createRowFromMaterialInfo);
  }, [deliverySlip.materials]);

  return (
    <>
      { isLoading && <DeliverySlipDetailsSkeleton attachments={ deliverySlip.attachments?.length } /> }
      { !isLoading && (
        <>
          <SectionTitle>{ translations.pickupRequestDetails_logisticsTitle }</SectionTitle>
          <Card indentation={ false }>
            <CustomCardContent>
              <SmartForm form={ 'delivery' } buttons={ [] } fields={ fields } handleSubmit={ () => {} }>
                { ({ controlledFields, Field }) => {
                  const indexedFields = createIndexedFields<FieldName>(controlledFields);

                  return (
                    <FormWrapper>
                      <WrapperLeft>
                        <Field options={ indexedFields.deliveryReference } />

                        <Field options={ indexedFields.driverOrderNo } />

                        <Field options={ indexedFields.transportOrderNo } />

                        <Field options={ indexedFields.bookingReference } />
                      </WrapperLeft>
                      <WrapperRight>
                        <Field options={ indexedFields.date } />

                        <Field options={ indexedFields.companyReference } />

                        <Field options={ indexedFields.driver } />
                      </WrapperRight>
                    </FormWrapper>
                  );
                } }
              </SmartForm>
            </CustomCardContent>

            <Table<MaterialInfoRow>
              columns={ columns }
              rows={ materialRows }
              showTitle={ false }
              sortEnabled={ false }
              showFooter={ false }
            />
          </Card>
        </>
      ) }
    </>
  );
};

export default TableForm;
