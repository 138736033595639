import produce from 'immer';
import { createReducer, isMockingApi } from 'base';
import { ActionHandler } from 'base/types';
import { ActionTypes as LogoutActions } from 'containers/Login/actionTypes';
import { ActionTypes, BillActionType } from '../actionTypes';
import { BILLS_LIST } from '../mocks';
import { billsInitialState } from '../models';
import { Bill, BillsState } from '../types';

const getBillListSuccess = (state: BillsState, action: BillActionType) =>
  produce(state, draft => {
    const { collection, pagination } = action.payload as BillsState;
    draft.collection = collection || state.collection;
    draft.pagination = pagination || state.pagination;
  });

const getBillListError = (state: BillsState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.collection = BILLS_LIST.slice(0, draft.pagination?.pageSize);
      draft.pagination.totalCount = BILLS_LIST.length;
    }
  });

const getBillSuccess = (state: BillsState, action: BillActionType) =>
  produce(state, draft => {
    const bill = action.payload as Bill;
    let hasBeenModified = false;
    draft.collection = state.collection.map(item => {
      if (item.id === bill.id) {
        hasBeenModified = true;
        return {
          ...item,
          ...bill
        };
      }

      return item;
    });

    if (!hasBeenModified) {
      draft.collection.push(bill);
    }
  });

const resetBills = (state: BillsState) =>
  produce(state, draft => {
    draft.collection = [];
  });

const getInitialState = (state: BillsState) =>
  produce(state, draft => {
    Object.keys(draft).forEach(key => {
      // @ts-ignore
      draft[key] = billsInitialState[key];
    });
  });

const actionHandlers: ActionHandler<BillsState> = {
  [ActionTypes.GET_BILL_LIST_SUCCESS]: getBillListSuccess,
  [ActionTypes.GET_BILL_LIST_ERROR]: getBillListError,
  [ActionTypes.GET_BILL_SUCCESS]: getBillSuccess,
  [ActionTypes.GET_BILL_ERROR]: getBillListError,
  [ActionTypes.RESET_BILLS]: resetBills,
  [LogoutActions.LOGOUT_REQUEST]: getInitialState
};

export default createReducer(billsInitialState, actionHandlers);
