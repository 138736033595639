import React, { PropsWithChildren } from 'react';

import { ItemWrapper } from './styles';

interface Props {
  flex?: boolean;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const MosaicItem: React.FC<PropsWithChildren<Props>> = ({ children, flex, onClick }) => (
  <ItemWrapper flex={ !!flex } onClick={ onClick }>
    { children }
  </ItemWrapper>
);

export default MosaicItem;
