import { FormikValues } from 'formik';
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { isMockingApi } from 'base';
import { createOptionsFromEntity, createRadioOptionsFromEntity } from 'app/utils/fields';
import { LangKeys, useTranslationsContext } from 'app/translations';
import { ROLE, getRoleName } from 'app/constants/roles';
import { RouteWithID } from 'app/types';
import { ButtonProps } from 'components/Touchable/types';
import { FieldProps } from 'components/SmartForm/types';
import { getCompany } from 'containers/Companies/actions';
import { getCompanySimpleList, getCompanyPermissions } from 'containers/Companies/selectors';
import { CompanySimple } from 'containers/Companies/types';
import { isAvagAdmin, isClientSuperUser } from 'containers/Core/selectors';
import { changePasswordRequest } from 'containers/ForgotPassword/actions';
import { getLoginData } from 'containers/Login/selectors';
import { getUser } from 'containers/Users/selectors';
import { ConstraintGroup, Entity, UserStatus, CompanyPermissions } from 'containers/Users/types';
import { canHostAvagAdmins, isAvagID } from 'app/utils/permissions';
import { CreatorProps, DropdownOptions } from '../types';
import { createPickupRequestActionButtons } from './buttons';
import { createFields } from './fields';
import { createValidationSchema } from './validation';

interface CreateFormDataProps {
  translations: LangKeys;
  constraintGroup: ConstraintGroup;
  dropdownOptions: DropdownOptions;
}

interface FormData {
  buttons: ButtonProps[];
  fields: FieldProps[];
  validationSchema?: Yup.ObjectSchema;
}

interface FormDataProps {
  formProps: CreateFormDataProps;
  handleChangeCompany: (id: string) => void;
  handleChangePassword?: () => void;
  permissions: CompanyPermissions;
  props: CreatorProps;
  translations: LangKeys;
  userStatus: number;
  values: FormikValues;
}

const createFormData = ({ formProps,
  handleChangeCompany,
  handleChangePassword,
  permissions,
  props: { buttonActions },
  translations,
  userStatus,
  values }: FormDataProps): FormData => ({
  buttons: createPickupRequestActionButtons(formProps.translations, formProps.constraintGroup, buttonActions),
  fields: createFields({ ...formProps, handleChangeCompany, handleChangePassword, permissions, userStatus, values }),
  validationSchema: createValidationSchema(formProps.constraintGroup, translations)
});

export const useFormData = (props: CreatorProps, values?: FormikValues): FormData => {
  const dispatch = useDispatch();
  const translations = useTranslationsContext();
  const translationsLength = Object.keys(translations).length;
  const { id } = useParams<RouteWithID>();
  const user = useSelector(getUser(id));
  const { user_id: loggedUserId, user_email, companyPermissions } = useSelector(getLoginData);
  const selectedCompanyPermissions = useSelector(getCompanyPermissions(values?.companyId));
  const isAdmin = useSelector(isAvagAdmin);
  const isSuperUser = useSelector(isClientSuperUser);
  const companyList = useSelector(getCompanySimpleList);
  const isSameUser = id === loggedUserId;
  const allowAvagAdmin = isAdmin && canHostAvagAdmins(values.companyId);

  const handleChangeCompany = (clientId: string) => {
    dispatch(getCompany(clientId));
  };

  const handleChangePassword = isSameUser
    ? async () => {
      const { type } = await dispatch(changePasswordRequest(user_email));
      if (isMockingApi() || type.includes('SUCCESS')) {
        props.buttonActions.handleChangePassword();
      }
    }
    : undefined;

  const constraintGroup: ConstraintGroup = {
    canModify: isAdmin || (isSuperUser && user?.role !== ROLE.AVAG_ADMIN) || isSameUser,
    isActiveUser: user?.status !== UserStatus.ACCESS_REQUESTED,
    isAdmin,
    isNewUser: !user?.id,
    isSameUser,
    isSuperUser
  };

  const permissions = isAdmin ? selectedCompanyPermissions : companyPermissions;

  const getRoleDescription = (role: ROLE) => {
    switch (role) {
      case ROLE.AVAG_ADMIN:
        return translations.userDetails_avagAdminDescription;

      case ROLE.CLIENT_SUPER_USER:
        return translations.userDetails_superUserDescription;

      case ROLE.CLIENT_OPERATOR:
        return translations.userDetails_operatorDescription;

      default:
        return '';
    }
  };

  return useMemo<FormData>(() => {
    const companyOptions = createOptionsFromEntity<CompanySimple>(
      companyList.map(c => {
        if (isAvagID(c.id)) {
          return c;
        }

        return {
          ...c,
          name: `${ c.name } - ${ c.id }`
        };
      })
    );

    const roleOptions = createRadioOptionsFromEntity<Entity>(
      Object.keys(ROLE)
        .map(key => {
          // @ts-ignore
          const r = ROLE[key];

          // Remove Alphanumeric elements and AVAG_ADMIN
          if (typeof r === 'number' && (r !== ROLE.AVAG_ADMIN || allowAvagAdmin)) {
            return {
              id: r,
              name: (
                <>
                  <b>{ getRoleName(r) }</b> { getRoleDescription(r) }
                </>
              )
            };
          }

          return undefined;
        })
        .filter(key => !!key)
    );

    const dropdownOptions = { companyOptions, roleOptions };
    const formProps = { translations, constraintGroup, dropdownOptions };
    return createFormData({
      formProps,
      handleChangeCompany,
      handleChangePassword,
      permissions,
      props,
      translations,
      userStatus: user?.status,
      values
    });
  }, [allowAvagAdmin, companyList.length, permissions, translationsLength, values?.role]);
};
