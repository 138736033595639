import { LangKeys } from 'app/translations';
import { createNotificationFromMaintenance, createNotificationFromSupport } from '../models';
import { MaintenanceNotification, SupportedBrowserInfo } from '../types';

export const maintenanceNotificationRequest = async (translations: LangKeys) => {
  try {
    const notification: MaintenanceNotification = (await import('assets/maintenance-notifications.json')).default;
    return createNotificationFromMaintenance(notification, translations);
  } catch (error) {
    throw new Error(error);
  }
};

const isSupportedBrowser = (supportedBrowsers: SupportedBrowserInfo[]) => {
  const chrome = supportedBrowsers.find(b => b.key === 'chrome');
  const edge = supportedBrowsers.find(b => b.key === 'edge');
  const firefox = supportedBrowsers.find(b => b.key === 'firefox');
  const safari = supportedBrowsers.find(b => b.key === 'safari');

  const { userAgent } = navigator;

  const edgMatching = userAgent.match(/Edg\/([0-9]*)/);
  if (edgMatching) {
    if (Number(edgMatching?.[1]) >= edge?.version) {
      return true;
    }
    return false;
  }

  const edgeMatching = userAgent.match(/Edge\/([0-9]*)/);
  if (edgeMatching) {
    if (Number(edgeMatching?.[1]) >= edge?.version) {
      return true;
    }
    return false;
  }

  const firefoxMatching = userAgent.match(/Firefox\/([0-9]*)/);
  if (firefoxMatching) {
    if (Number(firefoxMatching?.[1]) >= firefox?.version) {
      return true;
    }
    return false;
  }

  const chromeMatching = userAgent.match(/Chrome\/([0-9]*)/);
  if (chromeMatching) {
    if (Number(chromeMatching?.[1]) >= chrome?.version) {
      return true;
    }
    return false;
  }

  const safariMatching = userAgent.match(/Safari\/([0-9]*)/);
  if (safariMatching) {
    if (Number(safariMatching?.[1]) >= safari?.version) {
      return true;
    }
    return false;
  }

  return false;
};

export const browserNotificationRequest = async (translations: LangKeys) => {
  try {
    const browsers: SupportedBrowserInfo[] = (await import('assets/browsers-notifications.json')).default;
    return isSupportedBrowser(browsers) ? undefined : createNotificationFromSupport(translations);
  } catch (error) {
    throw new Error(error);
  }
};