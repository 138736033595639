import { http, envConf, isMockingApi } from 'base';
import * as Model from '../models';
import { Help, HelpForm } from '../types';
import { HELP_REQUEST } from '../mocks';

export const helpRequest = async (request: HelpForm): Promise<Help> => {
  try {
    const { data } = await http.post(envConf.API.HELP_POST, request);
    return Model.createHelpFromServer(data);
  } catch ({ response }) {
    if (isMockingApi()) {
      return Model.createHelpFromServer(HELP_REQUEST);
    }
    throw new Error(response?.data?.message);
  }
};
