import styled from 'styled-components';

import {
  BACKGROUND,
  CALENDAR_TEXT,
  CALENDAR_TITLE,
  SELECT_BORDER_COLOR,
  SELECT_BORDER_COLOR_HOVER,
  SELECT_COLOR, SELECT_COLOR_HOVER
} from 'base/styles/skin';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px;
  background-color: ${ BACKGROUND };
`;
TitleWrapper.displayName = 'TitleWrapper';

export const Title = styled.div`
  font-family: inherit;
  font-size: 22px;
  font-weight: 500;
  color: ${ CALENDAR_TITLE };
`;
Title.displayName = 'Title';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${ BACKGROUND };
`;
Header.displayName = 'Header';

export const Text = styled.div`
  font-family: inherit;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  color: ${ CALENDAR_TEXT };
`;
Text.displayName = 'Text';

export const ArrowButton = styled.button`
  background-color: ${ BACKGROUND };
  border: none;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  margin: 12px;
  margin-right: 5px;

  &:focus {
    outline: none;
  }
`;
ArrowButton.displayName = 'ArrowButton';

export const ArrowRight = styled.img`
  object-fit: contain;
  transform: rotate(180deg);
`;
ArrowRight.displayName = 'ArrowRight';

export const DateSelect = styled.select`
    border-color: ${SELECT_BORDER_COLOR};
    color: ${SELECT_COLOR};
    padding: 3px;
    border-radius: 5px;
    margin: 0px 5px;
    &:hover{
        border-color: ${SELECT_BORDER_COLOR_HOVER};
        color: ${SELECT_COLOR_HOVER};
    }  
`;

