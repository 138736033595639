import randomColor from 'randomcolor';
// eslint-disable-next-line no-restricted-imports
import * as colors from './do-not-export/colors';
import { AVAG_GREEN } from './do-not-export/colors';
// eslint-disable-next-line no-restricted-imports
export * from './do-not-export/utils';

export const PRIMARY = colors.BRAND_COLOR;
export const SECONDARY = colors.PINK_1;

export const PRIMARY_BACKGROUND = colors.WHITE_1;
export const BACKGROUND = colors.WHITE_1;

export const HEADER_BACKGROUND = colors.WHITE_1;
export const HEADER_BORDER_COLOR = colors.GREY_2;
export const HEADER_USERNAME_HOVER_COLOR = colors.AVAG_GREEN;

/* Sidebar */
export const SIDEBAR_BACKGROUND = colors.DARK_GREEN_1;
export const SIDEBAR_BORDER_COLOR = colors.WHITE_1;
export const SIDEBAR_COLOR = colors.WHITE_1;
export const NAV_LINK_HOVER = '#5b6b5b';

export const BACKDROP_BACKGROUND = colors.BLACK_TRANSPARENT;

/* Modal */
export const MODAL_FOREGROUND = colors.WHITE_1;
export const MODAL_HEADER = colors.GREY_7;
export const MODAL_FOOTER = colors.SAND;

/* Footer */
export const FOOTER_BACKGROUND = colors.SAND;
export const FOOTER_TEXT = colors.DARK_GREEN_1;
export const FOOTER_LINK_HOVER = colors.AVAG_GREEN;
// export const LINK_COLOR = colors.TURQUOISE_1;
export const LINK_COLOR = colors.AVAG_GREEN;
export const LINK_COLOR_HOVER = colors.DARK_GREEN_1;

/* Progress Bar */
export const PROGRESS_BAR_BACKGROUND_BIG = colors.GREY_14;
export const PROGRESS_BAR_BACKGROUND = colors.WHITE_1;
export const PROGRESS_BAR_BORDER = colors.GREY_14;
export const PROGRESS_BAR_POINT_GREEN = colors.GREEN_2;
export const PROGRESS_BAR_POINT_RED = colors.RED_1;
export const PROGRESS_BAR_COLOR_FILL = colors.GREY_7;

/* Card */
export const CARD_HEADER = colors.AVAG_GREEN;
export const CARD_FOOTER = colors.SAND;
export const CARD_BORDER = colors.GREY_2;

/* TAG */
export const TAG_BACKGROUND = colors.GREY_7;
export const TEXT_TAG_COLOR = colors.WHITE_1;
export const TAG_BACKGROUND_BLUE = colors.BLUE_4;
export const TAG_BACKGROUND_GREEN = colors.AVAG_GREEN;
export const TAG_BACKGROUND_ORANGE = colors.ORANGE_1;
export const TAG_BACKGROUND_RED = colors.RED_4;

/* FORMS */
/* Generic */
export const FIELD_LABEL = colors.GREY_11;
export const FIELD_BACKGROUND = colors.WHITE_1;
export const FIELD_BACKGROUND_ACTIVE = colors.BRAND_COLOR;
export const FIELD_BORDER_COLOR = colors.GREY_4;
export const FIELD_PLACEHOLDER_COLOR = colors.GREY_12;
export const FIELD_COLOR = colors.GREY_8;
export const FIELD_COLOR_ACTIVE = colors.WHITE_1;

export const DROPDOWN_BACKGROUND_ACTIVE = colors.LIGHT_BLUE;
export const DROPDOWN_COLOR_OPTIONS = colors.BLACK_2;

export const TEXT_COLOR = colors.GREY_8;
export const TEXT_COLOR1 = colors.RED_1;
export const TEXT_BUTTON_COLOR = colors.WHITE_1;

export const WEAK_PASSWORD = colors.RED_1;
export const STRONG_PASSWORD = colors.BRAND_COLOR;

export const TITLE_COLOR = colors.GREY_7;
export const DESCRIPTION_COLOR = colors.GREY_8;

export const LINE_SEPARATOR_COLOR = colors.GREY_2;
export const LINE_SEPARATOR_COLOR_MATERIAL = colors.AVAG_GREEN;
export const ROW_SEPARATOR_COLOR = colors.GREY_2;
export const SECTION_SEPARATOR_COLOR = colors.GREY_9;

export const FILE_DOWNLOAD_BACKGROUND = colors.LIGHT_BLUE;
export const FILE_DOWNLOAD_TEXT = colors.BRAND_COLOR;

/* Buttons */
export const BUTTON_BACKGROUND_DISABLED = colors.GREY_9;
export const BUTTON_BORDER_DISABLED = colors.GREY_9;
export const BUTTON_COLOR_DISABLED = colors.GREY_7;
export const BUTTON_PRIMARY_BACKGROUND = colors.BRAND_COLOR;
export const BUTTON_PRIMARY_BORDER = colors.BRAND_COLOR;
export const BUTTON_PRIMARY_COLOR = colors.WHITE_1;
export const BUTTON_BACKGROUND = colors.WHITE_1;
export const BUTTON_BORDER = colors.BRAND_COLOR;
export const BUTTON_COLOR = colors.BRAND_COLOR;
export const BUTTON_COLOR_HOVER = colors.DARK_GREEN_1;

/* Checkbox */
export const CHECKBOX_BACKGROUND_ACTIVE = colors.WHITE_1;
export const CHECKBOX_TICK_COLOR = colors.BRAND_COLOR;
export const CHECKBOX_TICK_COLOR_DISABLED = colors.GREY_7;

/* Switch */
export const SWITCH_BACKGROUND = colors.WHITE_1;
export const SWITCH_BACKGROUND_DISABLED = colors.GREY_1;
export const SWITCH_BACKGROUND_ACTIVE = colors.AVAG_GREEN;
export const SWITCH_BORDER_ACTIVE = colors.AVAG_GREEN;

/* Disabled */
export const DISABLED_BACKGROUND = colors.GREY_15;
export const FIELD_BORDER_COLOR_DISABLED = colors.GREY_9;
export const CHECKBOX_BORDER_COLOR_DISABLED = colors.GREY_2;
export const DISABLED_BORDER = colors.GREY_2;
export const DISABLED_COLOR = colors.GRAY_2;

/* Select */
export const SELECT_BORDER_COLOR = colors.AVAG_GREEN;
export const SELECT_COLOR = colors.AVAG_GREEN;
export const SELECT_BORDER_COLOR_HOVER = colors.DARK_GREEN_1;
export const SELECT_COLOR_HOVER = colors.DARK_GREEN_1;


/* Status message */
export const STATUS_TEXT = colors.WHITE_1;
export const STATUS_BACKGROUND = colors.GREY_8;

/* Errors */
export const ERROR_BACKGROUND = colors.GREY_8;
export const ERROR_BORDER_COLOR = colors.RED_1;
export const ERROR_COLOR = colors.RED_1;

/* Calendar */
export const CALENDAR_BORDER = colors.GREY_2;
export const CALENDAR_TEXT = colors.GREY_8;
export const CALENDAR_TITLE = colors.GREY_7;
export const CALENDAR_TEXT_TODAY = colors.BRAND_COLOR;
export const CALENDAR_TEXT_SELECTED = colors.WHITE_1;
export const CALENDAR_TEXT_WEEK_DAY = colors.GREY_11;
export const CALENDAR_DAY_BACKGROUND = colors.PALE_GREY;
export const CALENDAR_TODAY_BACKGROUND = colors.GREY_17;
export const CALENDAR_DAY_SELECTED_BACKGROUND = colors.BRAND_COLOR;
export const CALENDAR_FOOTER_BACKGROUND = colors.SAND;

/* Table */
export const TABLE_HEADER_BACKGROUND = colors.AVAG_GREEN;
export const TABLE_HEADER_COLOR = colors.WHITE_1;
export const TABLE_BACKGROUND = colors.WHITE_1;
export const TABLE_HOVER_BACKGROUND = colors.PALE_GREY;
export const TABLE_BORDER = colors.GREY_2;
export const TABLE_COLOR = 'rgba(0, 0, 0, 0.8)';
export const TABLE_FOOTER_BACKGROUND = colors.WHITE;

/* Mosaic items */
export const MOSAIC_ITEM_COLOR = colors.GREY_11;

/* Pagination */
export const PAGINATION_COLOR = colors.GREY_8;

/* Skeletons */
export const SKELETON_BACKGROUND = colors.GREY_2;

/* Export Data */
export const EXPORT_DESCRIPTION_COLOR = colors.BLACK_2;

/* Charts */
// export const CHART_BACKGROUNDS = [
//   colors.BLUE_5,
//   '#224568',
//   '#36677a',
//   '#479ba2',
//   '#58bec5',
//   colors.BLUE_6,
//   '#aedbda',
//   colors.GREY_16,
// ];
export const CHART_LABEL_COLOR = colors.GREY_11;
export const AREA_CHART_STROKE = colors.GREY_9;
export const AREA_CHART_FILL = colors.GREY_14;

/* Tooltip */
export const TOOLTIP_BACKGROUND = colors.WHITE_1;
export const TOOLTIP_SHADOW = colors.SHADOW;

let chartBackgrounds: string[] = [];

export const getChartBackgrounds = () => chartBackgrounds;

const generateChartBackgroundsRandomly = false;
export const buildChartBackgrounds = (items: number): string[] => {
  if (
    items === 0 || // eslint-disable-line operator-linebreak
    (items === chartBackgrounds.length && generateChartBackgroundsRandomly)
  ) {
    return chartBackgrounds;
  }

  // const baseColors = [colors.BLUE_5, colors.GREY_16, colors.BRAND_COLOR];
  const baseColors = [
    '#0B4F6C',
    '#01BAEF',
    '#6E8898',
    '#8CB369',
    '#4E6151',
    '#3B322C',
    '#BC4749',
    '#F8AF19',
    '#8789C0',
    '#7E1F86',
  ];

  if (!generateChartBackgroundsRandomly) {
    chartBackgrounds = baseColors;
    return chartBackgrounds;
  }

  // This will cause to generate always the exact same color palette
  // When this value is changed, a new color palette will be generated
  // with different values
  const colorPaletteID = 'avag';
  const countPerColor = Math.round(items / baseColors.length + 0.4);

  const options = {
    count: countPerColor,
    // luminosity: 'dark' as 'dark',
    seed: colorPaletteID,
  };

  chartBackgrounds = [];
  // eslint-disable-next-line arrow-parens
  baseColors.forEach((hue) => {
    chartBackgrounds.push(
      ...randomColor({
        ...options,
        hue,
      })
    );
  });
  chartBackgrounds = chartBackgrounds.slice(0, items);

  return chartBackgrounds;
};
