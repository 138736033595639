import { Action } from 'app/types';

export enum ActionTypes {
  GET_BOOKING_REFS_REQUEST = 'GET_BOOKING_REFS_REQUEST',
  GET_BOOKING_REFS_SUCCESS = 'GET_BOOKING_REFS_SUCCESS',
  GET_BOOKING_REFS_ERROR = 'GET_BOOKING_REFS_ERROR'
}

type GetBookingRefsRequest = Action<ActionTypes.GET_BOOKING_REFS_REQUEST>;
type GetBookingRefsSuccess = Action<ActionTypes.GET_BOOKING_REFS_SUCCESS>;
type GetBookingRefsError = Action<ActionTypes.GET_BOOKING_REFS_ERROR>;

type GetBookingRefsActionType = GetBookingRefsRequest | GetBookingRefsSuccess | GetBookingRefsError;

export type BookingRefsActionType = GetBookingRefsActionType;
