import { createGlobalStyle } from 'styled-components';
import { PRIMARY_BACKGROUND } from 'base/styles/skin';

// import 'typeface-roboto';
import 'base/styles/fonts.css';

export const Global = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body {
    background-color: ${ PRIMARY_BACKGROUND };
    //font-family: 'Roboto';
    font-family: 'TT Norms';
  }

  body {
    margin: 0;
    overflow: auto;
  }

  a:link,
  a:visited {
    text-decoration: inherit;
    color: inherit;
  }

  #root {
    // display: flex;
    // flex-direction: column;
    height: 100vh;
  }
`;
