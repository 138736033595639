/* eslint-disable max-lines */
/* eslint-disable max-len */
import { FormikValues } from 'formik';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { isMockingApi } from 'base';
import { useIsLoading } from 'app/hooks';
import { isAvagAdmin } from 'containers/Core/selectors';
import { RouteWithID } from 'app/types';
import { getBookingRefList } from 'containers/BookingRefs/selectors';
import { getCompany } from 'containers/Companies/actions';
import { PickupDetailsSkeleton } from 'components/Skeletons';
import { useModalData, useAutocompleteSuggestions } from 'containers/PickupRequest/hooks';
import { MODALS,
  PrintAction,
  RemoveAction,
  SaveAction,
  CancelAction,
  CloseAction,
  RequestHandler,
  RequestContainerList,
  SaveTemplate,
  ActionTemplate } from 'containers/PickupRequest/containers/PickupRequestDetails/types';
import { getLocationList } from 'containers/Location/selectors';
import { COMPANY_LIST } from 'containers/Companies/mocks';
import { Company, Customer } from 'containers/Companies/types';
import * as handlers from 'containers/PickupRequest/containers/PickupRequestDetails/handlers';
import * as templateHandlers from 'containers/PickupRequest/containers/Templates/handlers';
import { getContainerList } from 'containers/PickupRequest/actions';
import { PICKUP_REQUESTS_LIST } from 'app/routes';
import { useFormData } from './components/data';
import { TEMPLATE_MODALS } from './components/types';
import { getTemplateModalData } from './components/SaveTemplateModal/data/modals';
import View from './components/View';
import ExportModal from './components/SaveTemplateModal';
import { getTemplateDetails, getMaterialList } from '../../selectors';

import { useTemplateRequest } from '../../hooks';
import { TemplateRequest } from '../../types';
import {
  BackToTemplates, BackToTemplatesWrapper
} from 'containers/PickupRequest/containers/Templates/containers/TemplateDetails/components/styles';


interface Props {
  loadTemplateDetails?: boolean;
}
// eslint-disable-next-line max-lines-per-function
const TemplateDetails: React.FC<Props> = props => {
  const history = useHistory();
  const { id } = useParams<RouteWithID>();
  const dispatch = useDispatch();
  const isAdmin = useSelector(isAvagAdmin);
  const isLoading = useIsLoading(['get-template-requests']);
  const isProcessing = useIsLoading(['process-template-request']);
  const templateRequest = useSelector(getTemplateDetails(id));
  const materialList = useSelector(getMaterialList);
  const isUpdating = useIsLoading(['update-template-request']);
  const [values, setValues] = React.useState<FormikValues>();
  const locations = useSelector(getLocationList);
  const bookingRefs = useSelector(getBookingRefList);
  const [hasLoadedDetails, setHasDetails] = React.useState(!id);
  const [openModal, setOpenModal] = React.useState<MODALS>(undefined);
  const selectedCompany = values?.company?.id || templateRequest;
  const [showSaveTemplateModal, setshowSaveTemplateModal] = React.useState(false);
  const [modalConfig, setmodalConfig] = React.useState({ title: undefined, description: '', modalType: '' });
  const loadTemplateDetails = !!props.loadTemplateDetails;
  useTemplateRequest(setHasDetails);
  useAutocompleteSuggestions(isAdmin ? selectedCompany : undefined);

  React.useEffect(() => {
    if (modalConfig.title) {
      setshowSaveTemplateModal(true);
    }
  }, [modalConfig]);
  const initialValues = useSelector(getTemplateDetails(id));
  const templateName = initialValues ? initialValues.templateName : null;

  const updateTemplateRequestWithMaterialName = (
    tempRequest: TemplateRequest,
    mList: any
  ) => {
    const newTemplateRequest = { ...tempRequest };
    let materialOption = null;
    newTemplateRequest.materials = newTemplateRequest?.materials?.map(material => {
      materialOption = mList?.find(
        (m: { id: string }) => m?.id === material.materialId
      );
     
      material.materialName = materialOption?.name;
      return material;
    });
    return newTemplateRequest;
  };
  

  const handleSubmitExportModal = (templateValues: any) => {
    const newtemplatereq = {
      ...updateTemplateRequestWithMaterialName({ ...templateRequest, ...values }, materialList),
      ...templateValues
    };
    return handlers.saveTemplate({
      dispatch,
      history,
      pickupRequest: { ...newtemplatereq, ...values, ...templateValues },

    });
  };
  const handleSubmitUpdate = (templateValues: any) => {
    
    const newtemplaterequpdate = {
      ...updateTemplateRequestWithMaterialName({ ...templateRequest, ...values }, materialList),
      ...templateValues
    };
    return templateHandlers.updateTemplate({
      dispatch,
      history,
      pickupRequest: { ...newtemplaterequpdate, ...values, ...templateValues },

    });
  };
  const handleSubmitPlaceOrder = (templateValues: any) => {
    const newtemplatereqplaceorder = {
      ...updateTemplateRequestWithMaterialName({ ...templateRequest, ...values }, materialList),
      ...templateValues
    };
    return templateHandlers.placeOrderTemplate({
      dispatch,
      history,
      templateRequest: { ...newtemplatereqplaceorder, ...values, ...templateValues },
    });
  };
  const handleRemove: RemoveAction = () => {
    setOpenModal(MODALS.DELETE);
  };
  const handleClose: CloseAction = () => {
    history.push(PICKUP_REQUESTS_LIST.path);
  };

  const handlePrint: PrintAction = (isDirty: boolean) => async () => {
    return isDirty;

  };
  const handleAbort: CancelAction = () => {
    setOpenModal(MODALS.ABORT);
  };


  const handleSubmitDelete = () => {
    const { templateId } = initialValues;
    return templateHandlers.deleteTemplate({
      dispatch,
      history,
      templateId
    });
  };

  const modalDeleteConfigValues = getTemplateModalData(TEMPLATE_MODALS.TEMPLATE_DELETE, () => setshowSaveTemplateModal(false), handleSubmitDelete);
  const modalUpdateConfigValues = getTemplateModalData(TEMPLATE_MODALS.TEMPLATE_UPDATE, () => setshowSaveTemplateModal(false), handleSubmitUpdate);
  const modalSaveAsConfigValues = getTemplateModalData(TEMPLATE_MODALS.SAVE_AS_NEW_TEMPLATE, () => setshowSaveTemplateModal(false), handleSubmitExportModal);
  const modalPlaceOrderValues = getTemplateModalData(TEMPLATE_MODALS.TEMPLATE_PLACE_ORDER, () => setshowSaveTemplateModal(false), handleSubmitPlaceOrder);

  const handleSave: SaveAction = (newStatus: any) => {
    setmodalConfig(modalPlaceOrderValues);
    return newStatus;
  };

  const handleSaveTemplate: SaveTemplate = () => {
    
    setmodalConfig(modalSaveAsConfigValues);
  };
 
  const handleDeleteTemplate: ActionTemplate = () => {
    setmodalConfig(modalDeleteConfigValues);
  };

  const handleUpdateTemplate: ActionTemplate = () => {
    setmodalConfig(modalUpdateConfigValues);
  };
 
  const buttonActions = {
    handleClose,
    handleAbort,
    handlePrint,
    handleRemove,
    handleSave,
    handleSaveTemplate,
    handleDeleteTemplate,
    handleUpdateTemplate,
  };


  const newTemplate = true;
  // eslint-disable-next-line max-len
  const { buttons, fields, validationSchema } = useFormData({ buttonActions }, values, newTemplate, loadTemplateDetails);
  const modalProps = useModalData(openModal);

  const onDismiss = () => {
    handlers.dismiss(setOpenModal);
  };
  const onConfirm = () => {
    
    switch (openModal) {
      case MODALS.ABORT:
        return handlers.templateAbort({ history });

      case MODALS.PRINT:
        return handlers.print(setOpenModal);

      case MODALS.DELETE:
        return handlers.remove({ dispatch, history, id: templateRequest?.id, setOpenModal });

      default:
        return undefined;
    }
  };
  const handleSubmit = useCallback(() => {


  }, []);
  const modal = {
    ...modalProps,
    openModal,
    onConfirm,
    onDismiss
  };
  const handleCustomerListRequest = async (companyId: string): Promise<Customer[]> => {
    let collection: Customer[];
    const { type, payload } = await dispatch(getCompany(companyId));

    if (type.includes('SUCCESS')) {
      collection = (payload as Company).customers;
    } else if (isMockingApi() && type.includes('ERROR')) {
      collection = COMPANY_LIST[0].customers;
    }

    return collection;
  };

  const handleContainerListRequest: RequestContainerList = (companyId: string) => {
    dispatch(getContainerList(companyId));
  };
  const requestHandler: RequestHandler = {
    containerList: handleContainerListRequest,
    customerList: handleCustomerListRequest
  };
  if (isLoading || !hasLoadedDetails) {
    return <PickupDetailsSkeleton />;
  }

  // if (!isLoading) {
  //   return <p>No item found</p>;
  // }


  return (
    <div>
      <BackToTemplatesWrapper>
        <BackToTemplates href="/pickup-requests/templates">zur Vorlagenübersicht</BackToTemplates>
      </BackToTemplatesWrapper>
      <View
        buttons={ buttons }
        fields={ fields }
        initialValues={ initialValues }
        isLoading={
          isLoading
          || isUpdating || isProcessing
          // eslint-disable-next-line max-len
        }
        modalProps={ openModal && modal }
        onSubmit={ handleSubmit }
        requestHandler={ requestHandler }
        updateValues={ setValues }
        shouldShowAside={ !isAdmin }
        validationSchema={ validationSchema }
        locationData={ locations }
        bookingRefs={ bookingRefs }
      />
      { showSaveTemplateModal && <ExportModal
        templateName={ templateName }
        isLoading={
          isLoading
          || isUpdating || isProcessing
          // eslint-disable-next-line max-len
        }
        templateDateRule={ values.logistic.templateDateRule }
        modalConfig={ modalConfig }/> }
    </div>
  );
};

export default TemplateDetails;