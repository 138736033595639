import React from 'react';
import useMedia from 'react-use/esm/useMedia';
import { sortBy } from 'app/utils/sorting';
import getQuery, { TABLET } from 'base/styles/media-queries';
import AvagDot from 'components/AvagDot';
import Card from 'components/Card';
import { useTranslationsContext } from 'app/translations';
import Paginate from './components/pagination';
import * as renderers from './renderers';
import getFilteredRows from './search-engine';
import { Wrapper,
  ActionsBar,
  Title,
  Actions,
  StyledTable,
  TableHead,
  TableBody,
  TableRow,
  TableFooter } from './styles';
import { renderAction, renderRows, renderHeadings } from './utils';
import { Column, Pagination, SortingOptions } from './types';
import Loading from './components/loading';

export { renderers };

interface Props<R> {
  actions?: ('filter' | React.ReactNode)[];
  className?: string;
  columns: Column<R>[];
  emptyMessage?: string;
  filterValue?: string;
  footerActions?: React.ReactNode[];
  isLoading?: boolean;
  onClick?: (row: R) => void;
  onFilter?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSort?: (row: string, order: string) => void;
  pagination?: Pagination;
  rows: R[];
  title?: string;
  sortKey?: keyof R;
  sortEnabled?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  showTitle?: boolean;
  backSorting?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const Table = <R extends unknown>({ actions = [],
  className,
  columns,
  emptyMessage,
  filterValue = '',
  footerActions = [],
  isLoading = false,
  onClick,
  onFilter,
  onSort,
  pagination,
  rows,
  title,
  sortKey,
  sortEnabled = true,
  showHeader = true,
  showFooter = true,
  showTitle = true,
  backSorting = false }: Props<R>): React.ReactElement | null => {
  const { table_emptyMessage } = useTranslationsContext();
  const isTabletOrLarger = useMedia(getQuery(TABLET));
  const [sortedBy, setSortedBy] = React.useState<keyof R>(sortKey || 'creationDate' as keyof R);
  const [reverse, setReverse] = React.useState(backSorting);
  const [sortedRows, setSortedRows] = React.useState(rows);
  const [filteredColumns, setFilteredColumns] = React.useState(columns);
  const [nativeFilter, setNativeFilter] = React.useState('');
  
  React.useEffect(() => {
    if (backSorting) {
      onSort(sortedBy.toString(), reverse ? 'asc' : 'desc');
      setSortedRows(rows);
    } else {
      const filtered = getFilteredRows(rows, nativeFilter);
      const sorted = !sortedBy ? filtered : filtered.sort(sortBy<R>(sortedBy, reverse ? 'desc' : 'asc'));
      setSortedRows(sorted);
    }
  }, [rows, sortedBy, reverse, nativeFilter]);

  React.useEffect(() => {
    const filtered = columns
      .filter(({ mobile }) => isTabletOrLarger || (!isTabletOrLarger && mobile))
      .map(f => {
        if (f.key === 'navigate' && !f.renderer) {
          return {
            ...f,
            renderer: renderers.ArrowButton
          };
        }
        return f;
      });
    setFilteredColumns(filtered);
  }, [isTabletOrLarger, columns]);

  if (!filteredColumns.length) {
    return null;
  }
  const isPaginated = true;
  // const isPaginated = pagination?.totalCount > pagination?.pageSize && sortedRows.length >= pagination?.pageSize;
  const isFooterVisible = showFooter && (isPaginated || !!footerActions.length);

  const sortingOptions: SortingOptions<R> = {
    sortedBy,
    setSortedBy,
    reverse,
    setReverse
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    setNativeFilter(target.value);
  };

  return (
    <Wrapper className={ className } showTitle={ showTitle }>
      { showTitle && (
        <ActionsBar>
          <Title>
            { title && (
              <>
                <AvagDot /> { title }
              </>
            ) }
          </Title>
          <Actions>
            <React.Fragment>
              { actions.map((action, index) => {
                return renderAction({ action, index, handleFilter: onFilter || handleFilter });
              }) }
            </React.Fragment>
          </Actions>
        </ActionsBar>
      ) }

      <Card overflow={ true } indentation={ false }>
        <>
          <StyledTable>
            { showHeader && (
              <TableHead>
                <TableRow>
                  { renderHeadings<R>(filteredColumns, showTitle, sortEnabled ? sortingOptions : undefined) }
                </TableRow>
              </TableHead>
            ) }

            <TableBody isPaginated={ isPaginated }>
              <Loading columns={ filteredColumns.length } isLoading={ !!isLoading } />
              { renderRows<R>({
                columns: filteredColumns,
                emptyMessage: emptyMessage || table_emptyMessage,
                filterValue,
                onClick,
                rows: sortedRows
              }) }
            </TableBody>
          </StyledTable>

        </>
      </Card>
      { isFooterVisible && (
        <TableFooter>
          { isPaginated && <Paginate { ...pagination } /> }
          <Actions>
            { footerActions.map((action, index) =>
              renderAction({ action, index, handleFilter: onFilter || handleFilter })) }
          </Actions>
        </TableFooter>
      ) }
    </Wrapper>
  );
};

export default Table;
