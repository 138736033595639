import { Notification, NOTIFICATION_TYPE } from '../types';

export const NOTIFICATION_LIST: Notification[] = [
  {
    id: '1234',
    date: 1580893149831,
    notification: 'Rechnung #1234567890 ist verfügbar',
    status: 2,
    entityType: NOTIFICATION_TYPE.BILL,
    entityId: '121'
  },
  {
    id: '12345',
    date: 1580893149831,
    notification: 'Auftrag #87239 ist jetzt bearbeitet',
    status: 1,
    entityType: NOTIFICATION_TYPE.PICKUP,
    entityId: '2321'
  },
  {
    id: '12346',
    date: 1580893149831,
    notification: 'Rechnung #73648 ist in 5 Tagen fällig',
    status: 1,
    entityType: NOTIFICATION_TYPE.BILL,
    entityId: '145'
  },
  {
    id: '12347',
    date: 1580893149831,
    notification: 'Lieferschein #987436592 ist verfügbar',
    status: 1,
    entityType: NOTIFICATION_TYPE.DELIVERY,
    entityId: '1674'
  },
  {
    id: '12348',
    date: 1580893149831,
    notification: 'Andi Brechtbühl ist jetzt auf dem Portal aktiv',
    status: 1,
    entityType: NOTIFICATION_TYPE.USER,
    entityId: '1257'
  },
  {
    id: '12349',
    date: 1580893149831,
    notification: 'Andi Brechtbühl ist jetzt auf dem Portal aktiv',
    status: 1,
    entityType: NOTIFICATION_TYPE.USER,
    entityId: '1658'
  }
];
