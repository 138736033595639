import { LangKeys } from 'app/translations';
import { Column } from 'components/Table/types';
import Tag from '../components/Tag';
import { NotificationRow } from '../types';

export const createColumns = (translations: LangKeys): Column<NotificationRow>[] => [
  {
    key: 'id',
    label: translations.notifications_id
  },
  {
    key: 'date',
    label: translations.notifications_date
  },
  {
    key: 'notification',
    label: translations.notifications_message,
    isLeftAlign: true
  },
  {
    key: 'status',
    renderer: Tag
  },
  {
    key: 'navigate'
  }
];
