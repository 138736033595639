import React from 'react';
import { Container, Icon } from './styles';

const lockOn = require('assets/icons/lockOn.svg');
const lockOff = require('assets/icons/lockOff.svg');

interface Props {
  locked?: boolean;
}

const LockIcon: React.FC<Props> = ({ locked = false }) => (
  <Container>
    <Icon src={ locked ? lockOn : lockOff } />
  </Container>
);

export default LockIcon;
