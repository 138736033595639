import { DeliverySlip } from 'containers/DeliverySlips/types';
import { DeliverySlipStatus } from '../models';

export const DELIVERY_SLIP_LIST: DeliverySlip[] = [
  {
    date: 1580893149831,
    id: 'VA2425232',
    associateBillId: '8100',
    docNoOccurrence: '1',
    versionNo: '4',
    companyReference: 'C23422',
    dmsLinkID: '',
    driver: {
      numberPlate: 'BE47812',
      address: 'Brawand Transport GmbH Grindelwaldstrasse 49 Postfach 18 3818 Grindelwald'
    },
    bookingReference: '43452',
    status: DeliverySlipStatus.APPROVED,
    attachments: [
      {
        id: '1',
        name: 'picture1.jpg',
        fileType: '.jpg',
        docType: '519'
      },
      {
        id: '2',
        name: 'picture2.jpg',
        fileType: '.jpg',
        docType: '519'
      }
    ],
    materials: [
      {
        itemReference: '11-111-004',
        material: 'Gewerbeabfälle',
        unit: 'TO',
        gross: 23679,
        tare: 19150,
        net: 4520,
        retailPrice: 0.17
      }
    ],
    PDFUrl: 'url'
  },
  {
    date: 1580893149831,
    id: 'VA2400232',
    associateBillId: '8802',
    docNoOccurrence: '1',
    versionNo: '4',
    companyReference: 'C23422',
    dmsLinkID: '',
    driver: {
      numberPlate: 'BE47812',
      address: 'Brawand Transport GmbH Grindelwaldstrasse 49 Postfach 18 3818 Grindelwald'
    },
    bookingReference: '43452',
    status: DeliverySlipStatus.NEW,
    attachments: [
      {
        id: '1',
        name: 'picture.jpg',
        fileType: '.jpg',
        docType: '519'
      }
    ],
    materials: [
      {
        itemReference: '11-111-004',
        material: 'Gewerbeabfälle',
        unit: 'TO',
        gross: 23679,
        tare: 19150,
        net: 4520,
        retailPrice: 0.17
      }
    ],
    PDFUrl: 'url'
  },
  {
    date: 1580893149831,
    id: 'VA2425002',
    associateBillId: '67478',
    docNoOccurrence: '1',
    versionNo: '4',
    companyReference: 'C23422',
    dmsLinkID: '',
    driver: {
      numberPlate: 'BE47812',
      address: 'Brawand Transport GmbH Grindelwaldstrasse 49 Postfach 18 3818 Grindelwald'
    },
    bookingReference: '34678',
    status: DeliverySlipStatus.NEW,
    attachments: [],
    materials: [
      {
        itemReference: '11-111-004',
        material: 'Gewerbeabfälle',
        unit: 'TO',
        gross: 23679,
        tare: 19150,
        net: 4520,
        retailPrice: 0.17
      }
    ],
    PDFUrl: 'url'
  },
  {
    date: 1580893149831,
    id: 'VA0125232',
    associateBillId: '29704',
    docNoOccurrence: '1',
    versionNo: '4',
    companyReference: 'C23422',
    dmsLinkID: '',
    driver: {
      numberPlate: 'BE47812',
      address: 'Brawand Transport GmbH Grindelwaldstrasse 49 Postfach 18 3818 Grindelwald'
    },
    bookingReference: '',
    status: DeliverySlipStatus.NEW,
    attachments: [
      {
        id: '1',
        name: 'picture1.jpg',
        fileType: '.jpg',
        docType: '519'
      },
      {
        id: '2',
        name: 'picture2.jpg',
        fileType: '.jpg',
        docType: '519'
      },
      {
        id: '3',
        name: 'picture3.jpg',
        fileType: '.jpg',
        docType: '519'
      }
    ],
    materials: [
      {
        itemReference: '11-111-004',
        material: 'Gewerbeabfälle',
        unit: 'TO',
        gross: 23679,
        tare: 19150,
        net: 4520,
        retailPrice: 0.17
      }
    ],
    PDFUrl: 'url'
  },
  {
    date: 1580893149831,
    id: 'VA2404232',
    associateBillId: '74492',
    docNoOccurrence: '1',
    versionNo: '4',
    companyReference: 'C23422',
    dmsLinkID: '',
    driver: {
      numberPlate: 'BE47812',
      address: 'Brawand Transport GmbH Grindelwaldstrasse 49 Postfach 18 3818 Grindelwald'
    },
    bookingReference: '76543',
    status: DeliverySlipStatus.NEW,
    attachments: [],
    materials: [
      {
        itemReference: '11-111-004',
        material: 'Gewerbeabfälle',
        unit: 'TO',
        gross: 23679,
        tare: 19150,
        net: 4520,
        retailPrice: 0.17
      }
    ],
    PDFUrl: 'url'
  }
];
