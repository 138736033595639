/* eslint-disable max-lines */
import React from 'react';
// import { useTranslationsContext } from 'app/translations';
import { IndexedFields } from 'app/types';
import Field from 'components/SmartForm/components';
import { DriverOrders } from 'containers/PickupRequest/types';
import { DriverOrderFieldName } from '../../../../types';
import { FieldsWrapper,
  DriverOrderRowContainer,
  ContentWrapper,
  DateWrapper,
  DateWrapperRed,
  TimeWrapper } from './styles';

interface Props {
 fields: IndexedFields<DriverOrderFieldName>;
  values: DriverOrders;
}

const DriverOrderRow: React.FC<Props> = ({ fields, values }) => {
  // const translations = useTranslationsContext();
  return (
    <DriverOrderRowContainer indentation={ false }>
      <FieldsWrapper>
        <ContentWrapper>
          <Field options={ fields.driverOrderId } />
        </ContentWrapper>
        { /* <ContentWrapper>
          <Field options={ fields.driverOrderContent } />
        </ContentWrapper> */ }
        {
          values?.isdriverOrderExecutionDate === false 
            ? <DateWrapper >
              <Field options={ fields.deliveryDate } />
            </DateWrapper>
            : <DateWrapperRed>
              <Field options={ fields.deliveryDate } />
            </DateWrapperRed>
        }
        { /* <TimeWrapper>
          <Field options={ fields.deliveryFrameId } />
        </TimeWrapper> */ }
        <TimeWrapper>
          <Field options={ fields.driveOrderStatusId } />
        </TimeWrapper>
      </FieldsWrapper>
    </DriverOrderRowContainer>
  );
};

export default DriverOrderRow;
