import React from 'react';
import { isWeightChart } from 'app/utils/chart';
import { formatCurrency, formatDate } from 'app/utils/formatters';
import { LegendWrapper, ListWrapper, ListItem, TagColor, Content, Title, Details, ListTitle } from './styles';

interface ItemProps {
  color?: string;
  cost?: number;
  date?: number;
  metricUnits?: string[];
  title?: string;
  weight?: number;
}

export const LegendItem: React.FC<ItemProps> = ({ color, cost, date, metricUnits = [], title, weight }) => {
  const isSingleValue = metricUnits.length === 1;
  const isWeight = isWeightChart(metricUnits);
  const parsedDate = formatDate(new Date(date));

  return (
    <ListItem>
      { color && <TagColor color={ color } /> }

      <Content>
        <Title>{ title || parsedDate }</Title>

        { title && date && <Details>{ parsedDate } </Details> }
        { isSingleValue && isWeight && <Details>{ formatCurrency(weight, metricUnits[0]) }</Details> }
        { isSingleValue && !isWeight && <Details>{ formatCurrency(cost, metricUnits[0]) }</Details> }

        { !isSingleValue && (
          <>
            <Details>{ formatCurrency(weight, metricUnits[1]) }</Details>
            <Details>{ formatCurrency(cost, metricUnits[0]) }</Details>
          </>
        ) }
      </Content>
    </ListItem>
  );
};

interface ChildrenProps {
  LegendItem: typeof LegendItem;
}

interface Props {
  children: ({ LegendItem }: ChildrenProps) => React.PropsWithChildren<{}>['children'];
  isAreaChart?: boolean;
  isOverview?: boolean;
  maxHeight?: number;
  title: React.ReactElement;
}

const Legend: React.FC<Props> = ({ children, isAreaChart, isOverview = false, maxHeight, title }: Props) => (
  <LegendWrapper isAreaChart={ isAreaChart } isOverview={ isOverview }>
    { title && <ListTitle>{ title }</ListTitle> }
    <ListWrapper hasTitle={ !!title } isAreaChart={ isAreaChart } isOverview={ isOverview } maxHeight={ maxHeight }>
      { children({ LegendItem }) }
    </ListWrapper>
  </LegendWrapper>
);

export default Legend;
