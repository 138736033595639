/* eslint-disable max-len */
import { FormikValues } from 'formik';
import React, { useState } from 'react';
import { applyDateLimits } from 'app/utils/validations';
import { createIndexedFields } from 'app/utils/fields';
import Modal from 'components/Modal';
import SmartForm from 'components/SmartForm';
import { FieldName } from 'app/containers/PickupRequest/containers/PickupRequestDetails/types';
import { DateRuleDropdown } from 'app/constants/pickupRequest';
import { useTranslationsContext } from 'app/translations';
import { TEMPLATE_DETAILS } from 'app/routes';
import { useButtons } from './data/buttons';
import { useFields } from './data/fields';
import { TextDescription, FormWrapper, Body, Footer,
  ButtonsWrapper } from '../../../TemplateDetails/components/SaveTemplateModal/styles';
import { FieldWrapperTemplateName, ShowDetailsLink, DateWrapper, WarningMessageWrapper } from '../styles';

interface Props {
  onClose: any;
  selectedTemplate: any;
  onConfirm: (values: any) => void;
}

const ExportModal: React.FC<Props> = ({ onClose, onConfirm, selectedTemplate }) => {
  const [values, setValues] = useState<FormikValues>();
  const translations = useTranslationsContext();
  const fields = applyDateLimits(useFields(), values);
  const buttons = useButtons(onClose);
  const wishdate = `${ translations.pickupRequestDetails_preferredDateLabel } ${ ': ' }`; 
  //  const formInitialValues = { format: fields.find(f => f.id === ExportFieldName.checkboxSave) };
  const onFormSubmit = React.useCallback(() => {
    let pickupWishDate = '';
    const templateValues = { templateActionDetails: values?.templateActionDetails 
      ? values?.templateActionDetails?.templatePlaceOrderDate : null };
    if (templateValues !== null) {
      pickupWishDate = new Date(templateValues?.templateActionDetails).toISOString().split('T')[0].replace(/\//g, '-');
    }
    onConfirm(pickupWishDate);
  }, [values]);

  const getDateString = (currentDate: Date) => {
    return <span>{ `${ String(currentDate.getDate()).padStart(2, '0') }.${ String(currentDate.getMonth() + 1).padStart(2, '0') }.${ currentDate.getFullYear().toString().substr(2, 2) }` }</span>;
  };

  const getDateFromRule = (dateRule: number) => {
    const currentDate = new Date();
    switch (dateRule) {
      case DateRuleDropdown.date_rule_haute:
        return getDateString(currentDate);

      case DateRuleDropdown.date_rule_haute_1:
        currentDate.setDate(currentDate.getDate() + 1);
        return getDateString(currentDate);

      case DateRuleDropdown.date_rule_haute_2:
        currentDate.setDate(currentDate.getDate() + 2);
        return getDateString(currentDate);

      case DateRuleDropdown.date_rule_haute_3:
        currentDate.setDate(currentDate.getDate() + 3);
        return getDateString(currentDate);

      case DateRuleDropdown.date_rule_haute_4:
        currentDate.setDate(currentDate.getDate() + 4);
        return getDateString(currentDate);

      case DateRuleDropdown.date_rule_haute_5:
        currentDate.setDate(currentDate.getDate() + 5);
        return getDateString(currentDate);

      default:
        return '';
    }
  };

  const lastusedState = () => {
    const lastcheck = new Date(selectedTemplate?.lastUsed);
    const currentTime = new Date();
    let diff: number = (currentTime.getTime() / 1000) - (lastcheck.getTime() / 1000);
    diff = Math.floor(diff / 3600);
    if (diff <= 2) {
      return true;
    }
    return false;
  }; 
  return (
    <Modal onClose={ onClose } header={ translations.template_modalBox_placeOrder } size="auto">
      <>
        <TextDescription>{ translations.template_home_modal_text_description }</TextDescription>

        <SmartForm
          form={ 'exportData' }
          buttons={ buttons }
          fields={ fields }
          handleSubmit={ onFormSubmit }
        >
          { ({ controlledFields, controlledButtons, Field, Buttons, values: vals }) => {
            const indexedFields = createIndexedFields(controlledFields);
            const field = indexedFields;
            setValues(vals);
            return (
              <>
                <Body>
                  { lastusedState() 
                    ? <WarningMessageWrapper>{ translations.template_warning_message_modalBox } </WarningMessageWrapper> 
                    : null
                  } 
                  <FieldWrapperTemplateName>
                    { translations.template_modalBox_template_Name }:{ selectedTemplate.templateName }
                  </FieldWrapperTemplateName>
                  <ShowDetailsLink to={ TEMPLATE_DETAILS.path.replace(':id', selectedTemplate.templateId.toString()) }>
                    { translations.template_view_details_link }
                  </ShowDetailsLink> 
                  { selectedTemplate.templateDateRule === DateRuleDropdown.date_rule_datum_wahlen
                    ? <FormWrapper>
                      <DateWrapper>
                        <Field options={ { ...field[FieldName.templatePlaceOrderDate] } } />
                      </DateWrapper>
                    </FormWrapper>
                    : <FormWrapper> <span>{ wishdate } { getDateFromRule(selectedTemplate.templateDateRule) }  </span>
                    </FormWrapper>
                  }
                </Body>
                <Footer>
                  <ButtonsWrapper>
                    <Buttons options={ controlledButtons } />
                  </ButtonsWrapper>
                </Footer>
              </>
            );
          } }
        </SmartForm>
      </>
    </Modal>
  );
};

export default ExportModal;
