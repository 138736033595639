import { ChartData } from 'app/containers/Statistics/types';

export const getPositiveAndNegativeGroups = (chartData: ChartData[], isWeightChart: boolean) =>
  chartData.reduce(
    (prev, curr) => {
      const { cost, width } = curr;
      const height = Math.abs(curr.height);

      if (cost >= 0 || isWeightChart) {
        prev.negativeBars.push({ ...curr, height: 0 });
        prev.positiveBars.push(curr);
        prev.maxHeight = prev.maxHeight >= height ? prev.maxHeight : height;
      } else {
        prev.negativeBars.push(curr);
        prev.positiveBars.push({ ...curr, height: 0 });
        prev.minHeight = prev.minHeight >= height ? prev.minHeight : height;
      }

      prev.totalWidth += width;

      return prev;
    },
    { maxHeight: 0, minHeight: 0, positiveBars: [] as ChartData[], negativeBars: [] as ChartData[], totalWidth: 0 }
  );

  
export const checkWidth = (pos: ChartData[], neg: ChartData[]) => {
  const countArrayPos = pos.map(e => {
    let count = 1;
    let finalWidth = e.width;
    while (finalWidth > 500) {
      finalWidth = e.width / count;
      count += 1;
    }
    return count;
  });
  const countArrayNeg = neg.map(e => {
    let count = 1;
    let finalWidth = e.width;
    while (finalWidth > 500) {
      finalWidth = e.width / count;
      count += 1;
    }
    return count;
  });
  
  return Math.max(...countArrayPos, ...countArrayNeg);
};
