import { RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as ROUTES from 'app/routes';
import { mainLayoutWrapper } from 'app/utils/componentWrappers';
import Bills from 'containers/Bills';
import Companies from 'containers/Companies';
import DeliverySlips from 'containers/DeliverySlips';
import ForgotPassword from 'containers/ForgotPassword';
import Home from 'containers/Home';
import Help from 'containers/Help';
import Login from 'containers/Login';
import Logout from 'containers/Login/logout';
import Notifications from 'containers/Notifications';
import Onboarding from 'containers/Onboarding';
import PrivacyAgreementView from 'containers/Onboarding/components/PrivacyAgreementView';
import PickupRequest from 'containers/PickupRequest';
import Quotas from 'containers/Quotas';
import ResetPassword from 'containers/ResetPassword';
import Statistics from 'containers/Statistics';
import Users from 'containers/Users';
import { canDo } from '../selectors';

export const useGuestRoutes = (): RouteProps[] => [
  { path: ROUTES.ONBOARDING.path, component: Onboarding },
  { path: ROUTES.PRIVACY_AGREEMENT_INFO.path, component: PrivacyAgreementView },
  { path: ROUTES.FORGOT_PASSWORD.path, component: ForgotPassword },
  { path: ROUTES.RESET_PASSWORD.path, component: ResetPassword },
  { path: ROUTES.ROOT.path, component: Login, exact: true }
];

export const useUserRoutes = (): RouteProps[] => {
  const permissions = {
    billsAvailable: useSelector(canDo('VIEW_BILLS')),
    deliverySlipsAvailable: useSelector(canDo('VIEW_DELIVERY_SLIPS')),
    helpAvailable: useSelector(canDo('VIEW_HELP')),
    notificationsAvailable: useSelector(canDo('VIEW_NOTIFICATIONS')),
    pickupsAvailable: useSelector(canDo('VIEW_PICKUPS')),
    quotasAvailable: useSelector(canDo('VIEW_QUOTAS')),
    settingsAvailable: useSelector(canDo('VIEW_SETTINGS')),
    statisticsAvailable: useSelector(canDo('VIEW_STATISTICS')),
    usersAvailable: useSelector(canDo('VIEW_USERS')),
  };

  const routes: RouteProps[] = [];

  routes.push({ path: ROUTES.HOME.path, render: mainLayoutWrapper(Home, permissions) });

  if (permissions.billsAvailable) {
    routes.push({ path: ROUTES.BILL_LIST.path, render: mainLayoutWrapper(Bills, permissions) });
  }

  if (permissions.deliverySlipsAvailable) {
    routes.push({ path: ROUTES.DELIVERY_SLIP_LIST.path, render: mainLayoutWrapper(DeliverySlips, permissions) });
  }

  if (permissions.helpAvailable) {
    routes.push({ path: ROUTES.HELP.path, render: mainLayoutWrapper(Help, permissions) });
  }

  if (permissions.notificationsAvailable) {
    routes.push({ path: ROUTES.NOTIFICATIONS.path, render: mainLayoutWrapper(Notifications, permissions) });
  }

  
  if (permissions.pickupsAvailable) {
    routes.push({ path: ROUTES.PICKUP_REQUESTS_LIST.path, render: mainLayoutWrapper(PickupRequest, permissions) });
  }

  if (permissions.quotasAvailable) {
    routes.push({ path: ROUTES.QUOTAS.path, render: mainLayoutWrapper(Quotas, permissions) });
  }

  if (permissions.settingsAvailable) {
    routes.push({ path: ROUTES.SETTINGS.path, render: mainLayoutWrapper(Companies, permissions) });
  }
  

  if (permissions.statisticsAvailable) {
    routes.push({ path: ROUTES.STATISTICS.path, render: mainLayoutWrapper(Statistics, permissions) });
  }

  routes.push(
    {
      path: ROUTES.USERS.path,
      render: mainLayoutWrapper(Users, permissions, { usersAvailable: permissions.usersAvailable })
    },
    { path: ROUTES.LOGOUT.path, component: Logout }
  );

  return routes;

};