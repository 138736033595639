import styled, { css } from 'styled-components';
import { DESKTOP, MAX_TABLET_L } from 'base/styles/media-queries';

import { FOOTER_SIZE, FOOTER_SPACE } from 'base/styles/sizes';
import { BACKGROUND, PRIMARY_BACKGROUND } from 'base/styles/skin';

export const Container = styled.div`
  min-height: calc(100vh - ${ FOOTER_SIZE }px);
  overflow: hidden;
  overflow-y: auto;
  width: 100%;

  ${ DESKTOP } {
    display: flex;
    flex-direction: row-reverse;
  }
`;
Container.displayName = 'Container';

const commonCSS = css`
  padding: 20px 10px;
  width: 100%;

  ${ DESKTOP } {
    flex: 1;
  }
`;

export const LeftContent = styled.div`
  ${ commonCSS }

  background-color: ${ BACKGROUND };
  //box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.1);

  ${ MAX_TABLET_L }{

    padding-bottom: ${ FOOTER_SPACE }px;
  }
`;
LeftContent.displayName = 'LeftContent';

export const RightContent = styled.div`
  ${ commonCSS }

  background-color: ${ PRIMARY_BACKGROUND };
`;
RightContent.displayName = 'RightContent';
