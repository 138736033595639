import { combineReducers } from 'redux';

import bookingRefs from 'containers/BookingRefs/reducers';
import bills from 'containers/Bills/reducers';
import companies from 'containers/Companies/reducers';
import core from 'containers/Core/reducers';
import deliverySlips from 'containers/DeliverySlips/reducers';
import form from 'components/SmartForm/reducers';
import location from 'containers/Location/reducers';
import login from 'containers/Login/reducers';
import pickupRequest from 'containers/PickupRequest/reducers';
import { reducer as requests } from 'base/shared/request-identifier';
import statistics from 'containers/Statistics/reducers';
import notifications from 'containers/Notifications/reducers';
import quotas from 'containers/Quotas/reducers';
import users from 'containers/Users/reducers';
import templateRequest from 'containers/PickupRequest/containers/Templates/reducers';

export const rootReducer = combineReducers({
  bookingRefs,
  bills,
  companies,
  core,
  deliverySlips,
  form,
  location,
  login,
  pickupRequest,
  requests,
  statistics,
  notifications,
  quotas,
  users,
  templateRequest
});
