import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RouteWithID } from 'app/types';
import * as actions from '../actions';

export const useBillListRequest = (currentPage: number, pageSize: number, searchValue: string) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getBillList(currentPage, pageSize, searchValue));
  }, [searchValue, currentPage]);
};

export const useBillRequest = () => {
  const { id, docType } = useParams<RouteWithID>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(actions.getBill(id, docType));
    }
  }, [id]);
};

export const useBillsReset = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.resetBills());
  }, []);
};
