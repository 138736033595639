import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NOTIFICATIONS } from 'app/routes';
import { PropsWithWrapper } from 'app/types';
import Redirect from 'components/Redirect';
import NotificationList from './containers';

const Notifications: React.FC<PropsWithWrapper<{}>> = ({ wrapper }) => (
  <Switch>
    <Route exact={ true } path={ NOTIFICATIONS.path } component={ wrapper(NotificationList) } />
    <Redirect to={ NOTIFICATIONS.path } />
  </Switch>
);

export default Notifications;
