import styled from 'styled-components';
import { DESKTOP, TABLET } from 'base/styles/media-queries';
import { MAX_WIDTH_ASIDE_HOME_SIZE_DESKTOP } from 'base/styles/sizes';
import { CARD_BORDER } from 'base/styles/skin';

const backgroundImage = require('assets/images/home_bg_icon.svg');

// export const ButtonWrapperTemplate = styled.div`
// display: none;
// ${ DESKTOP }{
//   display: block;
// }
// `;

export const Wrapper = styled.div`
display: none;
width: 100%;
margin-bottom: 24px;
${ TABLET } {
  margin-left: 16px;
  margin-bottom: 0;
}

  ${ DESKTOP } {
    display: flex;
    flex-direction: column;
    flex: initial !important;
    margin-left: 16px;
    width: ${ MAX_WIDTH_ASIDE_HOME_SIZE_DESKTOP }px;
    .templateSectionHome {
      align-items: start;
      //padding-bottom: 15px;
      padding-bottom: 0px;
        //height: 46px;
        button{
            margin: 0px 0px 0px 20px;
        }
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export const CardContent = styled.div`
  ${ DESKTOP } {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-image: url(${ backgroundImage });
    background-repeat: no-repeat;
    background-position: 50% 30%;
    background-size: 80%;
    background-color: #fff;
    border-radius: 4px;
    //box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.1);
    border: solid 1px ${ CARD_BORDER };
    cursor: pointer;
  }
`;
CardContent.displayName = 'CardContent';

export const WrapperButton = styled.div`
  padding-right: 20px;
  padding-bottom: 40px;
`;
WrapperButton.displayName = 'WrapperButton';

export const WrapperButtonMobile = styled.div`
  width: 100%;
  padding-bottom: 20px;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;

    & > button {
      margin-left: 0;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  ${ TABLET } {
    display: none;
  }
`;
WrapperButtonMobile.displayName = 'WrapperButtonMobile';
