import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { HELP } from 'app/routes';
import { PropsWithWrapper } from 'app/types';
import Redirect from 'components/Redirect';
import HelpForm from './containers';

const Help: React.FC<PropsWithWrapper<{}>> = ({ wrapper }) => (
  <Switch>
    <Route exact={ true } path={ HELP.path } component={ wrapper(HelpForm) } />
    <Redirect to={ HELP.path } />
  </Switch>
);

export default Help;
