import React, { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  target?: 'modal' | 'sidebar';
}

const Portal: React.FC<PropsWithChildren<Props>> = ({ children, target = 'modal' }) => {
  const portalRoot = document.getElementById(`${ target }-root`);
  const rootElemRef = React.useRef(document.createElement('div'));

  React.useEffect(() => {
    if (portalRoot) {
      portalRoot.appendChild(rootElemRef.current);
    }

    return () => {
      // Fix for IE11
      portalRoot.removeChild(rootElemRef.current);
      rootElemRef.current = undefined;
    };
  }, []);

  if (!portalRoot) {
    return null;
  }

  return createPortal(children, rootElemRef.current);
};

export default Portal;
