import { DriverOrderStatus, TimePeriod } from 'app/constants/pickupRequest';
import { createPaginationFromServer } from 'app/models';
import { PickupRequestState, PickupRequest, PickupRequestRow, MaterialDetails, DriverOrders } from '../types';

export * from './containers';

export const createRowFromPickupRequest = (pickupRequest: PickupRequest): 
PickupRequestRow => ({
  lock: pickupRequest.status,
  address: pickupRequest.logistic?.location,
  creationDate: pickupRequest.createdDate,
  company: pickupRequest.company.name,
  id: pickupRequest.id,
  owner: `${ pickupRequest.owner.firstName } ${ pickupRequest.owner.lastName }`,
  preferredDate: pickupRequest.logistic?.preferredDate,
  status: pickupRequest.status,
  isVeva: !!pickupRequest?.materials?.some(m => m.isVeva),
  navigate: pickupRequest.id,
  isWishDateSame: pickupRequest.isWishDateSame
  // isWishDateSame: !!pickupRequest?.driverOrders?.some(drive => drive?.isdriverOrderExecutionDate === true)
  
});

export const createMaterialRow = (
  requestedDate = new Date().getTime(),
  timePeriod = TimePeriod.timePeriod_flexible
): MaterialDetails => ({
  id: undefined,
  containerId: undefined,
  materialId: '1',
  materialName: '',
  date: requestedDate,
  deliverContainers: 0,
  destination: '',
  pickupContainers: 0,
  timeSlot: timePeriod,
  handleDocumentation: false,
  addons: [],
  isVeva: false
});

export const DriverOrderRow = (
  // requestedDate = new Date().getTime(),
  timeSlot = TimePeriod.timePeriod_flexible,
  driveOrderStatusClose = DriverOrderStatus.driver_order_status_new
): DriverOrders => ({
  driverOrderContent: '',
  deliveryDate: '',
  pickupId: undefined,
  deliveryFrameId: timeSlot,
  driveOrderStatusId: driveOrderStatusClose,
  driverOrderId: '1',
});

export const pickupRequestInitialState: PickupRequestState = {
  collection: [],
  pagination: createPaginationFromServer({}),
  dropdownGroup: {
    customerList: [],
    containerList: [],
    materialList: [],
    recentsList: [],
    typesList: []
  }
};

export const pickupRequestDetailsInitialState: PickupRequest = {
  id: undefined,
  createdDate: new Date().getTime(),
  company: {
    id: '',
    name: ''
  },
  owner: {
    id: '',
    firstName: '',
    lastName: ''
  },
  logistic: {
    location: '',
    preferredDate: 12 / 12 / 2020, // new Date().getTime()
    hasToCallClient: false,
    hasToSaveLocation: false,
    telephone: '',
    timePeriod: TimePeriod.timePeriod_flexible,
    creatorName: '',
    templateDateRule: 0,
  },
  materials: [createMaterialRow()],
  status: undefined,
  PDFUrl: '',
  commentary: {
    bookingReference: '',
    hasToSaveBookinReference: false,
    additionalComment: '',
    attachedFiles: []
  },
  driverInformation: {
    additionalComment: '',
    attachedFiles: []
  },
  templateActionDetails: {
    saveTemplate: false,
    templateName: '',
    templateDateRule: 0
  },
  driverOrders: [DriverOrderRow()],
};
