export enum ChartType {
  BAR,
  AREA
}

export enum Dimension {
  weight = 'weight',
  cost = 'cost'
}

export enum DimensionChartOptions {
  costWeight = 'costWeight',
  fraction = 'fraction'
}

export const WEIGHT_UNIT = 'Kg';
export const COST_UNIT = 'CHF';

export const WEIGHT_CONVERSION_RATIO = 80;

export const MIN_BAR_HEIGHT = 10;
export const MIN_BAR_WIDTH = 10;

export const CHART_HEIGHT_OVERVIEW = 210;
export const CHART_HEIGHT = 400;

export const MAX_AREA_ASPECT = 16 / 9;
export const MIN_AREA_ASPECT = 4 / 3;
