import React from 'react';
import { useTranslationsContext } from 'app/translations';
import Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import { Page, CardContent, TextParagraph, Line, ExternalLink } from './styles';

const AsideSection: React.FC = () => {
  //  const { help_noteSectionTitle, help_noteSectionDescription } = useTranslationsContext();
  const { help_noteSectionTitle,
    help_noteSectionDescriptionNew, 
    help_noteSectionDescriptionNewDescription } = useTranslationsContext();
  const web = 'http://www.avag.ch/avag-kundenportal-hilfe';

  return (
    <Page>
      <SectionTitle>{ help_noteSectionTitle }</SectionTitle>
      <Card indentation={ true }>
        <CardContent>
          <TextParagraph>{ help_noteSectionDescriptionNew }</TextParagraph>
          <Line>
            <ExternalLink href={ web } target="_blank" rel="noopener noreferrer">
              { web.replace('http://', '') }
            </ExternalLink>
          </Line>
          <TextParagraph>{ help_noteSectionDescriptionNewDescription }</TextParagraph>
        </CardContent>
      </Card>
    </Page>
  );
};

export default AsideSection;
