import { FormikErrors, FormikValues } from 'formik';
import { FieldProps } from 'components/SmartForm/types';

export const isValidGroup = (errors: FormikErrors<FormikValues>, fieldNameList: string[] = []): boolean =>
  fieldNameList.every(fieldName => !errors[fieldName]);

export const applyDateLimits = (fields: FieldProps[], values: FormikValues) =>
  fields.map(field => {
    if (field.id === 'startDate' && values?.endDate) {
      return {
        ...field,
        maxLength: typeof values?.endDate === 'number' ? values?.endDate : new Date(values?.endDate).getTime()
      };
    }

    if (field.id === 'endDate' && values?.startDate) {
      return {
        ...field,
        minLength: typeof values?.startDate === 'number' ? values?.startDate : new Date(values?.startDate).getTime()
      };
    }

    return field;
  });
