import { getOffsetPagination } from 'app/utils/pagination';
import * as api from '../api';
import { ActionTypes, QuotasActionType } from '../actionTypes';

export const getQuotaList = (page: number, pageSize: number): QuotasActionType => ({
  type: ActionTypes.GET_QUOTA_LIST_REQUEST,
  request: api.getQuotaList(getOffsetPagination(page, pageSize), pageSize),
  requestName: 'get-quotas-request'
});

export const resetQuotas = (): QuotasActionType => ({
  type: ActionTypes.RESET_QUOTAS
});
