import { safeString, safeDate, safeNumber } from 'app/utils/safeTypes';
import { formatNumber } from 'app/utils/formatters';
import { Bill, BillDocumentType, BillItem, BillingAddress, BillContactInfo, CompanyResponse } from '../types';
import { BillFromServer,
  BillItemFromServer,
  BillContactInfoFromServer,
  CompanyResponseFromServer } from '../types/fromServer';
import {env} from "base";

export const createBillItemFromServer = ({ PostingDate,
  OrderNo,
  No,
  Type,
  Description,
  Description2,
  Quantity,
  UnitOfMeasureCode,
  UnitPrice,
  LineAmount,
  VATPercent }: BillItemFromServer): BillItem | undefined => {
  if (OrderNo === undefined || No === undefined || Type === undefined) {
    console.error('Error creating <BillItem> model.');
    return undefined;
  }

  return {
    date: safeDate(PostingDate).getTime(),
    deliveryNoteId: safeString(OrderNo),
    docNoOccurrence: safeString(No),
    versionNo: safeString(Type),
    material: safeString(Description) + safeString(Description2),
    quantity: safeNumber(Quantity),
    unit: safeString(UnitOfMeasureCode),
    price: safeNumber(UnitPrice),
    amount: safeNumber(LineAmount),
    VAT: safeNumber(VATPercent)
  };
};

export const createBillItemsListFromServer = (billItemsList: BillItemFromServer[]): BillItem[] => {
  if (!Array.isArray(billItemsList)) {
    if(env == 'development'){
      console.error('Error creating <BillItem[]> model.');
    }
    return [];
  }

  return billItemsList.map(createBillItemFromServer).filter(b => !!b);
};

export const createContactInfoFromServer = ({ SalesPersEMail,
  SalesPersName,
  SalesPersPhoneNo }: BillContactInfoFromServer): BillContactInfo => {
  if (SalesPersEMail === undefined || SalesPersName === undefined || SalesPersPhoneNo === undefined) {
    if(env == 'development'){
      console.error('Error creating <BillContactInfo> model.');
    }
    return { fullName: '', phone: '', email: '' };
  }

  return {
    fullName: safeString(SalesPersName),
    phone: safeString(SalesPersPhoneNo),
    email: safeString(SalesPersEMail)
  };
};

export const createCompanyResponseFromServer = (companyResponse?: CompanyResponseFromServer): CompanyResponse => {
  if (!companyResponse) {
    if(env == 'development'){
      console.error('Error creating <CompanyResponse> model.');
    }
    return { phone: '', email: '', web: '' };
  }

  const { CompInfoEMail, CompInfoHomePage, CompInfoPhoneNo } = companyResponse;
  if (CompInfoEMail === undefined || CompInfoPhoneNo === undefined || CompInfoHomePage === undefined) {
    if(env == 'development'){
      console.error('Error creating <CompanyResponse> model.');
    }
    return { phone: '', email: '', web: '' };
  }

  return {
    phone: safeString(CompInfoPhoneNo),
    email: safeString(CompInfoEMail),
    web: safeString(CompInfoHomePage)
  };
};

export const createBillingAddressFromServer = ({ BillToCustomerNo,
  BillToName,
  BillToName2,
  BillToAddress,
  BillToAddress2,
  BillToPostCode,
  BillToCity }: BillFromServer): BillingAddress => {
  const addressInfo = [
    BillToCustomerNo,
    BillToName,
    BillToName2,
    BillToAddress,
    BillToAddress2,
    BillToPostCode,
    BillToCity
  ].filter(line => !!line);
  if (!addressInfo.length) {
    if(env == 'development'){
      console.error('Error creating <BillingAddress[]> model.');
    }
    return [] as BillingAddress;
  }

  return addressInfo.filter(i => !!i).map(line => safeString(line));
};

export const createBillFromServer = ({ DocNo,
  DocDate,
  Status,
  DocType,
  DueDate,
  DocumentLines,
  OriginalAmount,
  totalAmount,
  CurrencyCode,
  companyResponse,
  BillToName,
  BillToName2,
  BillToAddress,
  BillToAddress2,
  BillToPostCode,
  BillToCity,
  BillToCustomerNo,
  SellToCustomerNo,
  VATRegistrationNo,
  DmsLinkTableID,
  SalesPerson }: BillFromServer): Bill | undefined => {
  if (DocNo === undefined || safeString(DocNo) === '') {
    console.error('Error creating <BillItem> model.');
    return undefined;
  }
  const billingAddress = {
    BillToCustomerNo,
    BillToName,
    BillToName2,
    BillToAddress,
    BillToAddress2,
    BillToPostCode,
    BillToCity
  };

  return {
    id: safeString(DocNo),
    date: safeDate(DocDate).getTime(),
    dueDate: safeDate(DueDate).getTime(),
    documentType: safeString(DocType) as BillDocumentType,
    billingAddress: createBillingAddressFromServer(billingAddress),
    customerId: safeString(SellToCustomerNo),
    VATnumber: safeString(VATRegistrationNo),
    contactInfo: SalesPerson && createContactInfoFromServer(SalesPerson[0]),
    companyResponse: createCompanyResponseFromServer(companyResponse?.[0]),
    dmsLinkID: safeString(DmsLinkTableID),
    items: createBillItemsListFromServer(DocumentLines),
    ...(OriginalAmount !== undefined && {
      originalAmount: `${ formatNumber(safeNumber(OriginalAmount)) } ${ safeString(CurrencyCode) }`
    }),
    ...(totalAmount !== undefined && {
      totalAmount: safeNumber(totalAmount)
    }),
    ...(Status !== undefined && {
      status: safeString(Status)
    })
  };
};

export const createBillListFromServer = (bills: BillFromServer[]): Bill[] => {
  if (!Array.isArray(bills)) {
    console.error('Error creating <Bill[]> model.');
    return [] as Bill[];
  }

  return bills.map(createBillFromServer).filter(b => !!b);
};
