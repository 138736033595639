import { LangKeys } from 'app/translations';
import { Column } from 'components/Table/types';
import { MaterialInfoRow } from 'containers/DeliverySlips/types';
import { Currency } from 'components/Table/renderers';

export const createColumns = (translations: LangKeys): Column<MaterialInfoRow>[] => [
  {
    key: 'itemReference',
    label: translations.deliverySlipDetails_itemReferenceLabel,
    mobile: true
  },
  {
    key: 'material',
    label: translations.deliverySlipDetails_materialLabel,
    mobile: true
  },
  {
    key: 'unit',
    label: translations.deliverySlipDetails_unitLabel,
    mobile: true
  },
  {
    key: 'gross',
    label: translations.deliverySlipDetails_grossLabel
  },
  {
    key: 'tare',
    label: translations.deliverySlipDetails_tareLabel
  },
  {
    key: 'net',
    label: translations.deliverySlipDetails_netLabel,
    mobile: true
  },
  {
    key: 'retailPrice',
    label: translations.deliverySlipDetails_retailPriceLabel,
    renderer: Currency
  },
  {
    key: 'amount',
    label: translations.deliverySlipDetails_amountLabel
  },
  {
    key: 'tax',
    label: translations.deliverySlipDetails_taxLabel
  }
];
