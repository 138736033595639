import { Pagination } from 'app/types';

export interface BillsState {
  collection: Bill[];
  pagination: Pagination;
}

export enum BillStatus {
  NEW = 'NEW',
  PAID = 'PAID'
}

export enum BillDocumentType {
  BILL = 'Invoice',
  CREDIT = 'Credit Memo',

}

export interface BillItem {
  date: number;
  deliveryNoteId: string;
  docNoOccurrence: string;
  versionNo: string;
  material: string;
  quantity: number;
  unit: string;
  price: number;
  amount: number;
  VAT: number;
}

export interface BillTotalRow {
  info: string;
  secondaryAmount: string;
  mainAmountLabel: string;
  mainAmount: string;
}

export interface BillTotalItemRow {
  infoVAT: string;
  subtotal: number;
  total: number;
}

export interface BillTotal {
  subtotalAmount: number;
  totalAmount: number;
  items: BillTotalItemRow[];
  termsOfPayment: string;
}

export type BillingAddress = string[];

export interface BillContactInfo {
  fullName: string;
  phone: string;
  email: string;
}

export interface CompanyResponse {
  phone?: string;
  email?: string;
  web?: string;
}

export interface Bill {
  id: string;
  date: number;
  dueDate: number;
  status?: string;
  documentType: BillDocumentType;
  billingAddress: BillingAddress;
  customerId: string;
  VATnumber: string;
  contactInfo: BillContactInfo;
  companyResponse: CompanyResponse;
  items: BillItem[];
  originalAmount: string;
  totalAmount: number;
  dmsLinkID: string;
}

export interface BillRow {
  id: string;
  date: number;
  documentType: BillDocumentType;
  status: string;
  totalAmount: string;
  navigate: string;
}

export interface BillDetailsRow extends BillItem {
  navigate: string;
}

export interface ExportData {
  startDate: string;
  endDate: string;
  format?: string;
}
