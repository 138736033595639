import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RouteWithID } from 'app/types';
import { useIsLoading } from 'app/hooks';
import { formatDate } from 'app/utils/formatters';
import { useTranslationsContext } from 'app/translations';
import { createDownload, base64ToBlob } from 'app/utils/downloads';
import { BillDetailsSkeleton } from 'components/Skeletons';
import { getBill } from '../../selectors';
import { useTableData } from './data';
import View from './components/View';
import { Error } from './styles';
import { BillStatus } from '../../types';
import { useBillRequest } from '../../hooks';
import { printBill } from '../../actions';

const BillDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { billDetails_printName } = useTranslationsContext();
  const { id } = useParams<RouteWithID>();
  const billItem = useSelector(getBill(id));
  const isLoading = useIsLoading(['get-bill']);
  const { columns } = useTableData();

  useBillRequest();

  const dueDate = billItem?.status !== BillStatus.PAID && billItem?.dueDate;

  const onPrintBill = () => async () => {
    try {
      const { type, payload } = await dispatch(printBill(billItem.dmsLinkID));
      if (type.includes('SUCCESS')) {
        const blob = base64ToBlob((payload as unknown) as string, 'application/pdf');
        const name = `${ id }-${ billDetails_printName }-${ formatDate(new Date(billItem.date)) }.pdf`;
        createDownload(blob, name);
      }
    } catch (error) {
      console.error(error);
    }
  };


  if (isLoading) {
    return <BillDetailsSkeleton />;
  }

  if (!isLoading && !billItem) {
    return <Error>No item found</Error>;
  }

  return <View bill={ billItem } itemsHeader={ columns } dueDate={ dueDate } onPrintBill={ onPrintBill } />;
};
export default BillDetails;
