import { useTranslationsContext } from 'app/translations';
import { FieldProps } from 'components/SmartForm/types';
import { TEXT, DATE } from 'components/SmartForm/models';
import { FieldName } from 'containers/PickupRequest/containers/PickupRequestDetails/types';


export const useFields = (): FieldProps[] => {
  const translations = useTranslationsContext();
  const fields: FieldProps[] = [];

  fields.push({
    id: FieldName.templateName,
    label: translations.template_modalBox_template_Name,
    type: TEXT, 
    placeholder: translations.template_modalBox_template_Name,
  });

  fields.push({
    id: FieldName.templatePlaceOrderDate,
    title: translations.calendar_title_preferred_date,
    label: translations.pickupRequestDetails_preferredDateLabel,
    type: DATE,
    placeholder: translations.pickupRequestDetails_preferredDatePlaceholder,
    withPortal: false,
    minLength: new Date().getTime()
  });

  return fields;
};
