import styled from 'styled-components';
import { TABLET } from 'base/styles/media-queries';

export const SectionWrapper = styled.div`
  margin-bottom: 30px;
`;
SectionWrapper.displayName = 'SectionWrapper';

export const ButtonsWrapper = styled.div`
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > button {
      margin: 4px;
    }
     #cancel_request {
       float: left;
    }

    ${ TABLET } {
      display: block;
    }
  }
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';
