import styled from 'styled-components';

import { PROGRESS_BAR_BACKGROUND,
  PROGRESS_BAR_BACKGROUND_BIG,
  PROGRESS_BAR_BORDER,
  PROGRESS_BAR_COLOR_FILL,
  PROGRESS_BAR_POINT_GREEN,
  PROGRESS_BAR_POINT_RED } from 'base/styles/skin';

interface BarProps {
  large: boolean;
}

interface PointProps {
  width: number;
}

interface ProgressProps extends BarProps, PointProps {
  color: string;
}

export const Bar = styled.div`
  width: 100%;
  height: ${ ({ large }: BarProps) => (large ? '18px' : '10px') };
  border-radius: ${ ({ large }: BarProps) => (large ? '12px' : '4px') };
  border: ${ ({ large }: BarProps) => (large ? 'none' : `1px solid ${ PROGRESS_BAR_BORDER }`) };
  background-color: ${ ({ large }: BarProps) => (large ? PROGRESS_BAR_BACKGROUND_BIG : PROGRESS_BAR_BACKGROUND) };
`;
Bar.displayName = 'Bar';

export const Progress = styled.div`
  width: ${ ({ large, width }: ProgressProps) => (
    large ? `calc(${ width }% + ${ (18 * (100 - width)) / 100 }px)` : `${ width }%`
  ) };
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: ${ ({ large }: ProgressProps) => (large ? '12px' : '4px') };
  transition: 0.4s;
  background-color: ${ ({ large, color }: ProgressProps) => (large ? PROGRESS_BAR_COLOR_FILL : color) };
`;
Progress.displayName = 'Progress';

export const Point = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 2px;
  border-radius: 8px;
  background-color: ${ ({ width }: PointProps) => (width < 80 ? PROGRESS_BAR_POINT_GREEN : PROGRESS_BAR_POINT_RED) };
`;
Point.displayName = 'Point';
