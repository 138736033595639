//For react >=18
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
//
// import React from 'react';
// // import { render } from 'react-dom';
// import { createRoot } from 'react-dom/client';
// import Core from 'containers/Core';
//
// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(<Core />);

//For react ^16 | ^17
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { render } from 'react-dom';
import Core from 'containers/Core';

render(<Core />, document.getElementById('root'));
