/* eslint-disable max-len, max-lines */
import { LangKeys } from './types';

const en: LangKeys = {
  sidebar_menu: 'Menu',

  routeName_root: 'Home',
  routeName_home: 'Home',
  routeName_forgotPassword: 'Forgot password',
  routeName_quotas: 'Quotas',
  routeName_notifications: 'Notifications',
  routeName_resetPassword: 'Reset password',
  routeName_onboarding: 'Onboarding',
  routeName_privacyAgreement: 'Privacy policy agreement',
  routeName_pickUpRequests: 'Pickup Requests',
  routeName_newPickupRequest: 'New',
  routeName_newTemplateRequest: 'New',
  routeName_TemplateRequest: 'Template',
  routeName_TemplateDetails: 'Details',
  routeName_pickUpRequest: 'Details',
  routeName_deliverySlips: 'Delivery Slips',
  routeName_deliverySlip: 'Details',
  routeName_bills: 'Bills',
  routeName_bill: 'Details',
  routeName_statistics: 'Statistics',
  routeName_users: 'Users',
  routeName_newUser: 'New',
  routeName_user: 'Details',
  routeName_settings: 'Settings',
  routeName_help: 'Help',
  routeName_logout: 'Logout',

  login_title: 'KundenPortal Login',
  requestAccess_title: 'Request Access',
  login_emailLabel: 'Your email',
  login_emailPlaceholder: 'name.lastname@workplace.ch',
  login_passwdLabel: 'Your password',
  login_passwdPlaceholder: '*************',
  login_submitLabel: 'Login',
  login_footer_1: 'If you do not yet have access to the AVAG customer portal, you can request your personal login data',
  login_footerHere: 'Create Account',
  login_footer_2: '',
  login_forgotPasswdLabel: 'Forgot password?',
  login_errorTitle: 'Ops, something went wrong…',
  login_errorDescription: 'Please, check you entered the right credentials.\n\nIn the case of a new registration, AVAG must first verify your login data. This may take a moment.',

  forgotPassword_title: 'Forgot password?',
  forgotPassword_description:
    'To reset the password, please enter your email address, which you use for the customer portal. You will then receive an email with which you can request a new password.',
  forgotpassword_description1: 'In the case of a new registration, AVAG must first verify your login data. This may take a moment. You will be automatically contacted with an email once you are verified.',
  forgotPassword_emailLabel: 'Your business email',
  forgotPassword_emailPlaceholder: 'name.lastname@workplace.ch',
  forgotPassword_submitLabel: 'Submit',
  forgotPassword_cancelLabel: 'Cancel',
  forgotPassword_errorMessage: 'Please check that you have entered the right email',
  forgotPassword_decriptionSuccess:
    'You will shortly receive an email with a link with which you can reset your password.',
  forgotPassword_linkLoginText: 'If you do not receive an email, please check your spam folder.',

  onboarding_title: 'Advantages of the AVAG customer portal',
  onboarding_text_1:
  'Simple, fast and uncomplicated - discover the advantages of the AVAG customer portal',
  onboarding_text_2: 'See for yourself and request your free access.\n\nTFor more information, videos and questions, please contact us at:',
  onboarding_text_3: 'or via email',
  onboarding_text_4: 'We look forward to your interest in the customer portal.',
  onboarding_text_icon_1: 'Available around the clock and everywhere.',
  onboarding_text_icon_2: 'Simply place waste disposal orders.',
  onboarding_text_icon_3: 'Dokumente zur Entsorgung einsehen.',
  onboarding_text_icon_4: 'Viewing documents for disposal.',
  onboarding_text_icon_5: 'Create and view waste statistics.',
  onboarding_text_icon_6: 'Quantity quotas at a glance.',
  onboarding_backToLogin: 'Back to the customer portal login',
  onboarding_formTitle: 'Request your access',
  onboarding_noteTextmandatory: 'All fields are mandatory',
  onboarding_nameLabel: 'Your Name',
  onboarding_lastNameLabel: 'Your Last Name',
  onboarding_lastNamePlaceholder: 'Your Last Name',
  onboarding_namePlaceholder: 'Your Name',
  onboarding_companyLabel: 'Your Company',
  onboarding_companyPlaceholder: 'Your Company',
  onboarding_phoneLabel: 'Phone number',
  onboarding_phonePlaceholder: '0795119713',
  onboarding_emailLabel: 'Your email',
  onboarding_emailPlaceholder: 'vorname.nachname@firma.ch',
  onboarding_privacy1: 'I accept the',
  onboarding_privacy2: 'Privacy policy',
  onboarding_backToLoginButton: 'Back to Login',
  onboarding_success_info:
  'Thank you for your inquiry in the AVAG customer portal. You have just received an email with a link to complete your verification. As soon as you have received approval from our customer advisor, you can log in with your password.',
  onboarding_password_info: 'Set your password below, which you will use for the customer portal after approval by AVAG.',
  onboarding_submitLabel: 'Submit',
  onboarding_requiredValidation: 'This field is required',
  onboarding_phoneValidation: 'Phone number is invalid',
  onboarding_requiredMailFormat: 'Email must have a valid format (name@firma.ch)',
  onboarding_closePrivacyAgreement: 'Close',
  onboarding_privacy_info_title1: 'Declaration of consent',
  onboarding_privacy_info1: 'I hereby give my consent that my personal data (name, company, telephone number and e-mail address) may be used by AVAG Umwelt AG (AVAG) for the purpose of providing the services. This personal data will only be passed on to third parties if this is relevant for the execution of the order (for example: transporters)',
  onboarding_privacy_info_title2: 'Information on data protection',
  onboarding_privacy_info2: 'AVAG takes into account the provisions of Swiss data protection legislation when collecting and processing personal data. AVAG uses comprehensive technical means and organizational measures in all areas of your online offering to protect against unauthorized access, manipulation and data loss.\n\nOur client portal uses an encrypted connection in accordance with current security standards. Avoid public hotspots and only use encrypted WLAN connections from providers you trust.',
  onboarding_privacy_info_title3: 'AG for Waste Recycling AVAG',
  onboarding_privacy_info3: 'Allmendstrasse 166, 3600 Thun\n\nE-Mail: info@avag.ch',
  onboarding_privacy_info_title4: 'Note on customer portal use',
  onboarding_privacy_info4: 'The roles, the distribution of access rights and the deletion of former company employees is the responsibility of the customer.\n\n\n',
  onboarding_privacy_info5: 'For further information and explanations, see ',
  onboarding_privacy_info6: ' or contact:',
  onboarding_privacy_info_title6: 'AVAG: AVAG-Kundenportal',
  resetPassword_title: 'Set password',
  resetPassword_description:
    'Create a new password, it should contain 1 capital letter, 1 lowercase letter, 1 number and be at least 8 characters long',
  resetPassword_newPasswordLabel: 'New password',
  resetPassword_confirmPasswordLabel: 'Confirm password',
  resetPassword_passwordPlaceholder: '*********',
  resetPassword_submitButton: 'Submit',
  resetPassword_successTitle: 'Your password has been successfully created',
  resetPassword_successMessage: 'You can now log into the portal',
  resetPassword_errorMessage: 'SOMETHING WRONG HAPPENED!',
  resetPassword_loginLink: 'Back portal Login.',
  resetPassword_requiredValidation: 'This field is required.',
  resetPassword_minValidation: 'Your password needs to be at least 8 characters long.',
  resetPassword_maxValidation: 'Your password needs to be at last 32 characters long.',
  resetPassword_lowercaseValidation: 'Your password needs to have at least a lowercase letter',
  resetPassword_uppercaseValidation: 'Your password needs to have at least an uppercase letter.',
  resetPassword_numberValidation: 'Your password needs to have at least an number.',
  resetPassword_matchValidation: 'The password confirmation is not correct.',

  pickupRequest_DescriptionDraft: 'The collection request has been temporarily stored by you for later processing. The open lock also indicates the possibility of post-processing. The pick-up request can still be deleted.',
  pickupRequest_DescriptionNew: 'The pick- up order was placed by you and is released for disposition.The lock closes, which means that the pick - up order can no longer be processed.',
  pickupRequest_DescriptionInProcess: 'As soon as the AVAG dispatcher schedules your pick-up order, the lock closes and the pick-up order can no longer be processed. The pick-up order is now in process as long as it has not been completed.',
  pickupRequest_DescriptionProcessed: 'The pick-up order has been picked up from you. The shipment is now complete, but the settlement may still be outstanding.',
  pickupRequest_DescriptionError: 'An error was detected while submitting the order. Our team has been informed and will take care of the order.',
  pickupRequestDetails_noteSectionTitle: 'Please note',
  pickupRequestDetails_noteSectionInfo1: 'Date',
  pickupRequestDetails_noteSectionInfo2: 'and',
  pickupRequestDetails_noteSectionInfo3: 'number of containers',
  pickupRequestDetails_noteSectionInfo4:
    'Please note that your date is a requested date and execution is not guaranteed for this date',
  pickupRequestDetails_noteSectionInfo5:
    'The delivery of empty containers can be limited due to availability. If there are any deviations, we will contact you',
  pickupRequestDetails_logisticsTitle: 'Logistics',
  pickupRequestDetails_logisticsOrderTitle: 'Order',
  pickupRequestDetails_companyLabel: 'Company',
  pickupRequestDetails_requestorLabel: 'Requestor',
  pickupRequestDetails_creatorLabel: 'Created By',
  pickupRequestDetails_locationLabel: 'Location',
  pickupRequestDetails_locationPlaceholder: 'Hardstrasse 201, 8005 Zürich',
  pickupRequestDetails_saveLocationLabel: 'Save location for future orders',
  pickupRequestDetails_preferredDateLabel: 'Preferred date',
  pickupRequestDetails_preferredDatePlaceholder: 'Select your preferred pick-up date',
  pickupRequestDetails_preferredTimeLabel: 'Time window',
  pickupRequestDetails_preferredTimePlaceholder: 'Flexible',
  pickupRequestDetails_phoneLabel: 'Telephone number',
  pickupRequestDetails_phonePlaceholder: '012345678',
  pickupRequestDetails_phoneCheck: 'Please, give me a call 1 hour before arrival',
  pickupRequestDetails_materialsTitle: 'Materials',
  pickupRequestDetails_containerLabel: 'Container assortment',
  pickupRequestDetails_containerPlaceholder: 'Choose',
  pickupRequestDetails_materialLabel: 'Material Type',
  pickupRequestDetails_materialPlaceholder: 'Choose',
  pickupRequestDetails_pickAmountLabel: 'Pick',
  pickupRequestDetails_pickAmountPlaceholder: 'Number',
  pickupRequestDetails_deliverAmountLabel: 'To deliver',
  pickupRequestDetails_deliverAmountPlaceholder: 'Number',
  pickupRequestDetails_addMaterialRowLabel: 'Add container',
  pickupRequestDetails_materialDestinationLabel: 'Destination',
  pickupRequestDetails_materialDateLabel: 'Date',
  pickupRequestDetails_materialTimeLabel: 'Timeslot',
  pickupRequestDetails_handleVevaMaterial: 'I instruct AVAG to issue a VEVA consignment note for a fee.',
  pickupRequestDetails_containerSelectLabel: 'Select the container type',
  pickupRequestDetails_containerSelectCancel: 'Cancel',
  pickupRequestDetails_commentaryTitle: 'Additional Comments',
  pickupRequestDetails_bookingRefLabel: 'Your booking reference(will be printed on the delivery note)',
  pickupRequestDetails_bookingRefPlaceholder: 'i.e. cost center or project code',
  pickupRequestDetails_saveLabel: 'Save for next time',
  pickupRequestDetails_commentaryLabel: 'Additional Comments',
  pickupRequestDetails_commentaryPlaceholder: 'Additional notes that you want to share with AVAG',
  pickupRequestDetails_driverCommentPlaceholder: 'Please add comments for the driver here.',
  pickupRequestDetails_uploadFileLabel: 'Upload file',
  pickupRequestDetails_uploadFilePlaceholder: 'Search on your computer',
  pickupRequestDetails_driverInfoTitle: 'Information for the driver',
  pickupRequestDetails_noContactPhone: 'No contact phone',
  pickupRequestDetails_closeButton: 'Close',
  pickupRequestDetails_abortButton: 'Cancel',
  pickupRequestDetails_abortMessage:
    'You are going to abort modifications.\nChanges will be lost.\nThis action cannot be undone',
  pickupRequestDetails_abortConfirm: 'Yes',
  pickupRequestDetails_abortDismiss: 'No',
  pickupRequestDetails_deleteButton: 'Delete',
  pickupRequestDetails_deleteMessage: 'You are going to delete this Pickup Request.\nThis action cannot be undone',
  pickupRequestDetails_deleteDismiss: 'Dismiss',
  pickupRequestDetails_printButton: 'Print',
  pickupRequestDetails_printMessage: 'You need to save before printing',
  pickupRequestDetails_printConfirm: 'Understood',
  pickupRequestDetails_printName: 'PickupRequest',
  pickupRequestDetails_saveForLaterButton: 'Save for later',
  pickupRequestDetails_saveForLaterMessage:
  'You are in the process of saving your pick-up order as a draft. \nso that you can edit it later. \nThe pick-up order is not sent to our disposition',
  pickupRequestDetails_saveForLaterDismiss: 'Dismiss',
  pickupRequestDetails_placeOrderButton: 'Place order',
  pickupRequestDetails_placeOrderMessage:
    'You are going to save modifications.\nChanges will be applied.\nThis action cannot be undone',
  pickupRequestDetails_placeOrderDismiss: 'Dismiss',
  pickupRequestDetails_updateOrderButton: 'Confirm',
  pickupRequestDetails_completeOrderButton: 'Done',
  pickupRequestDetails_completeOrderMessage: 'You are about to place an order.\nThe process cannot be undone.',
  pickupRequestDetails_completeOrderDismiss: 'Dismiss',
  pickupRequestDetails_containerSetup: 'Container setup',
  pickupRequestDetails_addonNote: 'Note',
  pickupRequestDetails_addonNoteDescription: 'The listed number of container additives is per container. \n\nExample: For 2 pallets with 3 frames, 6 frames are supplied. \n(3 frames per pallet)',

  pickupRequestDetails_connetionFailTitle: 'Connection Issue',
  pickupRequestDetails_connetionFailMessage: 'Unfortunately, your disposal order could not be sent to the dispatcher. Make sure you have a stable internet connection. Please contact our dispatcher by phone to place the disposal order. We apologize for the inconvenience. Dispatcher:+41 (33) 2265720',
  pickupRequestDetails_toPickup: 'to pickup',
  pickupRequestDetails_toDeliver: 'to deliver',
  pickupRequestDetails_perContainer: 'per container',
  pickupRequestDetails_materialNumberValidation: 'min.a field greater than 0',
  pickupRequestDetails_materialDefaultValidation: 'min.a field greater than 0',

  containers_backToCategory: 'Go back to the category selection',
  containers_backToContainer: 'Go back to the container selection',
  containers_confirmContainer: 'Confirm',
  containers_selectRecent: 'Your recently chosen container',
  containers_selectType: 'select a container category',
  containers_selectContainer: 'Select a container in the :typeName: category',
  containers_selectAddons: 'Add-ons to your :containerName:',

  billsList_date: 'Date',
  billsList_docType: 'Document Type',
  billsList_id: 'Invoice ID',
  billsList_amount: 'Total',
  billsList_status: 'Status',
  billsList_tag_new: 'New',
  billsList_tag_paid: 'Paid',
  billsList_exportData: 'Export data',
  billsList_exportAccountStatement: 'Export account statement',
  billsList_searchLabel: 'Search for bills',
  billsList_exportBalanceTitle: 'Export Balance sheet',
  billsList_exportBalanceDescription: 'Please select the period you want the balance sheet to refer to',
  billsList_export_dateStartLabel: 'Start date',
  billsList_export_dateEndLabel: 'End date',
  billsList_exportDataTitle: 'Data Exportieren',
  billsList_exportDataDescription: 'Please select the period you want the data export to refer to',
  billsList_exportDataFormatLabel: 'Select Format',
  billsList_exportDataFormatPlaceholder: 'CSV',
  billsList_exportDataName: 'BalanceSheet',

  billDetails_billNumberTitle: 'Invoice number',
  billDetails_creditNoteNumberTitle: 'Credit note number',
  billDetails_dueDateTitle: 'Please note',
  billDetails_dueDateDescription: 'the due date of this :billDocType is',
  billDetails_billInfoTitle: 'Bill info',
  billDetails_customerIdLabel: 'Customer ID',
  billDetails_vatIdLabel: 'VAT ID',
  billDetails_contactDetailsLabel: 'Contact details',
  billDetails_contactDetails_phone: 'Phone',
  billDetails_contactDetails_central: 'Central',
  billDetails_contactDetails_email: 'E-mail',
  billDetails_contactDetails_web: 'Web',
  billDetails_tableHeader_date: 'Date',
  billDetails_tableHeader_deliveryNoteId: 'Delivery note ID',
  billDetails_tableHeader_material: 'Material',
  billDetails_tableHeader_quantity: 'Quantity',
  billDetails_tableHeader_unit: 'Unit',
  billDetails_tableHeader_price: 'Price',
  billDetails_tableHeader_amount: 'Amount CHF',
  billDetails_tableHeader_vat: 'VAT',
  billDetails_total_title: 'Total',
  billDetails_total_subtotalLabel: 'Subtotal',
  billDetails_total_totalLabel: 'Total invoice amount including VAT',
  billDetails_total_termsOfPayment: 'Terms of payment',
  billDetails_printInvoice: 'Print invoice',
  billDetails_help: 'Help',
  billDetails_printName: 'Bill',
  billDetails_disclaimerTitle: 'Note',
  billDetails_disclaimerText: 'Deviations in the statistics are possible due to delivery notes that have not yet been released and the outstanding balance of accounts.',
  billDetails_modalBoxNoteText: 'In the account statement you can see all clearing items for the pre-selected period.\n\nYou can see open clearing items in the invoice overview with the status “New”.',

  exportFormat_csv: 'CSV',
  exportFormat_pdf: 'PDF',
  exportFormat_xlsx: 'Excel',

  field_passwordStrength: 'Password strength',

  confirmModal_cancel: 'Cancel',
  confirmModal_confirm: 'Confirm',

  pickupRequest: 'PickUp request',
  receipt: 'Receipt',
  amount: 'Amount',
  creditNote: 'Credit note',
  quota: 'Quota',
  balanceSheet: 'Balance sheet',
  client: 'Client',
  deliverySlip: 'Delivery slip',
  vat: 'VAT',
  frameAgreement: 'Frame Agreement',
  bill: 'Invoice',
  container: 'Container',
  attachment: 'File',

  finance: 'Finance',
  customerService: 'Customer service',
  disponent: 'Disponent',

  role: 'Role',
  operator: 'Operator',
  superUser: 'Super User',

  calendar_title_preferred_date: 'Choose your desired date',
  calendar_title_date: 'Choose your date',
  month_january: 'January',
  month_february: 'February',
  month_march: 'March',
  month_april: 'April',
  month_may: 'May',
  month_june: 'June',
  month_july: 'July',
  month_august: 'August',
  month_september: 'September',
  month_october: 'October',
  month_november: 'November',
  month_december: 'December',

  timePeriod_flexible: 'Flexible',
  timePeriod_afternoon: 'Afternoon',
  timePeriod_morning: 'Morning',

  driver_order_status_new: 'New',
  driver_order_status_progress: 'In-progress',
  driver_order_status_proceed: 'Processed',

  every_monday: 'Every Monday',
  every_tuesday: 'Every Tuesday',
  every_wednesday: 'Every Wednesday',
  every_thursday: 'Every Thursday',
  every_friday: 'Every Friday',

  table_emptyMessage: 'No data available.',
  table_emptyMessage_Quotas: 'You have no quota.',
  table_emptyMessage_statistics: 'There is no data for this period.',
  table_emptyMessage_notification: 'You have no notifications.',

  pickUpRequest_create: 'Create',
  pickUpRequest_search_placeholder: 'Search for an order',
  pickUpRequest_tag_new: 'New',
  pickUpRequest_tag_draft: 'Draft',
  pickUpRequest_tag_processing: 'In Processing',
  pickUpRequest_tag_processed: 'Processed',

  pickUp_creationDate: 'Order date',
  pickUp_orderId: 'OrderID',
  pickUp_company: 'Company',
  pickUp_owner: 'Owner',
  pickUp_address: 'Location',
  pickUp_veva: 'VeVa',
  pickUp_status: 'Status',

  tag_new: 'New',
  tag_draft: 'Draft',
  tag_in_process: 'In Process',
  tag_processed: 'Processed',
  tag_approved: 'Approved',
  tag_error: 'Error',

  uploadFile_placeholder: 'Search on your computer',
  uploadFile_label: 'Upload file',

  deliverySlip_search_placeholder: 'Search for delivery notes',
  deliverySlip_date: 'Date',
  deliverySlip_id: 'Delivery Notes ID',
  deliverySlip_bookingReference: 'Booking Reference',
  deliverySlip_attachments: 'Attachments',
  deliverySlip_status: 'Status',
  deliverySlipList_exportData: 'Export Data',
  deliverySlip_exportModalTitle: 'Export Data',
  deliverySlip_exportModalDescription: 'Please select the period you want the data export to refer to',
  deliverySlip_exportModalConfirmLabel: 'Confirm',
  deliverySlip_exportModalDismissLabel: 'Cancel',
  deliverySlip_exportModalStartDateLabel: 'Start date',
  deliverySlip_exportModalEndDateLabel: 'End date',
  deliverySlip_exportModalFormatLabel: 'Select Format',
  deliverySlipList_exportDataName: 'DeliverySlips',

  deliverySlipDetails_title: 'Delivery note number',
  deliverySlipDetails_help: 'Help',
  deliverySlipDetails_openInvoice: 'Open an invoice',
  deliverySlipDetails_messageTitle: 'Please note',
  deliverySlipDetails_messageNew:
    'Your sales order is being processed. There may be changes until the final confirmation',
  deliverySlipDetails_messageApproved:
    'This sales order is currently being checked by AVAG. Only when the status is displayed as released are materials, quantities and costs binding and will be charged accordingly',
  deliverySlipDetails_messageBeInvoiced:
    'This order has been checked and released by AVAG. Only when the status is shown as confirmed are materials, quantities and costs binding',
  deliverySlipDetails_buttonPrint: 'Print out the delivery note',
  deliverySlipDetails_deliveryReferenceLabel: 'Delivery note No',
  deliverySlipDetails_dateLabel: 'Date',
  deliverySlipDetails_companyReferenceLabel: 'Client No',
  deliverySlipDetails_driverLabel: 'Driver',
  deliverySlipDetails_bookingReferenceLabel: 'Booking Reference',
  deliverySlipDetails_itemReferenceLabel: 'Item No',
  deliverySlipDetails_materialLabel: 'Material',
  deliverySlipDetails_unitLabel: 'Unit',
  deliverySlipDetails_grossLabel: 'Gross',
  deliverySlipDetails_tareLabel: 'Tare',
  deliverySlipDetails_netLabel: 'Net',
  deliverySlipDetails_retailPriceLabel: 'Retail Price',
  deliverySlipDetails_amountLabel: 'Amount',
  deliverySlipDetails_taxLabel: 'Tax',
  deliverySlipDetails_printName: 'DeliverySlip',

  usersList_title: 'Users',
  usersList_searchLabel: 'Search for a user',
  usersList_createLabel: 'Add user',
  usersList_tableHeader_company: 'Company',
  usersList_tableHeader_date: 'Request Date',
  usersList_tableHeader_name: 'Name',
  usersList_tableHeader_email: 'Email',
  usersList_tableHeader_role: 'Role',
  usersList_tableHeader_status: 'Status',
  usersList_tag_pendingActivation: 'Pending activation',
  usersList_tag_Inactive: 'Inactive',
  userList_tag_activated: 'Password pending',
  userList_tag_active: 'Active',
  userList_tag_pending_verification: 'Verification pending',

  userDetails_title: 'User data',
  userDetails_titleRole: 'Role',
  userDetails_titleFunctionalities: 'Functionalities',
  userDetails_passwdPendingTitle: 'User activation',
  userDetails_passwdPendingDescription:
    'The user is activated as soon as he has processed his email to set up his password.',
  userDetails_newUserTitle: 'User activation',
  userDetails_newUserDescription:
    'The user will receive an email with an activation URL and will then be able to set up his/her password, only by then the user will be activated.',
  userDetails_cancelUserEdit: 'Cancel',
  userDetails_deleteUser: 'Delete user',
  userDetails_createUser: 'Create user',
  userDetails_saveUser: 'Confirm',
  userDetails_userCompany: 'Client',
  userDetails_userFirstName: 'First name',
  userDetails_userFirstNamePlaceholder: 'Johan',
  userDetails_userLastName: 'Last name',
  userDetails_userLastNamePlaceholder: 'Ruebi',
  userDetails_userEmail: 'Email',
  userDetails_userEmailPlaceholder: 'johan.ruebi@workplace.ch',
  userDetails_userPhoneNumber: 'Phone number',
  userDetails_userPhoneNumberPlaceholder: '012345678',
  userDetails_changePassword: 'Change password',
  userDetails_userRole: 'Role',
  userDetails_roleLegend:
    'The role of a Super user or an Operator can be assigned to a user, which means that different release rights can be distributed.',
  userDetails_avagAdminDescription:
    'Grant and manage portal approval for new customers and users. Define and manage the functionalities and orders of the customers.',
  userDetails_superUserDescription:
    'Manage your users and grant orders, delivery notes and invoices, as well as statistics in the area of access rights.',
  userDetails_operatorDescription:
    'Receive approval from the super user for generating and managing orders, as well as access to delivery notes, invoices and statistics.',
  userDetails_userPickupPermission: 'Enabling to request pickup',
  userDetails_userBillsPermission: 'Enabling to bills access',
  userDetails_deleteModalTitle: 'Delete user',
  userDetails_deleteModalMessage: 'You are going to delete this User.\nThis action cannot be undone',
  userDetails_deleteModalRequest: 'Delete',
  userDetails_deleteModalDismiss: 'Dismiss',
  userDetails_submitModalTitle: 'Save user',
  userDetails_submitModalMessage: 'You are going to save this User.',
  userDetails_submitModalRequest: 'Save',
  userDetails_submitModalDismiss: 'Dismiss',
  userDetails_changePermissionsDisclaimer: 'This user will have to log in again so that the new access rights are valid.',

  help_title: 'Help',
  help_subjectLabel: 'Subject',
  help_subjectPlaceholder: 'why are your writing to AVAG',
  help_recipientLabel: 'Recipient',
  help_recipientPlaceholder: 'Select the AVAG business unit to which you want to write',
  help_messageLabel: 'Your message',
  help_submitLabel: 'Send message',
  help_noteSectionTitle: 'Please note',
  help_noteSectionDescription:
    'Your message will be processed as soon as possible and we will contact you accordingly. \n\nPlease note that messages can only be processed during AVAG business hours',
  help_confirmationTitle: 'Your request ID',
  help_confirmationDescription:
    'The AVAG team will contact you as soon as possible.',
  help_feedback: 'Feedback to portal',
  help_noteSectionDescriptionNew: 'Erklärvideos und weitere Informationen unter:',
  help_noteSectionDescriptionNewDescription: 'For further questions, it is best to use the contact form. Please note that your message can only be processed during AVAG business hours. We will contact you as soon as possible.',

  settings_id: 'ID',
  settings_client: 'Customer',
  settings_portal_authorization: 'Portal Authorization',
  settings_pickup_request: 'PickUp Request',
  settings_bill: 'Bill',
  settings_search_placeholder: 'Search for customers',

  button_show_all: 'Show all',
  home_footer_show: 'Shows',
  home_footer_of: 'of',
  notifications: 'Notifications',
  notifications_id: 'ID',
  notifications_date: 'Date',
  notifications_message: 'Notification',
  notifications_status: 'Status',
  notifications_tag_new: 'New',
  home_quotas_showLabel: 'All quotas',
  home_quotas_rowTitle: 'Oil/-Concentrates',
  home_quotas_upToLabel: 'Valid till',
  home_quotas_Kontingentmenge: 'Quota amount',
  home_quotas_Restmenge: 'Remaining amount',
  home_quotas_Verwendete_menge: 'Amount used',
  home_quotas_EGI: 'EGI-Nr',
  home_quotas_Beschreibung: 'description',

  home_statistic_title: 'Statistics for',
  home_statistic_radioButtonLabel: 'Show statistics by',
  home_statistic_totalWeight: 'Total weight',
  home_statistic_totalCost: 'Total cost',

  statistics_legend: 'Legend',
  statistics_costs: 'Cost',
  statistics_weight: 'Weight',
  statistics_fraction: 'Fraction',
  statistics_credit: 'Credit',
  statistics_fromDate: 'From',
  statistics_toDate: 'To',
  statistics_filter: 'Filter',
  statistics_compare: 'Compare',
  statistics_exitCompare: 'Exit Comparison',
  statistics_download: 'Download Data',
  statistics_material: 'Material',

  validations_loading: 'Loading...',
  validations_requiredField: 'This field is required',
  validations_maxLength: 'Cannot be longer than :max: characters',
  validation_maxLength_location: 'Cannot be longer than :max: characters',
  validation_maxLength_telephone: 'Cannot be longer than :max: characters',
  validation_maxLength_bookingReference: 'Cannot be longer than :max: characters',
  validation_maxlength_additionalComments: 'Cannot be longer than :max: characters',
  validations_minLength: 'Cannot be shorter than :min: characters',
  validations_maxStartDate: 'Start date cannot be later than end date',
  validations_minEndDate: 'End date cannot be earlier than start date',
  validation_laterThan: 'This should be later than :min:',
  validations_alreadyInUse: 'This email is already in use',
  validations_greaterThan: 'This should be greater than :min:',

  unsupportedBrowser_title: 'Unsupported browser',
  unsupportedBrowser_modal: 'You are using an unsupported browser',
  unsupportedBrowser_buttonInfo: 'More info',
  unsupportedBrowser_buttonConfirm: 'Ok',
  unsupportedBrowser_moreInfo: 'The browser you are using is not fully supported, and we cannot grant you the latest improvements in UI and performance.\n\nPlease, use an updated browser, such as:',
  unsupportedBrowser_higher: 'or higher',
  footer_impressum: 'AVAG Umwelt AG \nAllmendstrasse 166 \n3600 Thun',

  maintenance_text: 'The AVAG customer portal is currently not accessible due to maintenance work, but will be available again shortly. In urgent cases, please contact us by phone or email.\n\n • Clerk:  +41 (33) 2265645       markt@avag.ch \n • Disponent:           +41 (33) 2265720       dispo@avag.ch',
  scheduledMaintenance: 'Scheduled maintenance',

  pickup_and_template_back_to_overview_tab: 'back to Overview',
  template_tab1_blank_new: 'Blank new',
  template_tab2_template: 'Templates',
  template_tab3_recurring_order: 'Recurring',
  template_modalBox_checkbox: 'Save this order as New Template after placing.',
  template_modalBox_template_Name: 'Template Name',
  template_modalBox_date_rule: 'Schedule rules control future orders',
  template_modalBox_template_Description: 'You are about to place an order.The operation cannot be reversed.',
  template_modalBox_cancel: 'cancel',
  template_modalBox_placeOrder: 'Place order',
  template_create_button: 'Create new Template',
  template_table_title: 'Templates',
  template_table_created_by: 'Erstellungsdatum',
  template_table_lastused: 'zuletzt verwendeten',
  template_modalBox_save_title: 'Save template as',
  template_save_as_new: 'Give your template a name so that you can easily find it the next time you run it.',
  template_save_as: 'Save template',
  template_delete: 'Delete template',
  template_update: 'Update template',
  template_save_as_newTemplate: 'Save as new',
  template_modal_delete_title: 'Delete template',
  template_modal_delete_description: 'Are you sure you do not need any longer the following template?',
  template_modal_update_title: 'Update template',
  template_modal_update_description: 'Are you sure you want to save your current changes to the following template?',
  template_place_order_description: 'You are about to place an order.The process cannot be reversed.',
  template_date_rule_datum_note: 'Automate your desired future date with the appointment controller. Weekends and public holidays will be postponed to the next possible date.',
  date_rule_datum_wahlen: 'Select the date after placing the order',
  date_rule_haute: 'Today',
  date_rule_haute_1: 'Today +1 working day',
  date_rule_haute_2: 'Today +2 working days',
  date_rule_haute_3: 'Today +3 working days',
  date_rule_haute_4: 'Today +4 working days',
  date_rule_haute_5: 'Today +5 working days',
  template_create_process_details_1: 'You have not yet saved a template.',
  template_create_process_details_2: 'Frequently used jobs can be saved as templates and reused for future jobs.',
  template_create_process_details_3: 'If you want to create a new template, click on the green «Create template» button, fill in all the required fields, give the template a name and save it. You can create, revise and delete multiple templates. Each template created appears in the overview.',
  template_created_on: 'Created on',
  template_created_by: 'by',
  template_view_all: 'View all templates',
  template_home_modal_text_description: 'You are about to place an order. \nThe operation cannot be reversed.',
  template_view_details_link: 'View details',
  template_aside_section_title: 'We endeavor to carry out the order according to your wishes.',
  template_aside_section_header_1: 'Desired date',
  template_aside_section_header_2: 'Schedule controller',
  template_aside_section_Terminregler_detail: 'Automate your future order date with the appointment controller.The appointment controller offers several options:',
  template_aside_section_Terminregler_list_1: 'Today (desired date is the same day)',
  template_aside_section_Terminregler_list_2: 'Today + 2 days (desired date is the day after tomorrow)',
  template_aside_section_Terminregler_list_3: 'Select date after placing the order(desired date must always be selected)',
  template_aside_section_Terminregler_list_4: 'Files cannot be saved in the template. Files must be added and granted manually before placing a template.',
  template_aside_section_header_3: 'container',
  template_aside_section_header_4: 'File',
  template_warning_message_modalBox: 'Order like this was placed just 2h ago.',
  driver_order_title: 'Driver Order',
  driver_order_number: 'Driver Order No.',
  driver_order_drive_content: 'Driver Order Type',
  driver_order_drive_delivery: 'Pickup date',
  driver_order_status: 'Status',
  Kundenportal_video: 'Insights into the AVAG customer portal'

};

export default en;
