import produce from 'immer';
import { createReducer, isMockingApi } from 'base';
import { ActionHandler } from 'base/types';
import { ActionTypes as LogoutActions } from 'containers/Login/actionTypes';
import { ActionTypes, QuotasActionType } from '../actionTypes';
import { QUOTAS_LIST } from '../mocks';
import { quotasInitialState } from '../models';
import { QuotasState } from '../types';

const getQuotaListSuccess = (state: QuotasState, action: QuotasActionType) =>
  produce(state, draft => {
    const { collection, pagination } = action.payload as QuotasState;
    draft.collection = collection || state.collection;
    draft.pagination = pagination || state.pagination;
  });

const getQuotaListError = (state: QuotasState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.collection = QUOTAS_LIST.slice(0, draft.pagination.pageSize);
      draft.pagination.totalCount = QUOTAS_LIST.length;
    }
  });

const resetQuotas = (state: QuotasState) =>
  produce(state, draft => {
    draft.collection = [];
  });

const getInitialState = (state: QuotasState) =>
  produce(state, draft => {
    Object.keys(draft).forEach(key => {
      // @ts-ignore
      draft[key] = quotasInitialState[key];
    });
  });

const actionHandlers: ActionHandler<QuotasState> = {
  [ActionTypes.GET_QUOTA_LIST_SUCCESS]: getQuotaListSuccess,
  [ActionTypes.GET_QUOTA_LIST_ERROR]: getQuotaListError,
  [ActionTypes.RESET_QUOTAS]: resetQuotas,
  [LogoutActions.LOGOUT_REQUEST]: getInitialState
};

export default createReducer(quotasInitialState, actionHandlers);
