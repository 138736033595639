import styled from 'styled-components';
import { ROW_SEPARATOR_COLOR } from 'base/styles/skin';

export const Container = styled.div``;
Container.displayName = 'Container';

export const Row = styled.div`
  cursor: pointer;
  border-bottom: 1px solid ${ ROW_SEPARATOR_COLOR };

  &:last-child {
    border-bottom: none;
  }
`;
Row.displayName = 'Row';
