import { RootState } from 'base/types';
import { Statistic } from '../types';

export const getStatistics = ({ statistics }: RootState) => statistics.collection;

export const isComparisonMode = (rootState: RootState) => getStatistics(rootState).length === 2;

export const getStatistic = (isSecondary?: boolean) => (rootState: RootState) => {
  const empty: Statistic = {
    data: [],
    metricUnits: []
  };

  const [principal = empty, compared = empty] = getStatistics(rootState);
  return isSecondary ? compared : principal;
};

export const getMaterialList = ({ statistics }: RootState) => statistics.materials;
