import { ContainerFromServer, MaterialFromServer, Material } from '../types';
import { createContainerListFromServer, createMaterialListFromServer } from '../models';

export const MATERIAL_LIST_FROM_SERVER: MaterialFromServer[] = [
  {
    ItemNo: '123123',
    ItemDescription: 'Biogene Verwertung',
    VEVAItemNeedsAuthorisation: null
  },
  {
    ItemNo: '234234',
    ItemDescription: 'Oil',
    VEVAItemNeedsAuthorisation: null
  },
  {
    ItemNo: '234235',
    ItemDescription: 'Oil VeVa',
    VEVAItemNeedsAuthorisation: true
  },
  {
    ItemNo: '345345',
    ItemDescription: 'Leerer Behälter',
    VEVAItemNeedsAuthorisation: false
  }
];

export const MATERIAL_LIST: Material[] = createMaterialListFromServer(MATERIAL_LIST_FROM_SERVER);

export const CONTAINER_LIST_FROM_SERVER: ContainerFromServer[] = [
  {
    container_id: '123123123',
    container_name: 'A1',
    container_typology_id: 1,
    container_content_type_name: 'Type A',
    description: 'L1.2   *  B1.1  *  H1.2  m',
    container_image_url: '/some/mocked-container_image_url.png',
    collected: false,
    delivered: false,
    addons: [
      {
        addons_id: '1-boolean',
        addons_name: 'Sample boolean addon',
        container_addons_type_id: '2',
        value: true
      }
    ]
  },
  {
    container_id: '234234234',
    container_name: 'A2',
    container_typology_id: 1,
    container_content_type_name: 'Type A',
    description: 'L1.2   *  B1.1  *  H1.2  m',
    container_image_url: '',
    collected: true,
    delivered: true,
    addons: [
      {
        addons_id: '2-number',
        addons_name: 'Sample number addon',
        container_addons_type_id: '3',
        value: 10
      },
      {
        addons_id: '3-dropdown',
        addons_name: 'Sample dropdown addon',
        container_addons_type_id: '1',
        value: 2,
        options: [
          {
            id: 1,
            name: '1 One'
          },
          {
            id: 2,
            name: '2 Two'
          }
        ]
      }
    ]
  },
  {
    container_id: '345345345',
    container_name: 'B1',
    container_typology_id: 2,
    container_content_type_name: 'Type B',
    description: 'L1.2   *  B1.1  *  H1.2  m',
    collected: false,
    delivered: true,
    container_image_url: ''
  },
  {
    container_id: '456456456',
    container_name: 'B2',
    container_typology_id: 2,
    container_content_type_name: 'Type B',
    description: 'L1.2   *  B1.1  *  H1.2  m',
    collected: true,
    delivered: false,
    container_image_url: '/some/mocked-container_image_url.jpg'
  }
];

export const { containers: CONTAINER_LIST, types: CONTAINER_TYPES } = createContainerListFromServer(
  CONTAINER_LIST_FROM_SERVER
);
