import React, { useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { de, enGB } from 'date-fns/locale';
import { useTranslationsContext } from 'app/translations';
import { getApplicableLanguage } from 'app/translations/utils';
import Buttons from 'components/Buttons';
import { safeNumber } from 'app/utils/safeTypes';
import Content from '../../../Touchable';
import { FieldProps } from '../../types';
import { Wrapper, Label, ErrorMessage, Description, Icon } from '../styles';
import DatePickerInput from './components/CustomInput';
import CalendarHeader from './components/CalendarHeader/CalendarHeader';
import { Calendar, Footer } from './styles';

import 'react-datepicker/dist/react-datepicker.css';

const setTestTime = (n?: number) => {
  if (process.env.NODE_ENV === 'test') {
    return undefined;
  }

  return new Date(n);
};

interface Props {
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  options: FieldProps;
  setFieldValue: (field: string, value: number) => void;
  value?: string;
}

const locale = getApplicableLanguage();
switch (locale) {
  case 'en':
    registerLocale(locale, enGB);
    break;

  default:
    registerLocale(locale, de);
}

const errorIcon = require('assets/icons/alert.svg');

const DatePickerComponent: React.FC<Props> = ({ onFocus, options, onBlur, setFieldValue, value }) => {
  const [intendedDate, setDate] = React.useState<number>();
  const translations = useTranslationsContext();
  const datepickerRef = React.useRef(null);
  const [parsedValue, shouldDisplayTime] = `${ value }`?.split('displayTime=');
  const timestamp = safeNumber(parsedValue);

  useEffect(() => {
    if (!value) {
      // initialize no data with current date in values formik
      setFieldValue(options.id, new Date().getTime());
    }
  }, []);

  useEffect(() => {
    if (intendedDate !== timestamp) {
      setDate(timestamp);
    }
  }, [timestamp]);

  const handleCancel = () => {
    setDate(timestamp);
    datepickerRef.current.setOpen(false);
  };

  const handleConfirm = () => {
    setFieldValue(options.id, new Date(intendedDate).getTime());
    datepickerRef.current.setOpen(false);
  };

  return (
    <Wrapper>
      <Description>
        <Label htmlFor={ options.id }>
          <Content options={ options } />
        </Label>
      </Description>

      <DatePicker
        minDate={ options?.minLength !== undefined ? setTestTime(options.minLength) : setTestTime() }
        maxDate={ options?.maxLength !== undefined && setTestTime(options.maxLength) }
        id={ options.id }
        name={ options.id }
        ref={ datepickerRef }
        selected={ intendedDate ? setTestTime(intendedDate) : undefined }
        locale={ locale }
        onChange={ d => setDate(d.getTime()) }
        onBlur={ onBlur }
        onFocus={ onFocus }
        customInput={ <DatePickerInput /> }
        dateFormat={ shouldDisplayTime ? 'dd.MM.yyyy / hh:mm:ss' : 'dd.MM.yyyy' }
        highlightDates={ intendedDate ? [setTestTime(intendedDate)] : undefined }
        disabled={ options.isDisabled }
        required={ options.isRequired }
        className={ !!options.error && 'error' }
        placeholderText={ options.placeholder }
        calendarContainer={ Calendar }
        renderCustomHeader={ props => <CalendarHeader { ...props } title={ options.title } /> }
        withPortal={ options.withPortal === undefined ? true : options.withPortal }
        showTimeInput={ !options.withPortal /* workaround to trigger onBlur at first time within modal */ }
        onClickOutside={ () => setDate(timestamp) }
        shouldCloseOnSelect={ false }
      >
        <Footer>
          <Buttons
            options={ [
              { id: 'cancel', label: translations.confirmModal_cancel, type: 'button', onClick: handleCancel },
              {
                id: 'confirm',
                buttonType: 'primary',
                label: translations.confirmModal_confirm,
                type: 'button',
                onClick: handleConfirm
              }
            ] }
          />
        </Footer>
      </DatePicker>

      { !!options.error && (
        <ErrorMessage>
          <Content
            options={ {
              icon: <Icon src={ errorIcon } />,
              label: Array.isArray(options.error) ? options.error[0] : options.error
            } }
          />
        </ErrorMessage>
      ) }
    </Wrapper>
  );
};
export default DatePickerComponent;
