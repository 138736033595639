import React from 'react';
import { useTranslationsContext } from 'app/translations';
import Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import { DeliveryFile } from 'containers/DeliverySlips/types';
import { Icon, PictureWrapper, TextLink, CustomCardContent } from './styles';

interface Props {
  attachments: DeliveryFile[];
  handleDownload: (file: DeliveryFile) => void;
}
const pictureIcon = require('assets/icons/picture-icon.svg');

const Attachments: React.FC<Props> = ({ attachments, handleDownload }) => {
  const { deliverySlip_attachments } = useTranslationsContext();

  return (
    <>
      <SectionTitle>{ deliverySlip_attachments }</SectionTitle>
      <Card>
        <CustomCardContent>
          { attachments.map((file, index) => {
            return (
              <PictureWrapper key={ index }>
                <Icon src={ pictureIcon } />
                <TextLink onClick={ () => handleDownload(file) }>{ file.name }</TextLink>
              </PictureWrapper>
            );
          }) }
        </CustomCardContent>
      </Card>
    </>
  );
};
export default Attachments;
