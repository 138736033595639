import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../actions';

export const useCompaniesSimpleRequest = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getCompaniesSimple());
  }, []);
};

export const useCompaniesRequest = (currentPage: number, pageSize: number, searchValue: string) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getCompanies(currentPage, pageSize, searchValue));
  }, [searchValue, currentPage]);
};

export const useCompaniesReset = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.resetCompanies());
  }, []);
};

export const useCompaniesListRequest = (id: string) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getCompanyList(id));
  }, []);
};
