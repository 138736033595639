/* eslint-disable max-lines */
import styled from 'styled-components';
import { TABLET, DESKTOP, DESKTOP_L } from 'base/styles/media-queries';
import { LINE_SEPARATOR_COLOR, LINE_SEPARATOR_COLOR_MATERIAL, PRIMARY_BACKGROUND, BACKGROUND } from 'base/styles/skin';
import Card from 'components/Card';

export const MaterialRowContainer = styled(Card)`
  margin-top: 20px;

    ${ DESKTOP } {
    padding:0 20px;
  }

  &:first-child {
    margin-top: 0;
  }

  ${ TABLET } {
    margin-top: 0;
  }

  & > div:first-child {
    align-items: flex-end;
    border-bottom: 1.5px solid ${ LINE_SEPARATOR_COLOR_MATERIAL };
    display: flex;
    flex-direction: column;

    ${ TABLET } {
      align-items: center;
      flex-direction: row;
    }
  }
`;
MaterialRowContainer.displayName = 'MaterialRowContainer';

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${ TABLET } {
    flex: 1 1 auto;
  }

  ${ DESKTOP } {
    flex-direction: row;
  }
`;
FieldsWrapper.displayName = 'FieldsWrapper';

export const UserSection = styled.div`
  border-bottom: 1px solid ${ LINE_SEPARATOR_COLOR };
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 0 4px;

  ${ TABLET } {
    flex-direction: row;
    padding-top: 10px;
    flex-wrap: wrap;
  }

  ${ DESKTOP } {
    border-bottom: none;
    padding: 10px 0;
    flex: 1 0 0px;
  }
`;
UserSection.displayName = 'MaterialProcessWrapper';

export const AdminSection = styled.div`
  background-color: ${ PRIMARY_BACKGROUND };
  flex: 1 0 auto;
  padding: 0 4px;

  ${ TABLET } {
    display: flex;
    padding-bottom: 10px;
  }

  ${ DESKTOP } {
    flex: 1 0 0px;
    padding: 10px 0;
    margin-bottom: 0.5px;
  }
`;
AdminSection.displayName = 'MaterialProcessWrapper';

export const VevaSection = styled.div`
  padding: 8px 4px;
  padding-top: 0;
  flex: 2 0 auto;
  width: 100%;

  & > span > div {
    margin: 0;
    padding: 0;

    & > label {
      display: block;
    }
  }

  ${ TABLET } {
    flex-basis: 100%;
  }
`;
VevaSection.displayName = 'VevaSection';

export const AddonsList = styled.div`
  color: #333;
  display: block;
  flex: 2 0 auto;
  font-size: 16px;
  padding: 10px;
  width: 100%;

  ${ TABLET } {
    flex-basis: 100%;
  }
`;
AddonsList.displayName = 'AddonsList';

export const AddonListHeader = styled.p`
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 10px;
`;
AddonListHeader.displayName = 'AddonListHeader';

export const Addon = styled.li`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  padding-left: 10px;

  &:last-child {
    padding-bottom: 0;
  }

  ${ TABLET } {
    flex-direction: row;
  }

  ${ DESKTOP } {
    flex-direction: column;
  }

  ${ DESKTOP_L } {
    flex-direction: row;
  }
`;
Addon.displayName = 'Addon';

export const AddonName = styled.span`
  flex: 2 0 auto;
`;
AddonName.displayName = 'AddonName';

export const AddonPickup = styled.span`
  flex: 1 0 auto;
  padding: 0 5px;
`;
AddonPickup.displayName = 'AddonPickup';

export const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;

  ${ DESKTOP } {
    flex: 1 0 0px;
  }
`;
FlexBox.displayName = 'FlexBox';

export const SelectionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
`;
SelectionsWrapper.displayName = 'SelectionsWrapper';

export const SelectContainerWrapper = styled.div``;
SelectContainerWrapper.displayName = 'SelectContainerWrapper';

export const MaterialTypeWrapper = styled.div`
  flex: 0 0 auto;
  min-width: 100%;
`;
MaterialTypeWrapper.displayName = 'MaterialTypeWrapper';

export const FlexField = styled.div`
  flex: auto;
  min-width: 100px !important;
  padding: 0 4px;
  width: inherit;

  ${ DESKTOP } {
    flex: 1 0 0px;
  }
`;
FlexField.displayName = 'FlexField';

export const ContainerField = styled(FlexField)`
  flex: 1 0 auto;

  ${ DESKTOP } {
    flex: 1 0 0px;
  }
`;
ContainerField.displayName = 'ContainerField';

export const AmountsWrapper = styled(FlexBox)`
${ DESKTOP } {
    flex: 0 0 0px;
  }
`;
AmountsWrapper.displayName = 'AmountsWrapper';

export const RemoveRowWrapper = styled.div`
  align-items: flex-end;
  padding: 8px 12px;
  display: flex;
  justify-content: flex-end;

  & button {
    background-color: ${ BACKGROUND };
    width: 80px;
  }

  ${ TABLET } {
    padding: 0 12px;
    width: 54px;
    align-items: initial;
    justify-content: initial;
  }
`;
RemoveRowWrapper.displayName = 'RemoveRowWrapper';
