import { createPaginationFromServer } from 'app/models';
import { NotificationsState, NotificationRow, Notification } from '../types';

export const notificationsInitialState: NotificationsState = {
  collection: [],
  pagination: createPaginationFromServer({})
};

export const createRowFromNotification = (notification: Notification): NotificationRow => ({
  ...notification,
  date: new Date(notification.date),
  navigate: notification.id
});
