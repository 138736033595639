import React from 'react';
import TemplateHomeButton from '../TemplateHomeButton';
import { Wrapper, ResponsiveRows } from './styles';

interface Props {
  item: any;
}
const ItemRow: React.FC<Props> = ({ item }) => {

  return (
    <Wrapper>
      <ResponsiveRows>
        <TemplateHomeButton label={ item.templateName }></TemplateHomeButton>
      </ResponsiveRows>

    </Wrapper>
  );
};
export default ItemRow;
