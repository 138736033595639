import styled, { css } from 'styled-components';

import { BACKGROUND, CARD_HEADER, CARD_FOOTER, CARD_BORDER } from 'base/styles/skin';

interface StyledProps {
  indentation: boolean;
}

const CommonStyles = css`
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
`;

export const Container = styled.div`
  // overflow: auto;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${ BACKGROUND };
  //box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.1);
  border: solid 1px ${ CARD_BORDER };
`;
Container.displayName = 'Container';

export const Header = styled.div`
  ${ CommonStyles }
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: ${ CARD_HEADER };
`;
Header.displayName = 'Header';

export const Body = styled.div`
  padding: ${ ({ indentation }: StyledProps) => (indentation ? '0 20px' : '0') };
  width: 100%;
`;
Body.displayName = 'Body';

export const Footer = styled.div`
  ${ CommonStyles }
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${ CARD_FOOTER };
`;
Footer.displayName = 'Footer';
