import styled from 'styled-components';
import { PRIMARY, DESCRIPTION_COLOR, STATUS_TEXT } from 'base/styles/skin';
import { Link } from 'containers/Login/styles';

export const Description = styled.span`
  font-size: 14px;
  color: ${ DESCRIPTION_COLOR };
  padding: 20px 0;
`;
Description.displayName = 'Description';

export const ResetPasswordActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;

  > div:first-child {
    margin: 10px 0;

    > button {
      margin: 0;
    }
  }
`;
ResetPasswordActions.displayName = 'ResetPasswordActions';

export const LoginLink = styled(Link)`
  &,
  &:link,
  &:visited {
    font-size: 14px;
    font-weight: 500;
    color: ${ PRIMARY };
    text-decoration: underline;
  }
  margin: 10px 0 25px;
`;
LoginLink.displayName = 'LoginLink';

export const LoginSuccessLink = styled(LoginLink)`
  &:visited {
    color: ${ STATUS_TEXT };
  }
  margin-left: 5px;
`;
LoginSuccessLink.displayName = 'LoginSuccessLink';