import styled from 'styled-components';
import { PRIMARY_BACKGROUND } from 'base/styles/skin';
import { TABLET, DESKTOP } from 'base/styles/media-queries';

const margin = 2;

interface ItemWrapperProps {
  flex: boolean;
}

export const ItemWrapper = styled.div`
  background-color: ${ PRIMARY_BACKGROUND };
  display: ${ ({ flex }: ItemWrapperProps) => flex && 'flex' };
  margin: ${ margin }px;
  min-height: 70px;
  width: calc(100% - ${ margin * 2 }px);

  ${ TABLET } {
    width: calc(33% - ${ margin * 2 }px);
  }

  ${ DESKTOP } {
    width: calc(25% - ${ margin * 2 }px);
  }
`;
ItemWrapper.displayName = 'ItemWrapper';
