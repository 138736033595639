import styled from 'styled-components';
import { LINK_COLOR, LINK_COLOR_HOVER, TITLE_COLOR } from 'base/styles/skin';
import DefaultPage from 'components/Page';
import { DESKTOP, TABLET } from 'base/styles/media-queries';

export const Page = styled(DefaultPage)`
  ${ DESKTOP } {
    padding-left: 20px;
  }
`;

export const TextParagraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: ${ TITLE_COLOR };
`;
TextParagraph.displayName = 'TextParagraph';

export const CardContent = styled.div`
  margin: 10px 0;
  white-space: pre-line;

  & > ${ TextParagraph } {
    margin-bottom: 10px;
  }
`;
CardContent.displayName = 'CardContent';
export const Line = styled.div`
  align-items: center;
  display: flex;
`;
Line.displayName = 'Line';

export const ExternalLink = styled.a`
  &,
  &:active,
  &:focus,
  &:link,
  &:visited {
    margin-left: 1px;
    margin-bottom: 20px;
    color: ${ LINK_COLOR };
    cursor: pointer;
    font-size: 7px;
    font-weight: 500;
    text-decoration: underline;
    white-space: nowrap;

    ${ TABLET } {
      font-size: 12px;
    }
    ${ DESKTOP } {
      font-size: 14px;
    }
    &:hover{
        color: ${ LINK_COLOR_HOVER };
    }
  }
`;
ExternalLink.displayName = 'ExternalLink';
