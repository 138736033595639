import { LangKeys } from 'app/translations';
import { FieldProps } from 'components/SmartForm/types';
import { TEXT, TEXTAREA, DATE } from 'components/SmartForm/models';
import { DeliverySlip, Driver } from 'containers/DeliverySlips/types';

const displayDriverInfo = (driver: Driver): string => {
  return `Kontrollschild:  ${ driver.numberPlate }\nAddresse:  ${ driver.address }`;
};

export const createFields = (
  translations: LangKeys,
  { id, date, companyReference, driver, bookingReference, driverOrderNo, transportOrderNo }: DeliverySlip
): FieldProps[] => {
  const fields: FieldProps[] = [];

  fields.push({
    id: 'deliveryReference',
    label: translations.deliverySlipDetails_deliveryReferenceLabel,
    type: TEXT,
    model: id,
    isDisabled: true
  });

  fields.push({
    id: 'date',
    label: translations.deliverySlipDetails_dateLabel,
    type: DATE,
    model: `${ date.toString() }displayTime=1`,
    isDisabled: true
  });

  fields.push({
    id: 'companyReference',
    label: translations.deliverySlipDetails_companyReferenceLabel,
    type: TEXT,
    model: companyReference,
    isDisabled: true
  });

  fields.push({
    id: 'driver',
    label: translations.deliverySlipDetails_driverLabel,
    type: TEXTAREA,
    model: driver && displayDriverInfo(driver),
    isDisabled: true
  });

  fields.push({
    id: 'bookingReference',
    label: translations.deliverySlipDetails_bookingReferenceLabel,
    type: TEXT,
    model: bookingReference,
    isDisabled: true
  });

  fields.push({
    id: 'driverOrderNo',
    label: translations.driver_order_number,
    type: TEXT,
    model: driverOrderNo,
    isDisabled: true
  });

  fields.push({
    id: 'transportOrderNo',
    label: translations.pickUp_orderId,
    type: TEXT,
    model: transportOrderNo,
    isDisabled: true
  });
  return fields;
};
