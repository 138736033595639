export enum ROLE {
  AVAG_ADMIN = 1,
  CLIENT_SUPER_USER,
  CLIENT_OPERATOR
}

const ROLE_NAMES = {
  [ROLE.AVAG_ADMIN]: 'AVAG admin',
  [ROLE.CLIENT_SUPER_USER]: 'Super user',
  [ROLE.CLIENT_OPERATOR]: 'Operator'
};

export const getRoleName = (role: ROLE) => ROLE_NAMES[role] || '';
