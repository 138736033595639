import { FormikValues } from 'formik';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useIsLoading } from 'app/hooks';
import { USERS, HOME } from 'app/routes';
import { RouteWithID } from 'app/types';
import { getCompanyPermissions } from 'containers/Companies/selectors';
import { isAvagAdmin, canDo } from 'containers/Core/selectors';
import { useUserRequest, useModalData } from '../../hooks';
import { getUser } from '../../selectors';
import { useFormData } from './data';
import View from './components/View';
import * as handlers from './handlers';
import { MODALS } from './types';

const UserDetails: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<RouteWithID>();
  const dispatch = useDispatch();
  const user = useSelector(getUser(id));
  const isAdmin = useSelector(isAvagAdmin);
  const isLoading = useIsLoading(['get-user-requests', 'get-companies']);
  const usersAvailable = useSelector(canDo('VIEW_USERS'));
  const isUpdating = useIsLoading(['user-request', 'change-password']);
  const [emailError, setEmailError] = React.useState(false);
  const [hasLoadedDetails, setHasDetails] = React.useState(!id);
  const [values, setValues] = React.useState<FormikValues>({});
  const [openModal, setOpenModal] = React.useState(undefined as MODALS);
  const companyPermissions = useSelector(getCompanyPermissions(values.companyId));

  useUserRequest(setHasDetails, values?.companyId);

  const handleChangePassword = () => {
    setOpenModal(MODALS.PASSWORD);
  };

  const handleRemove = () => {
    setOpenModal(MODALS.DELETE);
  };

  const handleSave = () => {
    setOpenModal(MODALS.SUBMIT);
  };

  const handleClose = () => {
    history.push(usersAvailable ? USERS.path : HOME.path);
  };

  const buttonActions = {
    handleChangePassword,
    handleClose,
    handleRemove,
    handleSave
  };

  const initialValues = useSelector(getUser(id));
  const { buttons, fields, validationSchema } = useFormData({ buttonActions }, values);
  const modalProps = useModalData(openModal);

  const onDismiss = () => {
    handlers.dismiss(setOpenModal);
  };

  const onConfirm = () => {
    switch (openModal) {
      case MODALS.DELETE:
        return handlers.remove({ dispatch, history, id: user?.id, setOpenModal });

      case MODALS.PASSWORD:
        return onDismiss();

      case MODALS.SUBMIT:
        return handlers.save({
          companyPermissions,
          dispatch,
          history,
          isAdmin,
          user: { ...user, ...values },
          setEmailError,
          setOpenModal
        });

      default:
        return undefined;
    }
  };

  const modal = {
    ...modalProps,
    openModal,
    onConfirm,
    onDismiss: openModal === MODALS.PASSWORD ? undefined : onDismiss
  };

  if (isLoading || !hasLoadedDetails) {
    // TODO: Skeleton
    return null;
  }

  if (!isLoading && !user) {
    // No item found
    handleClose();
    return null;
  }

  return (
    <View
      buttons={ buttons }
      fields={ fields }
      initialValues={ initialValues }
      isErrored={ emailError }
      isLoading={ isLoading || isUpdating }
      modalProps={ openModal && modal }
      onSubmit={ handleSave }
      updateValues={ setValues }
      validationSchema={ validationSchema }
      status={ user?.status }
    />
  );
};

export default UserDetails;
