import styled from 'styled-components';
import { LINK_COLOR, LINK_COLOR_HOVER } from 'base/styles/skin';


export const BackToTemplates = styled.a`
    color: ${ LINK_COLOR } !important;
    &:hover {
        color: ${ LINK_COLOR_HOVER } !important;
    }
    
`;
BackToTemplates.displayName = 'BackToTemplates';


export const BackToTemplatesWrapper = styled.div`
    margin-bottom: 10px;
`;
BackToTemplatesWrapper.displayName = 'BackToTemplatesWrapper';