import styled from 'styled-components';
import getLayerPosition from 'base/styles/z-index';
// eslint-disable-next-line no-restricted-imports
import { WHITE_1, RED_5 } from 'base/styles/do-not-export/colors';

export const Wrapper = styled.div`
  align-items: center;
  background: ${ RED_5 };
  border-radius: 50%;
  color: ${ WHITE_1 };
  display: flex;
  height: 100px;
  justify-content: center;
  left: 5px;
  position: fixed;
  top: 5px;
  word-wrap: break-word;
  width: 100px;
  z-index: ${ getLayerPosition('NOTIFICATION') };
`;
Wrapper.displayName = 'Wrapper';
