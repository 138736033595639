import { useSelector } from 'react-redux';
import { useTranslationsContext } from 'app/translations';
import { isAvagAdmin } from 'app/containers/Core/selectors';
import { MODALS, ModalData } from '../containers/PickupRequestDetails/types';

export const useModalData = (openModal: MODALS): ModalData => {
  const translations = useTranslationsContext();
  const isAVAGAdmin = useSelector(isAvagAdmin);
  switch (openModal) {
    case MODALS.ABORT:
      return {
        title: translations.pickupRequestDetails_abortButton,
        description: translations.pickupRequestDetails_abortMessage,
        confirmLabel: translations.pickupRequestDetails_abortConfirm,
        dismissLabel: translations.pickupRequestDetails_abortDismiss
      };

    case MODALS.PRINT:
      return {
        title: translations.pickupRequestDetails_printButton,
        description: translations.pickupRequestDetails_printMessage,
        confirmLabel: translations.pickupRequestDetails_printConfirm
      };

    case MODALS.TA_CONNECTION_FAILURE:
      return {
        title: translations.pickupRequestDetails_connetionFailTitle,
        description: translations.pickupRequestDetails_connetionFailMessage,
        confirmLabel: translations.pickupRequestDetails_placeOrderDismiss
      };      

    case MODALS.DELETE:
      return {
        title: translations.pickupRequestDetails_deleteButton,
        description: translations.pickupRequestDetails_deleteMessage,
        confirmLabel: translations.pickupRequestDetails_deleteButton,
        dismissLabel: translations.pickupRequestDetails_deleteDismiss
      };

    case MODALS.SAVE_DRAFT:
      return {
        title: translations.pickupRequestDetails_saveForLaterButton,
        description: translations.pickupRequestDetails_saveForLaterMessage,
        confirmLabel: translations.pickupRequestDetails_saveForLaterButton,
        dismissLabel: translations.pickupRequestDetails_saveForLaterDismiss
      };

    case MODALS.SAVE:
      return {
        title: isAVAGAdmin
          ? translations.pickupRequestDetails_updateOrderButton
          : translations.pickupRequestDetails_placeOrderButton,
        description: translations.pickupRequestDetails_placeOrderMessage,
        confirmLabel: isAVAGAdmin
          ? translations.pickupRequestDetails_updateOrderButton
          : translations.pickupRequestDetails_placeOrderButton,
        dismissLabel: translations.pickupRequestDetails_placeOrderDismiss
      };

    case MODALS.COMPLETE:
      return {
        title: translations.pickupRequestDetails_completeOrderButton,
        description: translations.pickupRequestDetails_completeOrderMessage,
        confirmLabel: translations.pickupRequestDetails_completeOrderButton,
        dismissLabel: translations.pickupRequestDetails_completeOrderDismiss
      };

    default:
      return {
        title: '',
        description: '',
        confirmLabel: '',
        dismissLabel: ''
      };
  }
};
