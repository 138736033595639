import { FieldProps } from 'components/SmartForm/types';
import { LangKeys } from 'app/translations';

export const createFields = (translations: LangKeys): FieldProps[] => ([
  {
    id: 'password',
    label: translations.resetPassword_newPasswordLabel,
    type: 'password',
    isRequired: true,
    strengthCheck: true,
    placeholder: translations.resetPassword_passwordPlaceholder
  },
  {
    id: 'confirm_password',
    label: translations.resetPassword_confirmPasswordLabel,
    type: 'password',
    isRequired: true,
    placeholder: translations.resetPassword_passwordPlaceholder
  }
]);
