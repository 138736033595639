import * as Yup from 'yup';
import { useMemo } from 'react';
import { LangKeys, useTranslationsContext } from 'app/translations';
import { ButtonProps } from 'components/Touchable/types';
import { FieldProps } from 'components/SmartForm/types';
import { createButtons } from './buttons';
import { createFields } from './fields';
import { createValidationSchema } from './validation';

interface FormData {
  buttons: ButtonProps[];
  fields: FieldProps[];
  validationSchema?: Yup.ObjectSchema;
  validate?: Function;
}

const createFormData = (translations: LangKeys): FormData => ({
  buttons: createButtons(translations),
  fields: createFields(translations),
  validationSchema: createValidationSchema(translations)
});

export const useFormData = (): FormData => {
  const translations = useTranslationsContext();
  const translationsSize = Object.keys(translations).length;
  const memoizedFormData = useMemo<FormData>(() => createFormData(translations), [translationsSize]);
  return memoizedFormData;
};
