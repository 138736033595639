import { http, envConf } from 'base';
import { handleListError } from 'app/utils/connection-errors';
import { Bill, BillsState, ExportData } from 'containers/Bills/types';
import { createPaginationFromServer } from 'app/models';
import * as Model from '../models/fromServer';
import { ExportDataFormats } from '../containers/BillList/types';

export const getBillList = async (offset: number, limit: number, filterValue: string): Promise<BillsState> => {
  try {
    const { data } = await http.get(envConf.API.BILLS_LIST_GET, {
      params: { limit, offset, filterValue }
    });

    const { bills, ...pagination } = data;

    return {
      collection: Model.createBillListFromServer(bills),
      pagination: createPaginationFromServer(pagination)
    };
  } catch (error) {
    return handleListError<Bill>(error);
  }
};

export const getBill = async (id: string, docType: string): Promise<Bill> => {
  try {
    const { data } = await http.get(envConf.API.BILL_GET.replace(':id', id).replace(':docType', docType));
    return Model.createBillFromServer(data?.[0]);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getExportDataRequest = async (exportData: ExportData): Promise<string> => {
  const { startDate, endDate, format } = exportData;
  try {
    const data = await http.get(
      envConf.API.BILLS_EXPORT_GET.replace(':startDate', startDate)
        .replace(':endDate', endDate)
        .replace(':format', format ? `${ format }` : ''),
      {
        ...((format === ExportDataFormats.exportFormat_csv || format === ExportDataFormats.exportFormat_xlsx) && {
          responseType: 'arraybuffer'
        })
      }
    );

    return (data as unknown) as string;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getExportBalanceRequest = async (exportData: ExportData): Promise<string> => {
  const { startDate, endDate, format } = exportData;
  try {
    const { data } = await http.get(
      envConf.API.BILLS_BALANCE_GET.replace(':startDate', startDate)
        .replace(':endDate', endDate)
        .replace(':format', format || 'pdf')
    );

    return (data?.document as unknown) as string;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const printBill = async (linkId: string): Promise<string> => {
  try {
    const { data } = await http.get(envConf.API.BILL_PRINT_REQUEST_GET
      .replace(':dmsLinkID', linkId));
    return data.document;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
