import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HELP } from 'app/routes';
import { RouteWithID } from 'app/types';
import { useTranslationsContext } from 'app/translations';
import Buttons from 'components/Buttons';
import { HelpTopic, HelpFromSection } from 'containers/Help/types';

interface Props {
  topic?: HelpTopic;
}

const HelpButton: React.FC<Props> = ({ topic }) => {
  const { help_title } = useTranslationsContext();
  const history = useHistory();
  const { id } = useParams<RouteWithID>();

  const onSelectHelp = React.useCallback(() => {
    const routeState: HelpFromSection = {
      from: HelpTopic[topic],
      itemId: id
    };
    history.push(HELP.path, topic ? routeState : undefined);
  }, []);

  return (
    <Buttons
      options={ [
        {
          id: 'help',
          label: help_title,
          onClick: onSelectHelp,
          type: 'button'
        }
      ] }
    />
  );
};
export default HelpButton;
