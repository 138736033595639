import React from 'react';

import { PROGRESS_BAR_COLOR_FILL } from 'base/styles/skin';
import { Bar, Progress, Point } from './styles';

interface Props {
  width: number;
  color?: string;
  large?: boolean;
}

const ProgressBar: React.FC<Props> = ({ width, color = PROGRESS_BAR_COLOR_FILL, large = false }) => (
  <Bar large={ large }>
    <Progress width={ width } color={ color } large={ large }>
      { large && <Point width={ width } /> }
    </Progress>
  </Bar>
);

export default ProgressBar;
