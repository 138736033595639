import React from 'react';
import styled from 'styled-components';
import Card from 'components/Card';
import { WrapperTitle } from 'components/SectionTitle/styles';
import { SectionWrapper } from 'containers/PickupRequest/containers/PickupRequestDetails/styles';

import Skeleton, { AvagDot, Bar, Line, Buttons } from './pieces';
import Table from './Table';

const StyledBar = styled(Bar)`
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  width: 200px;
`;

const ContentWrapper = styled.div`
  margin-top: 30px;
`;

const BillInfoWrapper = styled.div`
  display: flex;
  margin: 8px;
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 16px;
`;

const BillsCard = styled(Card)`
  min-height: 300px;
  padding-top: 32px;
`;

const InfoSection: React.FC<Props> = () => (
  <SectionWrapper>
    <WrapperTitle>
      <AvagDot />
      <StyledBar />
    </WrapperTitle>
    <BillsCard indentation={ false }>
      <BillInfoWrapper>
        <FieldsWrapper>
          <Line />
          <Line />
          <Line />
          <Line />
          <Line />
        </FieldsWrapper>
        <FieldsWrapper>
          <Line />
          <Line />
          <Line />
        </FieldsWrapper>
      </BillInfoWrapper>
      <Table actions={ 0 } showActionsBar={ false } rows={ 2 }></Table>
    </BillsCard>
  </SectionWrapper>
);

const TotalSection: React.FC<Props> = () => (
  <>
    <WrapperTitle>
      <AvagDot />
      <StyledBar />
    </WrapperTitle>
    <Table actions={ 0 } showActionsBar={ false } rows={ 1 }></Table>
    <Buttons />
  </>
);

interface Props {
  attachments?: number;
}

const BillDetailsSkeleton: React.FC<Props> = () => (
  <Skeleton>
    <ContentWrapper>
      <InfoSection />
      <TotalSection />
    </ContentWrapper>
  </Skeleton>
);

export default BillDetailsSkeleton;
