import React from 'react';
import { useTranslationsContext } from 'app/translations';
import { Wrapper, OnboardSuccessInfo, BackHeaderButton } from './styles';
import BackToLoginButton from '../BackToLoginButton';

export const SuccessConfirmation: React.FC = () => {
  const translations = useTranslationsContext();

  return (
    <Wrapper>
      <OnboardSuccessInfo>{ translations.onboarding_success_info }</OnboardSuccessInfo>
      <BackHeaderButton>
        <BackToLoginButton></BackToLoginButton>
      </BackHeaderButton>
    </Wrapper>
  );
};

export default SuccessConfirmation;
