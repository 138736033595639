import React from 'react';
import { useTranslationsContext } from 'app/translations';
import Buttons from 'components/Buttons';

interface Props {
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const AccountStatementButton: React.FC<Props> = ({ onClick }) => {
  const translations = useTranslationsContext();

  return (
    <Buttons
      options={ [
        {
          buttonType: 'primary',
          id: 'exportAccountStatement',
          label: translations.billsList_exportAccountStatement,
          onClick,
          type: 'button'
        }
      ] }
    />
  );
};

export default AccountStatementButton;
