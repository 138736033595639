import React from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import Page from 'components/Page';
import MainLayout, { Props } from 'containers/Core/components/MainLayout';

export type ComponentWrapper = <T extends object>(
  Component: React.FC<T>,
  renderBreadcrumbs?: boolean,
  props?: T
) => () => JSX.Element;

export const breadcrumbsPageWrapper: ComponentWrapper = (Component, renderBreadcrumbs = true, props) => () => (
  <>
    { renderBreadcrumbs && <Breadcrumbs /> }
    <Page>
      <Component { ...props } />
    </Page>
  </>
);

export const mainLayoutWrapper = <T extends object>(
  Component: React.FC<T>,
  permissions: Props['permissions'],
  props?: T
) => () => (
    <MainLayout permissions={ permissions }>
      <Component wrapper={ breadcrumbsPageWrapper } { ...props } />
    </MainLayout>
  );
