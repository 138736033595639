export * from './fromServer';

export interface Material {
  id: string;
  name: string;
  isVeva: boolean;
}

export interface ContainerType {
  id: string;
  name: string;
  image: string;
}

export interface ContainerPermission {
  collected: boolean;
  delivered: boolean;
}

export enum AddonType {
  dropdown = 1,
  bool,
  number
}

export interface AddonOption {
  id: string;
  name: string;
}

export interface ContainerAddon {
  id: string;
  addonId: string;
  name: string;
  type: AddonType;
  defaultValue?: boolean | number | string;
  options?: AddonOption[];
}

export interface Container {
  id: string;
  name: string;
  type: string;
  description: string;
  image: string;
  permissions: ContainerPermission;
  addons: ContainerAddon[];
}

export interface ContainerResponse {
  containers: Container[];
  types: ContainerType[];
}
