import { createPaginationFromServer } from 'app/models';
import { CompaniesState, Company, CompaniesRow } from '../types';

export const companiesInitialState: CompaniesState = {
  simple: {
    collection: []
  },
  switchlist: {
    collection: []
  },
  complete: {
    collection: [],
    pagination: createPaginationFromServer({})
  }
};

export const createRowFromCompanies = ({ id, name, permissions }: Company): CompaniesRow => ({
  id,
  name,
  ...permissions
});
