export * from './fromServer';

export enum HelpRecipient {
  finance = 1,
  customerService,
  disponent,
  help_feedback
}

export enum HelpTopic {
  PICKUPS = 'PICKUPS',
  BILLS = 'BILLS',
  DELIVERY_SLIPS = 'DELIVERY_SLIPS'
}

export interface HelpForm {
  subject: string;
  department_id: HelpRecipient;
  message: string;
}

export interface Help {
  id: string;
  subject?: string;
  message?: string;
  department_id?: HelpRecipient;
}

export interface HelpFromSection {
  from: HelpTopic;
  itemId: string;
}
