import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { STATISTICS } from 'app/routes';
import { PropsWithWrapper } from 'app/types';
import Redirect from 'components/Redirect';
import Stats from './containers';

const Statistics: React.FC<PropsWithWrapper<{}>> = ({ wrapper }) => (
  <Switch>
    <Route exact={ true } path={ STATISTICS.path } component={ wrapper(Stats) } />
    <Redirect to={ STATISTICS.path } />
  </Switch>
);

export default Statistics;
