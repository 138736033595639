import styled from 'styled-components';

import { WrapperTitle } from '../../../styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`;
Container.displayName = 'Container';

export const CustomWrapperTitle = styled.div`
  ${ WrapperTitle }
  padding: 0;
`;
CustomWrapperTitle.displayName = 'WrapperTitle';
