import React, { memo } from 'react';
import { useTranslationsContext } from 'app/translations';
import { RendererProps } from 'components/Table/types';
import Tooltip from 'app/components/Tooltip';
import Tag from 'components/Tag';

import {
  PRIMARY,
  TAG_BACKGROUND,
  TAG_BACKGROUND_BLUE,
  TAG_BACKGROUND_ORANGE,
  TAG_BACKGROUND_RED
} from 'base/styles/skin';
import { PickupRequestStatus } from 'app/constants/pickupRequest';
import { Description } from './styles';

const TableTag = <R extends unknown>({ cell }: RendererProps<R>): React.ReactElement | null => {
  const translations = useTranslationsContext();
  const tooltipElement = React.useRef<HTMLDivElement>();
  const [tooltip, setTooltip] = React.useState(false);
  let color = TAG_BACKGROUND;
  let label = '';
  let description = '';
  switch (cell) {
    case PickupRequestStatus.NEW:
      color = PRIMARY;
      label = translations.tag_new;
      description = translations.pickupRequest_DescriptionNew;
      break;
    case PickupRequestStatus.NO_NAV:
      color = TAG_BACKGROUND_RED;
      label = translations.tag_error;
      description = translations.pickupRequest_DescriptionError;
      break;
    case PickupRequestStatus.DRAFT:
      color = TAG_BACKGROUND_BLUE;
      label = translations.tag_draft;
      description = translations.pickupRequest_DescriptionDraft;
      break;
    case PickupRequestStatus.IN_PROCESS:
      color = TAG_BACKGROUND_ORANGE;
      label = translations.tag_in_process;
      description = translations.pickupRequest_DescriptionInProcess;
      break;
    case PickupRequestStatus.PROCESSED:
      label = translations.tag_processed;
      description = translations.pickupRequest_DescriptionProcessed;
      break;
    default:
      color = TAG_BACKGROUND;
  }

  const handleMouseEnter = () => {
    setTooltip(true);
  };

  const handleMouseLeave = () => {
    setTooltip(false);
  };

  React.useEffect(() => {
    tooltipElement.current.addEventListener('mouseenter', handleMouseEnter, true);
    tooltipElement.current.addEventListener('mouseleave', handleMouseLeave, true);
    return () => {
      if (tooltipElement.current) {
        tooltipElement.current.removeEventListener('mouseenter', handleMouseEnter, true);
        tooltipElement.current.removeEventListener('mouseleave', handleMouseLeave, true);
      }
    };
  }, []);

  const rect = tooltipElement.current?.getBoundingClientRect();

  return (
    <div ref={ tooltipElement }>
      <Tag label={ label } color={ color } />
      { tooltip && (
        <Tooltip 
          left={ rect?.left + rect?.width - 200 } 
          top={ rect?.top + rect?.height * 4 } 
          right={ rect?.width / 4 }>
          <Description>{ description }</Description>
        </Tooltip>
      ) }
    </div>
  );
};
export default memo(TableTag);
