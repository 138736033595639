import { useTranslationsContext } from 'app/translations';
import { ButtonProps } from 'components/Touchable/types';
import { Steps } from '../types';

interface Props {
  handleClose: () => void;
  handleSubmit: () => void;
  setStep: (step: Steps) => void;
  step: Steps;
}

export const useButtons = ({ handleClose, handleSubmit, setStep, step }: Props) => {
  const { containers_backToCategory,
    containers_backToContainer,
    pickupRequestDetails_containerSelectCancel,
    containers_confirmContainer } = useTranslationsContext();

  const buttons: ButtonProps[] = [];

  if (step >= Steps.container) {
    buttons.push({
      id: 'containerSelection_back',
      label: step === Steps.container ? containers_backToCategory : containers_backToContainer,
      type: 'button',
      onClick: () => {
        setStep(step - 1);
      }
    });
  }

  if (step < Steps.addons) {
    buttons.push({
      id: 'containerSelection_cancel',
      label: pickupRequestDetails_containerSelectCancel,
      type: 'button',
      onClick: handleClose
    });
  } else {
    buttons.push({
      buttonType: 'primary',
      id: 'containerSelection_confirm',
      label: containers_confirmContainer,
      onClick: () => {
        handleSubmit();
      },
      type: 'submit'
    });
  }

  return buttons;
};
