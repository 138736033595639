import styled from 'styled-components';
import { DISABLED_BACKGROUND,
  DISABLED_COLOR,
  BUTTON_BACKGROUND,
  BUTTON_BORDER,
  BUTTON_COLOR,
  BUTTON_PRIMARY_BACKGROUND,
  BUTTON_PRIMARY_BORDER,
  BUTTON_PRIMARY_COLOR,
  BUTTON_COLOR_HOVER } from 'base/styles/skin';
import { BUTTON_MARGIN, BUTTON_SIZE } from 'base/styles/sizes';

interface StyledButtonsProps {
  align: 'left' | 'center' | 'right';
}

export const Buttons = styled.div`
  font-size: 16px;
  text-align: ${ ({ align }: StyledButtonsProps) => align };

  & > button,
  & > span {
    margin: 5px 0;
  }

  & > button,
  & > span {
    margin-right: ${ ({ align }: StyledButtonsProps) => align === 'left' && `${ BUTTON_MARGIN }px` };
    margin-left: ${ ({ align }: StyledButtonsProps) => align === 'right' && `${ BUTTON_MARGIN }px` };
  }
`;
Buttons.displayName = 'Buttons';

interface Props {
  isPrimary?: boolean;
}

export const Button = styled.button`
  background-color: ${ ({ isPrimary }: Props) => (isPrimary ? BUTTON_PRIMARY_BACKGROUND : BUTTON_BACKGROUND) };
  border: ${ ({ isPrimary }: Props) => (isPrimary ? '0px solid' : '1px solid '+BUTTON_BORDER) };
  border-radius: 5px;
  color: ${ ({ isPrimary }: Props) => (isPrimary ? BUTTON_PRIMARY_COLOR : BUTTON_COLOR) };
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  margin: 5px;
  max-width: calc(100% - 10px);
  height: ${ BUTTON_SIZE }px;
  overflow: hidden;
  padding: 6px 21px;
  text-align: center;
  text-overflow: ellipsis;
  transition: background-color 0.2s, border 0.2s, color 0.2s;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s, width 0.3s;

  &:active {
    border-top-color: ${ ({ isPrimary }: Props) => (isPrimary ? BUTTON_PRIMARY_BORDER : BUTTON_BORDER) };
    border-bottom-color: transparent;
  }

  &:focus {
    outline: none;
  }

  &:hover:enabled {
    //background-image: linear-gradient(to bottom, ${ BUTTON_COLOR_HOVER }, ${ BUTTON_PRIMARY_BACKGROUND });
    border-color: ${ BUTTON_COLOR_HOVER };

    background-color: ${ ({ isPrimary }: Props) => (isPrimary ? BUTTON_COLOR_HOVER : BUTTON_BACKGROUND) };
    color: ${ ({ isPrimary }: Props) => (isPrimary ? BUTTON_PRIMARY_COLOR : BUTTON_COLOR_HOVER) };
  }

  &:disabled {
    border: 1px solid ${ DISABLED_BACKGROUND };
    background-color: ${ DISABLED_BACKGROUND };
    color: ${ DISABLED_COLOR };
    cursor: default;
  }
`;
Button.displayName = 'Button';
