import { LangKeys } from 'app/translations';
import { Column } from 'components/Table/types';
import { renderers } from 'components/Table';
import { TemplateRow } from 'containers/PickupRequest/containers/Templates/types';

export const createColumns = (translations: LangKeys): Column<TemplateRow>[] => [
 
  {
    key: 'templateName',
    label: translations.template_modalBox_template_Name,
    mobile: true
  },
  {
    key: 'createdDate',
    label: translations.template_table_created_by,
    renderer: renderers.Date,
    mobile: true
  },
  {
    key: 'lastUsed',
    label: translations.template_table_lastused,
    renderer: renderers.Date,
    mobile: true
  },
  {
    key: 'navigate',
    renderer: renderers.ArrowButton,
    mobile: true
  }

];
