import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ROOT } from 'app/routes';
import { SeverityType, StatusResponse } from 'components/StatusMessage/types';
import SimpleLayout from 'containers/Core/components/SimpleLayout';
import { useTranslationsContext } from 'app/translations';
import { useIsLoading } from 'app/hooks';
import { RouteWithToken } from 'app/types';
import FormHeader from './components/FormHeader';
import FormBody from './components/FormBody';
import * as actions from './actions';
import { ActionTypes } from './actionTypes';
import { LoginLink } from './styles';
import { ResetPasswordParams } from './types';

const ResetPassword: React.FC = () => {
  const translations = useTranslationsContext();
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const { token } = useParams<RouteWithToken>();
  const isLoading = useIsLoading(['reset-password']);

  const handleSubmit = async (password: string) => {
    setIsSuccess(false);
    setMessage('');
    const params: ResetPasswordParams = { password, token };
    const { type: responseType } = await dispatch(actions.resetPasswordRequest(params));
    if (responseType === ActionTypes.RESET_PASSWORD_SUCCESS) {
      setIsSuccess(true);
      setMessage(translations.resetPassword_successMessage);
    } else if (responseType === ActionTypes.RESET_PASSWORD_ERROR) {
      setMessage(translations.resetPassword_errorMessage);
    }
  };

  const statusResponse: StatusResponse = {
    severity: isSuccess ? SeverityType.SUCCESS : SeverityType.ERROR,
    title: isSuccess && translations.resetPassword_successTitle,
    description: message
  };

  return (
    <SimpleLayout>
      <FormHeader title={ translations.resetPassword_title } description={ translations.resetPassword_description } />
      <FormBody isLoading={ isLoading } onSubmit={ handleSubmit } statusResponse={ message && statusResponse } />
      <LoginLink to={ ROOT.path }>{ translations.resetPassword_loginLink }</LoginLink>
    </SimpleLayout>
  );
};

export default ResetPassword;
