import { getOffsetPagination } from 'app/utils/pagination';
import { ActionTypes, UserActionType } from '../actionTypes';
import * as api from '../api';
import { User, CompanyPermissions } from '../types';

export const getUserList = (page: number, pageSize: number, filterValue = '0'): UserActionType => ({
  type: ActionTypes.GET_USER_LIST_REQUEST,
  request: api.getUserList(getOffsetPagination(page, pageSize), pageSize, filterValue),
  requestName: 'get-user-requests'
});

export const getUser = (id: string): UserActionType => ({
  type: ActionTypes.GET_USER_REQUEST,
  request: api.getUser(id),
  requestName: 'get-user-requests'
});

export const createUser = (user: User, isAdmin: boolean, companyPermissions: CompanyPermissions): UserActionType => ({
  type: ActionTypes.CREATE_USER_REQUEST,
  request: api.createUser(user, isAdmin, companyPermissions),
  requestName: 'user-request'
});

export const updateUser = (user: User, isAdmin: boolean, companyPermissions: CompanyPermissions): UserActionType => ({
  type: ActionTypes.UPDATE_USER_REQUEST,
  request: api.updateUser(user, isAdmin, companyPermissions),
  requestName: 'user-request'
});

export const deleteUser = (id: string): UserActionType => ({
  type: ActionTypes.DELETE_USER_REQUEST,
  request: api.deleteUser(id),
  requestName: 'user-request'
});

export const resetUserList = (): UserActionType => ({
  type: ActionTypes.RESET_USER_LIST
});
