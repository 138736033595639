import React from 'react';
import { PASSWORD_REGEX } from 'app/constants/validations';
import { BACKGROUND, WEAK_PASSWORD, STRONG_PASSWORD } from 'base/styles/skin';
import StrengthProgress from './components/StrengthProgress';

import { Container } from './styles';

interface Props {
  value?: string;
}

const StrengthCheck: React.FC<Props> = ({ value = '' }) => {
  const [strengthParam, setStrength] = React.useState(0);
  const [colorProgress, setColorProgress] = React.useState(BACKGROUND);

  React.useEffect(() => {
    let strength = 0;
    let color = colorProgress;
    const { number, symbol, uppercase, lowercase } = PASSWORD_REGEX;

    if (lowercase.test(value)) strength += 1;
    if (uppercase.test(value)) strength += 1;
    if (number.test(value)) strength += 1;

    color = strength > 2 ? STRONG_PASSWORD : WEAK_PASSWORD;

    if (symbol.test(value)) strength += 1;

    setStrength(strength);
    setColorProgress(color);
  }, [value]);

  return (
    <Container>
      <StrengthProgress strength={ strengthParam } color={ colorProgress } />
    </Container>
  );
};

export default StrengthCheck;
