import { safeString } from 'app/utils/safeTypes';
import { Help, HelpFromServer } from '../types';

export const createHelpFromServer = ({ help_id }: HelpFromServer): Help | undefined => {
  if (help_id === undefined) {
    console.error('Error creating <Help> model');
    return undefined;
  }

  return {
    id: safeString(help_id)
  };
};
