import styled from 'styled-components';

import { CALENDAR_BORDER,
  BACKGROUND,
  CALENDAR_TEXT,
  CALENDAR_FOOTER_BACKGROUND,
  CALENDAR_TODAY_BACKGROUND,
  CALENDAR_TEXT_WEEK_DAY,
  CALENDAR_DAY_BACKGROUND,
  CALENDAR_DAY_SELECTED_BACKGROUND,
  CALENDAR_TEXT_TODAY,
  CALENDAR_TEXT_SELECTED } from 'base/styles/skin';

export const Calendar = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: ${ CALENDAR_TEXT };
  border: solid 1px ${ CALENDAR_BORDER };
  background-color: ${ BACKGROUND };

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  > .react-datepicker__month-container {
    padding: 0 12px;
  }

  > .react-datepicker__children-container {
      width: unset;
      margin: unset;
      padding-right: unset;
      padding-left: unset;
  }

  > .react-datepicker__input-time-container {
    display: none;
  }

  > * {
    > .react-datepicker__header {
      background-color: ${ BACKGROUND };
      border: none;
    }

    > * {
      > * {
        > .react-datepicker__day-name {
          font-size: 10px;
          color: ${ CALENDAR_TEXT_WEEK_DAY };
          line-height: 0;
          width: 3rem;
        }
        > .react-datepicker__day {
          color: ${ CALENDAR_TEXT };
          background-color: ${ CALENDAR_DAY_BACKGROUND };
          border: solid 2px ${ BACKGROUND };
          border-radius: 0;
          font-size: 14px;
          margin: 0;
          width: 55px;

          &--disabled {
            opacity: 0.5;
          }

          &--selected {
            background-color: ${ CALENDAR_DAY_SELECTED_BACKGROUND } !important;
            color: ${ CALENDAR_TEXT_SELECTED } !important;
          }

          &--highlighted {
            color: ${ CALENDAR_TEXT_TODAY };
            background-color: ${ CALENDAR_TODAY_BACKGROUND };
          }
          &--today {
            color: ${ CALENDAR_TEXT_SELECTED };
            background-color: ${ CALENDAR_TODAY_BACKGROUND };
          }
        }
      }
    }
  }
`;
Calendar.displayName = 'Calendar';

export const Footer = styled.div`
  padding: 4px 20px;
  background-color: ${ CALENDAR_FOOTER_BACKGROUND };
`;
Footer.displayName = 'Footer';
