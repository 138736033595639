export enum TEMPLATE_MODALS {
    SAVE_AS_NEW_TEMPLATE = 'SAVE_AS_NEW_TEMPLATE',
    TEMPLATE_DELETE = 'TEMPLATE_DELETE',
    TEMPLATE_UPDATE = 'TEMPLATE_UPDATE',
    TEMPLATE_PLACE_ORDER = 'TEMPLATE_PLACE_ORDER'
}

export interface ModalData {
    description: string;
    title: string;
    modalType: string;
    onDismiss?: any;
    onConfirm?: (values: any) => void;
}

export interface ModalProps extends ModalData {
    openModal: TEMPLATE_MODALS;
    onConfirm: () => void;
    onDismiss: () => void;
}

export enum TEMPLATE_TYPE {
    saveAsTemplate = 'saveAsTemplate',
    deleteTemplate = 'deleteTemplate',
    updateTemplate = 'updateTemplate',
    processOrderTemplate = 'processOrderTemplate'
}
