import React from 'react';
import Buttons from 'components/Buttons';

interface Props {
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const ExportDataButton: React.FC<Props> = () => {
  return (
    <Buttons
      options={
        [
          /* TODO enable when ready
            {
              id: 'exportAccountStatement',
              label: translations.billsList_exportData,
              onClick,
              type: 'button',
            }
          */
        ]
      }
    />
  );
};

export default ExportDataButton;
