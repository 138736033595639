import React from 'react';

import SmartForm from 'components/SmartForm';
import Buttons from 'components/Buttons';
import { StatusResponse } from 'components/StatusMessage/types';

import { useTranslationsContext } from 'app/translations';
import { ButtonProps } from 'components/Touchable/types';
import { FieldProps } from 'components/SmartForm/types';

import { createValidationSchema } from './data/validation';
import GoBackToLoginButton from 'containers/Onboarding/components/BackToLoginButton';
import { BackButton } from 'containers/Onboarding/components/PrivacyAgreementLink/styles';

interface Props {
  formName: string;
  buttons: ButtonProps[];
  fields: FieldProps[];
  handleSubmit: (fields: FieldProps[]) => void;
  isLoading?: boolean;
  statusResponse: StatusResponse;
}

const FormBody: React.FC<Props> = ({ formName, buttons, fields, isLoading, handleSubmit, statusResponse }) => {
  const translations = useTranslationsContext();
  const validationSchema = createValidationSchema(translations);

  return (
    <SmartForm
      form={ formName }
      buttons={ buttons }
      fields={ fields }
      handleSubmit={ handleSubmit }
      isFetching={ isLoading }
      statusResponse={ statusResponse }
      validateOnChange={ true }
      validationSchema={ validationSchema }
    >
      { ({ controlledButtons, controlledFields, Field }) => (
        <>
          { controlledFields.map(field => (
            <>
              <Field key={ field.id } options={ field } />
              { field?.messageLabel
              && <>
                <div className={ field?.messageLabel?.className }> 
                  { field?.messageLabel?.labelText }</div></> 
              }
              { field?.messageInfo
              && <>
                <div className={ field?.messageInfo?.className }> 
                  { field?.messageInfo?.text }</div></> 
              }
            </>
          )) }
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <GoBackToLoginButton/>
            <Buttons options={ controlledButtons } />
          </div>
        </>
      ) }
    </SmartForm>
  );
};

export default FormBody;
