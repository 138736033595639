import { Action } from 'app/types';

export enum ActionTypes {
  GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST',
  GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS',
  GET_LOCATION_ERROR = 'GET_LOCATION_ERROR'
}

type GetLocationRequest = Action<ActionTypes.GET_LOCATION_REQUEST>;
type GetLocationSuccess = Action<ActionTypes.GET_LOCATION_SUCCESS>;
type GetLocationError = Action<ActionTypes.GET_LOCATION_ERROR>;

type GetLocationActionType = GetLocationRequest | GetLocationSuccess | GetLocationError;

export type LocationActionType = GetLocationActionType;
