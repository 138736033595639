import React from 'react';
import { useTranslationsContext } from 'app/translations';
import ProgressBar from 'components/ProgressBar';

import { Wrapper, Text } from './styles';

interface Props {
  strength: number;
  color: string;
}

const StrengthProgress: React.FC<Props> = ({ strength, color }) => {
  const translations = useTranslationsContext();
  return (
    <>
      <Text>{ translations.field_passwordStrength }</Text>
      <Wrapper>
        <ProgressBar width={ strength < 1 ? 0 : 100 } color={ color } />
        <ProgressBar width={ strength < 2 ? 0 : 100 } color={ color } />
        <ProgressBar width={ strength < 3 ? 0 : 100 } color={ color } />
        <ProgressBar width={ strength < 4 ? 0 : 100 } color={ color } />
      </Wrapper>
    </>
  );
};
export default StrengthProgress;
