import React from 'react';
import Buttons from 'components/Buttons';
import { useHistory } from 'react-router-dom';
import { ROOT } from 'app/routes';
import { useTranslationsContext } from 'app/translations';

interface Props {
  align?: 'left';

}
const BackToLoginButton: React.FC<Props> = ({ align = 'left' }) => {
  const translations = useTranslationsContext();
  const history = useHistory();

  return (
    <Buttons align={ align }
      options={ [
        {
          id: 'backToLoginButton',
          label: translations.onboarding_backToLoginButton,
          onClick: () => {
            history.push(ROOT.path);
          },
          type: 'button'
        }
      ] }
    />
  );
};

export default BackToLoginButton;