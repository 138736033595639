/* eslint-disable max-lines */
import { FieldProps } from 'components/SmartForm/types';
import { LangKeys } from 'app/translations';
import { TEXT, TEXTAREA, CHECKBOX, DATE, DROPDOWN, SEARCH, NUMBER, ARRAY, FILE } from 'components/SmartForm/models';
import { ROLE } from 'app/constants/roles';
import { ConstraintGroup } from 'containers/PickupRequest/types';
import { createOptionsFromEnum, createOptionsFromList } from 'app/utils/fields';
import { TimePeriod, DESTINATION_OPTIONS, DateRuleDropdown, DriverOrderStatus } from 'app/constants/pickupRequest';

import { FieldName, MaterialFieldName, AddonsFieldName, DropdownOptions, DriverOrderFieldName } from '../types';

const createAddonsFields = (): FieldProps[] => [
  {
    id: AddonsFieldName.addonId,
    label: AddonsFieldName.addonId,
    type: TEXT
  },
  {
    id: AddonsFieldName.defaultValue,
    label: AddonsFieldName.defaultValue,
    type: TEXT
  },

  {
    id: AddonsFieldName.id,
    label: AddonsFieldName.id,
    type: TEXT
  },

  {
    id: AddonsFieldName.name,
    label: AddonsFieldName.name,
    type: TEXT
  },
  {
    id: AddonsFieldName.type,
    label: AddonsFieldName.type,
    type: NUMBER
  },
  {
    id: AddonsFieldName.options,
    label: AddonsFieldName.options,
    options: [],
    type: DROPDOWN
  }
];
export const createDriveOrdersFields = (translations: LangKeys): FieldProps[] => {
  const driveOrders: FieldProps[] = [];
  driveOrders.push(
    {
      id: DriverOrderFieldName.driverOrderId,
      label: translations.driver_order_number,
      type: TEXT,
      placeholder: translations.pickupRequestDetails_containerPlaceholder,
    },
    {
      id: DriverOrderFieldName.driverOrderContent,
      label: translations.driver_order_drive_content,
      type: TEXT,
      placeholder: translations.pickupRequestDetails_containerPlaceholder,
    },
    {
      id: DriverOrderFieldName.deliveryDate,
      label: translations.driver_order_drive_delivery,
      type: TEXT,
      // placeholder: translations.pickupRequestDetails_containerPlaceholder,
    },
    {
      id: DriverOrderFieldName.deliveryFrameId,
      label: translations.pickupRequestDetails_materialTimeLabel,
      type: DROPDOWN,
      options: createOptionsFromEnum<number>(TimePeriod, translations),
    },
    {
      id: DriverOrderFieldName.driveOrderStatusId,
      label: translations.driver_order_status,
      type: DROPDOWN,
      options: createOptionsFromEnum<number>(DriverOrderStatus, translations),
    },
  );
  return driveOrders;
};

export const createMaterialOptionFields = (translations: LangKeys, role: ROLE): FieldProps[] => {
  const showMaterialsDetination = role === ROLE.AVAG_ADMIN;

  const materialOptions: FieldProps[] = [];

  materialOptions.push(
    {
      id: MaterialFieldName.containerId,
      label: translations.pickupRequestDetails_containerLabel,
      type: SEARCH,
      placeholder: translations.pickupRequestDetails_containerPlaceholder
    },
    {
      id: MaterialFieldName.containerName,
      label: translations.pickupRequestDetails_containerLabel,
      type: SEARCH,
      placeholder: translations.pickupRequestDetails_containerPlaceholder
    },
    {
      id: MaterialFieldName.addons,
      type: ARRAY,
      fields: createAddonsFields()
    }
  );

  materialOptions.push({
    id: MaterialFieldName.materialId,
    label: translations.pickupRequestDetails_materialLabel,
    type: DROPDOWN,
    placeholder: translations.pickupRequestDetails_materialPlaceholder
  });

  materialOptions.push({
    id: MaterialFieldName.canPickup,
    type: CHECKBOX
  });

  materialOptions.push({
    id: MaterialFieldName.pickupContainers,
    label: translations.pickupRequestDetails_pickAmountLabel,
    type: NUMBER,
    placeholder: translations.pickupRequestDetails_pickAmountPlaceholder
  });

  materialOptions.push({
    id: MaterialFieldName.canDeliver,
    type: CHECKBOX
  });

  materialOptions.push({
    id: MaterialFieldName.deliverContainers,
    label: translations.pickupRequestDetails_deliverAmountLabel,
    type: NUMBER,
    placeholder: translations.pickupRequestDetails_deliverAmountPlaceholder
  });

  if (showMaterialsDetination) {
    materialOptions.push({
      id: MaterialFieldName.destination,
      label: translations.pickupRequestDetails_materialDestinationLabel,
      type: DROPDOWN,
      options: createOptionsFromList(DESTINATION_OPTIONS),
      placeholder: translations.pickupRequestDetails_materialPlaceholder
    });

    materialOptions.push({
      id: MaterialFieldName.date,
      title: translations.calendar_title_date,
      label: translations.pickupRequestDetails_materialDateLabel,
      type: DATE,
      placeholder: translations.pickupRequestDetails_materialPlaceholder
    });

    materialOptions.push({
      id: MaterialFieldName.timeSlot,
      label: translations.pickupRequestDetails_materialTimeLabel,
      type: DROPDOWN,
      options: createOptionsFromEnum<number>(TimePeriod, translations)
    });
  }

  materialOptions.push({
    id: MaterialFieldName.handleDocumentation,
    label: translations.pickupRequestDetails_handleVevaMaterial,
    type: CHECKBOX
  });

  return materialOptions;
};

export const createFields = (
  translations: LangKeys, 
  constraintGroup: ConstraintGroup,
  dropdownOptions: DropdownOptions
): FieldProps[] => {
  const fields: FieldProps[] = [];
  const { role, status } = constraintGroup;
  const showRemindAddress = role !== ROLE.AVAG_ADMIN;
  const showCompanyData = role === ROLE.AVAG_ADMIN;
  const showDriverInfo = role === ROLE.AVAG_ADMIN;

  if (showCompanyData) {
    fields.push({
      id: FieldName.companyId,
      isAlwaysDisabled: true,
      label: translations.pickupRequestDetails_companyLabel,
      options: dropdownOptions.companyOptions,
      type: DROPDOWN
    });

    fields.push({
      id: FieldName.creatorName,
      isAlwaysDisabled: true,
      label: translations.pickupRequestDetails_requestorLabel,
      type: TEXT,
      options: dropdownOptions.customerOptions
    });
  }

  fields.push({
    id: FieldName.location,
    label: translations.pickupRequestDetails_locationLabel,
    type: TEXT,
    placeholder: translations.pickupRequestDetails_locationPlaceholder
  });
  if (showRemindAddress) {
    fields.push({
      id: FieldName.creatorName,
      label: translations.pickupRequestDetails_creatorLabel,
      type: TEXT,
      placeholder: translations.pickupRequestDetails_locationPlaceholder, 
      isAlwaysDisabled: true,
      //  (role !== ROLE.AVAG_ADMIN)
    });
  }

  fields.push({
    id: FieldName.telephone,
    label: translations.pickupRequestDetails_phoneLabel,
    type: TEXT,
    placeholder: translations.pickupRequestDetails_phonePlaceholder
  });

  fields.push({
    id: FieldName.hasToCallClient,
    type: CHECKBOX,
    label: translations.pickupRequestDetails_phoneCheck,
    isChecked: false
  });

  if (showRemindAddress) {
    fields.push({
      id: FieldName.hasToSaveLocation,
      label: translations.pickupRequestDetails_saveLocationLabel,
      type: CHECKBOX,
      isChecked: false
    });
  }
 
  fields.push({
    id: FieldName.templateDateRuleNewTemplate,
    label: translations.template_modalBox_date_rule,
    type: DROPDOWN, 
    options: createOptionsFromEnum<number>(DateRuleDropdown, translations)
  });

  fields.push({
    id: FieldName.templateDateRule,
    label: translations.template_modalBox_date_rule,
    type: DROPDOWN, 
    options: createOptionsFromEnum<number>(DateRuleDropdown, translations)
  });
  fields.push({
    id: FieldName.preferredDate,
    title: translations.calendar_title_preferred_date,
    label: translations.pickupRequestDetails_preferredDateLabel,
    type: DATE,
    minLength: new Date().getTime(),
    placeholder: translations.pickupRequestDetails_preferredDatePlaceholder,
    isDisabled: (status && role === ROLE.AVAG_ADMIN)
  });

  fields.push({
    id: FieldName.timePeriod,
    label: translations.pickupRequestDetails_preferredTimeLabel,
    type: DROPDOWN,
    options: createOptionsFromEnum<number>(TimePeriod, translations),
    isDisabled: (status && role === ROLE.AVAG_ADMIN)
  });

  fields.push({
    id: FieldName.materials,
    type: ARRAY,
    fields: createMaterialOptionFields(translations, role)
  });
  fields.push({
    id: FieldName.driverOrders,
    type: ARRAY,
    fields: createDriveOrdersFields(translations)
  });

  fields.push({
    id: FieldName.bookingReference,
    label: translations.pickupRequestDetails_bookingRefLabel,
    type: TEXT,
    placeholder: translations.pickupRequestDetails_bookingRefPlaceholder
  });

  fields.push({
    id: FieldName.hasToSaveBookinReference,
    label: translations.pickupRequestDetails_saveLabel,
    type: CHECKBOX,
    isChecked: false
  });

  fields.push({
    id: FieldName.additionalComments,
    label: translations.pickupRequestDetails_commentaryLabel,
    type: TEXTAREA,
    placeholder: translations.pickupRequestDetails_commentaryPlaceholder
  });

  fields.push({
    id: FieldName.attachedFiles,
    label: translations.pickupRequestDetails_uploadFileLabel,
    type: FILE,
    placeholder: translations.pickupRequestDetails_uploadFilePlaceholder
  });

  if (showDriverInfo) {
    fields.push({
      id: FieldName.additionalDriverComments,
      label: translations.pickupRequestDetails_commentaryLabel,
      type: TEXTAREA,
      placeholder: translations.pickupRequestDetails_driverCommentPlaceholder
    });

    fields.push({
      id: FieldName.attachedDriverFiles,
      label: translations.pickupRequestDetails_uploadFileLabel,
      type: FILE,
      placeholder: translations.pickupRequestDetails_uploadFilePlaceholder
    });
  }

  return fields;
};
