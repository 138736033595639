import { AxiosError } from 'axios';
import { createPaginationFromServer } from 'app/models';
import { Pagination } from 'app/types';

interface GenericList<T> {
  collection: T[];
  pagination: Pagination;
}

export const handleUnauthorizedError = (error: AxiosError) => {
  if (error.response?.status === 403 && window?.location) {
    window.location.href = window.location.origin;
  }

  throw error;
};

export const handleListError = <T>(error: AxiosError | any): GenericList<T> => {
  switch (error.response?.status) {
    case 404:
      return {
        collection: [],
        pagination: createPaginationFromServer({})
      };

    default:
      console.error(error);
      throw error.toJSON ? error.toJSON() : new Error(error);
  }
};
