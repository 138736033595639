import { FormikValues } from 'formik';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ObjectSchema } from 'yup';
import { createIndexedFields, flattenObject } from 'app/utils/fields';
import SmartForm from 'components/SmartForm';
import { FieldProps } from 'components/SmartForm/types';
import { ButtonProps } from 'components/Touchable/types';
import { BookingRef } from 'containers/BookingRefs/types';
import AsideLayout from 'containers/Core/components/AsideLayout';
import { Location } from 'containers/Location/types';
import { getContainerList } from 'containers/PickupRequest/selectors';
import { PickupRequest, Container } from 'containers/PickupRequest/types';
import { SectionWrapper } from '../../styles';
import { ModalProps, FieldName, RequestHandler, SetFieldValue } from '../../types';
import AsideSection from '../AsideSection';
import Commentary from '../Commentary';
import ConfirmationModal from '../ConfirmationModal';
import DriverInfo from '../DriverInfo';
import Logistics from '../Logistics';
import DriverOrders from '../DriverOrders';
import Materials from '../Materials';
import { ButtonsWrapper } from './styles';

interface Props {
  buttons: ButtonProps[];
  fields: FieldProps[];
  initialValues: PickupRequest;
  isLoading: boolean;
  modalProps?: ModalProps;
  onSubmit: () => void;
  requestHandler: RequestHandler;
  shouldShowAside: boolean;
  updateValues: (values: FormikValues) => void;
  validationSchema: ObjectSchema<object>;
  locationData?: Location[];
  bookingRefs?: BookingRef[];
}

// eslint-disable-next-line max-lines-per-function
const View: React.FC<Props> = ({ buttons,
  fields,
  initialValues,
  isLoading,
  modalProps,
  onSubmit,
  requestHandler,
  shouldShowAside,
  updateValues,
  validationSchema,
  locationData,
  bookingRefs }) => {
  const { id, status } = initialValues;
  const containers = useSelector(getContainerList());

  const [formErrors, getFormErrors] = React.useState({});
  const [formErrorKeys, setFormErrorKeys] = React.useState({});

  const [formTouched, getFormTouched] = React.useState({});
  const [formTouchedKeys, setFormTouchedKeys] = React.useState({});

  useEffect(() => {
    setFormErrorKeys(flattenObject(formErrors));
  }, [formErrors]);

  useEffect(() => {
    setFormTouchedKeys(flattenObject(formErrors));
  }, [formTouched]);

  return (
    <>
      <AsideLayout aside={ shouldShowAside && <AsideSection /> }>
        <SmartForm
          form={ 'request' }
          buttons={ buttons }
          fields={ fields }
          handleErrors={ getFormErrors }
          handleTouched={ getFormTouched }
          initialValues={ initialValues }
          isFetching={ isLoading }
          handleSubmit={ onSubmit }
          validationSchema={ validationSchema }
          validateOnChange={ true }
          submitRequireDirty={ false }
          validateRequireDirty={ false }
        >
          { ({ Buttons, controlledButtons, controlledFields, dirty, setFieldValue, touched, values }) => {
            const indexedFields = createIndexedFields<FieldName>(controlledFields);

            const handleSelectCompany = () => {
              setFieldValue('owner', { id: '', firstName: '', lastName: '' }, true);
            };

            const handleSelectContainer = (
              field: string,
              container?: Container,
              containerId?: string
            ): SetFieldValue => {
              setFieldValue(field, container?.id || containerId);
              setFieldValue(field.replace('.containerId', '.containerName'), container?.name);
              setFieldValue(field.replace('.containerId', '.addons'), container?.addons || []);

              return setFieldValue;
            };

            const smartButtons = controlledButtons.map<ButtonProps>(b => {
              if (b.id == 'resend_request') b.isDisabled = false;
              if (b.id !== 'print_request') return b;
              return { ...b, onClick: b.onClick(Object.keys(touched).length && dirty) };
            });

            const typedValues = values as PickupRequest;
            const companyId = typedValues.company.id;

            // When first renders, set containerName based on containerId for each material
            // This operation is needed to display container name in a field since
            // it's not saved in backend (so it's not present in values)
            if (containers.length) {
              const { materials } = typedValues;
              materials.forEach((material, index) => {
                const selectedContainer = containers.find(container => container.id === material.containerId);
                if (selectedContainer && !material.containerName) {
                  setFieldValue(`materials.${ index }.containerName`, selectedContainer.name);
                }
              });
            }
            updateValues(values);

            return (
              <>
                <SectionWrapper>
                  <Logistics
                    id={ id }
                    companyId={ companyId }
                    onContainerListRequest={ requestHandler.containerList }
                    onCustomerListRequest={ requestHandler.customerList }
                    onCompanySelection={ handleSelectCompany }
                    status={ status }
                    fields={ indexedFields }
                    shouldShowAside={ shouldShowAside }
                    locationData={ locationData }
                    values={ typedValues }
                  />
                </SectionWrapper>
                <SectionWrapper>
                  <DriverOrders
                    isLoading={ isLoading }
                    fields={ indexedFields }
                    values={ typedValues }
                  />
                </SectionWrapper>
                <SectionWrapper>
                  <Materials
                    key={ indexedFields.materials.id }
                    formErrors={ formErrorKeys }
                    formTouched={ formTouchedKeys }
                    isLoading={ isLoading }
                    fields={ indexedFields }
                    values={ typedValues }
                    onSelectContainer={ handleSelectContainer }
                  />
                </SectionWrapper>

                <SectionWrapper>
                  <Commentary fields={ indexedFields } bookingRefs={ bookingRefs } />
                </SectionWrapper>

                { indexedFields['driverInformation.additionalComment'] && (
                  <SectionWrapper>
                    <DriverInfo fields={ indexedFields } />
                  </SectionWrapper>
                ) }

                <ButtonsWrapper>
                  <Buttons options={ smartButtons } />
                </ButtonsWrapper>
              </>
            );
          } }
        </SmartForm>
      </AsideLayout>

      { modalProps && <ConfirmationModal { ...modalProps } /> }
    </>
  );
};

export default View;
