import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslationsContext } from 'app/translations';
import Card from 'components/Card';
import List from 'components/List';
import SectionTitle from 'components/SectionTitle';
import { useIsLoading } from 'app/hooks';
import { useNotificationListRequest, useNotificationsReset } from '../../hooks';
import { getNotificationList } from '../../selectors';
import { Notification } from '../../types';
import { navigate } from '../../utils';
import FooterTable from './components/FooterTable';
import ItemRow from './components/ItemRow';
import { Container, WrapperTitle, WrapperButton, EmptyCollection } from './styles';

interface Props {
  maxItems?: number;
  pickupButton: React.ReactNode;
}

const HomeNotifications: React.FC<Props> = ({ maxItems, pickupButton }) => {
  const translations = useTranslationsContext();
  const history = useHistory();
  const { collection, pagination } = useSelector(getNotificationList);
  const isLoading = useIsLoading(['get-notifications']);

  const footer = <FooterTable currentLength={ collection.length } totalCount={ pagination.totalCount } />;

  useNotificationsReset();
  useNotificationListRequest(pagination.currentPage, maxItems || pagination.pageSize);

  const handleNavigation = (row: Notification) => {
    const { entityId, entityType, extras } = row;
    navigate(history, { entityId, entityType, extras });
  };

  return (
    <Container>
      <WrapperTitle>
        <SectionTitle>{ translations.routeName_notifications }</SectionTitle>
        <WrapperButton>{ pickupButton }</WrapperButton>
      </WrapperTitle>

      <Card indentation={ false } footer={ footer }>
        { /* TODO: Skeleton */ }
        { isLoading && !collection.length && <EmptyCollection>Loading...</EmptyCollection> }

        { !isLoading && !collection.length
         && <EmptyCollection>{ translations.table_emptyMessage_notification }</EmptyCollection> }

        { (!isLoading || !!collection.length) && (
          <List>
            { ({ ListItem }) =>
              collection.map(item => (
                <ListItem
                  key={ item.id }
                  onClick={ () => {
                    handleNavigation(item);
                  } }
                >
                  <ItemRow item={ item } />
                </ListItem>
              ))
            }
          </List>
        ) }
      </Card>
    </Container>
  );
};

export default HomeNotifications;
