/* eslint-disable max-lines-per-function */
import { ButtonProps } from 'components/Touchable/types';
import { LangKeys } from 'app/translations';
import { ROLE } from 'app/constants/roles';
import { PickupRequestStatus } from 'app/constants/pickupRequest';

import { ConstraintGroup } from 'containers/PickupRequest/types';
import { CreatorProps, FieldName } from '../types';

export const createPickupRequestActionButtons = (
  translations: LangKeys,
  constraintGroup: ConstraintGroup,
  buttonActions: CreatorProps['buttonActions'],
  newTemplate: boolean,
  loadTemplateDetails: boolean
  // eslint-disable-next-line max-params
): ButtonProps[] => {
  const { role, status } = constraintGroup;
  const isAVAGAdmin = role === ROLE.AVAG_ADMIN;

  const saveRequestDependencies = [
    FieldName.location,
    FieldName.preferredDate,
    FieldName.timePeriod,
    FieldName.materials,
    FieldName.bookingReference
  ];

  // Create all buttons
  const closeButton: ButtonProps = {
    id: 'close',
    isAlwaysEnabled: true,
    type: 'button',
    label: translations.pickupRequestDetails_closeButton,
    onClick: buttonActions.handleClose
  };

  const cancelButton: ButtonProps = {
    id: 'cancel_request',
    isAlwaysEnabled: true,
    type: 'reset',
    label: translations.pickupRequestDetails_abortButton,
    onClick: buttonActions.handleAbort
  };

  const deleteButton: ButtonProps = {
    id: 'delete_request',
    type: 'button',
    label: translations.pickupRequestDetails_deleteButton,
    onClick: buttonActions.handleRemove
  };

  const printButton: ButtonProps = {
    id: 'print_request',
    isAlwaysEnabled: true,
    type: 'button',
    label: translations.pickupRequestDetails_printButton,
    onClick: buttonActions.handlePrint
  };

  const resendButton: ButtonProps = {
    id: 'resend_request',
    isAlwaysEnabled: true,
    isDisabled: false,
    type: 'button',
    label: 'Auftrag übermitteln',
    onClick: buttonActions.handleResend
  };

  // const saveAsDraftButton: ButtonProps = {
  //   id: 'save_for_later',
  //   type: 'button',
  //   label: translations.pickupRequestDetails_saveForLaterButton,
  //   fieldDependencies: saveRequestDependencies,
  //   onClick: () => {
  //     buttonActions.handleSave(PickupRequestStatus.DRAFT);
  //   }
  // };

  const saveButton: ButtonProps = {
    id: 'save_request',
    type: isAVAGAdmin ? 'button' : 'submit',
    buttonType: isAVAGAdmin ? undefined : 'primary',
    fieldDependencies: saveRequestDependencies,
    isAlwaysDisabled: false,
    isAlwaysEnabled: false,
    isDisabled: false,
    label: isAVAGAdmin
      ? translations.pickupRequestDetails_updateOrderButton
      : translations.pickupRequestDetails_placeOrderButton,
    onClick: () => {
      const newStatus = isAVAGAdmin ? PickupRequestStatus.IN_PROCESS : PickupRequestStatus.NEW;
      buttonActions.handleSave(newStatus);
    }
  };

  const processButton: ButtonProps = {
    id: 'request_processed',
    type: 'submit',
    buttonType: 'primary',
    fieldDependencies: saveRequestDependencies,
    label: translations.pickupRequestDetails_completeOrderButton
  };

  const saveTemplateButton: ButtonProps = {
    id: 'save_template',
    fieldDependencies: saveRequestDependencies,
    type: 'submit',
    label: loadTemplateDetails ? translations.template_save_as_newTemplate : translations.template_save_as,
    buttonType: 'primary',
    onClick: () => {
      buttonActions.handleSaveTemplate();
    }
  };

  const templateDeleteButton: ButtonProps = {
    id: 'delete_template',
    type: 'button',
    label: translations.template_delete,
    buttonType: 'warning',
    onClick: () => {
      buttonActions.handleDeleteTemplate();
    }
  };

  const templateUpdateButton: ButtonProps = {
    id: 'update_template',
    type: 'button',
    label: translations.template_update,
    buttonType: 'warning',
    fieldDependencies: saveRequestDependencies,
    onClick: () => {
      buttonActions.handleUpdateTemplate();
    }
  };

  // Create button collection
  const buttons: ButtonProps[] = [];

  // Rules for show/hide buttons
  if (
    (!isAVAGAdmin && status === PickupRequestStatus.IN_PROCESS)
    || status === PickupRequestStatus.PROCESSED
  ) {
    buttons.push(closeButton);
  } 
  if (status !== PickupRequestStatus.NEW && status !== PickupRequestStatus.PROCESSED) {
    buttons.push(cancelButton);
  }

  if (!newTemplate) {
    if (
      //  (!status || status === PickupRequestStatus.DRAFT)
      (status === PickupRequestStatus.DRAFT)
      || (isAVAGAdmin && status === PickupRequestStatus.IN_PROCESS)
    ) {
      buttons.push(deleteButton);
    }

    if (status && isAVAGAdmin) {
      buttons.push(printButton);
    }
    if (isAVAGAdmin && !status) { // Rules no status and avag admin print button
      buttons.push(printButton);
    }

    // if (!isAVAGAdmin && (!status || status === PickupRequestStatus.DRAFT)) {
    //   buttons.push(saveAsDraftButton);
    // }

    //Add ResendButton
    if(isAVAGAdmin && status === PickupRequestStatus.NO_NAV){
      buttons.push(resendButton);
    }

    if (
      (!status || status === PickupRequestStatus.DRAFT)
      || (isAVAGAdmin && status < PickupRequestStatus.IN_PROCESS)
    ) {
      buttons.push(saveButton);
    }

    if (isAVAGAdmin && status === PickupRequestStatus.IN_PROCESS) {
      buttons.push(saveButton);
    }

    if (isAVAGAdmin && status < PickupRequestStatus.PROCESSED) {
      buttons.push(processButton);
    }
    if (isAVAGAdmin && !status) { // Rules no status and avag admin
      buttons.push(processButton);
    }
  }
  if (loadTemplateDetails) {
    buttons.push(templateDeleteButton);
    buttons.push(templateUpdateButton);
    // buttons.push(saveTemplateButton);
    buttons.push(saveButton);
    return buttons;
  }
  if (newTemplate) {
    buttons.push(saveTemplateButton);
  }

  return buttons;
};
