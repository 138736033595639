import styled from 'styled-components';
import { DESKTOP } from 'base/styles/media-queries';

import { HEADER_SIZE, FOOTER_SIZE, FOOTER_SPACE, MAX_WIDTH_ASIDE_SIZE_DESKTOP } from 'base/styles/sizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - ${ HEADER_SIZE + FOOTER_SIZE + FOOTER_SPACE }px);

  ${ DESKTOP } {
    flex-direction: row;
  }
`;
Container.displayName = 'Container';

export const Content = styled.div`
  ${ DESKTOP } {
    flex: 1;
    padding-right: 20px;
  }
`;
Content.displayName = 'Content';

export const Aside = styled.aside`
  padding-top: 20px;

  ${ DESKTOP } {
    width: ${ MAX_WIDTH_ASIDE_SIZE_DESKTOP }px;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 0;
  }
`;
Aside.displayName = 'Aside';
