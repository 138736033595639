import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutRequest } from './actions';

const Logout: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(logoutRequest());
  }, []);

  return null;
};

export default Logout;
