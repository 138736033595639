import React, { memo } from 'react';
import { useTranslationsContext } from 'app/translations';
import { RendererProps } from 'components/Table/types';

import { BillDocumentType } from 'containers/Bills/types';

const DocTypeRenderer = <R extends unknown>({ cell }: RendererProps<R>) => {
  const { bill, creditNote } = useTranslationsContext();
  let docTypeLabel;
  switch (cell) {
    case BillDocumentType.CREDIT:
      docTypeLabel = creditNote;
      break;
    case BillDocumentType.BILL:
    default:
      docTypeLabel = bill;
  }
  return <p>{ docTypeLabel }</p>;
};
export default memo(DocTypeRenderer);
