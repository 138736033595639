import React from 'react';
import { useSelector } from 'react-redux';
import { useIsLoading } from 'app/hooks';
import { useTranslationsContext } from 'app/translations';
import SectionTitle from 'components/SectionTitle';
import List from 'components/List';
import Card from 'components/Card';
import { QuotasSkeleton } from 'components/Skeletons';
import Footer from './components/Footer';
import ItemRow from './components/ItemRow';
import { useQuotaListRequest, useQuotasReset } from '../../hooks';
import { getQuotaList } from '../../selectors';

import { Container, EmptyCollection } from './styles';

interface Props {
  maxItems?: number;
  showTotalButton?: boolean;
}

const PreviewQuotes: React.FC<Props> = ({ maxItems, showTotalButton = true }) => {
  const translations = useTranslationsContext();
  const { collection, pagination } = useSelector(getQuotaList);
  const isLoading = useIsLoading(['get-quotas-request']);

  useQuotasReset();
  useQuotaListRequest(pagination.currentPage, maxItems || pagination.pageSize);

  return (
    <Container>
      <SectionTitle>{ translations.routeName_quotas }</SectionTitle>
      <Card footer={ <Footer total={ showTotalButton && pagination.totalCount } /> }>
        { isLoading && !collection.length && <QuotasSkeleton /> }
        { !isLoading && !collection.length
         && <EmptyCollection>{ translations.table_emptyMessage_Quotas }</EmptyCollection> }
        { !!collection.length && (
          <List>
            { ({ ListItem }) =>
              collection.map((item, i) => (
                <ListItem key={ i } onClick={ () => {} }>
                  <ItemRow item={ item } />
                </ListItem>
              ))
            }
          </List>
        ) }
      </Card>
    </Container>
  );
};

export default PreviewQuotes;
