import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { buildChartBackgrounds, getChartBackgrounds } from 'base/styles/skin';
import { DimensionChartOptions, CHART_HEIGHT_OVERVIEW, CHART_HEIGHT } from 'app/constants/statistic';
import { useIsLoading } from 'app/hooks';
import { useTranslationsContext } from 'app/translations';
import { createStatisticsData,
  // getTotalDimension,
  getFilteredMetricUnits,
  getDataGroupedByMaterial } from 'app/utils/chart';
import { getColorIn } from 'app/utils/colors';
import { lastMonthDate, currentYear, firstDayOfYear } from 'app/utils/date';
// import { formatCurrency } from 'app/utils/formatters';
// import { safeString } from 'app/utils/safeTypes';
import AreaChart from 'components/AreaChart';
import BarChart from 'components/BarChart';
import Card from 'components/Card';
import { StatisticsSkeleton } from 'components/Skeletons';
import SectionTitle from 'components/SectionTitle';
import { useStatisticsRequest } from '../../hooks';
import { getStatistic } from '../../selectors';
import { DataFilterValues } from '../../types';
import Footer from './components/Footer';
import Header from './components/Header';
import Legend from './components/Legend';
import Tooltip from './components/Tooltip';
import { Container, Details, Body, EmptyCollection, ChartWrapper, RightWrapper } from './styles';

interface Props {
  isOverview?: boolean;
  isSecondary?: boolean;
  isComparing?: boolean;
  onCompare?: () => void;
  dimension?: DimensionChartOptions;
  onChangeDimension?: (newDimension: DimensionChartOptions) => void;
}

// eslint-disable-next-line max-lines-per-function
const StatisticsWidget: React.FC<Props> = ({ dimension = DimensionChartOptions.costWeight,
  isOverview,
  isSecondary,
  isComparing,
  onCompare,
  onChangeDimension }) => {
  const isLoading = useIsLoading(['get-statistics-meta', isSecondary ? 'compare-statistics' : 'get-statistics']);
  const translations = useTranslationsContext();
  const statisticData = useSelector(getStatistic(isSecondary));
  const [filterValues, setFilterValues] = useState<DataFilterValues>({
    startDate: (isOverview ? firstDayOfYear : lastMonthDate).getTime(),
    endDate: new Date().getTime(),
    material: '',
    dimensions: [!isOverview, true] // [cost (height), weight (width)]
  });
  const [materialIndex, setMaterialIndex] = useState(0);

  const aggregated = getDataGroupedByMaterial(statisticData.data);
  const { data: drawable, meta } = createStatisticsData(statisticData, dimension, filterValues);
  const isAreaChart = dimension === DimensionChartOptions.fraction;
  const filteredMetricUnits = getFilteredMetricUnits(statisticData.metricUnits, filterValues);

  useStatisticsRequest(filterValues, isSecondary);

  const updateFilter = (field: string, value: any) => {
    setFilterValues({ ...filterValues, [field]: value });
  };

  useEffect(() => {
    const showRadio = isAreaChart || isOverview;
    if (!showRadio !== filterValues.dimensions[0]) {
      const newDimensions = [!showRadio, true];
      updateFilter('dimensions', newDimensions);
    }
  }, [isAreaChart, statisticData?.data?.length]);

  const axisLabels = isOverview
    ? []
    : [translations.statistics_weight, translations.statistics_costs, translations.statistics_credit];

  const chartHeight = isOverview ? CHART_HEIGHT_OVERVIEW : CHART_HEIGHT;
  const ChartComponent = isAreaChart ? AreaChart : BarChart;

  // Title and Sublegend, only for overview
  const title = isOverview && `${ translations.home_statistic_title } ${ currentYear }`;
  // const totalValues = isOverview && getTotalDimension(statisticData, filterValues);
  // const totalUnits = isOverview && safeString(filteredMetricUnits);
  // const subLegendDetails = isOverview && formatCurrency(totalValues, totalUnits);
  // const subLegendTitle = filterValues.dimensions[0]
  //   ? translations.home_statistic_totalCost
  //   : translations.home_statistic_totalWeight;

  const footer = !isLoading && (
    <Footer isOverview={ isOverview } isComparing={ isComparing } onCompare={ onCompare } onDownload={ () => {} } />
  );

  const hasDataDrawable = drawable.length > 0;

  const CHART_BACKGROUNDS = !isAreaChart
    ? buildChartBackgrounds(drawable.length)
    : getChartBackgrounds();

  return (
    <Container isOverview={ isOverview }>
      { title && <SectionTitle>{ title }</SectionTitle> }

      <Card footer={ footer }>
        { (hasDataDrawable || !isOverview) && (
          <Header
            data={ aggregated }
            dimension={ dimension }
            filterValues={ filterValues }
            isAreaChart={ isAreaChart }
            isComparing={ isComparing }
            isOverview={ isOverview }
            isSecondary={ isSecondary }
            onChangeDimension={ onChangeDimension }
            onChangeMaterial={ setMaterialIndex }
            onFilter={ updateFilter }
          />
        ) }

        <Body minHeight={ chartHeight }>
          { isLoading && <StatisticsSkeleton /> }

          { !isLoading && (
            <>
              { !hasDataDrawable && <EmptyCollection>{ translations.table_emptyMessage_statistics }</EmptyCollection> }

              { hasDataDrawable && (
                <>
                  <ChartWrapper className="ChartWrapper">
                    <ChartComponent
                      axisLabels={ axisLabels }
                      color={ getColorIn(CHART_BACKGROUNDS, materialIndex) }
                      containerHeight={ chartHeight }
                      data={ drawable }
                      meta={ meta }
                      metricUnits={ filteredMetricUnits }
                      tooltip={ Tooltip }
                    />
                    <RightWrapper>
                      { /* { subLegendDetails && (
                        <SubLegend>
                          <Title>{ subLegendTitle }</Title>
                          <Details>{ subLegendDetails }</Details>
                        </SubLegend>
                      ) } */ }
                      { !isComparing && (
                        <Legend
                          isAreaChart={ isAreaChart }
                          isOverview={ isOverview }
                          maxHeight={ chartHeight }
                          title={ <Details>{ translations.statistics_legend }</Details> }
                        >
                          { ({ LegendItem }) =>
                            drawable
                              .filter(record => !record.isPolyfill)
                              .map(({ cost, date, title: tooltipTitle, weight }, i) => (
                                <LegendItem
                                  key={ i }
                                  color={ !isAreaChart && getColorIn(CHART_BACKGROUNDS, i) }
                                  cost={ cost }
                                  date={ isAreaChart && date }
                                  metricUnits={ filteredMetricUnits }
                                  title={ !isAreaChart && tooltipTitle }
                                  weight={ weight }
                                />
                              ))
                          }
                        </Legend>
                      ) }
                    </RightWrapper>
                  </ChartWrapper>
                </>
              ) }
            </>
          ) }
        </Body>
      </Card>
    </Container>
  );
};

export default StatisticsWidget;
