import * as api from '../api';
import { LoginRequest } from '../types';
import { ActionTypes, LoginActionType } from '../actionTypes';

export const loginRequest = (loginData: LoginRequest): LoginActionType => ({
  type: ActionTypes.LOGIN_REQUEST,
  request: api.loginRequest(loginData),
  requestName: 'login'
});

export const logoutRequest = (): LoginActionType => ({
  type: ActionTypes.LOGOUT_REQUEST,
  request: api.logoutRequest(),
  requestName: 'login'
});
