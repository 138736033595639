import styled from 'styled-components';

export const SectionWrapper = styled.div`
  margin-bottom: 30px;
`;
SectionWrapper.displayName = 'SectionWrapper';

export const CardContent = styled.div`
  margin: 10px 0;
`;
CardContent.displayName = 'CardContent';
