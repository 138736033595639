import styled from 'styled-components';
import { PRIMARY } from 'base/styles/skin';
import { DESKTOP } from 'base/styles/media-queries';

export const Wrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  height: 30px;
  padding: 3px;
  width: 30px;

  ${ DESKTOP } {
    display: none;
  }
`;
Wrapper.displayName = 'Wrapper';

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${ PRIMARY };
  margin: 3px auto;
  transition: 0.4s;
`;
Line.displayName = 'Line';

interface LineProps {
  active: boolean;
  animated: boolean;
}

export const FirstLine = styled(Line)`
  transform: ${ ({ active, animated }: LineProps) => (active && animated
    ? 'rotate(-35deg) translate(-4px, 7px)'
    : 'none') };
`;
FirstLine.displayName = 'FirstLine';

export const SecondLine = styled(Line)`
  margin: 6px auto;
  opacity: ${ ({ active, animated }: LineProps) => (active && animated
    ? '0'
    : 'initial') };
`;
SecondLine.displayName = 'SecondLine';

export const ThirdLine = styled(Line)`
  transform: ${ ({ active, animated }: LineProps) => (active && animated
    ? 'rotate(35deg) translate(-4px, -7px)'
    : 'none') };
`;
ThirdLine.displayName = 'ThirdLine';