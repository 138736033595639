import React, { PropsWithChildren } from 'react';
import Footer from 'components/Footer';
import Header from './components/Header';

import { Container, Body, Content } from './styles';

const SimpleLayout: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <Container>
    <Body>
      <Header />
      <Content>{ children }</Content>
    </Body>
    <Footer />
  </Container>
);

export default SimpleLayout;
