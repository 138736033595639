import { CSSProperties } from 'react';
import { FIELD_BORDER_COLOR,
  FIELD_BACKGROUND,
  FIELD_BORDER_COLOR_DISABLED,
  ERROR_BORDER_COLOR } from 'base/styles/skin';

export const getTextStyles = (): CSSProperties => ({
  appearance: 'none',
  backgroundColor: FIELD_BACKGROUND,
  borderRadius: '4px',
  border: 'none',
  borderBottom: `1px solid ${ FIELD_BORDER_COLOR }`,
  borderTop: '1px solid transparent',
  color: 'inherit',
  cursor: 'inherit',
  display: 'block',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  lineHeight: '1.3em',
  minHeight: '150px',
  padding: '5px 10px',
  resize: 'vertical',
  transition: 'background-color 0.2s, border: 0.2s, color 0.2s',
  userSelect: 'initial',
  width: '100%'
});

export const getTextAreaStyles = () => ({
  ...getTextStyles(),
  border: `1px solid ${ FIELD_BORDER_COLOR }`,
  overflow: 'auto',
  outline: 'none'
});

export const getTextAreaErrorStyles = () => ({
  border: `1px solid ${ ERROR_BORDER_COLOR }`
});

export const getTextAreaDisabledStyles = () => ({
  borderRadius: '0px',
  border: 'none',
  minHeight: '107px',
  borderBottom: `solid 1px ${ FIELD_BORDER_COLOR_DISABLED }`
});
