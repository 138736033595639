import * as React from 'react';
import { Buttons as StyledButtons, Button as StyledButton } from './styles';
import { ButtonProps } from '../Touchable/types';
import Content from '../Touchable';

interface ChildProps {
  options: ButtonProps;
}

const Button: React.FC<ChildProps> = ({ options: { buttonType,
  icon,
  iconLast,
  id,
  isAlwaysDisabled,
  isAlwaysEnabled,
  isDiactivatable = true,
  isDisabled,
  label,
  name,
  onClick,
  type = 'button' } }) => (
  <StyledButton
    disabled={ isAlwaysDisabled || (isDiactivatable && !isAlwaysEnabled && isDisabled) }
    id={ id }
    isPrimary={ buttonType === 'primary' }
    name={ name }
    onClick={ event => {
      if (onClick) onClick(event);
      
    } }
    type={ type }
  >
    <Content options={ { icon, iconLast, label } } />
  </StyledButton>
);

interface Props {
  align?: 'left' | 'center' | 'right';
  className?: string;
  options: ButtonProps[];
}

const Buttons: React.FC<Props> = ({ align = 'right', className, options }) => {
  if (!options?.length) {
    return null;
  }

  return (
    <StyledButtons align={ align } className={ className }>
      { options.map((button: ButtonProps) => {
        const isDisabled = (!button.isAlwaysEnabled && button.isDisabled) || button.isAlwaysDisabled;

        return <Button key={ button.id } options={ { ...button, isDisabled } } />;
      }) }
    </StyledButtons>
  );
};

export default Buttons;
