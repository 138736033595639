import React from 'react';
import { useCallback } from 'react';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FieldProps } from '../../types';
import { HiddenInput } from '../styles';
import './ReCaptcha.css';

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  setFieldValue: (field: string, value: boolean) => void;
  options: FieldProps;
  sitekey?: string;
}

const Captcha: React.FC<Props> = ({ onChange,
  onClick,
  onFocus,
  setFieldValue,
  options,
  sitekey = process.env.CAPTCHA_PUBLIC }) => {
  const [value, setValue] = React.useState(false);

  const onVerify = useCallback(response => {
    setValue(!!response);
    setFieldValue(options.id, !!response);
  }, [value]);

  return (
    <>
      <HiddenInput
        id={ options.id }
        name={ options.id }
        checked={ !!value }
        disabled={ options.isDisabled }
        onClick={ onClick }
        onChange={ onChange }
        onFocus={ onFocus }
        required={ options.isRequired }
        type="checkbox"
        value={ value.toString() }
      />

      { sitekey && process.env.NODE_ENV !== 'test' && (
        <GoogleReCaptchaProvider reCaptchaKey={ sitekey }>
          <GoogleReCaptcha
            onVerify={ onVerify }
            refreshReCaptcha="false"
          />
        </GoogleReCaptchaProvider>
      ) }
    </>
  );
};

export default Captcha;
