import { safeString, safeDate, safeNumber } from 'app/utils/safeTypes';
import { DeliverySlip, Driver, MaterialInfo, DeliveryFile } from '../types';
import { DeliverySlipFromServer, DriverFromServer, MaterialInfoFromServer, FileFromServer } from '../types/fromServer';
import {env} from "base";

const createAttachmentFromServer = ({ ID, FileName, DocType, FileType }: FileFromServer): DeliveryFile | undefined => {
  if (ID === undefined || FileName === undefined || DocType === undefined || FileType === undefined) {
    console.error('Error creating <Attachment> model.');
    return undefined;
  }

  return {
    id: safeString(ID),
    name: safeString(FileName),
    docType: safeString(DocType),
    fileType: safeString(FileType)
  };
};

export const createAttachmentListFromServer = (attachmentList: FileFromServer[]): DeliveryFile[] => {
  if (!Array.isArray(attachmentList)) {
    console.error('Error creating <Attachment[]> model. Not array found.');
    return [] as DeliveryFile[];
  }

  return attachmentList.map(createAttachmentFromServer).filter(p => !!p);
};

const createMaterialFromServer = ({ No,
  Description,
  UnitOfMeasureCode,
  GrossWeight,
  TaraWeight,
  NetWeigth,
  UnitPrice,
  Amount,
  VatPercent }: MaterialInfoFromServer): MaterialInfo | undefined => {
  if (
    No === undefined
    || Description === undefined
    || UnitOfMeasureCode === undefined
    || GrossWeight === undefined
    || TaraWeight === undefined
    || NetWeigth === undefined
    || UnitPrice === undefined
  ) {
    console.error('Error creating <Item> model.');
    return undefined;
  }

  return {
    itemReference: safeString(No),
    material: safeString(Description),
    unit: safeString(UnitOfMeasureCode),
    gross: safeNumber(GrossWeight),
    tare: safeNumber(TaraWeight),
    net: safeNumber(NetWeigth),
    retailPrice: safeNumber(UnitPrice),
    amount: safeNumber(Amount),
    tax: safeNumber(VatPercent)
  };
};

export const createMaterialListFromServer = (materials: MaterialInfoFromServer[]): MaterialInfo[] => {
  if (!Array.isArray(materials)) {
    if(env == 'development'){
      console.error('Error creating <ItemInfo[]> model. Not array found.');
    }
    return [] as MaterialInfo[];
  }

  return materials.map(createMaterialFromServer).filter(p => !!p);
};

const createDriverFromServer = (carrier: DriverFromServer[], licensePlate: unknown): Driver | undefined => {
  if (licensePlate === undefined || carrier === undefined || carrier[0] === null) {
    if(env == 'development'){
      console.error('Error creating <Carrier> model.');
    }
    return { numberPlate: '', address: '' };
  }
  const { CarrierName = '', CarrierAddress = '', CarrierPostCode = '', CarrierCity = '' } = carrier?.[0] || {};
  return {
    numberPlate: safeString(licensePlate),
    address: safeString(`${ CarrierName } ${ CarrierAddress } ${ CarrierPostCode } ${ CarrierCity }`)
  };
};

export const createDeliverySlipFromServer = ({ OrderNo,
  OrderDate,
  DeliveryTime,
  AssociateBillId,
  SellToCustomerNo,
  DocNoOccurrence,
  VersionNo,
  LicensePlate,
  Carrier,
  BookingReference,
  docData,
  Attachments,
  SalesLine,
  DmsShipmentID,
  Status,
  PDFUrl,
  DOHeaderNo,
  CreatedbyTransportOrderNo }: DeliverySlipFromServer): DeliverySlip | undefined => {
  if (OrderNo === undefined || OrderDate === undefined || VersionNo === undefined || DocNoOccurrence === undefined) {
    console.error('Error creating <DeliverySlip> model.');
    return undefined;
  }

  const attachmentCount = safeNumber(Attachments);
  const mockedArray = attachmentCount > 0
    ? new Array(attachmentCount).fill(createAttachmentFromServer({ ID: '', FileName: '', DocType: '', FileType: '' }))
    : [];

  const attachments = !docData ? mockedArray : createAttachmentListFromServer(docData);

  const dateStringToFormat = DeliveryTime ? `${ OrderDate } ${ DeliveryTime?.[0] }` : `${ OrderDate }`;

  return {
    id: safeString(OrderNo),
    associateBillId: safeString(AssociateBillId),
    date: safeDate(`${ dateStringToFormat }`).getTime(),
    docNoOccurrence: safeString(DocNoOccurrence),
    versionNo: safeString(VersionNo),
    companyReference: safeString(SellToCustomerNo),
    driver: createDriverFromServer(Carrier, LicensePlate),
    attachments,
    materials: createMaterialListFromServer(SalesLine),
    PDFUrl: safeString(PDFUrl),
    dmsLinkID: safeString(DmsShipmentID),
    driverOrderNo: safeString(DOHeaderNo),
    transportOrderNo: safeString(CreatedbyTransportOrderNo),
    // ...(DmsShipmentID !== undefined && { dmsLinkID: safeString(DmsShipmentID) }),
    ...(Status !== undefined && { status: safeNumber(Status) }),
    ...(BookingReference !== undefined && { bookingReference: safeString(BookingReference) })
  };
};

export const createDeliverySlipListFromServer = (deliverySlipList: DeliverySlipFromServer[]): DeliverySlip[] => {
  if (!Array.isArray(deliverySlipList)) {
    console.error('Error creating <DeliverySlip[]> model. Not array found.');
    return [] as DeliverySlip[];
  }

  return deliverySlipList.map(createDeliverySlipFromServer).filter(p => !!p);
};
