import { getOffsetPagination } from 'app/utils/pagination';
import { ActionTypes, DeliverySlipActionType } from '../actionTypes';
import * as api from '../api';
import { ExportData, DeliveryFile } from '../types';

export const getDeliverySlipList = (page: number, pageSize: number, filterValue = '0'): DeliverySlipActionType => ({
  type: ActionTypes.GET_DELIVERY_SLIP_LIST_REQUEST,
  request: api.getDeliverySlipList(getOffsetPagination(page, pageSize), pageSize, filterValue),
  requestName: 'get-delivery-slips'
});

export const getDeliverySlip = (id: string, docNo: string, versionNo: string): DeliverySlipActionType => ({
  type: ActionTypes.GET_DELIVERY_SLIP_REQUEST,
  request: api.getDeliverySlip(id, docNo, versionNo),
  requestName: 'get-delivery-slip'
});

// export const getExportRequest = (exportData: ExportData): DeliverySlipActionType => ({
//   type: ActionTypes.GET_EXPORT_DATA_REQUEST,
//   request: api.getExportDataRequest(exportData),
//   requestName: 'export-data-request'
// });

export const getDeliveryFileRequest = (file: DeliveryFile): DeliverySlipActionType => ({
  type: ActionTypes.GET_DELIVERY_FILE_REQUEST,
  request: api.getDeliveryFileRequest(file),
  requestName: 'get-file-request'
});

export const resetDeliverySlips = (): DeliverySlipActionType => ({
  type: ActionTypes.RESET_DELIVERY_SLIPS
});

export const printDeliverySlip = (orderNo: string, linkId: string): DeliverySlipActionType => ({
  type: ActionTypes.PRINT_DELIVERY_SLIP_REQUEST,
  request: api.printDeliverySlip(orderNo, linkId),
  requestName: 'print-delivery-slip'
});
