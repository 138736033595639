import styled, { css } from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';
import { TABLET, DESKTOP } from 'base/styles/media-queries';
import { LINK_COLOR, LINK_COLOR_HOVER, PRIMARY, TEXT_COLOR } from 'base/styles/skin';
import { PAGE_MAX_WIDTH_SM } from 'base/styles/sizes';

export const WrapperHeader = styled.div`
  margin-top: 20px;

  ${ TABLET } {
    margin-top: 28px;
  }
`;
WrapperHeader.displayName = 'WrapperHeader';

export const InfoContainer = styled.div`
  width: 100%;

  & ${ WrapperHeader } {
    display: none;
  }

  ${ DESKTOP } {
    & ${ WrapperHeader } {
      display: block;
    }
  }
`;
InfoContainer.displayName = 'InfoContainer';

export const InfoContent = styled.div`
  margin: 0 auto;

  ${ TABLET } {
    width: 60%;
  }

  ${ DESKTOP } {
    margin-left: auto;
    margin-top: 60px;
    margin-right: 64px;
  }
`;
InfoContainer.displayName = 'InfoContent';

export const FormContainer = styled.div`
  margin: 0 auto;
  width: 100%;

  ${ TABLET } {
    max-width: 60%;
  }

  ${ DESKTOP } {
    margin: 0;
    margin-top: 140px;
    padding-left: 20px;

    & ${ WrapperHeader } {
      display: none;
    }
  }
`;
FormContainer.displayName = 'FormContainer';

export const WrapperForm = styled.div`
  margin: 0 auto;
  label{
      font-size: 14px;
  }
    
  .passwordContent{
   margin-top: 0px;
   color: #333;
   font-size: 14px;
    margin-bottom:10px;
   }
  .passwordTite{
    margin-top: 30px;
    margin-bottom:20px;
    flex-wrap: wrap;
    font-size: 22px;
    font-weight: 500;
    color: #666;
   }
  .passwordTite:before {
    content: '';
    display: inline-block;
    min-width: 18px;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    background-color: #5fa550;
    display: none;
   }

  ${ DESKTOP } {
    margin-left: 0;
    max-width: ${ PAGE_MAX_WIDTH_SM }px;
  }
`;
WrapperForm.displayName = 'WrapperForm';

export const InfoText = styled.div`
  margin-top: 34px;
  margin-bottom: 16px;
  color: ${ TEXT_COLOR };
  font-size: 14px;
  line-height: 1.4;
  white-space: pre-line;
`;
InfoText.displayName = 'InfoText';

export const WrapperTitle = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
`;
WrapperTitle.displayName = 'WrapperTitle';

const commonLinkCss = css`
  &,
  &:active,
  &:hover,
  &:focus,
  &:link,
  &:visited {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: ${ PRIMARY };
    text-decoration: underline;
  }
`;

export const Link = styled(ReactRouterLink)`
  &,
  &:link,
  &:visited {
    text-decoration: none;
    cursor: pointer;
  }
`;
Link.displayName = 'Link';

export const GoBackToLogin = styled(Link)`
  ${ commonLinkCss }
`;
GoBackToLogin.displayName = 'GoBackToLogin';

export const ExternalLink = styled.a`
  // ${ commonLinkCss }
  &,
  &:active,
  &:focus,
  &:link,
  &:visited {
    margin-left: 1px;
    margin-bottom: 20px;
    color: ${ LINK_COLOR };
    cursor: pointer;
    font-size: 7px;
    font-weight: 500;
    text-decoration: underline;
    white-space: nowrap;

    ${ TABLET } {
      font-size: 12px;
    }
    ${ DESKTOP } {
      font-size: 14px;
    }
    &:hover{
        color: ${ LINK_COLOR_HOVER };
    }
  }  
`;
ExternalLink.displayName = 'ExternalLink';

export const IconsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0 15px;
`;
IconsRow.displayName = 'IconsRow';

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
`;
IconWrapper.displayName = 'IconWrapper';

export const Icon = styled.img`
  width: 80px;
  height: 80px;
  margin: 0 20px 0 0;
  object-fit: contain;
`;
Icon.displayName = 'Icon';

export const IconTitleTextWrapper = styled.div`
  color: ${ TEXT_COLOR };
  font-size: 14px;
`;
IconTitleTextWrapper.displayName = 'IconTitleTextWrapper';

export const IconTitle = styled.div`
  margin: 0 0 8px;
  
  color: ${ TEXT_COLOR };
  font-size: 16px;
  font-weight: 500;
`;
IconTitle.displayName = 'IconTitle';

export const IconText = styled.div`
  color: ${ TEXT_COLOR };
  font-size: 14px;
  line-height: 1.4;
`;
IconText.displayName = 'IconText';

export const SectionSubTitle = styled.div`
  color: ${ TEXT_COLOR };
  font-size: 14px;
  color: red;
`;
SectionSubTitle.displayName = 'SectionSubTitle';

export const OnboadingList = styled.div`
  margin-left: 18px;
  margin-top: 12px;
  color: ${ TEXT_COLOR };
  font-size: 14px;
  line-height: 1.4;
  .onbording_sqaure_list >li{
    list-style-type: square;
  }
`;
OnboadingList.displayName = 'OnboadingList';
