import { Action } from 'app/types';

export enum ActionTypes {
  GET_COMPANIES_SIMPLE_REQUEST = 'GET_COMPANIES_SIMPLE_REQUEST',
  GET_COMPANIES_SIMPLE_SUCCESS = 'GET_COMPANIES_SIMPLE_SUCCESS',
  GET_COMPANIES_SIMPLE_ERROR = 'GET_COMPANIES_SIMPLE_ERROR',

  GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST',
  GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS',
  GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR',

  GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS',
  GET_COMPANY_ERROR = 'GET_COMPANY_ERROR',

  GET_COMPANYLIST_REQUEST = 'GET_COMPANYLIST_REQUEST',
  GET_COMPANYLIST_SUCCESS = 'GET_COMPANYLIST_SUCCESS',
  GET_COMPANYLIST_ERROR = 'GET_COMPANYLIST_ERROR',

  POST_CHANGECOMPANY_REQUEST = 'POST_CHANGECOMPANY_REQUEST',

  PATCH_COMPANY_PERMISSIONS_REQUEST = 'PATCH_COMPANY_PERMISSIONS_REQUEST',
  PATCH_COMPANY_PERMISSIONS_SUCCESS = 'PATCH_COMPANY_PERMISSIONS_SUCCESS',
  PATCH_COMPANY_PERMISSIONS_ERROR = 'PATCH_COMPANY_PERMISSIONS_ERROR',

  RESET_COMPANIES = 'RESET_COMPANIES'
}

type GetCompaniesSimpleRequest = Action<ActionTypes.GET_COMPANIES_SIMPLE_REQUEST>;
type GetCompaniesSimpleSuccess = Action<ActionTypes.GET_COMPANIES_SIMPLE_SUCCESS>;
type GetCompaniesSimpleError = Action<ActionTypes.GET_COMPANIES_SIMPLE_ERROR>;

type GetCompaniesRequest = Action<ActionTypes.GET_COMPANIES_REQUEST>;
type GetCompaniesSuccess = Action<ActionTypes.GET_COMPANIES_SUCCESS>;
type GetSettingsError = Action<ActionTypes.GET_COMPANIES_ERROR>;

type GetCompanyRequest = Action<ActionTypes.GET_COMPANY_REQUEST>;
type GetCompanySuccess = Action<ActionTypes.GET_COMPANY_SUCCESS>;
type GetCompanyError = Action<ActionTypes.GET_COMPANY_ERROR>;

type GetCompanyListRequest = Action<ActionTypes.GET_COMPANYLIST_REQUEST>;
type GetCompanyListSuccess = Action<ActionTypes.GET_COMPANYLIST_SUCCESS>;
type GetCompanyListError = Action<ActionTypes.GET_COMPANYLIST_ERROR>;

type PostChangeCompanyRequest = Action<ActionTypes.POST_CHANGECOMPANY_REQUEST>;

type PatchCompanyPermissionsRequest = Action<ActionTypes.PATCH_COMPANY_PERMISSIONS_REQUEST>;
type PatchCompanyPermissionsSuccess = Action<ActionTypes.PATCH_COMPANY_PERMISSIONS_SUCCESS>;
type PatchCompanyPermissionsError = Action<ActionTypes.PATCH_COMPANY_PERMISSIONS_ERROR>;

type ResetCompanies = Action<ActionTypes.RESET_COMPANIES>;

type GetCompaniesSimpleActionType = GetCompaniesSimpleRequest | GetCompaniesSimpleSuccess | GetCompaniesSimpleError;
type GetCompaniesActionType = GetCompaniesRequest | GetCompaniesSuccess | GetSettingsError;
type GetCompaniyActionType = GetCompanyRequest | GetCompanySuccess | GetCompanyError;
type GetCompanyListActionType = GetCompanyListRequest | GetCompanyListSuccess | GetCompanyListError;
type PostChangeCompanyActionType = PostChangeCompanyRequest;
type PatchCompanyPermissionsActionType =
  | PatchCompanyPermissionsRequest
  | PatchCompanyPermissionsSuccess
  | PatchCompanyPermissionsError;

export type CompaniesActionType =
  | GetCompaniesSimpleActionType
  | GetCompaniyActionType
  | GetCompaniesActionType
  | ResetCompanies
  | PostChangeCompanyActionType
  | PatchCompanyPermissionsActionType
  | GetCompanyListActionType;
