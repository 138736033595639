import React from 'react';
import { Tabs, Tab } from 'components/Tabs/styles';
import { useTranslationsContext } from 'app/translations';

const NavigatorTab: React.FC<{}> = () => {
  const translations = useTranslationsContext();
  return (
    <>
      <Tabs>
        <a href={"/pickup-requests"}>{ translations.pickup_and_template_back_to_overview_tab }</a>
        {/* <Tab to="/pickup-requests" activeClassName="active" > */}
        {/*   { translations.pickup_and_template_back_to_overview_tab }</Tab> */}
        {/* <Tab to="/pickup-requests/new" activeClassName="active" > */}
        {/*   { translations.template_tab1_blank_new }</Tab> */}
        {/* <Tab to="/pickup-requests/templates" activeClassName="active"> */}
        {/*   { translations.template_tab2_template } </Tab> */}
        { /* <Tab to="/pickup-requests/recurring" activeClassName="active">
                { translations.template_tab2_template } </Tab> */ }
      </Tabs>
    </>
  );
};

export default NavigatorTab;
