import React from 'react';
import { useSelector } from 'react-redux';
import { useQuotaListRequest, useQuotasReset } from '../hooks';
import { getQuotaList } from '../selectors';
import QuotasWidget from '../components/QuotasWidget';

const QuotasList: React.FC = () => {
  const { pagination } = useSelector(getQuotaList);

  useQuotasReset();
  useQuotaListRequest(pagination.currentPage, pagination.pageSize);

  return <QuotasWidget showTotalButton={ false } />;
};
export default QuotasList;
