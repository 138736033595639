import { Action } from 'app/types';

export enum ActionTypes {
  GET_STATISTICS_REQUEST = 'GET_STATISTICS_REQUEST',
  GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS',
  GET_STATISTICS_ERROR = 'GET_STATISTICS_ERROR',

  GET_STATISTICS_COMPARISION_REQUEST = 'GET_STATISTICS_COMPARISION_REQUEST',
  GET_STATISTICS_COMPARISION_SUCCESS = 'GET_STATISTICS_COMPARISION_SUCCESS',
  GET_STATISTICS_COMPARISION_ERROR = 'GET_STATISTICS_COMPARISION_ERROR',

  ENABLE_COMPARISION = 'ENABLE_COMPARISION',
  DISABLE_COMPARISION = 'DISABLE_COMPARISION',

  RESET_STATISTICS = 'RESET_STATISTICS',
  RESET_STATISTICS_COMPARISION = 'RESET_STATISTICS_COMPARISION',

  GET_STATISTICS_MATERIALS_REQUEST = 'GET_STATISTICS_MATERIALS_REQUEST',
  GET_STATISTICS_MATERIALS_SUCCESS = 'GET_STATISTICS_MATERIALS_SUCCESS',
  GET_STATISTICS_MATERIALS_ERROR = 'GET_STATISTICS_MATERIALS_ERROR'
}

type GetStatisticsRequest = Action<ActionTypes.GET_STATISTICS_REQUEST>;
type GetStatisticsSuccess = Action<ActionTypes.GET_STATISTICS_SUCCESS>;
type GetStatisticsError = Action<ActionTypes.GET_STATISTICS_ERROR>;

type GetStatisticsComparisionRequest = Action<ActionTypes.GET_STATISTICS_COMPARISION_REQUEST>;
type GetStatisticsComparisionSuccess = Action<ActionTypes.GET_STATISTICS_COMPARISION_SUCCESS>;
type GetStatisticsComparisionError = Action<ActionTypes.GET_STATISTICS_COMPARISION_ERROR>;

type EnableComparision = Action<ActionTypes.ENABLE_COMPARISION>;
type DisableComparision = Action<ActionTypes.DISABLE_COMPARISION>;

type ResetStatistics = Action<ActionTypes.RESET_STATISTICS>;
type ResetStatisticsComparision = Action<ActionTypes.RESET_STATISTICS_COMPARISION>;

type GetStatisticsMaterialsRequest = Action<ActionTypes.GET_STATISTICS_MATERIALS_REQUEST>;
type GetStatisticsMaterialsSuccess = Action<ActionTypes.GET_STATISTICS_MATERIALS_SUCCESS>;
type GetStatisticsMaterialsError = Action<ActionTypes.GET_STATISTICS_MATERIALS_ERROR>;

type GetStatisticsActionType = GetStatisticsRequest | GetStatisticsSuccess | GetStatisticsError;
type GetStatisticsComparisionActionType =
  | GetStatisticsComparisionRequest
  | GetStatisticsComparisionSuccess
  | GetStatisticsComparisionError;

type ComparisionActionType = EnableComparision | DisableComparision;

type ResetStatisticsActionType = ResetStatistics | ResetStatisticsComparision;

type GetStatisticsMaterialsActionType =
  | GetStatisticsMaterialsRequest
  | GetStatisticsMaterialsSuccess
  | GetStatisticsMaterialsError;

export type StatisticsActionType =
  | GetStatisticsActionType
  | GetStatisticsComparisionActionType
  | ComparisionActionType
  | ResetStatisticsActionType
  | GetStatisticsMaterialsActionType;
