import { http, envConf } from 'base';
import { handleListError } from 'app/utils/connection-errors';
import * as Model from '../models';
import { Location } from '../types';

export const getLocations = async (companyId: string): Promise<Location[]> => {
  try {
    const { data } = await http.get(envConf.API.LOCATION_GET.replace(':client_id', companyId));
    return Model.createLocationListFromServer(data);
  } catch (error) {
    const { collection } = handleListError(error);
    return collection as Location[];
  }
};
