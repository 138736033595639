import { http, envConf, isMockingApi } from 'base';
import { Pagination } from 'app/types';
import { createPaginationFromServer } from 'app/models';
import { handleListError } from 'app/utils/connection-errors';
import * as Model from '../models/fromServer';
import {CompanySimple, Company, CompanyPermissionRequest, CompanyList, CompanyChangeRequest} from '../types';
import * as localStorage from "app/utils/localStorage";
import {SESSION_TOKEN} from "app/constants/localStorage";
import {createUserFromServer} from "containers/Login/models";
import {decodeLoginToken} from "app/utils/encoding";
import {addBearer, removeBearer} from "containers/Login/api";

interface CompaniesResponse {
  collection: Company[];
  pagination: Pagination;
}

export const getCompaniesSimple = async (): Promise<CompanySimple[]> => {
  try {
    const { data } = await http.get(envConf.API.COMPANIES_GET);
    return Model.createCompanySimpleListFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getCompanies = async (offset: number, limit: number, filterValue: string): Promise<CompaniesResponse> => {
  try {
    const { data } = await http.get(envConf.API.SETTINGS_GET, {
      params: { limit, offset, filterValue }
    });

    const { client_permissions, ...pagination } = data;

    return {
      collection: Model.createCompanyListFromServer(client_permissions),
      pagination: createPaginationFromServer(pagination)
    };
  } catch (error) {
    return handleListError(error);
  }
};

export const getCompany = async (id: string): Promise<Company> => {
  try {
    const { data } = await http.get(envConf.API.COMPANY_GET.replace(':id', id));
    return Model.createCompanyFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getCompanyList = async (id: string): Promise<CompanyList[]> => {
  try {
    const { data } = await http.get(envConf.API.COMPANYLIST_GET.replace(':id', id));
    return Model.createCompanySwitchListFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const changeCompanyForUser = async (request: CompanyChangeRequest)=> {//: Promise<LoggedUser | undefined> => {
  try {
    const { data } = await http.post<string>(envConf.API.COMPANYCHANGE_POST, request);

    if (data) {
      if(request.current_client_id !== request.client_id){
        localStorage.removeItem(SESSION_TOKEN);
        removeBearer();

        // Persist user's data
        localStorage.setItem(SESSION_TOKEN, data);

        // Add authentication layer
        addBearer(data);

        createUserFromServer(decodeLoginToken(data));
      }

      location.reload();
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error);
  }
};

export const companyPermissionsRequest = async (request: CompanyPermissionRequest): Promise<Company> => {
  try {
    const { data } = await http.patch(envConf.API.SETTINGS_PATCH.replace(':id', request.client_id.toString()), request);
    return Model.createCompanyFromServer({ ...data, client_name: '' });
  } catch (error) {
    if (isMockingApi()) {
      throw new Error(JSON.stringify(request));
    }
    throw new Error(error);
  }
};
