import produce from 'immer';
import { createReducer, isMockingApi } from 'base';
import { ActionHandler } from 'base/types';
import { ActionTypes as LogoutActions } from 'containers/Login/actionTypes';
import { PickupRequestStatus } from 'app/constants/pickupRequest';
import { ActionTypes, PickupRequestActionType } from '../actionTypes';
import { PICKUP_REQUEST_LIST, CONTAINER_LIST, CONTAINER_TYPES, MATERIAL_LIST } from '../mocks';
import { pickupRequestInitialState } from '../models';
import { PickupRequestState, PickupRequest, ContainerResponse, Material } from '../types';

const getRecentContainerListSuccess = (state: PickupRequestState, action: PickupRequestActionType) =>
  produce(state, draft => {
    const { containers } = action.payload as ContainerResponse;
    draft.dropdownGroup.recentsList = Array.isArray(containers) ? containers : state.dropdownGroup.recentsList;
  });

const getRecentContainerListError = (state: PickupRequestState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.dropdownGroup.recentsList = CONTAINER_LIST;
    }
  });

const getContainerListSuccess = (state: PickupRequestState, action: PickupRequestActionType) =>
  produce(state, draft => {
    const { containers, types } = action.payload as ContainerResponse;
    draft.dropdownGroup.containerList = Array.isArray(containers) ? containers : state.dropdownGroup.containerList;
    draft.dropdownGroup.typesList = Array.isArray(types) ? types : state.dropdownGroup.typesList;
  });

const getContainerListError = (state: PickupRequestState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.dropdownGroup.containerList = CONTAINER_LIST;
      draft.dropdownGroup.typesList = CONTAINER_TYPES;
    }
  });

const getMaterialListSuccess = (state: PickupRequestState, action: PickupRequestActionType) =>
  produce(state, draft => {
    const materials = action.payload as Material[];
    draft.dropdownGroup.materialList = Array.isArray(materials) ? materials : state.dropdownGroup.materialList;
  });

const getMaterialListError = (state: PickupRequestState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.dropdownGroup.materialList = MATERIAL_LIST;
    }
  });

const getPickupListSuccess = (state: PickupRequestState, action: PickupRequestActionType) =>
  produce(state, draft => {
    const { collection, pagination } = action.payload as PickupRequestState;
    draft.collection = collection || state.collection;
    draft.pagination = pagination || state.pagination;
  });

const getPickupListError = (state: PickupRequestState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.collection = PICKUP_REQUEST_LIST.slice(0, draft.pagination.pageSize);
      draft.pagination.totalCount = PICKUP_REQUEST_LIST.length;
    }
  });

const getPickupSuccess = (state: PickupRequestState, action: PickupRequestActionType) =>
  produce(state, draft => {
    const pickup = action.payload as PickupRequest;
    let hasBeenModified = false;
    draft.collection = state.collection.map(p => {
      if (p.id === pickup.id) {
        hasBeenModified = true;
        return {
          ...p,
          ...pickup
        };
      }

      return p;
    });

    if (!hasBeenModified) {
      draft.collection.push(pickup);
    }
  });

const updatePickupSuccess = (state: PickupRequestState, action: PickupRequestActionType) =>
  produce(state, draft => {
    const pickupRequest = action.payload as PickupRequest;
    draft.collection = draft.collection.map(pr => {
      if (pr.id === pickupRequest?.id) {
        return {
          ...pr,
          ...pickupRequest
        };
      }

      return pr;
    });
  });

const processPickupSuccess = (state: PickupRequestState, action: PickupRequestActionType) =>
  produce(state, draft => {
    const pickupRequest = action.payload as PickupRequest;
    draft.collection = draft.collection.map(pr => {
      if (pr.id === pickupRequest?.id) {
        return {
          ...pr,
          ...pickupRequest,
          status: PickupRequestStatus.IN_PROCESS
        };
      }

      return pr;
    });
  });

const deletePickupSuccess = (state: PickupRequestState, action: PickupRequestActionType) =>
  produce(state, draft => {
    const { id } = action.payload as PickupRequest;
    draft.collection = draft.collection.filter(pr => pr.id === id);
  });

const resetPickupRequestList = (state: PickupRequestState) =>
  produce(state, draft => {
    draft.collection = [];
  });

const getInitialState = (state: PickupRequestState) =>
  produce(state, draft => {
    Object.keys(draft).forEach(key => {
      // @ts-ignore
      draft[key] = pickupRequestInitialState[key];
    });
  });

const actionHandlers: ActionHandler<PickupRequestState> = {
  [ActionTypes.GET_RECENT_CONTAINER_LIST_SUCCESS]: getRecentContainerListSuccess,
  [ActionTypes.GET_RECENT_CONTAINER_LIST_ERROR]: getRecentContainerListError,
  [ActionTypes.GET_CONTAINER_LIST_SUCCESS]: getContainerListSuccess,
  [ActionTypes.GET_CONTAINER_LIST_ERROR]: getContainerListError,
  [ActionTypes.GET_MATERIAL_LIST_SUCCESS]: getMaterialListSuccess,
  [ActionTypes.GET_MATERIAL_LIST_ERROR]: getMaterialListError,
  [ActionTypes.GET_PICKUP_LIST_SUCCESS]: getPickupListSuccess,
  [ActionTypes.GET_PICKUP_LIST_ERROR]: getPickupListError,
  [ActionTypes.GET_PICKUP_SUCCESS]: getPickupSuccess,
  [ActionTypes.UPDATE_PICKUP_SUCCESS]: updatePickupSuccess,
  [ActionTypes.PROCESS_PICKUP_SUCCESS]: processPickupSuccess,
  [ActionTypes.DELETE_PICKUP_SUCCESS]: deletePickupSuccess,
  [ActionTypes.RESET_PICKUP_LIST]: resetPickupRequestList,
  [LogoutActions.LOGOUT_REQUEST]: getInitialState
};

export default createReducer(pickupRequestInitialState, actionHandlers);
