import * as Yup from 'yup';
import { LangKeys } from 'app/translations';
import { HelpRecipient } from 'containers/Help/types';
import { TimePeriod } from './pickupRequest';

const TEXTAREA_MAX_LENGTH = 1000;
const TEXT_MAX_LENGTH = 1000;
const EMAIL_MAX_LENGTH = 50;
const PASSWORD_MAX_LENGTH = 50;
const NAME_MAX_LENGTH = 50;
const COMPANY_MAX_LENGTH = 50;
const PHONE_MAX_LENGTH = 50;

export const PASSWORD_REGEX = {
  number: /^(?=.*[0-9]).*/,
  lowercase: /^(?=.*[a-z]).*/,
  uppercase: /^(?=.*[A-Z]).*/,
  symbol: /^(?=.*[@#$%^&+=_-]).*/
};

export const string = (translations: LangKeys) =>
  Yup.string().max(TEXT_MAX_LENGTH, translations.validations_maxLength?.replace(':max:', TEXT_MAX_LENGTH.toString()));

export const textArea = (translations: LangKeys) =>
  Yup.string().max(
    TEXTAREA_MAX_LENGTH,
    translations.validations_maxLength?.replace(':max:', TEXTAREA_MAX_LENGTH.toString())
  );

export const simplePassword = (translations: LangKeys): Yup.StringSchema =>
  Yup.string()
    .required(translations.resetPassword_requiredValidation)
    .max(PASSWORD_MAX_LENGTH, translations.validations_maxLength?.replace(':max:', PASSWORD_MAX_LENGTH.toString()));

export const password = (translations: LangKeys): Yup.StringSchema =>
  simplePassword(translations)
    .min(8, translations.resetPassword_minValidation)
    .matches(PASSWORD_REGEX.lowercase, translations.resetPassword_lowercaseValidation)
    .matches(PASSWORD_REGEX.uppercase, translations.resetPassword_uppercaseValidation)
    .matches(PASSWORD_REGEX.number, translations.resetPassword_numberValidation);

export const confirmPassword = (translations: LangKeys, passwordRef: string): Yup.StringSchema =>
  simplePassword(translations).oneOf([Yup.ref(passwordRef), null], translations.resetPassword_matchValidation);

export const firstName = (translations: LangKeys): Yup.StringSchema =>
  Yup.string()
    .required(translations.onboarding_requiredValidation)
    .max(NAME_MAX_LENGTH, translations.validations_maxLength?.replace(':max:', NAME_MAX_LENGTH.toString()))
    .matches(/^.*\S.*$/);

export const lastName = (translations: LangKeys): Yup.StringSchema =>
  Yup.string()
    .required(translations.onboarding_requiredValidation)
    .max(NAME_MAX_LENGTH, translations.validations_maxLength?.replace(':max:', NAME_MAX_LENGTH.toString()))
    .matches(/^.*\S.*$/);

export const companyName = (translations: LangKeys): Yup.StringSchema =>
  Yup.string()
    .required(translations.onboarding_requiredValidation)
    .max(COMPANY_MAX_LENGTH, translations.validations_maxLength?.replace(':max:', COMPANY_MAX_LENGTH.toString()))
    .matches(/^.*\S.*$/);

export const email = (translations: LangKeys): Yup.StringSchema =>
  Yup.string()
    .required(translations.onboarding_requiredValidation)
    .max(EMAIL_MAX_LENGTH, translations.validations_maxLength?.replace(':max:', EMAIL_MAX_LENGTH.toString()))
    .matches(/\S+@\S+\.\S+/, translations.onboarding_requiredMailFormat);

export const checkbox: Yup.BooleanSchema = Yup.boolean().oneOf([true]);

export const phoneNumberOptional = (translations: LangKeys): Yup.StringSchema =>
  Yup.string()
    .max(PHONE_MAX_LENGTH, translations.validations_maxLength?.replace(':max:', PHONE_MAX_LENGTH.toString()))
    .matches(/(\d+)/, translations.onboarding_phoneValidation);

export const phoneNumber = (translations: LangKeys): Yup.StringSchema => 
  phoneNumberOptional(translations).required(translations.onboarding_requiredValidation);

export const timePeriod: Yup.NumberSchema = Yup.number()
  .oneOf([TimePeriod.timePeriod_flexible, TimePeriod.timePeriod_morning, TimePeriod.timePeriod_afternoon])
  .required();

export const containerNumber: Yup.NumberSchema = Yup.number().required('min. ein Feld grösser 0');

export const subject = (translations: LangKeys): Yup.StringSchema =>
  Yup.string().required(translations.validations_requiredField);

export const recipient = (translations: LangKeys): Yup.NumberSchema =>
  Yup.number()
    .oneOf(
      [HelpRecipient.finance, HelpRecipient.customerService, HelpRecipient.disponent, HelpRecipient.help_feedback],
      translations.validations_requiredField
    )
    .nullable()
    .required();

export const message = (translations: LangKeys): Yup.StringSchema =>
  Yup.string()
    .required(translations.validations_requiredField)
    .max(TEXTAREA_MAX_LENGTH, translations.validations_maxLength?.replace(':max:', TEXTAREA_MAX_LENGTH.toString()));

export const startDate = (translations: LangKeys, endDateRef: string): Yup.NumberSchema =>
  Yup.number()
    .max(Yup.ref(endDateRef), translations.validations_maxStartDate)
    .required(translations.validations_requiredField);

export const endDate = (translations: LangKeys, startDateRef: string): Yup.NumberSchema =>
  Yup.number()
    .min(Yup.ref(startDateRef), translations.validations_minEndDate)
    .required(translations.validations_requiredField);
