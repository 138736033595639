import jwt from 'jsonwebtoken';
import { UserFromServer } from 'containers/Users/types';

export const decodeLoginToken = (token: string) => {
  try {
    return jwt.verify(token, process.env.LOGIN_PUBLIC_KEY) as UserFromServer;
  } catch (error) {
    switch (error.name) {
      case 'JsonWebTokenError': // Invalid token
      case 'TokenExpiredError': // Expired token
        return undefined;

      default:
        console.error(error);
        throw new Error(error);
    }
  }
};
