import React from 'react';
import { useTranslationsContext } from 'app/translations';

import AvagDot from 'components/AvagDot';
import { IconSearch } from 'components/Searcher/styles';
import { monthNames } from 'components/SmartForm/components/datepicker/constants';
import { DatePickerHeaderProps } from 'components/SmartForm/components/datepicker/types';

import { TitleWrapper, Title, ArrowButton, Header, Text, ArrowRight, DateSelect } from './styles';

interface Props extends DatePickerHeaderProps {
  title?: string | JSX.Element;
}
const arrowLeftIcon = require('assets/icons/arrowLeft.svg');
const getYear = (date: Date) => date.getFullYear();
const getMonth = (date: Date) => date.getMonth();
const years = Array.from({ length: getYear(new Date) - 2000 + 2 }, (_, i) => (getYear(new Date) + 1) - i);

// const years = [getYear(new Date) + 1, getYear(new Date)];

const CalendarHeader: React.FC<Props> = ({ title,
  date,
  changeYear,
  changeMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  increaseMonth,
  decreaseMonth }) => {
  const translations = useTranslationsContext();
  const monthToken = monthNames[date.getMonth()];
  // @ts-ignore
  const monthName = translations[monthToken];

  return (
    <>
      { title && (
        <TitleWrapper>
          <AvagDot />
          <Title>{ title }</Title>
        </TitleWrapper>
      ) }
      <Header>
          <ArrowButton onClick={ decreaseMonth } type="button" disabled={ prevMonthButtonDisabled }>
            <IconSearch src={ arrowLeftIcon }/>
          </ArrowButton>
        {/* Year Select Start  */}
        <DateSelect
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(value)}
        >
          {years.map((option) => (
            <option key={option} value={option} {option == getYear(date) ? "selected" : null}>
              {option}
            </option>
          ))}
        </DateSelect>
        {/* Year Select End  */}
        {/* Month Select Start  */}
        <DateSelect
          value={getMonth(date)}
          onChange={({ target: { value } }) => changeMonth(value)}
        >
          {monthNames.map((option, index) => (
            <option key={index} value={index} {index == getMonth(date) ? "selected" : null}>
              {translations[option]}
            </option>
          ))}
        </DateSelect>
        {/* Month Select End  */}
          <ArrowButton onClick={ increaseMonth } type="button" disabled={ nextMonthButtonDisabled }>
            <ArrowRight src={ arrowLeftIcon }/>
          </ArrowButton>
      </Header>
    </>
);
};
export default CalendarHeader;
