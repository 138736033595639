import React from 'react';
import Footer from 'components/Footer';

import { Container, LeftContent, RightContent } from './styles';

interface Props {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

const SplitView: React.FC<Props> = ({ left, right }) => (
  <>
    <Container>
      <RightContent>{ right }</RightContent>
      <LeftContent>{ left }</LeftContent>
    </Container>
    <Footer margin={ false } />
  </>
);

export default SplitView;
