import { useTranslationsContext } from 'app/translations';
import { createOptionsFromEnum } from 'app/utils/fields';
import { FieldProps } from 'components/SmartForm/types';
import { DROPDOWN, DATE } from 'components/SmartForm/models';
import { EXPORT_FORMAT } from 'containers/DeliverySlips/types';

export enum ExportFieldName {
  startDate = 'startDate',
  endDate = 'endDate',
  format = 'format'
}

export const useFields = (): FieldProps[] => {
  const translations = useTranslationsContext();
  const fields: FieldProps[] = [];

  fields.push({
    id: ExportFieldName.startDate,
    label: translations.deliverySlip_exportModalStartDateLabel,
    type: DATE,
    minLength: new Date(0).getTime(),
    withPortal: false
  });

  fields.push({
    id: ExportFieldName.endDate,
    label: translations.deliverySlip_exportModalEndDateLabel,
    type: DATE,
    withPortal: false
  });

  fields.push({
    id: ExportFieldName.format,
    label: translations.deliverySlip_exportModalFormatLabel,
    options: createOptionsFromEnum<string>(EXPORT_FORMAT, translations),

    type: DROPDOWN
  });

  return fields;
};
