import * as React from 'react';
import styled from 'styled-components';
import { FormikErrors } from 'formik';

const Label = styled.span`
  vertical-align: top;
`;
Label.displayName = 'Label';

const Space: React.FC = () => <span>&nbsp;&nbsp;</span>;

interface Props {
  options: {
    icon?: string | React.ReactElement<{}>;
    iconLast?: boolean;
    label?: (string | FormikErrors<any>) | (string | FormikErrors<any>)[] | JSX.Element;
  };
}

const Content: React.FC<Props> = ({ options: { icon, iconLast, label } }) => {
  if (typeof icon === 'string') {
    return (
      <>
        { icon && !iconLast && <i className={ `fa fa-${ icon }` } /> }
        { icon && !iconLast && label && <Space /> }
        { label && <Label>{ label }</Label> }
        { icon && iconLast && label && <Space /> }
        { icon && iconLast && <i className={ `fa fa-${ icon }` } /> }
      </>
    );
  }

  return (
    <>
      { icon && !iconLast && icon }
      { icon && !iconLast && label && <Space /> }
      { label && <Label>{ label }</Label> }
      { icon && iconLast && label && <Space /> }
      { icon && iconLast && icon }
    </>
  );
};

export default Content;
