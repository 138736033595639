import styled from 'styled-components';
import { TITLE_COLOR } from 'base/styles/skin';
import DefaultTable from 'components/Table';
import { TableFooter } from 'components/Table/styles';

export const SectionWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
`;
SectionWrapper.displayName = 'SectionWrapper';

export const WrapperTitle = styled.div`
  display: flex;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;
WrapperTitle.displayName = 'WrapperTitle';

export const Title = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  font-size: 22px;
  font-weight: 500;
  color: ${ TITLE_COLOR };
`;
Title.displayName = 'Title';

export const BillNumber = styled.div`
  display: flex;
  margin-left: 8px;
  margin-right: 16px;
`;
BillNumber.displayName = 'BillNumber';

export const Table = styled(DefaultTable)`
  & ${ TableFooter } {
    display: none;
  }
` as typeof DefaultTable;
