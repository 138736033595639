import { ChartData } from 'app/containers/Statistics/types';
import { formatDate } from 'app/utils/formatters';

export const formatAxisData = (date: ChartData['date']) => {
  const outputValue = formatDate(new Date(date));
  return outputValue;
};

const getCheckpoints = (data: number[], period = 5) => {
  return data.filter(d => {
    const currDate = new Date(d);
    const day = currDate.getDate();
    const isFeb = currDate.getMonth() === 1;

    // Always display first day of the month
    // when period is smaller than monthly
    if (day === 1 && period <= 30) {
      return true;
    }

    // Never return last days of the month
    // so first day is displayed (better reference)
    if ((!isFeb && day >= 29) || (isFeb && day >= 27)) {
      return false;
    }

    return day % period === 0;
  });
};

export const calculateDateTicks = (data: ChartData[]) => {
  // Yearly
  let period = 365;

  if (data.length >= 180) {
    // Quarterly
    period = 120;
  } else if (data.length >= 60) {
    // Monthly
    period = 30;
  } else if (data.length >= 30) {
    // Twice per month
    period = 15;
  } else if (data.length >= 19) {
    // Weekly (more or less)
    period = 5;
  } else {
    // Daily
    period = 1;
  }

  return getCheckpoints(
    data.map(d => d.date),
    period
  );
};
