import styled, { css } from 'styled-components';
import { TITLE_COLOR } from 'base/styles/skin';

export const WrapperTitle = css`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  padding: 24px 0;
`;

export const Title = styled.div`
  font-family: inherit;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-right: 8px;
  color: ${ TITLE_COLOR };
`;

export const SectionWrapper = styled.div`
  margin-bottom: 30px;
`;
SectionWrapper.displayName = 'SectionWrapper';

export const CardContent = styled.div`
  margin: 10px 0;
`;
CardContent.displayName = 'CardContent';

export const Error = styled.div`
`;
Error.displayName = 'Error';