import { Action } from 'app/types';

export enum ActionTypes {
  FORM_RESET = 'FORM_RESET'
}

type FormReset = Action<ActionTypes.FORM_RESET>;
type FormAction = Action<string>;

export type FormActionType = FormReset | FormAction;
