import React from 'react';
import { Wrapper, HeaderBar, Logo } from './styles';

const logo = require('assets/images/avag.svg');

const Header: React.FC = () => (
  <Wrapper>
    <HeaderBar>
      <Logo src={ logo } />
    </HeaderBar>
  </Wrapper>
);

export default Header;
