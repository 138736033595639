import { Action } from 'app/types';

export enum ActionTypes {
  GET_QUOTA_LIST_REQUEST = 'GET_QUOTA_LIST_REQUEST',
  GET_QUOTA_LIST_SUCCESS = 'GET_QUOTA_LIST_SUCCESS',
  GET_QUOTA_LIST_ERROR = 'GET_QUOTA_LIST_ERROR',

  RESET_QUOTAS = 'RESET_QUOTAS'
}

type GetQuotaListRequest = Action<ActionTypes.GET_QUOTA_LIST_REQUEST>;
type GetQuotaListSuccess = Action<ActionTypes.GET_QUOTA_LIST_SUCCESS>;
type GetQuotaListError = Action<ActionTypes.GET_QUOTA_LIST_ERROR>;

type ResetQuotas = Action<ActionTypes.RESET_QUOTAS>;

type GetQuotaListActionType = GetQuotaListRequest | GetQuotaListSuccess | GetQuotaListError;

export type QuotasActionType = GetQuotaListActionType | ResetQuotas;
