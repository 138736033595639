import { LocationState, Location, LocationFromServer } from '../types';

export const locationInitialState: LocationState = {
  collection: []
};

export const createLocationFromServer = (location: LocationFromServer): Location | undefined => {
  if (location.location_id === undefined || location.location === undefined) {
    console.error('Error creating location model');
    return undefined;
  }

  return {
    id: String(location.location_id),
    name: String(location.location)
  };
};

export const createLocationListFromServer = (locationList: LocationFromServer[]): Location[] => {
  if (!Array.isArray(locationList)) {
    console.error('Error creating <Location[]> model. Not array found.');
    return [] as Location[];
  }

  return locationList.map(createLocationFromServer).filter(p => !!p);
};
