import { ROLE } from 'app/constants/roles';
import { safeString, safeNumber, safeDate } from 'app/utils/safeTypes';
import { UserFromServer,
  User,
  Permissions,
  CompanyPermissions,
  CompanyPermissionsFromServer,
  UserPermissionFromServer } from '../types';

const findRole = (id: number) => {
  for (const index in ROLE) {
    if (ROLE[index]) {
      const parsedId = parseInt(index, 10);

      if (parsedId >= 0 && id === parsedId) {
        return Number(id);
      }
    }
  }

  return ROLE.CLIENT_OPERATOR;
};

export const createUserPermissionsFromServer = ({ bills_enable = false,
  pickup_request_enable = false }: UserPermissionFromServer): Permissions => ({
  requestPickup: !!pickup_request_enable,
  accessBills: !!bills_enable
});

export const createCompanyPermissionsFromServer = ({ portal_enable = true,
  pickup_request_enable = false,
  bills_enable = false }: CompanyPermissionsFromServer): CompanyPermissions => ({
  portal: !!portal_enable,
  requestPickup: !!pickup_request_enable,
  accessBills: !!bills_enable
});

export const createUserFromServer = ({ user_id,
  last_modifieddate,
  first_name,
  user_email,
  last_name,
  phone,
  client_id,
  client_name,
  role_id,
  permissions,
  client_permissions,
  status_id }: UserFromServer): User | undefined => {
  if (
    user_id === undefined
    || first_name === undefined
    || user_email === undefined
    || client_id === undefined
    || role_id === undefined
  ) {
    console.error('Error creating <User> model.');
    return undefined;
  }

  return {
    id: safeString(user_id),
    date: safeDate(last_modifieddate).getTime(),
    firstName: safeString(first_name),
    lastName: safeString(last_name),
    fullName: `${ safeString(first_name) } ${ safeString(last_name) }`,
    email: safeString(user_email),
    phoneNumber: safeString(phone),
    companyId: safeString(client_id),
    companyName: safeString(client_name),
    role: findRole(Number(role_id)),
    permissions: createUserPermissionsFromServer(permissions || {}),
    companyPermissions: createCompanyPermissionsFromServer(client_permissions || {}),
    status: safeNumber(status_id)
  };
};

export const createUserListFromServer = (userList: UserFromServer[]): User[] => {
  if (!Array.isArray(userList)) {
    console.error('Error creating <User[]> model. Not array found.');
    return [] as User[];
  }

  return userList.map(createUserFromServer).filter(p => !!p);
};
