import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { PRIMARY_BACKGROUND, hexToRGB } from 'base/styles/skin';

interface WrapperProps {
  background: {
    r: number;
    g: number;
    b: number;
  };
}

const SkeletonWrapper = styled.div`
  position: relative;
  overflow: hidden;

  &:after {
    @keyframes loading {
      from: {
        left: -100vw;
      }
      to {
        left: 100vw;
      }
    }

    content: ' ';
    animation: loading 1s infinite linear;
    background-image: ${ ({ background }: WrapperProps) => `linear-gradient(90deg,
      rgba(${ background.r }, ${ background.g }, ${ background.b }, 0) 0,
      rgba(${ background.r }, ${ background.g }, ${ background.b }, .8) 40%,
      rgba(${ background.r }, ${ background.g }, ${ background.b }, .8) 60%,
      rgba(${ background.r }, ${ background.g }, ${ background.b }, 0) 100%
    )` };
    background-position: 0 0;
    height: 100%;
    left: -100vw;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

interface Props {
  background?: string;
}

const Skeleton: React.FC<PropsWithChildren<Props>> = ({ background = PRIMARY_BACKGROUND, children }) => (
  <SkeletonWrapper background={ hexToRGB(background) }>{ children }</SkeletonWrapper>
);

export default Skeleton;
