import React from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonProps } from 'components/Touchable/types';
import { useTranslationsContext } from 'app/translations';
import PrivacyAgreementView from 'containers/Onboarding/components/PrivacyAgreementView';
import Modal from 'app/components/Modal';
import Buttons from 'app/components/Buttons';

import { Wrapper, Spacer, Container, Text, Impressum, Contact, Line, ExternalLink, RightContent } from './styles';

interface Props {
  margin?: boolean;
}

const Footer: React.FC<Props> = ({ margin = true }) => {
  const { footer_impressum, onboarding_privacy2, onboarding_closePrivacyAgreement } = useTranslationsContext();
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const tel = '+41 33 226 56 56';
  const mailto = 'markt@avag.ch';
  const web = 'http://www.avag.ch';
  const { location } = useHistory();

  const handleClose = () => {
    setOpenPrivacy(false);
  };

  const modalButtons: ButtonProps[] = [
    {
      id: 'closePrivacy',
      type: 'button',
      onClick: handleClose,
      label: onboarding_closePrivacyAgreement
    }
  ];
  
  return (
    <Wrapper margin={ margin }>
      <Spacer margin={ margin } />
      <Container noMenu={ location.pathname === '/' 
      || location.pathname === '/forgot-password' || location.pathname === '/onboarding' }
      >
        <Impressum>{ footer_impressum }</Impressum>
        <Contact>
          <Line>
            Direkt:
            <ExternalLink href={ `tel:${ tel }` } target="_blank" rel="noopener noreferrer">
              { tel }
            </ExternalLink>
          </Line>
          <Line>
            Mail:
            <ExternalLink href={ `mailto:${ mailto }` } target="_blank" rel="noopener noreferrer">
              { mailto }
            </ExternalLink>
          </Line>
          <Line>
            Web:
            <ExternalLink href={ web } target="_blank" rel="noopener noreferrer">
              { web.replace('http://', '') }
            </ExternalLink>
          </Line>
        </Contact>
        <RightContent>
          <ExternalLink
            href="https://www.avag.ch/impressum"
            target="_blank"
          >
            { onboarding_privacy2 }
          </ExternalLink>
          <Text>{ `© ${ new Date().getFullYear() } AVAG` }</Text>
        </RightContent>
      </Container>
      { openPrivacy && (
        <Modal
          header={ onboarding_privacy2 }
          footer={ <Buttons options={ modalButtons } /> }
          onClose={ handleClose }
          size="lg"
        >
          <PrivacyAgreementView isModal={ true } />
        </Modal>
      ) }
    </Wrapper>
  );
};

export default Footer;
