import styled from 'styled-components';
import { MIN_BAR_HEIGHT } from 'app/constants/statistic';

interface RectangleProps {
  backgroundColor: string;
  heightPercentage: number;
  isHidden?: boolean;
  totalWidth: number;
  isBigger: number;
}

export const Rectangle = styled.div.attrs(({ backgroundColor, 
  heightPercentage, 
  totalWidth, 
  isBigger }: RectangleProps) => ({
  style: {
    backgroundColor,
    height: `${ Math.abs(heightPercentage) }%`,
    minWidth: `${ totalWidth / isBigger }px`
  }
}))`
  margin: 0 3px;
  min-height: ${ ({ isHidden }: RectangleProps) => !isHidden && `${ MIN_BAR_HEIGHT }px` };
  transition: height 1s, min-width 1s;
  visibility: ${ ({ isHidden }: RectangleProps) => (isHidden ? 'hidden' : 'visible') };

  &:first-of-type {
    margin-left: 6px;
  }

  &:last-of-type {
    margin-right: 6px;
  }
`;
Rectangle.displayName = 'Rectangle';
