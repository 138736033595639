import { http, envConf } from 'base';
import { lastMonthDate, formatBackendDate } from 'app/utils/date';
import { handleListError } from 'app/utils/connection-errors';
import { createMaterialListFromServer } from 'containers/PickupRequest/models';
import { Material } from 'containers/PickupRequest/types';
import * as Model from '../models';
import { Statistic } from '../types';

export const getCronList = async () => {
  try {
    // eslint-disable-next-line max-len
    const { data } = await http.get(envConf.API.NOTIFICATIONNAV_GET, {
    });
    // eslint-disable-next-line no-console
    console.log(data);

  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
export const getStatistics = async (
  fromDate = lastMonthDate,
  toDate = new Date(),
  material: string
): Promise<Statistic> => {
  try { 
    const { data } = await http.get(envConf.API.STATISTICS_GET, {
      params: { fromPostingDate: formatBackendDate(fromDate), toPostingDate: formatBackendDate(toDate), material }
    });
    return Model.createStatisticFromServer(data);
  } catch (error) {
    const { collection } = handleListError(error);
    return Model.createStatisticFromServer(collection);
  }
};

// export const getMaterials = async (): Promise<Material[]> => {
//   try {
//     const { data } = await http.get(envConf.API.STATISTICS_MATERIALS_GET);
//     return createMaterialListFromServer(data);
//   } catch (error) {
//     console.error(error);
//     throw new Error(error);
//   }
// };
