import React from 'react';
import { Videoresponsive } from './styles';

interface Props {
embedId: string;
}

const YoutubeEmbed: React.FC<Props> = ({ embedId }) => {

  return (
    <Videoresponsive>
      <iframe
        width="853"
        height="480"
        src={ `https://www.youtube.com/embed/${ embedId }` }
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={ true }
        title="AVAG-Kundenportal - Erste Schritte"
      />
    </Videoresponsive>);
};

export default YoutubeEmbed;