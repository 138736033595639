import React from 'react';
import ReactPaginate from 'react-paginate';
import { PAGE_SIZE } from 'app/constants/table';
import { Pagination, SelectedItem } from '../../types';
import { PaginateWrapper, NavigationPrev, NavigationNext } from './styles';

const arrowLeft = require('assets/icons/arrowLeft.svg');

const Paginate: React.FC<Pagination> = ({ currentPage, onChange, pageSize = PAGE_SIZE, totalCount }: Pagination) => {
  const pages = totalCount / pageSize;

  const onPageChange = (selectedItem: SelectedItem) => {
    onChange(selectedItem);
  };

  return (
    <>
      <PaginateWrapper />
      <ReactPaginate
        previousLabel={ <NavigationPrev src={ arrowLeft } /> }
        nextLabel={ <NavigationNext src={ arrowLeft } /> }
        activeClassName="Paginate--active"
        activeLinkClassName="Paginate-link--active"
        breakClassName="Paginate-break"
        breakLinkClassName="Paginate-breakLink"
        containerClassName="Paginate-container"
        disabledClassName="Paginate--disabled"
        nextClassName="Paginate-next"
        nextLinkClassName="Paginate-nextLink"
        pageClassName="Paginate-page"
        pageLinkClassName="Paginate-pageLink"
        previousClassName="Paginate-prev"
        previousLinkClassName="Paginate-prevLink"
        onPageChange={ onPageChange }
        initialPage={ currentPage }
        pageCount={ pages }
        pageRangeDisplayed={ 5 }
        marginPagesDisplayed={ 2 }
      />
    </>
  );
};

export default Paginate;
