import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom';
import { LangKeys, useTranslationsContext } from 'app/translations';

import { FieldProps } from 'components/SmartForm/types';
import { helpRequest } from '../actions';
import { ActionTypes } from '../actionTypes';
import View from '../components/View';
import { useFormFields } from '../data/fields';
import { useFormButtons } from '../data/buttons';
import { HelpFromSection, HelpTopic, Help as HelpType } from '../types';

const topicLabels: Record<HelpTopic, keyof LangKeys> = {
  BILLS: 'bill',
  PICKUPS: 'pickupRequest',
  DELIVERY_SLIPS: 'deliverySlip'
};

const HelpForm: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const routeState = location.state as HelpFromSection;

  let isFinanceTopic;
  if (routeState) {
    const { from } = routeState;
    isFinanceTopic = from === HelpTopic.BILLS || from === HelpTopic.DELIVERY_SLIPS || from === HelpTopic.PICKUPS;
  }
  const fields = useFormFields();
  const buttons = useFormButtons();
  const translations = useTranslationsContext();

  const [error, setError] = useState(false);
  const [requestId, setRequestId] = useState('');

  const handleSubmit = useCallback(async (formFields: FieldProps[]) => {
    const subject = formFields.find(f => f.id === 'subject').model || '';
    const message = formFields.find(f => f.id === 'message').model || '';
    const department_id = Number(formFields.find(f => f.id === 'recipient').model) || undefined;
    const help = {
      subject,
      department_id,
      message
    };

    const { type, payload } = await dispatch(helpRequest(help));
    switch (type) {
      case ActionTypes.HELP_ERROR:
        setError(true);
        break;

      case ActionTypes.HELP_SUCCESS:
        setRequestId((payload as HelpType).id);
        break;

      default:
    }
  }, []);

  const helpContext = location.state as HelpFromSection;
  let subject: string;

  switch (helpContext?.from) {
    case HelpTopic.DELIVERY_SLIPS:
      subject = `RE: ${ translations[topicLabels.DELIVERY_SLIPS] } ${ helpContext.itemId }`;
      break;
    case HelpTopic.BILLS:
      subject = `RE: ${ translations[topicLabels.BILLS] } ${ helpContext.itemId }`;
      break;
    case HelpTopic.PICKUPS:
      subject = `RE: ${ translations[topicLabels.PICKUPS] } ${ helpContext.itemId }`;
      break;
    default:
  }

  const formInitialValues = {
    subject,
    recipient: isFinanceTopic ? fields.find(f => f.id === 'recipient').options[0].value : null
  };

  return (
    <View
      initialValues={ formInitialValues }
      formFields={ fields }
      formButtons={ buttons }
      handleSubmit={ handleSubmit }
      error={ error }
      requestId={ requestId }
      formSubmitted={ error || !!requestId }
    />
  );
};
export default HelpForm;
