import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslationsContext, LangKeys } from 'app/translations';
import { Column } from 'components/Table/types';
import { isAvagAdmin } from 'containers/Core/selectors';
import { UserRow } from 'containers/Users/types';
import { createColumns } from './table';

interface TableData {
  columns: Column<UserRow>[];
}

const createTableData = (translations: LangKeys, isAdmin: boolean): TableData => ({
  columns: createColumns(translations, isAdmin)
});

export const useTableData = (): TableData => {
  const translations = useTranslationsContext();
  const translationsSize = Object.keys(translations).length;
  const isAdmin = useSelector(isAvagAdmin);
  const memoizedTableData = useMemo<TableData>(() => createTableData(translations, isAdmin), [translationsSize]);
  return memoizedTableData;
};
