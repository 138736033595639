import React from 'react';
import {FieldProps, InputElement} from "components/SmartForm/types";
import Dropdown from "components/SmartForm/components/dropdown";

interface Props {
    onChange?: Function;
    onFocus?: Function;
    onBlur?: Function;
    onClick?: Function;
    options: FieldProps;
    setFieldValue?: (fieldName: string, value: any) => void;
    value?: any;
    hasMultipleClients?: boolean;
}

const ClientSwitch: React.FC<Props> = ({ onBlur, onChange, onClick, onFocus, options, value, setFieldValue, hasMultipleClients }) => {
    const handleClick = (event: React.MouseEvent<InputElement>) => {
        if (options.onClick) options.onClick(event);
        if (onClick) onClick(event);
    };

    const handleFocus = (event: React.FocusEvent<InputElement>) => {
        if (options.onFocus) options.onFocus(event);
        if (onFocus) onFocus(event);
    };

    const handleChange = (event: React.ChangeEvent<InputElement>) => {
        if (options.onChange) options.onChange(event);
        if (onChange) onChange(event);
    };

    const handleBlur = (event: React.FocusEvent<InputElement>) => {
        if (options.onBlur) options.onBlur(event);
        if (onBlur) onBlur(event);
    };

    const handleSetFieldValue = (fieldName: string, newValue: any) => {
        if (setFieldValue) setFieldValue(fieldName, newValue);
    };

    if (hasMultipleClients){
        return (
            <Dropdown
                onClick={ handleClick }
                onFocus={ handleFocus }
                onChange={ handleChange }
                onBlur={ handleBlur }
                options={ options }
                setFieldValue={ handleSetFieldValue }
                value={ value }/>
        );
    } else {
       return null;
    }
};

export default ClientSwitch;
