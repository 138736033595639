import { useTranslationsContext } from 'app/translations';
import { FieldProps } from 'components/SmartForm/types';
import { TEXT, TEXTAREA, DROPDOWN } from 'components/SmartForm/models';
import { createOptionsFromEnum } from 'app/utils/fields';
import { HelpRecipient } from '../types';

export const useFormFields = () => {
  const translations = useTranslationsContext();
  const fields: FieldProps[] = [
    {
      id: 'subject',
      label: translations.help_subjectLabel,
      type: TEXT,
      placeholder: translations.help_subjectPlaceholder
    },
    {
      id: 'recipient',
      label: translations.help_recipientLabel,
      type: DROPDOWN,
      placeholder: translations.help_recipientPlaceholder,
      options: createOptionsFromEnum<number>(HelpRecipient, translations)
    },
    {
      id: 'message',
      label: translations.help_messageLabel,
      type: TEXTAREA
    }
  ];
  return fields;
};
