import React from 'react';
import { useHistory } from 'react-router-dom';
import { HOME } from 'app/routes';
import Buttons from 'components/Buttons';

const Create: React.FC = () => {
  const history = useHistory();

  return (
    <Buttons
      options={ [
        {
          buttonType: 'primary',
          id: 'create',
          label: 'Create',
          onClick: () => {
            history.push(HOME.path);
          },
          type: 'button'
        }
      ] }
    />
  );
};

export default Create;
