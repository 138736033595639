import React from 'react';
import { Redirect as ReactRouterRedirect, useLocation } from 'react-router-dom';

interface Props {
  to: string;
}

const Redirect: React.FC<Props> = ({ to }) => {
  const { pathname } = useLocation();
  return <ReactRouterRedirect from={ pathname } to={ to } />;
};

export default Redirect;
