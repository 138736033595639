import React, { createContext, useState, PropsWithChildren } from 'react';

type ContextProps = [boolean, (newValue: boolean) => void];

export const UnsupportedBrowserContext = createContext<ContextProps>([false, () => {}]);

export const UnsupportedBrowserProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [dismissed, setDismissed] = useState(false);

  return (
    <UnsupportedBrowserContext.Provider value={ [dismissed, setDismissed] }>
      { children }
    </UnsupportedBrowserContext.Provider>
  );
};
