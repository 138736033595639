import { safeString, safeDate } from 'app/utils/safeTypes';
import { NotificationFromServer } from '../types/fromServer';
import { Notification, NOTIFICATION_TYPE, NOTIFICATION_STATUS } from '../types';

const createValidNotificationType = (type: string): NOTIFICATION_TYPE | undefined => {
  switch (type) {
    case 'Pickup':
      return NOTIFICATION_TYPE.PICKUP;

    case 'Delivery Slips':
      return NOTIFICATION_TYPE.DELIVERY;

    case 'Bills':
      return NOTIFICATION_TYPE.BILL;

    case 'User':
      return NOTIFICATION_TYPE.USER;

    default:
      return undefined;
  }
};

const createNotificationFromServer = ({ id,
  date,
  notification,
  status,
  entity_id,
  entitytype,
  docType, 
  versionNo,
  docNo }: NotificationFromServer): Notification | undefined => {
  if (
    id === undefined
    || notification === undefined
    || status === undefined
    || entity_id === undefined
    || entitytype === undefined
    || docType === undefined
    || versionNo === undefined
    || docNo === undefined
  ) {
    console.error('Error creating <Notification> model.');
    return undefined;
  }

  const entityType = createValidNotificationType(entitytype.toString());

  if (entityType === undefined) {
    console.error('Error creating <Notification> model. Invalid entity type');
    return undefined;
  }

  const singleDayPeriod = 24 * 60 * 60 * 1000;
  const yesterday = new Date().getTime() - singleDayPeriod;

  const isRecentNotification = safeDate(date).getTime() > yesterday;

  return {
    id: safeString(id),
    date: safeDate(date).getTime(),
    notification: safeString(notification),
    status: isRecentNotification ? NOTIFICATION_STATUS.NEW : NOTIFICATION_STATUS.OLD,
    entityId: safeString(entity_id),
    entityType,
    extras: {
      docType: safeString(docType), 
      versionNo: safeString(versionNo),
      docNo: safeString(docNo),
    }
  };
};

export const createNotificationListFromServer = (notifications: NotificationFromServer[]): Notification[] => {
  if (!Array.isArray(notifications)) {
    console.error('Error creating <Notification[]> model. Not array found.');
    return [] as Notification[];
  }

  return notifications.map(createNotificationFromServer).filter(n => !!n);
};
