import styled from 'styled-components';
import { SKELETON_BACKGROUND } from 'base/styles/skin';

interface BarProps {
  width?: string;
}

const Bar = styled.div`
  border-radius: 4px;
  background-color: ${ SKELETON_BACKGROUND };
  height: 8px;
  margin-bottom: 8px;
  width: ${ ({ width = '100%' }: BarProps) => width };
`;
Bar.displayName = 'Bar';

export default Bar;
