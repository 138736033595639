import React from 'react';
import { useTranslationsContext } from 'app/translations';

import Buttons from 'components/Buttons';
import SmartForm from 'components/SmartForm';
import AsideLayout from 'containers/Core/components/AsideLayout';
import { FieldProps } from 'components/SmartForm/types';
import { ButtonProps } from 'components/Touchable/types';
import { SeverityType, StatusResponse } from 'components/StatusMessage/types';
import { useIsLoading } from 'app/hooks';
import SectionTitle from 'components/SectionTitle';
import AsideSection from '../AsideSection';

import { useValidationSchema } from '../../data/validations';
import { ButtonsWrapper, Card } from './styles';
import { HelpForm } from '../../types';

interface Props {
  initialValues?: {
    [key in keyof HelpForm]?: string | number;
  };
  formFields: FieldProps[];
  formButtons: ButtonProps[];
  formEnabled?: boolean;
  handleSubmit: (fields: FieldProps[]) => void;
  error?: boolean;
  requestId?: string;
  formSubmitted?: boolean;
}

const View: React.FC<Props> = ({ initialValues, formFields, formButtons, handleSubmit, requestId, formSubmitted }) => {
  const { help_title, help_confirmationTitle, help_confirmationDescription } = useTranslationsContext();
  const validations = useValidationSchema();
  const isLoading = useIsLoading(['help-request']);

  const statusResponse: StatusResponse = {
    severity: requestId ? SeverityType.SUCCESS : SeverityType.ERROR,
    title: requestId ? `${ help_confirmationTitle } ${ ': ' } ${ requestId }` : '',
    description: requestId ? help_confirmationDescription : '',
    showIcon: !requestId
  };

  return (
    <AsideLayout aside={ <AsideSection /> }>
      <SectionTitle>{ help_title }</SectionTitle>
      <SmartForm
        form={ 'help' }
        fields={ formFields }
        buttons={ formButtons }
        handleSubmit={ handleSubmit }
        validationSchema={ validations }
        initialValues={ initialValues }
        isFetching={ isLoading || !!requestId }
        statusResponse={ formSubmitted && statusResponse }
        validateOnChange={ true }
      >
        { ({ controlledFields, Field, controlledButtons }) => (
          <Card
            showStatusMessage={ formSubmitted }
            footer={
              !requestId && (
                <ButtonsWrapper>
                  <Buttons options={ controlledButtons } />
                </ButtonsWrapper>
              )
            }
          >
            { controlledFields.map(f => (
              <Field key={ f.id } options={ f } />
            )) }
          </Card>
        ) }
      </SmartForm>
    </AsideLayout>
  );
};

export default View;
