import styled from 'styled-components';
import Card from 'components/Card';
import { TABLET } from 'base/styles/media-queries';

export const Legend = styled.p`
  font-size: 14px;
  padding: 10px 0;
`;
Legend.displayName = 'Legend';

interface Props {
  extraBottomSpace?: boolean;
}

export const StyledCard = styled(Card)`
  margin-bottom: 30px;
  padding-bottom: ${ ({ extraBottomSpace }: Props) => extraBottomSpace && '20px' };
`;

export const UserDate = styled.span`
  padding-left: 8px;
`;
UserDate.displayName = 'UserDate';

export const FieldsWrapper = styled.div`
  padding-bottom: 10px;

  ${ TABLET } {
    margin: 0 -8px;
    width: calc(100% + 16px);

    & > span {
      display: inline-block !important;
      padding: 0 8px;
      width: 50% !important;
    }
  }
`;
FieldsWrapper.displayName = 'FieldsWrapper';

export const ButtonsWrapper = styled.div`
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > button {
      margin: 4px;
    }

    ${ TABLET } {
      display: block;
    }
  }
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';
