import React from 'react';
// eslint-disable-next-line no-restricted-imports
import MosaicItem from './do-not-export/MosaicItem';
import { Container } from './styles';

interface ChildrenProps {
  MosaicItem: typeof MosaicItem;
}
interface Props {
  children: (props: ChildrenProps) => React.ReactNode;
}
const MosaicList: React.FC<Props> = ({ children }) => <Container>{ children({ MosaicItem }) }</Container>;

export default MosaicList;
