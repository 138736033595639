import React from 'react';
import Buttons from 'components/Buttons';
import { useHistory } from 'react-router-dom';
import { TEMPLATE_LIST } from 'app/routes';
import { useTranslationsContext } from 'app/translations';

const TemplateButton: React.FC = () => {
  const translations = useTranslationsContext();
  const history = useHistory();

  return (
    <div style={ { position: 'relative', left: '15px' } }>
      <Buttons
        options={ [
          {
            buttonType: 'primary',
            id: 'create',
            label: translations.template_table_title,
            onClick: () => {
              history.push(TEMPLATE_LIST.path);
            },
            type: 'button'
          }
        ] }
      />
    </div>
  );
};

export default TemplateButton;
