import React from 'react';
import { useTranslationsContext } from 'app/translations';
import List from 'components/List';
import Modal from 'components/Modal';
import { ButtonProps } from 'components/Touchable/types';
import { SupportedBrowserInfo } from '../../types';
import { ModalFooter, ModalBody, BrowserLink, Text } from './styles';

interface Props {
  handleClose: () => void;
  supportedBrowsers: SupportedBrowserInfo[];
}

const SupportedBrowsersModal: React.FC<Props> = ({ handleClose, supportedBrowsers }) => {
  const { unsupportedBrowser_moreInfo, 
    unsupportedBrowser_buttonConfirm,
    unsupportedBrowser_higher } = useTranslationsContext();

  const dismissModal: ButtonProps = {
    buttonType: 'primary',
    id: 'dismiss-browsers-modal',
    label: unsupportedBrowser_buttonConfirm,
    onClick: handleClose,
    type: 'button'
  };
  
  return (
    <Modal footer={ <ModalFooter options={ [dismissModal] } /> } onClose={ handleClose } size="sm">
      <ModalBody>
        <Text>
          { unsupportedBrowser_moreInfo }
        </Text>

        <List>
          { ({ ListItem }) => (
            <>
              { supportedBrowsers
                .filter(b => b.url)
                .map(browser => (
                  <ListItem key={ browser.key }>
                    <BrowserLink href={ browser.url } target="_blank" rel="noopener noreferrer">
                      { browser.name } (v{ browser.version } { unsupportedBrowser_higher })
                    </BrowserLink>
                  </ListItem>
                )) }
            </>
          ) }
        </List>
      </ModalBody>
    </Modal>
  );
};

export default SupportedBrowsersModal;
