import produce from 'immer';
import { createReducer } from 'base';
import { CoreState, appInitialState } from '../models';
import { ActionTypes, AppActionType } from '../actionTypes';

const setLanguage = (state: CoreState, action: AppActionType): CoreState =>
  produce(state, draft => {
    const { lang: langFromServer } = action.payload as CoreState;
    draft.lang = langFromServer;
  });

const actionHandlers = {
  [ActionTypes.SET_LANGUAGE]: setLanguage
};

export default createReducer(appInitialState, actionHandlers);
