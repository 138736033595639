import { Quota } from '../types';
import { QuotasFromServer } from '../types/fromServer';

export const QUOTAS_LIST: Quota[] = [
  {
    id: '1234',
    percentage: 36,
    label: 'VeVA 190207 - EGI 10768/4’000 To',
    date: 1580893149831,
    total: 10000
  },
  {
    id: '12345',
    percentage: 66,
    label: 'VeVA 190207 - EGI 10768/4’000 To',
    date: 1580893149831,
    total: 10000
  },
  {
    id: '12346',
    percentage: 85,
    label: 'VeVA 190207 - EGI 10768/4’000 To',
    date: 1580893149831,
    total: 10000
  },
  {
    id: '12347',
    percentage: 46,
    label: 'VeVA 190207 - EGI 10768/4’000 To',
    date: 1580893149831,
    total: 10000
  },
  {
    id: '12348',
    percentage: 46,
    label: 'VeVA 190207 - EGI 10768/4’000 To',
    date: 1580893149831,
    total: 10000
  }
];

export const QUOTAS_FROM_SERVER: QuotasFromServer[] = [
  {
    SellToCustomerNo: '1007715',
    OrderNo: 'VRA0001452',
    ValidFrom: ['2019-08-14'],
    ValidTo: ['2024-08-31'],
    BlanketOrderLine: [
      {
        LineType: ['2'],
        LineNo: '11-111-027',
        Description: 'VeVA 150110 Verpackungen',
        OrigQuantity: ['50000'],
        RemainingQuantity: ['50000'],
        GenProPostGroup: 'STANDARD'
      },
      {
        LineType: ['2'],
        LineNo: '91-919-074',
        Description: 'VeVA Begleitschein',
        OrigQuantity: ['1000'],
        RemainingQuantity: ['1000'],
        GenProPostGroup: 'STANDARD'
      }
    ]
  },
  {
    SellToCustomerNo: '1007715',
    OrderNo: 'VRA0001460',
    ValidFrom: ['2019-09-05'],
    ValidTo: ['2024-08-31'],
    BlanketOrderLine: [
      {
        LineType: ['2'],
        LineNo: '11-111-036',
        Description: 'VeVA 180102 Medizinalabfälle',
        OrigQuantity: ['20000'],
        RemainingQuantity: ['19810'],
        GenProPostGroup: 'STANDARD'
      }
    ]
  },
  {
    SellToCustomerNo: '1007715',
    OrderNo: 'VRA0001463',
    ValidFrom: ['2019-09-17'],
    ValidTo: ['2024-09-30'],
    BlanketOrderLine: [
      {
        LineType: ['2'],
        LineNo: '11-111-027',
        Description: 'VeVA 150110 Verpackungen',
        OrigQuantity: ['25000'],
        RemainingQuantity: ['25000'],
        GenProPostGroup: 'STANDARD'
      }
    ]
  }
];
