import { Action } from 'app/types';

export enum ActionTypes {
  GET_RECENT_CONTAINER_LIST_REQUEST = 'GET_RECENT_CONTAINER_LIST_REQUEST',
  GET_RECENT_CONTAINER_LIST_SUCCESS = 'GET_RECENT_CONTAINER_LIST_SUCCESS',
  GET_RECENT_CONTAINER_LIST_ERROR = 'GET_RECENT_CONTAINER_LIST_ERROR',

  GET_CONTAINER_LIST_REQUEST = 'GET_CONTAINER_LIST_REQUEST',
  GET_CONTAINER_LIST_SUCCESS = 'GET_CONTAINER_LIST_SUCCESS',
  GET_CONTAINER_LIST_ERROR = 'GET_CONTAINER_LIST_ERROR',

  GET_MATERIAL_LIST_REQUEST = 'GET_MATERIAL_LIST_REQUEST',
  GET_MATERIAL_LIST_SUCCESS = 'GET_MATERIAL_LIST_SUCCESS',
  GET_MATERIAL_LIST_ERROR = 'GET_MATERIAL_LIST_ERROR',

  GET_PICKUP_LIST_REQUEST = 'GET_PICKUP_LIST_REQUEST',
  GET_PICKUP_LIST_SUCCESS = 'GET_PICKUP_LIST_SUCCESS',
  GET_PICKUP_LIST_ERROR = 'GET_PICKUP_LIST_ERROR',

  GET_PICKUP_REQUEST = 'GET_PICKUP_REQUEST',
  GET_PICKUP_SUCCESS = 'GET_PICKUP_SUCCESS',
  GET_PICKUP_ERROR = 'GET_PICKUP_ERROR',

  PRINT_PICKUP_REQUEST = 'PRINT_PICKUP_REQUEST',
  PRINT_PICKUP_SUCCESS = 'PRINT_PICKUP_SUCCESS',
  PRINT_PICKUP_ERROR = 'PRINT_PICKUP_ERROR',

  CREATE_PICKUP_REQUEST = 'CREATE_PICKUP_REQUEST',
  CREATE_PICKUP_SUCCESS = 'CREATE_PICKUP_SUCCESS',
  CREATE_PICKUP_ERROR = 'CREATE_PICKUP_ERROR',
  SAVE_NEW_TEMPLATE = 'SAVE_NEW_TEMPLATE',

  UPDATE_PICKUP_REQUEST = 'UPDATE_PICKUP_REQUEST',
  UPDATE_PICKUP_SUCCESS = 'UPDATE_PICKUP_SUCCESS',
  UPDATE_PICKUP_ERROR = 'UPDATE_PICKUP_ERROR',

  UPDATE_TEMPLATE_REQUEST = 'UPDATE_TEMPLATE_REQUEST',
  UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS',
  UPDATE_TEMPLATE_ERROR = 'UPDATE_TEMPLATE_ERROR',

  PROCESS_PICKUP_REQUEST = 'PROCESS_PICKUP_REQUEST',
  PROCESS_PICKUP_SUCCESS = 'PROCESS_PICKUP_SUCCESS',
  PROCESS_PICKUP_ERROR = 'PROCESS_PICKUP_ERROR',

  DELETE_PICKUP_REQUEST = 'DELETE_PICKUP_REQUEST',
  DELETE_PICKUP_SUCCESS = 'DELETE_PICKUP_SUCCESS',
  DELETE_PICKUP_ERROR = 'DELETE_PICKUP_ERROR',

  RESEND_PICKUP_REQUEST = 'RESEND_PICKUP_REQUEST',
  RESEND_PICKUP_SUCCESS = 'RESEND_PICKUP_SUCCESS',
  RESEND_PICKUP_ERROR = 'RESEND_PICKUP_ERROR',

  RESET_PICKUP_LIST = 'RESET_PICKUP_LIST'
}

type GetRecentContainersRequest = Action<ActionTypes.GET_RECENT_CONTAINER_LIST_REQUEST>;
type GetRecentContainersSuccess = Action<ActionTypes.GET_RECENT_CONTAINER_LIST_SUCCESS>;
type GetRecentContainersError = Action<ActionTypes.GET_RECENT_CONTAINER_LIST_ERROR>;

type GetContainersRequest = Action<ActionTypes.GET_CONTAINER_LIST_REQUEST>;
type GetContainersSuccess = Action<ActionTypes.GET_CONTAINER_LIST_SUCCESS>;
type GetContainersError = Action<ActionTypes.GET_CONTAINER_LIST_ERROR>;

type GetMaterialListRequest = Action<ActionTypes.GET_MATERIAL_LIST_REQUEST>;
type GetMaterialListSuccess = Action<ActionTypes.GET_MATERIAL_LIST_SUCCESS>;
type GetMaterialListError = Action<ActionTypes.GET_MATERIAL_LIST_ERROR>;

type GetPickupListRequest = Action<ActionTypes.GET_PICKUP_LIST_REQUEST>;
type GetPickupListSuccess = Action<ActionTypes.GET_PICKUP_LIST_SUCCESS>;
type GetPickupListError = Action<ActionTypes.GET_PICKUP_LIST_ERROR>;

type GetPickupRequest = Action<ActionTypes.GET_PICKUP_REQUEST>;
type GetPickupSuccess = Action<ActionTypes.GET_PICKUP_SUCCESS>;
type GetPickupError = Action<ActionTypes.GET_PICKUP_ERROR>;

type PrintPickupRequest = Action<ActionTypes.PRINT_PICKUP_REQUEST>;
type PrintPickupSuccess = Action<ActionTypes.PRINT_PICKUP_SUCCESS>;
type PrintPickupError = Action<ActionTypes.PRINT_PICKUP_ERROR>;

type CreatePickupRequest = Action<ActionTypes.CREATE_PICKUP_REQUEST>;
type CreatePickupSuccess = Action<ActionTypes.CREATE_PICKUP_SUCCESS>;
type CreatePickupError = Action<ActionTypes.CREATE_PICKUP_ERROR>;
type SaveNewTemplate = Action<ActionTypes.SAVE_NEW_TEMPLATE>;

type UpdatePickupRequest = Action<ActionTypes.UPDATE_PICKUP_REQUEST>;
type UpdatePickupSuccess = Action<ActionTypes.UPDATE_PICKUP_SUCCESS>;
type UpdatePickupError = Action<ActionTypes.UPDATE_PICKUP_ERROR>;

type ProcessPickupRequest = Action<ActionTypes.PROCESS_PICKUP_REQUEST>;
type ProcessPickupSuccess = Action<ActionTypes.PROCESS_PICKUP_SUCCESS>;
type ProcessPickupError = Action<ActionTypes.PROCESS_PICKUP_ERROR>;

type DeletePickupRequest = Action<ActionTypes.DELETE_PICKUP_REQUEST>;
type DeletePickupSuccess = Action<ActionTypes.DELETE_PICKUP_SUCCESS>;
type DeletePickupError = Action<ActionTypes.DELETE_PICKUP_ERROR>;

type ResendPickupRequest = Action<ActionTypes.RESEND_PICKUP_REQUEST>;
type ResendPickupSuccess = Action<ActionTypes.RESEND_PICKUP_SUCCESS>;
type ResendPickupError = Action<ActionTypes.RESEND_PICKUP_ERROR>;

type UpdateTemplateRequest = Action<ActionTypes.UPDATE_TEMPLATE_REQUEST>;
type UpdateTemplateSuccess = Action<ActionTypes.UPDATE_TEMPLATE_SUCCESS>;
type UpdateTemplateError = Action<ActionTypes.UPDATE_TEMPLATE_ERROR>;

type ResetPickupRequestList = Action<ActionTypes.RESET_PICKUP_LIST>;

type GetRecentContainersActionType = GetRecentContainersRequest | GetRecentContainersSuccess | GetRecentContainersError;
type GetContainersActionType = GetContainersRequest | GetContainersSuccess | GetContainersError;
type GetMaterialListActionType = GetMaterialListRequest | GetMaterialListSuccess | GetMaterialListError;
type GetPickupListActionType = GetPickupListRequest | GetPickupListSuccess | GetPickupListError;
type GetPickupActionType = GetPickupRequest | GetPickupSuccess | GetPickupError;
type PrintPickupActionType = PrintPickupRequest | PrintPickupSuccess | PrintPickupError;
type CreatePickupActionType = CreatePickupRequest | CreatePickupSuccess | CreatePickupError | SaveNewTemplate;
type UpdatePickupActionType = UpdatePickupRequest | UpdatePickupSuccess | UpdatePickupError;
type ProcessPickupActionType = ProcessPickupRequest | ProcessPickupSuccess | ProcessPickupError;
type DeletePickupActionType = DeletePickupRequest | DeletePickupSuccess | DeletePickupError;
type ResendPickupActionType = ResendPickupRequest | ResendPickupSuccess | ResendPickupError;
type UpdateTemplateType = UpdateTemplateRequest | UpdateTemplateSuccess | UpdateTemplateError;

export type PickupRequestActionType =
  | GetRecentContainersActionType
  | GetContainersActionType
  | GetMaterialListActionType
  | GetPickupListActionType
  | GetPickupActionType
  | PrintPickupActionType
  | CreatePickupActionType
  | UpdatePickupActionType
  | ProcessPickupActionType
  | DeletePickupActionType
  | ResendPickupActionType
  | ResetPickupRequestList
  | UpdateTemplateType;
