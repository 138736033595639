import { getOffsetPagination } from 'app/utils/pagination';
import { ActionTypes, CompaniesActionType } from '../actionTypes';
import * as api from '../api';
import {CompanyChangeRequest, CompanyPermissionRequest} from '../types';

export const getCompaniesSimple = (): CompaniesActionType => ({
  type: ActionTypes.GET_COMPANIES_SIMPLE_REQUEST,
  request: api.getCompaniesSimple(),
  requestName: 'get-companies-simple'
});

export const getCompanies = (page: number, pageSize: number, filterValue = '0'): CompaniesActionType => ({
  type: ActionTypes.GET_COMPANIES_REQUEST,
  request: api.getCompanies(getOffsetPagination(page, pageSize), pageSize, filterValue),
  requestName: 'get-companies'
});

export const getCompany = (id: string): CompaniesActionType => ({
  type: ActionTypes.GET_COMPANY_REQUEST,
  request: api.getCompany(id),
  requestName: 'get-company'
});

export const companiesPermissionsRequest = (request: CompanyPermissionRequest): CompaniesActionType => ({
  type: ActionTypes.PATCH_COMPANY_PERMISSIONS_REQUEST,
  request: api.companyPermissionsRequest(request),
  requestName: 'company-permissions-request'
});

export const resetCompanies = (): CompaniesActionType => ({
  type: ActionTypes.RESET_COMPANIES
});

export const getCompanyList = (id: string): CompaniesActionType => ({
  type: ActionTypes.GET_COMPANYLIST_REQUEST,
  request: api.getCompanyList(id),
  requestName: 'get-companylist'
});

export const changeCompany = (request: CompanyChangeRequest): CompaniesActionType => ({
  type: ActionTypes.POST_CHANGECOMPANY_REQUEST,
  request: api.changeCompanyForUser(request),
  requestName: 'post-changecompany'
});