import React from 'react';
import { Container, Icon } from './styles';

const lockOn = require('assets/icons/lockRedOn.svg');


const LockIcon: React.FC = () => (
  <Container>
    <Icon src={ lockOn } />
  </Container>
);

export default LockIcon;
