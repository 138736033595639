import React from 'react';

import { SearchWrapper, InputSearch, SearchButton, IconSearch } from 'components/Searcher/styles';
import { DatePickerInputProps } from '../../types';

const calendarIcon = require('assets/icons/calendar.svg');

const DatePickerInput = (props: DatePickerInputProps, ref: React.RefObject<HTMLInputElement>) => (
  <SearchWrapper isDisabled={ props.disabled } isError={ props.className === 'error' }>
    <InputSearch
      id={ props.id }
      name={ props.id }
      value={ props.value || '' }
      disabled={ props.disabled }
      onClick={ props.onClick }
      onChange={ props.onChange }
      onBlur={ props.onBlur }
      onFocus={ props.onFocus }
      placeholder={ props.placeholder }
      required={ props.required }
      ref={ ref }
    />
    { !props.disabled && (
      <SearchButton type="button" onClick={ props.onClick }>
        <IconSearch src={ calendarIcon } />
      </SearchButton>
    ) }
  </SearchWrapper>
);

export default React.forwardRef(DatePickerInput);
