import produce from 'immer';
import { createReducer, isMockingApi } from 'base';
import { ActionHandler } from 'base/types';
import { AVAG_ADMIN, CLIENT_SUPER_USER, CLIENT_OPERATOR } from 'containers/Login/mocks';
import { ActionTypes, UserActionType } from '../actionTypes';
import { USER_LIST } from '../mocks';
import { userInitialState, createUserFromLoggedUser } from '../models';
import { UsersState, User } from '../types';

const getUserListSuccess = (state: UsersState, action: UserActionType) =>
  produce(state, draft => {
    const { collection, pagination } = action.payload as UsersState;
    draft.collection = collection;
    draft.pagination = pagination;
  });

const getUserListError = (state: UsersState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      const Admin = createUserFromLoggedUser(AVAG_ADMIN);
      const SuperUser = createUserFromLoggedUser(CLIENT_SUPER_USER);
      const Operator = createUserFromLoggedUser(CLIENT_OPERATOR);
      draft.collection = [Admin, SuperUser, Operator, ...USER_LIST.slice(0, draft.pagination.pageSize)];
      draft.pagination.totalCount = USER_LIST.length;
    }
  });

const getUserSuccess = (state: UsersState, action: UserActionType) =>
  produce(state, draft => {
    const user = action.payload as User;
    let hasBeenModified = false;
    draft.collection = state.collection.map(u => {
      if (u.id === user?.id) {
        hasBeenModified = true;
        return {
          ...u,
          ...user
        };
      }

      return u;
    });

    if (!hasBeenModified) {
      draft.collection.push(user);
    }
  });

const getUserError = (state: UsersState) =>
  produce(state, draft => {
    if (isMockingApi() && !state.collection.length) {
      const user = USER_LIST[0];
      draft.collection.push(user);
    }
  });

const updateUserSuccess = (state: UsersState, action: UserActionType) =>
  produce(state, draft => {
    const user = action.payload as User;
    draft.collection = draft.collection.map(u => {
      if (u.id === user?.id) {
        return {
          ...u,
          ...user
        };
      }

      return u;
    });
  });

const deleteUserSuccess = (state: UsersState, action: UserActionType) =>
  produce(state, draft => {
    const user = action.payload as User;
    draft.collection = draft.collection.filter(u => u.id === user?.id);
  });

const resetUserList = (state: UsersState) =>
  produce(state, draft => {
    draft.collection = [];
  });

const actionHandlers: ActionHandler<UsersState> = {
  [ActionTypes.GET_USER_LIST_SUCCESS]: getUserListSuccess,
  [ActionTypes.GET_USER_LIST_ERROR]: getUserListError,
  [ActionTypes.GET_USER_SUCCESS]: getUserSuccess,
  [ActionTypes.GET_USER_ERROR]: getUserError,
  [ActionTypes.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [ActionTypes.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [ActionTypes.RESET_USER_LIST]: resetUserList
};

export default createReducer(userInitialState, actionHandlers);
