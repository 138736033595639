import { ADD, REMOVE } from './constants';
import { AddAction, RemoveAction } from './types';
import { MakeQuerablePromise } from './utils';

export const addRequest = <T>(request: Promise<T>, requestName: string): AddAction => ({
  type: ADD,
  payload: {
    request: MakeQuerablePromise<T>(request),
    requestName
  }
});

export const removeRequest = (requestName: string): RemoveAction => ({
  type: REMOVE,
  payload: {
    requestName
  }
});
