import styled, { css } from 'styled-components';
import { TEXT_COLOR } from 'base/styles/skin';

const TextStyles = css`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${ TEXT_COLOR };
`;

export const Wrapper = styled.div`
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.span`
  ${ TextStyles }
`;
Text.displayName = 'Text';
