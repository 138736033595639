import React from 'react';
import { useTranslationsContext } from 'app/translations';
import Content from 'components/Touchable';
import FileItem from './components/FileItem';
import { InputFile, Placeholder, Input, InputButton, Icon, Files } from './styles';
import { FieldProps } from '../../types';
import { Wrapper, Description, Label } from '../styles';
import { SmartFile } from './types';

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (event: React.MouseEvent<HTMLInputElement | HTMLButtonElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  setFieldValue: (field: string, value: SmartFile[]) => void;
  options: FieldProps;
  value?: SmartFile[];
}

const searchIcon = require('assets/icons/lens.svg');

const FileUploader: React.FC<Props> = ({ options, value = [], setFieldValue }) => {
  const translations = useTranslationsContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files);
    setFieldValue(options.id, [...value, ...newFiles]);
  };

  const handleRemove = (index: number) => () => {
    if (!options.isDisabled) {
      const remaminingFiles = value.filter((_, i) => i !== index);
      setFieldValue(options.id, remaminingFiles);
    }
  };

  return (
    <Wrapper>
      { !options.isDisabled && (
        <Description>
          <Label htmlFor={ options.id }>
            <Content options={ options } />
          </Label>
        </Description>
      ) }
      <InputFile htmlFor={ options.id } isDisabled={ options.isDisabled }>
        { !options.isDisabled && <Placeholder>{ translations.uploadFile_placeholder }</Placeholder> }
        <Input
          id={ options.id }
          name={ options.id }
          type={ options.type }
          multiple={ true }
          disabled={ options.isDisabled }
          maxLength={ options.maxLength }
          minLength={ options.minLength }
          onChange={ handleChange }
          value={ options.model || [] }
          placeholder={ translations.uploadFile_placeholder }
          required={ options.isRequired }
        />
        { !options.isDisabled && (
          <InputButton>
            <Icon src={ searchIcon } />
          </InputButton>
        ) }
      </InputFile>
      <Files>
        { value.map((file, index) => (
          <FileItem key={ index } file={ file } isDisabled={ options.isDisabled } onRemove={ handleRemove(index) } />
        )) }
      </Files>
    </Wrapper>
  );
};

export default FileUploader;
