import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../actions';
import { DataFilterValues } from '../types';

export const useStatisticsRequest = ({ startDate, endDate }: DataFilterValues, isSecondary?: boolean) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSecondary) {
      dispatch(actions.resetStatisticsComparision());
      dispatch(actions.getStatisticsComparision(new Date(startDate), new Date(endDate)));
    } else {
      dispatch(actions.resetStatistics());
      dispatch(actions.getStatistics(new Date(startDate), new Date(endDate)));
    }
  }, [startDate, endDate]);
};
