/* eslint-disable max-len */
import React from 'react';
import { useDispatch } from 'react-redux';
import FooterTable from 'app/containers/PickupRequest/containers/Templates/containers/TemplateHome/components/TableFooter';
import ItemRow from 'app/containers/PickupRequest/containers/Templates/containers/TemplateHome/components/ItemRow';
import List from 'components/List';
import Card from 'app/components/Card';
// import { useIsLoading } from 'app/hooks';
import { useHistory } from 'react-router-dom';
import * as templateHandlers from 'containers/PickupRequest/containers/Templates/handlers';
import { TemplateRequest } from '../../types';
import TemplateModal from './components/TemplateModal/TemplateModal';

interface Props {
  collection: TemplateRequest[];
}

const TemplateHomeComp: React.FC<Props> = ({ collection }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState({});
  const footer = <FooterTable />;
  const handleSubmitSuccessModal = (pickupWishDate: string) => {
    setShowModal(false);
    // eslint-disable-next-line no-param-reassign
    return templateHandlers.placeOrderFromHomeTemplate({
      dispatch,
      history,
      pickupFromHomeTemplate: { ...selectedTemplate, templatePlaceOrderDate: pickupWishDate }
    });
  };
  return (
    <>
      <Card indentation={ false } footer={ footer }>

        <List>
          { ({ ListItem }) =>
            collection.map(item => (

              <ListItem
                key={ item.templateId }
                onClick={ () => {
                  setSelectedTemplate(item);
                  setShowModal(!showModal);
                } }
              >
                <ItemRow item={ item } />
              </ListItem>
            ))
          }
        </List>

      </Card>
      {
        showModal && <TemplateModal onConfirm={ handleSubmitSuccessModal } selectedTemplate={ selectedTemplate } onClose={ () => setShowModal(!showModal) } />
      }
    </>
  );
};

export default TemplateHomeComp;
