export enum SeverityType {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

export interface SeverityData {
  icon: string;
  title: string;
  description?: string;
}

export type SeverityValues = {
  [Key in keyof typeof SeverityType]: SeverityData;
};

export interface StatusResponse {
  severity: SeverityType;
  title?: string;
  description?: string | React.ReactNode;
  showIcon?: boolean;
}
