import { useTranslationsContext } from 'app/translations';
import { DATE, DROPDOWN } from 'components/SmartForm/models';
import { FieldProps } from 'components/SmartForm/types';
import { createOptionsFromEnum } from 'app/utils/fields';
import { ExportFieldName, ExportDataFormats } from '../types';

export const useExportBalanceFields = () => {
  const translations = useTranslationsContext();
  const fields: FieldProps[] = [
    {
      id: ExportFieldName.startDate,
      label: translations.billsList_export_dateStartLabel,
      type: DATE,
      placeholder: translations.billsList_export_dateStartLabel,
      minLength: new Date(0).getTime(),
      withPortal: false
    },
    {
      id: ExportFieldName.endDate,
      label: translations.billsList_export_dateEndLabel,
      type: DATE,
      placeholder: translations.billsList_export_dateEndLabel,
      withPortal: false
    }
  ];
  return fields;
};

export const useExportDataFields = () => {
  const translations = useTranslationsContext();
  const fields: FieldProps[] = [
    ...useExportBalanceFields(),
    {
      id: ExportFieldName.format,
      label: translations.billsList_exportDataFormatLabel,
      type: DROPDOWN,
      placeholder: translations.billsList_exportDataFormatPlaceholder,
      options: createOptionsFromEnum<string>(ExportDataFormats, translations)
    }
  ];
  return fields;
};
