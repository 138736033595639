type Order = 'asc' | 'desc';

interface Collection {
  [index: string]: any;
}

export const sortBy = <T extends Collection>(key: keyof T, order: Order = 'desc') => (a: T, b: T) => {
  if (`${ a[key] }`.toLowerCase() < `${ b[key] }`.toLowerCase()) return order === 'asc' ? -1 : 1;
  if (`${ a[key] }`.toLowerCase() > `${ b[key] }`.toLowerCase()) return order === 'asc' ? 1 : -1;
  return 0;
};
