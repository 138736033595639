import { FormikValues } from 'formik';
import React from 'react';
import { ObjectSchema } from 'yup';
import { useTranslationsContext } from 'app/translations';
import { createOptionsFromEntity } from 'app/utils/fields';
import SectionTitle from 'components/SectionTitle';
import SmartForm from 'components/SmartForm';
import { CHECKBOX, TEXT, DROPDOWN, NUMBER } from 'components/SmartForm/models';
import { FieldProps } from 'components/SmartForm/types';
import { ContainerAddon, AddonType, Container } from 'containers/PickupRequest/types';
import { AddonsWrapper } from '../../styles';
import { setDynamicValidation } from './validation';

const vals: FormikValues = {};

const mapAddonToField = ({ addonId, name, defaultValue, type, options }: ContainerAddon): FieldProps => {
  let fieldType: FieldProps['type'];

  switch (type) {
    case AddonType.bool:
      fieldType = CHECKBOX;
      vals[addonId] = defaultValue === 'true';
      break;

    case AddonType.dropdown:
      fieldType = DROPDOWN;
      vals[addonId] = defaultValue;
      break;

    case AddonType.number:
      fieldType = NUMBER;
      vals[addonId] = Number(defaultValue);
      break;

    default:
      fieldType = TEXT;
      vals[addonId] = defaultValue;
  }

  return {
    id: addonId,
    label: name,
    options: fieldType === DROPDOWN ? createOptionsFromEntity(options) : undefined,
    type: fieldType
  };
};

const getInitialValues = () => vals;

interface Props {
  container?: Container;
  handleSubmit: () => void;
  handleUpdateAddons: (values: FormikValues) => void;
}

const SelectAddon: React.FC<Props> = ({ container, handleSubmit, handleUpdateAddons }) => {
  const [render, setRender] = React.useState(false);
  const [fields, setFields] = React.useState<FieldProps[]>([]);
  const [validation, setValidation] = React.useState<ObjectSchema>();
  const [initialValues, setInitialValues] = React.useState<FormikValues>({});
  const translations = useTranslationsContext();

  const { addons = [], name = '' } = container || {};

  React.useEffect(() => {
    // Reset initialValues
    Object.keys(initialValues).forEach(key => {
      initialValues[key] = undefined;
      delete initialValues[key];
    });

    if (render) {
      setRender(false);
    }

    // Transform addons into fields
    setFields(addons.map(mapAddonToField));

    // Set initial values
    setInitialValues(getInitialValues());
  }, [addons]);

  React.useEffect(() => {
    if (fields.length) {
      setValidation(setDynamicValidation(fields, translations));
      setRender(true);
    }
  }, [fields]);

  return (
    <AddonsWrapper>
      <SectionTitle>{ translations.containers_selectAddons.replace(':containerName:', name) }</SectionTitle>

      { render && (
        <SmartForm
          reinitialize={ true }
          form="container-addons"
          buttons={ [] }
          fields={ fields }
          validationSchema={ validation }
          handleSubmit={ () => {
            handleSubmit();
          } }
          initialValues={ initialValues }
          submitRequireDirty={ false }
        >
          { ({ controlledFields, Field, values }) => {
            handleUpdateAddons(values);

            return (
              <>
                { controlledFields.map(field => (
                  <Field key={ field.id } options={ field } />
                )) }
              </>
            );
          } }
        </SmartForm>
      ) }
    </AddonsWrapper>
  );
};

export default SelectAddon;
