/* eslint-disable max-len */
import * as Yup from 'yup';
import { ROLE } from 'app/constants/roles';
import * as validations from 'app/constants/validations';
import { LangKeys } from 'app/translations';
import { formatDate } from 'app/utils/formatters';
import { sevendays } from 'app/utils/date';
import { ConstraintGroup } from 'containers/PickupRequest/types';
import { FieldName, MaterialFieldName } from 'app/containers/PickupRequest/containers/PickupRequestDetails/types';

export const createValidationSchema = (translations: LangKeys, constraintGroup: ConstraintGroup,): Yup.ObjectSchema => {
  const { role, status } = constraintGroup;
  const TEXT_MAX_LENGTH = 100;
  const TEXT_MIN_LENGTH = 1;
  const TEXT_MAX_LENGTH_PHONE = 30;
  const TEXT_MAX_LENGTH_bookingReference = 80;
  const TEXT_MAX_LENGTH_additionalComments = 250;
  const avagAdminValidations = {
    [MaterialFieldName.destination]: validations.string(translations).required(translations.validations_requiredField),
    [MaterialFieldName.date]: Yup.number()
      .required(translations.validations_requiredField)
      .min(sevendays.getTime(), translations.validation_laterThan?.replace(':min:', formatDate(sevendays))),
    [MaterialFieldName.timeSlot]: validations.timePeriod,
  };

  const logisticValidations = (status && role === ROLE.AVAG_ADMIN)
    ? { logistic: Yup.object().shape({
      location: validations.string(translations).required(translations.validations_requiredField),
      timePeriod: Yup.number().notRequired()
    }) }
    : { logistic: Yup.object().shape({
      location: validations.string(translations).min(TEXT_MIN_LENGTH, translations.validations_minLength.replace(':min:', TEXT_MIN_LENGTH.toString())).max(TEXT_MAX_LENGTH, translations.validation_maxLength_location.replace(':max:', TEXT_MAX_LENGTH.toString())).required(translations.validations_requiredField),
      telephone: validations.string(translations)
        .min(TEXT_MIN_LENGTH, translations.validations_minLength.replace(':min:', TEXT_MIN_LENGTH.toString()))
        .max(TEXT_MAX_LENGTH_PHONE, translations.validation_maxLength_telephone.replace(':max:', TEXT_MAX_LENGTH_PHONE.toString()))
        .required(translations.validations_requiredField),
        // location: validations.string(translations).required(translations.validations_requiredField),
      timePeriod: validations.timePeriod
    }) };
    
  const extraValidations = role === ROLE.AVAG_ADMIN ? avagAdminValidations : {};

  return Yup.object().shape({
    ...logisticValidations,
    [FieldName.materials]: Yup.array().of(
      Yup.object().shape({
        [MaterialFieldName.containerId]: validations
          .string(translations)
          .required(translations.validations_requiredField),
        [MaterialFieldName.materialId]: validations.string(translations).when([MaterialFieldName.canPickup], {
          is: true,
          then: () => validations.string(translations).required(translations.validations_requiredField)
        }),
        [MaterialFieldName.pickupContainers]: validations.containerNumber
          .when([MaterialFieldName.materialId], {
            is: value => value > '0',
            then: () => validations.containerNumber.when([MaterialFieldName.canPickup], {
              is: true,
              then: () => validations.containerNumber.when([MaterialFieldName.deliverContainers], {
                is: a => a > 0,
                then: () => validations.containerNumber.positive()
                  .min(0, translations.pickupRequestDetails_materialDefaultValidation),
                otherwise: () => validations.containerNumber.positive()
                  .min(1, translations.pickupRequestDetails_materialDefaultValidation)
              })
            }),
            otherwise: () => validations.containerNumber.when([MaterialFieldName.canPickup], {
              is: true,
              then: () => validations.containerNumber.positive()
                .min(1, translations.pickupRequestDetails_materialNumberValidation)
            })
          }),
        [MaterialFieldName.deliverContainers]: validations.containerNumber
          .when([MaterialFieldName.materialId], {
            is: value => value > '0',
            then: () => validations.containerNumber.when([MaterialFieldName.canDeliver], {
              is: true,
              then: () => validations.containerNumber.when([MaterialFieldName.pickupContainers], {
                is: a => a > 0,
                then: () => validations.containerNumber.positive()
                  .min(0, translations.pickupRequestDetails_materialDefaultValidation),
                otherwise: () => validations.containerNumber.positive()
                  .min(1, translations.pickupRequestDetails_materialDefaultValidation)
              })
            }),
            otherwise: () => validations.containerNumber.when([MaterialFieldName.canDeliver], {
              is: true,
              then: () => validations.containerNumber.positive()
                .min(1, translations.pickupRequestDetails_materialNumberValidation)
            })
          }),
        ...extraValidations
      })
    ),
    commentary: Yup.object().shape({
      // bookingReference: validations.string(translations),
      bookingReference: validations.string(translations).min(TEXT_MIN_LENGTH, translations.validations_minLength.replace(':min:', TEXT_MIN_LENGTH.toString())).max(TEXT_MAX_LENGTH_bookingReference, translations.validation_maxLength_bookingReference.replace(':max:', TEXT_MAX_LENGTH_bookingReference.toString())),
      // additionalComment: validations.textArea(translations)
      additionalComment: validations.textArea(translations).min(TEXT_MIN_LENGTH, translations.validations_minLength.replace(':min:', TEXT_MIN_LENGTH.toString())).max(TEXT_MAX_LENGTH_additionalComments, translations.validation_maxlength_additionalComments.replace(':max:', TEXT_MAX_LENGTH_additionalComments.toString())),
    }),
    driverInformation: Yup.object().shape({
      additionalComment: validations.textArea(translations)
    })
  });
};
