import styled from 'styled-components';
import { TABLET } from 'base/styles/media-queries';
import { textStyles } from 'containers/Home/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.div`
  ${ textStyles }
`;
Text.displayName = 'Text';

export const TextDesktop = styled.div`
  ${ textStyles }
  display: none;

  ${ TABLET } {
    display: flex;
    margin-left: 3px;
  }
`;
TextDesktop.displayName = 'TextDesktop';

export const TextRow = styled.div`
  display: flex;
`;
TextRow.displayName = 'TextRow';
