import * as Yup from 'yup';
// import * as validations from 'app/constants/validations';
import { useTranslationsContext } from 'app/translations';

const TEXT_MAX_LENGTH = 100;
const TEXT_MIN_LENGTH = 1;

export const useValidationSchema = (): Yup.ObjectSchema => {
  const translations = useTranslationsContext();

  return Yup.object().shape({
    templateActionDetails: Yup.object().shape({
      saveTemplate: Yup.boolean().notRequired(),
      templateName: Yup.string().notRequired()
        .when('saveTemplate', {
          is: val => val === true,
          then: () => Yup.string().required(translations.validations_requiredField)
            .min(TEXT_MIN_LENGTH, translations.validations_minLength.replace(':min:', TEXT_MIN_LENGTH.toString()))
            .max(TEXT_MAX_LENGTH, translations.validations_maxLength.replace(':max:', TEXT_MAX_LENGTH.toString()))
        })
    })
  });
};
