import React, { useEffect } from 'react';
import { PickupRequestStatus } from 'app/constants/pickupRequest';
import { useTranslationsContext } from 'app/translations';
import { IndexedFields } from 'app/types';
import Card from 'components/Card';
import HelpButton from 'components/HelpButton';
import LockIcon from 'components/LockIcon';
import LockRedIcon from 'components/LockRedIcon';
import SectionTitle from 'components/SectionTitle';
import Field from 'components/SmartForm/components';
import { HelpTopic } from 'containers/Help/types';
import { Location } from 'containers/Location/types';
import { PickupRequest } from 'containers/PickupRequest/types';
import Tag from '../../../PickupRequestList/components/Tag';
import { CardContent } from '../../styles';
import { FieldName, RequestCustomerlList, CompanySelection, RequestContainerList } from '../../types';
import { CompanyWrapper,
  DateWrapper,
  TimeWrapper,
  LocationWrapper,
  CustomerWrapper,
  PhoneWrapper,
  PhoneLabel,
  PhoneField,
  TemplateCreatorDetails,
  HelpButtonWrapper } from './styles';

interface Props {
  id: string;
  companyId: string;
  onContainerListRequest: RequestContainerList;
  onCustomerListRequest: RequestCustomerlList;
  onCompanySelection?: CompanySelection;
  status?: PickupRequestStatus;
  fields: IndexedFields<FieldName>;
  shouldShowAside?: boolean;
  locationData?: Location[];
  newTemplate?: boolean;
  createName?: string;
  createdDate?: number;
  values?: PickupRequest;
}

const Logistics: React.FC<Props> = ({ id,
  companyId,
  status, // status = PickupRequestStatus.DRAFT  not sure what is assigned 
  fields,
  values,
  onContainerListRequest,
  onCustomerListRequest,
  onCompanySelection,
  shouldShowAside,
  locationData,
  newTemplate,
  createName,
  createdDate }) => {
  const translations = useTranslationsContext();
  useEffect(() => {
    if (companyId) {
      onCustomerListRequest(companyId);
    }
  }, [companyId]);

  const title = id
    ? `${ translations.pickupRequestDetails_logisticsOrderTitle } ${ id }`
    : translations.pickupRequestDetails_logisticsTitle;

  const LockIconColor = values?.driverOrders?.some(drive => drive?.isdriverOrderExecutionDate === true);

  const pickupStatusCheck: boolean = values?.driverOrders?.some(driveStatus => driveStatus.driveOrderStatusId === 2);

  const statusIcons = id && (
    <>
      { LockIconColor && (status === 2 || status === 4 || status === 5)
        ? <LockRedIcon /> 
        : <LockIcon locked={ !(status === PickupRequestStatus.DRAFT) } />
      }
      {
        pickupStatusCheck
          ? <Tag cell={ 2 } />
          : <Tag cell={ status } /> 
      }
    </>
  );
  // const statusIcons = id && (
  //   <>
  //     <LockIcon locked={ !(status === PickupRequestStatus.DRAFT) } />
  //     <Tag cell={ status } />
  //   </>
  // );

  const creatorDatevalue = new Date(createdDate).toLocaleDateString().replace(/\//g, '.');
  const checkCreatorExist = (creatorDatevalue !== '01.01.1970' && creatorDatevalue !== '1.1.1970');
  const creatorNameshow = !((!status) || status < PickupRequestStatus.NEW);
  const templateCreator = `${ translations.template_created_on }  ${ creatorDatevalue }
   ${ translations.template_created_by } ${ createName }`;

  return (
    <>
      {/* { (newTemplate && checkCreatorExist) && <TemplateCreatorDetails>{ templateCreator }</TemplateCreatorDetails> } */}
      { !newTemplate ? <SectionTitle>
        { title }
        { statusIcons }
        { shouldShowAside && (
          <HelpButtonWrapper>
            <HelpButton topic={ HelpTopic.PICKUPS } />
          </HelpButtonWrapper>
        ) }
      </SectionTitle>
        : <SectionTitle>
          { translations.pickupRequestDetails_logisticsTitle }
          { shouldShowAside && (
            <HelpButtonWrapper>
              <HelpButton topic={ HelpTopic.PICKUPS } />
            </HelpButtonWrapper>
          ) }
        </SectionTitle>
      }
      <Card indentation={ true }>
        { (newTemplate && checkCreatorExist) && <TemplateCreatorDetails>{ templateCreator }</TemplateCreatorDetails> }
        <CardContent>
          <CustomerWrapper>
            { creatorNameshow && fields['logistic.creatorName']
              && <Field options={ fields['logistic.creatorName'] } /> }
          </CustomerWrapper>
          { fields['company.id'] && (
            <>
              <CompanyWrapper>
                <Field
                  options={ {
                    ...fields['company.id'],
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      onContainerListRequest(event.target.value);
                      onCompanySelection(event.target.value);
                    }
                  } }
                />
              </CompanyWrapper>
              { /* <CustomerWrapper>
                <Field options={ fields['logistic.creatorName'] } />
              </CustomerWrapper> */ }
            </>
          ) }

          <LocationWrapper fullWidth={ !fields['company.id'] }>
            <Field
              options={ {
                ...fields['logistic.location'],
                autocomplete: locationData?.map(loc => loc.name)
              } }
            ></Field>
            { fields['logistic.hasToSaveLocation'] && <Field options={ fields['logistic.hasToSaveLocation'] } /> }
          </LocationWrapper>

          { !newTemplate ? (
            <DateWrapper>
              <Field options={ fields['logistic.preferredDate'] } />
            </DateWrapper>
          ) : (
            <DateWrapper>
              <Field options={ fields['logistic.templateDateRule'] } />
            </DateWrapper>
          ) }

          <TimeWrapper>
            <Field options={ fields['logistic.timePeriod'] } />
          </TimeWrapper>

          <PhoneWrapper>
            <PhoneLabel>
              <Field options={ fields['logistic.hasToCallClient'] } />
            </PhoneLabel>
            <PhoneField>
              <Field options={ fields['logistic.telephone'] } />
            </PhoneField>
          </PhoneWrapper>
        </CardContent>
      </Card>
    </>
  );
};

export default Logistics;
