import { TimePeriod, PickupRequestStatus, DriverOrderStatus } from 'app/constants/pickupRequest';
import { ROLE } from 'app/constants/roles';
import { Pagination } from 'app/types';
import { SmartFile } from 'components/SmartForm/components/fileUploader/types';
import { Customer } from 'containers/Companies/types';
import { Container, ContainerType, Material, ContainerAddon } from './containers';

export * from './containers';

export interface Entity {
  id: string;
  name: string;
}

interface DropdownGroup {
  customerList: Customer[];
  materialList: Material[];
  containerList: Container[];
  recentsList: Container[];
  typesList: ContainerType[];
}

export interface PickupRequestState {
  collection: PickupRequest[];
  pagination: Pagination;
  dropdownGroup: DropdownGroup;
}

export interface ConstraintGroup {
  role: ROLE;
  status: PickupRequestStatus;
}

export interface Logistic {
  location: string;
  timePeriod?: TimePeriod;
  preferredDate: number;
  hasToSaveLocation?: boolean;
  hasToCallClient?: boolean;
  telephone?: string;
  creatorName?: string;
  templateDateRule?: number;
}

export interface DriverOrders {
  pickupId?: string;
  driverOrderId?: string;
  driverOrderContent?: string;
  deliveryDate?: string;
  deliveryFrameId?: TimePeriod;
  driveOrderStatusId?: DriverOrderStatus;
  isdriverOrderExecutionDate?: boolean;

}

export interface MaterialDetails {
  addons: ContainerAddon[];
  id: string;
  containerId: string;
  containerName?: string;
  materialId: string;
  materialName?: string;
  pickupContainers: number;
  deliverContainers: number;
  destination: string;
  handleDocumentation?: boolean;
  date: number;
  timeSlot: TimePeriod;
  isVeva?: boolean;
}

export interface DummyFile {
  id: string;
  name: string;
}

export interface Commentary {
  bookingReference?: string;
  hasToSaveBookinReference?: boolean;
  additionalComment?: string;
  attachedFiles?: SmartFile[];
}

export interface DriverInfo {
  additionalComment?: string;
  attachedFiles?: SmartFile[];
}
export interface TemplateActionDetails {
  saveTemplate?: boolean;
  templateName?: string;
  templateDateRule?: number;
  templatePlaceOrderDate?: number;
}

export interface Company {
  id: string;
  name: string;
}

export interface PickupRequest {
  id: string;
  company: Company;
  owner: Customer;
  createdDate: number;
  status: PickupRequestStatus;
  logistic: Logistic;
  materials?: MaterialDetails[];
  commentary?: Commentary;
  driverInformation?: DriverInfo;
  PDFUrl?: string;
  templateActionDetails?: TemplateActionDetails;
  driverOrders?: DriverOrders[];
  isWishDateSame?: boolean;
}

export interface ResendPickupRequest{
  id: string;
}

export interface PickupRequestRow {
  lock: PickupRequestStatus;
  address: string;
  creationDate: number;
  company?: string;
  id: string;
  owner: string;
  preferredDate: number;
  status: PickupRequestStatus;
  isVeva: boolean;
  navigate: string;
  isWishDateSame?: boolean;
}

export interface PickupRequestParams {
  offset: number;
  limit: number;
  filterValue: string;
  order: string;
  rowname: string;
}

export interface TemplateUpdateRequest extends PickupRequest {
  templateName: string;
  templateId?: string;
}