/* eslint-disable max-len, max-lines */
import { LangKeys } from './types';

const de: LangKeys = {
  sidebar_menu: 'Menu',
  routeName_root: 'Home',
  routeName_home: 'Home',
  routeName_forgotPassword: 'Passwort vergessen',
  routeName_quotas: 'Kontingente',
  routeName_notifications: 'Benachrichtigungen',
  routeName_resetPassword: 'Passwort zurücksetzen',
  routeName_onboarding: 'Onboarding',
  routeName_privacyAgreement: 'Datenschutzbestimmung',
  routeName_pickUpRequests: 'Aufträge',
  routeName_newPickupRequest: 'Neu',
  routeName_newTemplateRequest: 'Neu',
  routeName_TemplateRequest: 'Vorlage',
  routeName_TemplateDetails: 'Details',
  routeName_pickUpRequest: 'Details',
  routeName_deliverySlips: 'Lieferscheine',
  routeName_deliverySlip: 'Details',
  routeName_bills: 'Rechnungen',
  routeName_bill: 'Details',
  routeName_statistics: 'Statistiken',
  routeName_users: 'Benutzer',
  routeName_newUser: 'Neu',
  routeName_user: 'Details',
  routeName_settings: 'Einstellungen',
  routeName_help: 'Hilfe',
  routeName_logout: 'Abmelden',

  login_title: 'Anmeldung AVAG-Kundenportal',
  requestAccess_title: 'Zugriff beantragen',
  login_emailLabel: 'E-Mail',
  login_emailPlaceholder: 'E-Mailadresse eingeben',
  login_passwdLabel: 'Passwort',
  login_passwdPlaceholder: '*************',
  login_submitLabel: 'Anmelden',
  login_footer_1: 'Falls Sie noch keinen Zugriff auf das AVAG-Kundenportal haben, können Sie',
  login_footerHere: 'Zugriff beantragen',
  login_footer_2: 'Ihre persönlichen Logindaten beantragen',
  login_forgotPasswdLabel: 'Passwort vergessen?',
  login_errorTitle: 'Etwas ist schief gelaufen…',
  login_errorDescription: 'Bitte überprüfen Sie, ob Sie Ihre korrekten Daten eingegeben haben.\n\n Bei einer Neuregistration muss AVAG Ihre Logindaten zuerst verifizieren. Dies kann einen Augenblick in Anspruch nehmen.',

  forgotPassword_errorMessage: 'Bitte überprüfen Sie die E-Mailadresse',

  forgotPassword_title: 'Passwort vergessen?',
  forgotPassword_description:
    'Um das Passwort zurückzusetzen, geben Sie bitte Ihre E-Mailadresse ein. Sie erhalten im Anschluss eine E-Mail, womit Sie ein neues Passwort anfordern können.',
  forgotpassword_description1: 'Bei einer Neuregistration muss AVAG Ihre Logindaten zuerst verifizieren. Dies kann einen Augenblick in Anspruch nehmen. Sie werden automatisch mit einer E-Mail kontaktiert, sobald Sie verifiziert sind.',
  forgotPassword_emailLabel: 'E-Mail',
  forgotPassword_emailPlaceholder: 'E-Mailadresse eingeben ',
  forgotPassword_submitLabel: 'Senden',
  forgotPassword_cancelLabel: 'Abbrechen',
  forgotPassword_decriptionSuccess:
    'Sie erhalten in Kürze eine E-Mail mit einem Link, womit Sie Ihr Passwort zurücksetzen können.',
  forgotPassword_linkLoginText: 'Falls Sie kein E-Mail erhalten, so prüfen Sie Ihren Spamordner.',

  onboarding_title: 'Vorteile des AVAG-Kundenportals',
  onboarding_text_1: 'Einfach, schnell und unkompliziert. In Zeiten der nachhaltigen Umweltentwicklung bringen Sie mit dem kostenlosen AVAG-Kundenportal noch mehr Transparenz in Ihre Abfallbewirtschaftung. Entsorgungsaufträge erstellen Sie innerhalb weniger Sekunden – auch unterwegs via Smartphone. Das AVAG-Kundenportal bietet zudem folgende Vorteile:',
  onboarding_text_2: 'Überzeugen Sie sich selbst und fordern Sie Ihren kostenlosen Zugang an.\n\nFür weitere Informationen, Videos und bei Fragen kontaktieren Sie uns unter:',
  onboarding_text_3: 'oder via E-Mail an',
  onboarding_text_4: 'Wir freuen uns auf Ihr Interesse am Kundenportal.',
  onboarding_title_icon_1: 'Stets zur Verfügung',
  onboarding_title_icon_2: 'Zeitsparend und effizient',
  onboarding_title_icon_3: 'Zentrale Dokumentenablage',
  onboarding_title_icon_4: 'Persönliche Statistiken',
  onboarding_title_icon_5: 'Übersichtliche Mengenkontingente',
  onboarding_text_icon_1: 'Das AVAG-Kundenportal und dessen Dienstleistungen stehen Ihnen rund  um die Uhr und auf allen gängigen Webbrowsern zur Verfügung.',
  onboarding_text_icon_2: 'Erteilen Sie Entsorgungsaufträge dank der praktischen Vorlagenfunktion  innerhalb von wenigen Sekunden.',
  onboarding_text_icon_3: 'Rechnungen, Kontoauszüge, VeVA-Begleitscheine und andere Entsorgungsdokumente – alles an einem Ort im AVAG-Kundenportal.',
  onboarding_text_icon_4: 'Behalten Sie dank Statistiken den Überblick über Ihre bereits abgeführten  Fraktionen und die Kosten.',
  onboarding_text_icon_5: 'Rufen Sie im AVAG-Kundenportal den aktuellen Stand Ihrer Mengenkontingente ab.',
  onboarding_backToLogin: 'Zurück zum Kundenportal Login',
  onboarding_formTitle: 'Zugriff anfordern',
  onboarding_noteTextmandatory: 'Füllen Sie alle Felder aus.',
  onboarding_nameLabel: 'Vorname',
  onboarding_lastNameLabel: 'Nachname',
  onboarding_lastNamePlaceholder: 'Nachname eingeben',
  onboarding_namePlaceholder: 'Vorname eingeben',
  onboarding_companyLabel: 'Firma',
  onboarding_companyPlaceholder: 'Firmennamen eingeben',
  onboarding_phoneLabel: 'Telefonnummer',
  onboarding_phonePlaceholder: '0795119713',
  onboarding_emailLabel: 'E-Mail',
  onboarding_emailPlaceholder: 'E-Mailadresse eingeben',
  onboarding_privacy1: 'Ich akzeptiere die',
  onboarding_privacy2: 'Datenschutzbestimmung',
  onboarding_backToLoginButton: 'Zurück zum  Login',
  onboarding_success_info:
    'Vielen Dank für Ihre Anfrage im AVAG-Kundenportal. Sie haben soeben eine Mail mit einem Link erhalten, um Ihre Verifizierung abzuschliessen. Sobald Sie die Freigabe unseres Kundenberaters erhalten, können Sie sich mit Ihrem Passwort anmelden.',
  onboarding_password_info: 'Setzen Sie nachstehend Ihr persönliches Passwort, dass Sie nach der Freigabe durch die AVAG für das Kundenportal benutzen möchten.',
  onboarding_submitLabel: 'Anfordern',
  onboarding_requiredValidation: 'Dies ist ein Pflichtfeld',
  onboarding_phoneValidation: 'Die angegebene Telefonnummer ist ungültig',
  onboarding_requiredMailFormat: 'Bitte überprüfen sie die E-Mailadresse (name@firma.ch)',
  onboarding_closePrivacyAgreement: 'Schliessen',
  onboarding_privacy_info_title1: 'Einverständniserklärung',
  onboarding_privacy_info1: 'Hiermit gebe Ich mein Einverständnis, dass meine Personendaten (Namen, Firma, Telefonnummer und E-Mail-Adresse) durch die AVAG Umwelt AG (AVAG) zur Erfüllung der Dienstleistungen verwendet werden dürfen. Diese Personendaten werden ausschliesslich an Dritte weitergegeben, wenn dies für die Auftragsausführung relevant ist (zum Beispiel: Transporteure)',
  onboarding_privacy_info_title2: 'Hinweis zum Datenschutz',
  onboarding_privacy_info2: 'AVAG berücksichtigt bei der Erhebung und Bearbeitung von Personendaten die Bestimmungen der schweizerischen Datenschutzgesetzgebung. AVAG setzt in allen Bereichen Ihres Online-Angebots umfassende technische Mittel und organisatorische Massnahmen zum Schutz vor unberechtigtem Zugriff, Manipulationen und Datenverlust ein. Unser Kundenportal nutzt eine verschlüsselte Verbindung nach aktuellem Sicherheitsstandard. Vermeiden Sie öffentliche Hotspots und verwenden Sie nur verschlüsselte WLAN-Verbindungen von Anbietern Ihres Vertrauens.',
  onboarding_privacy_info_title3: 'AVAG Umwelt AG ',
  onboarding_privacy_info3: 'Allmendstrasse 166, 3600 Thun\n\nE-Mail: info@avag.ch',
  onboarding_privacy_info_title4: 'Hinweis zur Kundenportalnutzung',
  onboarding_privacy_info4: 'Die Rollen, Zugriffsrechtverteilung, sowie das Löschen von ehemaligen Firmenmitarbeitern liegt in der Obhut des Kunden.\n\n\n ',
  onboarding_privacy_info5: 'Für weitere Informationen und Erläuterungen finden Sie unter  ',
  onboarding_privacy_info6: ' oder wenden Sie sich an:',
  onboarding_privacy_info_title6: 'AVAG: AVAG-Kundenportal',
  resetPassword_title: 'Passwort setzen',
  resetPassword_description:
    'Bitte beachten Sie, dass Ihr Passwort mindestens einen Buchstaben und eine Zahl enthalten muss. Zudem muss das Passwort mindestens 8 Zeichen lang sein.',
  resetPassword_newPasswordLabel: 'Neues Passwort erstellen',
  resetPassword_confirmPasswordLabel: 'Passwort bestätigen',
  resetPassword_passwordPlaceholder: '*********',
  resetPassword_submitButton: 'Absenden',
  resetPassword_successTitle: 'Ihr Passwort wurde erfolgreich gesetzt',
  resetPassword_successMessage: 'Sie können sich nun auf dem Portal anmelden',
  resetPassword_errorMessage: 'Etwas ist schief gelaufen.',
  resetPassword_loginLink: 'Zurück zum Login',
  resetPassword_requiredValidation: 'Dies ist ein Pflichtfeld',
  resetPassword_minValidation: 'Ihr Passwort sollte mindestesnd 8 Zeichen lang sein',
  resetPassword_maxValidation: 'Ihr Passwort sollte mindestesnd 32 Zeichen lang sein',
  resetPassword_lowercaseValidation: 'Ihr Passwort sollte mindestens 1 Kleinbuchstaben enthalten',
  resetPassword_uppercaseValidation: 'Ihr Passwort sollte mindestens 1 Grossbuchstaben enthalten',
  resetPassword_numberValidation: 'Ihr Passwort sollte mindestens 1 Zahl enthalten',
  resetPassword_matchValidation: 'Die Passwort Bestätigung ist nicht einstimmig.',
  pickupRequest_DescriptionDraft: 'Der Abholauftrag wurde von Ihnen für eine spätere Bearbeitung zwischengespeichert. Das offene Schloss zeigt zudem die Nachbearbeitungsmöglichkeit an. Der Abholauftrag lässt sich noch löschen.',
  pickupRequest_DescriptionNew: 'Der Abholauftrag wurde von Ihnen gestellt und ist zur Disposition freigegeben. Das Schloss schliesst sich, womit der Abholauftrag nicht mehr nachbearbeitet werden kann.',
  pickupRequest_DescriptionInProcess: 'Sobald der AVAG-Disponent Ihren Abholauftrag disponiert, schliesst sich das Schloss und der Abholauftrag lässt sich nicht mehr Nachbearbeiten. Der Abholauftrag ist nun in Bearbeitung, solange dieser nicht abgeschlossen ist.',
  pickupRequest_DescriptionProcessed: 'Der Abholauftrag wurde bei Ihnen abgeholt. Der Transport ist somit abgeschlossen, jedoch kann die Verrechnung noch ausstehend sein.',
  pickupRequest_DescriptionError: 'Beim übermitteln des Auftrags wurde ein Fehler festgestellt. Unser Team wurde informiert und wird sich um den Auftrag kümmern.',
  pickupRequestDetails_noteSectionTitle: 'Bitte beachten Sie',
  pickupRequestDetails_noteSectionInfo1: 'Datum',
  pickupRequestDetails_noteSectionInfo2: 'und',
  pickupRequestDetails_noteSectionInfo3: 'Behälteranzahl',
  pickupRequestDetails_noteSectionInfo4:
    'Bitte beachten Sie, dass es sich bei Ihrer Datumsangabe um ein Wunschdatum handelt und die Ausführung nicht für dieses Datum garantiert wird',
  pickupRequestDetails_noteSectionInfo5:
    'Die Anlieferung von leeren Behältern kann aufgrund von Verfügbarkeiten eingeschränkt sein. Bei Abweichungen setzen wir uns mit Ihnen in Verbindung',
  pickupRequestDetails_logisticsTitle: 'Standort und Termin',
  pickupRequestDetails_logisticsOrderTitle: 'Auftrag',
  pickupRequestDetails_companyLabel: 'Kunde',
  pickupRequestDetails_requestorLabel: 'Auftraggeber',
  pickupRequestDetails_creatorLabel: 'Erstellt durch',
  pickupRequestDetails_locationLabel: 'Behälterstandort',
  pickupRequestDetails_locationPlaceholder: 'Geben Sie die Abhol-/Lieferadresse ein',
  pickupRequestDetails_saveLocationLabel: 'Standort für zukünftige Aufträge speichern',
  pickupRequestDetails_preferredDateLabel: 'Wunschdatum',
  pickupRequestDetails_preferredDatePlaceholder: 'Wählen Sie das Abhol-/Lieferdatum aus',
  pickupRequestDetails_preferredTimeLabel: 'Zeitfenster',
  pickupRequestDetails_preferredTimePlaceholder: 'Flexibel',
  pickupRequestDetails_phoneLabel: 'Telefonnummer',
  pickupRequestDetails_phonePlaceholder: 'Geben Sie Ihre Telefonnummer ein',
  pickupRequestDetails_phoneCheck: 'Rufen Sie mich 1 Stunde vor Ankunft an',
  pickupRequestDetails_materialsTitle: 'Materialien',
  pickupRequestDetails_containerLabel: 'Behältersortiment',
  pickupRequestDetails_containerPlaceholder: 'Auswählen',
  pickupRequestDetails_materialLabel: 'Material-Art',
  pickupRequestDetails_materialPlaceholder: 'Auswählen',
  pickupRequestDetails_pickAmountLabel: 'Abzuholen',
  pickupRequestDetails_pickAmountPlaceholder: 'Anzahl',
  pickupRequestDetails_deliverAmountLabel: 'Zu liefern',
  pickupRequestDetails_deliverAmountPlaceholder: 'Anzahl',
  pickupRequestDetails_addMaterialRowLabel: 'weiteren Behälter hinzufügen',
  pickupRequestDetails_materialDestinationLabel: 'Entsorgungsstelle',
  pickupRequestDetails_materialDateLabel: 'Datum',
  pickupRequestDetails_materialTimeLabel: 'Zeitfenster',
  pickupRequestDetails_handleVevaMaterial: 'Ich beauftrage AVAG einen kostenpflichtigen VEVA Begleitschein auszustellen.',
  pickupRequestDetails_containerSelectLabel: 'Behälter-Typ auswählen',
  pickupRequestDetails_containerSelectCancel: 'Abbrechen',
  pickupRequestDetails_commentaryTitle: 'Zusätzliche Informationen',
  pickupRequestDetails_bookingRefLabel: 'Buchungsreferenz (wird auf Lieferschein abgedruckt)',
  pickupRequestDetails_bookingRefPlaceholder: 'z.B. Projektnummer, Kostenstelle, ...',
  pickupRequestDetails_saveLabel: 'Buchungsreferenz für zukünftige Aufträge speichern',
  pickupRequestDetails_commentaryLabel: 'Zusätzliche Kommentare',
  pickupRequestDetails_commentaryPlaceholder: 'Weitere Informationen, die Sie mit der AVAG teilen möchten',
  pickupRequestDetails_driverCommentPlaceholder: 'Bitte hier Kommentare für den Fahrer hinzufühgen.',
  pickupRequestDetails_uploadFileLabel: 'Datei hochladen',
  pickupRequestDetails_uploadFilePlaceholder: 'Auf Ihrem Computer suchen',
  pickupRequestDetails_driverInfoTitle: 'Informationen für den Fahrer',
  pickupRequestDetails_noContactPhone: 'Keine Telefonnummer vorhanden',
  pickupRequestDetails_closeButton: 'Zurück',
  pickupRequestDetails_abortButton: 'Abbrechen',
  pickupRequestDetails_abortMessage: 'Wollen Sie wirklich abbrechen?\nÄnderungen werden nicht gespeichert.',
  pickupRequestDetails_abortConfirm: 'Ja',
  pickupRequestDetails_abortDismiss: 'Nein',
  pickupRequestDetails_deleteButton: 'Löschen',
  pickupRequestDetails_deleteMessage: 'Wollen sie wirklich Ihren Auftrag löschen?',
  pickupRequestDetails_deleteDismiss: 'Abbrechen',
  pickupRequestDetails_printButton: 'Drucken',
  pickupRequestDetails_printMessage: 'Speichern, bevor Sie drucken',
  pickupRequestDetails_printConfirm: 'Verstanden',
  pickupRequestDetails_printName: 'Abholauftrag',
  pickupRequestDetails_saveForLaterButton: 'Entwurf sichern',
  pickupRequestDetails_saveForLaterMessage:
    'Sie sind dabei Ihren Abholauftrag als Entwurf zwischen zu \nspeichern, damit Sie diesen zu einem späteren Zeitpunkt \nnachbearbeiten können. Der Abholauftrag wird nicht an unsere Disposition übermittelt.',
  pickupRequestDetails_saveForLaterDismiss: 'Abbrechen',
  pickupRequestDetails_placeOrderButton: 'Auftrag erteilen',
  pickupRequestDetails_placeOrderMessage:
    'Sie sind dabei einen Auftrag zu erteilen.\nDer Vorgang kann nicht rückgängig gemacht werden.',
  pickupRequestDetails_placeOrderDismiss: 'Abbrechen',
  pickupRequestDetails_updateOrderButton: 'Disposition erteilen',
  pickupRequestDetails_completeOrderButton: 'Auftrag abschliessen',
  pickupRequestDetails_completeOrderMessage:
    'Sie sind dabei einen Auftrag zu erteilen.\nDer Vorgang kann nicht rückgängig gemacht werden.',
  pickupRequestDetails_completeOrderDismiss: 'Abbrechen',
  pickupRequestDetails_containerSetup: 'Behälterkonfiguration',
  pickupRequestDetails_addonNote: 'Hinweis',
  pickupRequestDetails_addonNoteDescription: 'Die aufgeführte Anzahl der Behälterzusätze ist pro Behälter. \n\nBeispiel: Für 2 Palette mit 3 Rähmen werden 6 Rähmen geliefert. \n(3 Rähmen pro Palette)',
  pickupRequestDetails_connetionFailTitle: 'Verbindungsprobleme',
  pickupRequestDetails_connetionFailMessage: 'Bedauerlicherweise konnte Ihr Entsorgungsauftrag nicht dem Disponent übermittelt werden.\n\n Bitte kontaktieren Sie unseren Disponenten telefonisch, um den Entsorgungsauftrag zu platzieren.\n\n Wir entschuldigen uns für die Unannehmlichkeiten.\n\n Disponent: +41 (33) 2265720',
  pickupRequestDetails_toPickup: 'abzuholen',
  pickupRequestDetails_toDeliver: 'zu liefern',
  pickupRequestDetails_perContainer: 'pro Behälter',
  pickupRequestDetails_materialNumberValidation: 'min. ein Feld größer 0',
  pickupRequestDetails_materialDefaultValidation: 'min. ein Feld größer 0',

  containers_backToCategory: 'Andere Kategorie auswählen',
  containers_backToContainer: 'Anderen Behälter auswählen',
  containers_confirmContainer: 'Bestätigen',
  containers_selectRecent: 'Ihre kürzlich gewählten Behälter',
  containers_selectType: 'Wählen Sie eine Behälterkategorie aus',
  containers_selectContainer: 'Wählen Sie einen Behältertyp aus',
  containers_selectAddons: 'Behälterzusatz.',

  billsList_date: 'Datum',
  billsList_docType: 'Dokument-Typ',
  billsList_id: 'Rechnungs-Nr.',
  billsList_amount: 'Total',
  billsList_status: 'Status',
  billsList_tag_new: 'Neu',
  billsList_tag_paid: 'Bezahlt',
  billsList_exportData: 'Daten exportieren',
  billsList_exportAccountStatement: 'Kontoauszug exportieren',
  billsList_searchLabel: 'Suche nach Rechnung',
  billsList_exportBalanceTitle: 'Kontoauszug exportieren',
  billsList_exportBalanceDescription:
    'Bitte wählen Sie einen Zeitraum aus, auf den sich der Datenexport beziehen soll.',
  billsList_export_dateStartLabel: 'Startdatum',
  billsList_export_dateEndLabel: 'Enddatum',
  billsList_exportDataTitle: 'Daten exportieren',
  billsList_exportDataDescription: 'Bitte wählen Sie den Zeitraum aus, auf den sich der Kontoauszug beziehen soll.',
  billsList_exportDataFormatLabel: 'Format wählen',
  billsList_exportDataFormatPlaceholder: 'CSV',
  billsList_exportDataName: 'Kontoauszug',
  billDetails_billNumberTitle: 'Rechnungs-Nr.',
  billDetails_creditNoteNumberTitle: 'Gutschrift-Nr.',
  billDetails_dueDateTitle: 'Bitte Beachten Sie',
  billDetails_dueDateDescription: 'Das Fälligkeitsdatum dieser :billDocType ist',
  billDetails_billInfoTitle: 'Rechnungsinformationen',
  billDetails_customerIdLabel: 'Kunden-Nr.',
  billDetails_vatIdLabel: 'MwSt-Nr.',
  billDetails_contactDetailsLabel: 'Kontaktangaben',
  billDetails_contactDetails_phone: 'Tel. direkt.',
  billDetails_contactDetails_central: 'Tel. Zentrale',
  billDetails_contactDetails_email: 'E-Mail',
  billDetails_contactDetails_web: 'Web',
  billDetails_tableHeader_date: 'Datum',
  billDetails_tableHeader_deliveryNoteId: 'Lieferschein-Nr.',
  billDetails_tableHeader_material: 'Material',
  billDetails_tableHeader_quantity: 'Menge',
  billDetails_tableHeader_unit: 'Einheit',
  billDetails_tableHeader_price: 'Preis',
  billDetails_tableHeader_amount: 'Betrag CHF',
  billDetails_tableHeader_vat: 'MwSt.',
  billDetails_total_title: 'Total',
  billDetails_total_subtotalLabel: 'Zwischentotal',
  billDetails_total_totalLabel: 'Total Rechnungsbetrag inkl. MwSt.',
  billDetails_total_termsOfPayment: 'Zahlungsbedingungen',
  billDetails_printInvoice: 'Drucken',
  billDetails_help: 'Hilfe',
  billDetails_printName: 'Rechnung',
  billDetails_disclaimerTitle: 'Hinweis',
  billDetails_disclaimerText: 'Abweichungen in der Statistik sind aufgrund von noch nicht freigegebenen Lieferscheinen und dem noch ausstehenden Rechnungsabschluss möglich.',
  billDetails_modalBoxNoteText: 'Im Kontoauszug sehen Sie alle Verrechnungspositionen für den vorgewählten Zeitraum.\n\nOffene Verrechnungspositionen sehen Sie in der Rechnungsübersicht mit dem Status “Neu”.',

  exportFormat_csv: 'CSV',
  exportFormat_pdf: 'PDF',
  exportFormat_xlsx: 'Excel',

  field_passwordStrength: 'Passwortstärke',

  confirmModal_cancel: 'Abbrechen',
  confirmModal_confirm: 'Bestätigen',

  pickupRequest: 'Auftrag',
  receipt: 'Beleg',
  amount: 'Betrag',
  creditNote: 'Gutschrift',
  quota: 'Kontingent',
  balanceSheet: 'Kontoauszug',
  client: 'Kunden',
  deliverySlip: 'Lieferschein',
  vat: 'MwSt.',
  frameAgreement: 'Rahmenvertrag',
  bill: 'Rechnung',
  container: 'Behälter',
  attachment: 'Datei',

  finance: 'Finanzen',
  customerService: 'Kundendienst',
  disponent: 'Disponent',

  role: 'Rolle',
  operator: 'Benutzer',
  superUser: 'Administrator',

  calendar_title_preferred_date: 'Wählen Sie Ihr Wunschdatum',
  calendar_title_date: 'Wählen Sie Ihr Datum',
  month_january: 'Januar',
  month_february: 'Februar',
  month_march: 'März',
  month_april: 'April',
  month_may: 'Mai',
  month_june: 'Juni',
  month_july: 'Juli',
  month_august: 'August',
  month_september: 'September',
  month_october: 'Oktober',
  month_november: 'November',
  month_december: 'Dezember',

  timePeriod_flexible: 'Flexibel',
  timePeriod_afternoon: 'Nachmittag',
  timePeriod_morning: 'Vormittag',
  
  driver_order_status_new: 'Neu',
  driver_order_status_progress: 'In Bearbeitung',
  driver_order_status_proceed: 'Bearbeitet',

  every_monday: 'Every Monday',
  every_tuesday: 'Every Tuesday',
  every_wednesday: 'Every Wednesday',
  every_thursday: 'Every Thursday',
  every_friday: 'Every Friday',

  table_emptyMessage: 'keine Daten verfügbar.',
  table_emptyMessage_Quotas: 'Sie besitzen kein Kontingent.',
  table_emptyMessage_statistics: 'Für diesen Zeitraum bestehen keine Daten.',
  table_emptyMessage_notification: 'Sie haben keine Benachrichtigungen.',

  pickUpRequest_create: 'Neuen Auftrag erstellen',
  pickUpRequest_search_placeholder: 'Suche nach einem Auftrag',
  pickUpRequest_tag_new: 'Neu',
  pickUpRequest_tag_draft: 'Entwurf',
  pickUpRequest_tag_processing: 'In Bearbeitung',
  pickUpRequest_tag_processed: 'Bearbeitet',

  pickUp_creationDate: 'Auftragsdatum',
  pickUp_orderId: 'Auftrags-Nr.',
  pickUp_company: 'Kunde',
  pickUp_owner: 'Antragsteller',
  pickUp_address: 'Standort',
  pickUp_veva: 'VeVa',
  pickUp_status: 'Status',

  tag_new: 'Neu',
  tag_draft: 'Entwurf',
  tag_in_process: 'In Bearbeitung',
  tag_processed: 'Bearbeitet',
  tag_approved: 'Freigegeben',
  tag_error: 'Error',

  uploadFile_placeholder: 'Suche auf Computer',
  uploadFile_label: 'Datei hochladen',

  deliverySlip_search_placeholder: 'Suche nach Lieferschein',
  deliverySlip_date: 'Datum',
  deliverySlip_id: 'Lieferschein-Nr.',
  deliverySlip_bookingReference: 'Buchungsreferenz',
  deliverySlip_attachments: 'Anhänge',
  deliverySlip_status: 'Status',
  deliverySlipList_exportData: 'Daten exportieren',
  deliverySlip_exportModalTitle: 'Daten exportieren',
  deliverySlip_exportModalDescription: 'Bitte wählen Sie den Zeitraum aus, auf den sich der Datenexport beziehen soll',
  deliverySlip_exportModalConfirmLabel: 'Bestätigen',
  deliverySlip_exportModalDismissLabel: 'Abbrechen',
  deliverySlip_exportModalStartDateLabel: 'Startdatum',
  deliverySlip_exportModalEndDateLabel: 'Enddatum',
  deliverySlip_exportModalFormatLabel: 'Dateiformat',
  deliverySlipList_exportDataName: 'Lieferscheine',

  deliverySlipDetails_title: 'Lieferschein-Nr.',
  deliverySlipDetails_help: 'Hilfe',
  deliverySlipDetails_openInvoice: 'Rechnung öffnen',
  deliverySlipDetails_messageTitle: 'Bitte beachten Sie',
  deliverySlipDetails_messageNew:
    'Dieser Lieferschein wird im Moment von der AVAG geprüft. Erst wenn der Status als freigegeben angezeigt wird, sind Materialien, Mengen und Kosten verbindlich und werden entsprechend verrechnet.',
  deliverySlipDetails_messageApproved:
    'Dieser Lieferschein wird im Moment von der AVAG geprüft. Erst wenn der Status als freigegeben angezeigt wird, sind Materialien, Mengen und Kosten verbindlich und werden entsprechend verrechnet.',
  deliverySlipDetails_messageBeInvoiced:
    'Dieser Auftrag wurde von AVAG geprüft und freigegeben. Erst wenn der Status als bestätigt angezeigt wird, sind Materialien, Mengen und Kosten verbindlich',
  deliverySlipDetails_buttonPrint: 'Drucken',
  deliverySlipDetails_deliveryReferenceLabel: 'Lieferschein-Nr.',
  deliverySlipDetails_dateLabel: 'Datum',
  deliverySlipDetails_companyReferenceLabel: 'Kunden Nr.',
  deliverySlipDetails_driverLabel: 'Transporteur',
  deliverySlipDetails_bookingReferenceLabel: 'Buchungsreferenz',
  deliverySlipDetails_itemReferenceLabel: 'Artikel-Nr.',
  deliverySlipDetails_materialLabel: 'Material',
  deliverySlipDetails_unitLabel: 'Einheit',
  deliverySlipDetails_grossLabel: 'Brutto',
  deliverySlipDetails_tareLabel: 'Tara',
  deliverySlipDetails_netLabel: 'Netto',
  deliverySlipDetails_retailPriceLabel: 'VK-Preis',
  deliverySlipDetails_amountLabel: 'Betrag',
  deliverySlipDetails_taxLabel: 'MwSt.',
  deliverySlipDetails_printName: 'Lieferschein',

  usersList_title: 'Benutzer',
  usersList_searchLabel: 'Suche nach einem Benutzer',
  usersList_createLabel: 'Benutzer hinzufügen',
  usersList_tableHeader_company: 'Kunde',
  usersList_tableHeader_date: 'Antragsdatum',
  usersList_tableHeader_name: 'Name',
  usersList_tableHeader_email: 'E-Mail',
  usersList_tableHeader_role: 'Rolle',
  usersList_tableHeader_status: 'Status',
  usersList_tag_pendingActivation: 'Aktivierung ausstehend',
  usersList_tag_Inactive: 'Inaktiv',
  userList_tag_activated: 'Passwort ausstehend',
  userList_tag_active: 'Aktiv',
  userList_tag_pending_verification: 'Verifizierung ausstehend',

  userDetails_title: 'Benutzer data',
  userDetails_titleRole: 'Rolle',
  userDetails_titleFunctionalities: 'Zugriffsrechte',
  userDetails_passwdPendingTitle: 'Benutzeraktivierung',
  userDetails_passwdPendingDescription:
    'Der Benutzer ist aktiviert, sobald er seine zugestellte E-Mail zur Einrichtung seines Passworts bearbeitet hat.',
  userDetails_newUserTitle: 'Benutzeraktivierung',
  userDetails_newUserDescription:
    'Der Benutzer wird eine E-Mail erhalten, um dort sein Passwort einzurichten. Nachdem ist der Nutzer aktiviert.',
  userDetails_cancelUserEdit: 'Abbrechen',
  userDetails_deleteUser: 'Benutzer löschen',
  userDetails_createUser: 'Bestätigen',
  userDetails_saveUser: 'Benutzerinformationen aktualisieren',
  userDetails_userCompany: 'Kunde',
  userDetails_userFirstName: 'Vorname',
  userDetails_userFirstNamePlaceholder: 'Max',
  userDetails_userLastName: 'Nachname',
  userDetails_userLastNamePlaceholder: 'Mustermann',
  userDetails_userEmail: 'E-Mail',
  userDetails_userEmailPlaceholder: 'm.mustermann@avag.ch',
  userDetails_userPhoneNumber: 'Telefonnummer',
  userDetails_userPhoneNumberPlaceholder: '012345678',
  userDetails_changePassword: 'Passwort ändern',
  userDetails_userRole: 'Rolle',
  userDetails_roleLegend:
    'Einem Benutzer kann die Rolle eines Super Users oder eines Operators zugewiesen werden, wodurch unterschiedliche Freigaberechte erteilt werden.',
  userDetails_avagAdminDescription:
    'Erteilen Sie für Neukunden und Benutzer eine Portalfreigabe und verwalten Sie diese. Definieren und managen Sie die Funktionalitäten und Aufträge der Kunden.',
  userDetails_superUserDescription:
    'Verwalten Sie Ihre Benutzer und erteilen Sie die Freigabe für Aufträge, Lieferscheine und Rechnungen sowie Statistik im Bereich der Zugriffsrechte.',
  userDetails_operatorDescription:
    'Erhalten Sie die Freigabe vom Super User für das Generieren und Managen von Aufträgen sowie die Einsicht für Lieferscheine, Rechnungen und Statistik.',
  userDetails_userPickupPermission: 'Kann Aufträge erstellen',
  userDetails_userBillsPermission: 'Kann auf die Rechnungen zugreifen',
  userDetails_deleteModalTitle: 'Benutzer löschen',
  userDetails_deleteModalMessage:
    'Sie sind dabei einen Bnutzer zu löschen.\nDieser Vorgang kann nicht rückgängig gemacht werden',
  userDetails_deleteModalRequest: 'Löschen',
  userDetails_deleteModalDismiss: 'Abbrechen',
  userDetails_submitModalTitle: 'Benutzer hinzufügen',
  userDetails_submitModalMessage: 'Wollen sie den Benutzer speichern?',
  userDetails_submitModalRequest: 'Speichern',
  userDetails_submitModalDismiss: 'Abbrechen',
  userDetails_changePermissionsDisclaimer: 'Dieser Benutzer muss sich erneut anmelden, damit die neuen Zugriffsrechte gültig sind.',

  help_title: 'Hilfe',
  help_subjectLabel: 'Betreff',
  help_subjectPlaceholder: 'Ihr Anliegen',
  help_recipientLabel: 'Empfänger',
  help_recipientPlaceholder: 'Wählen Sie die AVAG-Geschäftseinheit aus, an die Sie schreiben möchten',
  help_messageLabel: 'Ihre Nachricht',
  help_submitLabel: 'Nachricht senden',
  help_noteSectionTitle: 'Bitte beachten Sie',
  help_noteSectionDescription:
    'Ihre Nachricht wird so schnell wie möglich bearbeitet und wir werden Sie entsprechend kontaktieren. \n\nBitte beachten Sie, dass Nachrichten nur zu den Geschäftszeiten der AVAG bearbeitet werden können',
  help_confirmationTitle: 'Ihre Anfrage-ID',
  help_confirmationDescription: 'Das AVAG-Team wird sich so schnell wie möglich mit Ihnen in Verbindung setzen.',
  help_feedback: 'Feedback Kundenportal',
  help_noteSectionDescriptionNew: 'Erklärvideos und weitere Informationen unter:',
  help_noteSectionDescriptionNewDescription: 'Für weiterführende Fragen nutzen Sie am besten das Kontaktformular. Bitte beachten Sie, dass Ihre Nachricht nur während den Geschäftszeiten der AVAG bearbeitet werden kann. Wir werden Sie so rasch wie möglich kontaktieren.',
 

  settings_id: 'Klient-Nr',
  settings_client: 'Klient',
  settings_portal_authorization: 'Portal Berechtigung',
  settings_pickup_request: 'Auftrag erstellen',
  settings_bill: 'Rechnung',
  settings_search_placeholder: 'Nach Kunden suchen',

  button_show_all: 'Alles anzeigen',
  home_footer_show: 'Zeigt',
  home_footer_of: 'von',
  notifications: 'Benachrichtigungen',
  notifications_id: 'Benachrichtigung-Nr',
  notifications_date: 'Datum',
  notifications_message: 'Benachrichtigung',
  notifications_status: 'Status',
  notifications_tag_new: 'Neu',
  home_quotas_showLabel: 'Alle Kontingente',
  home_quotas_rowTitle: 'Oel/-Konzentrate',
  home_quotas_upToLabel: 'Gultig bis',
  home_quotas_Kontingentmenge: 'Kontingentmenge',
  home_quotas_Restmenge: 'Restmenge',
  home_quotas_Verwendete_menge: 'verwendete Menge',
  home_quotas_EGI: 'EGI-Nr',
  home_quotas_Beschreibung: 'Beschreibung',

  home_statistic_title: 'Statistiken für',
  home_statistic_radioButtonLabel: 'Statistik anzeigen nach',
  home_statistic_totalWeight: 'Gesamtgewicht',
  home_statistic_totalCost: 'Gesamtkosten',

  statistics_legend: 'Legende',
  statistics_costs: 'Gesamtkosten',
  statistics_weight: 'Gewicht',
  statistics_fraction: 'Material Art',
  statistics_credit: 'Gutschrift',
  statistics_fromDate: 'Von',
  statistics_toDate: 'Bis',
  statistics_filter: 'Filter',
  statistics_compare: 'Vergleichen',
  statistics_exitCompare: 'Exit-Vergleich',
  statistics_download: 'Daten herunterladen',
  statistics_material: 'Material',

  validations_loading: 'Loading...',
  validations_requiredField: 'Dies ist ein Pflichtfeld',
  validations_maxLength: 'Der Vorlagename kann nicht länger als :max: Zeichen haben',
  validation_maxLength_telephone: 'Die maximale Zeichenlänge beträgt :max: Zeichen.',
  validation_maxLength_bookingReference: 'Die maximale Zeichenlänge beträgt :max: Zeichen.',
  validation_maxLength_location: 'Der Behälterstandort kann nicht länger als :max: Zeichen haben',
  validation_maxlength_additionalComments: 'Die maximale Zeichenlänge beträgt :max: Zeichen.',
  validations_minLength: 'Kann nicht kürzer als :min: Zeichen sein',
  validations_maxStartDate: 'Das Startdatum darf nicht nach dem Enddatum liegen',
  validations_minEndDate: 'Das Enddatum darf nicht vor dem Startdatum liegen',
  validation_laterThan: 'Dies sollte später als :min: sein',
  validations_alreadyInUse: 'Diese E-Mailadresse wird schon verwendet',
  validations_greaterThan: 'Dies sollte größer als :min: sein',

  unsupportedBrowser_title: 'Unterstützen browser',
  unsupportedBrowser_modal: 'Sie verwenden einen nicht unterstützten Browser.',
  unsupportedBrowser_buttonInfo: 'Zusätzliche Informationen',
  unsupportedBrowser_buttonConfirm: 'Ok',
  unsupportedBrowser_moreInfo: 'Der von Ihnen verwendete Internetbrowser wird nicht vollständig unterstützt, was zu Fehlern führen kann. \n\nFür das bestmögliche Nutzungserlebnis bitten wir Sie, einen der nachstehenden Internetbrowser zu verwenden.',
  unsupportedBrowser_higher: 'oder höher',
  footer_impressum: 'AVAG Umwelt AG  \nAllmendstrasse 166 \n3600 Thun',

  maintenance_text: 'Das AVAG-Kundenportal ist zur Zeit wegen Wartungsarbeiten nicht zugänglich, wird aber in Kürze wieder erreichbar sein. In dringenden Fällen kontaktieren Sie uns telefonisch oder per Mail.\n\n • Sachbearbeiter*in:  +41 (33) 2265645       markt@avag.ch \n • Disponent*in:           +41 (33) 2265720       dispo@avag.ch',
  scheduledMaintenance: 'Geplante Wartung',

  pickup_and_template_back_to_overview_tab: 'zur Auftragsübersicht',
  template_tab1_blank_new: 'neuer Auftrag',
  template_tab2_template: 'Vorlagen öffnen',
  template_tab3_recurring_order: 'Dauerauftrag',
  template_modalBox_checkbox: 'Speichern Sie diesen Auftrag nach dem Platzieren als neue Vorlage.',
  template_modalBox_template_Name: 'Vorlagenname',
  template_modalBox_date_rule: 'Terminregler',
  template_modalBox_template_Description: 'Sie sind im Begriff, einen Auftrag aufzugeben.\n Der Vorgang kann nicht rückgängig gemacht werden.',
  template_modalBox_cancel: 'Nein',
  template_modalBox_placeOrder: 'Auftrag erteilen',
  template_create_button: 'Neue Vorlage erstellen',
  template_table_title: 'Vorlagen',
  template_table_created_by: 'Erstellungsdatum',
  template_table_lastused: 'zuletzt verwendeten',
  template_modalBox_save_title: 'Vorlage speichern unter',
  template_save_as_new: 'Vergeben Sie Ihrer Vorlage einen Namen, damit Sie diese bei der nächsten Ausführung einfacher wiederfinden.',
  template_save_as: 'Vorlage speichern',
  template_save_as_newTemplate: 'Als neu speichern',
  template_modal_delete_title: 'Vorlage löschen',
  template_modal_delete_description: 'Sind Sie sicher, dass Sie die folgende Vorlage nicht mehr benötigen?',
  template_modal_update_title: 'Vorlage aktualisieren',
  template_modal_update_description: 'Sind Sie sicher, dass Sie Ihre aktuellen Änderungen in der folgenden Vorlage speichern möchten?',
  template_place_order_description: 'Sie sind dabei einen Auftrag zu erteilen. Der Vorgang kann nicht rückgängig gemacht werden.',
  template_date_rule_datum_note: 'Automatisieren Sie mit dem Terminregler ihr künftiges Wunschdatum. Wochenende und Feiertage werden auf den nächstmöglichen Termin verschoben.',
  template_delete: 'Vorlage löschen',
  template_update: 'Vorlage aktualisieren',
  date_rule_datum_wahlen: 'Datum nach Auftragserteilung wählen',
  date_rule_haute: 'Heute',
  date_rule_haute_1: 'Heute +1 Arbeitstag',
  date_rule_haute_2: 'Heute +2 Arbeitstage',
  date_rule_haute_3: 'Heute +3 Arbeitstage',
  date_rule_haute_4: 'Heute +4 Arbeitstage',
  date_rule_haute_5: 'Heute +5 Arbeitstag',
  template_create_process_details_1: 'Sie haben derzeitig noch keine Vorlage gespeichert.',
  template_create_process_details_2: 'Häufig verwendete Aufträge lassen sich als Vorlage speichern und für zukünftige Aufträge wiederverwenden.',
  template_create_process_details_3: 'Wenn Sie eine neue Vorlage erstellen möchten, so klicken Sie auf die grüne Schaltfläche «Vorlage erstellen», füllen alle erforderlichen Felder aus, vergeben der Vorlage einen Namen und speichern Sie diese. Sie können mehrere Vorlagen erstellen, diese überarbeiten und löschen. Jede erstellte Vorlage erscheint in der Übersicht.',
  template_created_on: 'Erstellt am',
  template_created_by: 'von',
  template_view_all: 'Alle Vorlagen anzeigen',
  template_home_modal_text_description: 'Sie sind dabei, einen Auftrag zu erteilen. \nDer Vorgang kann nicht rückgängig gemacht werden.',
  template_view_details_link: 'Details anzeigen',
  template_aside_section_title: 'Wir sind bemüht, den Auftrag nach Ihren Wünschen auszuführen.',
  template_aside_section_header_1: 'Wunschdatum',
  template_aside_section_header_2: 'Terminregler',
  template_aside_section_Terminregler_detail: 'Automatisieren Sie mit dem Terminregler Ihr künftigen Auftrag. Der Terminregler bietet mehrere Optionen:',
  template_aside_section_Terminregler_list_1: 'Heute (Wunschdatum lautet auf denselben Tag)',
  template_aside_section_Terminregler_list_2: 'Heute + 2Tage (Wunschdatum lautet auf Übermorgen)',
  template_aside_section_Terminregler_list_3: 'Datum nach Auftragserteilung wählen (Wunschdatum muss immer gewählt werden)',
  template_aside_section_header_3: 'Behälter',
  template_aside_section_header_4: 'Datei',
  template_aside_section_Terminregler_list_4: 'In der Vorlage lassen sich keine Dateien speichern. Dateien müssen vor dem platzieren eines Auftrags vorher manuell in die Vorlage eingefügt werden',
  template_warning_message_modalBox: 'Diese Vorlage wurde in den letzten 2h bereits aufgegeben.',
  driver_order_title: 'Fahraufträge',
  driver_order_number: 'Fahrauftrags-Nr.',
  driver_order_drive_content: 'Fahrzeugtyp',
  driver_order_drive_delivery: 'Abholdatum',
  driver_order_status: 'Status',
  Kundenportal_video: 'Einblicke ins AVAG-Kundenportal'

};

export default de;
