import { LANGUAGE } from 'app/constants/localStorage';
import * as localStorage from 'app/utils/localStorage';
import { LangKeys } from '../lang/types';

interface TranslationFiles {
  [key: string]: Promise<{ default: LangKeys }>;
}

const translationFiles: TranslationFiles = {
  de: import('app/translations/lang/de'),
  en: import('app/translations/lang/en')
};

interface Translations {
  [key: string]: LangKeys;
}

const translations: Translations = {};

export const getApplicableLanguage = () => {
  // Get selected language
  const selectedLanguage = localStorage.getItem(LANGUAGE);

  // If it's not a valid language, select a default one
  const applicableLanguage = Object.keys(translationFiles).find(k => k === selectedLanguage) ? selectedLanguage : 'de';

  return applicableLanguage;
};

export const getTranslations = async () => {
  const applicableLanguage = getApplicableLanguage();

  // If translations have not been downloaded yet, download them
  if (!translations[applicableLanguage]) {
    translations[applicableLanguage] = (await translationFiles[applicableLanguage]).default;
  }

  // Return translations
  return translations[applicableLanguage];
};
