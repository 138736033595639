import React, { memo } from 'react';
import { useTranslationsContext } from 'app/translations';
import { PRIMARY, TAG_BACKGROUND } from 'base/styles/skin';
import { RendererProps } from 'components/Table/types';
import Tag from 'components/Tag';
import { NOTIFICATION_STATUS } from '../../types';

const TableTag = <R extends unknown>({ cell }: RendererProps<R>) => {
  const translations = useTranslationsContext();
  let color = TAG_BACKGROUND;
  let label = '';

  switch (cell) {
    case NOTIFICATION_STATUS.NEW:
      color = PRIMARY;
      label = translations.notifications_tag_new;
      break;

    default:
  }
  return cell && label && <Tag label={ label } color={ color } />;
};
export default memo(TableTag);
