import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RouteWithID } from 'app/types';
// import { ActionTypes } from '../actionTypes';
import * as actions from '../actions';

export const useTemplatesListRequest = (page: number, pageSize: number) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getTemplateList(page, pageSize));
  }, [page]);
};


export const useTemplatesReset = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.resetTemplates());
  }, []);
};

export const useTemplateRequest = (setHasLoadedDetails: (state: boolean) => void) => {
  const { id } = useParams<RouteWithID>();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let templatedata: any;
  useEffect(() => {
    if (id) {
      // @ts-ignore
      dispatch(actions.getTemplateDetailRequest(id)).then(({ type, payload }: TemplateRequestActionType) => {
        setHasLoadedDetails(true);
        // TODO: 
        // eslint-disable-next-line no-console
        // console.log('type, payload', type, payload);
        // if (type === ActionTypes.GET_TEMPLATE_DETAILS_SUCCESS) {
        //   dispatch(actions.processTemplateRequest(payload));
        // }  
        if (type.includes('SUCCESS')) { // added dummy templatedata to remove console warning
          templatedata = payload;
          // dispatch(actions.processTemplateRequest(payload));
        }
      });
    }
  }, [id]);
};
// export const useTemplateRequest = (setHasLoadedDetails: (state: boolean) => void) => {
//   const { id } = useParams<RouteWithID>();
//   const dispatch = useDispatch();
//   useEffect(() => {
//     if (id) {
//       setHasLoadedDetails(true);
//       dispatch(actions.getTemplateDetailRequest(id));
//     }
//   }, [id]);
// };