import React from 'react';
import { useTranslationsContext } from 'app/translations';
import { formatDate } from 'app/utils/formatters';
import AvagDot from 'components/AvagDot';
import Card from 'components/Card';
import HelpButton from 'components/HelpButton';
import StatusMessage from 'components/StatusMessage';
import { Column } from 'components/Table/types';
import { createRowFromBillItem } from 'containers/Bills/models';
import { Bill, BillDocumentType, BillDetailsRow } from 'containers/Bills/types';
import { HelpTopic } from 'containers/Help/types';
import Tag from '../../../BillList/components/Tag';
import CustomerInfo from '../CustomerInfo';
import PrintInvoiceButton from '../PrintInvoiceButton';
import TotalSection from '../TotalSection';
import { SectionWrapper, WrapperTitle, Title, BillNumber, Table } from './styles';

interface Props {
  bill: Bill;
  dueDate?: number;
  itemsHeader: Column<BillDetailsRow>[];
  onPrintBill: () => Function;
}

const View: React.FC<Props> = ({ bill, itemsHeader, dueDate, onPrintBill }) => {
  const translations = useTranslationsContext();

  const { id, items, status, customerId, VATnumber, billingAddress, totalAmount, companyResponse } = bill;

  const detailTitle = bill.documentType === BillDocumentType.CREDIT
    ? translations.billDetails_creditNoteNumberTitle
    : translations.billDetails_billNumberTitle;

  return (
    <>
      <SectionWrapper>
        <WrapperTitle>
          <AvagDot />
          <Title>
            { detailTitle }
            { ':' }
            <BillNumber>{ id }</BillNumber>
            <Tag cell={ status }></Tag>
          </Title>
          <HelpButton topic={ HelpTopic.BILLS } />
        </WrapperTitle>

        { dueDate && (
          <StatusMessage
            title={ `${ translations.billDetails_dueDateTitle }:` }
            description={ `${ translations.billDetails_dueDateDescription.replace(
              ':billDocType',
              bill.documentType === BillDocumentType.CREDIT ? translations.creditNote : translations.bill
            ) } ${ formatDate(new Date(dueDate)) }` }
            showIcon={ false }
            inlineText={ true }
          ></StatusMessage>
        ) }
      </SectionWrapper>

      <SectionWrapper>
        <WrapperTitle>
          <AvagDot />
          <Title>{ translations.billDetails_billInfoTitle }</Title>
        </WrapperTitle>

        <Card indentation={ false }>
          <CustomerInfo
            billingAddress={ billingAddress }
            customerId={ customerId }
            vatId={ VATnumber }
            // contactInfo={ contactInfo }
            companyResponse={ companyResponse }
          />
          <Table<BillDetailsRow>
            columns={ itemsHeader }
            rows={ items.map(createRowFromBillItem) }
            sortEnabled={ false }
            showTitle={ false }
            // onClick={ handleSelectRow } TODO waiting for backend to provide correct params (AMP-57)
          ></Table>
        </Card>
      </SectionWrapper>

      { totalAmount && (
        <SectionWrapper>
          <TotalSection totalAmount={ totalAmount } />
        </SectionWrapper>
      ) }

      <PrintInvoiceButton onClick={ onPrintBill } />
    </>
  );
};

export default View;
