import React, { PropsWithChildren } from 'react';

import { Container, Header, Body, Footer } from './styles';

interface Props {
  className?: string;
  footer?: React.ReactNode;
  title?: React.ReactNode;
  indentation?: boolean;
  overflow?: boolean;
}
// eslint-disable-next-line max-len
const Card: React.FC<PropsWithChildren<Props>> = ({ className, children, footer, title, indentation = true, overflow = false }) => (
  <Container style={ { overflow: overflow ? 'auto' : 'initial' } } className={ className }>
    { title && <Header>{ title }</Header> }
    <Body indentation={ indentation }>{ children }</Body>
    { footer && <Footer>{ footer }</Footer> }
  </Container>
);

export default Card;
