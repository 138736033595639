import React from 'react';
import { Wrapper, FirstLine, SecondLine, ThirdLine } from './styles';

interface Props {
  animated?: boolean;
  handleClick: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}

const Hamburger: React.FC<Props> = ({ animated = true, handleClick, isOpen }) => (
  <Wrapper
    onClick={ () => {
      handleClick(!isOpen);
    } }
  >
    <FirstLine active={ isOpen } animated={ animated } />
    <SecondLine active={ isOpen } animated={ animated } />
    <ThirdLine active={ isOpen } animated={ animated } />
  </Wrapper>
);

export default Hamburger;
