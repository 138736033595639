import React, { PropsWithChildren } from 'react';

import { Container, Row } from './styles';

interface ItemProps {
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
}
const ListItem: React.FC<PropsWithChildren<ItemProps>> = ({ children, onClick }) => (
  <Row onClick={ onClick }>{ children }</Row>
);

interface ChildrenProps {
  ListItem: typeof ListItem;
}
interface Props {
  children: (props: ChildrenProps) => React.ReactNode;
}
const List: React.FC<Props> = ({ children }) => <Container>{ children({ ListItem }) }</Container>;

export default List;
