import React, { PropsWithChildren } from 'react';
import { Size } from 'app/types';
import { Wrapper, Content } from './styles';

interface Props {
  className?: string;
  size?: Size;
}

const Page: React.FC<PropsWithChildren<Props>> = ({ children, className, size = 'lg' }) => (
  <Wrapper className={ className }>
    <Content size={ size }>{ children }</Content>
  </Wrapper>
);

export default Page;
