import { http, envConf, isMockingApi } from 'base';
import { SESSION_TOKEN } from 'app/constants/localStorage';
import { decodeLoginToken } from 'app/utils/encoding';
import * as localStorage from 'app/utils/localStorage';
import { handleUnauthorizedError } from 'app/utils/connection-errors';
import { createUserFromServer } from '../models/fromServer';
import { createLoginToServer } from '../models/toServer';
import { LoggedUser, LoginRequest } from '../types';

let authInterceptor: number;
let authErrorHandler: number;

export const addBearer = (token: string) => {
  authInterceptor = http.interceptors.request.use(config => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${ token }`
    }
  }));

  authErrorHandler = http.interceptors.response.use(
    res => res,
    error => handleUnauthorizedError(error)
  );
};

export const removeBearer = () => {
  http.interceptors.request.eject(authInterceptor);
  http.interceptors.response.eject(authErrorHandler);
};

export const loginRequest = async (loginForm: LoginRequest): Promise<LoggedUser | undefined> => {
  try {
    const loginToServer = createLoginToServer(loginForm);
    const { data } = await http.post<string>(envConf.API.LOGIN_POST, { ...loginToServer });

    // Persist user's data
    localStorage.setItem(SESSION_TOKEN, data);

    // Add authentication layer
    addBearer(data);

    return createUserFromServer(decodeLoginToken(data));
  } catch (error) {
    if (isMockingApi()) {
      throw new Error(JSON.stringify(loginForm));
    } else {
      throw new Error(error?.response?.data?.message || error);
    }
  }
};

export const logoutRequest = async (): Promise<void> => {
  // Remove user's data
  localStorage.removeItem(SESSION_TOKEN);

  try {
    // Perform a logout request
    await http.post(envConf.API.LOGOUT_POST);

    // Remove Auth layer after logout request has succeed
    removeBearer();
  } catch (error) {
    // Remove Auth layer after logout request has errored
    removeBearer();

    console.error(error);
    throw new Error(error);
  }
};
