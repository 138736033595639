import { Action } from 'app/types';

export enum ActionTypes {
  GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST',
  GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS',
  GET_USER_LIST_ERROR = 'GET_USER_LIST_ERROR',

  GET_USER_REQUEST = 'GET_USER_REQUEST',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_ERROR = 'GET_USER_ERROR',

  CREATE_USER_REQUEST = 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR = 'CREATE_USER_ERROR',

  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',

  DELETE_USER_REQUEST = 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR = 'DELETE_USER_ERROR',

  RESET_USER_LIST = 'RESET_USER_LIST'
}

type GetUserListRequest = Action<ActionTypes.GET_USER_LIST_REQUEST>;
type GetUserListSuccess = Action<ActionTypes.GET_USER_LIST_SUCCESS>;
type GetUserListError = Action<ActionTypes.GET_USER_LIST_ERROR>;

type GetUserRequest = Action<ActionTypes.GET_USER_REQUEST>;
type GetUserSuccess = Action<ActionTypes.GET_USER_SUCCESS>;
type GetUserError = Action<ActionTypes.GET_USER_ERROR>;

type CreateUserRequest = Action<ActionTypes.CREATE_USER_REQUEST>;
type CreateUserSuccess = Action<ActionTypes.CREATE_USER_SUCCESS>;
type CreateUserError = Action<ActionTypes.CREATE_USER_ERROR>;

type UpdateUserRequest = Action<ActionTypes.UPDATE_USER_REQUEST>;
type UpdateUserSuccess = Action<ActionTypes.UPDATE_USER_SUCCESS>;
type UpdateUserError = Action<ActionTypes.UPDATE_USER_ERROR>;

type DeleteUserRequest = Action<ActionTypes.DELETE_USER_REQUEST>;
type DeleteUserSuccess = Action<ActionTypes.DELETE_USER_SUCCESS>;
type DeleteUserError = Action<ActionTypes.DELETE_USER_ERROR>;

type ResetUserList = Action<ActionTypes.RESET_USER_LIST>;

type GetUserListActionType = GetUserListRequest | GetUserListSuccess | GetUserListError;
type GetUserActionType = GetUserRequest | GetUserSuccess | GetUserError;
type CreateUserActionType = CreateUserRequest | CreateUserSuccess | CreateUserError;
type UpdateUserActionType = UpdateUserRequest | UpdateUserSuccess | UpdateUserError;
type DeleteUserActionType = DeleteUserRequest | DeleteUserSuccess | DeleteUserError;

export type UserActionType =
  | GetUserListActionType
  | GetUserActionType
  | CreateUserActionType
  | UpdateUserActionType
  | DeleteUserActionType
  | ResetUserList;
