import { ROLE, getRoleName } from 'app/constants/roles';
import { createPaginationFromServer } from 'app/models';
import { LoggedUser } from 'containers/Login/types';
import { isAvagID } from 'app/utils/permissions';
import { canEditUser } from '../containers/utils';
import { UsersState, User, UserRow, UserStatus } from '../types';

export * from './fromServer';
export * from './toServer';

export const createRowFromUser = (
  { companyId, date, email, fullName, id, role, status }: User,
  { user_id, role: user_role }: LoggedUser,
  companyName?: string
): UserRow => ({
  company: isAvagID(companyId) ? companyName : `${ companyName } - ${ companyId }`,
  date,
  email,
  fullName,
  id,
  locked: !canEditUser({ id, role }, { user_id, user_role }),
  navigate: id,
  role: getRoleName(role),
  status
});

export const createUserFromLoggedUser = ({ client_id,
  client_name,
  first_name,
  last_name,
  role,
  user_email,
  user_id,
  permissions,
  companyPermissions }: LoggedUser): User => ({
  companyId: client_id,
  companyName: client_name,
  companyPermissions,
  email: user_email,
  firstName: first_name,
  lastName: last_name,
  fullName: `${ first_name } ${ last_name }`,
  id: user_id,
  permissions,
  role,
  status: UserStatus.USER
});

export const userInitialState: UsersState = {
  collection: [],
  pagination: createPaginationFromServer({})
};

export const userDetailsInitialState: User = {
  id: '',
  date: new Date().getTime(),
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  companyId: '',
  companyName: '',
  role: ROLE.CLIENT_OPERATOR,
  permissions: {
    requestPickup: false,
    accessBills: false
  },
  companyPermissions: {
    portal: true,
    requestPickup: false,
    accessBills: false
  },
  status: -1
};
