import styled from 'styled-components';

import { LINE_SEPARATOR_COLOR, TEXT_COLOR } from 'base/styles/skin';
import { TABLET } from 'base/styles/media-queries';

export const Wrapper = styled.header`
  align-items: center;
  border-bottom: solid 1px ${ LINE_SEPARATOR_COLOR };
  color: ${ TEXT_COLOR };
  display: block;
  flex: 1;
  flex-direction: column;
  //font-family: Roboto;
  font-family: 'TT Norms';
  font-size: 14px;
  justify-content: flex-end;

  ${ TABLET } {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.div`
  flex: none;
`;
Label.displayName = 'Label';

export const DropdownWrappers = styled.div`
  display: flex;
  flex: 2;
  width: 100%;

  > div {
    padding-right: 10px;
  }

  ${ TABLET } {
    width: auto;
  }
`;
DropdownWrappers.displayName = 'DropdownWrappers';

export const DimensionWrappers = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  > div {
    display: flex;
    > label {
      padding-right: 10px;
    }
  }

  ${ TABLET } {
    padding-top: 22px;
    width: 100%;
    display: flex;
    justify-content: space-around;

    > div {
      justify-content: space-around;
      > label {
        padding-right: auto;
      }
    }
  }
`;
DimensionWrappers.displayName = 'DimensionWrappers';
