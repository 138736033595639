export const WHITE_1 = '#ffffff';
export const WHITE_2 = '#f4f4f4';

export const GRAY_1 = 'lightgray';
export const GRAY_2 = 'gray';
export const GRAY_3 = 'darkgray';

export const GREY_1 = '#cbcbcb';
export const GREY_2 = '#ddd';
export const GREY_3 = '#d3d3d3';
export const GREY_4 = '#ccc';
export const GREY_5 = '#999';
export const GREY_6 = '#898989';
export const GREY_7 = '#666';
export const GREY_8 = '#333';
export const GREY_9 = '#979797';
export const GREY_10 = '#111';
export const GREY_11 = '#4a4a4a';
export const GREY_12 = '#c9c9c9';
export const GREY_13 = '#c5c9c3';
export const GREY_14 = '#d8d8d8';
export const GREY_15 = '#eee';
export const GREY_16 = '#e0dcd8';
export const GREY_17 = '#afb0b1';

export const PALE_GREY = '#f5f7fb';

export const BLACK = '#000';
export const BLACK_2 = '#3a3a3a';

export const ORANGE_RED = '#ff3b30';

export const RED_1 = '#ff0000';
export const RED_2 = '#da291c';
export const RED_3 = '#f3b5b4';
export const RED_4 = '#c80000';
export const RED_5 = '#640000';

export const PINK_PASTEL = '#e3c0bd';
export const PINK_1 = '#ff3b67';

export const AQUA_1 = '#64ccc9';

export const BLUE_1 = '#007aff';
export const BLUE_2 = '#0c2340';
export const BLUE_3 = '#0c2340';
export const BLUE_4 = '#00bdc6';
export const BLUE_5 = '#062356';
export const BLUE_6 = '#2bdbe3';
export const LIGHT_BLUE = '#e6ecf8';
export const BLUE_COGNIZANT = '#1c33a1';

export const TURQUOISE_1 = '#37a8a4';

export const YELLOW_1 = '#EEB608';

export const ORANGE_1 = '#ff9900';

export const AVAG_GREEN = '#5fa550';
export const GREEN_1 = '#508436';
export const GREEN_2 = '#3fbc61';
export const GREEN_3 = '#447d85';
export const DARK_GREEN_1 = '#324632';
export const SAND = '#EBE6D7';

export const BRAND_COLOR = AVAG_GREEN;

export const BLACK_TRANSPARENT = 'rgba(51, 51, 51, 0.85)';

// eslint-disable-next-line max-len
export const SHADOW = '0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2)';
