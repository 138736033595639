import { useTranslationsContext } from 'app/translations';
import { MODALS, ModalData } from '../containers/Details/types';

export const useModalData = (openModal: MODALS): ModalData => {
  const translations = useTranslationsContext();

  switch (openModal) {
    case MODALS.DELETE:
      return {
        title: translations.userDetails_deleteModalTitle,
        description: translations.userDetails_deleteModalMessage,
        confirmLabel: translations.userDetails_deleteModalRequest,
        dismissLabel: translations.userDetails_deleteModalDismiss
      };

    case MODALS.PASSWORD:
      return {
        title: translations.userDetails_changePassword,
        description: translations.forgotPassword_decriptionSuccess,
        confirmLabel: translations.userDetails_deleteModalDismiss,
        dismissLabel: undefined
      };

    case MODALS.SUBMIT:
      return {
        title: translations.userDetails_submitModalTitle,
        description: translations.userDetails_submitModalMessage,
        confirmLabel: translations.userDetails_submitModalRequest,
        dismissLabel: translations.userDetails_submitModalDismiss
      };

    default:
      return {
        title: '',
        description: '',
        confirmLabel: '',
        dismissLabel: ''
      };
  }
};
