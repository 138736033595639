import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DimensionChartOptions } from 'app/constants/statistic';
import * as actions from 'containers/Statistics/actions';
import View from './components/View';
import { isComparisonMode } from '../selectors';

const Statistics: React.FC = () => {
  const [dimension, setDimension] = useState(DimensionChartOptions.costWeight);
  const isComparing = useSelector(isComparisonMode);
  const dispatch = useDispatch();

  const disableComparison = useCallback(() => {
    dispatch(actions.disableComparision());
  }, []);

  const enableComparison = useCallback(() => {
    dispatch(actions.enableComparision());
  }, []);

  return (
    <View
      dimension={ dimension }
      isComparing={ isComparing }
      onChangeDimension={ setDimension }
      onDisableComparison={ disableComparison }
      onEnableComparison={ enableComparison }
    />
  );
};

export default Statistics;
