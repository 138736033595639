/* eslint-disable max-lines */
import { http, envConf } from 'base';
import { PickupAttachedFile, PickupRequestStatus } from 'app/constants/pickupRequest';
import { createPaginationFromServer } from 'app/models';
import { handleListError } from 'app/utils/connection-errors';
import { Pagination } from 'app/types';
import { getFilesToUpload } from 'app/utils/fields';
import {
  PickupRequest,
  ContainerResponse,
  Material,
  Container,
  PickupRequestParams,
  TemplateUpdateRequest, ResendPickupRequest
} from 'containers/PickupRequest/types';
import { createContainerListFromServer, createMaterialListFromServer } from '../models';
import * as Model from '../models/fromServer';
import { createPickupRequestToServer,
  createNewTemplateRequestToServer,resendPickupRequestToServer } from '../models/toServer';

export const getRecentContainerList = async (companyId: string): Promise<ContainerResponse> => {
  try {
    const { data } = await http.get(envConf.API.RECENT_CONTAINERS_GET.replace(':companyId', companyId));
    return createContainerListFromServer(data, false);
  } catch (error) {
    const { collection } = handleListError<Container>(error);

    return {
      containers: collection,
      types: []
    };
  }
};

export const getContainerList = async (companyId: string): Promise<ContainerResponse> => {
  try {
    const { data } = await http.get(envConf.API.CONTAINERS_GET.replace(':companyId', companyId));
    return createContainerListFromServer(data);
  } catch (error) {
    const { collection } = handleListError<Container>(error);

    return {
      containers: collection,
      types: []
    };
  }
};

export const getMaterialList = async (containerId: string, companyId: string): Promise<Material[] | undefined> => {
  try {
    const { data } = await http.get(
      envConf.API.MATERIALS_GET.replace(':containerId', containerId).replace(':companyId', companyId)
    );
    return createMaterialListFromServer(data);
  } catch (error) {
    return handleListError<Material>(error)?.collection;
  }
};

interface PickupRequestResponse {
  collection: PickupRequest[];
  pagination: Pagination;
}

export const getPickupRequestList = async (
  pickupParams: PickupRequestParams
): Promise<PickupRequestResponse> => {
  const { limit, offset, order, rowname, filterValue } = pickupParams;
  try {
    const { data } = await http.get(envConf.API.PICKUP_REQUEST_LIST_GET, {
      params: { limit, offset, order, rowname, filterValue }
    });
 
    const { pickup_details, ...pagination } = data;

    return {
      collection: Model.createPickupRequestListFromServer(pickup_details),
      pagination: createPaginationFromServer({ ...pagination, offset: Math.round(offset / limit) })
    };
  } catch (error) {
    return handleListError<PickupRequest>(error);
  }
};

export const getPickupRequest = async (id: string): Promise<PickupRequest> => {
  try {
    const { data } = await http.get(envConf.API.PICKUP_REQUEST_GET.replace(':id', id));
    return Model.createPickupRequestFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const uploadFilesRequest = async (files: File[], pickupId: string, fileCategoryId: number) => {
  try {
    if (files.length) {
      const formData = new FormData();
      files.forEach(file => {
        formData.append('file', file);
      });

      const apiURL = envConf.API.UPLOAD_FILE_POST.replace(':pickupId', pickupId).replace(
        ':fileCategoryId',
        String(fileCategoryId)
      );

      await http.post(apiURL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

const uploadPickupFiles = (pickupRequest: PickupRequest | TemplateUpdateRequest, pickupId: string): Promise<void[]> => {
  if (pickupId) {
    const commentaryFiles = getFilesToUpload(pickupRequest.commentary.attachedFiles);
    const driverInfoFiles = getFilesToUpload(pickupRequest.driverInformation.attachedFiles);
    return Promise.all([
      uploadFilesRequest(commentaryFiles, pickupId, PickupAttachedFile.COMMENTARY),
      uploadFilesRequest(driverInfoFiles, pickupId, PickupAttachedFile.DRIVER_INFO)
    ]);
  }

  return Promise.all([]);
};

export const createPickupRequest = async (
  pickupRequest: PickupRequest,
  isAdmin: boolean,
  status: PickupRequestStatus
): Promise<PickupRequest> => {
  try {
    const pickupToServer = createPickupRequestToServer({ ...pickupRequest, status }, isAdmin);
    const { data } = await http.post(envConf.API.PICKUP_REQUEST_POST, { ...pickupToServer });

    await uploadPickupFiles(pickupRequest, data.pickup_id);

    return Model.createPickupRequestFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const updatePickupRequest = async (
  pickupRequest: PickupRequest,
  isAdmin: boolean,
  status: PickupRequestStatus
): Promise<PickupRequest> => {
  try {
    const { id } = pickupRequest;
    const pickupToServer = createPickupRequestToServer({ ...pickupRequest, status }, isAdmin);
    const { data } = await http.patch(envConf.API.PICKUP_REQUEST_PUT.replace(':id', id), pickupToServer);

    await uploadPickupFiles(pickupRequest, id);

    return Model.createPickupRequestFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const processPickupRequest = async (pickupRequest: PickupRequest): Promise<PickupRequest> => {
  try {
    const { id } = pickupRequest;
    const pickupToServer = createPickupRequestToServer(
      { ...pickupRequest, status: PickupRequestStatus.IN_PROCESS },
      true
    );
    const { data } = await http.patch(envConf.API.PICKUP_REQUEST_PATCH.replace(':id', id), pickupToServer);
    return Model.createPickupRequestFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const deletePickupRequest = async (id: string): Promise<PickupRequest> => {
  try {
    const { data } = await http.delete(envConf.API.PICKUP_REQUEST_DELETE.replace(':id', id));
    return data;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const printPickupRequest = async (id: string): Promise<string> => {
  try {
    const { data } = await http.get(envConf.API.PICKUP_PRINT_REQUEST_GET.replace(':id', id));
    return data.response;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const resendPickupRequest = async (request: ResendPickupRequest): Promise<ResendPickupRequest> => {
  try {
    const { data } = await http.post(envConf.API.PICKUP_RESEND_REQUEST_POST, request);

    //@TODO Testing if correct
    return Model.createPickupRequestFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};


export const saveNewTemplateRequest = async (
  pickupRequest: PickupRequest,
  isAdmin: boolean
): Promise<PickupRequest> => {

  try {
    const pickupToServer = createNewTemplateRequestToServer({ ...pickupRequest }, isAdmin);
    const { data } = await http.post(envConf.API.TEMPLATE_CREATE_POST, { ...pickupToServer });
    await uploadPickupFiles(pickupRequest, data.pickup_id);
    return Model.createPickupRequestFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
