import React, { memo } from 'react';
import { useTranslationsContext } from 'app/translations';
import { RendererProps } from 'components/Table/types';
import Tag from 'components/Tag';

import { PRIMARY, TAG_BACKGROUND } from 'base/styles/skin';
import { BillStatus } from 'containers/Bills/types';

const TableTag = <R extends unknown>({ cell }: RendererProps<R>) => {
  const translations = useTranslationsContext();
  let color = TAG_BACKGROUND;
  let label = '';
  switch (cell) {
    case BillStatus.NEW:
      color = PRIMARY;
      label = translations.billsList_tag_new;
      break;
    case BillStatus.PAID:
      label = translations.billsList_tag_paid;
      break;
    default:
      color = TAG_BACKGROUND;
  }
  return cell && label && <Tag label={ label } color={ color } />;
};
export default memo(TableTag);
