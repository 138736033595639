import React from 'react';
import { ResponsiveContainer,
  AreaChart as AChart,
  XAxis as AXAxis,
  YAxis as AYAxis,
  Tooltip,
  Area,
  TooltipProps } from 'recharts';
import { AREA_CHART_FILL, AREA_CHART_STROKE } from 'base/styles/skin';
import { MIN_AREA_ASPECT } from 'app/constants/statistic';
import { isWeightChart } from 'app/utils/chart';
import { ChartData, ChartMetadata } from 'containers/Statistics/types';
import { Container, YAxis, YAxisLabel } from './styles';
import { formatAxisData, calculateDateTicks } from './tools';

interface Props {
  axisLabels: string[];
  color: string;
  containerHeight: number;
  data: ChartData[];
  meta: ChartMetadata;
  metricUnits: string[];
  tooltip: React.FC<any>;
}

const AreaChart: React.FC<Props> = ({ axisLabels,
  color,
  containerHeight,
  data,
  metricUnits,
  tooltip: CustomTooltip }) => {
  const [weightLabel, costLabel, creditLabel] = axisLabels;
  const isWeight = isWeightChart(metricUnits);

  const dataKey: keyof ChartData = 'date';
  const ticks = calculateDateTicks(data);

  return (
    <Container>
      <YAxis>
        <YAxisLabel>{ isWeight ? weightLabel : costLabel }</YAxisLabel>
        { !isWeight && <YAxisLabel>{ creditLabel }</YAxisLabel> }
      </YAxis>

      <ResponsiveContainer width="100%" maxHeight={ containerHeight } aspect={ MIN_AREA_ASPECT }>
        <AChart data={ data } margin={ { top: 20, right: 30, left: -30, bottom: 20 } }>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={ color || AREA_CHART_STROKE } stopOpacity={ 0.8 } />
              <stop offset="95%" stopColor={ color || AREA_CHART_FILL } stopOpacity={ 0 } />
            </linearGradient>
          </defs>

          <AXAxis
            dataKey={ dataKey }
            minTickGap={ 20 }
            tickMargin={ 10 }
            type="number"
            interval="preserveStartEnd"
            domain={ [ticks[0], ticks[ticks.length - 1]] }
            ticks={ ticks }
            tickCount={ 5 }
            tickFormatter={ formatAxisData }
          />
          <AYAxis tickFormatter={ () => '' } tickLine={ false } />

          <Tooltip
            content={ ({ payload, active }: TooltipProps) => (
              <CustomTooltip payload={ payload } active={ active } isAreaChart={ true } metricUnits={ metricUnits } />
            ) }
          />

          <Area type="monotone" dataKey="height" stroke={ color } fillOpacity={ 1 } fill="url(#colorUv)" />
        </AChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default AreaChart;
