import { PICKUP_REQUEST, 
  DELIVERY_SLIP, 
  DELIVERY_SLIP_LIST, 
  BILL, 
  BILL_LIST, 
  USER } from 'app/routes';
import { Notification, NOTIFICATION_TYPE } from '../types';

interface NotificationData {
  entityId: Notification['entityId'];
  entityType: Notification['entityType'];
  extras: Notification['extras'];
}

export const navigate = (history: any, { entityId, entityType, extras }: NotificationData) => {
  switch (entityType) {
    case NOTIFICATION_TYPE.PICKUP:
      history.push(PICKUP_REQUEST.path.replace(':id', entityId));
      break;

    case NOTIFICATION_TYPE.DELIVERY:
      if (extras) {
        history.push(
          DELIVERY_SLIP.path
            .replace(':id', entityId)
            .replace(':docNo', extras?.docNo)
            .replace(':versionNo', extras?.versionNo)
        );
      } else {
        history.push(DELIVERY_SLIP_LIST.path);
      }
      break;
      
    case NOTIFICATION_TYPE.BILL:
      if (extras) {
        history.push(BILL.path
          .replace(':id', entityId)
          .replace(':docType', extras?.docType));
      } else {
        history.push(BILL_LIST.path);
      }
      break;

    case NOTIFICATION_TYPE.USER:
      history.push(USER.path.replace(':id', entityId));
      break;

    default:
  }
};
