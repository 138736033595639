import * as api from '../api';
import { ActionTypes, StatisticsActionType } from '../actionTypes';

export const getStatistics = (fromDate: Date, toDate: Date, material?: string): StatisticsActionType => ({
  type: ActionTypes.GET_STATISTICS_REQUEST,
  request: api.getStatistics(fromDate, toDate, material),
  requestName: 'get-statistics'
});

export const getStatisticsComparision = (fromDate: Date, toDate: Date, material?: string): StatisticsActionType => ({
  type: ActionTypes.GET_STATISTICS_COMPARISION_REQUEST,
  request: api.getStatistics(fromDate, toDate, material),
  requestName: 'compare-statistics'
});

export const enableComparision = (): StatisticsActionType => ({
  type: ActionTypes.ENABLE_COMPARISION
});

export const disableComparision = (): StatisticsActionType => ({
  type: ActionTypes.DISABLE_COMPARISION
});

export const getStatisticsMaterials = () => ({
  type: ActionTypes.GET_STATISTICS_MATERIALS_REQUEST,
  request: api.getMaterials(),
  requestName: 'get-statistics-meta'
});

export const resetStatistics = (): StatisticsActionType => ({
  type: ActionTypes.RESET_STATISTICS
});

export const resetStatisticsComparision = (): StatisticsActionType => ({
  type: ActionTypes.RESET_STATISTICS_COMPARISION
});
