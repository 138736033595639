import styled from 'styled-components';

import { PRIMARY } from 'base/styles/skin';

export const Rectangle = styled.div`
  display: inline-block;
  min-width: 18px;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  background-color: ${ PRIMARY };
    display: none;
`;
Rectangle.displayName = 'Rectangle';
