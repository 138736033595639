import styled from 'styled-components';
import { CHART_LABEL_COLOR } from 'base/styles/skin';
import { MIN_BAR_HEIGHT } from 'app/constants/statistic';
import { RefObject } from 'react';
import { DESKTOP } from 'base/styles/media-queries';

interface BarGroupProps {
  heightPercentage: number;
  isPositive?: boolean;
  totalWidth?: number;
  isBigger?: number;
}

export const Container = styled.div`
  position: relative;
  align-items: center;
  align-self: flex-end;
  display: flex;
  flex: 1;
  height: ${ ({ heightPercentage }: BarGroupProps) => heightPercentage }px;
  justify-content: center;
  overflow: hidden;
`;
Container.displayName = 'Container';

export const Content = styled.div`
  flex: 1;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;
  position: relative;
`;
Content.displayName = 'Content';

interface XAxisProps {
  contentRef: RefObject<HTMLDivElement>;
  top: number;
  totalWidth: number;
}

export const XAxis = styled.div`
  background-color: orange;
  height: ${ MIN_BAR_HEIGHT }px;
  min-width: calc(100% + 30px);
  opacity: 0.5;
  position: absolute;
  // top: calc(${ ({ top }: XAxisProps) => top }% - ${ MIN_BAR_HEIGHT }px);
  top: ${ ({ contentRef, top, totalWidth }: XAxisProps) => {
    const SCROLLBAR_HEIGHT = 20;
    const isBiggerContentThanWrapper = contentRef.current?.offsetWidth < totalWidth;

    if (top >= 100) {
      if (isBiggerContentThanWrapper) {
        return `calc(${ top }% - ${ MIN_BAR_HEIGHT }px - ${ SCROLLBAR_HEIGHT / 1.333333333 }px)`;
      }

      return `calc(${ top }% - ${ MIN_BAR_HEIGHT }px)`;
    }

    if (isBiggerContentThanWrapper) {
      return `calc(${ top }% - ${ SCROLLBAR_HEIGHT / 2 }px)`;
    }

    return `${ top }%`;
  } };
  transition: top 1s, width 1s;
  width: calc(100% + 30px);
`;
XAxis.displayName = 'XAxis';

interface XAxisLabelProps {
  top: number;
}

export const XAxisLegend = styled.span`
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  border-radius: 4px;
  color: ${ CHART_LABEL_COLOR };
  // display: flex;
  //font-family: Roboto;
  font-family: 'TT Norms';
  font-size: 13.5px;
  padding: 3px;
  margin: 8px;
  margin-right: 0;
  position: absolute;
  right: 0;
  top: ${ ({ top }: XAxisLabelProps) => (top > 90 ? 90 : top) }%;
  transform: translateY(-7px);
`;

export const YAxis = styled.div`
  border-right: solid 1px lightslategrey;
  //font-family: Roboto;
  font-family: 'TT Norms';
  font-size: 13.5px;
  height: 100%;
  max-width: 30px;
  position: relative;
  overflow: hidden;
  width: 30px;
`;
YAxis.displayName = 'YAxis';

interface YAxisLabelProps {
  isTop?: boolean;
}

export const YAxisLabel = styled.div`
  color: ${ CHART_LABEL_COLOR };
  display: block;
  //font-family: Roboto;
  font-family: 'TT Norms';
  font-size: 13.5px;
  height: 50%;
  position: relative;
  width: 100%;

  & > span {
    bottom: ${ ({ isTop }: YAxisLabelProps) => !isTop && '20%' };
    display: block;
    left: 50%;
    position: absolute;
    top: ${ ({ isTop }: YAxisLabelProps) => isTop && '0' };
    transform-origin: 0 0;
    transform: rotate(-90deg) translateX(${ ({ isTop }: YAxisLabelProps) => (isTop ? '-100%' : '-15px') })
      translateY(-50%);
    width: max-content;
  }
`;
YAxisLabel.displayName = 'YAxisLabel';

export const BarGroup = styled.div`
  align-items: ${ ({ isPositive }: BarGroupProps) => (isPositive ? 'flex-end' : 'flex-start') };
  display: flex;
  height: ${ ({ heightPercentage, isPositive }: BarGroupProps) => {
    if (heightPercentage >= 100 && isPositive) {
      return `${ heightPercentage }%`;
    }

    if (isPositive) {
      return `calc(${ heightPercentage }% + ${ MIN_BAR_HEIGHT }px)`;
    }

    return `${ heightPercentage }%`;
  } };
  margin-top: ${ ({ isPositive }: BarGroupProps) => !isPositive && `-${ MIN_BAR_HEIGHT }px` };
  padding-bottom: ${ ({ isPositive }: BarGroupProps) => isPositive && '15px' };
  max-height: 100%;
  min-width: 100%;
  width: ${ ({ totalWidth, isBigger }: BarGroupProps) => (totalWidth / isBigger) || 5 }px;
  position: relative;
  transition: height 1s, width 1s;

  ${ DESKTOP } {
    margin-top: ${ ({ isPositive }: BarGroupProps) => !isPositive && '0' };
    padding-bottom: ${ ({ isPositive }: BarGroupProps) => isPositive && '3px' };
  }
`;
BarGroup.displayName = 'BarGroup';
