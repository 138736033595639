import React, { useCallback, /* ChangeEvent, */ useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIsLoading } from 'app/hooks';
import { BILL } from 'app/routes';
import { useTranslationsContext } from 'app/translations';
import debounce from 'app/utils/debounce';
// import InputFilter from 'components/InputFilter';
import { TableSkeleton } from 'components/Skeletons';
import { FieldProps } from 'components/SmartForm/types';
import Table from 'components/Table';
import { SelectedItem } from 'components/Table/types';
import { safeDate } from 'app/utils/safeTypes';
import { formatDate } from 'app/utils/formatters';
import { ExportData } from 'app/containers/Bills/types';
import { downloadExportData } from 'app/utils/downloads';
import { useBillsReset, useBillListRequest } from '../../hooks';
import { createRowFromBillsRequest } from '../../models';
import { getBillList } from '../../selectors';
import { BillRow } from '../../types';
import * as actions from '../../actions';
import AccountStatementButton from './components/AccountStatementButton';
import ExportDataButton from './components/ExportDataButton';
import FormExportModal from './components/FormExportModal';
import { useTableData } from './data';
import { useFormButtons } from './data/buttons';
import { useExportBalanceFields, useExportDataFields } from './data/fields';
import { ExportFieldName, ExportDataFormats } from './types';

interface ExportFormParams {
  from: string;
  to: string;
  format?: string;
}

const getExportFormParams = (formData: FieldProps[]): ExportData => {
  if (!Array.isArray(formData)) {
    return undefined;
  }

  const startDate = formData.find(f => f.id === ExportFieldName.startDate).model;
  const endDate = formData.find(f => f.id === ExportFieldName.endDate).model;
  const format = formData.find(f => f.id === ExportFieldName.format)?.model;

  return {
    startDate: formatDate(safeDate(startDate))
      .split('.')
      .reverse()
      .join('-'),
    endDate: formatDate(safeDate(endDate))
      .split('.')
      .reverse()
      .join('-'),
    ...(format && { format })
  };
};

const BillList: React.FC = () => {
  const { collection, pagination } = useSelector(getBillList);
  const [isLoading, setIsLoading] = useState(true);
  // const [filterValue, setFilterValue] = useState<string>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(pagination.currentPage);
  const { columns } = useTableData();
  const translations = useTranslationsContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const openQueries = useIsLoading(['get-bills']);

  useBillsReset();
  useBillListRequest(currentPage, pagination.pageSize, /* filterValue */ '');

  const billRows = React.useMemo(() => {
    return collection.map(createRowFromBillsRequest);
  }, [collection]);

  const [showExportBalance, setShowExportBalance] = React.useState(false);
  const [showExportData, setShowExportData] = React.useState(false);

  const exportBalanceFields = useExportBalanceFields();
  const exportDataFields = useExportDataFields();
  const formButtons = useFormButtons();

  const handleSelectRow = useCallback((row: BillRow) => {
    history.push(BILL.path.replace(':id', row.id.toString()).replace(':docType', row.documentType.toString()));
  }, []);

  // const handleFilter = useCallback((event: ChangeEvent<HTMLInputElement>) => {
  //   setFilterValue(event.target.value);
  // }, []);

  const handlePagination = useCallback(({ selected }: SelectedItem) => {
    setCurrentPage(selected);
  }, []);

  const exportDataHandler = useCallback(async (formData: FieldProps[], isBalance?: boolean) => {
    const exportFormData = getExportFormParams(formData);
    if (isBalance && exportFormData) {
      exportFormData.format = ExportDataFormats.exportFormat_pdf;
    }

    const action = isBalance ? actions.getExportBalanceRequest : actions.getBillsExportRequest;

    if (exportFormData) {
      const { type, payload } = await dispatch(action(exportFormData));

      if (type.includes('SUCCESS')) {
        downloadExportData(translations.billsList_exportDataName, (payload as unknown) as string, exportFormData);
      }
    }
  }, []);

  React.useEffect(() => {
    debounce(() => {
      if (isLoading) {
        setIsLoading(openQueries);
      }
    }, 100);
  }, [openQueries]);

  const tableActions = [
    // <InputFilter key="filter" onChange={ handleFilter } placeholder={ translations.billsList_searchLabel } />,
    <AccountStatementButton key="exportBalanceButton" onClick={ () => setShowExportBalance(true) } />
  ];
  const footerTableActions = [<ExportDataButton key="exportDataButton" onClick={ () => setShowExportData(true) } />];

  if (isLoading && !billRows.length) {
    return <TableSkeleton />;
  }

  return (
    <>
      <Table<BillRow>
        actions={ tableActions }
        columns={ columns }
        // filterValue={ filterValue }
        footerActions={ footerTableActions }
        isLoading={ openQueries }
        onClick={ handleSelectRow }
        pagination={ {
          onChange: handlePagination,
          ...pagination
        } }
        rows={ billRows }
        title={ translations.routeName_bills }
      />

      { showExportBalance && (
        <FormExportModal
          onClose={ () => setShowExportBalance(false) }
          onConfirm={ (formData: FieldProps[]) => {
            exportDataHandler(formData, true);
          } }
          formName="export-balance"
          formFields={ exportBalanceFields }
          formButtons={ formButtons }
          title={ translations.billsList_exportBalanceTitle }
          description={ translations.billsList_exportBalanceDescription }
        />
      ) }

      { showExportData && (
        <FormExportModal
          onClose={ () => setShowExportData(false) }
          onConfirm={ (formData: FieldProps[]) => {
            exportDataHandler(formData);
          } }
          formName="export-data"
          formFields={ exportDataFields }
          formButtons={ formButtons }
          title={ translations.billsList_exportDataTitle }
          description={ translations.billsList_exportDataDescription }
        />
      ) }
    </>
  );
};
export default BillList;
