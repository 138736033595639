import React, { useState, useEffect, useCallback } from 'react';
import { useTranslationsContext } from 'app/translations';
import View from './components/View';
import { maintenanceNotificationRequest, browserNotificationRequest } from './actions';
import { Notification } from './types';

const Notifier: React.FC = () => {
  const translations = useTranslationsContext();
  const [browserModalOpen, setBrowserModalOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const removeNotification = useCallback((id: string) => {
    setNotifications(notifications.filter(n => n.tag !== id));
  }, [notifications]);

  const getNotifications = async () => {
    const notifs = [];
    const maintenanceNotification = await maintenanceNotificationRequest(translations);
    if (maintenanceNotification) {
      notifs.push(maintenanceNotification);
    }

    const browsersNotification = await browserNotificationRequest(translations);
    if (browsersNotification) {
      notifs.push(browsersNotification);
    }

    setNotifications(notifs);
  };
  
  useEffect(() => {
    if (Object.keys(translations).length > 0) {
      getNotifications();
    }
  }, [translations]);

  const browserModalHandleClose = () => {
    setBrowserModalOpen(false);
  };

  const browserModalHandleOpen = () => {
    setBrowserModalOpen(true);
  };

  return (
    <View
      browserModalHandleClose={ browserModalHandleClose }
      browserModalHandleOpen={ browserModalHandleOpen }
      browserModalOpen={ browserModalOpen }
      handleDismiss={ removeNotification }
      notifications={ notifications }
    />
  );
};

export default Notifier;