import styled from 'styled-components';
import { TABLET } from 'base/styles/media-queries';
import { textStyles } from 'containers/Home/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
`;
Wrapper.displayName = 'Wrapper';

export const ResponsiveRows = styled.div`
  display: flex;
  max-width: calc(100% - 10px);
    max-width: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  ${ TABLET } {
    flex-direction: row;
  }
`;
ResponsiveRows.displayName = 'ResponsiveRows';

export const DateCell = styled.div`
  ${ textStyles }
  width: 100px;
`;
DateCell.displayName = 'DateCell';

export const NotificationCell = styled.div`
  ${ textStyles }
  flex: 1;
`;
NotificationCell.displayName = 'NotificationCell';

export const TagCell = styled.div`
  flex: 0;
  padding: 0 18px;
`;
TagCell.displayName = 'TagCell';

export const NavigationCell = styled.div`
  width: 40px;
  padding: 0 8px;
`;
NavigationCell.displayName = 'NavigationCell';
