import { FormikValues } from 'formik';
import { DimensionChartOptions, Dimension } from 'app/constants/statistic';
import { LangKeys, useTranslationsContext } from 'app/translations';
import { getDataGroupedByMaterial } from 'app/utils/chart';
import { addSuffix, createOptionsFromEntity } from 'app/utils/fields';
import { DROPDOWN, DATE } from 'components/SmartForm/models';
import { FieldProps } from 'components/SmartForm/types';
import { StatisticData } from 'containers/Statistics/types';

const createID = (id: string, isSecondary?: boolean) => addSuffix(isSecondary ? 'reference' : 'comparison')(id);

export const createDimensionChart = (translations: LangKeys, fieldName: string): FieldProps => ({
  id: fieldName,
  label: translations.statistics_filter,
  type: DROPDOWN,
  options: createOptionsFromEntity([
    {
      id: DimensionChartOptions.costWeight,
      name: `${ translations.statistics_costs }/${ translations.statistics_weight }`
    },
    {
      id: DimensionChartOptions.fraction,
      name: translations.statistics_fraction
    }
  ])
});

const createMaterial = (translations: LangKeys, fieldName: string, data?: StatisticData[]): FieldProps => {
  const options = getDataGroupedByMaterial(data);

  return {
    id: fieldName,
    label: translations.statistics_material,
    options: options.map(record => ({
      id: record.material.id,
      label: record.material.name,
      value: record.material.id
    })),
    model: options?.[0]?.material.id,
    type: DROPDOWN
  };
};

const createStartDate = (translations: LangKeys, fieldName: string, maxLength?: number): FieldProps => ({
  id: fieldName,
  title: translations.calendar_title_preferred_date,
  label: translations.statistics_fromDate,
  maxLength,
  minLength: new Date(0).getTime(),
  type: DATE
});

const createEndDate = (translations: LangKeys, fieldName: string, minLength?: number): FieldProps => ({
  id: fieldName,
  title: translations.calendar_title_preferred_date,
  label: translations.statistics_toDate,
  minLength,
  type: DATE
});

const createDimensionRadio = (translations: LangKeys, fieldName: string): FieldProps => ({
  id: fieldName,
  type: 'radio',
  options: [
    {
      id: Dimension.weight,
      value: Dimension.weight,
      label: translations.statistics_weight
    },
    {
      id: Dimension.cost,
      value: Dimension.cost,
      label: translations.statistics_costs
    }
  ]
});

const createDimensionWeight = (translations: LangKeys, fieldName: string): FieldProps => ({
  id: fieldName,
  type: 'checkbox',
  label: translations.statistics_weight
});

const createDimensionCost = (translations: LangKeys, fieldName: string): FieldProps => ({
  id: fieldName,
  type: 'checkbox',
  label: translations.statistics_costs
});

interface DropdownFields {
  dimensionChart?: FieldProps;
  material?: FieldProps;
  startDate?: FieldProps;
  endDate?: FieldProps;
}

interface DimensionFields {
  dimensionRadio?: FieldProps;
  dimensionWeight?: FieldProps;
  dimensionCost?: FieldProps;
}

interface FormData {
  dropdownFields: DropdownFields;
  dimensionFields: DimensionFields;
}

interface FormDataProps {
  data: StatisticData[];
  isAreaChart?: boolean;
  isComparing?: boolean;
  isOverview?: boolean;
  isSecondary?: boolean;
  values: FormikValues;
}

export const useFormData = ({ data,
  isAreaChart,
  isComparing,
  isOverview,
  isSecondary,
  values }: FormDataProps): FormData => {
  const translations = useTranslationsContext();

  const dropdownFields: DropdownFields = {};
  const dimensionFields: DimensionFields = {};

  if (!isOverview) {
    if (!isComparing) {
      dropdownFields.dimensionChart = createDimensionChart(translations, createID('dimensionChart', isSecondary));
    }

    if (isAreaChart) {
      const material = createMaterial(translations, createID('material', isSecondary), data);
      if (material.model) {
        dropdownFields.material = material;
      }
    } else {
      dimensionFields.dimensionWeight = createDimensionWeight(translations, createID('dimensionWeight', isSecondary));

      dimensionFields.dimensionCost = createDimensionCost(translations, createID('dimensionCost', isSecondary));
    }

    dropdownFields.startDate = createStartDate(
      translations,
      createID('startDate', isSecondary),
      values.endDate
    );

    dropdownFields.endDate = createEndDate(translations, createID('endDate', isSecondary), values.startDate);
  }

  if (isAreaChart || isOverview) {
    dimensionFields.dimensionRadio = createDimensionRadio(translations, createID('dimensionRadio', isSecondary));
  }

  return { dropdownFields, dimensionFields };
};
