import { safeString, safeDate, safeNumber } from 'app/utils/safeTypes';
// import { createCompanySimpleFromServer, createCustomerFromServer,
//   createCustomerFromClientFromServer } from 'containers/Companies/models/fromServer';
import { TemplatesListFromServer, TemplateRequestFromServer } from '../types/fromServer';
import { TemplateRequest, TemplateRow } from '../types';
import { createPickupRequestFromServer } from '../../../models/fromServer';
// import { Logistic } from '../../../types';


export const createTemplatesFromServer = ({ pickup_template_id, name,
  date_created,
  last_modified_date, 
  template_date_frame_type_id }: TemplatesListFromServer): TemplateRow => {
  if (
    pickup_template_id === undefined
    || name === undefined
    || date_created === undefined
    || last_modified_date === undefined
  ) {
    console.error('Error creating <TemplateRow> model. Logistic not found.');
  }
  return {
    templateId: safeString(pickup_template_id),
    templateName: safeString(name),
    createdDate: safeDate(date_created).getTime(),
    lastUsed: safeDate(last_modified_date).getTime(),
    templateDateRule: safeNumber(template_date_frame_type_id)
    
  };
};

export const createTemplatesListFromServer = (templates: TemplatesListFromServer[]): TemplateRow[] => {
  if (!Array.isArray(templates)) {
    console.error('Error creating <ItemInfo[]> model. Not array found.');
    return [] as TemplateRow[];
  }

  return templates.map(createTemplatesFromServer).filter(p => !!p);
};

export const createTemplateRequestFromServer = (template: TemplateRequestFromServer): TemplateRequest | undefined => {
  if (!template || template.name === undefined || template.pickup_template_id === undefined) {
    console.error('Error creating <TemplateRequest> model.');
    return undefined;
  }
  return {
    ...createPickupRequestFromServer(template, true),
    templateId: safeString(template.pickup_template_id),
    templateName: safeString(template.name),
    createdDate: safeDate(template.date_created).getTime(),
    lastUsed: safeDate(template.last_modified_date).getDate(),
    templateDateRule: safeNumber(template.template_date_frame_type_id)
  };
};