import styled from 'styled-components';
import { DESKTOP, TABLET } from 'base/styles/media-queries';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
Container.displayName = 'Container';

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
WrapperTitle.displayName = 'WrapperTitle';

export const WrapperButton = styled.div`
  display: none;

  ${ TABLET } {
    display: flex;
  }
  ${ DESKTOP } {
    display: none;
  }
`;
WrapperButton.displayName = 'WrapperButton';

export const EmptyCollection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;
EmptyCollection.displayName = 'EmptyCollection';
