import { LangKeys } from 'app/translations';
import { Column } from 'components/Table/types';
import { UserRow } from 'containers/Users/types';
import { renderers } from 'components/Table';
import TableTag from '../components/Tag';
// import LockRenderer from '../components/LockRenderer'; commented as per client request

export const createColumns = (translations: LangKeys, isAdmin: boolean) => {
  const columns: Column<UserRow>[] = [
    // {
    //   key: 'locked',
    //   mobile: true,
    //   renderer: LockRenderer
    // }
  ];

  if (isAdmin) {
    columns.push({
      key: 'company',
      label: translations.usersList_tableHeader_company
    });
  }

  columns.push(
    {
      key: 'fullName',
      label: translations.usersList_tableHeader_name,
      mobile: true
    },
    {
      key: 'date',
      label: translations.usersList_tableHeader_date,
      renderer: renderers.Date
    },
    {
      key: 'email',
      label: translations.usersList_tableHeader_email
    },
    {
      key: 'role',
      label: translations.usersList_tableHeader_role,
      mobile: true
    },
    {
      key: 'status',
      label: translations.usersList_tableHeader_status,
      renderer: TableTag
    },
    {
      key: 'navigate'
    }
  );

  return columns;
};
