import { formatDate } from 'app/utils/formatters';
import { Cell } from './types';

export const matches = (cell: Cell, filterValue = '') => {
  if (!filterValue || filterValue === '0' || !cell) {
    return false;
  }
  
  const value = cell.toString().toLowerCase();
  const date = formatDate(new Date(value));
  const filter = filterValue.toLowerCase();

  return new RegExp(filter).test(value) || new RegExp(filter).test(date);
};

const getFilteredRows = <R>(rows: R[], filterValue?: string): R[] =>
  // @ts-ignore
  (filterValue ? rows.filter(row => Object.keys(row).some(key => matches(row[key], filterValue))) : rows);

export default getFilteredRows;
