import styled from 'styled-components';
import { TABLE_FOOTER_BACKGROUND, BACKGROUND } from 'base/styles/skin';

export const Row = styled.tr``;
Row.displayName = 'Row';

export const Cell = styled.td``;
Cell.displayName = 'Cell';

interface BarWrapperProps {
  isLoading: boolean;
}

export const BarWrapper = styled.div`
  background-color: ${ TABLE_FOOTER_BACKGROUND };
  height: ${ ({ isLoading }: BarWrapperProps) => (isLoading ? '4px' : '0px') };
  overflow: hidden;
  position: relative;
  transition: height 0.2s;
  width: 100%;
`;
BarWrapper.displayName = 'BarWrapper';

export const BarContent = styled.div`
  @keyframes progress {
    from {
      margin-left: -50%;
    }
    to {
      margin-left: 100%;
    }
  }

  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: progress;
  animation-timing-function: linear;

  background-color: ${ BACKGROUND };
  border-bottom: 1px solid ${ TABLE_FOOTER_BACKGROUND };
  height: 100%;
  position: absolute;
  top: 0;
  width: 50%;
`;
BarContent.displayName = 'BarContent';
