import { FormikValues } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslationsContext } from 'app/translations';
import { applyDateLimits } from 'app/utils/validations';
import { createIndexedFields, flattenObject } from 'app/utils/fields';
import Modal from 'components/Modal';
import SmartForm from 'components/SmartForm';
import { useButtons } from './data/buttons';
import { useFields } from './data/fields';
import { useValidationSchema } from './data/validation';
import { TextDescription, FormWrapper, FieldWrapper, Body, Footer, ButtonsWrapper,
  FieldWrapperNoteText } from './styles';
import { FieldName } from '../../types';

interface Props {
  onConfirm: (values: any) => void;
  onDismiss: () => void;
}

const ExportModal: React.FC<Props> = ({ onConfirm, onDismiss }) => {
  const [values, setValues] = useState<FormikValues>();
  const translations = useTranslationsContext();
  const fields = applyDateLimits(useFields(), values);
  const buttons = useButtons(onDismiss);
  //  const formInitialValues = { format: fields.find(f => f.id === ExportFieldName.checkboxSave) };
  const [showTemplate, setshowTemplate] = useState(false);
  const templateActionDetailsNullCheck = {
    saveTemplate: false,
    templateName: '',
    templateDateRule: '',
  };


  const [formErrors, getFormErrors] = React.useState<FormikValues>({ });
  const [formErrorKeys, setFormErrorKeys] = React.useState<FormikValues>({ });

  const [formTouched, getFormTouched] = React.useState<FormikValues>({ });
  const [formTouchedKeys, setFormTouchedKeys] = React.useState<FormikValues>({ });

  useEffect(() => {
    setFormErrorKeys(flattenObject(formErrors));
  }, [formErrors]);

  useEffect(() => {
    setFormTouchedKeys(flattenObject(formErrors));
  }, [formTouched]);


  useEffect(() => {
    if (values?.templateActionDetails?.saveTemplate) setshowTemplate(true);
    if (!values?.templateActionDetails?.saveTemplate) setshowTemplate(false);
  }, [values]);

  const onFormSubmit = React.useCallback(() => {
    const templateValues = {
      templateActionDetails:
        values?.templateActionDetails !== undefined ? values?.templateActionDetails : templateActionDetailsNullCheck
    };
    onConfirm(templateValues);
  }, [values]);

  return (
    <Modal onClose={ onDismiss } header={ translations.template_modalBox_placeOrder } size="auto">
      <>
        <TextDescription>{ translations.template_modalBox_template_Description }</TextDescription>
        <SmartForm
          form={ 'exportData' }
          buttons={ buttons }
          fields={ fields }
          validationSchema={ useValidationSchema() }
          handleSubmit={ onFormSubmit }
          handleErrors={ getFormErrors }
          handleTouched={ getFormTouched }
          validateOnChange={ true }
        // initialValues={ formInitialValues }
        >
          { ({ controlledFields, controlledButtons, Field, Buttons, values: vals }) => {
            const indexedFields = createIndexedFields(controlledFields);
            const field = indexedFields;
     
            setValues(vals);
            return (
              <>
                <Body>
                  <FormWrapper>
                    <FieldWrapper>
                      <Field
                        options={ { ...field[FieldName.saveTemplate],
                          error: formTouchedKeys[FieldName.saveTemplate] && formErrorKeys[FieldName.saveTemplate] } } />
                      {
                        showTemplate
                        && <div>
                          <Field options={ {
                            ...field[FieldName.templateName],
                            error: formTouchedKeys[FieldName.templateName] && formErrorKeys[FieldName.templateName]
                          } } />
                          <Field options={ field[FieldName.templateDateRule] } />
                          <FieldWrapperNoteText>
                            <strong>Notiz: </strong>
                            { translations.template_date_rule_datum_note }
                          </FieldWrapperNoteText>
                        </div>
                      }
                    </FieldWrapper>
                  </FormWrapper>
                </Body>
                <Footer>
                  <ButtonsWrapper>
                    <Buttons options={ controlledButtons } />
                  </ButtonsWrapper>
                </Footer>
              </>
            );
          } }
        </SmartForm>
      </>
    </Modal>
  );
};

export default ExportModal;
