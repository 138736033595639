import React from 'react';
import Card from 'components/Card';

import { useTranslationsContext } from 'app/translations';
import { IndexedFields } from 'app/types';
import Field from 'components/SmartForm/components';
import SectionTitle from 'components/SectionTitle';
import { BookingRef } from 'containers/BookingRefs/types';
import { CardContent } from './styles';
import { FieldName } from '../../types';

interface Props {
  fields: IndexedFields<FieldName>;
  bookingRefs?: BookingRef[];
}

const Commentary: React.FC<Props> = ({ fields, bookingRefs }) => {
  const translations = useTranslationsContext();

  return (
    <>
      <SectionTitle>{ translations.pickupRequestDetails_commentaryTitle }</SectionTitle>
      <Card indentation={ true }>
        <CardContent>
          <Field
            options={ { ...fields['commentary.bookingReference'], autocomplete: bookingRefs?.map(ref => ref.name) } }
          />
          <Field options={ fields['commentary.hasToSaveBookinReference'] } />
          <Field options={ fields['commentary.additionalComment'] } />
          <Field options={ fields['commentary.attachedFiles'] } />
        </CardContent>
      </Card>
    </>
  );
};

export default Commentary;
