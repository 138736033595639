import React from 'react';
import { http } from 'base';
import { createDownload, base64ToBlob } from 'app/utils/downloads';
import { Icon } from 'components/SmartForm/components/styles';
import { SmartFile, DummyFile } from '../../types';
import { FileWrapper, FileUrl, FileDot } from './styles';

const deleteIcon = require('assets/icons/delete.svg');

interface Props {
  file: SmartFile;
  isDisabled?: boolean;
  onRemove: () => void;
  onClick?: () => void;
}

const FileItem: React.FC<Props> = ({ file, onRemove, isDisabled, onClick }) => {
  const handleClick = async () => {
    if (onClick) onClick();

    const url = (file as DummyFile).url || URL.createObjectURL(file);
    if (url) {
      const { data } = await http.get(url);
      const encodedFile = (data?.data || data)[0]?.encode;
      const blob = base64ToBlob(atob(encodedFile));
      createDownload(blob, file.name);
    }
  };

  return (
    <FileWrapper isDisabled={ isDisabled }>
      { isDisabled && <FileDot /> }
      <FileUrl isDisabled={ isDisabled } onClick={ handleClick }>
        { file.name }
      </FileUrl>
      { !isDisabled && <Icon src={ deleteIcon } isDisabled={ isDisabled } onClick={ onRemove } /> }
    </FileWrapper>
  );
};

export default FileItem;
