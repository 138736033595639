import React from 'react';
import { sortBy } from 'app/utils/sorting';
import Buttons, { } from 'components/Buttons';
import { ButtonProps } from 'components/Touchable/types';
import { Notification, SupportedBrowserInfo } from '../../types';
import SupportedBrowsersModal from '../SupportedBrowsersModal';
import { Wrapper, NotificationCard, NotificationTitle, NotificationBody } from './styles';

const supportedBrowsers: SupportedBrowserInfo[] = require('assets/browsers-notifications.json');

const createButtonsFromActions = (actions: NotificationAction[]): ButtonProps[] =>
  actions.map(({ action, icon, title }) => ({
    id: action,
    label: title,
    icon,
    type: 'button',
  }));

interface Props {
  browserModalOpen: boolean;
  browserModalHandleClose: () => void;
  browserModalHandleOpen: () => void;
  handleDismiss?: (id: string) => void;
  notifications: Notification[];
}

const notficationset = false; // forcely disabling the notifcation as per client request    
const View: React.FC<Props> = ({ browserModalOpen,
  browserModalHandleClose,
  browserModalHandleOpen,
  handleDismiss,
  notifications }) => {
  const reversed = notifications.map(n => n).sort(sortBy<Notification>('timestamp', 'desc')).slice(0, 3);
  
  return (
    <Wrapper>
      { reversed.map(({ actions = [], body, tag, title }) => {
        const buttons = createButtonsFromActions(actions).map(btn => {
          let onClick;

          switch (btn.id) {
            case 'more-info':
              onClick = browserModalHandleOpen;
              break;

            case 'dismiss':
            default:
              onClick = () => {
                handleDismiss(tag);
              };
          }

          return {
            ...btn,
            onClick
          };
        });

        return (  
          notficationset  
&& <NotificationCard key={ tag }>
  <NotificationTitle>{ title }</NotificationTitle>
  <NotificationBody>{ body }</NotificationBody>
  { !!actions.length && <Buttons options={ buttons } /> }
</NotificationCard>
        ); 
      }) }

      { browserModalOpen && (
        <SupportedBrowsersModal
          handleClose={ browserModalHandleClose }
          supportedBrowsers={ supportedBrowsers }
        />
      ) }
    </Wrapper>
  ); 
};

export default View;