import { LangKeys } from 'app/translations';
import { FieldProps } from 'components/SmartForm/types';
import { TEXT, TEXTAREA } from 'components/SmartForm/models';

export const createBillDetailFields = (translations: LangKeys): FieldProps[] => {
  return [
    {
      id: 'billingAddress',
      type: TEXTAREA,
      isDisabled: true
    },
    {
      id: 'customerId',
      label: translations.billDetails_customerIdLabel,
      type: TEXT,
      isDisabled: true
    },
    {
      id: 'vatId',
      label: translations.billDetails_vatIdLabel,
      type: TEXT,
      isDisabled: true
    },
    {
      id: 'contactInfo',
      label: translations.billDetails_contactDetailsLabel,
      type: TEXTAREA,
      isDisabled: true
    }
  ];
};
