import { Dispatch } from 'redux';
import { PickupRequestStatus } from 'app/constants/pickupRequest';
import { PICKUP_REQUESTS_LIST, TEMPLATE_LIST } from 'app/routes';
// eslint-disable-next-line max-len
import { deletePickupRequest, updatePickupRequest, createPickupRequest, saveTemplateRequest } from 'containers/PickupRequest/actions';
import { PickupRequest, TemplateUpdateRequest } from 'containers/PickupRequest/types';
import { MODALS } from '../types';

type SetOpenModal = (openModal: MODALS) => void;

interface CommonDependencies {
  dispatch: Dispatch;
  setOpenModal: SetOpenModal;
}

export const dismiss = (setOpenModal: SetOpenModal) => {
  setOpenModal(undefined);
};

interface AbortProps extends CommonDependencies {
  history: any;
  initialStatus: PickupRequestStatus;
  isAdmin: boolean;
  pickup: PickupRequest;
}

export const abort = ({ dispatch, history, initialStatus, isAdmin, pickup, setOpenModal }: AbortProps) => {
  dismiss(setOpenModal);
  if (isAdmin && pickup && initialStatus !== pickup.status && pickup.status === PickupRequestStatus.IN_PROCESS) {
    dispatch(updatePickupRequest(pickup, isAdmin, PickupRequestStatus.NEW));
  }
  history.push(PICKUP_REQUESTS_LIST.path);
};

export const print = (setOpenModal: SetOpenModal) => {
  dismiss(setOpenModal);
};


interface ConnectionFailureRequest{
  history: any;
  setOpenModal: SetOpenModal;
}

export const connectionFailureTA = ({ setOpenModal, history }: ConnectionFailureRequest) => {
  dismiss(setOpenModal);
  history.push(PICKUP_REQUESTS_LIST.path);
};
interface RemovePickupRequest extends CommonDependencies {
  history: any;
  id: string;
}

export const remove = async ({ dispatch, history, id, setOpenModal }: RemovePickupRequest) => {
  dismiss(setOpenModal);
  await dispatch(deletePickupRequest(id));
  history.push(PICKUP_REQUESTS_LIST.path);
};

interface SavePickupRequest extends CommonDependencies {
  history: any;
  isAdmin: boolean;
  pickupRequest: PickupRequest;
  status: PickupRequestStatus;
}

export const save = async ({ dispatch, history, isAdmin, pickupRequest, setOpenModal, status }: SavePickupRequest) => {
  const shouldUpdate = pickupRequest?.id;
  const actionToPerform = shouldUpdate ? updatePickupRequest : createPickupRequest;
  const { type } = await dispatch(actionToPerform(pickupRequest, isAdmin, status));

  if (new RegExp('SUCCESS').test(type)) {
    history.push(PICKUP_REQUESTS_LIST.path);
  } else if (new RegExp('ERROR').test(type)) {
    setOpenModal(MODALS.TA_CONNECTION_FAILURE);
  }
};

interface SaveTemplateRequest {
  dispatch: Dispatch;
  history: any;
  pickupRequest: TemplateUpdateRequest;
  status?: PickupRequestStatus;
}
export const saveTemplate = async ({ dispatch, history, pickupRequest }: SaveTemplateRequest) => {
  const { type } = await dispatch(saveTemplateRequest(pickupRequest));

  if (new RegExp('SUCCESS').test(type)) {
    history.push(TEMPLATE_LIST.path);
  }
};

interface AbortTemplate {
  history: any;
}

export const templateAbort = ({ history }: AbortTemplate) => {
  history.push(TEMPLATE_LIST.path);
};
