import { getOffsetPagination } from 'app/utils/pagination';
import * as api from '../api';
import { ActionTypes, NotificationsActionType } from '../actionTypes';

export const getNotificationList = (page: number, pageSize: number): NotificationsActionType => ({
  type: ActionTypes.GET_NOTIFICATION_LIST_REQUEST,
  request: api.getNotificationList(getOffsetPagination(page, pageSize), pageSize),
  requestName: 'get-notifications'
});

export const resetNotifications = (): NotificationsActionType => ({
  type: ActionTypes.RESET_NOTIFICATIONS
});
