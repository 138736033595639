import { http, envConf } from 'base';
import { Pagination } from 'app/types';
import { Notification } from 'containers/Notifications/types';
import { createPaginationFromServer } from 'app/models';
import * as Model from '../models/fromServer';

interface NotificationsResponse {
  collection: Notification[];
  pagination: Pagination;
}

export const getNotificationList = async (offset: number, limit: number): Promise<NotificationsResponse> => {
  try {
    const { data } = await http.get(envConf.API.NOTIFICATION_LIST_GET, {
      params: { limit, offset }
    });

    const { notification, ...pagination } = data;

    return {
      collection: Model.createNotificationListFromServer(notification),
      pagination: createPaginationFromServer(pagination)
    };
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
