import produce from 'immer';
import { createReducer, isMockingApi } from 'base';
import { ActionHandler } from 'base/types';
import { ActionTypes as LogoutActions } from 'containers/Login/actionTypes';
import { ActionTypes, TemplatesActionType } from '../actionTypes';
import { TEMPLATES_LIST } from '../mocks';
import { templatesInitialState } from '../models';
import { TemplateRequest, TemplatesState } from '../types';

const getTemplateListSuccess = (state: TemplatesState, action: TemplatesActionType) =>
  produce(state, draft => {
    const { collection, pagination } = action.payload as TemplatesState;
    draft.collection = collection || state.collection;
    draft.pagination = pagination || state.pagination;
  });

const getTemplateListError = (state: TemplatesState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.collection = TEMPLATES_LIST.slice(0, draft.pagination.pageSize);
      draft.pagination.totalCount = TEMPLATES_LIST.length;
    }
  });

const resetTemplates = (state: TemplatesState) =>
  produce(state, draft => {
    draft.collection = [];
  });

const getInitialState = (state: TemplatesState) =>
  produce(state, draft => {
    Object.keys(draft).forEach(key => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      draft[key] = templatesInitialState[key];
    });
  });

const getTemplateDetailsSuccess = (state: TemplatesState, action: TemplatesActionType) =>
  produce(state, draft => {
    const template = action.payload as TemplateRequest;
    let hasBeenModified = false;
    draft.collection = state.collection.map(p => {
      if (p.templateId === template.templateId) {
        hasBeenModified = true;
        return {
          ...p,
          ...template
        };
      }

      return p;
    });

    if (!hasBeenModified) {
      draft.collection.push(template);
    }
  });

const actionHandlers: ActionHandler<TemplatesState> = {
  [ActionTypes.GET_TEMPLATES_LIST_SUCCESS]: getTemplateListSuccess,
  [ActionTypes.GET_TEMPLATES_LIST_ERROR]: getTemplateListError,
  [ActionTypes.RESET_TEMPLATES]: resetTemplates,
  [ActionTypes.GET_TEMPLATE_DETAILS_SUCCESS]: getTemplateDetailsSuccess,
  [LogoutActions.LOGOUT_REQUEST]: getInitialState
};

export default createReducer(templatesInitialState, actionHandlers);
