import styled from 'styled-components';
import { DISABLED_BACKGROUND, 
  SWITCH_BACKGROUND, 
  SWITCH_BACKGROUND_DISABLED,
  SWITCH_BACKGROUND_ACTIVE, 
  DISABLED_BORDER, 
  SWITCH_BORDER_ACTIVE } from 'base/styles/skin';

import { RADIO_SIZE, Wrapper, Label, Description } from '../styles';

interface StyledProps {
  checked: boolean;
}

const outerBorder = Math.trunc(RADIO_SIZE / 10);
const innerBorder = Math.trunc(RADIO_SIZE / 10);

export const StyledWrapper = styled(Wrapper)`
  padding: 0;
`;
StyledWrapper.displayName = 'Wrapper';

export const StyledLabel = styled(Label)`
  padding: 0;
`;
StyledLabel.displayName = 'Label';

export const StyledDescription = styled(Description)`
  padding: 10px 0;
`;
StyledDescription.displayName = 'Description';

export const CustomSwitch = styled.div`
  background-color: ${ ({ checked }: StyledProps) => (checked 
    ? SWITCH_BACKGROUND_ACTIVE : SWITCH_BACKGROUND_DISABLED) };
  border: ${ ({ checked }: StyledProps) => (checked 
    ? `${ outerBorder }px solid ${ SWITCH_BORDER_ACTIVE }` : `${ outerBorder }px solid ${ DISABLED_BORDER }`) };
  border-radius: ${ RADIO_SIZE / 2 }px;
  box-sizing: border-box;
  cursor: inherit;
  display: inline-block;
  height: ${ RADIO_SIZE }px;
  margin-right: 10px;
  padding: ${ innerBorder }px;
  position: relative;
  transition: background-color 0.2s;
  width: ${ RADIO_SIZE * 2 }px;

  &:after {
    background-color: ${ SWITCH_BACKGROUND };
    border-radius: 50%;
    box-sizing: border-box;
    content: ' ';
    display: inline-block;
    height: ${ RADIO_SIZE - outerBorder * 2 - innerBorder * 2 }px;
    transition: background-color 0.2s, transform 0.2s;
    transform: translateX(0px);
    vertical-align: top;
    width: ${ RADIO_SIZE - outerBorder * 2 - innerBorder * 2 }px;

    input:checked ~ div & {
      transform: translateX(${ RADIO_SIZE }px);
    }

    input:disabled ~ div & {
      background-color: ${ DISABLED_BACKGROUND };
    }
  }

  & + span {
    font-size: 14px;
    line-height: 1.2em;
  }
`;
CustomSwitch.displayName = 'CustomSwitch';
