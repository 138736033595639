import { RootState } from 'base/types';
import { createCompanyPermissionsFromServer } from 'containers/Users/models';
import { CompanyPermissions } from 'containers/Users/types';
import { Customer } from '../types';

export const getCompanySimpleList = ({ companies: { simple } }: RootState) => simple.collection;

export const getCompanies = ({ companies: { complete } }: RootState) => ({
  collection: complete.collection,
  pagination: complete.pagination
});

export const getCompany = (id: string) => ({ companies: { complete } }: RootState) =>
  complete.collection.find(c => c.id === id);

export const getCompanyCustomers = (id: string) => (rootState: RootState): Customer[] =>
  getCompany(id)(rootState)?.customers || [];

export const getCompanyPermissions = (id: string) => (rootState: RootState): CompanyPermissions =>
  getCompany(id)(rootState)?.permissions || createCompanyPermissionsFromServer({});

export const getCompanyList = ({ companies: { switchlist } }: RootState) => switchlist.collection;
