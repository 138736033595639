import { Action } from 'app/types';

export enum ActionTypes {
  GET_NOTIFICATION_LIST_REQUEST = 'GET_NOTIFICATION_LIST_REQUEST',
  GET_NOTIFICATION_LIST_SUCCESS = 'GET_NOTIFICATION_LIST_SUCCESS',
  GET_NOTIFICATION_LIST_ERROR = 'GET_NOTIFICATION_LIST_ERROR',

  RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'
}

type GetNotificationListRequest = Action<ActionTypes.GET_NOTIFICATION_LIST_REQUEST>;
type GetNotificationListSuccess = Action<ActionTypes.GET_NOTIFICATION_LIST_SUCCESS>;
type GetNotificationListError = Action<ActionTypes.GET_NOTIFICATION_LIST_ERROR>;

type ResetNotifications = Action<ActionTypes.RESET_NOTIFICATIONS>;

type GetNotificationListActionType = GetNotificationListRequest | GetNotificationListSuccess | GetNotificationListError;

export type NotificationsActionType = GetNotificationListActionType | ResetNotifications;
