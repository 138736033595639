import React from 'react';
import { ConfirmationModal as ConfModal } from 'components/Modal';
import { ModalProps } from '../../types';

const ConfirmationModal: React.FC<ModalProps> = ({ confirmLabel,
  description,
  dismissLabel,
  onConfirm,
  onDismiss,
  title }) => (
  <ConfModal
    header={ title }
    confirmLabel={ confirmLabel }
    onConfirm={ onConfirm }
    dismissLabel={ dismissLabel }
    onDismiss={ onDismiss }
  >
    { description }
  </ConfModal>
);

export default ConfirmationModal;
