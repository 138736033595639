import styled, { css } from 'styled-components';
import { Link } from 'containers/Login/styles';
import { LINK_COLOR, LINK_COLOR_HOVER, PRIMARY, TEXT_COLOR } from 'base/styles/skin';

export const Wrapper = styled.div`
  display: inline-block;
  padding: 4px 0;
  position: relative
`;
Wrapper.displayName = 'Wrapper';

const TextStyles = css`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${ TEXT_COLOR };
`;

export const Text = styled.span`
  display: inline !important;
  ${ TextStyles }
`;
Text.displayName = 'Text';

export const TextLink = styled(Link)`
  &,
  &:link,
  &:visited {
    ${ TextStyles }
    color: ${ LINK_COLOR };
    text-decoration: underline;
  }
  &:hover {
      color: ${ LINK_COLOR_HOVER };
  }
`;
TextLink.displayName = 'TextLink';

export const BackButton = styled.div`
position: absolute;
//right: 55px;
    left: -33px;
top: 40px;
`;
BackButton.displayName = 'BackButton';