import { PickupRequestStatus } from 'app/constants/pickupRequest';
import { Option } from 'components/SmartForm/types';

export type EmptyFunction = () => void;
export type SaveAction = (status: PickupRequestStatus) => void;

export interface CreatorProps {
  buttonActions: {
    handleChangePassword: EmptyFunction;
    handleClose: EmptyFunction;
    handleRemove: EmptyFunction;
    handleSave: SaveAction;
  };
}

export enum FieldName {
  companyName = 'companyName',
  companyId = 'companyId',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phoneNumber = 'phoneNumber',
  role = 'role',
  requestPickup = 'permissions.requestPickup',
  accessBills = 'permissions.accessBills',
  changePassword = 'changePassword'
}

export enum MODALS {
  DELETE = 'DELETE',
  PASSWORD = 'PASSWORD',
  SUBMIT = 'SUBMIT'
}

export interface ModalData {
  description: string;
  dismissLabel: string;
  confirmLabel: string;
  title: string;
}

export interface ModalProps extends ModalData {
  openModal: MODALS;
  onConfirm: () => void;
  onDismiss: () => void;
}

export interface DropdownOptions {
  companyOptions: Option<string>[];
  roleOptions: Option<string>[];
}
