import styled, { css } from 'styled-components';
import { TABLET, DESKTOP } from 'base/styles/media-queries';
import { TITLE_COLOR,
  TABLE_HEADER_BACKGROUND,
  TABLE_HEADER_COLOR,
  TABLE_BACKGROUND,
  TABLE_COLOR,
  TABLE_BORDER,
  TABLE_HOVER_BACKGROUND,
  TABLE_FOOTER_BACKGROUND } from 'base/styles/skin';

interface TableProps {
  showTitle?: boolean;
}

export const Wrapper = styled.div`
  margin-top: ${ ({ showTitle }: TableProps) => !showTitle && '12px' };
  margin-bottom: ${ ({ showTitle }: TableProps) => (showTitle ? '16px' : '0') };
  overflow-x: ${ ({ showTitle }: TableProps) => !showTitle && 'auto' };
`;
Wrapper.displayName = 'Wrapper';

export const ActionsBar = styled.div`
  align-items: center;
  padding-bottom: 16px;
  width: 100%;

  ${ TABLET } {
    display: flex;
  }
  ${ DESKTOP } {
    padding-bottom: 20px;
  }
`;
ActionsBar.displayName = 'ActionsBar';

export const Title = styled.h2`
  color: ${ TITLE_COLOR };
  flex: 1;
  //font-family: Roboto;
  font-family: 'TT Norms';
  font-weight: 500;
  font-size: 22px;
  padding-right: 10px;
`;
Title.displayName = 'Title';

export const Actions = styled.div`
  padding-top: 10px;
  text-align: right;

  ${ TABLET } {
    padding-top: 0;
  }
`;
Actions.displayName = 'Actions';

export const ActionWrapper = styled.div`
  display: inline-block;
  max-width: 320px;
  margin-left: 10px;
`;
ActionWrapper.displayName = 'ActionWrapper';

export const StyledTable = styled.table`
  width: 100%;

  &,
  & > * {
    border: none;
    border-spacing: 0;
    //font-family: Roboto;
    font-family: 'TT Norms';
    font-size: 14px;
    font-weight: normal;
    text-align: center;
  }
`;
StyledTable.displayName = 'StyledTable';

const borderRadius = 4;
export const TableHead = styled.thead`
  color: ${ TABLE_HEADER_COLOR };
`;
TableHead.displayName = 'TableHead';

interface TableBodyProps {
  isPaginated?: boolean;
}

export const TableBody = styled.tbody`
  background-color: ${ TABLE_BACKGROUND };
  color: ${ TABLE_COLOR };

  ${ ({ isPaginated }: TableBodyProps) =>
    !isPaginated
    && `
    tr:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: ${ borderRadius }px;
        }
        &:last-child {
          border-bottom-right-radius: ${ borderRadius }px;
        }
      }
    }
  ` }
`;
TableBody.displayName = 'TableBody';

export const TableRow = styled.tr`
  cursor: ${ ({ onClick }) => !!onClick && 'pointer' };

  &:hover {
    background-color: ${ ({ onClick }) => !!onClick && TABLE_HOVER_BACKGROUND };
  }

  td {
    border-bottom: 1px solid ${ TABLE_BORDER };
    text-align: -webkit-center;
  }

  &:last-child td {
    border: none;
  }
`;
TableRow.displayName = 'TableRow';

interface CellProps extends TableProps {
  active?: boolean;
  mobile: boolean;
  isLeftAlign?: boolean;
}

const cellProps = css`
  display: ${ ({ mobile }: CellProps) => !mobile && 'none' };
  font-weight: ${ ({ active }: CellProps) => (active ? 'bold' : 'normal') };
  padding: 16px;

  ${ TABLET } {
    display: table-cell;
  }
`;

export const TableHeading = styled.th`
  ${ cellProps }
  background-color: ${ TABLE_HEADER_BACKGROUND };
  cursor: ${ ({ onClick }) => (onClick ? 'pointer' : 'default') };
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 

  &:first-child {
    border-top-left-radius: ${ ({ showTitle }: CellProps) => (showTitle ? `${ borderRadius }px` : '0') };
  }

  &:last-child {
    border-top-right-radius: ${ ({ showTitle }: CellProps) => (showTitle ? `${ borderRadius }px` : '0') };
  }
  
  * {
    vertical-align: middle;
  }
`;
TableHeading.displayName = 'TableHeading';

export const TableData = styled.td`
  ${ cellProps }
  text-align: ${ ({ isLeftAlign }: CellProps) => (isLeftAlign ? 'left' : 'center') };
`;
TableData.displayName = 'TableData';

interface SortArrowProps {
  reverse: boolean;
}

export const SortArrow = styled.img`
  transform: ${ ({ reverse }: SortArrowProps) => reverse && 'rotate(180deg)' };
  width: 20px;
`;
SortArrow.displayName = 'SortArrow';

export const TableFooter = styled.div`
  background-color: ${ TABLE_FOOTER_BACKGROUND };
  border-bottom-left-radius: ${ borderRadius }px;
  border-bottom-right-radius: ${ borderRadius }px;
  color: ${ TABLE_COLOR };
  display: flex;
  flex-direction: column;
  justify-content: ${ ({ isPaginated }: TableBodyProps) => (!isPaginated ? 'flex-end' : 'flex-start') };

  font-size: 14px;
  padding: 12px;
  text-align: center;
  width: 100%;

  ${ TABLET } {
    flex-direction: row;
  }
`;
TableFooter.displayName = 'TableFooter';
