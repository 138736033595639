import React from 'react';
import { useSelector } from 'react-redux';
import Page from 'components/Page';
import { canDo } from 'containers/Core/selectors';
import NotificationsWidget from 'containers/Notifications/components/NotificationsWidget';
import QuotasWidget from 'containers/Quotas/components/QuotasWidget';
import StatisticsWidget from 'containers/Statistics/components/StatisticsWidget';
import { getCronList } from 'app/containers/Statistics/api';
import AsideHome from '../components/AsideHome';
import PickupButton from '../components/PickupButton';
import { SplitInfo } from '../styles';

const HomeScreen: React.FC = () => {
  const canSeeNotifications = useSelector(canDo('VIEW_NOTIFICATIONS'));
  const canCreatePickups = useSelector(canDo('CREATE_PICKUPS'));
  const canSeeQuotas = useSelector(canDo('VIEW_QUOTAS'));
  const canSeeStatistics = useSelector(canDo('VIEW_STATISTICS'));
  getCronList();

  return (
    <Page>
      { (canSeeNotifications || canCreatePickups) && (
        <SplitInfo isReverse={ true }>
          { canSeeNotifications && <NotificationsWidget maxItems={ 5 } pickupButton={ <PickupButton /> } /> }
          { canCreatePickups && <AsideHome /> }
        </SplitInfo>
      ) }

      { (canSeeQuotas || canSeeStatistics) && (
        <SplitInfo>
          { canSeeQuotas && <QuotasWidget maxItems={ 3 } /> }
          { canSeeStatistics && <StatisticsWidget isOverview={ true } /> }
        </SplitInfo>
      ) }
    </Page>
  );
};

export default HomeScreen;
