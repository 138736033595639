import React from 'react';
import Buttons from 'components/Buttons';
import { useHistory } from 'react-router-dom';
import { ONBOARDING } from 'app/routes';
import { useTranslationsContext } from 'app/translations';

const OnBoardingButton: React.FC = () => {
  const translations = useTranslationsContext();
  const history = useHistory();

  return (
    <div style={ { marginBottom: '25px', marginTop: '25px' } }>
      <Buttons
        options={ [
          {
            buttonType: 'primary',
            id: 'onBoardingButton',
            label: translations.login_footerHere,
            onClick: () => {
              history.push(ONBOARDING.path);
            },
            type: 'button'
          }
        ] }
      />
    </div>
  );
};

export default OnBoardingButton;