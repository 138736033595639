import React from 'react';

import { Container, StatusIcon, TextWrapper, Text } from './styles';
import { SeverityValues, SeverityType } from './types';

interface Props {
  severity?: SeverityType;
  title?: string;
  description?: string | React.ReactNode;
  showIcon?: boolean;
  inlineText?: boolean;
}

const successIcon = require('assets/icons/success.svg');
const warningIcon = require('assets/icons/alert.svg');
const infoIcon = require('assets/icons/alert.svg');
const errorIcon = require('assets/icons/alert.svg');

const severityValues: SeverityValues = {
  [SeverityType.SUCCESS]: {
    icon: successIcon,
    title: 'Success',
    description: ''
  },
  [SeverityType.WARNING]: {
    icon: warningIcon,
    title: 'Warning',
    description: ''
  },
  [SeverityType.INFO]: {
    icon: infoIcon,
    title: 'Info',
    description: ''
  },
  [SeverityType.ERROR]: {
    icon: errorIcon,
    title: 'Error',
    description: ''
  }
};

const StatusMessage: React.FC<Props> = ({ description,
  inlineText,
  severity = SeverityType.INFO,
  showIcon = true,
  title }) => (
  <Container>
    { showIcon && <StatusIcon src={ severityValues[severity].icon } /> }

    <TextWrapper inlineText={ inlineText }>
      <Text isBold={ true }>{ title || severityValues[severity].title } </Text>
      { description && <Text>{ description }</Text> }
    </TextWrapper>
  </Container>
);

export default StatusMessage;
