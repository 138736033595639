import styled from 'styled-components';
import { TITLE_COLOR } from 'base/styles/skin';

export const WrapperTitle = styled.div`
  display: flex;
  padding-bottom: 20px;
  flex-direction: row;
  align-items: baseline;
    height: 55px;
`;
WrapperTitle.displayName = 'WrapperTitle';

export const Title = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  font-size: 22px;
  font-weight: 500;
  color: ${ TITLE_COLOR };
  display: flex;
  align-items: center;
  justify-content: ${ props => (props.className === 'templateSectionHome' ? 'space-between;' : null) };

  > div {
    margin-left: 10px;
  }
`;
Title.displayName = 'Title';
