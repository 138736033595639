import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonProps } from 'components/Touchable/types';
import { useTranslationsContext } from 'app/translations';

export const useFormButtons = () => {
  const translations = useTranslationsContext();
  const history = useHistory();

  const onCancel = useCallback(() => {
    history.goBack();
  }, []);

  const buttons: ButtonProps[] = [
    {
      id: 'cancel',
      type: 'button',
      label: translations.confirmModal_cancel,
      onClick: onCancel
    },
    {
      id: 'submit',
      type: 'submit',
      buttonType: 'primary',
      label: translations.help_submitLabel
    }
  ];
  return buttons;
};
