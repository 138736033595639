import styled from 'styled-components';
import * as SIZES from 'base/styles/sizes';
import { EXPORT_DESCRIPTION_COLOR, MODAL_FOOTER } from 'base/styles/skin';
import { DESKTOP, TABLET } from 'base/styles/media-queries';

export const TextDescription = styled.div`
  padding: 24px;
  padding-top: 0;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: pre-line;
  color: ${ EXPORT_DESCRIPTION_COLOR };
`;
TextDescription.displayName = 'TextDescription';

export const FormWrapper = styled.div`
  display: flex;
  color: #3a3a3a;
  font-size:14px;
  flex-direction: column;
  align-items: start;

  & > *:last-child {
    margin-right: 0;
  }
`;
FormWrapper.displayName = 'FormWrapper';

export const FieldWrapper = styled.div`
  width: 100%;
  flex: 0;
  margin: 0 0px;
`;
FieldWrapper.displayName = 'FieldWrapper';

export const Footer = styled.footer`
  width: 100%;
  min-height: ${ SIZES.MODAL_FOOTER_SIZE }px;
  background-color: ${ MODAL_FOOTER };
  padding: 0 24px;
  display: inline-block;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 0 4px 4px;
`;
Footer.displayName = 'Footer';

export const Body = styled.div`
  padding: 24px;
  padding-top: 0;
  white-space: pre-line;
`;
export const ButtonsWrapper = styled.div`
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > button {
      margin: 4px;
    }

    ${ TABLET } {
      display: block;
    }
  }
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';

export const DateWrapper = styled.div`

    & .react-datepicker {
      right: 50px;
      top: 60px;
      .react-datepicker__day-name{
        width:2.8rem;
      }
      .react-datepicker__day{
        width:50px;
      }
      ${ DESKTOP } {
      right: 0px;
      top: 0px;
    }
    ${ TABLET } {
      right: 0px;
      top: 0px;
    }
  }

`;
DateWrapper.displayName = 'DateWrapper';
