/* eslint-disable max-lines */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useIsLoading } from 'app/hooks';
import { FieldProps } from 'components/SmartForm/types';
import { ButtonProps } from 'components/Touchable/types';
import { TEXT, EMAIL, CHECKBOX, CAPTCHA } from 'components/SmartForm/models';
import { useTranslationsContext } from 'app/translations';
import SplitView from 'containers/Core/components/SplitView';
import { SeverityType, StatusResponse } from 'components/StatusMessage/types';
import SectionTitle from 'components/SectionTitle';
import Modal from 'app/components/Modal';
import Buttons from 'app/components/Buttons';
import Header from './components/Header';

import SuccessConfirmation from './components/SuccessConfirmation';
import PrivacyAgreementLink from './components/PrivacyAgreementLink';
import FormBody from './components/FormBody';
import { onboardingRequest } from './actions';
import { OnboardingRequest } from './types';
import { InfoContainer,
  InfoContent,
  InfoText,
  FormContainer,
  WrapperForm,
  WrapperHeader,
  WrapperTitle,
  ExternalLink,
  IconsRow,
  IconWrapper,
  Icon,
  IconTitleTextWrapper,
  IconTitle,
  IconText,
  OnboadingList,
  SectionSubTitle } from './styles';
import PrivacyAgreementView from './components/PrivacyAgreementView';

const tel = '033 226 57 11';
const mailto = 'markt@avag.ch';
const web = 'https://www.avag.ch/avag-kundenportal';

const onboarding1 = require('assets/icons/onboarding/clock.svg');
const onboarding2 = require('assets/icons/onboarding/stopwatch.svg');
const onboarding3 = require('assets/icons/onboarding/docs.svg');
const onboarding4 = require('assets/icons/onboarding/statistics.svg');
const onboarding5 = require('assets/icons/onboarding/contingent.svg');

/* eslint-disable max-lines-per-function */
export const Onboarding: React.FC = () => {
  const translations = useTranslationsContext();
  const [onboardError, setOnboardError] = React.useState<string>();
  const [onboardSuccess, setOnboardSuccess] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const dispatch = useDispatch();
  const isLoading = useIsLoading(['onboarding']);

  const icons = [
    {
      icon: onboarding1,
      title: translations.onboarding_title_icon_1,
      text: translations.onboarding_text_icon_1
    },
    {
      icon: onboarding2,
      title: translations.onboarding_title_icon_2,
      text: translations.onboarding_text_icon_2
    },
    {
      icon: onboarding3,
      title: translations.onboarding_title_icon_3,
      text: translations.onboarding_text_icon_3
    },
    {
      icon: onboarding4,
      title: translations.onboarding_title_icon_4,
      text: translations.onboarding_text_icon_4
    },
    {
      icon: onboarding5,
      title: translations.onboarding_title_icon_5,
      text: translations.onboarding_text_icon_5
    }
  ];

  const fields: FieldProps[] = [
    {
      id: 'first_name',
      label: translations.onboarding_nameLabel,
      type: TEXT,
      isRequired: true,
      placeholder: translations.onboarding_namePlaceholder
    },
    {
      id: 'last_name',
      label: translations.onboarding_lastNameLabel,
      type: TEXT,
      isRequired: true,
      placeholder: translations.onboarding_lastNamePlaceholder
    },
    {
      id: 'company',
      label: translations.onboarding_companyLabel,
      type: TEXT,
      isRequired: true,
      placeholder: translations.onboarding_companyPlaceholder
    },
    {
      id: 'phone',
      label: translations.onboarding_phoneLabel,
      type: TEXT,
      isRequired: true,
      placeholder: translations.onboarding_phonePlaceholder,
    },
    {
      id: 'email',
      label: translations.onboarding_emailLabel,
      type: EMAIL,
      isRequired: true,
      placeholder: translations.onboarding_emailPlaceholder,
      messageLabel: {
        labelText: 'Passwort setzen',
        className: 'passwordTite'
      },
      messageInfo: {
        text: translations.onboarding_password_info,
        className: 'passwordContent'
      },
    },
    {
      id: 'password',
      label: translations.resetPassword_newPasswordLabel,
      type: 'password',
      isRequired: true,
      strengthCheck: true,
      placeholder: translations.resetPassword_passwordPlaceholder
    },
    {
      id: 'confirm_password',
      label: translations.resetPassword_confirmPasswordLabel,
      type: 'password',
      isRequired: true,
      placeholder: translations.resetPassword_passwordPlaceholder
    },
    {
      id: 'captcha',
      type: CAPTCHA
    },
    {
      id: 'privacy-policy',
      label: <PrivacyAgreementLink setOpenModal={ setOpenPrivacy } />,
      type: CHECKBOX,
      isRequired: true,
      isChecked: false
    }
  ];

  const buttons: ButtonProps[] = [
    {
      id: 'requestAccessSubmit',
      type: 'submit',
      buttonType: 'primary',
      label: translations.onboarding_submitLabel
    }
  ];

  const handleClose = () => {
    setOpenPrivacy(false);
  };

  const modalButtons: ButtonProps[] = [
    {
      id: 'closePrivacy',
      type: 'button',
      onClick: handleClose,
      label: translations.onboarding_closePrivacyAgreement
    }
  ];

  const handleSubmit = async (controlledFields: FieldProps[]) => {
    const firstName = controlledFields.find(field => field.id === 'first_name')?.model || '';
    const lastName = controlledFields.find(field => field.id === 'last_name')?.model || '';
    const clientName = controlledFields.find(field => field.id === 'company')?.model || '';
    const phone = controlledFields.find(field => field.id === 'phone')?.model || '';
    const email = controlledFields.find(field => field.id === 'email')?.model || '';
    const password = controlledFields.find(field => field.id === 'password')?.model || '';
    const confirm_password = controlledFields.find(field => field.id === 'confirm_password')?.model || '';

    const checkboxItem = controlledFields.find(field => field.id === 'privacy-policy');

    if (checkboxItem.isChecked) {
      const request: OnboardingRequest = {
        first_name: firstName,
        last_name: lastName,
        client_name: clientName,
        phone,
        user_email: email,
        onboarding_password: password,
        onboarding_conofirm_password: confirm_password,
      };

      const { type, payload } = await dispatch(onboardingRequest(request));
      if (type.includes('ERROR')) {
        // @ts-ignore
        const errorMessage = payload?.response?.data?.message || '';
        setOnboardError(errorMessage);
      } else if (type.includes('SUCCESS')) {
        setOnboardError('');
        setOnboardSuccess(true);
      }
    }
  };

  const statusResponseError: StatusResponse = {
    severity: SeverityType.ERROR,
    description: onboardError
  };

  const leftContent = (
    <InfoContainer>
      <InfoContent>
        <WrapperHeader>
          <Header />
        </WrapperHeader>

        <InfoText>
          { translations.onboarding_text_1 }{ ' ' }
        </InfoText>
        <IconsRow>
          { icons.map((i, index) => (
            <IconWrapper key={ index }>
              <Icon src={ i.icon } />
              <IconTitleTextWrapper>
                <IconTitle>{ i.title }</IconTitle>
                <IconText>{ i.text }</IconText>
              </IconTitleTextWrapper>
            </IconWrapper>
          )) }
        </IconsRow>
        <InfoText>
          { translations.onboarding_text_2 }{ ' ' }
          <OnboadingList>
            <ul className="onbording_sqaure_list">
              <li><ExternalLink href={ web } target="_blank" rel="noopener noreferrer">
                { web.replace('https://', '') }
              </ExternalLink></li>
              <li><ExternalLink href={ `tel:${ tel }` } target="_blank" rel="noopener noreferrer">
                { tel }
              </ExternalLink>{ ' ' }</li>
              <li><ExternalLink href={ `mailto:${ mailto }` } target="_blank" rel="noopener noreferrer">
                { mailto }
              </ExternalLink></li>
            </ul>
          </OnboadingList>
        </InfoText>
      </InfoContent>
    </InfoContainer>
  );

  const rightContent = (
    <FormContainer>
      <WrapperHeader>
        <Header />
      </WrapperHeader>

      <WrapperTitle>
        <SectionTitle>{ translations.onboarding_formTitle }</SectionTitle>
        { onboardSuccess ? ' '
          : <SectionSubTitle>{ translations.onboarding_noteTextmandatory }</SectionSubTitle>
        }
      </WrapperTitle>

      <WrapperForm>
        { onboardSuccess ? (
          <SuccessConfirmation />
        ) : (
          <FormBody
            formName="onboarding"
            fields={ fields }
            handleSubmit={ handleSubmit }
            buttons={ buttons }
            isLoading={ isLoading }
            statusResponse={ onboardError && statusResponseError }
          >
          </FormBody>
        ) }
      </WrapperForm>

      { openPrivacy && (
        <Modal
          header={ translations.onboarding_privacy2 }
          footer={ <Buttons options={ modalButtons } /> }
          onClose={ handleClose }
          size="lg"
        >
          <PrivacyAgreementView isModal={ true } />
        </Modal>
      ) }
    </FormContainer>
  );

  return <SplitView left={ leftContent } right={ rightContent } />;
};

export default Onboarding;
