import React, { PropsWithChildren } from 'react';
import { useTranslationsContext } from 'app/translations';
import { UserStatus } from 'containers/Users/types';
import { Wrapper, Title, Message } from './styles';

interface Props {
  status?: UserStatus;
}

const StatusMessage: React.FC<PropsWithChildren<Props>> = ({ status }) => {
  const translations = useTranslationsContext();

  switch (status) {
    case UserStatus.USER:
      return null;

    case UserStatus.PASSWORD_PENDING:
      return (
        <Wrapper>
          <Title>{ translations.userDetails_passwdPendingTitle }</Title>
          <Message> { translations.userDetails_passwdPendingDescription }</Message>
        </Wrapper>
      );

    // New user
    case UserStatus.ACCESS_REQUESTED:
    default:
      return (
        <Wrapper>
          <Title>{ translations.userDetails_newUserTitle }</Title>
          <Message> { translations.userDetails_newUserDescription }</Message>
        </Wrapper>
      );
  }
};

export default StatusMessage;
