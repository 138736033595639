import { http, envConf } from 'base';
import { Pagination } from 'app/types';
import { Quota } from 'containers/Quotas/types';
import { createPaginationFromServer } from 'app/models';
import * as Model from '../models/fromServer';

interface QuotasResponse {
  collection: Quota[];
  pagination: Pagination;
}
export const getQuotaList = async (offset: number, limit: number): Promise<QuotasResponse> => {
  try {
    const { data } = await http.get(envConf.API.QUOTA_LIST_GET, {
      params: { limit, offset }
    });
    const { quotas, ...pagination } = data;

    return {
      collection: Model.createQuotaListFromServer(quotas),
      pagination: createPaginationFromServer(pagination)
    };
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};