import axios, { AxiosInstance } from 'axios';

const createInstance = (): AxiosInstance => {
  const instance = axios.create({
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  });

  instance.interceptors.response.use(response => response?.data);
  return instance;
};

export const http = createInstance();
