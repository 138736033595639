/* eslint-disable max-len */
import { useTranslationsContext } from 'app/translations';
import { ButtonProps } from 'components/Touchable/types';
import { TEMPLATE_TYPE } from '../../types';

export const useButtons = (onDismiss: () => void, modalType: string): ButtonProps[] => {
  const translations = useTranslationsContext();
  const buttons: ButtonProps[] = [];

  buttons.push({
    id: 'templateModal_cancel',
    type: 'button',
    isAlwaysEnabled: true,
    label: translations.template_modalBox_cancel,
    onClick: onDismiss
  });
  if (modalType === TEMPLATE_TYPE.processOrderTemplate) {
    buttons.push({
      id: 'templateModal_confirm',
      type: 'submit',
      buttonType: 'primary',
      isAlwaysEnabled: true,
      label: translations.pickupRequestDetails_abortConfirm
    });
    return buttons;
  }
  if (modalType === TEMPLATE_TYPE.deleteTemplate) {
    buttons.push({
      id: 'templateModal_confirm',
      type: 'submit',
      buttonType: 'primary',
      isAlwaysEnabled: true,
      label: translations.pickupRequestDetails_abortConfirm
    });
    return buttons;
  }
  if (modalType === TEMPLATE_TYPE.updateTemplate) {
    buttons.push({
      id: 'templateModal_confirm',
      type: 'submit',
      buttonType: 'primary',
      isAlwaysEnabled: true,
      label: translations.pickupRequestDetails_abortConfirm
    });
    return buttons;
  }
  if (modalType === TEMPLATE_TYPE.saveAsTemplate) {
    buttons.push({
      id: 'templateModal_SaveAs',
      type: 'submit',
      buttonType: 'primary',
      isAlwaysEnabled: true,
      label: translations.pickupRequestDetails_abortConfirm
    });
  }

  return buttons;
};
