import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PickupRequestStatus } from 'app/constants/pickupRequest';
import { RouteWithID } from 'app/types';
import { getBookingRefs } from 'containers/BookingRefs/actions';
import { isAvagAdmin } from 'containers/Core/selectors';
import { getLocations } from 'containers/Location/actions';
import { getLoginData } from 'containers/Login/selectors';
import * as actions from '../actions';
import { ActionTypes } from '../actionTypes';
import { PickupRequestParams } from '../types';

export * from './modal';

export const usePickupListRequest = (params: PickupRequestParams) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getPickupRequestList(params));
  }, [params.offset, params.limit, params.order, params.rowname, params.filterValue]);
};

export const usePickupListReset = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.resetPickupRequestList());
  }, []);
};

export const useAutocompleteSuggestions = (companyId?: string) => {
  const { client_id } = useSelector(getLoginData);
  const isAdmin = useSelector(isAvagAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    let id: string;
    if (isAdmin && companyId) {
      id = companyId;
    } else if (!isAdmin && client_id) {
      id = client_id;
    }

    if (id) {
      dispatch(getBookingRefs(id));
      dispatch(getLocations(id));
      dispatch(actions.getRecentContainerList(id));
      dispatch(actions.getContainerList(id));
    }
  }, [client_id, companyId, isAdmin]);
};

export const usePickupRequest = (setHasLoadedDetails: (state: boolean) => void) => {
  const { id } = useParams<RouteWithID>();
  const isAdmin = useSelector(isAvagAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      // @ts-ignore
      dispatch(actions.getPickupRequest(id)).then(({ type, payload }: PickupRequestActionType) => {
        setHasLoadedDetails(true);
        if (type === ActionTypes.GET_PICKUP_SUCCESS && isAdmin && payload?.status === PickupRequestStatus.NEW) {
          dispatch(actions.processPickupRequest(payload));
        }
      });
    }
  }, [id]);
};

export const useMaterialRequest = (containerId: string, clientId: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (containerId) {
      dispatch(actions.getMaterialList(containerId, clientId));
    }
  }, []);
};
