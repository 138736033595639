import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslationsContext } from 'app/translations';
import { BILL } from 'app/routes';
import Buttons from 'components/Buttons';
import { DeliverySlipStatus } from 'containers/DeliverySlips/models';
import { Container, TextWrapper, Text } from './styles';

interface Props {
  status: DeliverySlipStatus;
  billId: string;
}

const CustomMessage: React.FC<Props> = ({ status, billId }) => {
  const history = useHistory();
  const { deliverySlipDetails_messageTitle,
    deliverySlipDetails_messageNew,
    deliverySlipDetails_openInvoice } = useTranslationsContext();
  let description;
  let isInvoiced;
  switch (status) {
    case DeliverySlipStatus.NEW:
      description = deliverySlipDetails_messageNew;
      isInvoiced = false;
      break;
    default:
      description = '';
      isInvoiced = false;
  }

  // Disclaimer should not be rendered for Approved status
  if (status === DeliverySlipStatus.APPROVED) {
    return null;
  }

  return (
    <Container>
      { description && (
        <TextWrapper>
          <Text isBold={ true }>{ deliverySlipDetails_messageTitle }: </Text>
          <Text>{ description }</Text>
        </TextWrapper>
      ) }

      { isInvoiced && (
        <Buttons
          options={ [
            {
              id: 'openInvoice',
              label: deliverySlipDetails_openInvoice,
              onClick: () => history.push(BILL.path.replace(':id', billId)),
              type: 'button',
              buttonType: 'primary'
            }
          ] }
        />
      ) }
    </Container>
  );
};
export default CustomMessage;
