import React from 'react';
import Searcher from 'components/Searcher';
import { SEARCH } from 'components/SmartForm/models';

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Filter: React.FC<Props> = ({ onChange }) => (
  <Searcher
    onBlur={ () => {} }
    onChange={ onChange }
    onClick={ () => {} }
    onFocus={ () => {} }
    options={ {
      id: 'filter',
      type: SEARCH
    } }
  />
);

export default Filter;
