import styled from 'styled-components';

import { FIELD_LABEL } from 'base/styles/skin';
import { Bar } from 'components/ProgressBar/styles';

export const Wrapper = styled.div`
  width: calc(100% + 4px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  & ${ Bar } {
    margin-left: 4px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.span`
  margin: 0 12px;
  font-size: 12px;
  color: ${ FIELD_LABEL };
`;
Text.displayName = 'Text';
