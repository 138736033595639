import produce from 'immer';
import { createReducer, isMockingApi } from 'base';
import { ActionHandler } from 'base/types';
import { ActionTypes as LogoutActions } from 'containers/Login/actionTypes';
import { ActionTypes, NotificationsActionType } from '../actionTypes';
import { NOTIFICATION_LIST } from '../mocks';
import { notificationsInitialState } from '../models';
import { NotificationsState } from '../types';

const getNotificationListSuccess = (state: NotificationsState, action: NotificationsActionType) =>
  produce(state, draft => {
    const { collection, pagination } = action.payload as NotificationsState;
    draft.collection = collection || state.collection;
    draft.pagination = pagination || state.pagination;
  });

const getNotificationListError = (state: NotificationsState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.collection = NOTIFICATION_LIST.slice(0, draft.pagination.pageSize);
      draft.pagination.totalCount = NOTIFICATION_LIST.length;
    }
  });

const resetNotifications = (state: NotificationsState) =>
  produce(state, draft => {
    draft.collection = [];
  });

const getInitialState = (state: NotificationsState) =>
  produce(state, draft => {
    Object.keys(draft).forEach(key => {
      // @ts-ignore
      draft[key] = notificationsInitialState[key];
    });
  });

const actionHandlers: ActionHandler<NotificationsState> = {
  [ActionTypes.GET_NOTIFICATION_LIST_SUCCESS]: getNotificationListSuccess,
  [ActionTypes.GET_NOTIFICATION_LIST_ERROR]: getNotificationListError,
  [ActionTypes.RESET_NOTIFICATIONS]: resetNotifications,
  [LogoutActions.LOGOUT_REQUEST]: getInitialState
};

export default createReducer(notificationsInitialState, actionHandlers);
