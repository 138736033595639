import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import {
  BUTTON_COLOR_HOVER,
  BUTTON_BACKGROUND,
  BUTTON_PRIMARY_COLOR,
  BUTTON_PRIMARY_BACKGROUND,
  BUTTON_BORDER, BUTTON_COLOR, LINK_COLOR, LINK_COLOR_HOVER
} from 'base/styles/skin';

import { DESKTOP } from 'base/styles/media-queries';

// MOBILE_L
interface Props {
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  id?: number;
}
export const Tabs = styled.div`
  display: flex;
  margin: 10px 0;
  color:#5fa550;
  `;
Tabs.displayName = 'Tabs';
  
export const Tab = styled(NavLink)`
    // background-color: ${ BUTTON_BACKGROUND };
    // border: ${ '1px solid '+BUTTON_BORDER };
    // border-radius: 5px;
    // color:  ${ BUTTON_COLOR };
    // outline: none;
    // cursor: pointer;
    // padding: 6px 15px;
    // position: relative;
    // margin-right: 0.1em;
    // font-size: 1em;
    // &:hover {
    //     border-color: ${ BUTTON_COLOR_HOVER };
    //     background-color: ${ BUTTON_BACKGROUND };
    //     color: ${ BUTTON_COLOR_HOVER };
    // }
    // &.active {
    //  background-color: ${ BUTTON_PRIMARY_BACKGROUND };
    //  color:${ BUTTON_PRIMARY_COLOR };
    //     &:hover {
    //           border-color: ${ BUTTON_COLOR_HOVER };
    //           background-color: ${ BUTTON_COLOR_HOVER };        
    //     }
    // }
    color: ${ LINK_COLOR };
    &:hover{
        color: ${ LINK_COLOR_HOVER };
    }
  ${ DESKTOP }{
   padding: 6px 0px;
  }
`;
Tab.displayName = 'Tab';
export const Content = styled.div`
  /* ${ ({ active }: Props) => (active ? '' : 'display:none') } */
`;
Content.displayName = 'Content';