import styled from 'styled-components';
import { FIELD_LABEL } from 'base/styles/skin';
import { DESKTOP } from 'base/styles/media-queries';

const TITLE_SIZE = 24;
const COLUMN_WIDTH = 120;

interface OverviewProps {
  isAreaChart: boolean;
  isOverview: boolean;
}

export const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  ${ DESKTOP } {
    margin-top: 0;
    min-width: ${ ({ isAreaChart, isOverview }: OverviewProps) => {
    if (!isOverview) {
      if (isAreaChart) {
        return '150px';
      }

      return '300px';
    }

    return null;
  } };
    padding-left: 20px;
  }
`;
LegendWrapper.displayName = 'LegendWrapper';

export const ListTitle = styled.h3`
  line-height: ${ TITLE_SIZE }px;
`;
ListTitle.displayName = 'ListTitle';

interface ListWrapperProps extends OverviewProps {
  maxHeight: number;
  hasTitle: boolean;
}

export const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${ COLUMN_WIDTH }px, 1fr));
  padding: 10px 0;
  max-height: ${ ({ maxHeight = 0, hasTitle }: ListWrapperProps) => {
    if (hasTitle) {
      return `${ maxHeight - TITLE_SIZE }px`;
    }

    return `${ maxHeight }px`;
  } };
  overflow: hidden;
  overflow-y: auto;

  ${ ({ isAreaChart, isOverview }: ListWrapperProps) => {
    if (!isOverview) {
      return `${ DESKTOP } {
        grid-template-columns: ${ isAreaChart ? '1fr' : '1fr 1fr 1fr' } ;
        grid-auto-flow: row dense;
      }`;
    }
    return null;
  } }
`;
ListWrapper.displayName = 'ListWrapper';

export const ListItem = styled.div`
  // align-items: center;
  color: ${ FIELD_LABEL };
  display: flex;
  flex-direction: row;
  //font-family: Roboto;
  font-family: 'TT Norms';
  font-size: 10px;
  margin: 5px 0;
`;
ListItem.displayName = 'ListItem';

interface TagColorProps {
  color: string;
}

export const TagColor = styled.div`
  background-color: ${ ({ color }: TagColorProps) => color };
  display: flex;
  margin-right: 10px;
  height: 25px;
  min-width: 25px;
`;
TagColor.displayName = 'TagColor';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: top;
`;
Content.displayName = 'Content';

export const Title = styled.div`
  font-weight: bold;
`;
Title.displayName = 'Title';

export const Details = styled.span``;
Details.displayName = 'Details';
