import React, { useState } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { configureStore } from 'base/store';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as ROUTES from 'app/routes';
import { Provider as TranslationsProvider, LangKeys, getTranslations } from 'app/translations';
import MocksAdvisor from 'components/MocksAdvisor';
import Redirect from 'components/Redirect';
import { UnsupportedBrowserProvider } from 'app/context/unsupportedBanner';
import { APP } from '../../../env/env.common';
import { getCompaniesSimple } from '../Companies/actions';
import { getLoginData } from '../Login/selectors';
import MaintenancePage from '../Maintenance';
import Notifier from '../Notifier';
import { useUserRoutes, useGuestRoutes } from './hooks';
import { Global } from './styles';

const store = configureStore();

const translate = async (setTranslations: React.Dispatch<React.SetStateAction<LangKeys>>) => {
  const translations = await getTranslations();
  setTranslations(translations);
};

const emptyTranslations = (translations: LangKeys) => Object.keys(translations).length === 0;

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [translations, setTranslations] = useState({} as LangKeys);
  const user = useSelector(getLoginData);
  const guestRoutes = useGuestRoutes();
  const userRoutes = useUserRoutes();
  const maintenanceMode = process.env.MAINTENANCE_MODE === 'true';
  
  React.useEffect(() => {
    document.title = APP.APP_NAME;
    translate(setTranslations);
  }, []);

  React.useEffect(() => {
    if (user) {
      dispatch(getCompaniesSimple());
    }
  }, [user]);

  const shouldRenderRoutes = !emptyTranslations(translations);

  return (
    <UnsupportedBrowserProvider>
      <TranslationsProvider value={ translations }>
        <Global />

        { maintenanceMode && <MaintenancePage /> }
        { !maintenanceMode && shouldRenderRoutes && (
          <Router>
            <Switch>
              { (user ? userRoutes : guestRoutes).map((route, index) => (
                <Route key={ index } { ...route } />
              )) }
              <Redirect to={ user ? ROUTES.HOME.path : ROUTES.ROOT.path } />
            </Switch>
          </Router>
        ) }

        <MocksAdvisor />
        <Notifier />
      </TranslationsProvider>
    </UnsupportedBrowserProvider>
  );
};

const Core: React.FC = () => (
  <Provider store={ store as any }>
    <App />
  </Provider>
);

export default Core;
