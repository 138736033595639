import { LangKeys } from 'app/translations';
import { renderers } from 'components/Table';
import { Column } from 'components/Table/types';
import { Currency } from 'components/Table/renderers';
import { BillTotalRow, BillDetailsRow } from 'containers/Bills/types';

export const createColumns = (translations: LangKeys): Column<BillDetailsRow>[] => [
  {
    key: 'date',
    label: translations.billDetails_tableHeader_date,
    renderer: renderers.Date,
    mobile: true
  },
  {
    key: 'deliveryNoteId',
    label: translations.billDetails_tableHeader_deliveryNoteId,
    mobile: true
  },
  {
    key: 'material',
    label: translations.billDetails_tableHeader_material
  },
  {
    key: 'quantity',
    label: translations.billDetails_tableHeader_quantity
  },
  {
    key: 'unit',
    label: translations.billDetails_tableHeader_unit
  },
  {
    key: 'price',
    label: translations.billDetails_tableHeader_price,
    renderer: Currency
  },
  {
    key: 'amount',
    label: translations.billDetails_tableHeader_amount,
    renderer: Currency
  },
  {
    key: 'VAT',
    label: translations.billDetails_tableHeader_vat
  },
  {
    key: 'navigate',
    renderer: renderers.ArrowButton,
    mobile: true
  }
];

export const createColumnsTotal = (): Column<BillTotalRow>[] => [
  {
    key: 'info',
    mobile: true
  },
  {
    key: 'secondaryAmount'
  },
  {
    key: 'mainAmountLabel',
    mobile: true
  },
  {
    key: 'mainAmount',
    mobile: true
  },
];
