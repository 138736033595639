import { RootState } from 'base/types';
import { CREATE_URL } from 'app/constants/routes';
import { pickupRequestDetailsInitialState } from '../models';
import { Container } from '../types';

export const getPickupRequestList = ({ pickupRequest: { collection, pagination } }: RootState) => ({
  collection,
  pagination
});

export const getPickupRequest = (id?: string) => ({ pickupRequest }: RootState) =>
  (id && id !== CREATE_URL
    ? pickupRequest.collection.find(pickup => pickup.id === id)
    : pickupRequestDetailsInitialState);

export const getPickupStatus = (id?: string) => (rootState: RootState) => getPickupRequest(id)(rootState)?.status;

export const getRecentContainerList = ({ pickupRequest }: RootState) => pickupRequest.dropdownGroup.recentsList;

export const getContainerList = (type?: string) => ({ pickupRequest }: RootState): Container[] => {
  const { containerList } = pickupRequest.dropdownGroup;
  if (!type) {
    return containerList;
  }

  return containerList.filter(c => c.type === type);
};

export const getContainer = (id: string) => ({ pickupRequest }: RootState) =>
  pickupRequest.dropdownGroup.containerList.find(c => c.id === id);

export const getTypesList = ({ pickupRequest }: RootState) => pickupRequest.dropdownGroup.typesList;

export const getMaterialList = ({ pickupRequest }: RootState) => pickupRequest.dropdownGroup.materialList;