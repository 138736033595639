import * as Yup from 'yup';
import * as validations from 'app/constants/validations';
import { useTranslationsContext } from 'app/translations';

export const useValidationSchema = (): Yup.ObjectSchema => {
  const translations = useTranslationsContext();
  return Yup.object().shape({
    subject: validations.subject(translations),
    recipient: validations.recipient(translations),
    message: validations.message(translations)
  });
};
