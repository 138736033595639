import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BUTTON_COLOR_HOVER, BUTTON_PRIMARY_BACKGROUND, BUTTON_PRIMARY_BORDER, BUTTON_PRIMARY_COLOR, BUTTON_BACKGROUND, BUTTON_BORDER, BUTTON_COLOR } from 'base/styles/skin';

export const CreatePickupLink = styled(Link)`
    &,
  &:link,
  &:visited {
  background-color: ${BUTTON_BACKGROUND};
  color: ${BUTTON_COLOR};
  border: ${'1px solid ' + BUTTON_BORDER};
  padding: 6px 15px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  text-decoration: none;
  border-radius: 5px;
  display: flex;
  :hover {
   color: ${ BUTTON_COLOR_HOVER };
   border-color: ${ BUTTON_COLOR_HOVER };
 }
   }
`;
export const  CreateTemplateLink= styled(Link)`
    &,
  &:link,
  &:visited {
  background-color: ${BUTTON_PRIMARY_BACKGROUND};
  color: ${BUTTON_PRIMARY_COLOR};
  border: ${'1px solid ' + BUTTON_PRIMARY_BORDER};
  padding: 6px 15px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  text-decoration: none;
  border-radius: 5px;
  display: flex;
  :hover {
   // color: ${ BUTTON_COLOR_HOVER };
    border-color: ${ BUTTON_COLOR_HOVER };
    background-color: ${ BUTTON_COLOR_HOVER };
 }
   }
`;
CreateTemplateLink.displayName = 'CreateTemplateLink';

export const CreateTemplateText = styled.div`
 p{
   margin-bottom:10px;
 }
`;
CreateTemplateText.displayName = 'CreateTemplateText';
