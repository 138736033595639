/* eslint-disable max-lines */
import { FormikValues } from 'formik';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, BrowserRouter as Router, Route } from 'react-router-dom';
import { isMockingApi } from 'base';
import { useIsLoading } from 'app/hooks';
import { useTranslationsContext } from 'app/translations';
import { PickupRequestStatus } from 'app/constants/pickupRequest';
// import { FieldProps } from 'components/SmartForm/types';
import { PICKUP_REQUESTS_LIST } from 'app/routes';
import { RouteWithID } from 'app/types';
import { getBookingRefList } from 'containers/BookingRefs/selectors';
import { getCompany } from 'containers/Companies/actions';
import { isAvagAdmin } from 'containers/Core/selectors';
import { PickupDetailsSkeleton } from 'components/Skeletons';
import { getLocationList } from 'containers/Location/selectors';
import { COMPANY_LIST } from 'containers/Companies/mocks';
import { Company, Customer } from 'containers/Companies/types';
import { createDownload, base64ToBlob } from 'app/utils/downloads';
import { formatDate } from 'app/utils/formatters';
import { Content } from 'components/Tabs/styles';
import { getContainerList, printPickupRequest, resendPickupRequest } from '../../actions';
import { usePickupRequest, useModalData, useAutocompleteSuggestions } from '../../hooks';
import { getPickupRequest, getMaterialList } from '../../selectors';
import View from './components/View';
import NavigatorTabs from './components/NavigatorTabs';
import { useFormData } from './data';
import * as handlers from './handlers';
import { MODALS,
  PrintAction,
  CloseAction,
  RemoveAction,
  SaveAction,
  CancelAction,
  RequestHandler,
  RequestContainerList,
  ResendAction} from './types';
import TemplateList from '../Templates/containers/TemplateList';
import SuccessModal from '../PickupRequestDetails/components/SuccessModal';
import { PickupRequest, ResendPickupRequest, TemplateActionDetails } from '../../types';

/* eslint-disable max-lines-per-function */
const PickupRequestDetails: React.FC = () => {
  const history = useHistory();
  const { pickupRequestDetails_printName } = useTranslationsContext();
  const { id } = useParams<RouteWithID>();
  const dispatch = useDispatch();
  const isLoading = useIsLoading(['get-pickup-requests']);
  const isUpdating = useIsLoading(['update-pickup-request']);
  const pickupRequest = useSelector(getPickupRequest(id));
  const materialList = useSelector(getMaterialList);
  const isAdmin = useSelector(isAvagAdmin);
  const locations = useSelector(getLocationList);
  const bookingRefs = useSelector(getBookingRefList);
  const [hasLoadedDetails, setHasDetails] = React.useState(!id);
  const [values, setValues] = React.useState<FormikValues>();
  const [openModal, setOpenModal] = React.useState<MODALS>(undefined);
  const [openSuccessModal, setopenSuccessModal] = React.useState(false);
  const [status, setStatus] = React.useState(PickupRequestStatus.DRAFT);
  const selectedCompany = values?.company?.id || pickupRequest?.company?.id;
  const [initialStatus, setInitialStatus] = React.useState<PickupRequestStatus>();
  // const translations = useTranslationsContext();
  const createNeuStatus: boolean = pickupRequest?.status === undefined;
  const currentPickupStatus: boolean = pickupRequest?.status !== undefined;
  usePickupRequest(setHasDetails);
  useAutocompleteSuggestions(isAdmin ? selectedCompany : undefined);


  React.useEffect(() => {
    if (!initialStatus && pickupRequest?.status) {
      setInitialStatus(pickupRequest.status);
    }
  }, [pickupRequest?.status]);

  const handleAbort: CancelAction = () => {
    setOpenModal(MODALS.ABORT);
  };
 
  const handlePrint: PrintAction = (isDirty: boolean) => async () => {
    if (isDirty) {
      setOpenModal(MODALS.PRINT);
    } else {
      try {
        const { type, payload } = await dispatch(printPickupRequest(id));
        if (type.includes('SUCCESS')) {
          const pdfName = isAdmin ? `${ pickupRequestDetails_printName }_Admin` : pickupRequestDetails_printName;
          const blob = base64ToBlob((payload as unknown) as string, 'application/pdf');
          const date = formatDate(new Date(pickupRequest.logistic.preferredDate));
          const name = ` ${ id }-${ pdfName }-${ date }.pdf`;
          createDownload(blob, name);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleRemove: RemoveAction = () => {
    setOpenModal(MODALS.DELETE);
  };

  const handleSave: SaveAction = (newStatus: PickupRequestStatus) => {
    setStatus(newStatus);
    switch (newStatus) {
      case PickupRequestStatus.DRAFT:
        setOpenModal(MODALS.SAVE_DRAFT);
        break;
      case PickupRequestStatus.NEW:
        setopenSuccessModal(true);
        break;
      case PickupRequestStatus.IN_PROCESS:
        setOpenModal(MODALS.SAVE);
        break;

      case PickupRequestStatus.PROCESSED:
        setOpenModal(MODALS.COMPLETE);
        break;

      default:
    }
  };

  const handleResend : ResendAction = async (newStatus: PickupRequestStatus)=>{
    const { type } = await dispatch(resendPickupRequest({ id: id }));

    if (new RegExp('SUCCESS').test(type)) {
      history.push(PICKUP_REQUESTS_LIST.path);
    } else if (new RegExp('ERROR').test(type)) {
      setOpenModal(MODALS.TA_CONNECTION_FAILURE);
    }
  }

  const handleClose: CloseAction = () => {
    history.push(PICKUP_REQUESTS_LIST.path);
  };

  const buttonActions = {
    handleClose,
    handleAbort,
    handlePrint,
    handleRemove,
    handleSave,
    handleResend
  };

  const initialValues = useSelector(getPickupRequest(id));
  const { buttons, fields, validationSchema } = useFormData({ buttonActions }, values);
  const modalProps = useModalData(openModal);

  const onDismiss = () => {
    handlers.dismiss(setOpenModal);
  };

  const handleCloseSuccessModal = () => {
    setopenSuccessModal(false);
  };

  const updatePickupRequestWithMaterialName = (
    pickRequest: PickupRequest,
    mList: any
  ) => {
    const newPickupRequest = { ...pickRequest };
    let materialOption = null;
    newPickupRequest.materials = newPickupRequest?.materials?.map(material => {
      materialOption = mList?.find(
        (m: { id: string }) => m?.id === material.materialId
      );
      material.materialName = materialOption?.name;
      return material;
    });
    return newPickupRequest;
  };
  // eslint-disable-next-line no-shadow
  const handleSubmitSuccessModal = async (templateValues: TemplateActionDetails) => {
    const newPickupRequest = {
      ...updatePickupRequestWithMaterialName({ ...pickupRequest, ...values }, materialList),
      ...templateValues
    };
    await handlers.save({
      dispatch,
      history,
      isAdmin,
      pickupRequest: newPickupRequest,
      setOpenModal,
      status,
    });
    //  history.push(PICKUP_REQUESTS_LIST.path);
    setopenSuccessModal(false);
  };

  const onConfirm = () => {

    switch (openModal) {
      case MODALS.ABORT:
        return handlers.abort({ dispatch, history, initialStatus, isAdmin, pickup: pickupRequest, setOpenModal });

      case MODALS.PRINT:
        return handlers.print(setOpenModal);

      case MODALS.TA_CONNECTION_FAILURE:
        return handlers.connectionFailureTA({ setOpenModal, history });

      case MODALS.DELETE:
        return handlers.remove({ dispatch, history, id: pickupRequest?.id, setOpenModal });

      case MODALS.SAVE_DRAFT:
      case MODALS.SAVE:
      case MODALS.COMPLETE:
      //  console.log(" test12233", materialList, pickupRequest);
        // eslint-disable-next-line max-len
        // eslint-disable-next-line no-case-declarations
        const newPickupRequest: PickupRequest = updatePickupRequestWithMaterialName(
          { ...pickupRequest, ...values }, materialList
        );
        return handlers.save({
          dispatch,
          history,
          isAdmin,
          pickupRequest: newPickupRequest,
          setOpenModal,
          status
        });

      default:
        return undefined;
    }
  };

  const handleCustomerListRequest = async (companyId: string): Promise<Customer[]> => {
    let collection: Customer[];
    const { type, payload } = await dispatch(getCompany(companyId));

    if (type.includes('SUCCESS')) {
      collection = (payload as Company).customers;
    } else if (isMockingApi() && type.includes('ERROR')) {
      collection = COMPANY_LIST[0].customers;
    }

    return collection;
  };

  const handleContainerListRequest: RequestContainerList = (companyId: string) => {
    dispatch(getContainerList(companyId));
  };

  const requestHandler: RequestHandler = {
    containerList: handleContainerListRequest,
    customerList: handleCustomerListRequest
  };

  const modal = {
    ...modalProps,
    openModal,
    onConfirm,
    onDismiss
  };

  const handleSubmit = useCallback(() => {
    const newStatus = isAdmin ? PickupRequestStatus.PROCESSED : PickupRequestStatus.NEW;
    handleSave(newStatus);
  }, []);

  if (isLoading || !hasLoadedDetails) {
    return <PickupDetailsSkeleton />;
  }

  if (!isLoading && !pickupRequest) {
    return <p>No item found</p>;
  }

  return (

    <div>
      {
        createNeuStatus
        && (
          <div>
            <NavigatorTabs />
            <Content>
              <View
                buttons={ buttons }
                fields={ fields }
                initialValues={ initialValues }
                isLoading={
                  isLoading
                  || isUpdating
                  // eslint-disable-next-line max-len
                  || (!isAdmin && (pickupRequest.status !== PickupRequestStatus.DRAFT) && (pickupRequest.status >= (PickupRequestStatus.NEW)))
                  || (isAdmin && pickupRequest.status >= PickupRequestStatus.PROCESSED)
                }
                modalProps={ openModal && modal }
                onSubmit={ handleSubmit }
                requestHandler={ requestHandler }
                updateValues={ setValues }
                validationSchema={ validationSchema }
                shouldShowAside={ !isAdmin }
                locationData={ locations }
                bookingRefs={ bookingRefs }
              />
              { openSuccessModal
                && <SuccessModal onConfirm={ handleSubmitSuccessModal }
                  onDismiss={ handleCloseSuccessModal } /> }
            </Content>
          </div>
        )
      }
      {
        currentPickupStatus
        && (
          <div>
            <NavigatorTabs />
            <View
              buttons={ buttons }
              fields={ fields }
              initialValues={ initialValues }
              isLoading={
                isLoading
                || isUpdating
                // eslint-disable-next-line max-len
                || (!isAdmin && (pickupRequest.status !== PickupRequestStatus.DRAFT) && (pickupRequest.status >= (PickupRequestStatus.NEW)))
                || (isAdmin && pickupRequest.status >= PickupRequestStatus.PROCESSED)
              }
              modalProps={ openModal && modal }
              onSubmit={ handleSubmit }
              requestHandler={ requestHandler }
              updateValues={ setValues }
              validationSchema={ validationSchema }
              shouldShowAside={ !isAdmin }
              locationData={ locations }
              bookingRefs={ bookingRefs }
            />
            { openSuccessModal
              && <SuccessModal onConfirm={ handleSubmitSuccessModal }
                onDismiss={ handleCloseSuccessModal } /> }
          </div>
        )
      }
    </div >
  );
};

export default PickupRequestDetails;
