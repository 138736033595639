import { PickupRequestStatus } from 'app/constants/pickupRequest';
import { ActionTypes, PickupRequestActionType } from '../actionTypes';
import * as api from '../api';
import { PickupRequest, PickupRequestParams, ResendPickupRequest } from '../types';

export const getRecentContainerList = (companyId: string): PickupRequestActionType => ({
  type: ActionTypes.GET_RECENT_CONTAINER_LIST_REQUEST,
  request: api.getRecentContainerList(companyId),
  requestName: 'get-pickup-metadata'
});

export const getContainerList = (companyId: string): PickupRequestActionType => ({
  type: ActionTypes.GET_CONTAINER_LIST_REQUEST,
  request: api.getContainerList(companyId),
  requestName: 'get-pickup-metadata'
});

export const getMaterialList = (containerId: string, companyId: string): PickupRequestActionType => ({
  type: ActionTypes.GET_MATERIAL_LIST_REQUEST,
  request: api.getMaterialList(containerId, companyId),
  requestName: 'get-pickup-metadata'
});

export const getPickupRequestList = (params: PickupRequestParams): PickupRequestActionType => ({
  type: ActionTypes.GET_PICKUP_LIST_REQUEST,
  request: api.getPickupRequestList(params),
  requestName: 'get-pickup-requests'
});

export const getPickupRequest = (id: string): PickupRequestActionType => ({
  type: ActionTypes.GET_PICKUP_REQUEST,
  request: api.getPickupRequest(id),
  requestName: 'get-pickup-requests'
});

export const createPickupRequest = (
  pickupRequest: PickupRequest,
  isAdmin: boolean,
  status: PickupRequestStatus
): PickupRequestActionType => ({
  type: ActionTypes.CREATE_PICKUP_REQUEST,
  request: api.createPickupRequest(pickupRequest, isAdmin, status),
  requestName: 'create-pickup-request'
});

export const updatePickupRequest = (
  pickupRequest: PickupRequest,
  isAdmin: boolean,
  status: PickupRequestStatus
): PickupRequestActionType => ({
  type: ActionTypes.UPDATE_PICKUP_REQUEST,
  request: api.updatePickupRequest(pickupRequest, isAdmin, status),
  requestName: 'update-pickup-request'
});

export const processPickupRequest = (pickupRequest: PickupRequest) => ({
  type: ActionTypes.PROCESS_PICKUP_REQUEST,
  request: api.processPickupRequest(pickupRequest),
  requestName: 'update-pickup-request',
  payload: pickupRequest
});

export const deletePickupRequest = (id: string): PickupRequestActionType => ({
  type: ActionTypes.DELETE_PICKUP_REQUEST,
  request: api.deletePickupRequest(id),
  requestName: 'update-pickup-request',
  payload: { id }
});

export const resetPickupRequestList = (): PickupRequestActionType => ({
  type: ActionTypes.RESET_PICKUP_LIST
});

export const printPickupRequest = (id: string): PickupRequestActionType => ({
  type: ActionTypes.PRINT_PICKUP_REQUEST,
  request: api.printPickupRequest(id),
  requestName: 'print-pickup-request'
});

export const resendPickupRequest = (request: ResendPickupRequest): PickupRequestActionType => ({
  type: ActionTypes.RESEND_PICKUP_REQUEST,
  request: api.resendPickupRequest(request),
  requestName: 'resend-pickup-request'
});

export const saveTemplateRequest = (pickupRequest: PickupRequest): PickupRequestActionType => {
  return ({
    type: ActionTypes.SAVE_NEW_TEMPLATE,
    request: api.saveNewTemplateRequest(pickupRequest, true),
    requestName: 'save-new-template-request'
  });
};
