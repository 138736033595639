import styled from 'styled-components';

import { LINE_SEPARATOR_COLOR, PRIMARY, TEXT_COLOR } from 'base/styles/skin';
import { Link as ReactRouterLink } from 'react-router-dom';

export const NavigationWrapper = styled.nav`
  align-items: center;
  border-bottom: 1px solid ${ LINE_SEPARATOR_COLOR };
  display: flex;
  flex-direction: row wrap;
  margin-bottom: 20px;
  min-height: 50px;
  padding: 10px 0;

  & ul li {
    display: inline-block;
  }
`;
NavigationWrapper.displayName = 'NavigationWrapper';

export const CurrentPage = styled(ReactRouterLink)`
  margin: 0 20px;
  font-size: 14px;
  font-weight: normal;
  color: ${ TEXT_COLOR };
`;
CurrentPage.displayName = 'CurrentPage';

export const PreviousPage = styled(CurrentPage)`
  &,
  &:link,
  &:visited {
    color: ${ PRIMARY };
    font-weight: 500;
    text-decoration: underline;
  }
`;
PreviousPage.displayName = 'PreviousPage';

export const PathSeparator = styled.span`
  display: inline-block;
`;
PathSeparator.displayName = 'PathSeparator';
