/* eslint-disable max-len */
import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIsLoading } from 'app/hooks';
import { NEW_PICKUP_REQUEST, NEW_TEMPLATE_REQUEST, TEMPLATE_DETAILS } from 'app/routes';
import { useTranslationsContext } from 'app/translations';
import debounce from 'app/utils/debounce';
import Table from 'components/Table';
import { SelectedItem } from 'components/Table/types';
import { TableSkeleton } from 'components/Skeletons';
import NavigatorTabs from 'containers/PickupRequest/containers/PickupRequestDetails/components/NavigatorTabs';
import { getLoginData } from 'containers/Login/selectors';
import { ROLE } from 'app/constants/roles';
import * as actions from '../../actions';
import { useTemplatesListRequest, useTemplatesReset } from '../../hooks';
import { createRowFromTemplateRequest } from '../../models';
import { getTemplatesList } from '../../selectors';
import { TemplateRow } from '../../types';
import { useTableData } from './data';
// import CreateButton from './components/CreateButton';
import { CreateTemplateLink, CreateTemplateText, CreatePickupLink } from './styles';


const TemplateList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const [filterValue, setFilterValue] = useState<string>(undefined);
  const { columns } = useTableData();
  const { collection, pagination } = useSelector(getTemplatesList);
  const translations = useTranslationsContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const openQueries = useIsLoading(['get-templates-request']);
  const { role } = useSelector(getLoginData);

  useTemplatesReset();
  useTemplatesListRequest(pagination.currentPage, pagination.pageSize);
 
  const templateRows = React.useMemo(() => {
    return collection.map(createRowFromTemplateRequest);
  }, [collection]);

  const handlePagination = useCallback(
    ({ selected }: SelectedItem) => {
      dispatch(actions.getTemplateList(selected, pagination.pageSize));
    },
    [pagination.pageSize]
  );
  // todo handleSelectRow
  const handleSelectRow = useCallback((row: TemplateRow) => {
    history.push(TEMPLATE_DETAILS.path.replace(':id', row.templateId.toString()));
  }, []);

  // const handleFilter = useCallback((event: ChangeEvent<HTMLInputElement>) => {
  //   setFilterValue(event.target.value);
  // }, []);

  React.useEffect(() => {
    debounce(() => {
      if (isLoading) {
        setIsLoading(openQueries);
      }
    }, 100);

    if (!window.location.hash) { // currently its hacked to reload the page 
      window.location.href = `${ window.location.href }#loaded`;
      window.location.reload();
    }
  }, [openQueries]);
  // console.log('cehck', templateRows.length);
  // const tableActions = [
  //   <InputFilter key="table-filter" onChange={ handleFilter } placeholder={ translations.usersList_searchLabel } />,
  // ];
  const tableActions = () => {
    if ((role) === ROLE.AVAG_ADMIN) {
      return [];
    }
    return (
      [
        //  <CreateButton key="create-button" />
        <CreatePickupLink to={ NEW_PICKUP_REQUEST.path } key="create-button">{ translations.pickUpRequest_create }</CreatePickupLink>,
        <CreateTemplateLink to={ NEW_TEMPLATE_REQUEST.path } key="create-button">{ translations.template_create_button }</CreateTemplateLink>,
      ]);
  };

  if (isLoading && !templateRows.length) {
    return <TableSkeleton />;
  }

  return (
    <div>
      <NavigatorTabs />
      <Table<TemplateRow>
        actions={ tableActions() }
        columns={ columns }
        // filterValue={ filterValue } comment filter not in scope may come in future
        isLoading={ openQueries }
        onClick={ handleSelectRow }
        pagination={ {
          onChange: handlePagination,
          ...pagination
        } }
        rows={ templateRows }
        title={ translations.template_table_title }
      />
      <CreateTemplateText>
        <div>
          {        
            templateRows.length > 0 ? null
              : <p>{ translations.template_create_process_details_1 }
              </p>
          }
          <p>{ translations.template_create_process_details_2 }</p>
          <p>{ translations.template_create_process_details_3 }</p>
        </div>
      </CreateTemplateText>
   
    </div>
  );
};

export default TemplateList;