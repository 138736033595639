import { http, envConf } from 'base';

export const changePasswordRequest = async (email: string): Promise<void> => {
  try {
    await http.post(envConf.API.CHANGE_PASSWORD.replace(':email', email));
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
