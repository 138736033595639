import { ADD, REMOVE } from './constants';
import { State, AddAction, RemoveAction } from './types';

const reducer = (state: State = {}, { type, payload }: AddAction | RemoveAction) => {
  switch (type) {
    case ADD:
      // eslint-disable-next-line no-restricted-syntax
      if ('request' in payload) {
        return {
          ...state,
          [payload.requestName]: [...(state[payload.requestName] || []), payload.request]
        };
      }

      // This should never happen, as per typed
      return state;

    // Remove action
    case REMOVE:
      return {
        ...state,
        [payload.requestName]: state[payload.requestName].filter(promise => promise?.isPending())
      };

    default:
      return state;
  }
};

export default reducer;
