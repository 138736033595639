import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslationsContext } from 'app/translations';
import Buttons from 'components/Buttons';
import { QUOTAS } from 'app/routes';
import { Wrapper } from './styles';

interface Props {
  total: number;
}

const Footer: React.FC<Props> = ({ total }) => {
  const translations = useTranslationsContext();
  const history = useHistory();

  return (
    <Wrapper>
      { !!total && (
        <Buttons
          options={ [
            {
              id: 'show-all-quotas',
              label: translations.home_quotas_showLabel,
              onClick: () => {
                history.push(QUOTAS.path);
              },
              type: 'button'
            }
          ] }
        />
      ) }
    </Wrapper>
  );
};

export default Footer;
