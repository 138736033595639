import { ButtonProps } from 'components/Touchable/types';
import { LangKeys } from 'app/translations';

export const createButtons = (translations: LangKeys): ButtonProps[] => ([
  {
    id: 'resetPasswordSubmit',
    type: 'submit',
    buttonType: 'primary',
    label: translations.resetPassword_submitButton
  }
]);
