import { ModifiedPromise } from './types';

export const MakeQuerablePromise = <T>(promise: ModifiedPromise<T>): ModifiedPromise<T> => {
  // Don't modify any promise that has been already modified.
  if (promise?.isPending) return promise;

  // Set initial state
  let isPending = true;
  let isSuccess = false;
  let isRejected = false;

  // Observe the promise, saving the fulfillment in a closure scope.
  const result: ModifiedPromise<T> = promise.then(
    v => {
      isPending = false;
      isSuccess = true;
      return v;
    },
    e => {
      isPending = false;
      isRejected = true;
      throw e;
    }
  );

  result.isPending = () => isPending;
  result.isSuccess = () => isSuccess;
  result.isRejected = () => isRejected;

  return result;
};
