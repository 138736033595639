import { ROLE } from 'app/constants/roles';
import { safeDate } from 'app/utils/safeTypes';
import { User } from '../types';

export const USER_LIST: User[] = [
  {
    id: '123',
    date: safeDate(['2020-07-08']).getTime(),
    firstName: 'First user',
    lastName: 'Test',
    fullName: 'First user Test',
    email: 'firstUser@avag.com',
    role: ROLE.AVAG_ADMIN,
    companyId: '234234',
    companyName: 'Test',
    permissions: {
      requestPickup: true,
      accessBills: false
    },
    companyPermissions: {
      portal: true,
      requestPickup: true,
      accessBills: false
    },
    status: 0
  },
  {
    id: '456',
    date: safeDate(['2020-07-08']).getTime(),
    firstName: 'Second user',
    lastName: 'Test',
    fullName: 'Second user Test',
    email: 'secondUser@avag.com',
    role: ROLE.CLIENT_SUPER_USER,
    companyId: '234234',
    companyName: 'Test',
    permissions: {
      requestPickup: true,
      accessBills: true
    },
    companyPermissions: {
      portal: true,
      requestPickup: true,
      accessBills: true
    },
    status: 1
  },
  {
    id: '789',
    date: safeDate(['2020-07-08']).getTime(),
    firstName: 'Third user',
    lastName: 'Test',
    fullName: 'Third user Test',
    email: 'thirdUser@avag.com',
    role: ROLE.CLIENT_OPERATOR,
    companyId: '234234',
    companyName: 'Test',
    permissions: {
      requestPickup: true,
      accessBills: false
    },
    companyPermissions: {
      portal: true,
      requestPickup: true,
      accessBills: false
    },
    status: 2
  }
];
