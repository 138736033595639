/* eslint-disable max-lines */
import { safeString, safeNumber } from 'app/utils/safeTypes';
import { ContainerAddonFromServer,
  ContainerAddon,
  AddonOptionsFromServer,
  AddonOption,
  Container,
  ContainerFromServer,
  ContainerType,
  MaterialFromServer,
  Material,
  AddonType } from '../../types';

let types: ContainerType[] = [];

const getTypes = () => types;

export const getAddonValue = (type: AddonType, defaultValue?: unknown, value?: unknown) => {
  switch (type) {
    case AddonType.bool:
      return value !== undefined ? !!value : !!defaultValue;

    case AddonType.number:
      return safeNumber(value || defaultValue);

    case AddonType.dropdown:
    default:
      return safeString(value || defaultValue);
  }
};

export const createMaterialFromServer = ({ ItemNo,
  ItemDescription,
  VEVAItemNeedsAuthorisation }: MaterialFromServer): Material => {
  if (ItemNo === undefined || ItemDescription === undefined) {
    console.error('Error creating <Material> model.');
    return undefined;
  }

  return {
    id: safeString(ItemNo),
    name: safeString(ItemDescription),
    isVeva: !!VEVAItemNeedsAuthorisation
  };
};

export const createMaterialListFromServer = (materialsFromServer: MaterialFromServer[]): Material[] => {
  if (!Array.isArray(materialsFromServer)) {
    console.error('Error creating <Material[]> model.');
    return [];
  }

  return materialsFromServer.map(createMaterialFromServer).filter(m => !!m);
};

export const createPermissionsFromServer = ({ collected = true, delivered = true }: ContainerFromServer) => ({
  collected: !!collected,
  delivered: !!delivered
});

const createAddonOptionFromServer = ({ id, name }: AddonOptionsFromServer): AddonOption => {
  if (id === undefined || name === undefined) {
    console.error('Error creating <AddonOption> model.');
    return undefined;
  }

  return {
    id: safeString(id),
    name: safeString(name)
  };
};

const createAddonOptionsFromServer = (optionsFromServer: AddonOptionsFromServer[]): AddonOption[] => {
  // Addon has no options
  if (optionsFromServer === undefined) {
    return [];
  }

  if (!Array.isArray(optionsFromServer)) {
    console.error('Error creating <AddonOption[]> model.');
    return [];
  }

  return optionsFromServer.map(createAddonOptionFromServer).filter(o => !!o);
};

export const createContainerAddonFromServer = ({ addons_content_id,
  addons_id,
  addons_name,
  container_addons_type_id,
  value,
  options }: ContainerAddonFromServer): ContainerAddon => {
  if (addons_id === undefined || addons_name === undefined || container_addons_type_id === undefined) {
    console.error('Error creating <ContainerAddon> model.');
    return undefined;
  }

  return {
    addonId: safeString(addons_id),
    defaultValue: getAddonValue(safeNumber(container_addons_type_id), value),
    id: safeString(addons_content_id),
    name: safeString(addons_name),
    options: createAddonOptionsFromServer(options),
    type: safeNumber(container_addons_type_id)
  };
};

const createContainerAddonsFromServer = (addonsFromServer: ContainerAddonFromServer[]): ContainerAddon[] => {
  // Container has no addons
  if (addonsFromServer === undefined) {
    return [];
  }

  if (!Array.isArray(addonsFromServer)) {
    console.error('Error creating <ContainerAddon[]> model.');
    return [];
  }

  // TODO: Remove when backend has a valid implementation
  const otherAddons = addonsFromServer.filter(a => a.container_addons_type_id !== AddonType.dropdown);
  const dropdownAddons = addonsFromServer.filter(a => a.container_addons_type_id === AddonType.dropdown);
  const dropdownAddon: ContainerAddonFromServer | undefined = !dropdownAddons.length
    ? undefined
    : dropdownAddons.reduce((addon, current) => {
      if (!addon?.addons_id) {
        return {
          ...current,
          value: current.value ? current.addons_id : undefined,
          options: [
            ...(current.options || []),
            {
              id: current.addons_id,
              name: current.addons_name
            }
          ]
        };
      }

      return {
        ...addon,
        value: current.value ? current.addons_id : addon.value,
        options: [
          ...(addon.options || []),
          ...(current.options || []),
          {
            id: current.addons_id,
            name: current.addons_name
          }
        ]
      };
    }, {} as ContainerAddonFromServer);

  if (dropdownAddon) {
    otherAddons.push(dropdownAddon);
  }

  return otherAddons.map(createContainerAddonFromServer).filter(a => !!a);
};

export const createContainerFromServer = (
  { addons,
    collected,
    container_id,
    container_image_url,
    container_name,
    container_typology_id,
    container_typology_name,
    delivered,
    description }: ContainerFromServer,
  shouldReturnTypes: boolean
): Container => {
  if (container_id === undefined || container_name === undefined || container_typology_id === undefined) {
    console.error('Error creating <Container> model.');
    return undefined;
  }

  const cType = safeString(container_typology_id);
  if (shouldReturnTypes) {
    const typeIndex = types.findIndex(t => t.id === cType);
    if (typeIndex < 0) {
      types.push({
        id: safeString(container_typology_id),
        image: safeString(container_image_url),
        name: safeString(container_typology_name)
      });
    } else {
      if (!types[typeIndex].image && container_image_url) {
        types[typeIndex].image = safeString(container_image_url);
      }

      if (!types[typeIndex].name && container_typology_name) {
        types[typeIndex].name = safeString(container_typology_name);
      }
    }
  }

  return {
    addons: createContainerAddonsFromServer(addons),
    description: safeString(description),
    id: safeString(container_id),
    image: safeString(container_image_url),
    name: safeString(container_name),
    permissions: createPermissionsFromServer({ collected, delivered }),
    type: cType
  };
};

interface ContainersAndTypes {
  containers: Container[];
  types: ContainerType[];
}

export const createContainerListFromServer = (
  containersFromServer: ContainerFromServer[],
  shouldCollectTypes = true
): ContainersAndTypes => {
  if (!Array.isArray(containersFromServer)) {
    console.error('Error creating <Container[]> model.');
    return {
      containers: [],
      types: []
    };
  }

  // Clean types Array
  while (types.length > 0) {
    types = [];
  }

  const containers = containersFromServer.map(c => createContainerFromServer(c, shouldCollectTypes)).filter(c => !!c);

  return {
    containers,
    types: shouldCollectTypes ? getTypes() : []
  };
};
