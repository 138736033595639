import styled from 'styled-components';
import { FIELD_BORDER_COLOR,
  FIELD_BACKGROUND,
  DISABLED_BACKGROUND,
  CHECKBOX_TICK_COLOR,
  FIELD_COLOR,
  CHECKBOX_TICK_COLOR_DISABLED,
  CHECKBOX_BORDER_COLOR_DISABLED } from 'base/styles/skin';

import { CONTROL_SIZE } from '../styles';

interface StyledProps {
  isDisabled?: boolean;
}

export const CONTROL_SIZE_CHECKBOX = 14;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: inherit;
  line-height: ${ CONTROL_SIZE }px;
`;
CheckboxWrapper.displayName = 'CheckboxWrapper';

export const CustomCheckbox = styled.div`
  background-color: ${ FIELD_BACKGROUND };
  border: 1px solid ${ FIELD_BORDER_COLOR };
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: ${ CONTROL_SIZE_CHECKBOX }px;
  margin-right: 10px;
  position: relative;
  transition: background-color 0.2s, border: 0.2s;
  vertical-align: bottom;
  width: ${ CONTROL_SIZE_CHECKBOX }px;
  min-width: ${ CONTROL_SIZE_CHECKBOX }px;

  input:checked ~ div & {
    color: ${ CHECKBOX_TICK_COLOR };
  }

  input:checked:disabled ~ div & {
    color: ${ CHECKBOX_TICK_COLOR_DISABLED };
  }
  
  input:disabled ~ div & {
    background-color: ${ DISABLED_BACKGROUND };
    border-color: ${ CHECKBOX_BORDER_COLOR_DISABLED };
    cursor: default;
  }

  &:after {
    border: ${ ({ isDisabled }: StyledProps) =>
    (isDisabled ? `solid ${ CHECKBOX_TICK_COLOR_DISABLED }` : `solid ${ CHECKBOX_TICK_COLOR }`) };
    border-width: 0 2px 2px 0;
    content: ' ';
    display: none;
    height: 8px;
    left: 3px;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    transition: border: 0.2s;
    width: 3px;

    input:checked ~ div & {
      display: block;
    }
  }
`;
CustomCheckbox.displayName = 'CustomCheckbox';

export const CheckboxText = styled.div`
  color: ${ FIELD_COLOR };
  font-size: 14px;
  padding-left: 0;
`;
CheckboxText.displayName = 'CheckboxText';
