import { Action } from 'app/types';

export enum ActionTypes {
  HELP_REQUEST = 'HELP_REQUEST',
  HELP_SUCCESS = 'HELP_SUCCESS',
  HELP_ERROR = 'HELP_ERROR'
}

type HelpRequest = Action<ActionTypes.HELP_REQUEST>;
type HelpSuccess = Action<ActionTypes.HELP_SUCCESS>;
type HelpError = Action<ActionTypes.HELP_ERROR>;

export type HelpActionType = HelpRequest | HelpSuccess | HelpError;
