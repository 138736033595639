import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { USERS, USER, NEW_USER, HOME } from 'app/routes';
import { PropsWithWrapper } from 'app/types';
import Redirect from 'components/Redirect';
import List from './containers/List';
import Details from './containers/Details';

const renderFallback = () => <Redirect to={ HOME.path } />;

interface Props {
  usersAvailable: boolean;
}

const Users: React.FC<PropsWithWrapper<Props>> = ({ usersAvailable, wrapper }) => (
  <Switch>
    { usersAvailable ? (
      <Route exact={ true } key="new-user" path={ NEW_USER.path } component={ wrapper(Details) } />
    ) : (
      renderFallback()
    ) }
    <Route exact={ true } path={ USER.path } component={ wrapper(Details) } />
    { usersAvailable ? <Route exact={ true } path={ USERS.path } component={ wrapper(List) } /> : renderFallback() }
    { renderFallback() }
  </Switch>
);

export default Users;
