import React from 'react';
import { BillingAddress, CompanyResponse } from 'containers/Bills/types';
import { useTranslationsContext, LangKeys } from 'app/translations';

import SmartForm from 'components/SmartForm';
import { createIndexedFields } from 'app/utils/fields';

import { CustomerInfoWrapper, BillAddressWrapper, Container, IdFieldsWrapper } from './styles';
import { createBillDetailFields } from '../../data/fields';

const parseAddressInfo = (billingAddress: BillingAddress): string => billingAddress.join('\n');

const parseContactDetails = (translations: LangKeys, { phone = '', email = '', web = '' }: CompanyResponse): string => {
  const contactDetailsData = [
    // {
    //   value: fullName
    // },
    {
      label: translations.billDetails_contactDetails_phone,
      value: phone
    },
    // {
    //   label: translations.billDetails_contactDetails_central,
    //   value: central
    // },
    {
      label: translations.billDetails_contactDetails_email,
      value: email
    },
    {
      label: translations.billDetails_contactDetails_web,
      value: web
    }
  ];

  return contactDetailsData.map(({ label, value }) => (label ? `${ label }: ${ value }` : `${ value }`)).join('\n');
};

interface Props {
  billingAddress: BillingAddress;
  // contactInfo: BillContactInfo;
  companyResponse: CompanyResponse;
  customerId: string;
  vatId: string;
}

const CustomerInfo: React.FC<Props> = ({ billingAddress, customerId, vatId, companyResponse }) => {
  const translations = useTranslationsContext();
  const infoFields = createBillDetailFields(translations);
  return (
    <SmartForm
      form={ 'customerInfo' }
      initialValues={ {
        billingAddress: parseAddressInfo(billingAddress),
        customerId,
        vatId,
        ...(companyResponse && { contactInfo: parseContactDetails(translations, companyResponse) })
      } }
      buttons={ [] }
      fields={ infoFields }
      handleSubmit={ () => {} }
    >
      { ({ controlledFields, Field }) => {
        const indexedFields = createIndexedFields(controlledFields);
        return (
          <Container>
            <BillAddressWrapper>
              <Field options={ indexedFields.billingAddress } />
            </BillAddressWrapper>
            <CustomerInfoWrapper>
              <IdFieldsWrapper>
                <Field options={ indexedFields.customerId } />
                <Field options={ indexedFields.vatId } />
              </IdFieldsWrapper>
              <Field options={ indexedFields.contactInfo } />
            </CustomerInfoWrapper>
          </Container>
        );
      } }
    </SmartForm>
  );
};
export default CustomerInfo;
