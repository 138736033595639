import styled from 'styled-components';
import DefaultCard from 'components/Card';

interface StyledProps {
  showStatusMessage: boolean;
}
export const ButtonsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
ButtonsWrapper.displayName = 'ButtonsWapper';

export const Card = styled(DefaultCard)`
  margin-top: ${ ({ showStatusMessage }: StyledProps) => (showStatusMessage ? '20px' : '0') };
`;
Card.displayName = 'Card';
