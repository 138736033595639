import React from 'react';
import { FieldProps, InputElement } from '../../types';
import Checkbox from '../checkbox';
import DatePickerComponent from '../datepicker';
import Dropdown from '../dropdown';
import Radio from '../radio';
import Text from '../text';

interface Props {
  onChange?: Function;
  onFocus?: Function;
  onBlur?: Function;
  onClick?: Function;
  options: FieldProps;
  setFieldValue?: (fieldName: string, value: any) => void;
  value?: any;
}

const DummmyField: React.FC<Props> = ({ onBlur, onChange, onClick, onFocus, options, value, setFieldValue }) => {
  const handleClick = (event: React.MouseEvent<InputElement>) => {
    if (options.onClick) options.onClick(event);
    if (onClick) onClick(event);
  };

  const handleFocus = (event: React.FocusEvent<InputElement>) => {
    if (options.onFocus) options.onFocus(event);
    if (onFocus) onFocus(event);
  };

  const handleChange = (event: React.ChangeEvent<InputElement>) => {
    if (options.onChange) options.onChange(event);
    if (onChange) onChange(event);
  };

  const handleBlur = (event: React.FocusEvent<InputElement>) => {
    if (options.onBlur) options.onBlur(event);
    if (onBlur) onBlur(event);
  };

  const handleSetFieldValue = (fieldName: string, newValue: any) => {
    if (setFieldValue) setFieldValue(fieldName, newValue);
  };

  switch (options.type) {
    case 'checkbox':
      return (
        <Checkbox
          onClick={ handleClick }
          onFocus={ handleFocus }
          onChange={ handleChange }
          onBlur={ handleBlur }
          options={ options }
          setFieldValue={ handleSetFieldValue }
          value={ value }
        />
      );
    case 'date':
      return (
        <DatePickerComponent
          key="date"
          onBlur={ handleBlur }
          options={ options }
          setFieldValue={ handleSetFieldValue }
          value={ value }
        />
      );
    case 'dropdown':
      return (
        <Dropdown
          onClick={ handleClick }
          onFocus={ handleFocus }
          onChange={ handleChange }
          onBlur={ handleBlur }
          options={ options }
          setFieldValue={ handleSetFieldValue }
          value={ value }
        />
      );
    case 'radio':
      return (
        <Radio
          onClick={ handleClick }
          onFocus={ handleFocus }
          onChange={ handleChange }
          onBlur={ handleBlur }
          options={ options }
          setFieldValue={ handleSetFieldValue }
          value={ value }
        />
      );
    case 'text':
    case 'textarea':
    case 'password':
    case 'email':
    case 'number':
    default:
      return (
        <Text
          onClick={ handleClick }
          onFocus={ handleFocus }
          onChange={ handleChange }
          onBlur={ handleBlur }
          options={ options }
          value={ value }
        />
      );
  }
};

export default DummmyField;
