import { BookingRefFromServer, BookingRef } from 'containers/BookingRefs/types';

export const BOOKING_REF_LIST_FROM_SERVER: BookingRefFromServer[] = [
  { account_reference_id: 1, reference_number_id: '1234' },
  { account_reference_id: 2, reference_number_id: '5678' }
];

export const BOOKING_REF_LIST: BookingRef[] = [
  { id: '1', name: '1234' },
  { id: '2', name: '5678' }
];
