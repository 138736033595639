import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIsLoading } from 'app/hooks';
import { useTranslationsContext } from 'app/translations';
import debounce from 'app/utils/debounce';
import { TableSkeleton } from 'components/Skeletons';
import Table from 'components/Table';
import { SelectedItem } from 'components/Table/types';
import * as actions from '../actions';
import { useTableData } from '../data';
import { useNotificationListRequest, useNotificationsReset } from '../hooks';
import { getNotificationList } from '../selectors';
import { createRowFromNotification } from '../models';
import { NotificationRow } from '../types';
import { navigate } from '../utils';

const NotificationList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { columns } = useTableData();
  const { collection, pagination } = useSelector(getNotificationList);
  const translations = useTranslationsContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const openQueries = useIsLoading(['get-notifications']);

  useNotificationsReset();
  useNotificationListRequest(pagination.currentPage, pagination.pageSize);

  const notificationRows = useMemo(() => {
    return collection.map(createRowFromNotification);
  }, [collection]);

  const handleSelectRow = useCallback((row: NotificationRow) => {
    const { entityId, entityType, extras } = row;
    navigate(history, { entityId, entityType, extras });
  }, []);

  const handlePagination = useCallback(
    ({ selected }: SelectedItem) => {
      dispatch(actions.getNotificationList(selected, pagination.pageSize));
    },
    [pagination.pageSize]
  );

  React.useEffect(() => {
    debounce(() => {
      if (isLoading) {
        setIsLoading(openQueries);
      }
    }, 100);
  }, [openQueries]);

  if (isLoading && !notificationRows.length) {
    return <TableSkeleton />;
  }

  return (
    <Table<NotificationRow>
      columns={ columns }
      isLoading={ openQueries }
      onClick={ handleSelectRow }
      pagination={ { onChange: handlePagination, ...pagination } }
      rows={ notificationRows }
      title={ translations.notifications }
    />
  );
};

export default NotificationList;
