import styled from 'styled-components';

import { FILE_DOWNLOAD_BACKGROUND, FILE_DOWNLOAD_TEXT, PRIMARY } from 'base/styles/skin';

interface StyledProps {
  isDisabled?: boolean;
}

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  max-height: 30px;
  border: none;
  margin-right: 18px;
  margin-bottom: 4px;
  padding: ${ ({ isDisabled }: StyledProps) => isDisabled && '4px 10px' };
  background-color: ${ FILE_DOWNLOAD_BACKGROUND };
`;
FileWrapper.displayName = 'FileWrapper';

export const FileUrl = styled.div`
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding: 4px 8px;
  cursor: pointer;
  text-decoration: ${ ({ isDisabled }: StyledProps) => isDisabled && 'underline' };
  color: ${ FILE_DOWNLOAD_TEXT };
`;
FileUrl.displayName = 'FileUrl';

export const FileDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${ PRIMARY };
`;
FileDot.displayName = 'FileDot';
