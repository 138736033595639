import { createPaginationFromServer } from 'app/models';
import { BillsState, BillRow, Bill, BillItem, BillDetailsRow } from '../types';

export const billsInitialState: BillsState = {
  collection: [],
  pagination: createPaginationFromServer({})
};

export const createRowFromBillsRequest = ({ id, date, documentType, status, originalAmount }: Bill): BillRow => ({
  id,
  date,
  documentType,
  status,
  totalAmount: originalAmount,
  navigate: id
});

export const createRowFromBillItem = (item: BillItem): BillDetailsRow => ({
  ...item,
  navigate: `${ item.deliveryNoteId }/${ item.docNoOccurrence }/${ item.versionNo }`
});
