import React from 'react';

import { Container, Icon } from './styles';

const arrow = require('assets/icons/arrowRight.svg');
const arrowHover = require('assets/icons/arrowHover.svg');

const ArrowButton: React.FC = () => {
  const [arrowIcon, setIcon] = React.useState(arrow);

  return (
    <Container onMouseEnter={ () => setIcon(arrowHover) } onMouseLeave={ () => setIcon(arrow) }>
      <Icon src={ arrowIcon } />
    </Container>
  );
};

export default ArrowButton;
