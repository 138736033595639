import { RootState } from 'base/types';
import { CREATE_URL } from 'app/constants/routes';
import { userDetailsInitialState } from '../models';

export const getUserList = ({ users: { collection, pagination } }: RootState) => ({
  collection,
  pagination
});

export const getUser = (id?: string) => ({ users }: RootState) =>
  (id && id !== CREATE_URL ? users.collection.find(user => user.id === id) : userDetailsInitialState);
