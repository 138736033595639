import React from 'react';
import styled from 'styled-components';
import { Wrapper,
  ActionsBar,
  Title,
  Actions,
  TableHead,
  TableRow,
  TableHeading,
  TableData } from 'components/Table/styles';
import { SKELETON_BACKGROUND } from 'base/styles/skin';
import Skeleton, { AvagDot, Bar, Buttons } from './pieces';

const StyledBar = styled(Bar)`
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
`;

const StyledTable = styled.table`
  border: 1px solid ${ SKELETON_BACKGROUND };
  border-spacing: 0;
  border-radius: 4px;
  margin-bottom: 8px;
  width: 100%;
`;

const StyledThead = styled(TableHead)``;

const StyledTh = styled(TableHeading)`
  background-color: ${ SKELETON_BACKGROUND };
  color: transparent;
`;

const StyledTd = styled(TableData)`
  color: transparent;
`;

interface Props {
  actions?: number;
  columns?: number;
  rows?: number;
  showActionsBar?: boolean;
}

const Table: React.FC<Props> = ({ actions = 2, columns = 4, rows = 3, showActionsBar = true }) => {
  const cols = [] as { [key: string]: string }[];
  const rws = [] as { [key: string]: string }[];

  for (let x = 1; x <= columns; x += 1) {
    cols.push({ key: x.toString() });
  }

  for (let y = 1; y <= rows; y += 1) {
    rws.push({ key: y.toString() });
  }

  return (
    <Skeleton>
      <Wrapper>
        { showActionsBar && (
          <ActionsBar>
            <Title>
              <AvagDot />
              <StyledBar width="200px" />
            </Title>
            <Actions>
              <Buttons count={ actions } />
            </Actions>
          </ActionsBar>
        ) }
        <StyledTable>
          <StyledThead>
            <TableRow>
              { cols.map(col => (
                <StyledTh key={ col.key } mobile={ true }>
                  ...
                </StyledTh>
              )) }
            </TableRow>
          </StyledThead>
          <tbody>
            { rws.map(row => (
              <TableRow key={ row.key }>
                { cols.map(col => (
                  <StyledTd key={ `${ row.key }-${ col.key }` } mobile={ true }>
                    ...
                  </StyledTd>
                )) }
              </TableRow>
            )) }
          </tbody>
        </StyledTable>
      </Wrapper>
    </Skeleton>
  );
};

export default Table;
