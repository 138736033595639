import { http, envConf } from 'base';
import { Pagination } from 'app/types';
import { handleListError } from 'app/utils/connection-errors';
import { DeliverySlip, ExportData, DeliveryFile, EXPORT_FORMAT } from 'containers/DeliverySlips/types';
import { createPaginationFromServer } from 'app/models';
import * as Model from '../models/fromServer';

interface DeliverySlipResponse {
  collection: DeliverySlip[];
  pagination: Pagination;
}

export const getDeliverySlipList = async (
  offset: number,
  limit: number,
  filterValue: string
): Promise<DeliverySlipResponse> => {
  try {
    const { data } = await http.get(envConf.API.DELIVERY_SLIP_LIST_GET, {
      params: { limit, offset, filterValue }
    });
    const { delivery_slips, ...pagination } = data;
    
    return {
      collection: Model.createDeliverySlipListFromServer(delivery_slips),
      pagination: createPaginationFromServer(pagination)
    };
  } catch (error) {
    return handleListError<DeliverySlip>(error);
  }
};

export const getDeliverySlip = async (id: string, docNo: string, versionNo: string): Promise<DeliverySlip> => {
  try {
    const { data } = await http.get(
      envConf.API.DELIVERY_SLIP_GET.replace(':id', id)
        .replace(':docNo', docNo)
        .replace(':versionNo', versionNo)
    );
    return Model.createDeliverySlipFromServer(data?.[0]);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

// export const getExportDataRequest = async (exportData: ExportData): Promise<string> => {
//   const { startDate, endDate, format } = exportData;
//   try {
//     const data = await http.get(
//       envConf.API.DELIVERY_SLIP_EXPORT_GET.replace(':startDate', startDate)
//         .replace(':endDate', endDate)
//         .replace(':format', format),
//       {
//         ...((format === EXPORT_FORMAT.exportFormat_csv || format === EXPORT_FORMAT.exportFormat_xlsx) && {
//           responseType: 'arraybuffer'
//         })
//       }
//     );
//
//     return (data as unknown) as string;
//   } catch (error) {
//     console.error(error);
//     throw new Error(error);
//   }
// };

export const getDeliveryFileRequest = async ({ id, docType }: DeliveryFile): Promise<string> => {
  try {
    const { data } = await http.get(
      envConf.API.DELIVERY_SLIP_FILE_GET.replace(':fileId', id).replace(':docType', docType)
    );

    return data;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const printDeliverySlip = async (orderNo: string, linkId: string): Promise<string> => {
  try {
    const { data } = await http.get(envConf.API.DELIVERY_SLIP_PRINT_REQUEST_GET
      .replace(':orderNo', orderNo)
      .replace(':dmsLinkID', linkId));
    return data.document;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
