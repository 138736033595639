import { LangKeys } from 'app/translations';
import { Column } from 'components/Table/types';
import { rendererCreator } from './permissionsCreators';
import { HandleCheckbox, CompaniesRow } from '../../types';

export const createColumns = (translations: LangKeys, handleCheckboxChange: HandleCheckbox): Column<CompaniesRow>[] => [
  {
    key: 'id',
    label: translations.settings_id
  },
  {
    key: 'name',
    label: translations.settings_client,
    mobile: true
  },
  {
    key: 'portal',
    label: translations.settings_portal_authorization,
    mobile: true,
    renderer: rendererCreator('portal', handleCheckboxChange)
  },
  {
    key: 'requestPickup',
    label: translations.settings_pickup_request,
    mobile: true,
    renderer: rendererCreator('requestPickup', handleCheckboxChange)
  },
  {
    key: 'accessBills',
    label: translations.settings_bill,
    mobile: true,
    renderer: rendererCreator('accessBills', handleCheckboxChange)
  }
];
