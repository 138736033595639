import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIsLoading } from 'app/hooks';
import { RouteWithID } from 'app/types';
import Buttons from 'components/Buttons';
import { useTranslationsContext } from 'app/translations';
import { DeliverySlipDetailsSkeleton } from 'components/Skeletons';
import { formatDate } from 'app/utils/formatters';
import { downloadDeliverySlipAttachment, createDownload, base64ToBlob } from 'app/utils/downloads';
import Header from './components/Header';
import TableForm from './components/TableForm';
import CustomMessage from './components/CustomMessage';
import { SectionWrapper, Error } from './styles';
import { getDeliverySlip } from '../../selectors';
import { useDeliverySlipRequest } from '../../hooks';
import Attachments from './components/Attachments';
import { DeliveryFile } from '../../types';
import { getDeliveryFileRequest, printDeliverySlip } from '../../actions';
import { PrintAction } from './types';

interface AttachmentPayload {
  document: string;
}

const DeliverySlipDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<RouteWithID>();
  const deliverySlip = useSelector(getDeliverySlip(id));
  const isLoading = useIsLoading(['get-delivery-slip']);
  const { deliverySlipDetails_buttonPrint, deliverySlipDetails_printName } = useTranslationsContext();

  useDeliverySlipRequest();

  const handleDownload = async (file: DeliveryFile) => {
    const { type, payload } = await dispatch(getDeliveryFileRequest(file));
    if (type.includes('SUCCESS')) {
      const { document } = payload as AttachmentPayload;
      downloadDeliverySlipAttachment(document, file);
    }
  };
  
  const handlePrint: PrintAction = () => async () => {
    try {
      const { type, payload } = await dispatch(printDeliverySlip(id, deliverySlip.dmsLinkID));
      if (type.includes('SUCCESS')) {
        const blob = base64ToBlob((payload as unknown) as string, 'application/pdf');
        const name = `${ id }-${ deliverySlipDetails_printName }-${ formatDate(new Date(deliverySlip.date)) }.pdf`;
        createDownload(blob, name);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading && !deliverySlip) {
    return <DeliverySlipDetailsSkeleton />;
  }

  if (!isLoading && !deliverySlip) {
    return <Error>Item not found</Error>;
  }

  return (
    <>
      <SectionWrapper>
        <Header id={ deliverySlip.id } status={ deliverySlip.status } />
      </SectionWrapper>

      <SectionWrapper>
        { !isLoading && <CustomMessage status={ deliverySlip.status } billId={ deliverySlip.associateBillId } /> }
      </SectionWrapper>

      <SectionWrapper>
        <TableForm deliverySlip={ deliverySlip } isLoading={ isLoading } />
      </SectionWrapper>

      { !!deliverySlip?.attachments.length && !isLoading && (
        <SectionWrapper>
          <Attachments attachments={ deliverySlip.attachments } handleDownload={ handleDownload } />
        </SectionWrapper>
      ) }

      { deliverySlip?.dmsLinkID && <Buttons
        options={
          [
            {
              id: 'print-delivery',
              label: deliverySlipDetails_buttonPrint,
              onClick: handlePrint(),
              type: 'button'
            }
          ]
        }
      /> }
    </>
  );
};

export default DeliverySlipDetails;
