
import { createPaginationFromServer } from 'app/models';
import { pickupRequestDetailsInitialState } from '../../../models';
import { TemplatesState, TemplateRequest, TemplateRow } from '../types';


export const templatesInitialState: TemplatesState = {
  collection: [],
  pagination: createPaginationFromServer({})
};

export const createRowFromTemplateRequest = (templateRequest: TemplateRequest): TemplateRow => ({
  templateId: templateRequest.templateId,
  templateName: templateRequest.templateName,
  createdDate: templateRequest.createdDate,
  lastUsed: templateRequest.lastUsed,
  templateDateRule: templateRequest.templateDateRule
});


export const pickupRequestTemplateDetailsInitialState: TemplateRequest = {
  ...pickupRequestDetailsInitialState,
  templateName: '',
  createdDate: 12 / 12 / 2020,
  lastUsed: 12 / 12 / 2020,
  templateDateRule: 0
};