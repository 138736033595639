import { number, object, ObjectSchema } from 'yup';
import { NUMBER } from 'app/components/SmartForm/models';
import { FieldProps } from 'app/components/SmartForm/types';
import { LangKeys } from 'app/translations';

export const setDynamicValidation = (fields: FieldProps[], translations: LangKeys): ObjectSchema => {
  const shape = {};

  fields.forEach(field => {
    if (field.type === NUMBER) {
      // @ts-ignore
      shape[field.id] = number().min(0, translations.validations_greaterThan.replace(':min:', 0));
    }
  });

  return object().shape(shape);
};