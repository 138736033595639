import { http, envConf } from 'base';
import { ResetPasswordParams } from '../types';

export const resetPasswordRequest = async ({ password, token }: ResetPasswordParams): Promise<void | undefined> => {
  try {
    await http.put(
      envConf.API.RESET_PASSWORD_PUT,
      { password },
      {
        headers: {
          Authorization: `Bearer ${ token }`
        }
      }
    );
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
