/* eslint-disable max-len */
import React from 'react';
import { useTranslationsContext } from 'app/translations';
import SectionTitle from 'components/SectionTitle';
import { useHistory } from 'react-router-dom';
import { NEW_PICKUP_REQUEST } from 'app/routes';
import { getTemplatesList } from 'app/containers/PickupRequest/containers/Templates/selectors';
import { useSelector } from 'react-redux';
import { createRowFromTemplateRequest } from 'app/containers/PickupRequest/containers/Templates/models';
import { useTemplatesListRequest, useTemplatesReset } from 'app/containers/PickupRequest/containers/Templates/hooks';
import TemplateHomeComp from 'app/containers/PickupRequest/containers/Templates/containers/TemplateHome';
import PickupButton from '../PickupButton';
import { Wrapper, CardContent, WrapperButton, WrapperButtonMobile } from './styles';

const AsideHome: React.FC = () => {
  const translations = useTranslationsContext();
  const history = useHistory();
  const { collection, pagination } = useSelector(getTemplatesList);
  useTemplatesReset();
  useTemplatesListRequest(pagination.currentPage, 5);


  const templateItem = React.useMemo(() => {
    return collection.map(createRowFromTemplateRequest);
  }, [collection]);
  return (
    <>
      <Wrapper>
        <SectionTitle className="templateSectionHome">{ translations.pickupRequest }
          { templateItem.length 
            ? <PickupButton />
            : null
          }
        </SectionTitle>
        { templateItem.length
          ? <TemplateHomeComp collection={ collection } />
          : <CardContent onClick={ () => {
            history.push(NEW_PICKUP_REQUEST.path);
          } }>
            <WrapperButton>
              <PickupButton />
            </WrapperButton>
          </CardContent> 

        }

      </Wrapper>
      <WrapperButtonMobile>
        <PickupButton />
        { templateItem.length
          ? <TemplateHomeComp collection={ collection } />
          : null
        }
      </WrapperButtonMobile>
    </>
  );
};

export default AsideHome;
