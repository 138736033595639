import { MOCKED_API_ENVIRONMENT, MOCK_API } from 'app/constants/localStorage';
import * as localStorage from 'app/utils/localStorage';
import * as dev from './env.development';
import * as pre from './env.preproduction';
import * as prod from './env.production';

import { env } from '../base/shared/env';

export * from './env.common';

export const getBaseUrl = () => {
  const mockedAPI = localStorage.getItem(MOCKED_API_ENVIRONMENT);
  let currentEnv = env;

  if (currentEnv !== 'production' && mockedAPI === 'mocks') {
    currentEnv = mockedAPI;
  }

  // If mocks have been set in localStorage, use them
  if (currentEnv === 'mocks') {
    return MOCK_API;
  }

  // If some API has been set through environment variables, use them
  if (process.env.API) {
    return process.env.API;
  }

  // Otherwise, use defaults
  switch (currentEnv) {
    case 'development':
      return dev.API_URL;

    case 'pre':
      return pre.API_URL;

    default:
      return prod.API_URL;
  }
};

const APIUrl = `${ getBaseUrl() }/api/v1`;

export const API = {
  LOGIN_POST: `${ APIUrl }/login`,
  LOGOUT_POST: `${ APIUrl }/logout`,
  RESET_PASSWORD_PUT: `${ APIUrl }/login`,
  ONBOARDING_POST: `${ APIUrl }/onboardusers`,

  RECENT_CONTAINERS_GET: `${ APIUrl }/recent-containers/:companyId`,
  CONTAINERS_GET: `${ APIUrl }/containers/:companyId`,
  MATERIALS_GET: `${ APIUrl }/materials/:containerId/:companyId`,
  // STATISTICS_MATERIALS_GET: `${ APIUrl }/materials/`,

  PICKUP_REQUEST_LIST_GET: `${ APIUrl }/pickup`,
  PICKUP_REQUEST_GET: `${ APIUrl }/pickup/:id`,
  PICKUP_REQUEST_POST: `${ APIUrl }/pickup`,
  PICKUP_REQUEST_PUT: `${ APIUrl }/pickup/:id`,
  PICKUP_REQUEST_PATCH: `${ APIUrl }/pickup/:id`,
  PICKUP_REQUEST_DELETE: `${ APIUrl }/pickup/:id`,
  PICKUP_PRINT_REQUEST_GET: `${ APIUrl }/pdf/:id`,
  PICKUP_RESEND_REQUEST_POST: `${ APIUrl }/resend-pickup`,

  COMPANIES_GET: `${ APIUrl }/clients`,
  COMPANY_GET: `${ APIUrl }/client/:id`,
  COMPANYLIST_GET: `${ APIUrl }/clientlistswitch/:id`,
  COMPANYCHANGE_POST: `${ APIUrl }/clientchange`,
  BOOKING_REF_GET: `${ APIUrl }/pickup_account_reference/:client_id`,
  LOCATION_GET: `${ APIUrl }/location/:client_id`,

  DELIVERY_SLIP_LIST_GET: `${ APIUrl }/deliverySlip`,
  DELIVERY_SLIP_GET: `${ APIUrl }/deliverySlip/:id/:docNo/:versionNo`,
  DELIVERY_SLIP_FILE_GET: `${ APIUrl }/deliverySlipDocuments/:fileId/:docType`,
  // DELIVERY_SLIP_EXPORT_GET: `${ APIUrl }/exportDeliverySlip/:startDate/:endDate/:format`,
  DELIVERY_SLIP_PRINT_REQUEST_GET: `${ APIUrl }/exportDeliverySlipDetails/:orderNo/:dmsLinkID`,

  BILLS_LIST_GET: `${ APIUrl }/bill`,
  BILL_GET: `${ APIUrl }/bill/:id/:docType`,
  BILLS_EXPORT_GET: `${ APIUrl }/billsexport/:startDate/:endDate/:format`,
  BILLS_BALANCE_GET: `${ APIUrl }/balancesheetexport/:startDate/:endDate/:format`,
  BILL_PRINT_REQUEST_GET: `${ APIUrl }/exportBillDetails/:dmsLinkID`,

  NOTIFICATION_LIST_GET: `${ APIUrl }/notification`,
  QUOTA_LIST_GET: `${ APIUrl }/quotas`,

  STATISTICS_GET: `${ APIUrl }/statistics`,
  NOTIFICATIONNAV_GET: `${ APIUrl }/cron`,

  TEMPLATES_LIST_GET: `${ APIUrl }/template`,
  TEMPLATES_DETAILS: `${ APIUrl }/template/:id`,
  TEMPLATE_DELETE: `${ APIUrl }/template/:id`,
  TEMPLATE_CREATE_POST: `${ APIUrl }/template`,
  TEMPLATES_DETAILS_PATCH: `${ APIUrl }/template/:id`,
  TEMPLATES_ORDER_POST: `${ APIUrl }/templateOrder`,
  TEMPLATES_ORDER_HOME_POST: `${ APIUrl }/homeTemplateOrder`,

  USERS_GET: `${ APIUrl }/user`,
  USER_GET: `${ APIUrl }/user/:id`,
  USERS_POST: `${ APIUrl }/user`,
  USERS_PUT: `${ APIUrl }/user/:id`,
  USERS_DELETE: `${ APIUrl }/user/:id`,
  CHANGE_PASSWORD: `${ APIUrl }/reset_password/:email`,

  SETTINGS_GET: `${ APIUrl }/settings`,
  SETTINGS_PATCH: `${ APIUrl }/settings/:id`,

  HELP_POST: `${ APIUrl }/help`,

  DOWNLOAD_FILE_GET: `${ APIUrl }/files/:pickupId/:fileName`,
  UPLOAD_FILE_POST: `${ APIUrl }/fileupload/:pickupId/:fileCategoryId`
};
