import { UPDATE_FORM_STATUS } from '../actionTypes/form';

import { FormState, FormAction } from '../types/form';

const reducer = (state: FormState, action: FormAction) => {
  switch (action.type) {
    case UPDATE_FORM_STATUS:
      return {
        ...state,
        canBeLocked: action.payload.canBeLocked !== undefined ? action.payload.canBeLocked : state.canBeLocked,
        hasPermissions:
          action.payload.hasPermissions !== undefined ? action.payload.hasPermissions : state.hasPermissions,
        isEditEnabled: action.payload.isEditEnabled !== undefined ? action.payload.isEditEnabled : state.isEditEnabled
      };

    default:
      return state;
  }
};

export default reducer;
