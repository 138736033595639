import React, { memo } from 'react';
import { PRIMARY, TAG_BACKGROUND, TAG_BACKGROUND_ORANGE, TAG_BACKGROUND_RED } from 'base/styles/skin';
import { useTranslationsContext } from 'app/translations';
import { RendererProps } from 'components/Table/types';
import Tag from 'components/Tag';
import { UserStatus } from 'containers/Users/types';

const TableTag = <R extends unknown>({ cell }: RendererProps<R>): React.ReactElement | null => {
  const translations = useTranslationsContext();
  let color = TAG_BACKGROUND;
  let label = '';
  switch (cell) {
    case UserStatus.ACCESS_REQUESTED:
      color = PRIMARY;
      label = translations.usersList_tag_pendingActivation;
      break;

    case UserStatus.Inactive:
      color = TAG_BACKGROUND_RED;
      label = translations.usersList_tag_Inactive;
      break;

    case UserStatus.PASSWORD_PENDING:
      color = TAG_BACKGROUND_ORANGE;
      label = translations.userList_tag_activated;
      break;

    case UserStatus.USER:
      color = TAG_BACKGROUND;
      label = translations.userList_tag_active;
      break;

    case UserStatus.VERIFICATION_PENDING:
      color = TAG_BACKGROUND_ORANGE;
      label = translations.userList_tag_pending_verification;
      break;
  
    default:
      color = TAG_BACKGROUND;
      return null;
  }

  return <Tag label={ label } color={ color } />;
};
export default memo(TableTag);
