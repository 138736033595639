import React from 'react';
import { BillTotal, BillTotalRow } from 'containers/Bills/types';
import { useTranslationsContext } from 'app/translations';
import SectionTitle from 'components/SectionTitle';
import { formatCurrency } from 'app/utils/formatters';
import { useTableData } from '../../data';
import { TotalsTableWrapper, TotalsTable } from './styles';

// Initializes all cells to avoid breaking the table
const initialRowValues = {
  info: '',
  secondaryAmount: '',
  mainAmountLabel: '',
  mainAmount: '',
};

interface Props {
  data?: BillTotal;
  totalAmount: number;
}

const TotalSection: React.FC<Props> = ({ totalAmount }) => {
  const { billDetails_total_title, billDetails_total_totalLabel } = useTranslationsContext();

  const { columnsTotal } = useTableData();

  const totalRow: BillTotalRow[] = [
    {
      ...initialRowValues,
      mainAmountLabel: `${ billDetails_total_totalLabel }: `,
      mainAmount: `${ formatCurrency(totalAmount) }`
    }
  ];

  const rows = [...totalRow];

  return (
    <>
      <SectionTitle>{ billDetails_total_title }</SectionTitle>
      <TotalsTableWrapper>
        <TotalsTable<BillTotalRow> showHeader={ false } columns={ columnsTotal } rows={ rows }></TotalsTable>
      </TotalsTableWrapper>
    </>
  );
};
export default TotalSection;
