import styled, { css } from 'styled-components';
import { PRIMARY, MOSAIC_ITEM_COLOR, BACKGROUND, LINK_COLOR, LINK_COLOR_HOVER } from 'base/styles/skin';
import { TABLET } from 'base/styles/media-queries';
import DefaultSectionTitle from 'components/SectionTitle';

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;
CarouselWrapper.displayName = 'CarouselWrapper';

interface ContentProps {
  step?: number;
  isBold?: boolean;
}

export const CarouselContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: flex-start;
  transform: translateX(${ ({ step }: ContentProps) => -33.333 * step }%);
  transition: transform 0.5s;
  width: 300%;
`;
CarouselContent.displayName = 'CarouselContent';

export const CarouselItem = styled.div`
  display: inline-block;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  min-height: 70vh;
  max-height: 70vh;
  overflow: hidden;
  overflow-y: auto;
  padding: 12px;
  width: 33.3333%;

  ${ TABLET } {
    padding: 24px;
  }
`;
CarouselItem.displayName = 'CarouselItem';

const textStyles = css`
  font-size: 10px;
  font-weight: normal;
  color: ${ MOSAIC_ITEM_COLOR };
`;

export const ContainerWrapper = styled.div`
  align-items: flex-start;
  display: inline-block;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;

  form {
    width: 100%;
  }
`;
ContainerWrapper.displayName = 'ContainerWrapper';

export const SectionTitle = styled(DefaultSectionTitle)`
  margin-bottom: 4px;
  margin-top: 24px;

  &:first-child {
    margin-top: 0;
  }
`;
SectionTitle.displayName = 'SectionTitle';



export const ImageWrapper = styled.div`
  align-items: center;
  background-color: ${ BACKGROUND };
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: 68px;
  min-width: 68px;
  padding: 4px;
  width: 68px;

  img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    // width: auto;
  }
`;
ImageWrapper.displayName = 'ImageWrapper';

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
`;
InfoWrapper.displayName = 'InfoWrapper';

export const ItemType = styled.div`
  ${ textStyles }
`;
ItemType.displayName = 'ItemType';

export const ItemDetails = styled.div`
  ${ textStyles }
`;
ItemDetails.displayName = 'ItemDetails';

export const ItemName = styled.div`
  font-size: 14px;
  color: ${ LINK_COLOR };
  text-decoration: underline;
`;
ItemName.displayName = 'ItemName';

// @ts-ignore
export const AddonsWrapper = styled(ContainerWrapper)`
  align-items: flex-start;
  display: inline-block;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;

  form {
    width: 100%;

    label {
      font-size: 14px;
    }
  }
`;
AddonsWrapper.displayName = 'AddonsWrapper';

export const AddondDisclaimer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;
AddondDisclaimer.displayName = 'AddondDisclaimer';

export const Text = styled.span`
  font-weight: ${ ({ isBold }: ContentProps) => (isBold ? 'bold' : 'normal') };
  white-space: pre-line;
  margin-bottom: 10px;
`;
Text.displayName = 'Text';

export const ItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
    
  &:hover ${ InfoWrapper } {
      ${ ItemName }{
        color: ${ LINK_COLOR_HOVER };
        //background-color: red;
      }
  }
`;
ItemWrapper.displayName = 'ItemWrapper';