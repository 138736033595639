import { DEFAULT_FILE_URL } from 'app/constants/attachedFile';
import { TimePeriod, PickupRequestStatus } from 'app/constants/pickupRequest';
import { COMPANY_SIMPLE_LIST, COMPANY_LIST } from 'containers/Companies/mocks';
import { MaterialDetails, PickupRequest } from '../types';
import { MATERIAL_LIST } from './containers';

export * from './containers';

const MATERIAL: MaterialDetails = {
  id: '123456789',
  containerId: '123',
  date: 1580893149831,
  deliverContainers: 10,
  destination: '',
  materialId: MATERIAL_LIST[0].id,
  pickupContainers: 10,
  timeSlot: TimePeriod.timePeriod_afternoon,
  addons: []
};

export const PICKUP_REQUEST_LIST: PickupRequest[] = [
  {
    id: '1',
    createdDate: 1580893149831,
    company: COMPANY_SIMPLE_LIST[0],
    owner: COMPANY_LIST[0].customers[0],
    logistic: {
      location: 'Hardstrasse 201, 8005...',
      preferredDate: 1580893149831,
      hasToCallClient: false,
      hasToSaveLocation: false
    },
    materials: [{ ...MATERIAL, isVeva: true }],
    commentary: {
      additionalComment: 'Example of addional comment.',
      attachedFiles: [],
      bookingReference: 'Example of bookin reference.',
      hasToSaveBookinReference: true
    },
    driverInformation: {
      additionalComment: 'Example of additional comment for my dear driverInformation',
      attachedFiles: []
    },
    status: PickupRequestStatus.PROCESSED,
    PDFUrl: DEFAULT_FILE_URL
  },
  {
    id: '2',
    createdDate: 1580893149831,
    company: COMPANY_SIMPLE_LIST[1],
    owner: COMPANY_LIST[0].customers[1],
    materials: [{ ...MATERIAL, isVeva: true }],
    logistic: {
      location: 'Hardstrasse 201, 8005...',
      preferredDate: 1580893149831
    },
    status: PickupRequestStatus.DRAFT,
    PDFUrl: DEFAULT_FILE_URL
  },
  {
    id: '3',
    createdDate: 1580893149831,
    company: COMPANY_SIMPLE_LIST[0],
    owner: COMPANY_LIST[0].customers[0],
    materials: [{ ...MATERIAL, isVeva: false }],
    logistic: {
      location: 'Hardstrasse 201, 8005...',
      preferredDate: 1580893149831
    },
    status: PickupRequestStatus.IN_PROCESS,
    PDFUrl: DEFAULT_FILE_URL
  },
  {
    id: '4',
    createdDate: 1580893149831,
    company: COMPANY_SIMPLE_LIST[1],
    owner: COMPANY_LIST[0].customers[1],
    materials: [{ ...MATERIAL, isVeva: true }],
    logistic: {
      location: 'Hardstrasse 201, 8005...',
      preferredDate: 1580893149831
    },
    status: PickupRequestStatus.PROCESSED,
    PDFUrl: DEFAULT_FILE_URL
  },
  {
    id: '5',
    createdDate: 1580893149831,
    company: COMPANY_SIMPLE_LIST[1],
    owner: COMPANY_LIST[0].customers[1],
    materials: [{ ...MATERIAL, isVeva: false }],
    logistic: {
      location: 'Hardstrasse 201, 8005...',
      preferredDate: 1580893149831
    },
    status: PickupRequestStatus.PROCESSED,
    PDFUrl: DEFAULT_FILE_URL
  },
  {
    id: '6',
    createdDate: 1580893149831,
    materials: [{ ...MATERIAL, isVeva: false }],
    company: COMPANY_SIMPLE_LIST[0],
    owner: COMPANY_LIST[0].customers[0],
    logistic: {
      location: 'Hardstrasse 201, 8005...',
      preferredDate: 1580893149831
    },
    status: PickupRequestStatus.NEW,
    PDFUrl: DEFAULT_FILE_URL
  },
  {
    id: '7',
    createdDate: 1580893149831,
    materials: [{ ...MATERIAL, isVeva: true }],
    company: COMPANY_SIMPLE_LIST[1],
    owner: COMPANY_LIST[0].customers[1],
    logistic: {
      location: 'Hardstrasse 201, 8005...',
      preferredDate: 1580893149831
    },
    status: PickupRequestStatus.DRAFT,
    PDFUrl: DEFAULT_FILE_URL
  }
];
