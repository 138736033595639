import React from 'react';
import { MIN_BAR_WIDTH } from 'app/constants/statistic';
import Tooltip from 'components/Tooltip';
import { LegendItem } from 'containers/Statistics/components/StatisticsWidget/components/Legend';
import { ChartData } from 'containers/Statistics/types';
import { Rectangle } from './styles';

interface Props {
  data: ChartData;
  dimensions: [boolean, boolean];
  isHidden?: boolean;
  metricUnits: string[];
  isBigger: number;
}

const Bar: React.FC<Props> = ({ data, dimensions, isHidden, metricUnits, isBigger }) => {
  const barElement = React.useRef<HTMLDivElement>();
  const [tooltip, setTooltip] = React.useState(false);

  const isOneDimension = dimensions.filter(d => !!d).length === 1;

  const handleMouseEnter = () => {
    setTooltip(true);
  };

  const handleMouseLeave = () => {
    setTooltip(false);
  };

  React.useEffect(() => {
    barElement.current.addEventListener('mouseenter', handleMouseEnter, true);
    barElement.current.addEventListener('mouseleave', handleMouseLeave, true);
    return () => {
      if (barElement.current) {
        barElement.current.removeEventListener('mouseenter', handleMouseEnter, true);
        barElement.current.removeEventListener('mouseleave', handleMouseLeave, true);
      }
    };
  }, []);

  const rect = barElement.current?.getBoundingClientRect();

  return (
    <>
      { tooltip && (
        <Tooltip left={ rect?.left + rect?.width / 2 } top={ rect?.top + rect?.height / 2 }>
          <LegendItem
            key={ `legend-${ data.id }` }
            color={ data.color }
            cost={ data.cost }
            metricUnits={ metricUnits }
            title={ data.title }
            weight={ data.weight }
          />
        </Tooltip>
      ) }

      <Rectangle
        key="bar"
        backgroundColor={ data.color }
        heightPercentage={ data.height }
        isHidden={ isHidden }
        ref={ barElement }
        totalWidth={ isOneDimension ? MIN_BAR_WIDTH : data.width }
        isBigger={ isBigger }
      />
    </>
  );
};

export default Bar;
