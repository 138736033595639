import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { DELIVERY_SLIP_LIST, DELIVERY_SLIP } from 'app/routes';
import { PropsWithWrapper } from 'app/types';
import Redirect from 'components/Redirect';
import DeliverySlipDetails from './containers/DeliverySlipDetails';
import DeliverySlipList from './containers/DeliverySlipList';

const DeliverySlips: React.FC<PropsWithWrapper<{}>> = ({ wrapper }) => (
  <Switch>
    <Route exact={ true } path={ DELIVERY_SLIP.path } render={ wrapper(DeliverySlipDetails) } />
    <Route exact={ true } path={ DELIVERY_SLIP_LIST.path } render={ wrapper(DeliverySlipList) } />
    <Redirect to={ DELIVERY_SLIP_LIST.path } />
  </Switch>
);

export default DeliverySlips;
