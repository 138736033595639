import produce from 'immer';
import { createReducer, isMockingApi } from 'base';
import { ActionHandler } from 'base/types';
import { ActionTypes, LoginActionType } from '../actionTypes';
import { AVAG_ADMIN, CLIENT_OPERATOR, CLIENT_SUPER_USER } from '../mocks';
import { loginInitialState } from '../models';
import { LoginState, LoggedUser } from '../types';

const loginSuccess = (state: LoginState, action: LoginActionType) =>
  produce(state, draft => {
    const user = action.payload as LoggedUser;
    draft.user = user || state.user;
  });

const loginError = (state: LoginState, action: LoginActionType) =>
  produce(state, draft => {
    const payload = action?.payload as Error;
    if (isMockingApi() && /{/.test(payload?.message)) {
      const user = JSON.parse(payload.message);
      if (user.user_email === AVAG_ADMIN.user_email) {
        draft.user = AVAG_ADMIN;
      } else if (user.user_email === CLIENT_SUPER_USER.user_email) {
        draft.user = CLIENT_SUPER_USER;
      } else {
        draft.user = CLIENT_OPERATOR;
      }
    }
  });

const getInitialState = (state: LoginState) =>
  produce(state, draft => {
    Object.keys(draft).forEach(key => {
      // @ts-ignore
      draft[key] = loginInitialState[key];
    });
  });

const actionHandlers: ActionHandler<LoginState> = {
  [ActionTypes.LOGIN_SUCCESS]: loginSuccess,
  [ActionTypes.LOGIN_ERROR]: loginError,
  [ActionTypes.LOGOUT_REQUEST]: getInitialState
};

export default createReducer(loginInitialState, actionHandlers);
