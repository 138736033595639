import styled from 'styled-components';
import { PRIMARY } from 'base/styles/skin';

import { CardContent } from '../../../styles';

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
  padding-right: 4px;
`;
Icon.displayName = Icon;

export const PictureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 36px;
`;
PictureWrapper.displayName = 'PictureWrapper';

export const TextLink = styled.div`
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;
  color: ${ PRIMARY };
`;
TextLink.displayName = 'TextLink';

export const CustomCardContent = styled(CardContent)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;

  & > ${ PictureWrapper }:last-child {
    margin-right: 0;
  }
`;
CustomCardContent.displayName = 'CustomCardContent';
