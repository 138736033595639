import { Store, Dispatch } from 'redux';
import { addRequest, removeRequest } from './actions';
import { Action, AddAction, RemoveAction } from './types';

const reduxNode = 'requests';

const requestIdentifier = (store: Store) => (next: any) => (action: Action) => {
  const { getState } = store;
  // eslint-disable-next-line prefer-destructuring
  const dispatch: Dispatch<AddAction | RemoveAction> = store.dispatch;
  const state = getState();

  const { request, requestName } = action;

  // No requestName, continue
  if (!requestName) {
    return next(action);
  }

  // Non stacked and existing requests
  if (state?.[reduxNode]?.[requestName]) {
    // TODO: Cancel previous promise (do it at promise level)
    // const promise: ModifiedPromise<any> = state[reduxNode][requestName];
    // promise.delete();
  }

  // Add new promise to reducer
  dispatch(addRequest(request, requestName));

  return next(action).finally(() => {
    dispatch(removeRequest(requestName));
  });
};

export default requestIdentifier;
