import { safeString, safeNumber, safeDate } from 'app/utils/safeTypes';
import { QuotasFromServer, QuotaLineFromServer } from '../types/fromServer';
import { Quota } from '../types';

interface PlainedQuotas extends QuotaLineFromServer {
  ValidTo: QuotasFromServer['ValidTo'];
  EgiNo: QuotasFromServer['EgiNo'];
  BuildingSite: string;
}

const createQuotaFromServer = ({ LineNo,
  LineType,
  Description,
  RemainingQuantity,
  OrigQuantity,
  EgiNo,
  BuildingSite,
  ValidTo }: PlainedQuotas): Quota | undefined => {
  if (
    LineNo === undefined
    || LineType === undefined
    || Description === undefined
    || RemainingQuantity === undefined
    || OrigQuantity === undefined
    || BuildingSite === undefined
    || safeNumber(RemainingQuantity) > safeNumber(OrigQuantity)
  ) {
    console.error('Error creating <Quota> model.');
    return undefined;
  }

  const percentage = Math.round((1 - safeNumber(RemainingQuantity) / safeNumber(OrigQuantity)) * 100);
  return {
    id: safeString(LineNo),
    label: safeString(Description),
    percentage,
    date: ValidTo ? safeDate(ValidTo).getTime() : undefined,
    total: safeNumber(OrigQuantity),
    egino: safeString(EgiNo),
    buildingsite: safeString(BuildingSite),
    restmenge: safeNumber(RemainingQuantity)

  };
};

export const createQuotaListFromServer = (quotasFromServer: QuotasFromServer[]): Quota[] => {
  if (!Array.isArray(quotasFromServer)) {
    console.error('Error creating <Quota[]> model. Not array found.');
    return [];
  }

  const quotas = quotasFromServer.reduce((prevItem, currentItem) => {
    return [
      ...prevItem,
      ...currentItem.BlanketOrderLine.map(orderLine => ({
        ...orderLine,
        ValidTo: currentItem.ValidTo,
        EgiNo: currentItem.EgiNo,
        BuildingSite: currentItem.BuildingSite
      }))
    ];
  }, [] as PlainedQuotas[]);

  return quotas.map(createQuotaFromServer).filter(q => !!q);
};
