import styled from 'styled-components';
import { CHART_LABEL_COLOR } from 'base/styles/skin';

export const Container = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;

  .recharts-layer {
    path {
      &:nth-child(1) {
        transition: fill 1s;
      }

      &:nth-child(2) {
        transition: stroke 1s;
      }
    }
  }
`;
Container.displayName = 'Container';

export const YAxis = styled.div`
  max-width: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 0;
  padding-bottom: 55px;
  //font-family: Roboto;
  font-family: 'TT Norms';
  font-size: 13.5px;
  position: absolute;
`;
YAxis.displayName = 'YAxis';

export const YAxisLabel = styled.div`
  transform: rotate(-90deg);
  //font-family: Roboto;
  font-family: 'TT Norms';
  font-size: 13.5px;
  color: ${ CHART_LABEL_COLOR };
`;
YAxisLabel.displayName = 'YAxisLabel';
