import React from 'react';
import styled from 'styled-components';
import Card from 'components/Card';
import { WrapperTitle } from 'components/SectionTitle/styles';
import { SectionWrapper } from 'containers/PickupRequest/containers/PickupRequestDetails/styles';

import Skeleton, { AvagDot, Bar, Buttons } from './pieces';
import Table from './Table';

const StyledBar = styled(Bar)`
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  width: 200px;
`;

const StyledField = styled(StyledBar)`
  height: 1px;
`;

const FieldsWrapper = styled.div`
  & > ${ StyledField } {
    margin: 24px;
    width: 400px;
  }
`;
const LogisticsCard = styled(Card)`
  min-height: 300px;
  padding-top: 32px;
`;

const AttachmentsCard = styled(Card)`
  padding: 8px 0;
`;

const LogisticSection: React.FC<Props> = () => (
  <SectionWrapper>
    <WrapperTitle>
      <AvagDot />
      <StyledBar />
    </WrapperTitle>
    <LogisticsCard indentation={ false }>
      <FieldsWrapper>
        <StyledField />
        <StyledField />
        <StyledField />
        <StyledField />
        <StyledField />
      </FieldsWrapper>
      <Table actions={ 0 } showActionsBar={ false } rows={ 2 }></Table>
    </LogisticsCard>
  </SectionWrapper>
);

interface Props {
  attachments?: number;
}

const DeliverySlipDetailsSkeleton: React.FC<Props> = ({ attachments = 0 }) => (
  <Skeleton>
    <LogisticSection />
    { attachments ? (
      <>
        <WrapperTitle>
          <AvagDot />
          <StyledBar />
        </WrapperTitle>
        <AttachmentsCard indentation={ true }>
          <Buttons align="left" count={ attachments } />
        </AttachmentsCard>
      </>
    ) : null }
  </Skeleton>
);

export default DeliverySlipDetailsSkeleton;
