import React from 'react';
import { TooltipPayload } from 'recharts';
import { isWeightChart } from 'app/utils/chart';
import { ChartData } from 'containers/Statistics/types';
import { LegendItem } from '../Legend';
import { Wrapper } from './styles';

interface Props {
  active?: boolean;
  metricUnits: string[];
  payload?: ReadonlyArray<TooltipPayload>;
}

const CustomTooltip: React.FC<Props> = ({ active, metricUnits, payload }) => {
  const charData = payload?.[0]?.payload as ChartData;

  if (!active || !charData) {
    return null;
  }

  const isWeight = isWeightChart(metricUnits);

  return (
    <Wrapper>
      <LegendItem
        key="legend-item"
        cost={ !isWeight && charData.cost }
        date={ charData.date }
        title={ charData.title }
        metricUnits={ metricUnits }
        weight={ isWeight && charData.weight }
      />
    </Wrapper>
  );
};

export default CustomTooltip;
