import React from 'react';
import { formatDate, formatCurrency } from 'app/utils/formatters';
import { useTranslationsContext } from 'app/translations';
import { Quota } from 'containers/Quotas/types';

import ProgressBar from 'components/ProgressBar';
import { Wrapper, Line, Title, Text } from './styles';

interface Props {
  item: Quota;
}
const ItemRow: React.FC<Props> = ({ item }) => {
  const translations = useTranslationsContext();
  
  return (
    <Wrapper>
      <Line>
        <Title>{ item.label }</Title>
        <Text>{ item.percentage }%</Text>
      </Line>
      <ProgressBar width={ item.percentage } large={ true } />
      <Line>
        { item.date && (
          <Text>
            { translations.home_quotas_upToLabel }: { formatDate(new Date(item.date)) }
          </Text>
        ) } 
        <Text>{ translations.home_quotas_EGI } : { item.egino }</Text>
      </Line>
      <Line>
        <Text><span className="rightContent">{ translations.home_quotas_Kontingentmenge }:
        </span><span className="leftContent">{ formatCurrency(item.total, 'Kg') }</span></Text>
        { /* <Text>{ translations.home_quotas_Restmenge } :{ formatCurrency(item.restmenge, 'Kg') }</Text> */ }
        { /* { item.percentage && (
          <Text>{ item.percentage }%</Text>
        ) } */ }
      </Line>
      <Line>
        { (item.restmenge || item.restmenge === 0) && (
          <Text>
            <span className="rightContent"> { translations.home_quotas_Verwendete_menge }:</span>
            <span className="leftContent">{ formatCurrency((item.total - item.restmenge), 'Kg') }</span> 
          </Text>
        ) }
      </Line>
      <Line>
        { (item.restmenge || item.restmenge === 0) && (
          <Text><span className="rightContent">
            { translations.home_quotas_Restmenge }:</span>
          <span className="leftContent">{ formatCurrency(item.restmenge, 'Kg') }</span>
          </Text>
        ) }
      </Line>
      <Line>
        { item.buildingsite && (
          <Text>
            { translations.home_quotas_Beschreibung }: { item.buildingsite }
          </Text>
        ) }
      </Line>
    </Wrapper>
  );
};
export default ItemRow;
