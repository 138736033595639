import React from 'react';
import { useTranslationsContext } from 'app/translations';
import Image from 'components/Image';
import MosaicList from 'components/MosaicList';
import { Container, ContainerType } from 'containers/PickupRequest/types';
import { ContainerWrapper,
  SectionTitle,
  ItemWrapper,
  ImageWrapper,
  InfoWrapper,
  ItemName,
  ItemDetails } from '../../styles';

const placeholderContainerIcon = require('assets/icons/placeholders/container.svg');

interface Props {
  handleSelectContainer: (container: Container) => void;
  containers: Container[];
  type?: ContainerType;
}

const SelectContainer: React.FC<Props> = ({ handleSelectContainer, containers, type: selectedType }) => {
  const { containers_selectContainer } = useTranslationsContext();

  return (
    <ContainerWrapper>
      <SectionTitle>{ containers_selectContainer.replace(':typeName:', selectedType?.name) }</SectionTitle>
      <MosaicList>
        { ({ MosaicItem }) =>
          containers.map(({ addons, id, image, name, description, permissions, type }) => (
            <MosaicItem
              key={ id }
              flex={ true }
              onClick={ () => {
                handleSelectContainer({ addons, id, image, name, description, permissions, type });
              } }
            >
              <ItemWrapper>
                <ImageWrapper>
                  <Image
                    key={ `container-${ id }-img` }
                    alt={ name }
                    sourceImg={ image }
                    loading="eager"
                    fallbackImg={ placeholderContainerIcon }
                  />
                </ImageWrapper>
                <InfoWrapper>
                  <ItemName>{ name }</ItemName>
                  <ItemDetails>{ description }</ItemDetails>
                </InfoWrapper>
              </ItemWrapper>
            </MosaicItem>
          ))
        }
      </MosaicList>
    </ContainerWrapper>
  );
};

export default SelectContainer;
