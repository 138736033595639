import { RootState } from 'base/types';
import { Pagination } from 'app/types';
import { Notification } from '../types';

interface CollectionReturn {
  collection: Notification[];
  pagination: Pagination;
}

export const getRawNotificationList = ({ notifications: { collection, pagination } }: RootState): CollectionReturn => ({
  collection,
  pagination
});

export const getNotificationList = (rootState: RootState): CollectionReturn => {
  return rootState.notifications;
  /* Removing filter based on user permissions */
  // const canSeePickups = canDo('VIEW_PICKUPS')(rootState);
  // const canSeeDeliverySlips = canDo('VIEW_DELIVERY_SLIPS')(rootState);
  // const canSeeBills = canDo('VIEW_BILLS')(rootState);
  // const canSeeUsers = canDo('VIEW_USERS')(rootState);

  // const collection = rootState.notifications.collection.filter(notif => {
  //   switch (notif.entityType) {
  //     case NOTIFICATION_TYPE.PICKUP:
  //       return canSeePickups;

  //     case NOTIFICATION_TYPE.DELIVERY:
  //       return canSeeDeliverySlips;

  //     case NOTIFICATION_TYPE.BILL:
  //       return canSeeBills;

  //     case NOTIFICATION_TYPE.USER:
  //       return canSeeUsers;

  //     default:
  //       return true;
  //   }
  // });

  // const { pagination } = rootState.notifications;
  // return {
  //   collection,
  //   pagination: {
  //     ...pagination,
  //     totalCount: pagination.totalCount < pagination.pageSize ? collection.length : pagination.totalCount
  //   }
  // };
};
