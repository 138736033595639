import { useTranslationsContext } from 'app/translations';
import { ButtonProps } from 'components/Touchable/types';

export const useButtons = (onDismiss: () => void): ButtonProps[] => {
  const translations = useTranslationsContext();
  const buttons: ButtonProps[] = [];

  buttons.push({
    id: 'successModal_cancel',
    type: 'button',
    isAlwaysEnabled: true,
    label: translations.template_modalBox_cancel,
    onClick: onDismiss
  });

  buttons.push({
    id: 'successModal_confirm',
    type: 'submit',
    buttonType: 'primary',
    isAlwaysEnabled: true,
    label: translations.pickupRequestDetails_abortConfirm
  });

  return buttons;
};
