/* eslint-disable max-lines */
import styled from 'styled-components';
import { TABLET, DESKTOP } from 'base/styles/media-queries';
import { LINE_SEPARATOR_COLOR_MATERIAL } from 'base/styles/skin';
import Card from 'components/Card';

export const DriverOrderRowContainer = styled(Card)`
  margin-top: 20px;

    ${ DESKTOP } {
    padding:0 20px;
  }

  &:first-child {
    margin-top: 0;
  }

  ${ TABLET } {
    margin-top: 0;
  }

  & > div:first-child {
    align-items: flex-end;
    border-bottom: 1.5px solid ${ LINE_SEPARATOR_COLOR_MATERIAL };
    display: flex;
    flex-direction: column;

    ${ TABLET } {
      align-items: center;
      flex-direction: row;
    }
  }
`;
DriverOrderRowContainer.displayName = 'DriverOrderRowContainer';

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${ TABLET } {
    flex: 1 1 auto;
  }

  ${ DESKTOP } {
    flex-direction: row;
  }
`;
FieldsWrapper.displayName = 'FieldsWrapper';

export const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;

  ${ DESKTOP } {
    flex: 1 0 0px;
  }
`;
FlexBox.displayName = 'FlexBox';

export const SelectionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
`;
SelectionsWrapper.displayName = 'SelectionsWrapper';

export const SelectContainerWrapper = styled.div``;
SelectContainerWrapper.displayName = 'SelectContainerWrapper';

export const MaterialTypeWrapper = styled.div`
  flex: 0 0 auto;
  min-width: 100%;
`;
MaterialTypeWrapper.displayName = 'MaterialTypeWrapper';

export const FlexField = styled.div`
  flex: auto;
  min-width: 100px !important;
  padding: 0 4px;
  width: inherit;

  ${ DESKTOP } {
    flex: 1 0 0px;
  }
`;
FlexField.displayName = 'FlexField';

export const ContainerField = styled.div`
  flex: 1 0 auto;

  ${ DESKTOP } {
    flex: 1 0 0px;
  }
`;
ContainerField.displayName = 'ContainerField';
export const ContentWrapper = styled.div`
  vertical-align: top;

  ${ TABLET } {
    display: inline-block;
    width: 100%;
    padding-right: 8px;
  }
`;
ContentWrapper.displayName = 'ContentWrapper';

export const DateWrapper = styled.div`
  vertical-align: top;

  ${ TABLET } {
    display: inline-block;
    width: 100%;
    padding-right: 8px;
  }
`;
DateWrapper.displayName = 'DateWrapper';

export const DateWrapperRed = styled.div`
  vertical-align: top;
 & input {
   color: red !important;
 }
  ${ TABLET } {
    display: inline-block;
    width: 100%;
    padding-right: 8px;
  }
`;
DateWrapperRed.displayName = 'DateWrapperRed';

export const TimeWrapper = styled.div`
  vertical-align: top;

  ${ TABLET } {
    display: inline-block;
    width: 100%;
    padding-left: 8px;
  }
`;
TimeWrapper.displayName = 'TimeWrapper';
