import { Action } from '../../../types';

export enum ActionTypes {
  ONBOARD_REQUEST = 'ONBOARD_REQUEST',
  ONBOARD_SUCCESS = 'ONBOARD_SUCCESS',
  ONBOARD_ERROR = 'ONBOARD_ERROR'
}

type OnboardRequest = Action<ActionTypes.ONBOARD_REQUEST>;
type OnboardSuccess = Action<ActionTypes.ONBOARD_SUCCESS>;
type OnboardError = Action<ActionTypes.ONBOARD_ERROR>;

export type OnboardActionType = OnboardRequest | OnboardSuccess | OnboardError;
