import styled from 'styled-components';
import { TABLET, DESKTOP } from 'base/styles/media-queries';

const CONTACT_INFO_LABEL_MIN_WIDTH = 100;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;

  ${ TABLET } {
    flex-direction: row;
  }
`;
Container.displayName = 'Container';

export const IdFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${ TABLET } {
    flex-direction: row;
    & > * {
      padding: 0 8px;
      flex: 1;
    }
    & > *:first-child {
      padding-left: 0;
    }
    & > *:last-child {
      padding-right: 0;
    }
  }
`;
IdFieldsWrapper.displayName = 'IdFieldsWrapper';

export const CustomerInfoWrapper = styled.div`
  flex: 1;
`;
CustomerInfoWrapper.displayName = 'CustomerInfoWrapper';

export const BillAddressWrapper = styled.div`
  width: 100%;

  ${ TABLET } {
    width: 33%;
    padding-right: 8px;
  }

  ${ DESKTOP } {
    width: 50%;
  }
`;
BillAddressWrapper.displayName = 'BillAddressWrapper';

export const ContactInfo = styled.ul``;
ContactInfo.displayName = 'ContactInfo';

export const ContactListItem = styled.li`
  display: flex;
`;
ContactListItem.displayName = 'ContactListItem';

export const ContactLabel = styled.div`
  min-width: ${ CONTACT_INFO_LABEL_MIN_WIDTH }px;
  padding-right: 24px;
`;
ContactLabel.displayName = 'ContactLabel';

export const ContactValue = styled.div`
  flex: 1;
`;
ContactValue.displayName = 'ContactValue';
