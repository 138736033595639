import { FormikValues } from 'formik';
import React from 'react';
import { ObjectSchema } from 'yup';
import { formatDate } from 'app/utils/formatters';
import { createIndexedFields } from 'app/utils/fields';
import { useTranslationsContext } from 'app/translations';
import LockIcon from 'components/LockIcon';
import SectionTitle from 'components/SectionTitle';
import SmartForm from 'components/SmartForm';
import { FieldProps } from 'components/SmartForm/types';
import { ButtonProps } from 'components/Touchable/types';
import { ROLE } from 'app/constants/roles';
import { User, UserStatus } from '../../../../types';
import { ModalProps, FieldName } from '../../types';
import ConfirmationModal from '../ConfirmationModal';
import StatusMessage from '../StatusMessage';
import { Wrapper, Message } from '../StatusMessage/styles';
import { StyledCard as Card, FieldsWrapper, ButtonsWrapper, Legend, UserDate } from './styles';

const getComputedTitle = (values: FormikValues) => `${ values.firstName } ${ values.lastName }`;

interface Props {
  buttons: ButtonProps[];
  fields: FieldProps[];
  initialValues: User;
  isErrored: boolean;
  isLoading: boolean;
  modalProps?: ModalProps;
  onSubmit: () => void;
  status?: UserStatus;
  updateValues: (values: FormikValues) => void;
  validationSchema: ObjectSchema<object>;
}

const View: React.FC<Props> = ({ buttons,
  fields,
  initialValues,
  isErrored,
  isLoading,
  modalProps,
  onSubmit,
  status,
  updateValues,
  validationSchema }) => {
  const translations = useTranslationsContext();
  const firstName = fields.find(f => f.id === FieldName.firstName);
  const canEdit = !firstName?.isDisabled;

  return (
    <>
      <SmartForm
        buttons={ buttons }
        fields={ fields }
        form={ 'user' }
        handleSubmit={ onSubmit }
        initialValues={ initialValues }
        isFetching={ isLoading }
        submitRequireDirty={ false }
        validationSchema={ validationSchema }
        validateRequireDirty={ false }
      >
        { ({ Buttons, controlledButtons, controlledFields, dirty, Field, setFieldValue, values }) => {
          const indexedFields = createIndexedFields<FieldName>(controlledFields);
          updateValues(values);

          return (
            <>
              <SectionTitle>
                { getComputedTitle(values) || translations.userDetails_title } <LockIcon locked={ !canEdit } />
                <UserDate>{ formatDate(new Date(initialValues.date)) }</UserDate>
              </SectionTitle>

              <Card extraBottomSpace={ !!indexedFields.changePassword }>
                { indexedFields.companyName && <Field options={ indexedFields.companyName } /> }

                { indexedFields.companyId && (
                  <Field
                    options={ {
                      ...indexedFields.companyId,
                      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(FieldName.role, ROLE.CLIENT_OPERATOR.toString());
                        indexedFields.companyId.onChange(event);
                      }
                    } }
                  />
                ) }

                <FieldsWrapper>
                  <Field options={ indexedFields.firstName } />
                  <Field options={ indexedFields.lastName } />
                  <Field
                    options={ {
                      ...indexedFields.email,
                      error: indexedFields?.email?.error || (isErrored && translations.validations_alreadyInUse)
                    } }
                  />
                  <Field options={ indexedFields.phoneNumber } />
                </FieldsWrapper>

                { indexedFields.changePassword && (
                  <Buttons
                    key="forgot-password-wrapper"
                    align="left"
                    options={ [indexedFields.changePassword as ButtonProps] }
                  />
                ) }
              </Card>

              { indexedFields.role && (
                <>
                  <SectionTitle>{ translations.userDetails_titleRole }</SectionTitle>
                  <Card>
                    <Legend>{ translations.userDetails_roleLegend }</Legend>
                    <Field options={ indexedFields.role } />
                  </Card>
                </>
              ) }

              { (indexedFields[FieldName.requestPickup] || indexedFields[FieldName.accessBills]) && (
                <>
                  <SectionTitle>{ translations.userDetails_titleFunctionalities }</SectionTitle>
                  <Card>
                    { indexedFields[FieldName.requestPickup] && (
                      <Field key="requestPickup" options={ indexedFields[FieldName.requestPickup] } />
                    ) }
                    { indexedFields[FieldName.accessBills] && (
                      <Field key="accessBills" options={ indexedFields[FieldName.accessBills] } />
                    ) }
                  </Card>
                </>
              ) }

              { !!dirty && (
                <Wrapper>
                  <Message>{ translations.userDetails_changePermissionsDisclaimer }</Message>
                </Wrapper>
              ) }

              <StatusMessage status={ status } />

              <ButtonsWrapper>
                <Buttons options={ controlledButtons } />
              </ButtonsWrapper>
            </>
          );
        } }
      </SmartForm>

      { modalProps && <ConfirmationModal { ...modalProps } /> }
    </>
  );
};

export default View;
