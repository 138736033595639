import React from 'react';

import { useTranslationsContext } from 'app/translations';
import { Wrapper, Text, TextLink, BackButton } from './styles';
import GoBackToLoginButton from '../BackToLoginButton';

interface Props {
  setOpenModal: (openModal: boolean) => void;
}

export const PrivacyAgreementLink: React.FC<Props> = ({ setOpenModal }) => {
  const translations = useTranslationsContext();
  return (
    <Wrapper>
      <Text>
        { translations.onboarding_privacy1 }{ ' ' }
        <TextLink
          to="#"
          onClick={ () => {
              window.open("https://www.avag.ch/impressum", "_blank");
          } }
        >
          { translations.onboarding_privacy2 }
        </TextLink>
        .
      </Text>
      {/* <BackButton> */}
      {/*   <GoBackToLoginButton/> */}
      {/* </BackButton> */}
    </Wrapper>
  );
};

export default PrivacyAgreementLink;
