import styled from 'styled-components';

import { TABLET, DESKTOP } from 'base/styles/media-queries';
import { LOGIN_LOGO_SIZE, LOGIN_LOGO_SIZE_TABLET, LOGIN_LOGO_SIZE_DESKTOP } from 'base/styles/sizes';

export const Wrapper = styled.header``;
Wrapper.displayName = 'Wrapper';

export const HeaderBar = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  top: 0;
`;
HeaderBar.displayName = 'HeaderBar';

export const Logo = styled.img`
  width: auto;
  height: ${ LOGIN_LOGO_SIZE }px;

  ${ TABLET } {
    height: ${ LOGIN_LOGO_SIZE_TABLET }px;
  }

  ${ DESKTOP } {
    height: ${ LOGIN_LOGO_SIZE_DESKTOP }px;
  }
`;
Logo.displayName = 'Logo';
