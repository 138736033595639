import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { PRIMARY, LINE_SEPARATOR_COLOR, LINK_COLOR_HOVER } from 'base/styles/skin';

export const Link = styled(ReactRouterLink)`
  &,
  &:link,
  &:visited {
    text-decoration: none;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;
Link.displayName = 'Link';

export const LoginActions = styled.div`
  display: flex;
  padding-bottom: 25px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;
LoginActions.displayName = 'LoginActions';

export const ForgotPwd = styled(Link)`
  &,
  &:link,
  &:visited {
    font-size: 14px;
    font-weight: 500;
    color: ${ PRIMARY };
    text-decoration: underline;
  }
  &:hover {
    color: ${ LINK_COLOR_HOVER };
  }
`;
ForgotPwd.displayName = 'ForgotPwd';

export const LineSeparator = styled.div`
  margin-bottom: 25px;
  height: 0.08rem;
  background-color: ${ LINE_SEPARATOR_COLOR };
`;
LineSeparator.displayName = 'LineSeparator';
