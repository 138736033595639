/* eslint-disable max-len */
import { safeString, safeNumber } from 'app/utils/safeTypes';
import { MaterialDetails, PickupRequest, ContainerAddon, TemplateUpdateRequest } from '../types';
import { PickupRequestToServer, MaterialDetailsToServer, AddonToServer } 
  from '../types/toServer';

const createDateString = (date: Date): string => {
  if (!date) {
    return '';
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${ year }-${ month }-${ day }`;
};

const createAddonToServer = ({ addonId, defaultValue, id }: ContainerAddon): AddonToServer => ({
  addons_content_id: safeNumber(id),
  addons_id: safeNumber(addonId),
  value: safeNumber(defaultValue)
});

const createMaterialDetailsToServer = (material: MaterialDetails): MaterialDetailsToServer => ({
  addons: material.addons.map(createAddonToServer),
  container_id: safeNumber(material.containerId),
  delivery_frame_id_material: material.timeSlot ? safeNumber(material.timeSlot) : null,
  destination: safeString(material.destination),
  material_id: safeString(material.materialId),
  material_name: safeString(material.materialName), // safeString(material.materialName),
  number_of_containers_to_deliver: material.deliverContainers,
  number_of_containers_to_pick: material.pickupContainers,
  pickup_date_material: createDateString(material.date ? new Date(material.date) : new Date()),
  pickup_content_id: material.id ? safeNumber(material.id) : 0,
  veva_documentation: material.handleDocumentation,
  veva: !!material.isVeva,
});

export const createPickupRequestToServer = (pickup: PickupRequest, isAdmin: boolean): PickupRequestToServer => {
  return ({
    ask_to_call: pickup.logistic.hasToCallClient,
    comments: pickup.commentary.additionalComment,
    contact_phone: safeString(pickup.logistic.telephone),
    date_pickup_logistic: createDateString(new Date(pickup.logistic.preferredDate)),
    delivery_frame_id_logistic: pickup.logistic.timePeriod,
    driver_comments: isAdmin ? pickup.driverInformation.additionalComment : undefined,
    location: pickup.logistic.location,
    location_save: pickup.logistic.hasToSaveLocation,
    material: pickup.materials.map(createMaterialDetailsToServer),
    pickup_id: pickup.id !== undefined ? safeNumber(pickup.id) : undefined,
    reference_number_id: safeString(pickup.commentary.bookingReference),
    reference_save: pickup.commentary.hasToSaveBookinReference,
    status_id: pickup.logistic.templateDateRule ? 1 : pickup.status,
    user_name: safeString(pickup.logistic.creatorName),
    // When user is Admin
    client_id_admin: isAdmin ? safeNumber(pickup.company.id) : 0,
    // requestor_id: isAdmin ? safeNumber(pickup.logistic.creatorName) : undefined,
    save_template: pickup.templateActionDetails ? !!(pickup.templateActionDetails.saveTemplate) : undefined,
    template_name: pickup.templateActionDetails ? pickup.templateActionDetails.templateName : undefined,
    // template_date_frame_type_id: (pickup.logistic.templateDateRule || pickup.templateActionDetails.templateDateRule)
    // create template issue 
    template_date_frame_type_id: isAdmin ? undefined : (pickup.logistic.templateDateRule || pickup.templateActionDetails.templateDateRule)
  });
};

export const resendPickupRequestToServer = (pickup: PickupRequest, isAdmin: boolean): PickupRequestToServer => {
  return ({
    ask_to_call: pickup.logistic.hasToCallClient,
    comments: pickup.commentary.additionalComment,
    contact_phone: safeString(pickup.logistic.telephone),
    date_pickup_logistic: createDateString(new Date(pickup.logistic.preferredDate)),
    delivery_frame_id_logistic: pickup.logistic.timePeriod,
    driver_comments: isAdmin ? pickup.driverInformation.additionalComment : undefined,
    location: pickup.logistic.location,
    location_save: pickup.logistic.hasToSaveLocation,
    material: pickup.materials.map(createMaterialDetailsToServer),
    pickup_id: pickup.id !== undefined ? safeNumber(pickup.id) : undefined,
    reference_number_id: safeString(pickup.commentary.bookingReference),
    reference_save: pickup.commentary.hasToSaveBookinReference,
    status_id: pickup.logistic.templateDateRule ? 1 : pickup.status,
    user_name: safeString(pickup.logistic.creatorName),
    // When user is Admin
    client_id_admin: isAdmin ? safeNumber(pickup.company.id) : 0,
    // requestor_id: isAdmin ? safeNumber(pickup.logistic.creatorName) : undefined,
    save_template: pickup.templateActionDetails ? !!(pickup.templateActionDetails.saveTemplate) : undefined,
    template_name: pickup.templateActionDetails ? pickup.templateActionDetails.templateName : undefined,
    // template_date_frame_type_id: (pickup.logistic.templateDateRule || pickup.templateActionDetails.templateDateRule)
    // create template issue
    template_date_frame_type_id: isAdmin ? undefined : (pickup.logistic.templateDateRule || pickup.templateActionDetails.templateDateRule)
  });
};

export const createNewTemplateRequestToServer = (pickup: PickupRequest, isAdmin: boolean): PickupRequestToServer => {
  return ({
    ask_to_call: pickup.logistic.hasToCallClient,
    comments: pickup.commentary.additionalComment,
    contact_phone: safeString(pickup.logistic.telephone),
    date_pickup_logistic: createDateString(new Date(pickup.logistic.preferredDate)),
    delivery_frame_id_logistic: pickup.logistic.timePeriod,
    driver_comments: isAdmin ? pickup.driverInformation.additionalComment : undefined,
    location: pickup.logistic.location,
    location_save: pickup.logistic.hasToSaveLocation,
    material: pickup.materials.map(createMaterialDetailsToServer),
    pickup_id: pickup.id !== undefined ? safeNumber(pickup.id) : undefined,
    reference_number_id: safeString(pickup.commentary.bookingReference),
    reference_save: pickup.commentary.hasToSaveBookinReference,
    status_id: pickup.logistic.templateDateRule ? 1 : pickup.status,
    user_name: safeString(pickup.logistic.creatorName),
    // When user is Admin
    client_id_admin: isAdmin ? safeNumber(pickup.company.id) : 0,
    requestor_id: isAdmin ? safeNumber(pickup.owner.id) : undefined,
    save_template: pickup.templateActionDetails ? !!(pickup.templateActionDetails.saveTemplate) : undefined,
    template_name: pickup.templateActionDetails ? pickup.templateActionDetails.templateName : undefined,
    template_date_frame_type_id: (pickup.logistic.templateDateRule || pickup.templateActionDetails.templateDateRule)
  });
};

export const createTemplateUpdateToServer = (pickup: TemplateUpdateRequest): any => {
  return ({
    pickup_template_id: safeNumber(pickup.templateId),
    client_id_admin: 0,
    template_name: pickup.templateName,
    location_save: pickup.logistic.hasToSaveLocation,
    location: pickup.logistic.location,
    template_date_frame_type_id: pickup.logistic.templateDateRule || pickup.templateActionDetails.templateDateRule,
    ask_to_call: pickup.logistic.hasToCallClient,
    contact_phone: safeString(pickup.logistic.telephone),
    comments: pickup.commentary.additionalComment,
    reference_number_id: safeString(pickup.commentary.bookingReference),
    reference_save: pickup.commentary.hasToSaveBookinReference,
    delivery_frame_id_logistic: pickup.logistic.timePeriod,
    requestor_id: undefined,
    material: pickup.materials.map(createMaterialDetailsToServer),
    driver_comments: undefined
  });
};