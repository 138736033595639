import styled from 'styled-components';
import * as SIZES from 'base/styles/sizes';
import { EXPORT_DESCRIPTION_COLOR, MODAL_FOOTER } from 'base/styles/skin';

export const TextDescription = styled.div`
  padding: 24px;
  padding-top: 0;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${ EXPORT_DESCRIPTION_COLOR };
`;
TextDescription.displayName = 'TextDescription';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;

  & > *:last-child {
    margin-right: 0;
  }
`;
FormWrapper.displayName = 'FormWrapper';

export const FieldWrapper = styled.div`
  flex: 1;
  margin: 0 12px;
`;
FieldWrapper.displayName = 'FieldWrapper';

export const Footer = styled.footer`
  width: 100%;
  min-height: ${ SIZES.MODAL_FOOTER_SIZE }px;
  background-color: ${ MODAL_FOOTER };
  padding: 0 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 0 4px 4px;
`;
Footer.displayName = 'Footer';

export const Body = styled.div`
  padding: 24px;
  padding-top: 0;
  white-space: pre-line;
`;
