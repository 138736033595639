import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIsLoading } from 'app/hooks';
import { useTranslationsContext } from 'app/translations';
import debounce from 'app/utils/debounce';
import InputFilter from 'components/InputFilter';
import Table from 'components/Table';
import { SelectedItem } from 'components/Table/types';
import { TableSkeleton } from 'app/components/Skeletons';
import * as actions from '../actions';
import { useCompaniesRequest, useCompaniesReset } from '../hooks';
import { createRowFromCompanies } from '../models';
import { getCompanies } from '../selectors';
import { HandleCheckbox, CompanyPermissionRequest, CompaniesRow } from '../types';
import { useTableData } from './data';

const CompanyList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { collection, pagination } = useSelector(getCompanies);
  const openQueries = useIsLoading(['get-companies']);
  const translations = useTranslationsContext();

  const [filterValue, setFilterValue] = useState<string>(undefined);
  const dispatch = useDispatch();

  const companiesRow = React.useMemo(() => {
    return collection.map(createRowFromCompanies);
  }, [collection]);

  useCompaniesReset();
  useCompaniesRequest(pagination.currentPage, pagination.pageSize, filterValue);

  const handleCheckboxChange: HandleCheckbox = (data, permission) => {
    const newData = { ...data, [permission]: !data[permission] };
    const request: CompanyPermissionRequest = {
      client_id: data.id as string,
      portal_enable: !!newData.portal,
      pickup_request_enable: !!(newData.portal && newData.requestPickup),
      bills_enable: !!(newData.portal && newData.accessBills)
    };
    dispatch(actions.companiesPermissionsRequest(request));
  };

  const { columns } = useTableData(handleCheckboxChange);

  const handlePagination = useCallback(
    ({ selected }: SelectedItem) => {
      dispatch(actions.getCompanies(selected, pagination.pageSize, filterValue));
    },
    [pagination.pageSize, filterValue]
  );

  const handleFilter = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  }, []);

  React.useEffect(() => {
    debounce(() => {
      if (isLoading) {
        setIsLoading(openQueries);
      }
    }, 100);
  }, [openQueries]);

  const tableActions = [
    <InputFilter
      key="settings-table-filter"
      onChange={ handleFilter }
      placeholder={ translations.settings_search_placeholder }
    />
  ];

  if (isLoading && !columns.length) {
    return <TableSkeleton />;
  }

  return (
    <Table<CompaniesRow>
      actions={ tableActions }
      columns={ columns }
      pagination={ {
        onChange: handlePagination,
        ...pagination
      } }
      rows={ companiesRow }
      title={ translations.routeName_settings }
    />
  );
};

export default CompanyList;
