import { Action } from '../../../types';

export enum ActionTypes {
  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
}

type ForgotPasswordRequest = Action<ActionTypes.FORGOT_PASSWORD_REQUEST>;
type ForgotPasswordSuccess = Action<ActionTypes.FORGOT_PASSWORD_SUCCESS>;
type ForgotPasswordError = Action<ActionTypes.FORGOT_PASSWORD_ERROR>;

export type ForgotPasswordActionType = ForgotPasswordRequest | ForgotPasswordSuccess | ForgotPasswordError;
