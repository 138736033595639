import { Dispatch } from 'redux';
import { PICKUP_REQUESTS_LIST, TEMPLATE_LIST } from 'app/routes';
import { PickupRequestStatus } from 'app/constants/pickupRequest';
import { MODALS } from '../../PickupRequestDetails/types';
import { TemplateRequest, PickupRequestFromTemplateHome } from '../types';
import { processTemplateRequest, deleteTemplateRequest,
  updateTemplateRequest, placeOrderFromHomeTemplateRequest } from '../actions';

type SetOpenModal = (openModal: MODALS) => void;

interface CommonDependencies {
    dispatch: Dispatch;
    setOpenModal: SetOpenModal;
}

export const dismiss = (setOpenModal: SetOpenModal) => {
  setOpenModal(undefined);
};

interface ProcessTemplateRequest {
    dispatch: Dispatch;
    history: any;
    templateRequest: TemplateRequest;
  }

  interface DeleteTemplateRequest {
    dispatch: Dispatch;
    history: any;
    templateId: string;
  }

  interface SaveTemplateRequest {
    dispatch: Dispatch;
    history: any;
    pickupRequest: TemplateRequest;
    status?: PickupRequestStatus;
  }

interface PlaceOrderFromHome {
  dispatch: Dispatch;
  history: any;
  pickupFromHomeTemplate: PickupRequestFromTemplateHome;
}
  
export const deleteTemplate = async ({ dispatch, history, templateId }: DeleteTemplateRequest) => {
  const { type } = await dispatch(deleteTemplateRequest(templateId));

  if (new RegExp('SUCCESS').test(type)) {
    history.push(TEMPLATE_LIST.path);
  }
};

export const placeOrderTemplate = async ({ dispatch, history, templateRequest }: ProcessTemplateRequest) => {
  const { type } = await dispatch(processTemplateRequest(templateRequest));

  if (new RegExp('SUCCESS').test(type)) {
    history.push(PICKUP_REQUESTS_LIST.path);
  }
};

export const updateTemplate = async ({ dispatch, history, pickupRequest }: SaveTemplateRequest) => {
  const { type } = await dispatch(updateTemplateRequest(pickupRequest));

  if (new RegExp('SUCCESS').test(type)) {
    history.push(TEMPLATE_LIST.path);
  }
};

export const placeOrderFromHomeTemplate = async ({ dispatch, history, pickupFromHomeTemplate }: PlaceOrderFromHome) => {
  const { type } = await dispatch(placeOrderFromHomeTemplateRequest(pickupFromHomeTemplate));
  if (new RegExp('SUCCESS').test(type)) {
    history.push(PICKUP_REQUESTS_LIST.path);
  }
};