import styled from 'styled-components';
import { TITLE_COLOR } from 'base/styles/skin';
import DefaultPage from 'components/Page';
import { DESKTOP } from 'base/styles/media-queries';

export const Page = styled(DefaultPage)`
  ${ DESKTOP } {
    padding-left: 20px;
  }
`;

interface StyledProps {
  isBold?: boolean;
}

export const TextParagraph = styled.div`
  font-size: 14px;
  color: ${ TITLE_COLOR };
`;
TextParagraph.displayName = 'TextParagraph';

export const CardContent = styled.div`
  margin: 20px 0;
  & > ${ TextParagraph } {
    margin-bottom: 10px;
  }
`;
CardContent.displayName = 'CardContent';

export const AsideListItem = styled.li`
  margin-left:20px;
`;
AsideListItem.displayName = 'AsideListItem';

export const Text = styled.span`
  font-weight: ${ ({ isBold }: StyledProps) => (isBold ? 'bold' : 'normal') };
`;
Text.displayName = 'HighlightText';

export const HelpButtonWrapper = styled.div`
  display: none;
  flex: 1;
  justify-content: flex-end;
  ${ DESKTOP } {
    display: flex;
  }
`;
HelpButtonWrapper.displayName = 'HelpButtonWrapper';
