import React from 'react';
import { useTranslationsContext } from 'app/translations';
import OnBoardingButton from '../OnboardingButton';
import { Wrapper, Text } from './styles';

export const Footer: React.FC = () => {
  const translations = useTranslationsContext();

  return (
    <Wrapper>
      <Text>{ translations.login_footer_1 } </Text>
      <Text>{ translations.login_footer_2 }.</Text>
      <OnBoardingButton></OnBoardingButton>
    </Wrapper>
  );
};

export default Footer;
