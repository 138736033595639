/* YEARS */
export const currentYear = new Date().getFullYear();
export const lastYearDate = new Date(new Date().setFullYear(currentYear - 1));

/* MONTHS */
export const currentMonth = new Date().getMonth();
// Date with statistics. Please do not remove
// export const lastMonthDate = new Date('01/August/2019');
// export const lastMonthDate = new Date(new Date().setMonth(currentMonth <= 0 ? 11 : currentMonth - 1));
export const lastMonthDate = new Date(new Date().setMonth(currentMonth < 0 ? 11 : currentMonth - 1));

/* DAYS */
// const currentDay = new Date().getDate();
export const yesterday = new Date(new Date().getTime() - 24 * 3600 * 1000);
export const sevendays = new Date(new Date().getTime() - 168 * 3600 * 1000);
// Date with statistics. Please do not remove
// export const firstDayOfYear = new Date('01/August/2019');
export const firstDayOfYear = new Date(`01/01/${ currentYear }`);

export const getSomeDaysAgo = (startingDate: Date, howManyDaysAgo: number) => {
  const initialDate = startingDate.setHours(0, 0, 0, 0);
  const daysInMilisecs = howManyDaysAgo * 1000 * 60 * 60 * 24;
  return new Date(initialDate - daysInMilisecs);
};

export const howManyDays = (start: Date, end: Date) => {
  const difference = end.getTime() - start.getTime();

  return Math.floor(difference / 1000 / 60 / 60 / 24) + 1;
};

export const formatBackendDate = (date: Date) => date.toISOString().split('T')[0];
