import { LOCAL_STORAGE_KEY } from 'app/constants/localStorage';

const expirationTime = 24 * 60 * 60 * 1000;

const setCookie = (key: LOCAL_STORAGE_KEY, value: string, expiration?: Date) => {
  if (document?.cookie) {
    const defaultExpiration = expiration || new Date(new Date().getTime() + expirationTime);
    document.cookie = `${ key }=${ value };Path=/;Expires=${ defaultExpiration.toString() };secure;`;
  }
};

const getCookie = (key: LOCAL_STORAGE_KEY) => {
  const cookies = document?.cookie.split(';');

  if (!cookies) {
    return '';
  }

  const selectedCookie = cookies.find(c => c.includes(key)) || '';
  const values = selectedCookie.split('=');
  return values?.[1] || '';
};

const deleteCookie = (key: LOCAL_STORAGE_KEY) => {
  setCookie(key, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'));
};

export const setItem = (key: LOCAL_STORAGE_KEY, value: string) => {
  sessionStorage.setItem(key, value);
};

export const getItem = (key: LOCAL_STORAGE_KEY) => {
  return sessionStorage.getItem(key);
};

export const removeItem = (key: LOCAL_STORAGE_KEY) => {
  sessionStorage.removeItem(key);
};
