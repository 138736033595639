import { ROLE } from './roles';

export type UserAction =
  | 'ACCESS_PORTAL'
  | 'VIEW_NOTIFICATIONS'
  | 'VIEW_PICKUPS'
  | 'CREATE_PICKUPS'
  | 'VIEW_DELIVERY_SLIPS'
  | 'VIEW_BILLS'
  | 'VIEW_QUOTAS'
  | 'VIEW_STATISTICS'
  | 'VIEW_USERS'
  | 'VIEW_SETTINGS'
  | 'VIEW_HELP';

interface Permission {
  [ROLE.AVAG_ADMIN]: UserAction[];
  [ROLE.CLIENT_SUPER_USER]: UserAction[];
  [ROLE.CLIENT_OPERATOR]: UserAction[];
}

export const Permission: Permission = {
  [ROLE.AVAG_ADMIN]: ['VIEW_NOTIFICATIONS', 'VIEW_PICKUPS', 'CREATE_PICKUPS', 'VIEW_USERS', 'VIEW_SETTINGS'],
  [ROLE.CLIENT_SUPER_USER]: [
    'VIEW_NOTIFICATIONS',
    'VIEW_PICKUPS',
    'VIEW_DELIVERY_SLIPS',
    'VIEW_QUOTAS',
    'VIEW_STATISTICS',
    'VIEW_USERS',
    'VIEW_HELP'
  ],
  [ROLE.CLIENT_OPERATOR]: [
    'VIEW_NOTIFICATIONS',
    'VIEW_PICKUPS',
    'VIEW_DELIVERY_SLIPS',
    'VIEW_QUOTAS',
    'VIEW_STATISTICS',
    'VIEW_USERS',
    'VIEW_HELP'
  ]
};
