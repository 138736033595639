import React, { useRef } from 'react';
import { isWeightChart } from 'app/utils/chart';
import { ChartData, ChartMetadata } from 'containers/Statistics/types';
import { MIN_BAR_WIDTH } from 'app/constants/statistic';
import Bar from './components/Bar';
import { Container, Content, BarGroup, XAxis, YAxis, YAxisLabel, XAxisLegend } from './styles';
import { getPositiveAndNegativeGroups, checkWidth } from './utils';

const getIsHidden = ({ cost, height, weight }: ChartData, isPositive: boolean, isWeight: boolean) => {
  if (isPositive) {
    // Height based on weight
    if (isWeight) {
      return weight < 0;
    }

    // Height based on cost
    return cost < 0 && height <= 0;
  }

  // Height based on weight
  if (isWeight) {
    return weight >= 0;
  }

  // Height based on cost
  return cost >= 0 && height >= 0;
};

interface RenderBarProps {
  data: ChartData;
  index: number;
  isPositive: boolean;
  isWeight: boolean;
  metricUnits: string[];
  isBigger: number;
}

const renderBar = ({ data, index, isPositive, isWeight, metricUnits, isBigger }: RenderBarProps) => (
  <Bar
    key={ `bar-${ index }` }
    data={ data }
    isHidden={ getIsHidden(data, isPositive, isWeight) }
    dimensions={ [metricUnits.includes('CHF'), metricUnits.includes('KG')] }
    metricUnits={ metricUnits }
    isBigger={ isBigger }
  />
);

interface Props {
  disclaimer?: string;
  axisLabels?: string[];
  containerHeight?: number;
  data: ChartData[];
  meta: ChartMetadata;
  metricUnits: string[];
}

const BarChart: React.FC<Props> = ({ axisLabels = [], data = [], containerHeight, meta, metricUnits }) => {
  const content = useRef<HTMLDivElement>();

  if (data.length === 0) {
    return <Container heightPercentage={ 100 }>No data</Container>;
  }

  const isOneDimension = metricUnits.length === 1;
  const isWeight = isWeightChart(metricUnits);

  const { negativeBars, positiveBars, totalWidth } = getPositiveAndNegativeGroups(data, isWeight);

  const totalHeight = isWeight ? 100 : meta.maxCost - meta.minCost;
  const bargroups = {
    width: totalWidth,
    positive: {
      height: isWeight ? 100 : (meta.maxCost * 100) / totalHeight,
      isBigger: checkWidth(positiveBars, negativeBars)
    },
    negative: {
      height: isWeight ? 0 : (Math.abs(meta.minCost) * 100) / totalHeight,
      isBigger: checkWidth(positiveBars, negativeBars)
    }
  };
  
  const negativeBarGroup = negativeBars.map((d, i) =>
    renderBar({ data: d, index: i, isPositive: false, isWeight, metricUnits, isBigger: bargroups.negative.isBigger }));
  const positiveBarGroup = positiveBars.map((d, i) =>
    renderBar({ data: d, index: i, isPositive: true, isWeight, metricUnits, isBigger: bargroups.positive.isBigger }));

  const [weightLabel = '', costLabel = '', creditLabel = ''] = axisLabels;

  const yAxis = (costLabel || creditLabel) && (
    <YAxis>
      <YAxisLabel isTop={ true }>
        <span>{ isWeight ? weightLabel : costLabel }</span>
      </YAxisLabel>
      { /* { !isWeight && (  // function is not clear
        <YAxisLabel>
          <span>{ creditLabel }</span>
        </YAxisLabel>
      ) } */ }
      { /* showing credit only when value is negaitve */ }
      { (bargroups.negative.height > 0) && (
        <YAxisLabel>
          <span>{ creditLabel }</span>
        </YAxisLabel>
      ) }
    </YAxis>
  );

  const width = totalWidth || positiveBars.length * (MIN_BAR_WIDTH + 10);

  return (
    <Container heightPercentage={ containerHeight }>
      { yAxis }

      { !isOneDimension && <XAxisLegend top={ bargroups.positive.height }>{ weightLabel }</XAxisLegend> }
      <XAxis contentRef={ content } top={ bargroups.positive.height } totalWidth={ width } />

      <Content ref={ content }>
        <BarGroup 
          isPositive={ true } 
          heightPercentage={ bargroups.positive.height } 
          totalWidth={ bargroups.width }
          isBigger={ bargroups.negative.isBigger }
        >
          { positiveBarGroup }
        </BarGroup>
        <BarGroup 
          isPositive={ false } 
          heightPercentage={ bargroups.negative.height } 
          totalWidth={ bargroups.width }
          isBigger={ bargroups.positive.isBigger }
        >
          { negativeBarGroup }
        </BarGroup>
      </Content>
    </Container>
  );
};

export default BarChart;
