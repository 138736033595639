import { Material } from 'containers/PickupRequest/types';
import { Statistic } from '../types';

export const STATISTIC_LIST: Statistic[] = [
  {
    data: [
      {
        date: 123456789,
        id: '000',
        description: 'A description',
        expected: [200, 10],
        values: [200, 10],
        material: {
          id: '0',
          name: 'A'
        }
      },
      {
        date: 123456789,
        id: '001',
        description: 'B description',
        expected: [100, 10],
        values: [100, 10],
        material: {
          id: '1',
          name: 'B'
        }
      },
      {
        date: 123456789,
        id: '002',
        description: 'C description',
        expected: [-50, 50],
        values: [-50, 50],
        material: {
          id: '2',
          name: 'C'
        }
      },
      {
        date: 123456789,
        id: '003',
        description: 'D description',
        expected: [150, 90],
        values: [150, 90],
        material: {
          id: '3',
          name: 'D'
        }
      },
      {
        date: 123456789,
        id: '004',
        description: 'F description',
        expected: [200, 50],
        values: [200, 50],
        material: {
          id: '4',
          name: 'F'
        }
      },
      {
        date: 123456789,
        id: '005',
        description: 'G description',
        expected: [-200, 20],
        values: [-200, 20],
        material: {
          id: '5',
          name: 'G'
        }
      }
    ],
    metricUnits: ['CHF', 'Kg']
  },
  {
    data: [
      {
        date: 123456789,
        id: '000',
        description: 'A description',
        expected: [200, 40],
        values: [200, 40],
        material: {
          id: '0',
          name: 'A'
        }
      },
      {
        date: 123456789,
        id: '001',
        description: 'B description',
        expected: [100, 20],
        values: [100, 20],
        material: {
          id: '1',
          name: 'B'
        }
      },
      {
        date: 123456789,
        id: '002',
        description: 'C description',
        expected: [-50, 50],
        values: [-50, 50],
        material: {
          id: '2',
          name: 'C'
        }
      },
      {
        date: 123456789,
        id: '003',
        description: 'D description',
        expected: [150, 100],
        values: [150, 100],
        material: {
          id: '3',
          name: 'D'
        }
      },
      {
        date: 123456789,
        id: '004',
        description: 'F description',
        expected: [200, 80],
        values: [200, 80],
        material: {
          id: '4',
          name: 'F'
        }
      },
      {
        date: 123456789,
        id: '005',
        description: 'G description',
        expected: [-200, 90],
        values: [-200, 90],
        material: {
          id: '5',
          name: 'G'
        }
      }
    ],
    metricUnits: ['CHF', 'Kg']
  }
];

export const MATERIAL_OPTIONS: Material[] = [
  { id: '1', name: 'Karton', isVeva: false },
  { id: '2', name: 'Plastiko', isVeva: false },
  { id: '3', name: 'Vidriok', isVeva: false },
  { id: '4', name: 'Latak', isVeva: false },
  { id: '5', name: 'Organiko', isVeva: false }
];
