import React from 'react';

import { useTranslationsContext } from 'app/translations';
import { NavigationWrapper, PreviousPage, CurrentPage, PathSeparator } from './styles';
import { BreadcrumbLink } from '../../types';

interface Props {
  links: BreadcrumbLink[];
}

const Navigation: React.FC<Props> = ({ links }) => {
  const translations = useTranslationsContext();
  if (!links.length) {
    return null;
  }

  return (
    <NavigationWrapper>
      <ul>
        { links.map((link, index) => (
          <li key={ index }>
            { index === links.length - 1 ? (
              <CurrentPage to={ link.path }>{ translations[link.displayLabel] }</CurrentPage>
            ) : (
              <>
                <PreviousPage to={ link.path }>{ translations[link.displayLabel] }</PreviousPage>
                <PathSeparator>{ '>' }</PathSeparator>
              </>
            ) }
          </li>
        )) }
      </ul>
    </NavigationWrapper>
  );
};

export default Navigation;
