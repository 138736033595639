import PickupDetailsSkeleton from './PickupDetails';
import TableSkeleton from './Table';
import DeliverySlipDetailsSkeleton from './DeliverySlipDetails';
import BillDetailsSkeleton from './BillDetails';
import QuotasSkeleton from './Quotas';
import StatisticsSkeleton from './Statistics';

export { PickupDetailsSkeleton,
  TableSkeleton,
  DeliverySlipDetailsSkeleton,
  BillDetailsSkeleton,
  QuotasSkeleton,
  StatisticsSkeleton };
