import styled, { css } from 'styled-components';
import { TABLET } from 'base/styles/media-queries';
import { TEXT_COLOR } from 'base/styles/skin';

interface StyledProps {
  isReverse?: boolean;
}

export const textStyles = css`
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${ TEXT_COLOR };
`;

export const SplitInfo = styled.div`
  display: flex;
  flex-direction: ${ ({ isReverse }: StyledProps) => (isReverse ? 'column-reverse' : 'column') };
  align-items: inherit;
  //margin: 44px 0;
  //  top: 44px;
  //  position: relative;
    padding-top: 44px;

  & > div {
    flex: 1;
  }

  ${ TABLET } {
    display: flex;
    flex-direction: row;
    align-items: normal;
    //margin: 44px 0;
    //top: 44px;
    //position: relative;
    justify-content: space-between;

    & > div {
      flex: 1;
    }
  }
`;
SplitInfo.displayName = 'SplitInfo';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
`;
Container.displayName = 'Container';
