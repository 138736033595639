import styled from 'styled-components';
import { DESKTOP } from 'base/styles/media-queries';

export const Wrapper = styled.div``;
Wrapper.displayName = 'Wrapper';

export const Container = styled.div`
  display: block;
  margin-top: 20px;
`;
Container.displayName = 'Container';

interface ChartWrapperProps {
  isComparing: boolean;
}

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & > :first-child {
    margin-bottom: 20px;
  }

  ${ DESKTOP } {
    flex-direction: row;
    justify-content: space-between;

    & > :first-child {
      margin-bottom: 0;
    }

    > div {
      width: ${ ({ isComparing }: ChartWrapperProps) => (isComparing ? 'calc(50% - 8px)' : '100%') };
    }
  }
`;
ChartWrapper.displayName = 'ChartWrapper';

export const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  > :last-child {
    padding-top: 22px;
  }
`;
TopWrapper.displayName = 'TopWrapper';
