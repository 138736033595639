import styled from 'styled-components';
import { SKELETON_BACKGROUND } from 'base/styles/skin';

interface LineProps {
  width?: string;
  height?: string;
}

const Line = styled.div`
  background-color: ${ SKELETON_BACKGROUND };
  margin-bottom: 24px;
  width: ${ ({ width = '100%' }: LineProps) => width };
  height: ${ ({ height = '1px' }: LineProps) => height };
`;
Line.displayName = 'Bar';

export default Line;
