/* eslint-disable max-len */
import { FormikValues } from 'formik';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Buttons from 'components/Buttons';
import Modal from 'components/Modal';
import { useTranslationsContext } from 'app/translations';
import { getAddonValue } from 'containers/PickupRequest/models';
import { getContainerList, getRecentContainerList, getTypesList } from 'containers/PickupRequest/selectors';
import { Container, AddonType, AddonOption } from 'containers/PickupRequest/types';
import SelectAddon from './components/select-addons';
import SelectContainer from './components/select-container';
import SelectType from './components/select-type';
import { useButtons } from './hooks';
import { CarouselWrapper, CarouselContent, CarouselItem, AddondDisclaimer, Text } from './styles';
import { Steps } from './types';

interface Props {
  handleClose: () => void;
  handleSelect: (container: Container) => void;
}

const ContainerModal: React.FC<Props> = ({ handleClose, handleSelect }) => {
  const translations = useTranslationsContext();
  const [step, setStep] = React.useState<Steps>(Steps.type);
  const [selectedType, setSelectedType] = React.useState<string>();
  const [selectedContainer, setSelectedContainer] = React.useState<string>();
  const [values, setValues] = React.useState<FormikValues>({});
  const recents = useSelector(getRecentContainerList);
  const types = useSelector(getTypesList);
  const containers = useSelector(getContainerList(selectedType));

  const handleTransitionEnd = React.useCallback(() => {
    switch (step) {
      case Steps.type:
        setSelectedType(undefined);
        break;

      case Steps.container:
        setSelectedContainer(undefined);
        break;

      default:
    }
  }, [step]);

  const handleSendToForm = React.useCallback((container: Container) => {
    handleSelect(container);
    handleClose();
  }, []);
  const getAddonName = (id: string, addonOpstions: AddonOption[]) => {
    const selAddon = addonOpstions.find(addon => addon.id === id);
    if (selAddon && selAddon?.name) {
      return selAddon.name;
    }
    return '';
  };

  const handleSubmitOfAbroll = (container?: Container) => {
    const selContainer = container || containers.find(c => c.id === selectedContainer);
    const updatedContainer: Container = {
      ...selContainer,
      addons: (selContainer?.addons || []).map(addon => ({
        ...addon,
        addonId: values[addon.addonId],
        name: getAddonName(values[addon.addonId], addon.options),
        defaultValue: '1'
      }))
    };
    handleSendToForm(updatedContainer);
  }; 

  const handleSubmit = (container?: Container) => {
    const selContainer = container || containers.find(c => c.id === selectedContainer);
    if (selContainer?.id === '1' || selContainer?.id === '87' || selContainer?.id === '92') {
      handleSubmitOfAbroll(selContainer); 
    } else {
      const updatedContainer: Container = {
        ...selContainer,
        addons: (selContainer?.addons || []).map(addon => ({
          ...addon,
          defaultValue: getAddonValue(addon.type, addon.defaultValue, values[addon.addonId])
        }))
      };
  
      handleSendToForm(updatedContainer);
    }
    
  };

  const handleSelectContainer = (container: Container) => {
    if (container.addons.length) {
      setSelectedContainer(container.id);
      setStep(Steps.addons);
    } else {
      handleSubmit(container);
    }
  };

  const isSubmitEnabled = () => {
    const selContainer = containers.find(c => c.id === selectedContainer);
    return (selContainer?.addons || []).every(addon => addon.type !== AddonType.number || values[addon.addonId] >= 0); 
  };

  const buttons = useButtons({ handleClose, handleSubmit, setStep, step });
  const validatedButtons = useMemo(() => buttons.map(button => {
    if (button.type === 'submit' && !isSubmitEnabled()) {
      return {
        ...button,
        isDisabled: true,
      };
    }

    return button;
  }), [buttons, containers]);


  const footerSection = <Buttons options={ validatedButtons } />;

  const container = containers.find(c => c.id === selectedContainer);

  return (
    <Modal flex={ true } footer={ footerSection } onClose={ handleClose } size={ step === Steps.addons ? 'sm' : 'lg' }>
      <CarouselWrapper>
        <CarouselContent step={ step } onTransitionEnd={ handleTransitionEnd }>
          <CarouselItem>
            <SelectType
              handleSelectContainer={ handleSubmit }
              handleSelectType={ (id: string) => {
                setSelectedType(id);
                setStep(Steps.container);
              } }
              recents={ recents.map(r => {
                const containerInfo = containers.find(c => c.id === r.id);
                return {
                  ...r,
                  addons: r.addons.map(addon => {
                    const addonInfo = containerInfo?.addons.find(a => a.addonId === addon.addonId);
                    return {
                      ...addon,
                      options: addonInfo?.options || addon.options
                    };
                  })
                };
              }) }
              types={ types }
            />
          </CarouselItem>

          <CarouselItem>
            <SelectContainer
              handleSelectContainer={ handleSelectContainer }
              containers={ containers }
              type={ types.find(t => t.id === selectedType) }
            />
          </CarouselItem>

          <CarouselItem>
            { container && (
              <>
                <SelectAddon container={ container } handleSubmit={ handleSubmit } handleUpdateAddons={ setValues } />
                <AddondDisclaimer>
                  <Text isBold={ true }>{ translations.pickupRequestDetails_addonNote }:</Text>
                  <Text>{ translations.pickupRequestDetails_addonNoteDescription }.</Text>
                </AddondDisclaimer>
              </>
            ) }
          </CarouselItem>
        </CarouselContent>
      </CarouselWrapper>
    </Modal>
  );
};

export default ContainerModal;
