const pad = (num: number) => num.toString().padStart(2, '0');

export const formatDate = (date = new Date(), displayTime = false, separator = '.'): string => {
  let year: number;
  let month: number;
  let day: number;
  let hours: number;
  let minutes: number;
  let seconds: number;

  if (process.env.NODE_ENV === 'test') {
    year = date.getUTCFullYear();
    month = date.getUTCMonth() + 1;
    day = date.getUTCDate();
    hours = date.getUTCHours();
    minutes = date.getUTCMinutes();
    seconds = date.getUTCSeconds();
  } else {
    year = date.getFullYear();
    month = date.getMonth() + 1;
    day = date.getDate();
    hours = date.getHours();
    minutes = date.getMinutes();
    seconds = date.getSeconds();
  }

  const dateToDisplay = `${ pad(day) }${ separator }${ pad(month) }${ separator }${ pad(year) }`;
  const timeToDisplay = `${ pad(hours) }:${ pad(minutes) }:${ pad(seconds) }`;

  if (displayTime) {
    return `${ dateToDisplay } / ${ timeToDisplay }`;
  }

  return dateToDisplay;
};

export const formatNumber = (value = 0) => {
  const [integerPart, decimalPart] = value.toString().split('.');
  const separator = "'";

  const integer = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  const decimal = decimalPart ? `.${ decimalPart.substr(0, 3) }` : '';
  return `${ integer }${ decimal }`;
};

export const formatCurrency = (value = 0, currency = 'CHF') => `${ formatNumber(value) } ${ currency }`;
