import React, { useRef, useEffect } from 'react';
import Select from 'react-select';
import { FocusEventHandler, ValueType } from 'react-select/src/types';
import { useTranslationsContext } from 'app/translations';
import Content from '../../../Touchable';
import { FieldProps, Option } from '../../types';
import { Wrapper, ErrorMessage, Label, Description, Icon } from '../styles';
import dropdownStyles, { DropdownButton, getDropdownErrorStyles } from './styles';

const dropdownIcon = require('assets/icons/dropdown.svg');

interface Props {
  options: FieldProps;
  value?: string;
  setFieldValue: (field: string, value: any) => void;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onClick: (event: React.MouseEvent<HTMLSelectElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLSelectElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLSelectElement>) => void;
}

const errorIcon = require('assets/icons/alert.svg');

const Dropdown: React.FC<Props> = ({ setFieldValue, onBlur, onChange, onClick, onFocus, options, value }) => {
  const dropdownOptions = useRef(options.options);
  const { table_emptyMessage } = useTranslationsContext();

  useEffect(() => {
    if (Array.isArray(options.options) && options.options.length > 0 && !value && !options.placeholder) {
      setFieldValue(options.id, options.options[0].value);
    }
    if (Array.isArray(options.options)) {
      if (dropdownOptions.current?.length > 0 && options.options.length !== dropdownOptions.current?.length) {
        // removes previous selection when options has changed
        setFieldValue(options.id, undefined);
      }
      dropdownOptions.current = options.options;
    }
  }, [options.options]);

  const handleChange = (v: ValueType<Option>) => {
    let newValue;
    if (v) {
      newValue = v as Option;
    }

    onChange({
      target: {
        id: options.id,
        name: options.id,
        value: newValue ? newValue.value : ''
      }
    } as React.ChangeEvent<HTMLSelectElement>);

    setFieldValue(options.id, newValue.value);
  };

  const handleFocus: FocusEventHandler = event => {
    onFocus(event as React.FocusEvent<HTMLSelectElement>);
  };

  const DropdownIndicator = () => (
    <DropdownButton type="button">
      <Icon src={ dropdownIcon } />
    </DropdownButton>
  );

  const currentSelection = options?.options?.find(o => o.value === (value || options.model));

  return (
    <Wrapper>
      <Description>
        <Label htmlFor={ options.id }>
          <Content options={ options } />
        </Label>
      </Description>

      <Select
        id={ options.id }
        name={ options.id }
        components={ !options.isDisabled && { DropdownIndicator } }
        value={ currentSelection }
        blurInputOnSelect={ true }
        isDisabled={ options.isDisabled }
        isSearchable={ true }
        onClick={ onClick }
        onBlur={ onBlur }
        onChange={ handleChange }
        onFocus={ handleFocus }
        options={ options.options }
        placeholder={ options.placeholder }
        required={ options.isRequired }
        noOptionsMessage={ () => table_emptyMessage }
        styles={ {
          ...dropdownStyles(),
          ...(!!options.error && getDropdownErrorStyles())
        } }
      ></Select>

      { options.error && (
        <ErrorMessage>
          <Content
            options={ {
              icon: <Icon src={ errorIcon } />,
              label: Array.isArray(options.error) ? options.error[0] : options.error
            } }
          />
        </ErrorMessage>
      ) }
    </Wrapper>
  );
};

export default Dropdown;
