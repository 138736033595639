import { ButtonProps } from 'components/Touchable/types';
import { LangKeys } from 'app/translations';

import { ConstraintGroup } from 'containers/Users/types';
import { CreatorProps } from '../types';

export const createPickupRequestActionButtons = (
  translations: LangKeys,
  { isActiveUser, isAdmin, isSameUser, isSuperUser, isNewUser }: ConstraintGroup,
  buttonActions: CreatorProps['buttonActions']
): ButtonProps[] => {
  const canModify = isAdmin || isSameUser || isSuperUser;
  const canDelete = !isNewUser && canModify;

  const buttons: ButtonProps[] = [
    {
      id: 'close',
      type: 'reset',
      label: translations.userDetails_cancelUserEdit,
      onClick: buttonActions.handleClose
    }
  ];

  if (canDelete) {
    buttons.push({
      id: 'delete_user',
      type: 'button',
      label: translations.userDetails_deleteUser,
      onClick: buttonActions.handleRemove
    });
  }

  if (canModify) {
    if (isNewUser) {
      buttons.push({
        id: 'create-user',
        type: 'submit',
        buttonType: 'primary',
        label: translations.userDetails_createUser,
        onClick: buttonActions.handleSave
      });
    } else {
      buttons.push({
        id: 'update-user',
        type: 'submit',
        buttonType: 'primary',
        isAlwaysEnabled: true,
        label: isActiveUser ? translations.userDetails_saveUser : translations.userDetails_createUser,
        onClick: buttonActions.handleSave
      });
    }
  }

  return buttons;
};
