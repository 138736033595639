import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../actions';

export const useNotificationListRequest = (page: number, pageSize: number) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getNotificationList(page, pageSize));
  }, [page]);
};

export const useNotificationsReset = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.resetNotifications());
  }, []);
};
