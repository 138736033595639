import styled from 'styled-components';
import { BACKDROP_BACKGROUND, MODAL_FOREGROUND, MODAL_HEADER, MODAL_FOOTER } from 'base/styles/skin';
import { TABLET, DESKTOP } from 'base/styles/media-queries';
import getLayerPosition from 'base/styles/z-index';
import * as SIZES from 'base/styles/sizes';
import { Size } from 'app/types';

export const Wrapper = styled.div`
  position: fixed;
  z-index: ${ getLayerPosition('MODAL') };
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
`;

export const Background = styled.div`
  background-color: ${ BACKDROP_BACKGROUND };
  flex: 1;
`;
Background.displayName = 'Background';

interface ForegroundProps {
  size: Size;
}

export const Foreground = styled.div`
  background-color: ${ MODAL_FOREGROUND };
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  left: 50%;
  max-height: calc(100vh - ${ SIZES.HEADER_SIZE * 2 }px);
  max-width: 90%;
  position: absolute;
  top: ${ SIZES.HEADER_SIZE }px;
  transform: translateX(-50%);
  transition: width 0.2s;
  width: 100%;

  ${ TABLET } {
    min-width: ${ SIZES.MODAL_MIN_WIDTH }px;
    width: ${ ({ size }: ForegroundProps) => {
    switch (size) {
      case 'sm':
        return `${ SIZES.MODAL_WIDTH_SM }px`;
      case 'md':
        return `${ SIZES.MODAL_WIDTH_MD }px`;
      case 'lg':
        return `${ SIZES.MODAL_WIDTH_LG }px`;
      default:
        return 'auto';
    }
  } };
  }

  ${ DESKTOP } {
    max-width: 90vw;
  }
`;
Foreground.displayName = 'Foreground';

interface ContentProps {
  flex: boolean;
}

export const Content = styled.div`
  display: ${ ({ flex }: ContentProps) => (flex ? 'flex' : 'block') };
  height: 100%;
  width: 100%;
  overflow:auto;
`;
Content.displayName = 'Content';

export const Header = styled.header`
  width: 100%;
  padding: 0 20px;
  min-height: ${ SIZES.MODAL_HEADER_SIZE }px;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  //font-family: Roboto;
  font-family: 'TT Norms';
  color: ${ MODAL_HEADER };
`;
Header.displayName = 'Header';

export const Footer = styled.footer`
  width: 100%;
  min-height: ${ SIZES.MODAL_FOOTER_SIZE }px;
  background-color: ${ MODAL_FOOTER };
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 0 5px 5px;
`;
Footer.displayName = 'Footer';

export const Body = styled.div`
  padding: 20px;
  padding-top: 0;
  white-space: pre-line;
`;
