import React, { PropsWithChildren } from 'react';

import { Container, Content, Aside } from './styles';

interface Props {
  aside?: React.ReactNode;
}

const AsideLayout: React.FC<PropsWithChildren<Props>> = ({ children, aside }) => (
  <Container>
    <Content>{ children }</Content>
    { aside && <Aside>{ aside }</Aside> }
  </Container>
);

export default AsideLayout;
