import { ButtonProps } from 'components/Touchable/types';
import { useTranslationsContext } from 'app/translations';

export const useFormButtons = () => {
  const translations = useTranslationsContext();
  const buttons: ButtonProps[] = [
    {
      id: 'cancel',
      type: 'button',
      label: translations.confirmModal_cancel
    },
    {
      id: 'submit',
      type: 'submit',
      buttonType: 'primary',
      label: translations.confirmModal_confirm
    }
  ];
  return buttons;
};
