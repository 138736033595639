import { Statistic, DataFilterValues, StatisticData } from 'containers/Statistics/types';

export const getFilteredMetricUnits = (metricUnits: Statistic['metricUnits'], filters: DataFilterValues): string[] => {
  const [costFilter, weightFilter] = filters?.dimensions;
  const dimensionUnits: string[] = [];
  if (costFilter) {
    dimensionUnits.push(metricUnits[0]);
  }

  if (weightFilter) {
    dimensionUnits.push(metricUnits[1]);
  }

  return dimensionUnits;
};

export const filterListByMaterial = (data: StatisticData[], materialId: string): StatisticData[] =>
  data.filter(record => record.material.id === materialId);

export const isWeightChart = (metricUnits: string | string[]) => {
  if (typeof metricUnits === 'number') {
    return metricUnits === 'KG';
  }

  return metricUnits.length === 1 && metricUnits[0] === 'KG';
};

export const filterNoWeightItems = (data: StatisticData[]): StatisticData[] =>
  data.filter(record => !!record.expected[1]);
