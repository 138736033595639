import React from 'react';
import { useTranslationsContext } from 'app/translations';
import Buttons from 'components/Buttons';

interface Props {
  onClick: () => Function;
}

const PrintInvoiceButton: React.FC<Props> = ({ onClick }) => {
  const { billDetails_printInvoice } = useTranslationsContext();

  return (
    <Buttons
      options={
        [
          {
            id: 'printInvoice',
            label: billDetails_printInvoice,
            onClick: onClick(),
            type: 'button'
          }
        ]
      }
    />
  );
};

export default PrintInvoiceButton;
