import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
Container.displayName = 'Container';

export const EmptyCollection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;
EmptyCollection.displayName = 'EmptyCollection';
