import styled, { css } from 'styled-components';
import { DESKTOP } from 'base/styles/media-queries';

import { WrapperTitle, CardContent } from '../../../styles';

const Wrappers = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const CustomWrapperTitle = styled.div`
  ${ WrapperTitle }
`;
CustomWrapperTitle.displayName = 'WrapperTitle';

export const CustomCardContent = styled(CardContent)`
  padding: 0 20px;
`;
CustomCardContent.displayName = 'CustomCardContent';

export const FormWrapper = styled.div`
  ${ DESKTOP } {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
FormWrapper.displayName = 'FormWrapper';

export const WrapperLeft = styled.div`
  ${ DESKTOP } {
    ${ Wrappers }
    padding-right: 4px;
  }
`;
WrapperLeft.displayName = 'WrapperLeft';

export const WrapperRight = styled.div`
  ${ DESKTOP } {
    ${ Wrappers }
    padding-left: 4px;
  }
`;
WrapperRight.displayName = 'WrapperRight';