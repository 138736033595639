import React from 'react';
import SmartForm from 'components/SmartForm';
import { StatusResponse, SeverityType } from 'components/StatusMessage/types';
import { FieldProps } from 'components/SmartForm/types';

import { useFormData } from './data';

interface Props {
  isLoading?: boolean;
  statusResponse?: StatusResponse;
  onSubmit: Function;
}

const FormBody: React.FC<Props> = ({ isLoading, statusResponse, onSubmit }) => {
  const { buttons, fields, validationSchema } = useFormData();

  const handleSubmit = (submitedFields: FieldProps[]) => {
    const [password, confirmPassword] = submitedFields.map(field => field.model || '');
    onSubmit(password, confirmPassword);
  };

  return (
    <SmartForm
      form={ 'reset-password' }
      buttons={ buttons }
      fields={ fields }
      validationSchema={ validationSchema }
      statusResponse={ statusResponse }
      handleSubmit={ handleSubmit }
      isFetching={ isLoading }
    >
      { ({ controlledButtons, controlledFields, Field, Buttons }) => (
        <>
          { statusResponse?.severity !== SeverityType.SUCCESS && (
            <>
              { controlledFields.map(field => (
                <Field key={ field.id } options={ field } />
              )) }
              <Buttons options={ controlledButtons } />
            </>
          ) }
        </>
      ) }
    </SmartForm>
  );
};

export default FormBody;
