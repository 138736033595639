import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { isMockingApi } from 'base';
import { useIsLoading } from 'app/hooks';
import { FORGOT_PASSWORD } from 'app/routes';
import * as validations from 'app/constants/validations';
import { LangKeys, useTranslationsContext } from 'app/translations';
import Buttons from 'components/Buttons';
import SmartForm from 'components/SmartForm';
import { EMAIL, PASSWORD } from 'components/SmartForm/models';
import { ButtonProps } from 'components/Touchable/types';
import { FieldProps } from 'components/SmartForm/types';
import { SeverityType, StatusResponse } from 'components/StatusMessage/types';
import SectionTitle from 'components/SectionTitle';
import YoutubeEmbed from 'components/YouTube';
import SimpleLayout from '../Core/components/SimpleLayout';
import LoginFooter from './components/Footer';
import { loginRequest } from './actions';
import { getLoginData } from './selectors';
import { LoginActions, LineSeparator, ForgotPwd } from './styles';
import { LoginRequest } from './types';

const createValidationSchema = (translations: LangKeys): Yup.ObjectSchema =>
  Yup.object().shape({
    user_email: validations.email(translations),
    password: validations.simplePassword(translations)
  });

const Login: React.FC = () => {
  const translations = useTranslationsContext();
  const [loginError, setLoginError] = React.useState<string[]>([]);
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoginData);
  const isLoading = useIsLoading(['login']);
  const validationSchema = createValidationSchema(translations);

  const fields: FieldProps[] = [
    {
      id: 'user_email',
      isRequired: true,
      label: translations.login_emailLabel,
      placeholder: translations.login_emailPlaceholder,
      type: EMAIL
    },
    {
      id: 'password',
      isRequired: true,
      label: translations.login_passwdLabel,
      placeholder: translations.login_passwdPlaceholder,
      type: PASSWORD
    }
  ];

  const buttons: ButtonProps[] = [
    {
      id: 'loginSubmit',
      type: 'submit',
      buttonType: 'primary',
      isAlwaysEnabled: true,
      label: translations.login_submitLabel
    }
  ];

  const handleLogin = async (controlledFileds: FieldProps[]) => {
    const email = controlledFileds.find(field => field.id === 'user_email');
    const password = controlledFileds.find(field => field.id === 'password');

    const user: LoginRequest = {
      user_email: email.model || '',
      password: password.model || ''
    };

    const { type } = await dispatch(loginRequest(user));

    if (!isMockingApi() && !loggedUser && type.includes('ERROR')) {
      setLoginError([translations.login_errorTitle, translations.login_errorDescription]);
    }
  };

  const statusResponse: StatusResponse = {
    severity: SeverityType.ERROR,
    title: loginError[0],
    description: loginError[1]
  };

  return (
    <SimpleLayout>
      <SectionTitle>{ translations.login_title }</SectionTitle>
      <SmartForm
        form={ 'login' }
        buttons={ buttons }
        fields={ fields }
        handleSubmit={ handleLogin }
        statusResponse={ loginError.length > 0 ? statusResponse : undefined }
        isFetching={ isLoading }
        validationSchema={ validationSchema }
        validateOnChange={ true }
      >
        { ({ controlledButtons, controlledFields, Field }) => (
          <>
            { controlledFields.map(field => (
              <Field key={ field.id } options={ field } />
            )) }
            <LoginActions>
              <ForgotPwd to={ FORGOT_PASSWORD.path }>{ translations.login_forgotPasswdLabel }</ForgotPwd>
              <Buttons options={ controlledButtons } />
            </LoginActions>
          </>
        ) }
      </SmartForm>
      <LineSeparator />
      <SectionTitle>{ translations.requestAccess_title }</SectionTitle>
      <LoginFooter />
      <LineSeparator />
      <SectionTitle>{ translations.Kundenportal_video }</SectionTitle>
      <YoutubeEmbed embedId="q5r1pPGUjbE"/>
    </SimpleLayout>
  );
};

export default Login;
