import React from 'react';
import { useTranslationsContext } from 'app/translations';
import Image from 'components/Image';
import MosaicList from 'components/MosaicList';
// import { renderAddonValue } from 'containers/PickupRequest/tools';
import { Container, ContainerType } from 'containers/PickupRequest/types';
import { ContainerWrapper,
  SectionTitle,
  ItemWrapper,
  ImageWrapper,
  InfoWrapper,
  ItemName } from '../../styles';
// test
const customPlaceholder = require('assets/icons/placeholders/empty-box.png');
const placeholderContainerIcon = require('assets/icons/placeholders/container.svg');

interface Props {
  handleSelectContainer: (container: Container) => void;
  handleSelectType: (id: string) => void;
  recents: Container[];
  types: ContainerType[];
}

const SelectType: React.FC<Props> = ({ handleSelectType, types }) => {
  const translations = useTranslationsContext();

  return (
    <ContainerWrapper>
      <SectionTitle>{ translations.containers_selectType }</SectionTitle>
      <MosaicList>
        { ({ MosaicItem }) =>
          types.map(({ id, image, name }) => (
            <MosaicItem key={ `container-${ id }` } flex={ true } onClick={ () => handleSelectType(id) }>
              <ItemWrapper>
                <ImageWrapper>
                  <Image
                    key={ `container-${ id }-img` }
                    alt={ name }
                    sourceImg={ id === '3' ? customPlaceholder : image }
                    loading="eager"
                    fallbackImg={ placeholderContainerIcon }
                  />
                </ImageWrapper>
                <InfoWrapper>
                  <ItemName>{ name }</ItemName>
                </InfoWrapper>
              </ItemWrapper>
            </MosaicItem>
          ))
        }
      </MosaicList>
    </ContainerWrapper>
  );
};

export default SelectType;
