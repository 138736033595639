import styled from 'styled-components';
import { STATUS_BACKGROUND, STATUS_TEXT } from 'base/styles/skin';

interface StyledProps {
  isBold?: boolean;
}

export const Container = styled.div`
  padding: 12px;
  //box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: ${ STATUS_BACKGROUND };
  border-radius: 4px;
  display: flex;
  align-items: center;
`;
Container.displayName = 'Container';

export const TextWrapper = styled.div`
  display: inline;
  padding-left: 12px;
  color: ${ STATUS_TEXT };
  font-size: 14px;
`;
TextWrapper.displayName = 'TextWrapper';

export const Text = styled.span`
  font-weight: ${ ({ isBold }: StyledProps) => (isBold ? 'bold' : 'normal') };
`;
Text.displayName = 'Text';
