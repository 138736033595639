import { Action } from '../../../types';

export enum ActionTypes {
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
}

type ResetPasswordRequest = Action<ActionTypes.RESET_PASSWORD_REQUEST>;
type ResetPasswordSuccess = Action<ActionTypes.RESET_PASSWORD_SUCCESS>;
type ResetPasswordError = Action<ActionTypes.RESET_PASSWORD_ERROR>;

export type ResetPasswordActionType = ResetPasswordRequest | ResetPasswordSuccess | ResetPasswordError;
