import React from 'react';
import styled from 'styled-components';
import { BACKGROUND } from 'base/styles/skin';
import { Bar as ProgressBar } from 'components/ProgressBar/styles';
import Skeleton, { Bar, Line } from './pieces';

const QuotaContainer = styled.div`
  padding: 32px 0;
`;

const StyledBar = styled(Bar)`
  margin-bottom: 24px;
  width: 200px;
`;

const StyledLine = styled(Line)`
  margin: 0;
`;

const Quotas: React.FC = () => (
  <Skeleton background={ BACKGROUND }>
    <QuotaContainer>
      <StyledBar />
      <ProgressBar large={ true } />
    </QuotaContainer>

    <StyledLine />

    <QuotaContainer>
      <StyledBar />
      <ProgressBar large={ true } />
    </QuotaContainer>
  </Skeleton>
);

export default Quotas;
