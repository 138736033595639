import { useTranslationsContext } from 'app/translations';
import { FieldProps } from 'components/SmartForm/types';
import { TEXT, CHECKBOX, DROPDOWN } from 'components/SmartForm/models';
import { createOptionsFromEnum } from 'app/utils/fields';
import { DateRuleDropdown } from 'app/constants/pickupRequest';
import { FieldName } from '../../../types';


export const useFields = (): FieldProps[] => {
  const translations = useTranslationsContext();
  const fields: FieldProps[] = [];

  fields.push({
    id: FieldName.saveTemplate,
    label: translations.template_modalBox_checkbox,
    type: CHECKBOX,
    isChecked: false,
  });
  fields.push({
    id: FieldName.templateName,
    label: translations.template_modalBox_template_Name,
    type: TEXT, 
  });
  fields.push({
    id: FieldName.templateDateRule,
    label: translations.template_modalBox_date_rule,
    type: DROPDOWN, 
    options: createOptionsFromEnum<number>(DateRuleDropdown, translations)
  });

  return fields;
};
