import { getOffsetPagination } from 'app/utils/pagination';
import * as api from '../api';
import { ActionTypes, TemplatesActionType } from '../actionTypes';
import { TemplateRequest, PickupRequestFromTemplateHome } from '../types';
// import { TemplateRequest } from '../types';

export const getTemplateList = (page: number, pageSize: number): TemplatesActionType => ({
  type: ActionTypes.GET_TEMPLATES_LIST_REQUEST,
  request: api.getTemplatesList(getOffsetPagination(page, pageSize), pageSize),
  requestName: 'get-templates-request'
});

export const resetTemplates = (): TemplatesActionType => ({
  type: ActionTypes.RESET_TEMPLATES
});

//  get TemplateDetail from server
export const getTemplateDetailRequest = (id: string): TemplatesActionType => ({
  type: ActionTypes.GET_TEMPLATE_DETAILS_REQUEST,
  request: api.getTemplateDetails(id),
  requestName: 'get-templates-request'
});

// create template 
// export const createTemplateRequest = (templateRequest: TemplateRequest): TemplatesActionType => ({
//   type: ActionTypes.CREATE_TEMPLATE_REQUEST,
//   request: api.createTemplateRequest(templateRequest),
//   requestName: 'update-template-request'
// });

// update template  
export const updateTemplateRequest = (
  pickupRequest: TemplateRequest 
): TemplatesActionType => {
  return ({
    type: ActionTypes.UPDATE_TEMPLATE_DETAILS_REQUEST,
    request: api.updateTemplateRequest(pickupRequest),
    requestName: 'update-template-request'
  });
};

// export const processTemplateRequest = (templateRequest: TemplateRequest) => ({
//   type: ActionTypes.UPDATE_TEMPLATE_DETAILS_REQUEST,
//   request: api.processTemplateRequest(templateRequest),
//   requestName: 'update-template-request',
//   payload: templateRequest
// });


export const processTemplateRequest = (
  templateRequest: TemplateRequest 
): TemplatesActionType => {
  return ({
    type: ActionTypes.PROCESS_TEMPLATE_REQUEST,
    request: api.processTemplateRequest(templateRequest),
    requestName: 'process-template-request'
  });
};


//  delete template 
export const deleteTemplateRequest = (templateId: string): TemplatesActionType => {
  return ({
    type: ActionTypes.DELETE_TEMPLATE_REQUEST,
    request: api.deleteTemplateRequest(templateId),
    requestName: 'delete-template-request'
  });
};

export const placeOrderFromHomeTemplateRequest = (
  pickupFromHomeTemplate: PickupRequestFromTemplateHome
): TemplatesActionType => {
  return ({
    type: ActionTypes.PLACEORDER_FROM_HOME_TEMPLATE_REQUEST,
    request: api.pickupRequestFromHomeTemplate(pickupFromHomeTemplate),
    requestName: 'placeorder-Hometemplate-request'
  });
};