/* eslint-disable max-lines */
import { safeDate } from 'app/utils/safeTypes';
import { BillListFromServer, BillFromServer } from '../types/fromServer';
import { Bill, BillDocumentType, BillStatus } from '../types';

// Mocked values until backend is ready

export const BILL_DETAIL_FROM_SERVER: BillFromServer = {
  DocType: 'Invoice',
  DocNo: '0160912',
  SellToCustomerNo: '1002619',
  VATRegistrationNo: 'VHE-114.764.854 MWST',
  BillToCustomerNo: '1002619',
  BillToName: 'Nestlé Suisse SA',
  BillToName2: 'Finance Service Center',
  BillToAddress: null,
  BillToAddress2: 'Case Postale 352',
  BillToPostCode: '1800',
  BillToCity: 'Vevey',
  DmsLinkTableID: '2056122',
  SalesPerson: [
    {
      SalesPersName: 'Bertschy Anna',
      SalesPersPhoneNo: '+41 33 226 57 16',
      SalesPersEMail: 'debitoren@avag.ch'
    }
  ],
  DocumentLines: [
    {
      Type: '2',
      No: '21-214-003',
      PostingDate: '2019-05-31',
      OrderNo: 'VA02214714',
      Description: 'Büchsen / Aludosen',
      Description2: null,
      Quantity: '3000',
      UnitOfMeasureCode: 'KG',
      UnitPrice: '-0.20',
      LineAmount: '-600.00',
      VATPercent: '7.7'
    },
    {
      Type: '2',
      No: '21-219-001',
      PostingDate: '2019-05-31',
      OrderNo: 'VA02214714',
      Description: 'Holz (ak 170297)',
      Description2: null,
      Quantity: '630',
      UnitOfMeasureCode: 'KG',
      UnitPrice: '0.13',
      LineAmount: '81.90',
      VATPercent: '7.7'
    }
  ],
  totalAmount: 9999
};

export const BILLS_LIST_FROM_SERVER: BillListFromServer = {
  BillData: [
    {
      ...BILL_DETAIL_FROM_SERVER,
      DocDate: '2019-06-05',
      DueDate: safeDate(['2019-12-08']).getTime(),
      OriginalAmount: -558,
      CurrencyCode: 'CHF',
      Status: BillStatus.NEW,
      DocNo: '0160912',
      DocType: BillDocumentType.BILL
    },
    {
      ...BILL_DETAIL_FROM_SERVER,
      DocDate: '2019-01-07',
      DueDate: safeDate(['2019-12-08']).getTime(),
      DocType: BillDocumentType.CREDIT,
      OriginalAmount: 3217.2,
      CurrencyCode: 'CHF',
      Status: BillStatus.NEW,
      DocNo: '0160913'
    },
    {
      ...BILL_DETAIL_FROM_SERVER,
      DocDate: '2018-12-04',
      DueDate: safeDate(['2019-12-08']).getTime(),
      DocType: BillDocumentType.BILL,
      OriginalAmount: 157.05,
      CurrencyCode: 'CHF',
      Status: BillStatus.PAID,
      DocNo: '0160914'
    },
    {
      ...BILL_DETAIL_FROM_SERVER,
      DocDate: '2018-11-07',
      DueDate: safeDate(['2019-12-08']).getTime(),
      DocType: BillDocumentType.CREDIT,
      OriginalAmount: 15,
      CurrencyCode: 'CHF',
      Status: BillStatus.NEW,
      DocNo: '0160915'
    }
  ]
};

export const BILLS_LIST: Bill[] = [
  {
    id: '0160912',
    date: safeDate('2019-06-05T00:00:00.000Z').getTime(),
    dueDate: safeDate(['2019-12-08']).getTime(),
    status: BillStatus.NEW,
    documentType: BillDocumentType.BILL,
    billingAddress: ['1002619', 'Nestlé Suisse SA', 'Finance Service Center', 'Case Postale 352', '1800', 'Vevey'],
    customerId: '1002619',
    VATnumber: 'VHE-114.764.854 MWST',
    contactInfo: { fullName: 'Bertschy Anna', phone: '+41 33 226 57 16', email: 'debitoren@avag.ch' },
    dmsLinkID: '2056122',
    companyResponse: {
      phone: '',
      email: '',
      web: ''
    },
    items: [
      {
        date: safeDate('2019-05-31T00:00:00.000Z').getTime(),
        deliveryNoteId: 'VA02214714',
        docNoOccurrence: '21-214-003',
        versionNo: '2',
        material: 'Büchsen / Aludosen',
        quantity: 3000,
        unit: 'KG',
        price: -0.2,
        amount: -600,
        VAT: 7.7
      },
      {
        date: safeDate('2019-05-31T00:00:00.000Z').getTime(),
        deliveryNoteId: 'VA02214714',
        docNoOccurrence: '21-219-001',
        versionNo: '2',
        material: 'Holz (ak 170297)',
        quantity: 630,
        unit: 'KG',
        price: 0.13,
        amount: 81.9,
        VAT: 7.7
      }
    ],
    originalAmount: '-558 CHF',
    totalAmount: 9999
  },
  {
    id: '0160913',
    date: safeDate('2019-01-07T00:00:00.000Z').getTime(),
    dueDate: safeDate(['2019-12-08']).getTime(),
    status: BillStatus.NEW,
    documentType: BillDocumentType.CREDIT,
    billingAddress: ['1002619', 'Nestlé Suisse SA', 'Finance Service Center', 'Case Postale 352', '1800', 'Vevey'],
    customerId: '1002619',
    VATnumber: 'VHE-114.764.854 MWST',
    contactInfo: { fullName: 'Bertschy Anna', phone: '+41 33 226 57 16', email: 'debitoren@avag.ch' },
    dmsLinkID: '2056122',
    companyResponse: {
      phone: '',
      email: '',
      web: ''
    },
    items: [
      {
        date: safeDate('2019-05-31T00:00:00.000Z').getTime(),
        deliveryNoteId: 'VA02214714',
        docNoOccurrence: '21-214-003',
        versionNo: '2',
        material: 'Büchsen / Aludosen',
        quantity: 3000,
        unit: 'KG',
        price: -0.2,
        amount: -600,
        VAT: 7.7
      },
      {
        date: safeDate('2019-05-31T00:00:00.000Z').getTime(),
        deliveryNoteId: 'VA02214714',
        docNoOccurrence: '21-219-001',
        versionNo: '2',
        material: 'Holz (ak 170297)',
        quantity: 630,
        unit: 'KG',
        price: 0.13,
        amount: 81.9,
        VAT: 7.7
      }
    ],
    originalAmount: "3'217.2 CHF",
    totalAmount: 9999
  },
  {
    id: '0160914',
    date: safeDate('2018-12-04T00:00:00.000Z').getTime(),
    dueDate: safeDate(['2019-12-08']).getTime(),
    status: BillStatus.PAID,
    documentType: BillDocumentType.BILL,
    billingAddress: ['1002619', 'Nestlé Suisse SA', 'Finance Service Center', 'Case Postale 352', '1800', 'Vevey'],
    customerId: '1002619',
    VATnumber: 'VHE-114.764.854 MWST',
    contactInfo: { fullName: 'Bertschy Anna', phone: '+41 33 226 57 16', email: 'debitoren@avag.ch' },
    dmsLinkID: '2056122',
    companyResponse: {
      phone: '',
      email: '',
      web: ''
    },
    items: [
      {
        date: safeDate('2019-05-31T00:00:00.000Z').getTime(),
        deliveryNoteId: 'VA02214714',
        docNoOccurrence: '21-214-003',
        versionNo: '2',
        material: 'Büchsen / Aludosen',
        quantity: 3000,
        unit: 'KG',
        price: -0.2,
        amount: -600,
        VAT: 7.7
      },
      {
        date: safeDate('2019-05-31T00:00:00.000Z').getTime(),
        deliveryNoteId: 'VA02214714',
        docNoOccurrence: '21-219-001',
        versionNo: '2',
        material: 'Holz (ak 170297)',
        quantity: 630,
        unit: 'KG',
        price: 0.13,
        amount: 81.9,
        VAT: 7.7
      }
    ],
    originalAmount: '157.05 CHF',
    totalAmount: 9999
  },
  {
    id: '0160915',
    date: safeDate('2018-11-07T00:00:00.000Z').getTime(),
    dueDate: safeDate(['2019-12-08']).getTime(),
    status: BillStatus.NEW,
    documentType: BillDocumentType.CREDIT,
    billingAddress: ['1002619', 'Nestlé Suisse SA', 'Finance Service Center', 'Case Postale 352', '1800', 'Vevey'],
    customerId: '1002619',
    VATnumber: 'VHE-114.764.854 MWST',
    contactInfo: { fullName: 'Bertschy Anna', phone: '+41 33 226 57 16', email: 'debitoren@avag.ch' },
    dmsLinkID: '2056122',
    companyResponse: {
      phone: '',
      email: '',
      web: ''
    },
    items: [
      {
        date: safeDate('2019-05-31T00:00:00.000Z').getTime(),
        deliveryNoteId: 'VA02214714',
        docNoOccurrence: '21-214-003',
        versionNo: '2',
        material: 'Büchsen / Aludosen',
        quantity: 3000,
        unit: 'KG',
        price: -0.2,
        amount: -600,
        VAT: 7.7
      },
      {
        date: safeDate('2019-05-31T00:00:00.000Z').getTime(),
        deliveryNoteId: 'VA02214714',
        docNoOccurrence: '21-219-001',
        versionNo: '2',
        material: 'Holz (ak 170297)',
        quantity: 630,
        unit: 'KG',
        price: 0.13,
        amount: 81.9,
        VAT: 7.7
      }
    ],
    originalAmount: '15 CHF',
    totalAmount: 9999
  }
];
