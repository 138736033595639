import React, { useContext } from 'react';
import { getTranslations } from './utils';
import { LangKeys } from './lang/types';

const TranslationsContext = React.createContext({} as LangKeys);

const { Consumer, Provider } = TranslationsContext;

const useTranslationsContext = () => useContext(TranslationsContext);

export { Consumer, Provider, LangKeys, getTranslations, useTranslationsContext };
export default TranslationsContext;
