import { TimePeriod, PickupRequestStatus } from 'app/constants/pickupRequest';
import { COMPANY_SIMPLE_LIST, COMPANY_LIST } from 'containers/Companies/mocks';
import { MaterialDetails } from 'app/containers/PickupRequest/types';
import { MATERIAL_LIST } from 'app/containers/PickupRequest/mocks';
import { DEFAULT_FILE_URL } from 'app/constants/attachedFile';
import { TemplateRequest } from '../types';
// import { TemplatesListFromServer } from '../types/fromServer';
const MATERIAL: MaterialDetails = {
  id: '123456789',
  containerId: '123',
  date: 1580893149831,
  deliverContainers: 10,
  destination: '',
  materialId: MATERIAL_LIST[0].id,
  pickupContainers: 10,
  timeSlot: TimePeriod.timePeriod_afternoon,
  addons: []
};
export const TEMPLATES_LIST: TemplateRequest[] = [
  {
    templateId: '1234',
    templateName: 'template1',
    createdDate: 1580893149831,
    templateDateRule: 0,
    lastUsed: 1580893149831,
    id: '1',
    company: COMPANY_SIMPLE_LIST[0],
    owner: COMPANY_LIST[0].customers[0],
    logistic: {
      location: 'Hardstrasse 201, 8005...',
      preferredDate: 1580893149831,
      hasToCallClient: false,
      hasToSaveLocation: false
    },
    materials: [{ ...MATERIAL, isVeva: true }],
    commentary: {
      additionalComment: 'Example of addional comment.',
      attachedFiles: [],
      bookingReference: 'Example of bookin reference.',
      hasToSaveBookinReference: true
    },
    driverInformation: {
      additionalComment: 'Example of additional comment for my dear driverInformation',
      attachedFiles: []
    },
    status: PickupRequestStatus.PROCESSED,
    PDFUrl: DEFAULT_FILE_URL
  }

];