import React from 'react';
import { ButtonProps } from 'components/Touchable/types';
import { useTranslationsContext } from 'app/translations';
import Buttons from 'components/Buttons';
import { Size } from 'app/types';

import Modal from '../..';
import { Body } from '../../styles';

interface Props {
  children?: React.ReactNode;
  header?: string;
  size?: Size;
  dismissLabel?: string;
  onDismiss?: () => void;
  confirmLabel?: string;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<Props> = ({ children,
  header,
  size = 'sm',
  dismissLabel,
  onDismiss,
  confirmLabel,
  onConfirm }) => {
  const translations = useTranslationsContext();

  const buttons: ButtonProps[] = [];

  if (onDismiss) {
    buttons.push({
      id: 'confirmModal_cancel',
      type: 'button',
      label: dismissLabel || translations.confirmModal_cancel,
      onClick: onDismiss
    });
  }

  buttons.push({
    id: 'confirmModal_confirm',
    type: 'submit',
    buttonType: 'primary',
    label: confirmLabel || translations.confirmModal_confirm,
    onClick: onConfirm
  });

  const footer = <Buttons options={ buttons } />;

  return (
    <Modal header={ header } footer={ footer } onClose={ onDismiss || onConfirm } size={ size }>
      <Body>{ children }</Body>
    </Modal>
  );
};

export default ConfirmationModal;
