import styled from 'styled-components';
import { TEXT_COLOR, FIELD_LABEL } from 'base/styles/skin';
import { DESKTOP, TABLET } from 'base/styles/media-queries';

interface Props {
  isOverview: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${ ({ isOverview }: Props) => (isOverview ? '24px' : '0') };

  ${ TABLET } {
    margin-left: ${ ({ isOverview }: Props) => (isOverview ? '16px' : '0') };
    margin-top: 0;
    width: ${ ({ isOverview }: Props) => (isOverview ? '50%' : 'auto') };
  }
`;
Container.displayName = 'Container';

export const EmptyCollection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;
EmptyCollection.displayName = 'EmptyCollection';

interface BodyProps {
  minHeight: number;
}

export const ChartWrapper = styled.div`
  margin-top: 20px;

  ${ DESKTOP } {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 0;
    overflow: hidden;
  }
`;
ChartWrapper.displayName = 'ChartWrapper';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  position: relative;

  &,
  & > .ChartWrapper {
    min-height: ${ ({ minHeight }: BodyProps) => minHeight }px;
  }
`;
Body.displayName = 'Body';

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
RightWrapper.displayName = 'RightWrapper';

export const SubLegend = styled.div`
  width: 100%;
  
  ${ DESKTOP } {
    padding-left: 20px;
  }
`;
SubLegend.displayName = 'SubLegend';

export const Title = styled.div`
  font-size: 10px;
  color: ${ FIELD_LABEL };
`;
Title.displayName = 'Title';

export const Details = styled.div`
  font-size: 14px;
  color: ${ TEXT_COLOR };
`;
Details.displayName = 'Details';
