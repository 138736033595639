import { safeString, safeNumber } from 'app/utils/safeTypes';
import { createUserPermissionsFromServer, createCompanyPermissionsFromServer } from 'containers/Users/models';
import { SimpleUserFromServer } from 'containers/Users/types';
import { LoggedUser } from '../types';

export const createUserFromServer = ({ user_id,
  first_name,
  user_email,
  last_name,
  client_id,
  client_name,
  role_id,
  permissions,
  client_permissions }: SimpleUserFromServer): LoggedUser | undefined => {
  if (
    user_id === undefined
    || first_name === undefined
    || user_email === undefined
    || client_id === undefined
    || role_id === undefined
  ) {
    console.error('Error creating <LoggedUser> model');
    return undefined;
  }

  return {
    user_id: safeString(user_id),
    first_name: safeString(first_name),
    last_name: safeString(last_name),
    user_email: safeString(user_email),
    client_id: safeString(client_id),
    client_name: safeString(client_name),
    role: safeNumber(role_id),
    permissions: createUserPermissionsFromServer(permissions || {}),
    companyPermissions: createCompanyPermissionsFromServer(client_permissions || {})
  };
};
