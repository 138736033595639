import styled from 'styled-components';

export const Container = styled.div``;
Container.displayName = 'Container';

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;
Icon.displayName = 'Icon';
