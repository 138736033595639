import styled from 'styled-components';
import DefaultButtons from 'app/components/Buttons';
import { TABLE_HEADER_BACKGROUND } from 'base/styles/skin';

export const ModalBody = styled.div`
  padding: 20px;

  & > * {
    margin-top: 10px;

    &:fist-child {
      margin-top: 0;
    }
  }
`;
ModalBody.displayName = 'ModalBody';

export const BrowserLink = styled.a`
  display: inline-block;
  padding: 10px 0;

  &,
  &:active,
  &:focus,
  &:hover,
  &:link,
  &:visited {
    color: ${ TABLE_HEADER_BACKGROUND };
  }
`;
BrowserLink.displayName = 'BrowserLink';

export const ModalFooter = styled(DefaultButtons)`
  width: 100%;
`;
ModalFooter.displayName = 'ModalFooter';

export const Text = styled.p`
  margin-bottom: 10px;
  white-space: pre-line;
`;
Text.displayName = 'Text';
