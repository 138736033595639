import { createPaginationFromServer } from 'app/models';
import { LangKeys } from 'app/translations';
import { formatDate } from 'app/utils/formatters';
import { safeString, safeNumber } from 'app/utils/safeTypes';
import { FieldProps } from 'components/SmartForm/types';
import { DeliverySlipsState, DeliverySlip, DeliverySlipRow, MaterialInfo, MaterialInfoRow, ExportData } from '../types';

export enum DeliverySlipStatus {
  NEW,
  APPROVED
}

export const createRowFromDeliverySlips = (deliverySlip: DeliverySlip, { attachment }: LangKeys): DeliverySlipRow => ({
  date: deliverySlip.date,
  id: deliverySlip.id,
  docNoOccurrence: deliverySlip.docNoOccurrence,
  versionNo: deliverySlip.versionNo,
  bookingReference: deliverySlip.bookingReference,
  attachments: !deliverySlip.attachments.length
    ? ''
    : `${ deliverySlip.attachments.length } ${ attachment }${ deliverySlip.attachments.length >= 2 ? 's' : '' }`,
  status: deliverySlip.status,
  navigate: deliverySlip.id
});

export const createRowFromMaterialInfo = (material: MaterialInfo): MaterialInfoRow => ({
  itemReference: material.itemReference,
  material: material.material,
  unit: material.unit,
  gross: material.gross,
  tare: material.tare,
  net: material.net,
  retailPrice: material.retailPrice,
  amount: safeNumber(material.amount),
  tax: safeNumber(material.tax)
});

// export const createExportDataInfo = (data: FieldProps[]): ExportData => ({
//   startDate: formatDate(new Date(safeString(data.find(v => v.id === 'startDate').model)))
//     .split('.')
//     .reverse()
//     .join('-'),
//   endDate: formatDate(new Date(safeString(data.find(v => v.id === 'endDate').model)))
//     .split('.')
//     .reverse()
//     .join('-'),
//   format: safeString(data.find(v => v.id === 'format').model)
// });

export const deliverySlipInitialState: DeliverySlipsState = {
  collection: [],
  pagination: createPaginationFromServer({})
};
