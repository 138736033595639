import { Pagination } from 'app/types';
import { DeliverySlipStatus } from 'containers/DeliverySlips/models';

export interface DeliveryFile {
  id: string;
  fileType: string;
  docType: string;
  name: string;
}

export interface MaterialInfo {
  itemReference: string;
  material: string;
  unit: string;
  gross: number;
  tare: number;
  net: number;
  retailPrice: number;
  amount?: number;
  tax?: number;
}

export interface Driver {
  numberPlate: string;
  address: string;
}

export interface DeliverySlip {
  date: number;
  id: string;
  associateBillId: string;
  docNoOccurrence: string;
  versionNo: string;
  companyReference: string;
  driver?: Driver;
  bookingReference?: string;
  attachments: DeliveryFile[];
  materials: MaterialInfo[];
  status?: DeliverySlipStatus;
  dmsLinkID: string;
  PDFUrl?: string;
  driverOrderNo?: string;
  transportOrderNo?: string;
}

export interface DeliverySlipsState {
  collection: DeliverySlip[];
  pagination: Pagination;
}

export interface DeliverySlipRow {
  date: number;
  id: string;
  docNoOccurrence: string;
  versionNo: string;
  bookingReference: string;
  attachments: string;
  status: DeliverySlipStatus;
  navigate: string;
}

export interface MaterialInfoRow {
  itemReference: string;
  material: string;
  unit: string;
  gross: number;
  tare: number;
  net: number;
  retailPrice: number;
  amount: number;
  tax: number;
}

export enum EXPORT_FORMAT {
  exportFormat_csv = 'csv',
  exportFormat_pdf = 'pdf',
  exportFormat_xlsx = 'xlsx'
}

export interface ExportData {
  startDate: string;
  endDate: string;
  format: string;
}
