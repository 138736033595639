import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslationsContext } from 'app/translations';
import Buttons from 'components/Buttons';
import { NEW_PICKUP_REQUEST } from 'app/routes';

const PickupButton: React.FC = () => {
  const history = useHistory();
  const translations = useTranslationsContext();

  return (
    <Buttons
      options={ [
        {
          id: 'create-pickupRequest',
          label: translations.pickUpRequest_create,
          onClick: () => {
            history.push(NEW_PICKUP_REQUEST.path);
          },
          buttonType: 'primary',
          type: 'button'
        }
      ] }
    />
  );
};

export default PickupButton;
