import styled from 'styled-components';
import { FOOTER_SIZE, FOOTER_SPACE } from 'base/styles/sizes';
import { PRIMARY } from 'base/styles/skin';

interface StyledProps {
  isBold?: boolean;
}

export const ButtonWrapper = styled.div`
  width: inherit;
  padding-top: 10px;
  padding-right: 10px;
  margin: 0 auto;
  }
`;
ButtonWrapper.displayName = 'ButtonWrapper';

export const ExternalLink = styled.a`
  &,
  &:active,
  &:hover,
  &:focus,
  &:link,
  &:visited {
    margin-left: 3px;
    color: ${ PRIMARY };
    cursor: pointer;
    font-size: 7px;
    font-weight: 500;
    font-size:14px;
    white-space: nowrap;
  }
`;
ExternalLink.displayName = 'ExternalLink';

export const Body = styled.div`
  margin: 0 auto;
  min-height: calc(100vh - ${ FOOTER_SIZE }px);
  overflow-y: auto;
`;
Body.displayName = 'Body';

interface ContentProps {
  isModal?: boolean;
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${ ({ isModal }: ContentProps) => !isModal && `${ FOOTER_SPACE }px` };
  padding: ${ ({ isModal }: ContentProps) => isModal && '20px' };
  padding-top: 0px;
`;
Content.displayName = 'Content';

export const Text = styled.p`
  font-weight: ${ ({ isBold }: StyledProps) => (isBold ? 'bold' : 'normal') };
  margin-bottom: 20px;
  white-space: pre-line;
`;
Text.displayName = 'Text';
