import * as React from 'react';
import TextArea from 'react-textarea-autosize';
import Searcher from 'components/Searcher';
import StrengthCheck from 'components/StrengthCheck';
import Content from '../../../Touchable';
import { Wrapper, ErrorMessage, Input, Label, Description, Icon } from '../styles';
import { getTextAreaDisabledStyles, getTextAreaStyles, getTextAreaErrorStyles } from './styles';

import { FieldProps } from '../../types';
import { PASSWORD } from '../../models';

interface Options extends FieldProps {
  minLength?: number;
  maxLength?: number;
}

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClick: (event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement | HTMLButtonElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  options: Options;
  value?: string;
}

const errorIcon = require('assets/icons/alert.svg');

const Text: React.FC<Props> = ({ onChange, onBlur, onClick, onFocus, options, value = '' }) => (
  <Wrapper>
    <Description>
      <Label htmlFor={ options.id }>
        <Content options={ options } />
      </Label>
    </Description>

    { options.type !== 'textarea' && options.type !== 'search' && (
      <>
        <Input
          id={ options.id }
          name={ options.id }
          isError={ !!options.error }
          value={ value }
          disabled={ options.isDisabled }
          maxLength={ options.maxLength }
          minLength={ options.minLength }
          min={ options.min }
          max={ options.max }
          onClick={ onClick }
          onChange={ onChange }
          onBlur={ onBlur }
          onFocus={ onFocus }
          placeholder={ options.placeholder }
          required={ options.isRequired }
          type={ options.type }
          autoComplete={ options.autocomplete && 'off' }
          list={ options.autocomplete && options.id.replace('.', '-') }
        />

        { /* datalist id attribute does not work well with '.' for some reason */ }
        { options.autocomplete && Array.isArray(options.autocomplete) && (
          <datalist id={ options.id.replace('.', '-') }>
            { options.autocomplete.map((option, index) => (
              <option key={ index } value={ option } />
            )) }
          </datalist>
        ) }
      </>
    ) }

    { options.type === 'textarea' && (
      <TextArea
        id={ options.id }
        name={ options.id }
        value={ value }
        disabled={ options.isDisabled }
        maxLength={ options.maxLength }
        minLength={ options.minLength }
        onClick={ onClick }
        onChange={ onChange }
        onBlur={ onBlur }
        onFocus={ onFocus }
        placeholder={ options.placeholder }
        required={ options.isRequired }
        style={ {
          ...getTextAreaStyles(),
          ...(options.error ? getTextAreaErrorStyles() : {}),
          ...(options.isDisabled ? getTextAreaDisabledStyles() : {})
        } }
      />
    ) }

    { options.type === 'search' && (
      <Searcher
        value={ value }
        onBlur={ onBlur }
        onChange={ onChange }
        onClick={ event => onClick(event) }
        onFocus={ onFocus }
        options={ options }
      />
    ) }

    { options.type === PASSWORD && options.strengthCheck && <StrengthCheck value={ value } /> }

    { !!options.error && (
      <ErrorMessage>
        <Content
          options={ {
            icon: <Icon src={ errorIcon } />,
            label: Array.isArray(options.error) ? options.error[0] : options.error
          } }
        />
      </ErrorMessage>
    ) }
  </Wrapper>
);

export default Text;
