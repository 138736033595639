import { ROLE } from 'app/constants/roles';
import { safeNumber, safeString } from 'app/utils/safeTypes';
import { User, CompanyPermissions } from '../types';
import { UserToServer } from '../types/toServer';

const getClientIdAdmin = (companyId: string, isAdmin: boolean, id: string) => {
  if (isAdmin) {
    return safeNumber(companyId);
  }

  if (id) {
    return 0;
  }

  return undefined;
};

export const createUserToServer = (
  { companyId, email, firstName, lastName, id, permissions, phoneNumber, role, status }: User,
  isAdmin: boolean,
  companyPermissions: CompanyPermissions
): UserToServer => ({
  bills_enable: Number(role) === ROLE.AVAG_ADMIN ? false : companyPermissions.accessBills && permissions.accessBills,
  client_id_admin: getClientIdAdmin(companyId, isAdmin, id),
  first_name: firstName,
  last_name: lastName,
  phone_number: safeString(phoneNumber),
  pickup_request_enable: companyPermissions.accessBills && permissions.requestPickup,
  role_id: role,
  user_email: email,
  user_id: id ? safeNumber(id) : undefined,
  status_id: status
});
