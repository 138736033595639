/* eslint-disable max-len */
import { createPickupRequestToServer } from 'app/containers/PickupRequest/models/toServer';
import { TemplateUpdateRequest } from 'app/containers/PickupRequest/types';
import { TemplateRequestToServer } from 'app/containers/PickupRequest/types/toServer';
import { safeNumber, safeString } from 'app/utils/safeTypes';
import { PickupRequestFromTemplateHome } from '../types';
// import { PickupRequestFromHomeTemplateToServer } from '../types/toServer';

const createDateString = (date: Date): string => {
  if (!date) {
    return '';
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${ year }-${ month }-${ day }`;
};


export const createTemplateRequestToServer = (pickup: TemplateUpdateRequest): TemplateRequestToServer => {
  return ({
    ...createPickupRequestToServer(pickup, false),
    template_place_order_date: pickup.templateActionDetails ? createDateString(new Date(safeNumber(pickup.templateActionDetails.templatePlaceOrderDate))) : ''
  });
};

export const createPickupRequestFromHomeTemplateToServer = (pickupFromHome: PickupRequestFromTemplateHome): any => {
  return ({
    pickup_template_id: safeString(pickupFromHome.templateId),
    template_date_frame_type_id: safeNumber(pickupFromHome.templateDateRule),
    template_name: safeString(pickupFromHome.templateName),
    template_place_order_date: pickupFromHome?.templateDateRule === 1 ? safeString(pickupFromHome.templatePlaceOrderDate) : '',
    status_id: 1
  });
};