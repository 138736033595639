import { http, envConf } from 'base';
import { Pagination } from 'app/types';
import { TemplateRow, TemplateRequest,
  PickupRequestFromTemplateHome } from 'containers/PickupRequest/containers/Templates/types';
import { createPaginationFromServer } from 'app/models';
import { createTemplateUpdateToServer } from 'app/containers/PickupRequest/models/toServer';
import { getFilesToUpload } from 'app/utils/fields';
import { PickupAttachedFile } from 'app/constants/pickupRequest';
import { PickupRequest, TemplateUpdateRequest } from 'app/containers/PickupRequest/types';
import * as Model from '../models/fromServer';
import { createPickupRequestFromHomeTemplateToServer, createTemplateRequestToServer } from '../models/toServer';

interface TemplatesResponse {
  collection: TemplateRow[];
  pagination: Pagination;
}

export const getTemplatesList = async (offset: number, limit: number): Promise<TemplatesResponse> => {
  try {
    const { data } = await http.get(envConf.API.TEMPLATES_LIST_GET, {
      params: { limit, offset }
    });
    const { Template, ...pagination } = data;
    return {
      collection: Model.createTemplatesListFromServer(Template),
      pagination: createPaginationFromServer(pagination)
    };
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
// get template details from server 

export const getTemplateDetails = async (id: string): Promise<TemplateRequest> => {
  try {
    const { data } = await http.get(envConf.API.TEMPLATES_DETAILS.replace(':id', id));
    return Model.createTemplateRequestFromServer(data);
    // return data;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
export const uploadFilesRequest = async (files: File[], pickupId: string, fileCategoryId: number) => {
  try {
    if (files.length) {
      const formData = new FormData();
      files.forEach(file => {
        formData.append('file', file);
      });

      const apiURL = envConf.API.UPLOAD_FILE_POST.replace(':pickupId', pickupId).replace(
        ':fileCategoryId',
        String(fileCategoryId)
      );

      await http.post(apiURL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
const uploadPickupFiles = (pickupRequest: PickupRequest | TemplateUpdateRequest, pickupId: string): Promise<void[]> => {
  if (pickupId) {
    const commentaryFiles = getFilesToUpload(pickupRequest.commentary.attachedFiles);
    const driverInfoFiles = getFilesToUpload(pickupRequest.driverInformation.attachedFiles);
    return Promise.all([
      uploadFilesRequest(commentaryFiles, pickupId, PickupAttachedFile.COMMENTARY),
      uploadFilesRequest(driverInfoFiles, pickupId, PickupAttachedFile.DRIVER_INFO)
    ]);
  }

  return Promise.all([]);
};

export const updateTemplateRequest = async (
  templateRequest: TemplateRequest
): Promise<TemplateRequest> => {
  try {
    const { templateId } = templateRequest;
    const pickupToServer = createTemplateUpdateToServer({ ...templateRequest });
    const { data } = await http.patch(envConf.API.TEMPLATES_DETAILS_PATCH.replace(':id', templateId), pickupToServer);

    return Model.createTemplateRequestFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const processTemplateRequest = async (templateRequest: TemplateRequest): Promise<TemplateRequest> => {
  try {
    const { templateId } = templateRequest;
    const pickupToServer = createTemplateRequestToServer(
      { ...templateRequest }
    );
    const { data } = await http.post(envConf.API.TEMPLATES_ORDER_POST.replace(':id', templateId), pickupToServer);

    await uploadPickupFiles(templateRequest, data.pickup_id);

    return Model.createTemplateRequestFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

// delete template 
export const deleteTemplateRequest = async (id: string): Promise<TemplateRequest> => {
  try {
    const { data } = await http.delete(envConf.API.TEMPLATE_DELETE.replace(':id', id));
    return data;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
// create order from home template
export const pickupRequestFromHomeTemplate = async (pickupFromHome: PickupRequestFromTemplateHome
): Promise<PickupRequestFromTemplateHome> => {

  try {
    const pickupToServer = createPickupRequestFromHomeTemplateToServer({ ...pickupFromHome });
    const { data } = await http.post(envConf.API.TEMPLATES_ORDER_HOME_POST, { ...pickupToServer });
    return data;
    // return Model.createPickupRequestFromServer(data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
