import styled from 'styled-components';
import { TABLET } from 'base/styles/media-queries';
import { SIDEBAR_BACKGROUND, FOOTER_TEXT } from 'base/styles/skin';
import getLayerPosition from 'base/styles/z-index';
import { Buttons } from 'app/components/Buttons/styles';
import Card from 'app/components/Card';

export const Wrapper = styled.div`
  bottom: 10px;
  position: fixed;
  right: 10px;
  width: calc(100% - 20px);
  z-index: ${ getLayerPosition('NOTIFICATION') };

  ${ TABLET } {
    max-width: 400px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const NotificationCard = styled(Card)`
  align-items: center;
  background-color: ${ SIDEBAR_BACKGROUND };
  color: ${ FOOTER_TEXT };
  margin-top: 12px;
  padding: 12px 0;

  & > div {
    padding: 0 12px;
    white-space: pre-wrap; /* Chrome; W3C standard */
    word-wrap: break-word; /* IE */
  }

  & > div > ${ Buttons } {
    margin-top: 12px;
  }

  ${ TABLET } {
    padding: 20px 0;

    & > div {
      padding: 0 20px;
    }
  }
`;
NotificationCard.displayName = 'NotificationCard';

export const NotificationTitle = styled.p`
  font-weight: bold;
`;
NotificationTitle.displayName = 'NotificationTitle';

export const NotificationBody = styled.p`
`;
NotificationBody.displayName = 'NotificationBody';