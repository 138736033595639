import styled, { css } from 'styled-components';
import {
  FIELD_BORDER_COLOR,
  FIELD_BACKGROUND,
  FIELD_PLACEHOLDER_COLOR,
  BUTTON_PRIMARY_BACKGROUND,
  BUTTON_PRIMARY_BORDER,
  BUTTON_PRIMARY_COLOR,
  FIELD_BORDER_COLOR_DISABLED,
  ERROR_BORDER_COLOR, BUTTON_COLOR_HOVER
} from 'base/styles/skin';
import { DESKTOP } from 'base/styles/media-queries';

interface StyledProps {
  isDisabled?: boolean;
  isError?: boolean;
}

const ErrorStyles = css`
  border: solid 1px ${ ERROR_BORDER_COLOR };
`;

const DisabledStyles = css`
  border-radius: 0;
  border: none;
  border-bottom: solid 1px ${ FIELD_BORDER_COLOR_DISABLED };
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 33px;
  border-radius: 4px;
  min-height: 30px;
  border: solid 1px ${ FIELD_BORDER_COLOR };

  ${ ({ isError }: StyledProps) => isError && ErrorStyles }
  ${ ({ isDisabled }: StyledProps) => isDisabled && DisabledStyles }
`;
SearchWrapper.displayName = 'SearchWrapper';

export const InputSearch = styled.input`
  appearance: none;
  background-color: ${ FIELD_BACKGROUND };
  color: inherit;
  border-radius: 4px;
  border: none;
  cursor: inherit;
  display: block;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.43;
  min-height: 30px;
  padding: 5px 10px;
  width: 100%;

  user-select: initial;
  -webkit-touch-callout: initial;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${ FIELD_PLACEHOLDER_COLOR };
    font-size: 12px;

    ${ DESKTOP } {
      font-size: 14px;
    }
  }
`;
InputSearch.displayName = 'InputSearch';

export const SearchButton = styled.button`
  background-color: ${ BUTTON_PRIMARY_BACKGROUND };
  border: none;
  color: ${ BUTTON_PRIMARY_COLOR };
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover{
    background-color: ${ BUTTON_COLOR_HOVER };
  
  }

  &:active {
    padding-top-color: ${ BUTTON_PRIMARY_BORDER };
    border-bottom-color: transparent;
  }

  &:focus {
    outline: none;
  }
`;
SearchButton.displayName = 'SearchButton';

export const IconSearch = styled.img`
  height: 28px;
  width: 30px;
  object-fit: contain;
`;
IconSearch.displayName = 'IconSearch';
