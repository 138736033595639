import React from 'react';
import Card from 'components/Card';
import { useTranslationsContext } from 'app/translations';

import { IndexedFields } from 'app/types';
import Field from 'components/SmartForm/components';
import SectionTitle from 'components/SectionTitle';
import { CardContent } from './styles';
import { FieldName } from '../../types';

interface Props {
  fields: IndexedFields<FieldName>;
}

const DriverInfo: React.FC<Props> = ({ fields }) => {
  const translations = useTranslationsContext();

  return (
    <>
      <SectionTitle>{ translations.pickupRequestDetails_driverInfoTitle }</SectionTitle>
      <Card indentation={ true }>
        <CardContent>
          <Field options={ fields['driverInformation.additionalComment'] } />
          <Field options={ fields['driverInformation.attachedFiles'] } />
        </CardContent>
      </Card>
    </>
  );
};

export default DriverInfo;
