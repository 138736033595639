import React from 'react';
import { FieldProps } from 'components/SmartForm/types';
import { RendererProps } from 'components/Table/types';
import { CompanyPermissions } from 'containers/Users/types';
import { HandleCheckbox, CompaniesRow } from '../../types';
import CheckboxSettings from '../components/Checkbox';

type RenderCreator = (
  permissionId: keyof CompanyPermissions,
  handleCheckboxChange: HandleCheckbox
) => React.FC<RendererProps<CompaniesRow>>;

export const rendererCreator: RenderCreator = (permissionId, handleCheckboxChange) => ({ cell, data }) => {
  const check: FieldProps = {
    id: `${ data.id }-${ permissionId }`,
    type: 'checkbox',
    isDisabled: (permissionId === 'requestPickup' || permissionId === 'accessBills') && !data.portal,
    onChange: () => {
      handleCheckboxChange(data, permissionId);
    }
  };

  return <CheckboxSettings options={ check } value={ cell as boolean } />;
};
