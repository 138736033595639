import { ROLE } from 'app/constants/roles';
import { LangKeys } from 'app/translations';
import { Column } from 'components/Table/types';
import { renderers } from 'components/Table';
import { PickupRequestRow } from 'containers/PickupRequest/types';
import LockRenderer from '../components/LockIcon';
import TableTag from '../components/Tag';
import VevaDoc from '../components/VeVa';

export const createColumns = (translations: LangKeys, role: ROLE) => {
  const showCompanyColumn = role === ROLE.AVAG_ADMIN;

  const columns: Column<PickupRequestRow>[] = [];

  columns.push({
    key: 'lock',
    renderer: LockRenderer
  });

  columns.push({
    key: 'id',
    label: translations.pickUp_orderId,
    mobile: true
  });

  columns.push({
    key: 'creationDate',
    label: translations.pickUp_creationDate,
    renderer: renderers.DateTime
  });

  columns.push({
    key: 'preferredDate',
    label: translations.pickupRequestDetails_preferredDateLabel,
    renderer: renderers.Date,
    mobile: true
  });

  if (showCompanyColumn) {
    columns.push({
      key: 'company',
      label: translations.pickUp_company
    });
  }

  columns.push({
    key: 'owner',
    label: translations.pickUp_owner
  });

  columns.push({
    key: 'address',
    label: translations.pickUp_address,
    mobile: true
  });

  columns.push({
    key: 'isVeva',
    label: translations.pickUp_veva,
    renderer: VevaDoc
  });

  columns.push({
    key: 'status',
    label: translations.pickUp_status,
    mobile: true,
    renderer: TableTag
  });

  columns.push({
    key: 'navigate'
  });

  return columns;
};
