import React from 'react';
import { EnumValue, IndexedFields, Enum } from 'app/types';
import { LangKeys } from 'app/translations';
import { SmartFile } from 'components/SmartForm/components/fileUploader/types';
import { FieldProps, Option } from 'components/SmartForm/types';

export const createIndexedFields = <T extends EnumValue>(
  fields: FieldProps[],
  arrayName?: string,
  index?: number
): IndexedFields<T> =>
    fields.reduce((fieldObject, field) => {
      fieldObject[field.id as T] = arrayName ? { ...field, id: `${ arrayName }.${ index }.${ field.id }` } : field;
      return fieldObject;
    }, {} as IndexedFields<T>);

export const createOptionsFromEnum = <T extends EnumValue>(myEnum: Enum, translations?: LangKeys): Option<T>[] => {
  return Object.keys(myEnum)
    .filter(x => Number.isNaN(Number(x)))
    .reduce((dropdownOptions, enumKey) => {
      dropdownOptions.push({
        id: enumKey,
        label: translations ? translations[enumKey as keyof LangKeys] : enumKey,
        value: myEnum[enumKey]
      });
      return dropdownOptions;
    }, []);
};

export const createOptionsFromList = (options: string[]): Option<string>[] =>
  options.reduce((dropdownOptions, option) => {
    dropdownOptions.push({
      id: option,
      label: option,
      value: option
    });
    return dropdownOptions;
  }, []);

export const createOptionsFromEntity = <T extends { id: string | number; name: string }>(
  options: T[],
  valuePropName = 'id'
): Option<string>[] =>
    options.reduce((dropdownOptions, option) => {
      dropdownOptions.push({
        id: option.id,
        label: option.name,
        value: option[valuePropName as keyof T]
      });
      return dropdownOptions;
    }, []);

export const createRadioOptionsFromEntity = <T extends { id: string | number; name: string | React.ReactElement }>(
  options: T[],
  valuePropName = 'id'
): Option<string>[] =>
    options.reduce((dropdownOptions, option) => {
      dropdownOptions.push({
        id: option.id,
        label: option.name,
        value: option[valuePropName as keyof T]
      });
      return dropdownOptions;
    }, []);

export const getFilesToUpload = (attachedFiles: SmartFile[]) =>
  // eslint-disable-next-line no-restricted-syntax
  attachedFiles.filter(file => 'id' in file === false) as File[];

export const addSuffix = (suffix: string) => (value: string) => `${ value }-${ suffix }`;

type ObjectType = {
  [key: string]: any;
};

export const flattenObject = (ob: ObjectType) => {
  const toReturn: ObjectType = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      // @ts-ignore
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        if (typeof flatObject === 'object') {
          toReturn[`${ i }.${ x }`] = flatObject[x];
        }
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
