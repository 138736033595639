import styled, { css } from 'styled-components';

import { TABLET } from 'base/styles/media-queries';
import { FOOTER_BACKGROUND, FOOTER_LINK_HOVER, FOOTER_TEXT } from 'base/styles/skin';
import { FOOTER_SIZE, FOOTER_SPACE } from 'base/styles/sizes';

interface Props {
  margin: boolean;
}

interface ContainerProps {
  noMenu: boolean; 
}

const TextStyles = css`
  font-size: 8px;
  color: ${ FOOTER_TEXT };
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  ${ TABLET } {
    font-size: 12px;
  }
`;

export const Wrapper = styled.footer`
  height: ${ ({ margin }: Props) => FOOTER_SIZE + (margin ? FOOTER_SPACE : 0) }px;
  min-height: ${ ({ margin }: Props) => FOOTER_SIZE + (margin ? FOOTER_SPACE : 0) }px;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const Spacer = styled.div`
  height: ${ ({ margin }: Props) => (margin ? FOOTER_SPACE : 0) }px;
  min-height: ${ ({ margin }: Props) => (margin ? FOOTER_SPACE : 0) }px;
  width: 100%;
`;
Spacer.displayName = 'Spacer';

export const Container = styled.div`
  align-items: flex-start;
  padding: 24px 0;
  padding-left: 0;
  background-color: ${ FOOTER_BACKGROUND };
  display: flex;
  height: ${ FOOTER_SIZE }px;
  min-height: ${ FOOTER_SIZE }px;
  justify-content: space-between;
  width: 100%;

  ${ TABLET } {
    padding-left: ${ ({ noMenu }: ContainerProps) => (noMenu ? '0' : '70px') };
  }
`;
Container.displayName = 'Container';

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;
Contact.displayName = 'Contact';

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 24px;
`;
RightContent.displayName = 'RightContent';

export const Impressum = styled.div`
  ${ TextStyles }
  white-space: pre-line;
  padding: 0 24px;
`;
Impressum.displayName = 'Impressum';

export const Text = styled.div`
  ${ TextStyles }
`;
Text.displayName = 'Text';

export const Line = styled.div`
  ${ TextStyles }
  align-items: center;
  display: flex;
`;
Line.displayName = 'Line';

export const ExternalLink = styled.a`
  &,
  &:active,
  &:focus,
  &:link,
  &:visited {
    margin-left: 3px;
    color: ${ FOOTER_TEXT };
    cursor: pointer;
    font-size: 7px;
    font-weight: 500;
    text-decoration: underline;
    white-space: nowrap;

    ${ TABLET } {
      font-size: 12px;
    }
  }

  &:hover{
      color: ${ FOOTER_LINK_HOVER };
  }
`;
ExternalLink.displayName = 'ExternalLink';
