import React from 'react';
import { Dimension, DimensionChartOptions } from 'app/constants/statistic';
import { useTranslationsContext } from 'app/translations';
import DummyField from 'components/SmartForm/components/DummyField';
import { DataFilterValues, StatisticData } from 'containers/Statistics/types';
import { useFormData } from './data';
import { Wrapper, DropdownWrappers, DimensionWrappers, Label } from './styles';

interface Props {
  data: StatisticData[];
  dimension: DimensionChartOptions;
  isOverview: boolean;
  isSecondary: boolean;
  isComparing: boolean;
  isAreaChart: boolean;
  filterValues: DataFilterValues;
  onChangeDimension: (newDimension: string) => void;
  onChangeMaterial: (newIndex: number) => void;
  onFilter: (fieldName: string, value: any) => void;
}

// eslint-disable-next-line max-lines-per-function
const Header: React.FC<Props> = ({ data,
  dimension,
  filterValues,
  isAreaChart,
  isComparing,
  isOverview,
  isSecondary,
  onChangeDimension,
  onChangeMaterial,
  onFilter }) => {
  const translations = useTranslationsContext();

  const { dropdownFields, dimensionFields } = useFormData({
    data,
    isAreaChart,
    isComparing,
    isOverview,
    isSecondary,
    values: filterValues
  });
  const { dimensionChart, material, startDate, endDate } = dropdownFields;
  const { dimensionRadio, dimensionWeight, dimensionCost } = dimensionFields;

  const handleFilterByName = (fieldName: string) => (_: string, newValue: any) => {
    if (fieldName === 'material') {
      onChangeMaterial(material.options.findIndex(opt => opt.id === newValue));
    }
    onFilter(fieldName, newValue);
  };

  React.useEffect(() => {
    if (isAreaChart && !isOverview && !filterValues.material && data.length) {
      onChangeMaterial(material.options.findIndex(opt => opt.id === material.model));
      onFilter('material', material.model);
    }
  }, [isAreaChart, isOverview, material, filterValues, data]);

  return (
    <Wrapper>
      { !isOverview && (
        <DropdownWrappers>
          { dimensionChart && (
            <DummyField
              key={ dimensionChart.id }
              options={ dimensionChart }
              setFieldValue={ (_, nextDimension) => onChangeDimension(nextDimension) }
              value={ dimension }
            />
          ) }

          { material && (
            <DummyField
              key={ material.id }
              options={ material }
              setFieldValue={ handleFilterByName('material') }
              value={ filterValues.material || material.model }
            />
          ) }

          { startDate && (
            <DummyField
              key={ startDate.id }
              options={ startDate }
              setFieldValue={ handleFilterByName('startDate') }
              value={ filterValues.startDate }
            />
          ) }

          { endDate && (
            <DummyField
              key={ endDate.id }
              options={ endDate }
              setFieldValue={ handleFilterByName('endDate') }
              value={ filterValues.endDate }
            />
          ) }
        </DropdownWrappers>
      ) }

      <DimensionWrappers>
        { isOverview && <Label>{ translations.home_statistic_radioButtonLabel }:</Label> }

        { dimensionRadio && filterValues.dimensions.some(v => !v) && (
          <DummyField
            setFieldValue={ (_, newValue) => {
              const newDimensions = [Dimension.cost === newValue, Dimension.weight === newValue];
              onFilter('dimensions', newDimensions);
            } }
            options={ dimensionRadio }
            value={ filterValues.dimensions[0] ? Dimension.cost : Dimension.weight }
          />
        ) }

        { dimensionWeight && (
          <DummyField
            key={ dimensionWeight.id }
            options={ dimensionWeight }
            setFieldValue={ (_, value) => {
              const newDimensions = [filterValues.dimensions[0], value];
              if (newDimensions.some(d => d)) {
                onFilter('dimensions', newDimensions);
              }
            } }
            onChange={ () => {} }
            value={ !!filterValues.dimensions[1] }
          />
        ) }

        { dimensionCost && (
          <DummyField
            key={ dimensionCost.id }
            options={ dimensionCost }
            setFieldValue={ (_, value) => {
              const newDimensions = [value, filterValues.dimensions[1]];
              if (newDimensions.some(d => d)) {
                onFilter('dimensions', newDimensions);
              }
            } }
            onChange={ () => {} }
            value={ !!filterValues.dimensions[0] }
          />
        ) }
      </DimensionWrappers>
    </Wrapper>
  );
};

export default Header;
