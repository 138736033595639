import React, { useState, useCallback } from 'react';
import { Img } from './styles';

declare module 'react' {
  interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
    loading?: 'eager' | 'lazy';
  }
}

interface Props {
  sourceImg: string;
  fallbackImg?: string;
  alt?: string;
  loading?: 'eager' | 'lazy';
}
const defaultFallbackImg = require('assets/icons/placeholders/fallback_img.svg');

const Image: React.FC<Props> = ({ sourceImg, fallbackImg = defaultFallbackImg, alt = '', loading = 'lazy' }) => {
  const [errored, setErrored] = useState(false);

  const handleImageError = useCallback(() => {
    if (!errored) {
      setErrored(true);
    }
  }, []);

  return <Img alt={ alt } src={ errored ? fallbackImg : sourceImg } onError={ handleImageError } loading={ loading } />;
};

export default Image;
