import { Action } from 'app/types';

export enum ActionTypes {
  GET_DELIVERY_SLIP_LIST_REQUEST = 'GET_DELIVERY_SLIP_LIST_REQUEST',
  GET_DELIVERY_SLIP_LIST_SUCCESS = 'GET_DELIVERY_SLIP_LIST_SUCCESS',
  GET_DELIVERY_SLIP_LIST_ERROR = 'GET_DELIVERY_SLIP_LIST_ERROR',

  GET_DELIVERY_SLIP_REQUEST = 'GET_DELIVERY_SLIP_REQUEST',
  GET_DELIVERY_SLIP_SUCCESS = 'GET_DELIVERY_SLIP_SUCCESS',
  GET_DELIVERY_SLIP_ERROR = 'GET_DELIVERY_SLIP_ERROR',

  // GET_EXPORT_DATA_REQUEST = 'GET_EXPORT_DATA_REQUEST',
  GET_EXPORT_DATA_SUCCESS = 'GET_EXPORT_DATA_SUCCESS',
  GET_EXPORT_DATA_ERROR = 'GET_EXPORT_DATA_ERROR',

  GET_DELIVERY_FILE_REQUEST = 'GET_DELIVERY_FILE_REQUEST',
  GET_DELIVERY_FILE_SUCCESS = 'GET_DELIVERY_FILE_SUCCESS',
  GET_DELIVERY_FILE_ERROR = 'GET_DELIVERY_FILE_ERROR',

  PRINT_DELIVERY_SLIP_REQUEST = 'PRINT_DELIVERY_SLIP_REQUEST',
  PRINT_DELIVERY_SLIP_SUCCESS = 'PRINT_DELIVERY_SLIP_SUCCESS',
  PRINT_DELIVERY_SLIP_ERROR = 'PRINT_DELIVERY_SLIP_ERROR',

  RESET_DELIVERY_SLIPS = 'RESET_DELIVERY_SLIPS'
}

type GetDeliverySlipListRequest = Action<ActionTypes.GET_DELIVERY_SLIP_LIST_REQUEST>;
type GetDeliverySlipListSuccess = Action<ActionTypes.GET_DELIVERY_SLIP_LIST_SUCCESS>;
type GetDeliverySlipListError = Action<ActionTypes.GET_DELIVERY_SLIP_LIST_ERROR>;

type GetDeliverySlipRequest = Action<ActionTypes.GET_DELIVERY_SLIP_REQUEST>;
type GetDeliverySlipSuccess = Action<ActionTypes.GET_DELIVERY_SLIP_SUCCESS>;
type GetDeliverySlipError = Action<ActionTypes.GET_DELIVERY_SLIP_ERROR>;

// type GetExportDataRequest = Action<ActionTypes.GET_EXPORT_DATA_REQUEST>;
type GetExportDataSuccess = Action<ActionTypes.GET_EXPORT_DATA_SUCCESS>;
type GetExportDataError = Action<ActionTypes.GET_EXPORT_DATA_ERROR>;

type GetDeliveryFileRequest = Action<ActionTypes.GET_DELIVERY_FILE_REQUEST>;
type GetDeliveryFileSuccess = Action<ActionTypes.GET_DELIVERY_FILE_SUCCESS>;
type GetDeliveryFileError = Action<ActionTypes.GET_DELIVERY_FILE_ERROR>;

type PrintDeliverySlipRequest = Action<ActionTypes.PRINT_DELIVERY_SLIP_REQUEST>;
type PrintDeliverySlipSuccess = Action<ActionTypes.PRINT_DELIVERY_SLIP_SUCCESS>;
type PrintDeliverySlipError = Action<ActionTypes.PRINT_DELIVERY_SLIP_ERROR>;

type ResetDeliveySlips = Action<ActionTypes.RESET_DELIVERY_SLIPS>;

type GetDeliverySlipListActionType = GetDeliverySlipListRequest | GetDeliverySlipListSuccess | GetDeliverySlipListError;
type GetDeliverySlipActionType = GetDeliverySlipRequest | GetDeliverySlipSuccess | GetDeliverySlipError;
// type GetExportDataActionType = GetExportDataRequest | GetExportDataSuccess | GetExportDataError;
type GetDeliveryFileActionType = GetDeliveryFileRequest | GetDeliveryFileSuccess | GetDeliveryFileError;
type PrintDeliverySlipActionType = PrintDeliverySlipRequest | PrintDeliverySlipSuccess | PrintDeliverySlipError;

export type DeliverySlipActionType =
  | GetDeliverySlipListActionType
  | GetDeliverySlipActionType
  | PrintDeliverySlipActionType
  | ResetDeliveySlips
  // | GetExportDataActionType
  | GetDeliveryFileActionType;
