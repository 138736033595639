import React, { /* ChangeEvent, */ useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIsLoading } from 'app/hooks';
import { DELIVERY_SLIP } from 'app/routes';
import debounce from 'app/utils/debounce';
import { useTranslationsContext } from 'app/translations';
// import InputFilter from 'components/InputFilter';
import { TableSkeleton } from 'components/Skeletons';
import { FieldProps } from 'components/SmartForm/types';
import Table from 'components/Table';
import { SelectedItem } from 'components/Table/types';
import { downloadExportData } from 'app/utils/downloads';
import * as actions from '../../actions';
import { useDeliverySlipListRequest, useDeliverySlipReset } from '../../hooks';
import { createRowFromDeliverySlips, createExportDataInfo } from '../../models';
import { getDeliverySlipList } from '../../selectors';
import { DeliverySlipRow } from '../../types';
import ExportModal from './components/ExportModal';
import ExportDataButton from './components/ExportDataButton';
import { useTableData } from './data';

const DeliverySlipList = () => {
  const { collection, pagination } = useSelector(getDeliverySlipList);
  const [isLoading, setIsLoading] = useState(true);
  // const [filterValue, setFilterValue] = useState<string>(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(pagination.currentPage);
  const { columns } = useTableData();
  const translations = useTranslationsContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const openQueries = useIsLoading(['get-delivery-slips']);

  const deliverySlipsRows = React.useMemo(() => {
    return collection.map(deliverySlip => createRowFromDeliverySlips(deliverySlip, translations));
  }, [collection]);

  useDeliverySlipReset();
  useDeliverySlipListRequest(currentPage, pagination.pageSize, /* filterValue */ '');
 
  const handlePagination = useCallback(({ selected }: SelectedItem) => {
    setCurrentPage(selected);
  }, []);

  const handleSelectRow = useCallback((row: DeliverySlipRow) => {
    history.push(
      DELIVERY_SLIP.path
        .replace(':id', row.id.toString())
        .replace(':docNo', row.docNoOccurrence.toString())
        .replace(':versionNo', row.versionNo.toString())
    );
  }, []);

  // const handleFilter = useCallback((event: ChangeEvent<HTMLInputElement>) => {
  //   setFilterValue(event.target.value);
  // }, []);

  const exportDataHandler = useCallback(() => {
    setOpenModal(true);
  }, []);

  // const onConfirm = async (values: FieldProps[]) => {
  //   const exportData = createExportDataInfo(values);
  //   const { type, payload } = await dispatch(actions.getExportRequest(exportData));
  //
  //   if (type.includes('SUCCESS')) {
  //     downloadExportData(translations.deliverySlipList_exportDataName, (payload as unknown) as string, exportData);
  //   }
  //
  //   setOpenModal(false);
  // };

  const onDismiss = useCallback(() => {
    setOpenModal(false);
  }, []);

  React.useEffect(() => {
    debounce(() => {
      if (isLoading) {
        setIsLoading(openQueries);
      }
    }, 100);
  }, [openQueries]);

  const tableActions: JSX.Element[] = [
    // <InputFilter
    //   key="table-filter"
    //   onChange={ handleFilter }
    //   placeholder={ translations.deliverySlip_search_placeholder }
    // />
  ];

  const footerTableActions = [<ExportDataButton key="exportDataButton" onClick={ exportDataHandler } />];

  if (isLoading && !deliverySlipsRows.length) {
    return <TableSkeleton />;
  }

  return (
    <>
      <Table<DeliverySlipRow>
        actions={ tableActions }
        columns={ columns }
        // filterValue={ filterValue }
        footerActions={ footerTableActions }
        isLoading={ openQueries }
        onClick={ handleSelectRow }
        pagination={ {
          onChange: handlePagination,
          ...pagination
        } }
        rows={ deliverySlipsRows }
        title={ translations.routeName_deliverySlips }
      />

      { openModal && <ExportModal onConfirm={ onConfirm } onDismiss={ onDismiss } /> }
    </>
  );
};

export default DeliverySlipList;
