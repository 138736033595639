import React, { ChangeEvent, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIsLoading } from 'app/hooks';
import { PICKUP_REQUEST } from 'app/routes';
import { useTranslationsContext } from 'app/translations';
import { getOffsetPagination } from 'app/utils/pagination';
import debounce from 'app/utils/debounce';
import Table from 'components/Table';
import { SelectedItem } from 'components/Table/types';
import { TableSkeleton } from 'components/Skeletons';
import InputFilter from 'components/InputFilter';
import { canDo } from 'containers/Core/selectors';
import { getLoginData } from 'containers/Login/selectors';
import * as actions from '../../actions';
import { usePickupListRequest, usePickupListReset } from '../../hooks';
import { createRowFromPickupRequest } from '../../models';
import { getPickupRequestList } from '../../selectors';
import { PickupRequestRow } from '../../types';
import CreateButton from './components/CreateButton';
import TemplateButton from './components/TemplateButton';
import { useTableData } from './data';

const PickupRequestList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterValue, setFilterValue] = useState<string>('0');
  const [order, setOrder] = useState<string>('Asc');
  const [rowname, setRowname] = useState<string>('status');
  const { role } = useSelector(getLoginData);
  const { columns } = useTableData(role);
  const { collection, pagination } = useSelector(getPickupRequestList);
  const createAvailable = useSelector(canDo('CREATE_PICKUPS'));
  const translations = useTranslationsContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const openQueries = useIsLoading(['get-pickup-requests']);

  const pickupRequestRows = React.useMemo(() => {
    return collection.map(createRowFromPickupRequest);
  }, [collection]);

  usePickupListReset();
  usePickupListRequest({
    limit: pagination.pageSize,
    offset: getOffsetPagination(pagination.currentPage, pagination.pageSize),
    order,
    rowname,
    filterValue,
  });

  const handlePagination = useCallback(
    ({ selected }: SelectedItem) => {
      if (selected !== pagination.currentPage) {
        dispatch(
          actions.getPickupRequestList({
            limit: pagination.pageSize,
            offset: getOffsetPagination(selected, pagination.pageSize),
            order,
            rowname,
            filterValue,
          })
        );
      }
    },
    [pagination.currentPage, pagination.pageSize, filterValue]
  );

  const handleSelectRow = useCallback((row: PickupRequestRow) => {
    history.push(PICKUP_REQUEST.path.replace(':id', row.id.toString()));
  }, []);

  const handleFilter = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  }, []);

  const sortingTable = (row: string, orderTable: string) => {
    if (order !== orderTable.toUpperCase()) {
      setOrder(orderTable.toUpperCase());
    }
    
    if (rowname !== row) {
      setRowname(row);
    }
  };

  React.useEffect(() => {
    debounce(() => {
      if (isLoading) {
        setIsLoading(openQueries);
      }
    }, 100);
  }, [openQueries]);

  const tableActions = [];
  if (createAvailable) {
    tableActions.push(<TemplateButton key="template-button" />);
    tableActions.push(<CreateButton key="create-button" />);
  }
  tableActions.push(<InputFilter
    key="table-filter"
    onChange={ handleFilter }
    placeholder={ translations.pickUpRequest_search_placeholder }
  />);
  if (isLoading && !pickupRequestRows.length) {
    return <TableSkeleton />;
  }

  return (
    <Table<PickupRequestRow>
      actions={ tableActions }
      columns={ columns }
      filterValue={ filterValue }
      isLoading={ openQueries }
      onClick={ handleSelectRow }
      pagination={ {
        onChange: handlePagination,
        ...pagination,
      } }
      backSorting={ true }
      sortKey={ rowname as keyof PickupRequestRow }
      onSort={ sortingTable }
      rows={ pickupRequestRows }
      title={ translations.routeName_pickUpRequests }
    />
  );
};

export default PickupRequestList;
