import { Location } from 'containers/Location/types';

export const LOCATION_LIST: Location[] = [
  {
    id: '123123',
    name: 'Zürich'
  },
  {
    id: '234234',
    name: 'Köniz'
  },
  {
    id: '345345',
    name: 'Bern'
  }
];
