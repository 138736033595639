import React, { ChangeEvent, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIsLoading } from 'app/hooks';
import { USER } from 'app/routes';
import { useTranslationsContext } from 'app/translations';
import debounce from 'app/utils/debounce';
import Table from 'components/Table';
import { SelectedItem } from 'components/Table/types';
import { TableSkeleton } from 'components/Skeletons';
import InputFilter from 'components/InputFilter';
import * as actions from '../../actions';
import { useUserListRequest, useUserListReset, useUserRows } from '../../hooks';
import { getUserList } from '../../selectors';
import { UserRow } from '../../types';
import CreateButton from './components/CreateButton';
import { useTableData } from './data';

const UserRequestList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterValue, setFilterValue] = useState<string>(undefined);
  const { columns } = useTableData();
  const { pagination } = useSelector(getUserList);
  const userRows = useUserRows();
  const translations = useTranslationsContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const openQueries = useIsLoading(['get-user-requests']);

  useUserListReset();
  useUserListRequest(pagination.currentPage, pagination.pageSize, filterValue);

  const handlePagination = useCallback(
    ({ selected }: SelectedItem) => {
      dispatch(actions.getUserList(selected, pagination.pageSize, filterValue));
    },
    [pagination.pageSize, filterValue]
  );

  const handleSelectRow = useCallback((row: UserRow) => {
    history.push(USER.path.replace(':id', row.id.toString()));
  }, []);

  const handleFilter = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  }, []);

  React.useEffect(() => {
    debounce(() => {
      if (isLoading) {
        setIsLoading(openQueries);
      }
    }, 100);
  }, [openQueries]);

  const tableActions = [
    <InputFilter key="table-filter" onChange={ handleFilter } placeholder={ translations.usersList_searchLabel } />,
    <CreateButton key="create-button" />
  ];

  if (isLoading && !userRows.length) {
    return <TableSkeleton />;
  }

  return (
    <Table<UserRow>
      actions={ tableActions }
      columns={ columns }
      filterValue={ filterValue }
      isLoading={ openQueries }
      onClick={ handleSelectRow }
      pagination={ {
        onChange: handlePagination,
        ...pagination
      } }
      rows={ userRows }
      title={ translations.usersList_title }
    />
  );
};

export default UserRequestList;
