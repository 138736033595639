import { DeliveryFile } from 'app/containers/DeliverySlips/types';

interface ExportDataFields {
  startDate: string;
  endDate: string;
  format?: string;
}

enum ExportDataFormats {
  exportFormat_csv = 'csv',
  exportFormat_pdf = 'pdf',
  exportFormat_xlsx = 'xlsx'
}

const isIE11 = !!window.navigator.msSaveBlob;

export const createDownload = (blob: Blob, fileName: string) => {
  if (isIE11) {
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
};

export const createArrayFromString = (decodedStr: string) => {
  const array = new Uint8Array(decodedStr.length);
  for (let i = 0; i < decodedStr.length; i += 1) {
    array[i] = decodedStr.charCodeAt(i);
  }
  return array;
};

export const createBlobFromUnitArray = (array: Uint8Array, type?: string) => {
  const blob = new Blob([array], { type });
  return blob;
};

export const createBlobFromString = (decodedStr: string, type?: string) => {
  const array = createArrayFromString(decodedStr);
  const blob = new Blob([array], { type });
  return blob;
};

export const base64ToBlob = (encodedStr: string, type?: string) => {
  const decodedStr = atob(encodedStr);
  return createBlobFromString(decodedStr, type);
};

export const downloadExportData = (name: string, data: string, exportData: ExportDataFields): void => {
  const { startDate, endDate, format } = exportData;
  const fileName = `${ name }-${ startDate }-${ endDate }.${ format || ExportDataFormats.exportFormat_pdf }`;

  let type = 'application/octet-stream';
  switch (format) {
    case ExportDataFormats.exportFormat_csv:
      type = 'text/csv';
      break;

    case ExportDataFormats.exportFormat_xlsx:
      type = 'application/vnd.ms-excel';
      break;

    case ExportDataFormats.exportFormat_pdf:
      type = 'application/pdf';
      break;

    default:
  }

  const blob = base64ToBlob(data, type);
  createDownload(blob, fileName);
};

export const downloadDeliverySlipAttachment = (data: string, file: DeliveryFile) => {
  const base64str = data;

  // remove space for IE compatibility
  const binary = atob(base64str.replace(/\s/g, ''));
  const buffer = new ArrayBuffer(binary.length);
  const view = new Uint8Array(buffer).map((i, index) => binary.charCodeAt(index));

  let blobType;

  switch (file.fileType) {
    case '.pdf':
      blobType = 'application/pdf';
      break;
    default:
      blobType = '';
  }

  const blob = new Blob([view], { type: blobType });
  createDownload(blob, file.name);
};
