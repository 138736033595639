export enum ExportFieldName {
  startDate = 'startDate',
  endDate = 'endDate',
  format = 'format'
}

export enum ExportDataFormats {
  exportFormat_csv = 'csv',
  exportFormat_pdf = 'pdf',
  exportFormat_xlsx = 'xlsx'
}
