/* eslint-disable max-lines */
import { PickupAttachedFile } from 'app/constants/pickupRequest';
import { safeString, safeNumber, safeDate } from 'app/utils/safeTypes';
import { SmartFile } from 'components/SmartForm/components/fileUploader/types';
import { createCompanySimpleFromServer,
  createCustomerFromServer,
  createCustomerFromClientFromServer } from 'containers/Companies/models/fromServer';
import { API } from '../../../../env/env';
import { PickupRequestFromServer,
  MaterialDetailsFromServer,
  FileFromServer,
  AddonFromServer, 
  DriverOrdersFromServer } from '../types/fromServer';
import { Logistic, Commentary, DriverInfo, MaterialDetails,
  PickupRequest, ContainerAddon, DriverOrders } from '../types';
import { getAddonValue } from './containers';
import {env} from "base";

const createLogisticFromServer = ({ location,
  date_pickup,
  date_pickup_logistic,
  location_save,
  ask_to_call,
  contact_phone,
  user_name,
  delivery_frame_id_logistic,
  template_date_frame_type_id }: PickupRequestFromServer): Logistic => {
  if (
    location === undefined
    || (date_pickup === undefined && date_pickup_logistic === undefined)
    || location_save === undefined
    || ask_to_call === undefined
    || contact_phone === undefined
    || user_name === undefined
    || delivery_frame_id_logistic === undefined
    || template_date_frame_type_id === undefined
  ) {
    if(env == 'development'){
      console.error('Error creating <Logistic> model. Logistic not found.');
    }
  }

  return {
    location: safeString(location),
    preferredDate: safeDate(date_pickup || date_pickup_logistic).getTime(),
    hasToSaveLocation: !!location_save,
    hasToCallClient: !!ask_to_call,
    creatorName: safeString(user_name),
    telephone: safeString(contact_phone),
    timePeriod: safeNumber(delivery_frame_id_logistic),
    templateDateRule: safeNumber(template_date_frame_type_id)
  };
};

const createSmartFileFromServer = ({ pickup_files_id, file_name, pickup_id }: FileFromServer): SmartFile => ({
  id: safeString(pickup_files_id),
  name: safeString(file_name),
  url: API.DOWNLOAD_FILE_GET.replace(':pickupId', safeString(pickup_id)).replace(':fileName', safeString(file_name))
});

const createSmartFileListFromServer = (files: unknown, attachedType: PickupAttachedFile): SmartFile[] => {
  if (!Array.isArray(files)) {
    console.error('Error creating <SmartFile[]> model. Not array found.');
    return [];
  }

  return (files as FileFromServer[])
    .filter(file => file?.file_category_id === attachedType)
    .map(createSmartFileFromServer)
    .filter(f => !!f);
};

const createCommentaryFromServer = ({ comments,
  files,
  reference_number_id,
  reference_save }: PickupRequestFromServer): Commentary => ({
  additionalComment: safeString(comments),
  attachedFiles: files ? createSmartFileListFromServer(files, PickupAttachedFile.COMMENTARY) : [],
  bookingReference: safeString(reference_number_id),
  hasToSaveBookinReference: !!reference_save
});

const createDriverInformationFromServer = ({ files, driver_comments }: PickupRequestFromServer): DriverInfo => ({
  attachedFiles: files ? createSmartFileListFromServer(files, PickupAttachedFile.DRIVER_INFO) : [],
  additionalComment: driver_comments === 'null' ? '' : safeString(driver_comments)
});

const createAddonFromServer = ({ addons_content_id,
  addons_id,
  addons_name,
  container_addons_type_id,
  value }: AddonFromServer): ContainerAddon => {
  if (
    addons_content_id === undefined
    || addons_id === undefined
    || addons_name === undefined
    || container_addons_type_id === undefined
  ) {
    console.error('Error creating <ContainerAddon> model.');
    return undefined;
  }

  return {
    addonId: safeString(addons_id),
    defaultValue: getAddonValue(safeNumber(container_addons_type_id), value),
    id: safeString(addons_content_id),
    name: safeString(addons_name),
    options: [],
    type: safeNumber(container_addons_type_id)
  };
};

const createAddonListFromServer = (addonsFromServer: AddonFromServer[]): ContainerAddon[] => {
  if (!Array.isArray(addonsFromServer)) {
    if(env == 'development'){
      console.error('Error creating <ContainerAddon[]> model.');
    }
    return [];
  }

  return addonsFromServer.map(createAddonFromServer).filter(a => !!a);
};

const createMaterialDetailsFromServer = ({ pickup_content_id,
  container_id,
  pickup_date_material,
  number_of_containers_to_deliver,
  destination,
  veva_documentation,
  veva,
  material_id,
  material_name,
  number_of_containers_to_pick,
  delivery_frame_id_material,
  addons }: MaterialDetailsFromServer,
pickupDate: unknown): MaterialDetails | undefined => {
  if (pickup_content_id === undefined) {
    console.error('Error creating <MaterialDetails> model.');
    return undefined;
  }

  return {
    id: safeString(pickup_content_id),
    containerId: safeString(container_id),
    date: safeDate(pickup_date_material || pickupDate).getTime(),
    deliverContainers: safeNumber(number_of_containers_to_deliver),
    destination: safeString(destination),
    handleDocumentation: !!veva_documentation,
    isVeva: !!veva,
    materialId: safeString(material_id),
    materialName: safeString(material_name),
    pickupContainers: safeNumber(number_of_containers_to_pick),
    timeSlot: safeNumber(delivery_frame_id_material),
    addons: createAddonListFromServer(addons)
  };
};

export const createMaterialDetailsListFromServer = (materialList: unknown, pickupDate: unknown): MaterialDetails[] => {
  if (!Array.isArray(materialList)) {
    console.error('Error creating <MaterialDetails[]> model. Not array found.');
    return [];
  }
  return (materialList as MaterialDetailsFromServer[])
    .map(m => createMaterialDetailsFromServer(m, pickupDate)).filter(m => !!m);
};
const createDriverOrdersFromServer = ({ truck_type,
  pickup_id,
  driver_order_id,
  delivery_date,
  delivery_frame_id,
  diffDriverOrderExecutionDate,
  driver_order_status_id, }: DriverOrdersFromServer): DriverOrders | undefined => {
  if (delivery_date === undefined) {
    console.error('Error creating <driverOrders> model.');
    return undefined;
  }
  return {
    driverOrderContent: safeString(truck_type),
    deliveryDate: delivery_date === '' ? '' : safeString(delivery_date)
      .replace(/-/g, '.')
      .slice(0, 11).split('.')
      .reverse()
      .join('.'),
    deliveryFrameId: safeNumber(delivery_frame_id),
    pickupId: safeString(pickup_id),
    driveOrderStatusId: safeNumber(driver_order_status_id),
    driverOrderId: safeString(driver_order_id),
    isdriverOrderExecutionDate: !!diffDriverOrderExecutionDate
  };
};
export const createDriverOrdersListFromServer = (driverOrdersList: DriverOrdersFromServer): DriverOrders[] => {
  if (!Array.isArray(driverOrdersList)) {
    if(env == 'development'){
      console.error('Error creating <MaterialDetails[]> model. Not array found.');
    }
    return [];
  }
  return (driverOrdersList as DriverOrdersFromServer[])
    .map(m => createDriverOrdersFromServer(m)).filter(m => !!m);
};

export const createPickupRequestFromServer = (
  pickup: PickupRequestFromServer,
  isTemplate = false
): PickupRequest | undefined => {
  if (!isTemplate && (!pickup || pickup.pickup_id === undefined || pickup.status_id === undefined)) {
    console.error('Error creating <PickupRequest> model.');
    return undefined;
  }

  return {
    id: safeString(pickup.pickup_id),
    createdDate: safeDate(pickup.date_created).getTime(),
    // TODO: Remove this conditional when backend fix the output
    company: pickup.client
      ? createCompanySimpleFromServer(pickup.client)
      : { id: safeString(pickup.client_id), name: '' },
    // TODO: Remove this conditional when backend fix the output
    owner: pickup.user_client
      ? createCustomerFromServer(pickup.user_client)
      : createCustomerFromClientFromServer(pickup.client),
    status: safeNumber(pickup.status_id),
    logistic: createLogisticFromServer(pickup),
    materials: createMaterialDetailsListFromServer(
      pickup.material, (pickup.date_pickup || pickup.date_pickup_logistic)
    ),
    driverOrders: createDriverOrdersListFromServer(pickup.driver_details),
    commentary: createCommentaryFromServer(pickup),
    driverInformation: createDriverInformationFromServer(pickup),
    PDFUrl: safeString(pickup.pdf_url) || undefined,
    isWishDateSame: !!(pickup.diffDriverOrderExecutionDate)
  };
};

export const createPickupRequestListFromServer = (pickupList: PickupRequestFromServer[]): PickupRequest[] => {
  if (!Array.isArray(pickupList)) {
    console.error('Error creating <PickupRequest[]> model. Not array found.');
    return [] as PickupRequest[];
  }

  return pickupList.map(pickup => createPickupRequestFromServer(pickup)).filter(p => !!p);
};
