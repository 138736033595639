import React, { memo } from 'react';
import { useTranslationsContext } from 'app/translations';
import { RendererProps } from 'components/Table/types';
import Tag from 'components/Tag';

import { PRIMARY, TAG_BACKGROUND } from 'base/styles/skin';
import { DeliverySlipStatus } from 'containers/DeliverySlips/models';

interface Props<R> extends RendererProps<R> {
  color?: string;
}
const TableTag = <R extends unknown>({ cell, color }: Props<R>): React.ReactElement | null => {
  const translations = useTranslationsContext();
  let tagColor = TAG_BACKGROUND;
  let label = '';
  switch (cell) {
    case DeliverySlipStatus.NEW:
      tagColor = PRIMARY;
      label = translations.tag_new;
      break;
    case DeliverySlipStatus.APPROVED:
      label = translations.tag_approved;
      break;
    default:
      tagColor = TAG_BACKGROUND;
  }
  return label && <Tag label={ label } color={ color || tagColor } />;
};
export default memo(TableTag);
