import { getOffsetPagination } from 'app/utils/pagination';
import * as api from '../api';
import { ActionTypes, BillActionType } from '../actionTypes';
import { ExportData } from '../types';

export const getBillList = (page: number, pageSize: number, filterValue = '0'): BillActionType => ({
  type: ActionTypes.GET_BILL_LIST_REQUEST,
  request: api.getBillList(getOffsetPagination(page, pageSize), pageSize, filterValue),
  requestName: 'get-bills'
});

export const getBill = (id: string, docType: string): BillActionType => ({
  type: ActionTypes.GET_BILL_REQUEST,
  request: api.getBill(id, docType),
  requestName: 'get-bill'
});

export const resetBills = (): BillActionType => ({
  type: ActionTypes.RESET_BILLS
});

export const getBillsExportRequest = (exportData: ExportData): BillActionType => ({
  type: ActionTypes.GET_BILLS_EXPORT_DATA_REQUEST,
  request: api.getExportDataRequest(exportData),
  requestName: 'export-data-request'
});

export const getExportBalanceRequest = (exportData: ExportData): BillActionType => ({
  type: ActionTypes.GET_BILLS_EXPORT_DATA_REQUEST,
  request: api.getExportBalanceRequest(exportData),
  requestName: 'export-balance-request'
});

export const printBill = (linkId: string): BillActionType => ({
  type: ActionTypes.PRINT_BILL_REQUEST,
  request: api.printBill(linkId),
  requestName: 'print-bill'
});
