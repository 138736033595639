import * as Yup from 'yup';
import { LangKeys } from 'app/translations';
import * as validations from 'app/constants/validations';
import { ConstraintGroup } from 'containers/Users/types';
import { FieldName } from '../types';

export const createValidationSchema = (
  { isAdmin, isSuperUser }: ConstraintGroup,
  translations: LangKeys
): Yup.ObjectSchema => {
  const clientValidation = {
    [FieldName.companyId]: Yup.string().required()
  };

  const roleValidation = {
    [FieldName.role]: Yup.number().required()
  };

  let extraValidations = {};

  if (isAdmin || isSuperUser) {
    if (isAdmin) {
      extraValidations = {
        ...extraValidations,
        ...clientValidation
      };
    }

    extraValidations = {
      ...extraValidations,
      ...roleValidation
    };
  }

  return Yup.object().shape({
    [FieldName.firstName]: validations.firstName(translations),
    [FieldName.lastName]: validations.lastName(translations),
    [FieldName.email]: validations.email(translations),
    [FieldName.phoneNumber]: validations.phoneNumberOptional(translations),
    ...extraValidations
  });
};
