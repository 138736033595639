import { http, envConf } from 'base';
import { OnboardingRequest } from '../types';

export const onboardingRequest = async (request: OnboardingRequest): Promise<void> => {
  try {
    await http.post(envConf.API.ONBOARDING_POST, request);
  } catch (error) {
    console.error(error);
    throw typeof error === 'string' ? new Error(error) : error;
  }
};
