/* eslint-disable max-lines */
import { Formik, FormikValues, FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { isValidGroup } from 'app/utils/validations';
import Buttons from 'components/Buttons';
import { flattenObject } from 'app/utils/fields';
import { useIsLoading } from 'app/hooks';
import { resetForm } from './actions';
import { updateFormStatus } from './actions/form';
import reducer from './reducers/form';
import Field from './components';
import { ButtonProps } from '../Touchable/types';
import { CHECKBOX, SWITCH } from './models';
import { getState } from './models/form';
import { FieldProps } from './types';
import { StatusResponse } from '../StatusMessage/types';
import StatusMessage from '../StatusMessage';

interface FormContent {
  Buttons: typeof Buttons;
  controlledButtons: ButtonProps[];
  controlledFields: FieldProps[];
  dirty: boolean;
  Field: typeof Field;
  isEditEnabled: boolean;
  setFieldValue: (name: string, value: any, shouldValidate?: boolean) => void;
  touched: FormikTouched<FormikValues>;
  values: FormikValues;
}

export const updateCollection = (originalCollection: FieldProps[], controlledCollection: FieldProps[]) =>
  originalCollection.map(field => {
    const controlledField = controlledCollection.find(f => f.id === field.id);
    return {
      ...field,
      ...controlledField,
      error: controlledField && controlledField.error ? controlledField.error : field.error,
      model: controlledField && controlledField.model ? controlledField.model : field.model
    };
  });

const getInitialValues = (fields: FieldProps[]): FormikValues =>
  fields.reduce<FormikValues>((initialValues, field) => {
    if (Array.isArray(field.fields)) {
      initialValues[field.id] = [];
    } else if (field.type === CHECKBOX || field.type === SWITCH) {
      initialValues[field.id] = field.isChecked;
    } else {
      initialValues[field.id] = field.model;
    }
    return initialValues;
  }, {});

const mapValuesToField = (field: FieldProps, values: FormikValues): FieldProps => {
  if (field.fields) {
    return {
      ...field,
      fields: field.fields.map(f => {
        return mapValuesToField(f, values);
      })
    };
  }

  if (field.type === CHECKBOX || field.type === SWITCH) {
    return {
      ...field,
      error: undefined,
      isChecked: values[field.id]
    };
  }

  return {
    ...field,
    error: undefined,
    model: values[field.id]
  };
};

const mapValuesToFields = (fields: FieldProps[], values: FormikValues) =>
  fields.map(field => mapValuesToField(field, values));

interface Props {
  buttons: ButtonProps[];
  children: (props: FormContent) => React.ReactNode;
  defaultEnabled?: boolean;
  fields: FieldProps[];
  initialValues?: FormikValues;
  form: string;
  handleSubmit: (fields: FieldProps[]) => void;
  isFetching?: boolean;
  reinitialize?: boolean;
  statusResponse?: StatusResponse;
  submitRequireDirty?: boolean;
  validate?: (values: FormikValues) => FormikErrors<FormikValues>;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
  validateOnMount?: boolean;
  validateRequireDirty?: boolean;
  validationSchema?: Yup.ObjectSchema<object>;
  handleErrors?: (values: any) => void;
  handleTouched?: (values: any) => void;
}

// eslint-disable-next-line max-lines-per-function
const SmartForm: React.FC<Props> = ({ buttons,
  children,
  defaultEnabled = true,
  fields,
  initialValues,
  form,
  handleErrors,
  handleSubmit,
  handleTouched,
  isFetching = false,
  reinitialize = false,
  submitRequireDirty = true,
  statusResponse,
  validate,
  validateOnBlur = true,
  validateOnChange = false,
  validateOnMount = true,
  validateRequireDirty = true,
  validationSchema }) => {
  const [{ canBeLocked, hasPermissions, isEditEnabled }, stateDispatch] = React.useReducer(reducer, getState());
  const smartInitialValues = initialValues || getInitialValues(fields);
  const isLoadingPickup = useIsLoading(['create-pickup-request']);
  const dispatch = useDispatch();

  React.useEffect(() => {
    // Reset form data
    dispatch(resetForm({ form }));

    // Set initial form status
    stateDispatch(
      updateFormStatus({
        canBeLocked: false,
        hasPermissions: true,
        isEditEnabled: !!defaultEnabled
      })
    );
  }, []);

  const onSubmit = (values: FormikValues): void => {
    if (isEditEnabled && (!canBeLocked || (canBeLocked && hasPermissions))) {
      handleSubmit(mapValuesToFields(fields, values));
    }
  };

  const toggleEnabledState = () => {
    stateDispatch(
      updateFormStatus({
        isEditEnabled: !isEditEnabled
      })
    );
  };
  const toggleButton: ButtonProps = {
    buttonType: isEditEnabled ? undefined : 'primary',
    icon: isEditEnabled ? 'close' : 'edit',
    id: 'toggle',
    isDisabled: canBeLocked && !hasPermissions,
    label: isEditEnabled ? 'Cancel' : 'Edit',
    onClick: toggleEnabledState,
    type: 'button'
  };

  return (
    <Formik
      initialValues={ smartInitialValues }
      validate={ validate }
      validationSchema={ validationSchema }
      onSubmit={ onSubmit }
      enableReinitialize={ reinitialize }
      validateOnBlur={ validateOnBlur }
      validateOnChange={ validateOnChange }
      validateOnMount={ validateOnMount }
    >
      { /* eslint-disable @typescript-eslint/no-unused-vars */
        ({ errors,
          handleBlur,
          handleChange,
          handleSubmit: handleFormikSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
          dirty,
          status,
          isValid }) => {
        // Workarounds for error messages in Pickup request form
          const formErrors = form === 'request' ? flattenObject(errors) : errors;
          const formTouched = form === 'request' ? flattenObject(touched) : touched;

          if (handleErrors) {
            handleErrors(errors);
          }

          if (handleTouched) {
            handleTouched(touched);
          }

          const isErrored = (b?: ButtonProps) =>
            (!b || (b && Array.isArray(b.fieldDependencies)))
          && (!validateRequireDirty || (validateRequireDirty && dirty))
          && (!isValidGroup(errors, b.fieldDependencies) || !isValid);

          return (
          /* eslint-enable @typescript-eslint/no-unused-vars */
            <>
              { statusResponse && <StatusMessage { ...statusResponse } /> }
              <form id={ form } name={ form } onSubmit={ handleFormikSubmit } noValidate={ true }>
                { children({
                  Buttons,
                  controlledButtons: buttons.map(b => ({
                    ...b,
                    isAlwaysDisabled: isLoadingPickup,
                    isAlwaysEnabled: !isFetching && isValid,
                    isDisabled:
                    (isSubmitting && b.type === 'submit')
                    || (submitRequireDirty && !dirty)
                    || isErrored(b)
                    || b.isDisabled
                    || !isEditEnabled
                    || isFetching
                    || (b.type === 'submit' && ((submitRequireDirty && !dirty) || !isValid))
                  })),
                  controlledFields: fields.map(f => ({
                    ...f,
                    isDisabled: f.isDisabled || !isEditEnabled || isFetching,
                    onBlur: handleBlur,
                    error: (!validateRequireDirty || (validateRequireDirty && formTouched[f.id])) && formErrors[f.id],
                    fields: f.fields?.map(fi => ({
                      ...fi,
                      isDisabled: f.isDisabled || !isEditEnabled || isFetching
                    }))
                  })),
                  dirty,
                  Field,
                  isEditEnabled,
                  setFieldValue,
                  touched,
                  values
                }) }

                { canBeLocked && <Buttons options={ [toggleButton] } /> }
              </form>
            </>
          );
        } }
    </Formik>
  );
};

export default SmartForm;
