import styled from 'styled-components';
import { textStyles } from 'containers/Home/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;
Wrapper.displayName = 'Wrapper';

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 0;
  justify-content: space-between;
`;
Line.displayName = 'Line';

export const Title = styled.div`
  ${ textStyles }
  font-weight: bold;
`;
Title.displayName = 'Title';

interface TextProps {
  textAlign?: string;
}

export const Text = styled.div`
  ${ textStyles }
  text-align: ${ ({ textAlign = 'left' }: TextProps) => textAlign };
  .rightContent{
    display: inline-block;
    min-Width: 120px;
  }
  .leftContent{
         min-Width: 60px;
         text-align: right;
         display: inline-block
  }
`;
Text.displayName = 'Text';
