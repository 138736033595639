import { MOCK_API } from 'app/constants/localStorage';
import * as commonConfig from '../../env/env';
import * as dev from '../../env/env.development';
import * as pre from '../../env/env.preproduction';
import * as prod from '../../env/env.production';
import { env } from './env';

let conf;
switch (env) {
  case 'development':
    conf = dev;
    break;

  case 'pre':
    conf = pre;
    break;

  default:
    conf = prod;
}

export const envConf = { ...commonConfig, ...conf };

export const isMockingApi = () => envConf.getBaseUrl() === MOCK_API;
