import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { NavLink } from 'react-router-dom';
import {USER, HOME} from 'app/routes';
import Hamburger from 'components/Hamburger';
import { getLoginData } from 'containers/Login/selectors';
import { useTranslationsContext } from 'app/translations';
import { isAvagID } from 'app/utils/permissions';
import { SidebarItem } from '../../types';
import { Wrapper, HeaderBar, HeaderSpacer, Side, Logo, HeaderButton, WelcomeMessage, Text, Name } from './styles';
import ClientSwitch from "containers/Core/data/clientswitch";
import {useCompaniesListRequest, useCompaniesReset} from "containers/Companies/hooks";
import {getCompanyList} from "containers/Companies/selectors";
import {CompanyList} from "containers/Companies/types";
import {Option} from "components/SmartForm/types";
import * as companyActions from 'containers/Companies/actions';

const logo = require('app/assets/images/avag.svg');

interface Props {
  handleClick: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  logout: SidebarItem;
}

export const createOptionFromCompanyListRequest = (companyList: CompanyList): Option => ({
  id: companyList.id,
  label: companyList.id + ' ' + companyList.name,
  value: companyList.id,
});

const Header: React.FC<Props> = ({ handleClick, isOpen, logout }) => {
  const user = useSelector(getLoginData);
  const { billDetails_customerIdLabel } = useTranslationsContext();
  const isAvag = isAvagID(user.client_id);
  const dispatch = useDispatch();

  useCompaniesReset();
  useCompaniesListRequest(user.user_id);

  const collection = useSelector(getCompanyList);
  const itemList = React.useMemo(() => {
    return collection.map(createOptionFromCompanyListRequest);
  }, [collection]);

  const hasMultipleClients = itemList.length > 1 && true;

  const handleClientSwitch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if(newValue && user.user_id){
      dispatch(companyActions.changeCompany({user_id: user.user_id, client_id: newValue, current_client_id: user.client_id}));
    }
  }, []);

  return (
    <Wrapper>
      <HeaderBar>
        <Side style={ { flex: 1 } }>
          <NavLink to={ HOME.path }>
            <Logo src={ logo } />
          </NavLink>
        </Side>
        { user && (
          <>
            <WelcomeMessage style={ { minWidth: '250px' } }>
              <ClientSwitch
                  onChange={ handleClientSwitch }
                  options={ { id: 'client_switcher', type: 'dropdown', options: itemList, placeholder: 'Kunden-Nr. wechseln' } }
                  hasMultipleClients={ hasMultipleClients }
              />
            </WelcomeMessage>
            <WelcomeMessage>
              <Text>{ billDetails_customerIdLabel }</Text>
              <Text>{ isAvag ? user.client_name || 'AVAG Admin' : user.client_id }</Text>
            </WelcomeMessage>
            <WelcomeMessage>
              <Text>Welcome</Text>
              <Name to={ USER.path.replace(':id', user.user_id) }>
                { user.first_name } { user.last_name }
              </Name>
            </WelcomeMessage>
          </>
        ) }
        <Side>
          <HeaderButton to={ logout.to }>{ logout.icon }</HeaderButton>
          <Hamburger handleClick={ handleClick } isOpen={ isOpen } />
        </Side>
      </HeaderBar>

      <HeaderSpacer />
    </Wrapper>
  );
};

export default Header;
