import { LangKeys } from 'app/translations';
import { Column } from 'components/Table/types';
import { renderers } from 'components/Table';
import { DeliverySlipRow } from 'containers/DeliverySlips/types';
import TableTag from '../components/Tag';

export const createColumns = (translations: LangKeys): Column<DeliverySlipRow>[] => [
  {
    key: 'date',
    label: translations.deliverySlip_date,
    renderer: renderers.Date,
    mobile: true
  },
  {
    key: 'id',
    label: translations.deliverySlip_id,
    mobile: true
  },
  {
    key: 'bookingReference',
    label: translations.deliverySlip_bookingReference
  },
  {
    key: 'attachments',
    label: translations.deliverySlip_attachments
  },
  {
    key: 'status',
    label: translations.deliverySlip_status,
    mobile: true,
    renderer: TableTag
  },
  {
    key: 'navigate',
    mobile: true
  }
];
