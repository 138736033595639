import React, { useCallback } from 'react';
import Searcher from 'components/Searcher';
import { SEARCH } from 'components/SmartForm/models';
import debounce from 'app/utils/debounce';

interface Props {
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputFilter: React.FC<Props> = ({ onChange, placeholder }) => {
  const [value, setValue] = React.useState<string>();
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setValue(event.target.value);
      debounce(() => {
        onChange(event);
      });
    },
    [onChange]
  );

  return (
    <Searcher
      onBlur={ () => {} }
      onChange={ handleChange }
      onClick={ () => {} }
      onFocus={ () => {} }
      value={ value }
      options={ {
        id: 'filter',
        type: SEARCH,
        placeholder
      } }
    />
  );
};
export default InputFilter;
