import {CompanySimple, Company, CompanyList} from 'containers/Companies/types';

export const COMPANY_SIMPLE_LIST: CompanySimple[] = [
  {
    id: '123123',
    name: 'Nestle'
  },
  {
    id: '234234',
    name: 'Cognizant'
  },
  {
    id: '345345',
    name: 'atSistemas'
  }
];
export const COMPANY_SWITCH_LIST: CompanyList[] = [
  {
    id: '123123',
    name: 'Nestle'
  },
  {
    id: '234234',
    name: 'Cognizant'
  },
  {
    id: '345345',
    name: 'atSistemas'
  }
];

export const COMPANY_LIST: Company[] = [
  {
    id: '123123',
    name: 'Cognizant',
    customers: [
      {
        id: '123',
        firstName: 'Johan',
        lastName: ' Bieli',
        phoneNumber: ''
      },
      {
        id: '456',
        firstName: 'Tiefchner',
        lastName: ' Kreis',
        phoneNumber: ''
      },
      {
        id: '789',
        firstName: 'Anna',
        lastName: ' Bergström',
        phoneNumber: ''
      }
    ],
    permissions: {
      portal: true,
      requestPickup: true,
      accessBills: true
    }
  },
  {
    id: '234234',
    name: 'Cognizant',
    customers: [],
    permissions: {
      portal: true,
      requestPickup: false,
      accessBills: true
    }
  },
  {
    id: '345345',
    name: 'Cognizant',
    customers: [],
    permissions: {
      portal: false,
      requestPickup: false,
      accessBills: false
    }
  }
];
