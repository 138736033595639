import styled from 'styled-components';

import { DESKTOP } from 'base/styles/media-queries';
import { SIDEBAR_SIZE_DOCKED, HEADER_SIZE, FOOTER_SPACE, FOOTER_SIZE } from 'base/styles/sizes';
import getLayerPosition from 'base/styles/z-index';
import { PRIMARY_BACKGROUND } from 'base/styles/skin';

export const AppWrapper = styled.div`
  background-color: ${ PRIMARY_BACKGROUND };
  flex: 1;
  min-height: calc(100vh - ${ HEADER_SIZE + FOOTER_SPACE + FOOTER_SIZE }px);
  z-index: ${ getLayerPosition('CONTENT') };
`;

export const Content = styled.div`
  display: block;
  height: 100%;
  width: 100%;

  ${ DESKTOP } {
    padding-left: ${ SIDEBAR_SIZE_DOCKED }px;
  }
`;
