/* eslint-disable max-lines */
import { Field as FormikField, FieldProps as FormikFieldProps } from 'formik';
import * as React from 'react';

import Buttons from 'components/Buttons';
import FileUploader from './fileUploader';
import Captcha from './captcha';
import Checkbox from './checkbox';
import DatePicker from './datepicker';
import Dropdown from './dropdown';
import Switch from './switch';
import Text from './text';

import { TEXT,
  SEARCH,
  EMAIL,
  NUMBER,
  PASSWORD,
  TEXTAREA,
  SWITCH,
  CHECKBOX,
  DROPDOWN,
  DATE,
  BUTTON,
  SUBMIT,
  CAPTCHA,
  FILE,
  RADIO } from '../models';

import { FieldProps, InputElement } from '../types';
import Radio from './radio';

const onClick = (event: React.MouseEvent<InputElement>, options: FieldProps) => {
  if (options.onClick) {
    options.onClick(event);
  }
};

const onFocus = (event: React.FocusEvent<InputElement>, options: FieldProps) => {
  if (options.onFocus) {
    options.onFocus(event);
  }
};

const onChange = (event: React.ChangeEvent<InputElement>, options: FieldProps) => {
  if (options.onChange) {
    options.onChange(event);
  }
};

const onBlur = (event: React.FocusEvent<InputElement>, options: FieldProps) => {
  if (options.onBlur) {
    options.onBlur(event);
  }
};

interface RenderWrappedFieldProps {
  Component:
    | typeof Text
    | typeof Switch
    | typeof Checkbox
    | typeof Dropdown
    | typeof DatePicker
    | typeof Captcha
    | typeof Buttons;
  props: any;
  options: FieldProps;
}

const renderWrappedField = ({ Component, props, options }: RenderWrappedFieldProps) => {
  const styles = {
    wrapper: {
      display: props.options.maxWidth ? 'inline-block' : 'block',
      maxWidth: props.options.maxWidth,
      verticalAlign: 'top'
    } as React.CSSProperties
  };

  return (
    <span key={ options.updateId || options.id } style={ styles.wrapper }>
      <FormikField id={ options.id } name={ options.id }>
        { ({ field, form }: FormikFieldProps) => {
          const newProps = {
            ...props,
            ...field,
            ...form
          };

          if (props.options.type === CHECKBOX || props.options.type === SWITCH || props.options.type === CAPTCHA) {
            newProps.options.isChecked = !!field.value;
          }

          return (
            <Component
              { ...newProps }
              onChange={ (event: React.ChangeEvent<InputEvent>) => {
                props.onChange(event);
                field.onChange(event);
              } }
              onBlur={ (event: React.ChangeEvent<InputEvent>) => {
                props.onBlur(event);
                field.onBlur(event);
              } }
            />
          );
        } }
      </FormikField>
    </span>
  );
};

interface Props {
  options: FieldProps;
}

// eslint-disable-next-line max-lines-per-function
const Field: React.FC<Props> = ({ options }) => {
  const parsedOptions = {
    ...options,
    isDisabled: (!options.isAlwaysEnabled && options.isDisabled) || options.isAlwaysDisabled
  };
  switch (parsedOptions.type) {
    case TEXT:
    case EMAIL:
    case NUMBER:
    case PASSWORD:
    case TEXTAREA:
    case SEARCH:
      return renderWrappedField({
        Component: Text,
        props: {
          onChange: (event: React.ChangeEvent<InputElement>) => onChange(event, options),
          onClick: (event: React.MouseEvent<InputElement>) => onClick(event, options),
          onFocus: (event: React.FocusEvent<InputElement>) => onFocus(event, options),
          onBlur: (event: React.FocusEvent<InputElement>) => onBlur(event, options),
          options: parsedOptions
        },
        options
      });

    case SWITCH:
      return renderWrappedField({
        Component: Switch,
        props: {
          onChange: (event: React.ChangeEvent<InputElement>) => onChange(event, options),
          onClick: (event: React.MouseEvent<InputElement>) => onClick(event, options),
          onFocus: (event: React.FocusEvent<InputElement>) => onFocus(event, options),
          onBlur: (event: React.FocusEvent<InputElement>) => onBlur(event, options),
          options: parsedOptions
        },
        options
      });

    case CHECKBOX:
      return renderWrappedField({
        Component: Checkbox,
        props: {
          onChange: (event: React.ChangeEvent<InputElement>) => onChange(event, options),
          onClick: (event: React.MouseEvent<InputElement>) => onClick(event, options),
          onFocus: (event: React.FocusEvent<InputElement>) => onFocus(event, options),
          onBlur: (event: React.FocusEvent<InputElement>) => onBlur(event, options),
          options: parsedOptions
        },
        options
      });

    case RADIO:
      return renderWrappedField({
        Component: Radio,
        props: {
          onChange: (event: React.ChangeEvent<InputElement>) => onChange(event, options),
          onClick: (event: React.MouseEvent<InputElement>) => onClick(event, options),
          onFocus: (event: React.FocusEvent<InputElement>) => onFocus(event, options),
          onBlur: (event: React.FocusEvent<InputElement>) => onBlur(event, options),
          options: parsedOptions
        },
        options
      });

    case DROPDOWN:
      return renderWrappedField({
        Component: Dropdown,
        props: {
          onChange: (event: React.ChangeEvent<InputElement>) => onChange(event, options),
          onClick: (event: React.MouseEvent<InputElement>) => onClick(event, options),
          onFocus: (event: React.FocusEvent<InputElement>) => onFocus(event, options),
          onBlur: (event: React.FocusEvent<InputElement>) => onBlur(event, options),
          options: parsedOptions
        },
        options
      });

    case DATE:
      return renderWrappedField({
        Component: DatePicker,
        props: {
          onChange: (event: React.ChangeEvent<InputElement>) => onChange(event, options),
          onClick: (event: React.MouseEvent<InputElement>) => onClick(event, options),
          onFocus: (event: React.FocusEvent<InputElement>) => onFocus(event, options),
          onBlur: (event: React.FocusEvent<InputElement>) => onBlur(event, options),
          options: parsedOptions
        },
        options
      });

    case CAPTCHA:
      return renderWrappedField({
        Component: Captcha,
        props: {
          onChange: (event: React.ChangeEvent<InputElement>) => onChange(event, options),
          onClick: (event: React.MouseEvent<InputElement>) => onClick(event, options),
          onFocus: (event: React.FocusEvent<InputElement>) => onFocus(event, options),
          onBlur: (event: React.FocusEvent<InputElement>) => onBlur(event, options),
          options: {
            ...parsedOptions,
            isRequired: true
          }
        },
        options
      });
    case FILE:
      return renderWrappedField({
        Component: FileUploader,
        props: {
          onChange: (event: React.ChangeEvent<InputElement>) => onChange(event, options),
          onClick: (event: React.MouseEvent<InputElement>) => onClick(event, options),
          onFocus: (event: React.FocusEvent<InputElement>) => onFocus(event, options),
          onBlur: (event: React.FocusEvent<InputElement>) => onBlur(event, options),
          options: parsedOptions
        },
        options
      });

    case BUTTON:
    case SUBMIT:
      return renderWrappedField({
        Component: Buttons,
        props: {
          align: 'center',
          options: [parsedOptions]
        },
        options
      });

    default:
      return null;
  }
};

export default Field;
