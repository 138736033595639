import { PickupRequestStatus } from 'app/constants/pickupRequest';
import { Option } from 'components/SmartForm/types';
import { Customer } from 'containers/Companies/types';

export type CancelAction = () => void;
export type CloseAction = () => void;
export type PrintAction = (isDirty: boolean) => () => void;
export type RemoveAction = () => void;
export type SaveAction = (status: PickupRequestStatus) => void;
export type SaveTemplate = () => void;
export type ActionTemplate = () => void;
export type TAConnectionFailureAction = () => void;
export type ResendAction = (status: PickupRequestStatus) => void;

export type SetFieldValue = (field: string, value: boolean | string) => void;

export interface CreatorProps {
  buttonActions: {
    handleClose: CloseAction;
    handleAbort: CancelAction;
    handlePrint: PrintAction;
    handleRemove: RemoveAction;
    handleSave: SaveAction;
    handleResend: ResendAction;
    handleSaveTemplate?: SaveTemplate;
    handleDeleteTemplate?: ActionTemplate;
    handleUpdateTemplate?: ActionTemplate;
    handleTAConnectFailAction?: TAConnectionFailureAction;
  };
}

export enum AddonsFieldName {
  addonId = 'addonId',
  defaultValue = 'defaultValue',
  id = 'id',
  name = 'name',
  options = 'options',
  type = 'type'
}

export enum MaterialFieldName {
  containerId = 'containerId',
  containerName = 'containerName',
  addons = 'addons',
  materialId = 'materialId',
  canPickup = 'permissions.collect',
  pickupContainers = 'pickupContainers',
  canDeliver = 'permissions.deliver',
  deliverContainers = 'deliverContainers',
  destination = 'destination',
  date = 'date',
  timeSlot = 'timeSlot',
  handleDocumentation = 'handleDocumentation'
}

export enum DriverOrderFieldName {
  driverOrderId = 'driverOrderId',
  driverOrderContent = 'driverOrderContent',
  deliveryDate = 'deliveryDate',
  deliveryFrameId = 'deliveryFrameId',
  driveOrderStatusId = 'driveOrderStatusId',
}

export enum FieldName {
  companyId = 'company.id',
  companyName = 'company.name',
  ownerId = 'owner.id',
  ownerName = 'owner.name',
  location = 'logistic.location',
  timePeriod = 'logistic.timePeriod',
  preferredDate = 'logistic.preferredDate',
  hasToCallClient = 'logistic.hasToCallClient',
  telephone = 'logistic.telephone',
  hasToSaveLocation = 'logistic.hasToSaveLocation',
  materials = 'materials',
  bookingReference = 'commentary.bookingReference',
  hasToSaveBookinReference = 'commentary.hasToSaveBookinReference',
  additionalComments = 'commentary.additionalComment',
  attachedFiles = 'commentary.attachedFiles',
  additionalDriverComments = 'driverInformation.additionalComment',
  attachedDriverFiles = 'driverInformation.attachedFiles',
  creatorName = 'logistic.creatorName',
  saveTemplate = 'templateActionDetails.saveTemplate',
  templateName = 'templateActionDetails.templateName',
  templateDateRule = 'templateActionDetails.templateDateRule',
  templateDateRuleNewTemplate = 'logistic.templateDateRule',
  templatePlaceOrderDate = 'templateActionDetails.templatePlaceOrderDate',
  driverOrders = 'driverOrders',
}

export enum MODALS {
  ABORT = 'ABORT',
  PRINT = 'PRINT',
  DELETE = 'DELETE',
  SAVE_DRAFT = 'SAVE_DRAFT',
  SAVE = 'SAVE',
  COMPLETE = 'COMPLETE',
  SAVE_TEMPLATE = 'SAVE_TEMPLATE',
  TEMPLATE_DELETE = 'TEMPLATE_DELETE',
  TEMPLATE_UPDATE = 'TEMPLATE_UPDATE',
  TA_CONNECTION_FAILURE = 'TA_CONNECTION_FAILURE',
}

export interface ModalData {
  description: string;
  dismissLabel?: string;
  confirmLabel: string;
  title: string;
}

export interface ModalProps extends ModalData {
  openModal: MODALS;
  onConfirm: () => void;
  onDismiss: () => void;
}

export interface DropdownOptions {
  companyOptions: Option<string>[];
  customerOptions: Option<string>[];
}

export type RequestContainerList = (companyId: string) => void;
export type RequestMaterialList = (containerId: string) => void;
export type RequestCustomerlList = (companyId: string) => Promise<Customer[]>;
export type CompanySelection = (companyId: string) => void;

export interface RequestHandler {
  containerList: RequestContainerList;
  customerList: RequestCustomerlList;
}
