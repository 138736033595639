import React from 'react';
import { useTranslationsContext } from 'app/translations';
import AvagDot from 'components/AvagDot';
import TableTag from 'containers/DeliverySlips/containers/DeliverySlipList/components/Tag';
import { DeliverySlipStatus } from 'containers/DeliverySlips/models';
import { HelpTopic } from 'containers/Help/types';
import HelpButton from 'components/HelpButton';
import { Title } from '../../styles';
import { Container, CustomWrapperTitle } from './styles';

interface Props {
  id: string;
  status: DeliverySlipStatus;
}

const Header: React.FC<Props> = ({ id, status }) => {
  const { deliverySlipDetails_title } = useTranslationsContext();
  return (
    <Container>
      <CustomWrapperTitle>
        <AvagDot />
        <Title>
          { deliverySlipDetails_title }: { id }
        </Title>
        <TableTag cell={ status } />
      </CustomWrapperTitle>
      <HelpButton topic={ HelpTopic.DELIVERY_SLIPS } />
    </Container>
  );
};

export default Header;
