import * as React from 'react';

import Content from '../../../Touchable';
import { Wrapper, HiddenInput, ErrorMessage, Label } from '../styles';
import { CheckboxWrapper, CustomCheckbox, CheckboxText } from './styles';

import { FieldProps } from '../../types';

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  setFieldValue: (field: string, value: boolean) => void;
  value?: boolean;
  options: FieldProps;
}

const Checkbox: React.FC<Props> = ({ onChange, onClick, onFocus, setFieldValue, value = false, options }) => {
  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    onClick(event);
    setFieldValue(options.id, !value);
  };

  return (
    <Wrapper>
      <Label htmlFor={ options.id }>
        <HiddenInput
          id={ options.id }
          name={ options.id }
          checked={ value }
          value={ value.toString() }
          disabled={ options.isDisabled }
          onClick={ handleClick }
          onChange={ onChange }
          onFocus={ onFocus }
          required={ options.isRequired }
          type="checkbox"
        />

        <CheckboxWrapper>
          <CustomCheckbox isDisabled={ options.isDisabled } />
          <Content options={ { ...options, label: '' } } />
          <CheckboxText>{ options?.label }</CheckboxText>
        </CheckboxWrapper>
        { options.error && <ErrorMessage>{ options.error }</ErrorMessage> }
      </Label>
    </Wrapper>
  );
};

export default Checkbox;
