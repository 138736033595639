import * as Yup from 'yup';
import * as validations from 'app/constants/validations';
import { useTranslationsContext } from 'app/translations';

export const useDatesValidationSchema = (): Yup.ObjectSchema => {
  const translations = useTranslationsContext();

  return Yup.object().shape({
    startDate: validations.startDate(translations, 'endDate'),
    endDate: validations.endDate(translations, 'startDate')
  });
};
