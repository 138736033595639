/* eslint-disable max-len */
/* eslint-disable max-params */
import { FormikValues } from 'formik';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LangKeys, useTranslationsContext } from 'app/translations';
import { RouteWithID } from 'app/types';
import { createOptionsFromEntity } from 'app/utils/fields';
import { sortBy } from 'app/utils/sorting';
import { FieldProps } from 'components/SmartForm/types';
import { ButtonProps } from 'components/Touchable/types';
import { getCompanySimpleList, getCompanyCustomers } from 'containers/Companies/selectors';
import { getLoginData } from 'containers/Login/selectors';
import { getPickupStatus } from 'containers/PickupRequest/selectors';
import { ConstraintGroup, Entity } from 'containers/PickupRequest/types';
import { isAvagID } from 'app/utils/permissions';
import { CreatorProps, DropdownOptions } from 'app/containers/PickupRequest/containers/PickupRequestDetails/types';
import { createPickupRequestActionButtons } from './buttons';
import { createFields } from './fields';
import { createValidationSchema } from './validation';

interface CreateFormDataProps {
  translations: LangKeys;
  constraintGroup: ConstraintGroup;
  dropdownOptions: DropdownOptions;
}

interface FormData {
  buttons: ButtonProps[];
  fields: FieldProps[];
  validationSchema?: Yup.ObjectSchema;
}

// eslint-disable-next-line max-len
const createFormData = (props: CreateFormDataProps, { buttonActions }: CreatorProps, newTemplate?: boolean, loadTemplateDetails?: boolean): FormData => ({
  buttons: createPickupRequestActionButtons(props.translations, props.constraintGroup, buttonActions, newTemplate, loadTemplateDetails),
  fields: createFields(props.translations, props.constraintGroup, props.dropdownOptions),
  validationSchema: createValidationSchema(props.translations, props.constraintGroup)
});

export const useFormData = (props: CreatorProps, values: FormikValues, newTemplate?: boolean, loadTemplateDetails?: boolean): FormData => {
  const translations = useTranslationsContext();
  const translationsLength = Object.keys(translations).length;
  const { id } = useParams<RouteWithID>();
  const { role } = useSelector(getLoginData);
  const companyId = values?.company?.id || 'undefined';
  const companyList = useSelector(getCompanySimpleList).map(c => {
    if (isAvagID(c.id)) {
      return c;
    }

    return {
      id: c.id,
      name: `${ c.name } - ${ c.id }`
    };
  });

  const customerList = useSelector(getCompanyCustomers(companyId)).map(c => ({
    id: c.id,
    name: `${ c.firstName } ${ c.lastName } - ${ c.phoneNumber || translations.pickupRequestDetails_noContactPhone }`
  }));

  const status = useSelector(getPickupStatus(id));

  const constraintGroup: ConstraintGroup = {
    role,
    status
  };

  return useMemo<FormData>(() => {
    const companyOptions = createOptionsFromEntity<Entity>(companyList).sort(sortBy('label', 'asc'));
    const customerOptions = createOptionsFromEntity<Entity>(customerList).sort(sortBy('label', 'asc'));

    const dropdownOptions: DropdownOptions = {
      companyOptions,
      customerOptions
    };

    const formProps = { translations, constraintGroup, dropdownOptions };
    return createFormData(formProps, props, newTemplate, loadTemplateDetails);
  }, [translationsLength, companyList.length, customerList, status]);
};
