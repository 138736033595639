import { safeString } from 'app/utils/safeTypes';
import { createCompanyPermissionsFromServer } from 'containers/Users/models';
import {
  Company,
  CompanyFromServer, CompanyList,
  CompanySimple,
  CompanySimpleFromServer, CompanySwitchListFromServer,
  Customer,
  CustomerFromServer
} from '../types';

export const createCompanySimpleFromServer = ({ client_id,
  client_name }: CompanySimpleFromServer): CompanySimple | undefined => {
  if (client_id === undefined || client_name === undefined) {
    console.error('Error creating <CompanySimple> model');

    return undefined;
  }

  return {
    id: safeString(client_id),
    name: safeString(client_name)
  };
};

export const createCompanySimpleListFromServer = (companyList: CompanySimpleFromServer[]): CompanySimple[] => {
  if (!Array.isArray(companyList)) {
    console.error('Error creating <CompanySimple[]> model. Not array found.');
    return [];
  }

  return companyList.map(createCompanySimpleFromServer).filter(p => !!p);
};


export const createCompanySwitchFromServer = ({ client_id, client_name }: CompanySwitchListFromServer): CompanyList | undefined => {
  if (client_id === undefined) {
    console.error('Error creating <CompanyList> model');

    return undefined;
  }

  return {
    id: safeString(client_id),
    name: safeString(client_name)
  };
};

export const createCompanySwitchListFromServer = (companyList: CompanySwitchListFromServer[]): CompanyList[] => {
  if (!Array.isArray(companyList)) {
    console.error('Error creating <CompanyList[]> model. Not array found.');
    return [];
  }

  return companyList.map(createCompanySwitchFromServer).filter(p => !!p);
};

interface ClientFromServer {
  client_id?: unknown;
  client_name?: unknown;
}

export const createCustomerFromClientFromServer = ({ client_id, client_name }: ClientFromServer): Customer => {
  if (client_id === undefined || client_name === undefined) {
    console.error('Error creating <Customer> model');
    return undefined;
  }

  const [firstName, ...others] = safeString(client_name).split(' ');

  return {
    id: safeString(client_id),
    firstName,
    lastName: others.join(' ')
  };
};

export const createCustomerFromServer = ({ first_name,
  last_name,
  user_id,
  phone }: CustomerFromServer): Customer | undefined => {
  if (user_id === undefined || first_name === undefined) {
    console.error('Error creating <Customer> model');
    return undefined;
  }

  return {
    id: safeString(user_id),
    firstName: safeString(first_name),
    lastName: safeString(last_name),
    phoneNumber: safeString(phone)
  };
};

const createCustomerListFromServer = (customerList: CustomerFromServer[]): Customer[] => {
  if (!Array.isArray(customerList)) {
    console.error('Error creating <CustomerFromServer[]> model. Not array found.');
    return [];
  }

  return customerList.map(createCustomerFromServer).filter(c => !!c);
};

export const createCompanyFromServer = ({ client_id,
  client_name,
  customers,
  portal_enable,
  pickup_request_enable,
  bills_enable }: CompanyFromServer): Company | undefined => {
  if (client_id === undefined || client_name === undefined) {
    console.error('Error creating <Company> model');
    return undefined;
  }

  return {
    id: safeString(client_id),
    name: safeString(client_name),
    customers: createCustomerListFromServer(customers),
    permissions: createCompanyPermissionsFromServer({ portal_enable, pickup_request_enable, bills_enable })
  };
};

export const createCompanyListFromServer = (companyList: CompanyFromServer[]): Company[] => {
  if (!Array.isArray(companyList)) {
    console.error('Error creating <Company[]> model. Not array found.');
    return [];
  }

  return companyList.map(createCompanyFromServer).filter(p => !!p);
};
