import React from 'react';
import { formatDate } from 'app/utils/formatters';
import { ArrowButton } from 'components/Table/renderers';
import Tag from 'containers/Notifications/components/Tag';
import { Notification, NOTIFICATION_STATUS } from 'containers/Notifications/types';
import { Wrapper, DateCell, NotificationCell, TagCell, NavigationCell, ResponsiveRows } from './styles';

interface Props {
  item: Notification;
}
const ItemRow: React.FC<Props> = ({ item }) => (
  <Wrapper>
    <ResponsiveRows>
      <DateCell>{ formatDate(new Date(item.date)) }</DateCell>
      <NotificationCell>{ item.notification }</NotificationCell>
    </ResponsiveRows>

    { item.status === NOTIFICATION_STATUS.NEW && (
      <TagCell>
        <Tag cell={ item.status } />
      </TagCell>
    ) }

    <NavigationCell>
      <ArrowButton />
    </NavigationCell>
  </Wrapper>
);

export default ItemRow;
