import React from 'react';

import { Container, Icon } from './styles';

interface Props {
  icon: string;
  onClickHandler: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const IconButton: React.FC<Props> = ({ onClickHandler, icon }) => {
  return (
    <Container type="button" onClick={ onClickHandler }>
      <Icon src={ icon } />
    </Container>
  );
};

export default IconButton;
