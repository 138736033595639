import { ROLE } from 'app/constants/roles';

interface User {
  id: string;
  role: ROLE;
}

interface LoggedUser {
  user_id: string;
  user_role: ROLE;
}

export const canEditUser = ({ id, role }: User, { user_id, user_role }: LoggedUser) =>
  user_role === ROLE.AVAG_ADMIN || (user_role === ROLE.CLIENT_SUPER_USER && role !== ROLE.AVAG_ADMIN) || user_id === id;
