import produce from 'immer';
import { createReducer, isMockingApi } from 'base';
import { ActionHandler } from 'base/types';
import { ActionTypes as LogoutActions } from 'containers/Login/actionTypes';
import { ActionTypes, BookingRefsActionType } from '../actionTypes';
import { BookingRefsState, BookingRef } from '../types';
import { bookingrefsInitialState } from '../models';
import { BOOKING_REF_LIST } from '../mocks';

const getBookingRefsSuccess = (state: BookingRefsState, action: BookingRefsActionType) =>
  produce(state, draft => {
    const collection = action.payload as BookingRef[];
    draft.collection = collection;
  });

const getBookingRefsError = (state: BookingRefsState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.collection = BOOKING_REF_LIST;
    }
  });

const getInitialState = (state: BookingRefsState) =>
  produce(state, draft => {
    Object.keys(draft).forEach(key => {
      // @ts-ignore
      draft[key] = bookingrefsInitialState[key];
    });
  });

const actionHandlers: ActionHandler<BookingRefsState> = {
  [ActionTypes.GET_BOOKING_REFS_SUCCESS]: getBookingRefsSuccess,
  [ActionTypes.GET_BOOKING_REFS_ERROR]: getBookingRefsError,
  [LogoutActions.LOGOUT_REQUEST]: getInitialState
};

export default createReducer(bookingrefsInitialState, actionHandlers);
