import { useTranslationsContext } from 'app/translations';
import { TEMPLATE_MODALS, ModalData, TEMPLATE_TYPE } from '../../types';

export const getTemplateModalData = (openModal: TEMPLATE_MODALS, onDismiss: () => void, onConfirm?: any): ModalData => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const translations = useTranslationsContext();
  switch (openModal) {
    case TEMPLATE_MODALS.TEMPLATE_DELETE:
      return {
        modalType: TEMPLATE_TYPE.deleteTemplate,
        title: translations.template_modal_delete_title,
        description: translations.template_modal_delete_description,
        onDismiss,
        onConfirm
      };
    case TEMPLATE_MODALS.TEMPLATE_UPDATE:
      return {
        modalType: TEMPLATE_TYPE.updateTemplate,
        title: translations.template_modal_update_title,
        description: translations.template_modal_update_description,
        onDismiss,
        onConfirm

      };
    case TEMPLATE_MODALS.SAVE_AS_NEW_TEMPLATE:
      return {
        modalType: TEMPLATE_TYPE.saveAsTemplate,
        title: translations.template_save_as,
        description: translations.template_save_as_new,
        onDismiss,
        onConfirm
      };
    case TEMPLATE_MODALS.TEMPLATE_PLACE_ORDER:
      return {
        modalType: TEMPLATE_TYPE.processOrderTemplate,
        title: translations.pickupRequestDetails_placeOrderButton,
        description: translations.template_place_order_description,
        onDismiss,
        onConfirm
      };

    default:
      return {
        modalType: '',
        title: '',
        description: '',
      };
  }
};
