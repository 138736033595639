import { useMemo } from 'react';
import { useTranslationsContext, LangKeys } from 'app/translations';
import { Column } from 'components/Table/types';
import { HandleCheckbox, CompaniesRow } from '../../types';
import { createColumns } from './table';

interface TableData {
  columns: Column<CompaniesRow>[];
}

const createTableData = (translations: LangKeys, handleCheckboxChange: HandleCheckbox): TableData => ({
  columns: createColumns(translations, handleCheckboxChange)
});

export const useTableData = (handleCheckboxChange: HandleCheckbox): TableData => {
  const translations = useTranslationsContext();
  const translationsSize = Object.keys(translations).length;
  const memoizedTableData = useMemo<TableData>(() => createTableData(translations, handleCheckboxChange), [
    translationsSize
  ]);
  return memoizedTableData;
};
