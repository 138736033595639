import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SETTINGS } from 'app/routes';
import { PropsWithWrapper } from 'app/types';
import Redirect from 'components/Redirect';
import CompanyList from './containers';

const Companies: React.FC<PropsWithWrapper<{}>> = ({ wrapper }) => (
  <Switch>
    <Route exact={ true } path={ SETTINGS.path } component={ wrapper(CompanyList) } />
    <Redirect to={ SETTINGS.path } />
  </Switch>
);

export default Companies;
