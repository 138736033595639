import { CREATE_URL } from 'app/constants/routes';
import { LangKeys } from 'app/translations';

export interface RouteInfo {
  path: string;
  displayLabel: keyof LangKeys;
}

export const ROOT: RouteInfo = {
  path: '/',
  displayLabel: 'routeName_root'
};

export const LOGOUT: RouteInfo = {
  path: '/logout',
  displayLabel: 'routeName_logout'
};

export const RESET_PASSWORD: RouteInfo = {
  path: '/reset-password/:token',
  displayLabel: 'routeName_resetPassword'
};

export const FORGOT_PASSWORD: RouteInfo = {
  path: '/forgot-password',
  displayLabel: 'routeName_forgotPassword'
};

export const HOME: RouteInfo = {
  path: '/home',
  displayLabel: 'routeName_home'
};

export const QUOTAS: RouteInfo = {
  path: '/quotas',
  displayLabel: 'routeName_quotas'
};

export const NOTIFICATIONS: RouteInfo = {
  path: '/notifications',
  displayLabel: 'routeName_notifications'
};

export const ONBOARDING: RouteInfo = {
  path: '/onboarding',
  displayLabel: 'routeName_onboarding'
};

export const PICKUP_REQUESTS_LIST: RouteInfo = {
  path: '/pickup-requests',
  displayLabel: 'routeName_pickUpRequests'
};

export const NEW_PICKUP_REQUEST: RouteInfo = {
  path: `${ PICKUP_REQUESTS_LIST.path }/${ CREATE_URL }`,
  displayLabel: 'routeName_newPickupRequest'
};
export const TEMPLATE_LIST: RouteInfo = {
  path: `${ PICKUP_REQUESTS_LIST.path }/templates`,
  displayLabel: 'routeName_TemplateRequest',
};
export const NEW_TEMPLATE_REQUEST: RouteInfo = {
  path: `${ TEMPLATE_LIST.path }/${ CREATE_URL }`,
  displayLabel: 'routeName_newTemplateRequest'
};
export const TEMPLATE_DETAILS: RouteInfo = {
  path: `${ TEMPLATE_LIST.path }/:id`,
  displayLabel: 'routeName_TemplateDetails'
};

export const PICKUP_REQUEST: RouteInfo = {
  path: `${ PICKUP_REQUESTS_LIST.path }/:id`,
  displayLabel: 'routeName_pickUpRequest'
};

export const DELIVERY_SLIP_LIST: RouteInfo = {
  path: '/delivery-slips',
  displayLabel: 'routeName_deliverySlips'
};

export const DELIVERY_SLIP: RouteInfo = {
  path: `${ DELIVERY_SLIP_LIST.path }/:id/:docNo/:versionNo`,
  displayLabel: 'routeName_deliverySlip'
};

export const BILL_LIST: RouteInfo = {
  path: '/bills',
  displayLabel: 'routeName_bills'
};

export const BILL: RouteInfo = {
  path: `${ BILL_LIST.path }/:id/:docType`,
  displayLabel: 'routeName_bill'
};

export const STATISTICS: RouteInfo = {
  path: '/statistics',
  displayLabel: 'routeName_statistics'
};

export const USERS: RouteInfo = {
  path: '/users',
  displayLabel: 'routeName_users'
};

export const NEW_USER: RouteInfo = {
  path: `${ USERS.path }/${ CREATE_URL }`,
  displayLabel: 'routeName_newUser'
};

export const USER: RouteInfo = {
  path: `${ USERS.path }/:id`,
  displayLabel: 'routeName_user'
};

export const SETTINGS: RouteInfo = {
  path: '/settings',
  displayLabel: 'routeName_settings'
};

export const HELP: RouteInfo = {
  path: '/help',
  displayLabel: 'routeName_help'
};

export const PRIVACY_AGREEMENT_INFO: RouteInfo = {
  path: '/privacy-agreement',
  displayLabel: 'routeName_privacyAgreement'
};
