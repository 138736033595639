import { useMemo } from 'react';
import { useTranslationsContext, LangKeys } from 'app/translations';
import { Column } from 'components/Table/types';
import { BillRow } from 'containers/Bills/types';
import { createColumns } from './table';

interface TableData {
  columns: Column<BillRow>[];
}

const createTableData = (translations: LangKeys): TableData => ({
  columns: createColumns(translations)
});

export const useTableData = (): TableData => {
  const translations = useTranslationsContext();
  const translationsSize = Object.keys(translations).length;
  const memoizedTableData = useMemo<TableData>(() => createTableData(translations), [translationsSize]);
  return memoizedTableData;
};
