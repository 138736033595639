import styled from 'styled-components';
import { TABLET, DESKTOP } from 'base/styles/media-queries';
// TODO adapt styles based on size

export const CompanyWrapper = styled.div`
  vertical-align: top;

  ${ DESKTOP } {
    width: 50%;
    padding-right: 8px;
  }
`;
CompanyWrapper.displayName = 'CompanyWrapper';

export const CustomerWrapper = styled.div`
  vertical-align: top;

  ${ DESKTOP } {
    display: inline-block;
    width: 50%;
    padding-right: 8px;
  }
`;
CustomerWrapper.displayName = 'CustomerWrapper';

interface LocationProps {
  fullWidth?: boolean;
}

export const LocationWrapper = styled.div`
  vertical-align: top;

  ${ DESKTOP } {
    display: inline-block;
    width: ${ ({ fullWidth }: LocationProps) => (fullWidth ? '100%' : '50%') };
    //padding-left: 8px;
  }
`;
LocationWrapper.displayName = 'LocationWrapper';

export const DateWrapper = styled.div`
  vertical-align: top;

  ${ TABLET } {
    display: inline-block;
    width: 50%;
    padding-right: 8px;
  }
`;
DateWrapper.displayName = 'DateWrapper';

export const TimeWrapper = styled.div`
  vertical-align: top;

  ${ TABLET } {
    display: inline-block;
    width: 50%;
    padding-left: 8px;
  }
`;
TimeWrapper.displayName = 'TimeWrapper';

export const PhoneWrapper = styled.div`
  vertical-align: top;

  display: flex;
  flex-direction: column;
  width: 100%;

  ${ TABLET } {
    flex-direction: row;
  }
`;
PhoneWrapper.displayName = 'PhoneWrapper';

export const PhoneLabel = styled.div`
  flex: 1;
  margin-right: 16px;
`;
PhoneLabel.displayName = 'PhoneLabel';

export const PhoneField = styled.div`
  ${ TABLET } {
    flex: 2;
  }
`;
PhoneField.displayName = 'PhoneField';

export const HelpButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  ${ DESKTOP } {
    display: none;
  }
`;
HelpButtonWrapper.displayName = 'HelpButtonWrapper';

export const TemplateCreatorDetails = styled.div`
  flex: 1;
  padding:20px 0px 0px 0px;
  margin-right: 16px;
    font-size: 14px;
    font-weight: 500;
    color:#666;
`;
TemplateCreatorDetails.displayName = 'TemplateCreatorDetails';
