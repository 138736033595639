import { WEIGHT_CONVERSION_RATIO, MIN_BAR_WIDTH } from 'app/constants/statistic';
import { Statistic, DataFilterValues, StatisticData, ChartMetadata } from 'containers/Statistics/types';
import { formatDate } from '../formatters';
import { isWeightChart } from './filterables';

export const getMinimumBarWidth = (width = 1) => {
  const computedWidth = width / WEIGHT_CONVERSION_RATIO;
  return computedWidth > MIN_BAR_WIDTH ? computedWidth : MIN_BAR_WIDTH;
};

const merge = (
  collection: StatisticData[],
  item: StatisticData,
  compareFn: (item: StatisticData) => boolean
): StatisticData[] => {
  const newCollection = [...collection];
  const itemIndex = newCollection.findIndex(compareFn);

  if (itemIndex < 0) {
    newCollection.push(item);
  } else {
    const prevItem = newCollection[itemIndex];
    const updatedItem = {
      ...item,
      expected: [prevItem.expected[0] + item.expected[0], prevItem.expected[1] + item.expected[1]],
      values: [prevItem.values[0] + item.values[0], prevItem.values[1] + item.values[1]]
    };

    newCollection[itemIndex] = updatedItem;
  }

  return newCollection;
};

export const getDataGroupedByDate = (data: StatisticData[] = []): StatisticData[] =>
  data.reduce(
    (acc, curr) => merge(acc, curr, record => formatDate(new Date(record.date)) === formatDate(new Date(curr.date))),
    [] as StatisticData[]
  );

export const getDataGroupedByMaterial = (data: StatisticData[] = []): StatisticData[] =>
  data.reduce(
    (acc, curr) => merge(acc, curr, record => record.material.name === curr.material.name),
    [] as StatisticData[]
  );

export const getMaxAndMinDimensions = (statisticData: StatisticData[], metricUnits: string[]): ChartMetadata =>
  statisticData.reduce(
    (prev, curr) => {
      const isWeight = isWeightChart(metricUnits);
      let { minCost: negative, maxCost: positive } = prev;
      const height = curr.values[0] + curr.expected[0];
      const width = curr.values[1];

      if (height >= 0 && height > prev.maxCost) {
        positive = height;
      } else if (height < 0 && height < prev.minCost) {
        negative = height;
      }

      let { maxWeight } = prev;
      if (isWeight) {
        if (width > maxWeight) {
          maxWeight = width;
        }
      } else {
        maxWeight += getMinimumBarWidth(width) + 2;
      }

      return {
        ...prev,
        minCost: negative,
        maxCost: positive,
        maxWeight
      };
    },
    { minCost: 0, maxCost: 0, maxWeight: 0 }
  );

export const getTotalDimension = (statisticData: Statistic, filters: DataFilterValues) => {
  const selectedDimension = filters.dimensions.findIndex(d => d);

  return statisticData.data.reduce((acc, curr) => {
    let totalValuesAcc = acc;
    totalValuesAcc += Math.abs(curr.values[selectedDimension]);
    return totalValuesAcc;
  }, 0);
};
