import React from 'react';

import { Container, Text } from './styles';

interface Props {
  label: string;
  color?: string;
}

const Tag: React.FC<Props> = ({ label, color }) => (
  <Container color={ color }>
    <Text>{ label }</Text>
  </Container>
);

export default Tag;
