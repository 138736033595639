import React from 'react';
import * as Yup from 'yup';
import { isMockingApi } from 'base';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIsLoading } from 'app/hooks';
import { useTranslationsContext, LangKeys } from 'app/translations';
import SimpleLayout from 'containers/Core/components/SimpleLayout';
import * as validations from 'app/constants/validations';
import Buttons from 'components/Buttons';
import SmartForm from 'components/SmartForm';
import { EMAIL } from 'components/SmartForm/models';
import { FieldProps } from 'components/SmartForm/types';
import { ButtonProps } from 'components/Touchable/types';
import { StatusResponse, SeverityType } from 'components/StatusMessage/types';
import { ROOT } from 'app/routes';
import SectionTitle from 'components/SectionTitle';
import { changePasswordRequest } from './actions';
import { Description, Description1 } from './styles';


import BackToLoginButton from '../Onboarding/components/BackToLoginButton';

const createValidationSchema = (translations: LangKeys): Yup.ObjectSchema =>
  Yup.object().shape({
    user_email: validations.email(translations)
  });

const ForgotPassword: React.FC = () => {
  const [forgotPwdError, setForgotPwdError] = React.useState('');
  const [forgotPwdSuccess, setForgotPwdSuccess] = React.useState(false);
  const translations = useTranslationsContext();
  const validationSchema = createValidationSchema(translations);
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useIsLoading(['change-password']);

  const fields: FieldProps[] = [
    {
      id: 'user_email',
      isRequired: true,
      label: translations.forgotPassword_emailLabel,
      placeholder: translations.forgotPassword_emailPlaceholder,
      type: EMAIL
    }
  ];

  const buttons: ButtonProps[] = [
    {
      id: 'forgotPwdCancel',
      type: 'button',
      buttonType: 'primary',
      isAlwaysEnabled: true,
      onClick: () => history.push(ROOT.path),
      label: translations.forgotPassword_cancelLabel
    },
    {
      id: 'forgotPwdSubmit',
      type: 'submit',
      buttonType: 'primary',
      label: translations.forgotPassword_submitLabel
    }
  ];

  const handleSubmit = async (controlledFields: FieldProps[]) => {
    const email = controlledFields.find(field => field.id === 'user_email')?.model || '';
    const { type } = await dispatch(changePasswordRequest(email));
    if (isMockingApi() || type.includes('SUCCESS')) {
      setForgotPwdSuccess(true);
    } else {
      setForgotPwdError(translations.forgotPassword_errorMessage);
    }
  };

  const statusResponse: StatusResponse = {
    severity: SeverityType.ERROR,
    description: forgotPwdError
  };

  return (
    <SimpleLayout>
      <SectionTitle>{ translations.forgotPassword_title }</SectionTitle>
      { !forgotPwdSuccess ? (
        <>
         
          <Description>{ translations.forgotPassword_description }  </Description>
          <Description1> { translations.forgotpassword_description1 } </Description1>
          <SmartForm
            form={ 'forgot-password' }
            buttons={ buttons }
            fields={ fields }
            isFetching={ isLoading }
            statusResponse={ forgotPwdError && statusResponse }
            handleSubmit={ handleSubmit }
            validationSchema={ validationSchema }
            validateOnChange={ true }
          >
            { ({ controlledButtons, controlledFields, Field }) => (
              <>
                { controlledFields.map(field => (
                  <Field key={ field.id } options={ field } />
                )) }
                <Buttons options={ controlledButtons } />
              </>
            ) }
          </SmartForm>
        </>
      ) : (
        <>
          <Description>{ translations.forgotPassword_decriptionSuccess }
            { translations.forgotPassword_linkLoginText }
          </Description>
          <BackToLoginButton></BackToLoginButton>
          { /* <CustomLink to={ ROOT.path }>{ translations.forgotPassword_linkLoginText }</CustomLink> , CustomLink
           <CustomLink to={ RESET_PASSWORD.path }>TODO: Delete this link: Go to reset password.</CustomLink> */ }
        </>
      ) }
    </SimpleLayout>
  );
};

export default ForgotPassword;
