import React, { PropsWithChildren } from 'react';
import AvagDot from 'components/AvagDot';
import { WrapperTitle, Title } from './styles';

interface Props {
  className?: string;
}

const SectionTitle: React.FC<PropsWithChildren<Props>> = ({ children, className }) => (
  <WrapperTitle className={ className }>
    <AvagDot />
    <Title className={ className } >{ children }</Title>
  </WrapperTitle>
);

export default SectionTitle;
