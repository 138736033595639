/* eslint-disable max-len */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
// eslint-disable-next-line max-len
import { PICKUP_REQUESTS_LIST, PICKUP_REQUEST, NEW_PICKUP_REQUEST, TEMPLATE_DETAILS, TEMPLATE_LIST, NEW_TEMPLATE_REQUEST } from 'app/routes';
import { PropsWithWrapper } from 'app/types';
import Redirect from 'components/Redirect';
import { canDo } from '../Core/selectors';
import PickupRequestDetails from './containers/PickupRequestDetails';
import PickupRequestList from './containers/PickupRequestList';
import TemplateList from './containers/Templates/containers/TemplateList';
import TemplateDetails from './containers/Templates/containers/TemplateDetails';

const renderFallback = () => <Redirect to={ PICKUP_REQUESTS_LIST.path } />;

const PickupRequest: React.FC<PropsWithWrapper<{}>> = ({ wrapper }) => {
  const canCreate = useSelector(canDo('CREATE_PICKUPS'));

  return (
    <Switch>
      <Route exact={ true } path={ TEMPLATE_LIST.path } component={ wrapper(TemplateList) } />
      <Route exact={ true } path={ NEW_TEMPLATE_REQUEST.path } component={ wrapper(TemplateDetails) } />
      <Route exact={ true } path={ TEMPLATE_DETAILS.path } component={ wrapper(TemplateDetails, true, { loadTemplateDetails: true }) } />
      <Route exact={ true } path={ TEMPLATE_LIST.path } component={ wrapper(TemplateList) } />
      <Route
        exact={ true }
        path={ NEW_PICKUP_REQUEST.path }
        component={ canCreate ? wrapper(PickupRequestDetails) : renderFallback }
      />
      <Route exact={ true } path={ PICKUP_REQUEST.path } component={ wrapper(PickupRequestDetails) } />
      <Route exact={ true } path={ PICKUP_REQUESTS_LIST.path } component={ wrapper(PickupRequestList) } />
      { renderFallback() }
    </Switch>
  );
};

export default PickupRequest;
