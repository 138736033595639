import React from 'react';
import { FieldArray, FormikErrors, FormikValues, FormikTouched } from 'formik';
import { useTranslationsContext } from 'app/translations';
import { IndexedFields } from 'app/types';
import { createIndexedFields } from 'app/utils/fields';
import Buttons from 'components/Buttons';
import SectionTitle from 'components/SectionTitle';
import { ButtonProps } from 'components/Touchable/types';
import { createMaterialRow } from 'containers/PickupRequest/models';
import { PickupRequest, Container, ContainerPermission } from 'containers/PickupRequest/types';
import { FieldName, MaterialFieldName, SetFieldValue } from '../../types';
import ContainerModal from '../ContainerModal';
import MaterialRow from './components/MaterialRow';
import { CardContent, ButtonsWrapper } from './styles';

interface Props {
  fields: IndexedFields<FieldName>;
  formErrors?: FormikErrors<FormikValues>;
  formTouched?: FormikTouched<FormikValues>;
  isLoading?: boolean;
  values: PickupRequest;
  onSelectContainer: (field: string, container?: Container, containerId?: string) => SetFieldValue;
}

const Materials: React.FC<Props> = ({ fields, formErrors, formTouched, isLoading, onSelectContainer, values }) => {
  const [showContainersModal, setShowContainersModal] = React.useState(false);
  const [permissions, setPermissions] = React.useState<{ [key: string]: ContainerPermission }>({});
  const [fieldId, setFieldId] = React.useState('');
  const [matIndex, setMatIndex] = React.useState(-1);
  const translations = useTranslationsContext();
  const { materials } = fields;

  const createAddMaterialRowBtn = (onAdd: Function): ButtonProps[] => [
    {
      id: 'add_row',
      type: 'button',
      buttonType: 'primary',
      label: translations.pickupRequestDetails_addMaterialRowLabel,
      onClick: () => onAdd(createMaterialRow(values.logistic.preferredDate))
    }
  ];

  const handleFieldsPermission = (field: string, container: Container) => {
    setPermissions({
      ...permissions,
      [container.id]: container.permissions
    });

    const setFieldValue = onSelectContainer(field, container);
    const { collected, delivered } = container.permissions;

    setFieldValue(`materials.${ matIndex }.permissions.collect`, collected);
    if (!collected) {
      setFieldValue(`materials.${ matIndex }.pickupContainers`, '0');
    }

    setFieldValue(`materials.${ matIndex }.permissions.deliver`, delivered);
    if (!delivered) {
      setFieldValue(`materials.${ matIndex }.deliverContainers`, '0');
    }
  };

  const handleShowContainersModal = (selectedField: string, index: number) => {
    setFieldId(selectedField);
    setShowContainersModal(true);
    setMatIndex(index);
  };

  const handleCloseContainersModal = () => {
    setShowContainersModal(false);
  };

  return (
    <>
      <SectionTitle>{ translations.pickupRequestDetails_materialsTitle }</SectionTitle>
      <CardContent indentation={ false }>
        <FieldArray name={ materials?.id }>
          { ({ remove, push }) => (
            <>
              { values.materials.map((material, index) => {
                const materialFields = createIndexedFields<MaterialFieldName>(materials.fields, materials.id, index);
                const handleRemove = () => remove(index);

                return (
                  <MaterialRow
                    key={ index }
                    errorKeys={ formErrors }
                    fields={ materialFields }
                    onOpenContainerModal={ (selectedField: string) => {
                      handleShowContainersModal(selectedField, index);
                    } }
                    onRemove={ !isLoading && values.materials.length > 1 && handleRemove }
                    permissions={ permissions[material.containerId] }
                    selectedCompanyId={ values.company.id }
                    touchedKeys={ formTouched }
                    values={ material }
                  />
                );
              }) }

              { !isLoading && (
                <ButtonsWrapper>
                  <Buttons options={ createAddMaterialRowBtn(push) } />
                </ButtonsWrapper>
              ) }
            </>
          ) }
        </FieldArray>
      </CardContent>

      { showContainersModal && (
        <ContainerModal
          handleClose={ handleCloseContainersModal }
          handleSelect={ (container: Container) => {
            handleFieldsPermission(fieldId, container);
          } }
        />
      ) }
    </>
  );
};

export default Materials;
