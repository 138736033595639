import { safeString } from 'app/utils/safeTypes';
import { BookingRefsState, BookingRef, BookingRefFromServer } from '../types';

export const bookingrefsInitialState: BookingRefsState = {
  collection: []
};

export const createBookingRefFromServer = (bookingRef: BookingRefFromServer): BookingRef | undefined => {
  if (bookingRef.account_reference_id === undefined || bookingRef.reference_number_id === undefined) {
    console.error('Error creating <BookingRef> model');

    return undefined;
  }

  return {
    id: safeString(bookingRef?.account_reference_id),
    name: safeString(bookingRef?.reference_number_id)
  };
};

export const createBookingRefListFromServer = (bookingRefList: BookingRefFromServer[]): BookingRef[] => {
  if (!Array.isArray(bookingRefList)) {
    console.error('Error creating <BookingRef[]> model. Not array found.');
    return [];
  }

  return bookingRefList.map(createBookingRefFromServer).filter(p => !!p);
};
