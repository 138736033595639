import React from 'react';
import SectionTitle from 'components/SectionTitle';
import { Description } from './styles';

interface Props {
  title?: string;
  description?: string;
}

const FormHeader: React.FC<Props> = ({ title = '', description }) => (
  <>
    <SectionTitle>{ title }</SectionTitle>
    { description && <Description>{ description }.</Description> }
  </>
);

export default FormHeader;
