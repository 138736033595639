import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;
Container.displayName = 'Container';

export const Description = styled.div`
  font-size: 14px;
`;
Description.displayName = 'Description';

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > * {
    flex: 1;
    margin: 0 8px;
  }
`;
FieldsWrapper.displayName = 'FieldsWrapper';

export const FieldWrapperNoteText = styled.div`
  width: 100%;
  flex: 0;
  color: #3a3a3a;
  font-size:14px;
`;
FieldWrapperNoteText.displayName = 'FieldWrapperNoteText';
