import { createStore, applyMiddleware, Store, Action } from 'redux';
// import { createLogger } from 'redux-logger';
// @ts-ignore no definitions for this library
import reduxReqMiddleware from 'redux-req-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import requestIdentifier from 'base/shared/request-identifier';
import { RootState } from 'base/types';
import { SESSION_TOKEN } from 'app/constants/localStorage';
import * as localStorage from 'app/utils/localStorage';
import { decodeLoginToken } from 'app/utils/encoding';
import { addBearer } from 'containers/Login/api';
import { createUserFromServer } from 'containers/Login/models';
import { rootReducer } from '../reducers';
import { env } from '../shared/env';

export const configureStore = (initialState?: RootState): Store<RootState, Action> => {
  let middleware;
  const commonMiddlewares = [requestIdentifier, reduxReqMiddleware()];

  if (env === 'development') {
    // middleware = applyMiddleware(...commonMiddlewares, createLogger({ level: 'info', collapsed: true }));
    middleware = applyMiddleware(...commonMiddlewares);
    middleware = composeWithDevTools(middleware);
  } else {
    middleware = applyMiddleware(...commonMiddlewares);
  }

  const state = rootReducer({ ...initialState, requests: {} }, { type: 'INIT' });
  const sessionToken = localStorage.getItem(SESSION_TOKEN);
  const userFromServer = sessionToken ? decodeLoginToken(sessionToken) : undefined;
  const store = createStore(
    rootReducer,
    { ...state, login: { user: userFromServer ? createUserFromServer(userFromServer) : state.login.user } },
    middleware
  );

  if (userFromServer) {
    // If there is a valid persisted user, add Auth layer to all requests
    addBearer(sessionToken);
  } else {
    // If there is no valid peristed user, remove it from localStorage
    localStorage.removeItem(SESSION_TOKEN);
  }

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('../reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};
