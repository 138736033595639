import React from 'react';
import { useHistory } from 'react-router-dom';
import { STATISTICS } from 'app/routes';
import { useTranslationsContext } from 'app/translations';
import Buttons from 'components/Buttons';
import { ButtonProps } from 'components/Touchable/types';
import { Wrapper } from './styles';

interface Props {
  isOverview?: boolean;
  isComparing?: boolean;
  onDownload: () => void;
  onCompare: () => void;
}

const Footer: React.FC<Props> = ({ isComparing, isOverview, onCompare }) => {
  const translations = useTranslationsContext();
  const history = useHistory();

  const buttons: ButtonProps[] = [];

  if (isOverview) {
    buttons.push({
      id: 'show-all',
      label: translations.button_show_all,
      onClick: () => {
        history.push(STATISTICS.path);
      },
      type: 'button'
    });
  } else {
    /* TODO enable when ready (AMP-68)
    buttons.push({
      id: 'download-data',
      label: translations.statistics_download,
      onClick: () => {
        onDownload();
      },
      type: 'button',
    });
    */

    // eslint-disable-next-line no-lonely-if
    if (!isComparing) {
      buttons.push({
        id: 'compare-data',
        label: translations.statistics_compare,
        buttonType: 'primary',
        onClick: () => {
          if (onCompare) onCompare();
        },
        type: 'button'
      });
    }
  }

  return (
    <Wrapper>
      <Buttons options={ buttons } />
    </Wrapper>
  );
};

export default Footer;
