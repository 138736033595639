import produce from 'immer';
import { createReducer, isMockingApi } from 'base';
import { ActionHandler } from 'base/types';
import { ActionTypes as LogoutActions } from 'containers/Login/actionTypes';
import { ActionTypes, LocationActionType } from '../actionTypes';
import { LOCATION_LIST } from '../mocks';
import { locationInitialState } from '../models';
import { LocationState, Location } from '../types';

const getLocationSuccess = (state: LocationState, action: LocationActionType) =>
  produce(state, draft => {
    const collection = action.payload as Location[];
    draft.collection = collection || state.collection;
  });

const getLocationError = (state: LocationState) =>
  produce(state, draft => {
    if (isMockingApi()) {
      draft.collection = LOCATION_LIST;
    }
  });

const getInitialState = (state: LocationState) =>
  produce(state, draft => {
    Object.keys(draft).forEach(key => {
      // @ts-ignore
      draft[key] = locationInitialState[key];
    });
  });

const actionHandlers: ActionHandler<LocationState> = {
  [ActionTypes.GET_LOCATION_SUCCESS]: getLocationSuccess,
  [ActionTypes.GET_LOCATION_ERROR]: getLocationError,
  [LogoutActions.LOGOUT_REQUEST]: getInitialState
};

export default createReducer(locationInitialState, actionHandlers);
