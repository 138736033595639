import { safeString, safeNumber, safeDate } from 'app/utils/safeTypes';
import { WEIGHT_UNIT, COST_UNIT } from 'app/constants/statistic';
import { StatisticData } from 'containers/Statistics/types';
import { StatisticFromServer, Statistic } from '../types';

export const createStatisticDataFromServer = ({ Description,
  EntryNo,
  No,
  PostingDate,
  ProductGrpDescription,
  Quantity,
  Quantity_Expected,
  Sales_Amount,
  Sales_Amount_Expected }: StatisticFromServer): StatisticData => {
  if (ProductGrpDescription === undefined || Sales_Amount === undefined || Quantity === undefined) {
    console.error('Error creating <Statistic> model');
    return undefined;
  }

  return {
    id: safeString(EntryNo),
    description: safeString(Description),
    date: safeDate(PostingDate).getTime(),
    material: {
      id: safeString(No),
      // name: safeString(ProductGrpDescription),
      name: safeString(Description)
    },
    expected: [safeNumber(Sales_Amount_Expected), safeNumber(Quantity_Expected)],
    values: [safeNumber(Sales_Amount), safeNumber(Quantity)] // [cost, weight]
  };
};

export const createStatisticDataListFromServer = (statisticFromServer: StatisticFromServer[]): StatisticData[] =>
  statisticFromServer.map(createStatisticDataFromServer).filter(s => !!s);

export const createStatisticFromServer = (
  statisticFromServer: StatisticFromServer[] | StatisticFromServer
): Statistic => {
  if (!Array.isArray(statisticFromServer)) {
    if (statisticFromServer.BaseUnitOfMeasure) {
      return {
        data: createStatisticDataListFromServer([statisticFromServer]),
        metricUnits: [COST_UNIT, safeString(statisticFromServer.BaseUnitOfMeasure, WEIGHT_UNIT)]
      };
    }

    console.error('Error creating <Statistic[]> model. Not array found.');
    return { data: [], metricUnits: [] };
  }

  const statisticWithUnitOfMeasure = statisticFromServer.find(s => s.BaseUnitOfMeasure);

  return {
    data: createStatisticDataListFromServer(statisticFromServer),
    metricUnits: [COST_UNIT, safeString(statisticWithUnitOfMeasure?.BaseUnitOfMeasure, WEIGHT_UNIT)]
  };
};
