import * as React from 'react';

import Content from '../../../Touchable';
import { HiddenInput } from '../styles';
import { StyledWrapper as Wrapper,
  StyledLabel as Label,
  CustomRadio,
  StyledDescription as Description } from './styles';

import { FieldProps } from '../../types';

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  options: FieldProps;
  value?: string;
  setFieldValue: (field: string, value: string) => void;
}

const Radio: React.FC<Props> = ({ onChange, onClick, onFocus, options, value, setFieldValue }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
    setFieldValue(options.id, event.target.value);
  };

  return (
    <Wrapper>
      { options.options.map(opt => (
        <Label key={ opt.id } htmlFor={ `${ options.id }[${ opt.id }]` }>
          <HiddenInput
            id={ `${ options.id }[${ opt.id }]` }
            name={ options.id }
            checked={ `${ opt.value }` === `${ value }` }
            disabled={ options.isDisabled }
            onChange={ handleChange }
            onClick={ onClick }
            onFocus={ onFocus }
            required={ options.isRequired }
            value={ `${ opt.value }` }
            type="radio"
          />

          <Description>
            <CustomRadio />
            <Content options={ opt } />
          </Description>
        </Label>
      )) }
    </Wrapper>
  );
};

export default Radio;
