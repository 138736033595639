import React from 'react';
import Buttons from 'components/Buttons';
import { useHistory } from 'react-router-dom';
import { NEW_PICKUP_REQUEST } from 'app/routes';
import { useTranslationsContext } from 'app/translations';

const CreateButton: React.FC = () => {
  const translations = useTranslationsContext();
  const history = useHistory();

  return (
    <Buttons
      options={ [
        {
          buttonType: 'primary',
          id: 'create',
          label: translations.pickUpRequest_create,
          onClick: () => {
            history.push(NEW_PICKUP_REQUEST.path);
          },
          type: 'button'
        }
      ] }
    />
  );
};

export default CreateButton;
