export const safeString = (value: unknown, defaultValue = ''): string => String(value || defaultValue);

export const safeNumber = (value: unknown, defaultValue = 0): number =>
  (value ? Number(Number(value).toFixed(3)) : defaultValue);

const isValidDate = (d: unknown) => d instanceof Date && !Number.isNaN(d.getTime());

export const safeDate = (value: unknown, defaultValue = new Date()): Date => {
  const date = new Date(value as number | string | Date);

  if (process.env.NODE_ENV === 'test') {
    date.setUTCHours(0, 0, 0, 0);
  }

  return isValidDate(date) ? date : defaultValue;
};
