import { useMemo } from 'react';
import { ROLE } from 'app/constants/roles';
import { useTranslationsContext, LangKeys } from 'app/translations';
import { Column } from 'components/Table/types';
import { PickupRequestRow } from 'containers/PickupRequest/types';
import { createColumns } from './table';

interface TableData {
  columns: Column<PickupRequestRow>[];
}

const createTableData = (translations: LangKeys, role: ROLE): TableData => ({
  columns: createColumns(translations, role)
});

export const useTableData = (role: ROLE): TableData => {
  const translations = useTranslationsContext();
  const translationsSize = Object.keys(translations).length;
  const memoizedTableData = useMemo<TableData>(() => createTableData(translations, role), [translationsSize, role]);
  return memoizedTableData;
};
