import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RouteWithID } from 'app/types';
import { getCompany } from 'containers/Companies/actions';
import { getCompanySimpleList } from 'containers/Companies/selectors';
import { getLoginData } from 'containers/Login/selectors';
import * as actions from '../actions';
import { UserRow } from '../types';
import { getUserList, getUser } from '../selectors';
import { createRowFromUser } from '../models';

export * from './modal';

export const useUserListRequest = (page: number, pageSize: number, filterValue: string) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getUserList(page, pageSize, filterValue));
  }, [filterValue, page]);
};

export const useUserRows = (): UserRow[] => {
  const companies = useSelector(getCompanySimpleList);
  const loggedUser = useSelector(getLoginData);
  const { collection } = useSelector(getUserList);

  return useMemo(
    () =>
      collection.map(user => createRowFromUser(user, loggedUser, companies.find(c => c.id === user.companyId)?.name)),
    [collection]
  );
};

export const useUserListReset = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.resetUserList());
  }, []);
};

export const useUserRequest = (setHasLoadedDetails: (state: boolean) => void, selectedCompanyId?: string) => {
  const dispatch = useDispatch();
  const { id } = useParams<RouteWithID>();
  const user = useSelector(getUser(id));
  const [fetchedUser, setFetchedUser] = useState(false);
  const [fetchedCompany, setFetchedCompany] = useState(false);

  useEffect(() => {
    if (!fetchedUser && id) {
      setFetchedUser(true);

      // @ts-ignore
      dispatch(actions.getUser(id)).then(() => {
        setHasLoadedDetails(true);
      });
    }
  }, [fetchedUser, id]);

  useEffect(() => {
    if (!fetchedCompany) {
      if (selectedCompanyId) {
        setFetchedCompany(true);
        dispatch(getCompany(selectedCompanyId));
      } else if (user?.companyId) {
        setFetchedCompany(true);
        dispatch(getCompany(user?.companyId));
      }
    }
  }, [fetchedCompany, selectedCompanyId, user]);
};
