import styled from 'styled-components';
import { TEXT_COLOR, PRIMARY, TEXT_COLOR1 } from 'base/styles/skin';
import { Link } from 'react-router-dom';

export const Description = styled.div`
  padding: 12px 0;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${ TEXT_COLOR };
`;

Description.displayName = 'Description';
export const Description1 = styled.div`
  padding: 12px 0;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${ TEXT_COLOR1 };
`;
Description.displayName = 'Description1';

export const CustomLink = styled(Link)`
  &,
  &:link,
  &:visited {
    padding: 12px 0;
    font-size: 14px;
    font-weight: 500;
    color: ${ PRIMARY };
    text-decoration: underline;
  }
`;
CustomLink.displayName = 'CustomLink';
