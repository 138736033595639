import { Pagination } from 'app/types';

export enum NOTIFICATION_STATUS {
  OLD = 1,
  NEW
}

export enum NOTIFICATION_TYPE {
  PICKUP,
  DELIVERY,
  BILL,
  USER
}

interface NotificationExtras {
  docNo: string;
  versionNo: string;
  docType: string;
}

export interface Notification {
  id: string;
  date: number;
  notification: string;
  status: number;
  entityType: NOTIFICATION_TYPE;
  entityId: string;
  extras?: NotificationExtras;
}

export interface NotificationsState {
  collection: Notification[];
  pagination: Pagination;
}

export interface NotificationRow extends Omit<Notification, 'date'> {
  date: Date;
  navigate: string;
}
