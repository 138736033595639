import { FormikValues } from 'formik';
import { ROLE } from 'app/constants/roles';
import { LangKeys } from 'app/translations';
import { TEXT, SWITCH, DROPDOWN, RADIO, BUTTON } from 'components/SmartForm/models';
import { FieldProps } from 'components/SmartForm/types';
import { ButtonProps } from 'components/Touchable/types';
import { ConstraintGroup, CompanyPermissions } from 'containers/Users/types';
import { FieldName, DropdownOptions } from '../types';

interface CreateFieldsProps {
  constraintGroup: ConstraintGroup;
  dropdownOptions: DropdownOptions;
  handleChangeCompany: (id: string) => void;
  handleChangePassword?: () => void;
  permissions: CompanyPermissions;
  translations: LangKeys;
  userStatus: number;
  values: FormikValues;
}

export const createFields = ({ constraintGroup: { canModify, isAdmin, isSameUser, isSuperUser },
  dropdownOptions: { companyOptions, roleOptions },
  handleChangeCompany,
  handleChangePassword,
  permissions,
  translations,
  userStatus,
  values }: CreateFieldsProps): FieldProps[] => {
  const fields: FieldProps[] = [];
  const showCompanyData = isAdmin;
  const showPermissionsData = isAdmin || isSuperUser;

  // Add company field
  if (showCompanyData) {
    if (userStatus === 1 && values.companyName) {
      fields.push({
        id: FieldName.companyName,
        isAlwaysDisabled: true,
        label: translations.onboarding_companyLabel,
        type: TEXT
      });
    }

    fields.push({
      id: FieldName.companyId,
      isDisabled: !canModify,
      label: translations.userDetails_userCompany,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
          handleChangeCompany(event.target.value);
        }
      },
      options: companyOptions,
      type: DROPDOWN
    });
  }

  // Add user's fields
  fields.push(
    {
      id: FieldName.firstName,
      isDisabled: !canModify,
      label: translations.userDetails_userFirstName,
      placeholder: translations.userDetails_userFirstNamePlaceholder,
      type: TEXT
    },
    {
      id: FieldName.lastName,
      isDisabled: !canModify,
      label: translations.userDetails_userLastName,
      placeholder: translations.userDetails_userLastNamePlaceholder,
      type: TEXT
    },
    {
      id: FieldName.email,
      isDisabled: !canModify,
      label: translations.userDetails_userEmail,
      placeholder: translations.userDetails_userEmailPlaceholder,
      type: TEXT
    },
    {
      id: FieldName.phoneNumber,
      isDisabled: !canModify,
      label: translations.userDetails_userPhoneNumber,
      placeholder: translations.userDetails_userPhoneNumberPlaceholder,
      type: TEXT
    }
  );

  // Add change password button
  if (isSameUser && handleChangePassword) {
    fields.push({
      id: FieldName.changePassword,
      isDisabled: !canModify,
      buttonType: 'primary',
      label: translations.userDetails_changePassword,
      onClick: handleChangePassword,
      type: BUTTON
    } as ButtonProps);
  }

  // Add Roles and Permissions
  if (showPermissionsData) {
    // Add Roles radiobuttons
    fields.push({
      id: FieldName.role,
      isDisabled: !canModify,
      label: translations.userDetails_userRole,
      options: roleOptions,
      type: RADIO,
      isChecked: false
    });

    // Add Pickup request Permission switch
    if (Number(values.role) !== ROLE.AVAG_ADMIN && permissions.requestPickup) {
      fields.push({
        id: FieldName.requestPickup,
        isDisabled: !canModify,
        label: translations.userDetails_userPickupPermission,
        type: SWITCH,
        isChecked: true
      });
    }

    // Add Bills Permission switch
    if (Number(values.role) !== ROLE.AVAG_ADMIN && permissions.accessBills) {
      fields.push({
        id: FieldName.accessBills,
        isDisabled: !canModify,
        label: translations.userDetails_userBillsPermission,
        type: SWITCH,
        isChecked: true
      });
    }
  }

  return fields;
};
