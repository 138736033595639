import { RootState } from 'base/types';
import { UserAction, Permission } from 'app/constants/permissions';
import { isAvagAdmin } from './role';

// Permissions selectors
const getRolePermissions = ({ login }: RootState): UserAction[] => Permission?.[login.user?.role] || [];

const getCompanyPermissions = ({ login, ...rest }: RootState): UserAction[] => {
  const permissions: UserAction[] = [];

  if (login.user?.companyPermissions?.portal) {
    permissions.push('ACCESS_PORTAL');
  }

  if (login.user?.companyPermissions?.requestPickup) {
    permissions.push('CREATE_PICKUPS');
  }

  if (!isAvagAdmin({ login, ...rest }) && login.user?.companyPermissions?.accessBills) {
    permissions.push('VIEW_BILLS');
  }

  return permissions;
};

const getUserPermissions = ({ login, ...rest }: RootState): UserAction[] => {
  const permissions: UserAction[] = ['ACCESS_PORTAL'];

  if (login.user?.permissions?.requestPickup) {
    permissions.push('CREATE_PICKUPS');
  }

  if (!isAvagAdmin({ login, ...rest }) && login.user?.permissions?.accessBills) {
    permissions.push('VIEW_BILLS');
  }

  return permissions;
};

// Permission finders
const canRoleDo = (action: UserAction) => (rootState: RootState) => {
  const rolePermissions = getRolePermissions(rootState);
  return rolePermissions.findIndex(a => a === action) >= 0;
};

const canCompanyDo = (action: UserAction) => (rootState: RootState) => {
  const companyPermissions = getCompanyPermissions(rootState);
  return companyPermissions.findIndex(a => a === action) >= 0;
};

const canUserDo = (action: UserAction) => (rootState: RootState) => {
  const userPermissions = getUserPermissions(rootState);
  return userPermissions.findIndex(a => a === action) >= 0;
};

// Single smart selector
export const canDo = (action: UserAction) => (rootState: RootState): boolean =>
  canRoleDo(action)(rootState) || (canCompanyDo(action)(rootState) && canUserDo(action)(rootState));
