import styled, { createGlobalStyle, css } from 'styled-components';
import { PAGINATION_COLOR } from 'base/styles/skin';

const listItem = css`
  cursor: pointer;
  display: inline-block;
  padding: 4px;
  margin: 0 4px;
`;

const linkItem = css`
  display: inline-block;
`;

export const PaginateWrapper = createGlobalStyle`
  .Paginate {
    &--active {
      font-weight: bold;
    }

    &-link--active {

    }

    &--disabled {
      cursor: not-allowed;
    }

    &-container {
      align-items: center;
      color: ${ PAGINATION_COLOR };
      display: flex;
      flex: 1;
      //font-family: Roboto;
      font-family: 'TT Norms';
      font-size: 14px;
      justify-content: center;
      margin: 0 -4px;
    }

    &-page {
      ${ listItem }
    }

    &-pageLink {
      ${ linkItem }
    }
    
    &-prev {
      ${ listItem }
      margin-right: 0;
      padding-right: 0;
    }

    &-prevLink {
      ${ linkItem }
    }

    &-next {
      ${ listItem }
      margin-left: 0;
      padding-left: 0;
    }

    &-nextLink {
      ${ linkItem }
    }

    &-break {
      ${ listItem }
    }

    &-breakLink {
      ${ linkItem }
    }
  }
`;

export const NavigationPrev = styled.img``;
NavigationPrev.displayName = 'NavigationPrev';

export const NavigationNext = styled(NavigationPrev)`
  transform: rotate(180deg);
`;
NavigationNext.displayName = 'NavigationNext';
