import * as Yup from 'yup';
import { LangKeys } from 'app/translations';
import * as validations from 'app/constants/validations';

export const createValidationSchema = (translations: LangKeys): Yup.ObjectSchema => {
  return Yup.object().shape({
    first_name: validations.firstName(translations),
    last_name: validations.lastName(translations),
    company: validations.companyName(translations),
    phone: validations.phoneNumber(translations),
    email: validations.email(translations),
    password: validations.password(translations),
    confirm_password: validations.confirmPassword(translations, 'password'),
    captcha: validations.checkbox.required(),
    'privacy-policy': validations.checkbox.required()
  });
};
