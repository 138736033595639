import React from 'react';
import { PickupRequestStatus } from 'app/constants/pickupRequest';
import LockIcon from 'components/LockIcon';
import LockRedIcon from 'components/LockRedIcon';
import { RendererProps } from 'components/Table/types';

interface PropsData {
  status?: number;
  isWishDateSame?: boolean;
}
const LockRenderer = <R extends PropsData>({ cell, data }: RendererProps<R>): React.ReactElement | null => {
  return (
  // data?.status && (data?.status === 2 || data?.status === 4 || data?.status === 5)
     data?.isWishDateSame === true 
       ? <LockRedIcon />
       : <LockIcon locked={ !(cell === PickupRequestStatus.DRAFT) } />
  );
};


// const LockRenderer = <R extends unknown>({ cell }: RendererProps<R>): React.ReactElement | null => (
//   <LockIcon locked={ !(cell === PickupRequestStatus.DRAFT) } />
// );

export default LockRenderer;