import styled from 'styled-components';

import { TEXT_TAG_COLOR, TAG_BACKGROUND } from 'base/styles/skin';

interface Props {
  color: string;
}

export const Container = styled.div`
  width: max-content;
  background-color: ${ ({ color }: Props) => color || TAG_BACKGROUND };
  border-radius: 4px;
  padding: 2px 12px;
`;
Container.displayName = 'Container';

export const Text = styled.div`
  font-size: 12px;
  text-align: center;
  text-transform: none;
  color: ${ TEXT_TAG_COLOR };
`;
Text.displayName = 'Text';
