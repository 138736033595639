import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BILL_LIST, BILL } from 'app/routes';
import { PropsWithWrapper } from 'app/types';
import Redirect from 'components/Redirect';
import BillList from './containers/BillList';
import BillDetails from './containers/BillDetails';

const Bills: React.FC<PropsWithWrapper<{}>> = ({ wrapper }) => (
  <Switch>
    <Route exact={ true } path={ BILL.path } component={ wrapper(BillDetails) } />
    <Route exact={ true } path={ BILL_LIST.path } component={ wrapper(BillList) } />
    <Redirect to={ BILL_LIST.path } />
  </Switch>
);

export default Bills;
